import { useEffect, useState } from 'react';
export default function useComponentDrag({ref,onMouseUp,onMouseDown,onMouseMoveAndDown}){

  let [ mouseDown, setMouseDown ] = useState(false);
  let toggleMouseUpDown = status => setMouseDown(status);

  const onMouseDownInternal = e => {
    onMouseDown && onMouseDown(e);
    window.someComponentIsDragging = true;
    setMouseDown(true);
  }

  useEffect(() => {

    let onMouseUpInternal = e => {
      if(mouseDown){
        onMouseUp && onMouseUp(e);
        setMouseDown(false);
        window.someComponentIsDragging = false;
      }
    }
    
    let onMouseMove = e => mouseDown && onMouseMoveAndDown(e);

    /*if(ref && ref.current){
    //alert("Setting onMouseDown for ref!");
    (ref.current.onMouseDown = onMouseDownInternal)
  }*/

    let listeners = {
      mouseup:onMouseUpInternal,
      mousemove:onMouseMove
    }

    Object.keys(listeners).forEach(event => {
      window.addEventListener(event,listeners[event])
    })

    return () => {

      Object.keys(listeners).forEach(event => {
        window.removeEventListener(event,listeners[event])
      })

    }

  })

  return onMouseDownInternal;
}
