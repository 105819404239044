export default function SubscriptionPrice({price,discount,comment}){

  let priceComponent = (
    <>
    <div style={{ 
      whiteSpace:'nowrap',
      textDecoration:(discount?'line-through':'unset'),
    }}>
      ${price} USD
    </div>
      {!discount ? null : (
        <>
        <div class="discount-color "style={{
          whiteSpace:'nowrap',
          }}>&nbsp;${discount} USD</div>
        </>
      )}

      {comment}
    </>
  )

  return priceComponent;
}
