import { 
  getSessionInfo, 
  getBuildVersion, 
  isUsingWebdriver,
  getGlobalObject,
} from './versionConfig';

import A from './ActionCreators';

const RRWEB_MUTATION_ERROR_MESSAGE = "Uncaught TypeError: Failed to execute 'contains' on 'Node': parameter 1 is not of type 'Node'.";

export default function initErrorHandler(store){


  let buildVersion = getBuildVersion();
  getGlobalObject().buildVersion = buildVersion;


  const handleError = (e,p) => {

    let objectToLog;

    if( e && e.message === RRWEB_MUTATION_ERROR_MESSAGE){
      return;
    }

    console.log("Handling the error...");

    //let serialized = serializeError(e);

    debugger;


    if( e instanceof Error ){

      let err = e.error || e;
      let hookTrace = getGlobalObject().__hookTrace
      let obj = {
        message:err.message,
        stack:(err.stack||'').split('\n'),
        name:err.name,
        ...getSessionInfo(),
        hookTrace,
        state:store.getState(),
        fileName:err.fileName,
        lineNumber:err.lineNumber,
        columnNumber:err.columnNumber,

      };

      objectToLog = JSON.parse(JSON.stringify(obj));

    }else if( e instanceof PromiseRejectionEvent ){

      let err = e.reason;


      let obj;

      let hookTrace = getGlobalObject().__hookTrace
      if( !err ){




        console.log("e.reason was undefined!");
        console.log("Logging e object");
        console.log(e);
        obj = {
          message:"Unhandled promise rejection came with no `reason` property, so no information available! Follow up by finding unhandled promise rejections in the codebase.",
        }
      }else{


        obj = {
          name:err.name,
          message:err.message,
          stack:(err.stack||'').split('\n'),
          ...getSessionInfo(),
          hookTrace,
          state:store.getState(),
          fileName:err.fileName,
          lineNumber:err.lineNumber,
          columnNumber:err.columnNumber,

        }
      }

      objectToLog = JSON.parse(JSON.stringify(obj));

    }else if( e instanceof ErrorEvent ){
      let hookTrace = getGlobalObject().__hookTrace;
      let obj = { 
        message:e.message,
        stack:(e.error.stack||'').split('\n'),
        ...getSessionInfo(),
        hookTrace,
        state:store.getState(),

        fileName:e.filename,
        lineNumber:e.lineno,
        columnNumber:e.colno,
        timeStamp:e.timeStamp
      }

      objectToLog = JSON.parse(JSON.stringify(obj));

    }else{


      let ePrototype = Object.getPrototypeOf(e);
      let eObject = {};
      if( ePrototype ){
        let eKeys = Object.keys(ePrototype);
        let eEntries = eKeys.map(key => { return [key, e[key]] })
        eObject = Object.fromEnries(eEntries); 
      }

      objectToLog = {
        message:e.message,
        ...eObject,
        errorType:e.constructor && e.constructor.name,
        ...e,
      };

    }



    if( process.env.NODE_ENV === 'production' ){
      store.dispatch(A.logError(objectToLog));
    }


    if( process.env.NODE_ENV !== 'production' ){
      if( getGlobalObject().__showErrorDialog ){
        store.dispatch(A.logError(objectToLog));
      }
    }


  }

  return handleError;

}


