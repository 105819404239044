import { useSelector } from 'react-redux';
import G from './Getters';
import CropWrapper from './CropWrapper';
export default function CropDisplay({
  annotationId
}){

  let state = useSelector(state => state);

  let inlineCrop = G.getCropFromAnnotation(state,annotationId);

  let imageRecord = G.getImageSetFigureImageDataByAnnotationId(state,{annotationId});

  let imageId = imageRecord._id;

  return (
    <div>
      <CropWrapper {...{
        
        imageId,
        inlineCrop,

      }}/>
    </div>
  )




}
