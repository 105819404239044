export function isCLI(){
  return typeof(process) !== "undefined" && process.title.includes( 'node' )
}

export function isUsingWebdriver(){
  if(typeof(navigator) !== typeof(undefined)){
    return !!navigator.webdriver;
  }else{
    return false;
  }
}

export function getGlobalObject(){
  if( isCLI() ){
    return process;
  }else{
    return window;
  }
}

export function getUserAgent(){
  if( isCLI() ){
    return {
      title:process.title,
      version:process.version,
    }
  }
  if( typeof(navigator) !== typeof(undefined) ){
    return navigator.userAgent;
  }else{
    return "NO_USER_AGENT_AVAILABLE"
  }
}


export function getBuildVersion(){
  if( isCLI() ){
    return "cli-test";
  }else{
    let buildVersion = process.env.REACT_APP_BUILD_VERSION;
    return buildVersion;
  }

}

export function getSessionInfo(){
  let userAgent = getUserAgent();
  let buildVersion = getBuildVersion();

  return {
    userAgent, buildVersion
  }
}
