import usePresentSelector from './usePresentSelector';
import CropListLaneAdjustmentContainer from './CropListLaneAdjustmentContainer';
import useLocalReducerWithGlobalDispatch from './useLocalReducerWithGlobalDispatch';
import { QUANTIFICATION } from './UIConstants';
import A from './ActionCreators';
import { produce } from 'immer';
import { useDispatch, useSelector } from 'react-redux';

function getAnnotationSelectionState(state){
  let annotations = state.ui[QUANTIFICATION].selectedAnnotations;
  let object = Object.fromEntries(annotations.map(id => [id,[]]));
  return object;
}

const ListReducer = (state,action) => {
  let { globalDispatch } = action;
  let type = action.type;
  if( !type ){
    throw Error("Trying to reduce action (QuantificationScene) without a type!");
  }
  let { ids } = action;
  switch(type){
    case "add":{
      globalDispatch(A.modifyQuantificationList({
        ids,
        modification:"add"
      }));
      return produce(state,draft => {
        ids.forEach(id => draft[id] = [])
      })
      //Array.from(new Set([...list,...ids]))
    }
    case "remove":{

      globalDispatch(A.modifyQuantificationList({
        ids,
        modification:"remove"
      }));

      return produce(state,draft => {
        ids.forEach(id => delete draft[id]);
      })
      //return list.filter(x => !ids.includes(x))
    }
    case "removeAll":{

      globalDispatch(A.modifyQuantificationList({
        modification:"removeAll"
      }));

      return {};
    }
    case "selectCells":{

      //if not multiselect, then just clear everything
      //and select what was given
      //

      let { indicesByAnnotationIds, multiselect } = action;
      return produce(state,draft => {

        //alert("hello? how are you?");

        if( ! multiselect ){

          Object.keys(draft).forEach(id => {
            if( id in indicesByAnnotationIds ){

              //alert("making a new shet");
              draft[id] = indicesByAnnotationIds[id]
            }else{
              //alert("emptying a set");
              draft[id] = []
            }
          })
        }else{
          for( let id in indicesByAnnotationIds ){
            for( let index of indicesByAnnotationIds[id] ){
              let indexOfIndex = draft[id].indexOf(index);
              if( indexOfIndex === -1 ){
                draft[id].push(index);
              }else{
                draft[id].splice(indexOfIndex,1);
                //draft[id].add(index)
              }
            }
          }
        }
      })
    }
    case "clearSelections":{
      return Object.fromEntries(Object.entries(state).map(([key]) => ([key,[]])));
    }
    default:
      throw Error("the type '"+action.type+"' was not found in QuantificationScene local reducer.");
      //return state;
  }
}

export default function Quantification({}){

  const dispatch = useDispatch();
  let state = usePresentSelector(state => state);
  const [ quantificationList, quantificationListDispatch ] = useLocalReducerWithGlobalDispatch(
    ListReducer,
    getAnnotationSelectionState(state),
    dispatch
  );
  return <CropListLaneAdjustmentContainer {...{
          selectionInformation:quantificationList,
          quantificationListDispatch
        }}/>

}
