import { createRef, useRef, useState, useEffect } from 'react';

 

export default function FigureText({
  value,
  cell,
  cellLocation,
  tdRef,
  textBounds,
  rotatedRectHeight,
}){


  


  let tdBounds = tdRef && tdRef.current && tdRef.current.getBoundingClientRect() || {};

  const ref = useRef(null);
  const [bounds, setBounds] = useState({});


  let originalBounds = textBounds;

  let angleDirection = cell.data.style.angleDirection;
  let directionSign = ({up:-1, down:1})[angleDirection] || -1;
  let angle = directionSign * cell.data.style.angle || 0;

  useEffect(() => {
    
    let newBounds = ref.current.getBoundingClientRect();
    //let newTdBounds = tdRef.current.getBoundingClientRect();
    //setTdBounds(newTdBounds);
    setBounds(newBounds);
  }, [/*tdRef,*/ ref, angle]);


  let theta = Math.abs((Math.PI * 2 * angle) / 360);
  let height = bounds.height;
  let miniWidth =
    tdBounds.height *
    (Math.sin(theta) + Math.cos(theta) ** 2 / Math.sin(theta));

  let tdWidth = Math.min(miniWidth, tdBounds.width);

  let verticalAlign;
  if (angle < 0) {
    verticalAlign = "bottom";
  } else if (angle > 0) {
    verticalAlign = "top";
  } else {
    verticalAlign = "middle";
  }


  let textTopTranslation;

  if( tdBounds.height <= rotatedRectHeight ){
    textTopTranslation = angle > 0 ? -(tdBounds.height * (1 - Math.cos(theta))) : 0;
  }else{
    textTopTranslation = 0 ;
  }

  

  let sign = angle < 0 ? 1 : -1;

  let offset = angle > 0 ? -1 : 0;
  let left =
    offset +
    tdBounds.width / 2 +
    sign *
    (originalBounds
      ? ((originalBounds.height - 2) * Math.sin(theta)) / 2
      : 0);

  let textLeftTranslation = angle !== 0 ? left : bounds.left;




  let lines = value.split('\n').map((val,iiLine) => {
    let spans = Array.from(val).map( 
      (char,iiChar) => {
        let id = "char-"+iiChar+"-line-"+iiLine+"-grid-"+cellLocation.join('-');
        return <span 
        id={id}>{char}</span>
      }
    )
    let fontSize = cell.data.style.fontSize || 12;
    return <div id={"line-"+iiLine+"-grid-"+cellLocation.join('-')} style={{fontSize}}>{spans}</div>
  })

  ////console.log("Lines:");
  //console.log(lines);

  let spanStyle = angle === 0 ? { 
    lineHeight: 1.5
  } : {
    position: "relative",
    zIndex: 2,
    padding: 0,
    margin: 0,
    top: angle !== 0 && textTopTranslation,
    left: angle !== 0 && textLeftTranslation,
    transform: "rotate(" + angle + "deg)",
    transformOrigin: "0% 100%",
    lineHeight: 1,
    whiteSpace: "nowrap"



    //border:'1px solid red',
    //[angle > 0 ? "top" : "bottom"]: 0,
    //[angle > 0 ? "top" : "bottom" ]: angle !== 0 && textTopTranslation,

  }


  let idWithGridAtTheBeginningAndCellLocationInfoSeparatedByDashesForSelectionFrameworkInManualAnnotationGrid = "grid_text_container-"+cellLocation.join('-')

  return (

    <>

      {(angle||null) && <div
        style={{
          pointerEvents:'none',
          zIndex: 10,
          position: "fixed",
          //border:'1px solid red',
          width: bounds.width,
          height: bounds.height,
          top:bounds.top,
          left: bounds.left
        }}
      />}

      
      <div
        style={{
          display: angle !== 0 ? "flex" : undefined,
          margin: 0,
          position: "relative",
          width:'100%',
          minHeight:15,
          //height:angle !== 0 ? '100%' : undefined

        }}
      >
        <div id={"pivot-point-"+cellLocation.join('-')}
          class={"DO-NOT-DELETE-NEEDED-FOR-SVG-GENERATION"} style={{position:'absolute', width:1,height:1,borederRadius:5,zIndex:5,
            //background:'magenta',
            top:(angle!==0 ? textTopTranslation: 0) + (originalBounds && originalBounds.height),
            left:(angle!==0 ? textLeftTranslation:0),

          }}/>
        <div
          id={idWithGridAtTheBeginningAndCellLocationInfoSeparatedByDashesForSelectionFrameworkInManualAnnotationGrid}
          ref={ref}
          style={spanStyle}
        >
          <div 
            style={{
              fontFamily:'Helvetica',
              fontSize:12,
              whiteSpace:'nowrap',
            }}
          >
            {lines}
          </div>
        </div>
      </div>
    </>
  )


}



