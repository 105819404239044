export default function isBrowserSupported(){


  let checkList = ['Chrome','Opera','MSIE','Firefox','Safari'];
  let agent = "Unknown browser";

  //find the first item in checkList 
  //and then break out, because that's the one we're using.
  for(let agentName of checkList){
    if( navigator && navigator.userAgent.indexOf(agentName) !== -1 ){
      agent = agentName;
      break;
    }
  }
  
  return ({
    agent, 
    supported:agent==='Chrome', 
    fullAgent:navigator.userAgent
  })

}
