import React from 'react';
import { useEffect, createRef } from 'react';
//import alldef from './alldef';
import replaceWithGreek from './replaceWithGreek';

const defaultHandleEnter = event => {
  event.preventDefault()
  event.target.blur()
}








const defaultOnKeyDown = ({onTab,onEmptyBackspace}) => event => {
  console.log(event.key);
  if( event.key === 'Enter' ){
    defaultHandleEnter(event);
  }else if( event.key === 'Backspace' ){
    let value = event.target.value;
    console.log({value,onEmptyBackspace});
    if( value.length === 0 && onEmptyBackspace){

      onEmptyBackspace();
    }
  }else if( event.key === 'Tab' && onTab ){
    onTab();
  }
}

const onBlurWrapper = (onBlur,defaultValue) => event => {
  if(!onBlur){
    return;
  }
  let value = event.target.textContent;

  let toSend = value.trim();
  if( toSend === '' ){
    onBlur(defaultValue)
  }else{
    onBlur(toSend)
  }
}

function EditableContent(
  {editable,content,onTab,
    onBlur,
    onChange,
    onEnter,
    onEmptyBackspace,
    defaultValue,
    padding,style,showBorder,
    ignoreEmptyContent,
    trimContent,
    maxLength,
    onKeyDown,
    info,
    focusOnRender
  }){

  defaultValue = defaultValue || "";

  const inputRef = createRef();
  const [editing,setEditing] = React.useState(false);

  useEffect(() => {
    if( !editing ){
      setCurrentContent(content);
    }
  },[editing])

  useEffect(() => {
    if( focusOnRender ){

      let input = inputRef.current;
      if( input ){
        input.focus();
        input.click();
        input.select();
      }
      
      console.log("Focusing!");
    }
  },[focusOnRender])




  const [currentContent,setCurrentContent] = React.useState(content);

  useEffect(() => {
    setCurrentContent(content);
  },[content])

  if( content === null ){
    return null;
  }

  let size;
  let strCurrentContent = currentContent!==undefined && ('' + currentContent);
  if( strCurrentContent ){
    size = strCurrentContent.length + (padding!==undefined?padding:1);
  }else{
    size = (defaultValue.length + (padding!==undefined?padding:1))// || 5;
  }



  let border = showBorder ? '1px solid #ccc' : 0;

  let inputValue = editing ? currentContent : content;

  let resolvedOnKeyDown;
  if( onKeyDown ){
    resolvedOnKeyDown = onKeyDown
  }else{
    resolvedOnKeyDown = e => defaultOnKeyDown({onTab,onEmptyBackspace})(e);
  }

  return (
    <>
      <input 
        info={info}
        ref={inputRef}
        maxLength={maxLength}
        placeholder={defaultValue}
        class="editable-content"
        disabled={editable===false}
        size={size}
        onKeyDown={resolvedOnKeyDown}
        onClick={e => {e.stopPropagation();setEditing(true)}}
        onChange={e=>{
          let value = e.target.value;

          let finalValue = replaceWithGreek(value);
          setCurrentContent(finalValue);
        }}
        onBlur={e=>{


          let value = e.target.value;
          let finalValue = trimContent ? value.trim() : value;

          onChange && onChange(finalValue);
          setEditing(false);
          if( ignoreEmptyContent && e.target.value.trim().length === 0 ){
            setCurrentContent(content);
          }
          onBlur && onBlur(currentContent);
        }}
        style={{margin:0,padding:0,border:0, ...style,display:'flex'}}
        value={inputValue}
      />
    </>
  )
}

export default EditableContent;
