import { produce, enablePatches } from 'immer';

import {diff} from 'jsondiffpatch';
import getVenn from './Venn';
import convertDiffToOpList from './deepDiffToImmerPatch';
import deleteItemFromObject from './deleteItemFromObject';

import pushPatchOnUndoHistoryStack from './pushPatchOnUndoHistoryStack';

import { ANNOTATIONS, FIGURE_PANELS, IMAGE_UPLOADS } from './RecordTypes';

enablePatches();


const UNDOABLE_TOP_LEVELS_IF_DATA_IS_UNDOABLE = [
  'data','meta','syncStatus'
]

const RECORDS_UNDOABLE_ON_ADD = [
  ANNOTATIONS
]

const RECORDS_UNDOABLE_ON_SET = [
  ANNOTATIONS,
  FIGURE_PANELS,
  'figures',
  IMAGE_UPLOADS
]

const RECORD_PROPERTIES_UNDOABLE_ON_SET = {
  [IMAGE_UPLOADS]:['adjustments']
}


const isOperationAnUndoableRecordAddOrRecordArchiveStatusToggle = operation => {
  let { path, op } = operation;

  let [topLevel, recordType, _id,property] = path;

  let requiredConditionsForAdd = {
    topLevelIsData:topLevel==='data',
    goodLength:path.length === 3,
    propertyOp:op.includes('add'),
    validAddRemoveRecord:(RECORDS_UNDOABLE_ON_ADD.includes(recordType)),
  }

  let requiredConditionsForUndoableArchiveStatusToggle = {
    validRecord:(RECORDS_UNDOABLE_ON_ADD).includes(recordType),
    isArchiveToggle:path.includes('archived')
  }
  
  return [requiredConditionsForAdd,requiredConditionsForUndoableArchiveStatusToggle].some( requiredConditions => Object.values(requiredConditions).every(x => x));
  
}

const isOperationUndoable = operation => {
  let undoableOps = { 
    propertyChange:isUndoableRecordPropertyChange(operation),
    addRemove:isOperationAnUndoableRecordAddOrRecordArchiveStatusToggle(operation)
  }

  let changedUndoableData = Object.values(undoableOps).some(x => x);


  return changedUndoableData;

}

const isUndoableRecordPropertyChange = operation => {

  let { path, op } = operation;
  let [topLevel, recordType, _id,property] = path;

  let requiredConditions = {
    topLevelIsData:topLevel==='data',
    goodLength:path.length > 3,
    

    validUndoableSetRecord:(RECORDS_UNDOABLE_ON_SET.includes(recordType)),
    validUndoableSetProperty:(
      !(recordType in RECORD_PROPERTIES_UNDOABLE_ON_SET) || (RECORD_PROPERTIES_UNDOABLE_ON_SET[recordType].includes(property))
    )
  }

  return Object.values(requiredConditions).every(x => x);

}

function getOpsSpecifyingAnUndoableDataChange(diff){
  let opList = convertDiffToOpList(diff);



  
  return {
    opList,
    undoableDataChanges:opList.filter(isOperationUndoable) 
  }
}

const pathTopLevelContains = (path, requiredStateTopLevelForValidOp) => {
  let topLevelOfOp = path[0];
  return requiredStateTopLevelForValidOp.includes(topLevelOfOp);
}

function isOperationOnRequirementGraph( operationPath ){
  return ['requiredAsByRole','requiredByRole'].some(field => operationPath.includes(field));
}

function isValidResponseToUndoableDataChange(op,correspondingDataChanges){

  // can probably make more efficient
  // and less confusing
  // by just putting in the ids
  // and comparing those


  let responseOperationPath = op.path;

  if( isOperationOnRequirementGraph( responseOperationPath ) ){
    return true;
  }

  if( pathTopLevelContains(responseOperationPath, ['meta','syncStatus']) ){

    return correspondingDataChanges.some(dataChange => {

      let prefixPath = dataChange.path;
      let validatedRecordType = prefixPath[1];
      let validatedId = prefixPath[2];
      return (
        validatedRecordType === responseOperationPath[1]
        &&
        validatedId === responseOperationPath[2]
      )
    })
  }

  return false;

}



function filterDiffPatch(diff){
  

  //include only patches with ["data",`recordType`,`_id`]
  //and the corresponding ["meta",`recordType`,`_id`]
  //as well as any changes made to "requiredByRole" and "requiredAsByRole" due to bidirection edge creation
  
  //bidirectional edge creation SHOULD only happen
  //when a record is added or some property
  //of a record changes

 
  
  let { opList, undoableDataChanges } = getOpsSpecifyingAnUndoableDataChange(diff);


  let notUndoableOps = [];
  let undoableOpsSet = new Set(undoableDataChanges.map(JSON.stringify));

  opList.forEach(op => {
    let strOp = JSON.stringify(op);
    if( isValidResponseToUndoableDataChange(op,undoableDataChanges) ){
      undoableOpsSet.add(strOp);
    }else if( !undoableOpsSet.has(strOp) ){
      notUndoableOps.push(op);
    }
  })


  let patchToFilter = {...diff};

  notUndoableOps.forEach(op => {
    let { path } = op;
    deleteItemFromObject(patchToFilter,path);
  })

  return patchToFilter;

}

const getFilteredPatch = diff => {
  if( diff ){
    return filterDiffPatch(diff);
  }
  return {};
}

const outerProduce = (state,changeFunction) => {

  let nextState = produce(state,changeFunction);
  let theDiff = diff(state, nextState);

  return produce(nextState,draft => {

    let filteredPatch = getFilteredPatch(theDiff);
    pushPatchOnUndoHistoryStack(draft,filteredPatch);

    
  })



}



export default outerProduce;
