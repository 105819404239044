import usePresentSelector from './usePresentSelector';
import measuredTextWidth from './measuredTextWidth';
import { useEffect, useState, createRef } from 'react';

import ImageFetchFailed from './ImageFetchFailed';

import { useDispatch } from 'react-redux';
import CropPreview from './CropPreview';

import InvertColorsIcon from '@material-ui/icons/InvertColors';
import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff';

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import A from './ActionCreators';
import G from './Getters';
import C from './Constants';

import imageMap from './imageMap';


export default function CropWrapper({
  canClickToRetryDownload,
  stack,
  setDragInfo,
  imageSetId,
  imageId,
  atnIdList,imageSelectable, 
  width, 
  pxHeight,
  removable,onRemove,draggable,
  placementEditable, gridCellId, invertable, temporaryAdjustments,
  isSelectedImage,
  htmlImageId,
  htmlImageClipDivId,
  overflow,
  imgRef,
  annotation,
  annotationId,
  annotationIdList,
  inlineCrop,
  fromImageManagement,
  version,
}){

  version = version || C.DEFAULT_PROCESSED_IMAGE_VERSION;
  //alert(version);


  

  let fetchStatus = usePresentSelector(state => {
   
    let status = G.getMediaFetchStatus(state,{imageId,version});
    if( status === "none" && version === "raw-png" ){
      version = "raw";
      status = G.getMediaFetchStatus(state,{imageId,version});
    }
    return status;
  })



  let dispatch = useDispatch();

  if( !fetchStatus || fetchStatus === "none" ){
    dispatch(A.fetchImages({
      imageSpecList:[{version,imageId}]
    }))
  }else{
  }
  



  const [entered,setEntered] = useState(false);

  let atns = usePresentSelector(state => state.data.annotations);

  ////console.log({entered});

  imageId = usePresentSelector(state => {

    if( !imageId && imageSetId ){
      return G.getImageSetFigureImage(state,{imageSetId})
    }else{
      return imageId;
    }
  })

  const [ wrapperWidth, setWrapperWidth ] = useState(width);

  const wrapperRef = createRef();


  useEffect(() => {

    /* this makes sure that 
     * changes in container width prop
     * are rendered
     */

    if( width !== wrapperWidth ){
      setWrapperWidth(width);
    }
  })

  const crop = {
    top:0,
    left:0,
    height:1,
    width:1,
    rotation:0,
  }

  useEffect(() => {

    if( wrapperRef.current ){
      if(isNaN(width)){
        let newWrapperWidth = (wrapperRef.current.getBoundingClientRect().width)
        ////console.log("setting new wrapper width: " + newWrapperWidth);
        setWrapperWidth(newWrapperWidth);
      }
    }
  },[wrapperRef,width])


  let {imageRecord,src} = usePresentSelector(state => {
    let imageRecord = state.data.imageUploads[imageId];

    let src = G.getImageRecordUrl(state,{imageId,version});
    //src = src || G.getImageRecordUrl(state,{imageId,version:"raw"});


    let queryNoResults = !src;
    if( queryNoResults ){

      let imageIdMediaContainer = state.media[ imageId ];
      let mediaContainerValues = Object.values(imageIdMediaContainer)
      let imagesInImageMap = mediaContainerValues.filter(
      ({localBlobUrl}) => {
        return (localBlobUrl in imageMap)
      });

      let { localBlobUrl } = imagesInImageMap[0] || {}; 
      return { imageRecord, src:imageMap[ localBlobUrl ] }
    }

    return { imageRecord, src }
  });



  if( !imageRecord ){
    return <div style={{color:'red',fontWeigth:'bold'}}>{
      "Error: no image found with id " + imageId}
    </div>
  }

  //let inverted = imageRecord?.adjustments.inverted;



  if( !src === true ){
    let loadingText ="Loading...";
    let textWidth = measuredTextWidth(loadingText,8);

    let loadingContent;

    if(  textWidth >= wrapperWidth ){
      loadingContent = <HourglassEmptyIcon style={{fontSize:14}}/> 
    }else if( fetchStatus === "pending"){
      loadingContent = (
        <div item={"image-fetch-status-text"} itemFetchStatus={"pending"}>
          Loading...
        </div>
      )

    }else if( fetchStatus === "corrupt" ){
      loadingContent = <ImageFetchFailed {...{
        message:"Resource not found.",
        gridCellId,
        imageId
      }}/>
    }else if( fetchStatus === "failed" ){
      loadingContent = <ImageFetchFailed {...{
        canClickToRetryDownload,
        gridCellId,
        imageId
      }}/>
    }
    return (
      <div style={{fontSize:8,border:'1px solid #ddd', 
        height:'100%',
        width:'100%', 
        textAlign:'center',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
        }}>
        {loadingContent}
      </div>
    )
  }

  if( atnIdList && atnIdList[0] && atns[atnIdList[0]] === undefined ){
    return <div style={{color:'red'}}><b>{"Deleted annotation: " +atnIdList[0]}</b></div>
  }

  return (
    <>

      <div
        type={"crop-boundary"}
        imageId={imageId}
        ref={wrapperRef}
        onMouseEnter={e => {
          ////console.log("ENTERED CROP WRAPPER!");
          setEntered(true)
        }}
        onMouseLeave={() => {
          ////console.log("onMouseLeave in CropWrapper");
          setEntered(false)
        }}
        draggable={false}
        onDrag={e=>{ 
          if(fromImageManagement){
            setDragInfo && setDragInfo({
              valueType:(annotationId?"crop":"image"),
              imageId,
              annotationId
            })
          }
        }}

        onDragStart={e => {


          if(!fromImageManagement){
            setDragInfo && setDragInfo({
              valueType:(annotationId?"crop":"image"),
              imageId,
              annotationId
            })
          }
        }}
        onDragEnd={e => {
          //e.preventDefault();
          if(setDragInfo){
            setDragInfo && setDragInfo(null)
          }
        }}
        style={{
          position:'relative', 
          height:pxHeight,
          //background:'pink'

        }}>


        <CropPreview
          stack={[...(stack||[]),"CropWrapper"]}
          setDragInfo={setDragInfo}
          imageId={imageId}
          crop={inlineCrop||crop}
          annotation={annotation}
          imgRef={imgRef}
          overflow={overflow}
          temporaryAdjustments={temporaryAdjustments}
          placementEditable={placementEditable}
          draggable={false && draggable ? draggable && imageSelectable : imageSelectable}
          parentWidth={wrapperWidth}
          parentHeight={pxHeight}
          entered={entered}
          gridCellId={gridCellId}
          htmlImageId={htmlImageId}
          htmlImageClipDivId={htmlImageClipDivId}
          imageRecord={imageRecord}
          src={src}
        />

        {isSelectedImage && <div style={{
          position:'absolute',
          top:0,
          left:0,
          width:'100%',
          height:'100%',
          border:'3px solid #a6d3f7',
          pointerEvents:'none'
        }}/>}
      </div>


      {false && <div style={{
        position:'absolute',
        left:0,
        top:0,
        width:100,
        color:'white',
        background:'black'
      }}>{width}
      </div>}





    </>
  )


}
