import RedoIcon from '@material-ui/icons/Redo';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useDispatch } from 'react-redux';
import usePresentSelector from './usePresentSelector';
import A from './ActionCreators';
import G from './Getters';


const DISCARD = "discard";
const RESTART = "restart";

export default function MediaPersistanceActionButton({_id,action}){
  let dispatch = useDispatch();
  let restartMediaArgs = usePresentSelector(state => G.getMediaProcessingStartArgs(state,{_id}));

  let icon;

  if( action === DISCARD ){
    icon = (<div style={{fontSize:18, 
      position:'absolute',
      lineHeight:0.5,
      marginLeft:1,
      marginTop:-2,
      //marginLeft:1,
      //marginTop:-1,
      
    }}>×</div>)
  }else if( action === RESTART ){
      icon = <RedoIcon style={{fontSize:12}}/>;
    }

  

  return (

    <div style={{
      //fontSize:10,
      borderRadius:20,
      minWidth:20,
      width:20,
      height:20,
      //marginRight:10,


      background:'#e0e0e0',
      alignItems:'center',
      justifyContent:'center',
      cursor:'pointer',
      display:'flex',
      position:'relative',
      



    }}
      action={action}
      onClick={() => {
        if( action === DISCARD ){
          dispatch(A.discardPendingRecords({
            imageIds:[_id]
          }));
        }else if( action === RESTART ){
          dispatch(A.startMediaProcessing({
            imageIds:[_id],
            args:restartMediaArgs
          }))
        }
      }}
      class="full-height flex v-center dark-hoverable round-corners dark-active">
      {icon}

      
    </div>

  )

}
