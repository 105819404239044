import QuantificationCropInfoContainer from './QuantificaitonCropInfoContainer';
import QuantificationViewer from './QuantificationViewer';
import QuantificationAnnotationUpdateMenu from './QuantificationAnnotationUpdateMenu';
import { QuantificationUpdateMenuContext } from './Contexts';
import SciugoContextMenu from './SciugoContextMenu';

import G from './Getters';

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import C from './Constants';

const options = [
  
]

export default function QuantificationAnnotationContainer({maxLaneCount, atn, localState, setLoadingControls,loadingControls, loadingControlFeature}){

  let [ activeUpdatePreviewProperty, setActiveUpdatePreviewProperty ] = useState();


  let state = useSelector(state => state);

  let possibleQuantificationAnnotationUpdates = G.getPossibleQuantificationAnnotationUpdates(state,{_id:atn._id});
  let possibleUpdatesSeen = G.arePossibleQuantificationAnnotationUpdatesSeen(state,{_id:atn._id}); 

  let [ quantificationUpdateActive, setQuantificationUpdateActive ] = useState(!!(!possibleUpdatesSeen && possibleQuantificationAnnotationUpdates.length));

  const [ contextMenuLocation, setContextMenuLocation ] = useState(null);

  const [ imageVersions, setImageVersions ] = useState([C.DEFAULT_PROCESSED_IMAGE_VERSION]);
 

  const contextMenuOptions = [{
    text:"Quantification Settings",
    disabled:(possibleQuantificationAnnotationUpdates.length === 0),
    onClick:() => setQuantificationUpdateActive(true),
  },

    {
      text:`View Color and Greyscale Versions`,
      disabled:false,
      onClick:() => setImageVersions([C.DEFAULT_PROCESSED_IMAGE_VERSION,C.GREYSCALE])
      //imageVersion === C.DEFAULT_PROCESSED_IMAGE_VERSION ? C.GREYSCALE : C.DEFAULT_PROCESSED_IMAGE_VERSION),
  }
    /*,
    { 
      text:'View Greyscale as Default',
      onClick:() => {}
    },
    {
      text:'View Colored as Default',
      onClick:() => {}
    }*/
  ]

  return (
    <div class="sg-row fair-margin flex-expand" key={atn._id} style={{
      //background:'green',
      //alignItems:'center',
      position:'relative',
      //boxShadow: '0 0 0 99999px rgba(0, 0, 0, .8)',
    }}

      onContextMenu={e => {
        e.preventDefault();
        e.stopPropagation();
        setContextMenuLocation({x:e.clientX, y:e.clientY});
      }}

    >

      {contextMenuLocation && <SciugoContextMenu {...{ 
        ...contextMenuLocation, 
        options:contextMenuOptions, 
        onClose:()=>setContextMenuLocation(null) }}/>}



      <div style={{
        paddingLeft:40,
        display:'flex',
        alignItems:'center',
        maxWidth:'70%',
        width:'70%',
      }}>
        <QuantificationCropInfoContainer 
          atn={atn} 
          maxLaneCount={maxLaneCount} 
          activeUpdatePreviewProperty={activeUpdatePreviewProperty}
          tempProperties={localState}
          imageVersions={imageVersions}
          setImageVersions={setImageVersions}
        /> 
      </div>

      {Boolean(loadingControlFeature) && <div style={{
        display:'flex',justifyContent:'center',alignItems:'center'}} class="fair-padding"><input type="checkbox" onChange={e => setLoadingControls({...loadingControls,[atn._id]:e.target.checked})}/></div>}


      <div style={{
        width:'30%',
        maxWidth:'30%',
        position:'relative',
      }}>
        { !quantificationUpdateActive &&  <QuantificationViewer {...{focusedAnnotationId:atn._id}}/> }
        { quantificationUpdateActive && <QuantificationAnnotationUpdateMenu {...{
          annotationId:atn._id,
          unsyncedProperties:possibleQuantificationAnnotationUpdates,
          setUpdateMenuActive:setQuantificationUpdateActive,
          setActiveUpdatePreviewProperty,
          
        }}/> }
      </div>

    </div>
  )
}
