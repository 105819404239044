import { useState } from 'react';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import { SCREENSHOT_IGNORE } from './UIConstants';

const getContent = (rowIndex,side,alwaysVisible,onClick) => {

  let text = (alwaysVisible!==true)&&<div style={{

    width:100,
    transform: 'translate('+(side!=='right'?'-':'')+'100%,-50%)',
    background:'pink'
  }}>Add row:</div> ;

  let circle = <AddCircleIcon 
    onClick={onClick}
    style={{
      cursor:'pointer',
      position:'absolute',
      top:'50%',
      left:'50%',
      transform:'translate(-50%,-50%)',

      background:'white',
      borderRadius:10,
      padding:0,
      margin:0,
      color:'#088c44',fontSize:15
    }}/>


    //let arrangement = side === 'right' ? [circle,text] : [text,circle];

  return circle;


}

export default function RowAdder({rowIndex,side,alwaysVisible,columnIndex,level,figurePanelId,tooltip,tooltipPlacement,
  pointerEvents,
}){

  if(!figurePanelId){
    throw Error("RowAdder: figurePanelId cannot be undefined.");
  }

  let [visible,setVisible] = useState(false);
  //visible = true;

  const dispatch = useDispatch();

  let yTranslate = side!==undefined?'-50%':(level==='top'?'-10%':'50%');
  let xTranslate = side!==undefined?'-50%':(level==='top'?'-12%':'50%');

  const onClick = e=>{
    e.stopPropagation();
    if( !isNaN(rowIndex) ){
      dispatch(A.insertRow({figurePanelId,rowIndex}))
    }else if( !isNaN(columnIndex) ){
      dispatch(A.insertColumn({figurePanelId,columnIndex}))
    }
  }



  let width = 30;
  let height = 30;
  if(side){
    width = 16;
    height = 18;
  }

  let transform;
  if( side === 'right' ){
    xTranslate = '0%';
    yTranslate = '-50%';
    //transform = 'translate('+xTranslate+','+yTranslate+')';
  }else if( side === 'left' ){
    xTranslate = '-100%'
  }

  let content = (

    <div 
      action={"add-row-"+rowIndex+"-"+side}
      class={"" + SCREENSHOT_IGNORE}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      style={{
        //background:'pink',
        //zIndex:5,
        top:0,
        right:(side==='right'? 0 :undefined),
        left:(side==='left'? 0 :undefined),
        width,
        height,

        pointerEvents,


        transform:'translate('+(side==='right'?'-':'')+xTranslate+','+yTranslate+')',
        position:'relative'
      }}>
      

      {(alwaysVisible||visible) && <div style={{
      position:'absolute',top:'50%',left:'50%',
      width:5,
      height:5,
      borderRadius:5,
      background:'#ddd',

      transform:'translate(-50%,-50%)'
    }}/>}

      {visible && getContent(rowIndex,side,alwaysVisible,onClick) }





    </div>

  )

  /*content = (
    <div style={{
      position:'absolute',top:'50%',left:'50%',width:7,height:7,
      borderRadius:7,
      background:'#ccc',

      transform:'translate(-50%,-50%)'
    }}/>
  )*/



  if( tooltip ){
    return (
      <Tooltip title={tooltip} placement={tooltipPlacement}> 

        {content}
      </Tooltip>
    )
  }else{
    return content;
  }


}
