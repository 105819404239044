import { useState, useEffect, useContext } from 'react';
import { distance, vectorAngleDegrees } from './Geometry';
import { CropAdjustmentContext } from './Contexts';

const div0Style = (arrowInfo,lineLength,angle) => ({

  position:'fixed',
        left:arrowInfo[0][0],
        top:arrowInfo[0][1],
        width:lineLength,
        height:3,
        background:'blue',
        'transform-origin':'top left',
        transform:'rotate('+(angle-180)+'deg)',
})

const div1Style = (arrowInfo,endCircleDim) => ({

  top:arrowInfo[1][1]-endCircleDim,
          left:arrowInfo[1][0]-endCircleDim/2,
          width:endCircleDim,
          height:endCircleDim,
          'border-radius':endCircleDim/2,
          border:'3px solid blue',
          position:'fixed',

})

function Div(id, style){
  let div = document.createElement('DIV');
  div.id = id;
  Object.entries(style).forEach(([prop,val]) => div.style[prop] = (!isNaN(val) ? val+'px' : val));
  return div;
}


export default function RowSpacingComponent({rowSpacing,colSpan}){

  let [ arrowInfo, setArrowInfo ] = useState();

  let cropAdjustmentContext = useContext(CropAdjustmentContext);
  let { cropResizeInfo } = cropAdjustmentContext || {};

  const mouseUp = e => {
    let arrow0 = document.getElementById('arrow-0');
    let arrow1 = document.getElementById('arrow-1');
    if( arrow0 && arrow1 ){
      let root = document.getElementById('root');
      root.removeChild(document.getElementById('arrow-0'));
      root.removeChild(document.getElementById('arrow-1'));
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup',mouseUp);
    return () => window.removeEventListener('mouseup',mouseUp)
  })

  let arrowDiv = null;


  return (

    <>
    <tr style={{
        height:rowSpacing,
      pointerEvents:(cropResizeInfo?'none':'unset')

      }}><td 
        class="dark-hoverable"
        onMouseDown={e => {

          let endEle = document.getElementById('toolbar-item-rowSpacing');
          let box = endEle.getBoundingClientRect();


          let newArrowInfo = [ [ e.clientX, e.clientY, ], [ (box.x + 0.5 * box.width), (box.y + box.height) ] ]
          let lineLength = distance(...newArrowInfo);
    let angle = vectorAngleDegrees(newArrowInfo);
    //console.log(angle);
    let endCircleDim = 40;

          let d0 = Div("arrow-0", div0Style(newArrowInfo,lineLength,angle));
          let d1 = Div("arrow-1",div1Style(newArrowInfo,endCircleDim));

          //console.log(box);
          setArrowInfo(newArrowInfo)

          let root = document.getElementById("root");
          root.appendChild(d0);
          root.appendChild(d1);


        }}
        style={{cursor:'row-resize', padding:0}} colSpan={colSpan}>
        
      </td>
      </tr>

    {arrowDiv}
    </>

  )
}
