import LoadImage from './LoadImage';

//import ProcessImageInWorker from './ProcessImageInWorker';


import quantifyAnnotation from './quantifyAnnotation';


function handleConfig(config){

  if( config && config.fail && config.fail === 'process' ){
      throw {failed:"process"};
    }
}




export default function ProcessImage(args){

  let {
    imageSrc, 
    config 
  } = args;


  /*
   1. gather arguments
      - annotation args
      - image source if needed
      A) Check 
  */

  return new Promise((resolve,reject) => {


    config && handleConfig(config);

    let processInWorker = false;

    if( processInWorker ){

      /*
      return ProcessImageInWorker(args).then(output => {
        resolve(output);
      }).catch(error => {
        throw(error);
        //1) need to dispatch the error to the server
        //2) need to cancel the process
      })
      */

    }else{

      //console.log("HELLO?");
     // console.log({imageSrc});

    LoadImage(imageSrc).then((raw) => {


      //console.log({raw});

      
      let { adjustments } = args.imageData;

      let invert = adjustments.inverted;

      let quantifications =
        quantifyAnnotation({raw,invert, ...args});
      //console.log("AFTER QUANT");
      resolve(quantifications);
    }).catch(e => {
      console.log("HUGE ERROR in LoadImage!");
      console.error(e);
    })
    }
  })
}
