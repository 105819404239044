import usePresentSelector from './usePresentSelector';
import { useContext } from 'react';
import RowSpacingComponent from './RowSpacingComponent';
import G from './Getters';
import A from './ActionCreators';
import DragRow from './DragRow';
import { useState, createRef, useRef, useEffect } from 'react';
import GridCell from './GridCell';
import RowAdder from './RowAdder';
import CropDropRow from './CropDropRow';
import SpacerRowForRotatedText from './SpacerRow';
import { SCREENSHOT_IGNORE } from './UIConstants';
import { RowDragContext } from './Contexts';
import DragRowButton from './DragRowButton';

import RowMoveDropRow from './RowMoveDropRow';


import computeCellTextBounds, {getRotatedRectHeight} from './PositionEngine';
const getCellWidth = (cellX,cellW,referenceWidths) => {
  let widthsToSum = referenceWidths.slice(cellX, cellX + cellW);
  let sum = widthsToSum.reduce((a,b) => a+b,0)

  return sum;// + accountForMarginsAndBorders;
}

const placeholderStyle = () => ({
  color:'grey',
  fontSize:9,
  fontStyle:'italic',
})

const getRotationSpacings = (originalBoundsList, angles, rowIndex) => {
  let x = rowIndex;
  let spacing = originalBoundsList.reduce(
    (maxes, bounds, index) => {

      let angle = angles[index];
      let copy;

      let height = 0;
      if( bounds ){
        height = getRotatedRectHeight(bounds,angle)
      }



      if (angle < 0) {
        copy = {
          ...maxes,
          'up':Math.max(maxes.up,height)
        }
      } else if (angle > 0) {
        copy = {
          ...maxes,
          'down':Math.max(maxes.down,height)
        }
      }else{
        copy = maxes;
      }

      return copy;
    },
    { up: 0, down: 0 }
  );

  let upSpacing = Math.max(0,spacing.up - (originalBoundsList[0]?.height || 0))
  let downSpacing = Math.max(0,spacing.down - (originalBoundsList[0]?.height || 0))
  return { upSpacing, downSpacing };
}

export const MINIMUM_ROW_HEIGHT = 15;

export default function ManualAnnotationRow ({
  editableTableRef,
  editable,

  rowSpacing,

  verticalImageResizeTranslation,
  manualAnnotationGridDispatch,
  figurePanelId,
  setDemoSelected,
  demoSelected,

  row, rowIndex, cellDragEntered, setCellDragEntered, onCropDrop,
  makeCellSelection, isCellSelected, selectedCells, multiselect,
  isDraggingCrop, setCurrentWidths, setCellResizeInfo, setDontUnselectCells,
  tableOutlineShowing, temporaryCellValue, widths, 

  cropIdsBeingDragged,
  setCropIdsBeingDragged,


  editorStyle, 
  //getCellWidth, 
  currentWidths, setMouseIsDown, cellResizeInfo,

  numRows,
}){


  
  let colSpan = row.reduce((sum,cell) => sum + cell.w, 0) + 1;
  //console.log({colSpan})

  if( !figurePanelId ){
    throw Error("figurePanelId cannot be undefined.");
  }

  let doCellsSpanMultipleRows = usePresentSelector(state => {
    if(G.getTableDimensions(state,figurePanelId)[0] <= rowIndex ){
      return null;
    }else{
      return G.doCellsSpanMultipleRows(state,{figurePanelId,rowIndex}) 
    }
  })


  let allTextBounds = row.map(computeCellTextBounds);
  let cellsThatSpanThisRowOnly = allTextBounds.filter((_,ii) => !doCellsSpanMultipleRows[ii]);

  let angles = row.map( cell => {
    let direction = ({'down':1,'up':-1})[cell.data.style.angleDirection] || -1;
    let angleMagnitude = cell.data.style.angle || 0;

    let signedAngle = direction * angleMagnitude;

    return signedAngle;
  })

  let anglesOfCellsThatSpanThisRowOnly = angles.filter((_,ii) => !doCellsSpanMultipleRows[ii]);

  let rotatedRectHeights = allTextBounds.map(
    (bounds,ii) => getRotatedRectHeight(bounds,angles[ii])
  );

  let { upSpacing, downSpacing } = getRotationSpacings(
    cellsThatSpanThisRowOnly, anglesOfCellsThatSpanThisRowOnly, rowIndex
  )


  ////console.dir(JSON.stringify({rowIndex, upSpacing, downSpacing}))


  let rowTranslation = editable!== false ? verticalImageResizeTranslation.y : 0;
  // * (verticalImageResizeTranslation.untilRowIndex < rowIndex ? -1 : 1)// verticalImageResizeTranslation.y : ;

  ////console.log({rowIndex, rowTranslation});


  const rowRef = useRef();
  let rowBounds = rowRef.current && rowRef.current.getBoundingClientRect();

  const isLastRow = rowIndex === numRows-1;



  const { rowDragState, rowDragDispatch } = useContext(RowDragContext);

  const dragRowMargin = 10;

  const inPreDragRowState = rowDragState && rowDragState.preDrag && rowDragState.hovering === rowIndex;
  
  const inDragRowState = rowDragState && rowDragState.dragging !== undefined;

  const draggingThisRow = rowDragState && rowDragState.dragging === rowIndex;


  let rowWidth = widths.reduce((a,b) => a+b);

  let dragRowLeftShift = -40;
  let totalDragRowWidth = rowWidth + 100;


  let dragEntryPositions = rowDragState?.dragging !== undefined && (<>
    <div 
      onMouseEnter={() => {

        //alert("UPPER " + rowIndex);

        if( inDragRowState ){
        rowDragDispatch({
          spaceAt:rowIndex
        })
      }
        /*
        rowDragDispatch({
          spaceAt:rowIndex
        })*/
      }}
      style={{
        position:'absolute',
        top:0,
        left:dragRowLeftShift,
        width:totalDragRowWidth,
        height:'50%',
        //border:'1px solid black',

        //background:'green',

        //background:'white',
        //opacity:0.7,
        zIndex:2000,

      }}>
    </div>
    <div onMouseEnter={() => {

      if( inDragRowState ){
        rowDragDispatch({
          spaceAt:rowIndex+1
        })
      }

    }}
      style={{
        //border:'1px solid black',

        position:'absolute',
        top:'50%',
        left:-40,
        width:rowWidth + 100,
        height:'50%',

        //background:'white',

        //background:'purple',
        //opacity:0.7,
        zIndex:2000,

      }}/>

    {draggingThisRow && (

      <div 
      style={{
        pointerEvents:'none',

        position:'absolute',
        top:0,
        left:0,
        width:rowWidth+3,
        height:'100%',

        background:'white',

        //background:'purple',
        opacity:0.7,
        zIndex:1000,

      }}/>
    )}
  </>)

  return (
    <>

      {(false || (rowDragState?.dragging !== undefined && rowDragState?.spaceAt===rowIndex && rowDragState.dragging !== rowDragState.spaceAt)) && <RowMoveDropRow 
        {...{
          dragRowLeftShift,
          totalDragRowWidth
        }}
        figurePanelId={figurePanelId}
        setCropIdsBeingDragged={
          setCropIdsBeingDragged
        }
        rowDragDispatch={rowDragDispatch}
        fromIndex={rowDragState.dragging}
        toIndex={rowIndex}
        rowIndex={rowIndex}
        cellDragEntered={cellDragEntered}
        setCellDragEntered={setCellDragEntered}
      />}


      <SpacerRowForRotatedText height={upSpacing} />




      <tr class="grid-row" ref={rowRef} onMouseEnter={() => {


        /*
        rowDragDispatch({
          hover:rowIndex
        })
        */

      }}
        style={{
          position:'relative',
          //outline:'1px solid blue',
          transform:'translate(0,'+rowTranslation+'px)'}}>



        {editable!==false && <td 
          class={""+SCREENSHOT_IGNORE}
          style={{
            verticalAlign:'top',
            
            //border:'1px solid red',
            //height:'100%',
            position:'sticky',
            background:'white',
            width:0,
            margin:0,
            padding:0,
            top:0,
            left:0,
            height:'100%',
            zIndex:20,


            /*
             There's a random space here,
             and I don't know why the space is there.
            */

            //background:'yellow',
            
          }}>

          <div style={{
            top:-5,
            left:-50,
            position:'absolute',
            width:50,
            height:'100%', // no explanation as to WHY this is here... likely to hover stuff for the drag row
            background:'white',
          }}/>

          {dragEntryPositions}



          {true && <DragRowButton {...{
            manualAnnotationGridDispatch,
            dragRowMargin,
            rowDragDispatch,
            rowIndex,
            rowDragState,
            left:-40,
            tooltipPlacement:"left",
          }}/>}

          {!inPreDragRowState && rowIndex > 0 && <div style={{
            height:'100%',
            position:'absolute',
            width:20,
            top:0,
            left:0,
            pointerEvents:(inDragRowState?'none':'unset')
          }}>
            <RowAdder {...{
              pointerEvents:(inDragRowState?'none':'unset'),
              tooltip:'Add Row Here',
              tooltipPlacement:'left',
              figurePanelId,rowIndex,
              side:'left',level:'top',
              alwaysVisible:tableOutlineShowing,
            }} />
          </div>}
        </td>}

        {row.map( (cell,iiCell) => {
          let cellData = cell.data;
          let { style, value } = cellData;

          let displayPlaceholder = false;



          let noStyle = Object.keys(style).length === 0 ;

          if( cellData.placeholder && noStyle ){
            if( !isCellSelected ){
            //  debugger;
            }

            let cellIsSelected = isCellSelected([cell.y,cell.x],selectedCells);

            if( !cellIsSelected ){
              displayPlaceholder = !value;
            }else{
              displayPlaceholder = (
                value?temporaryCellValue === '':!temporaryCellValue
              )
            }


            if( displayPlaceholder ){
              style =   placeholderStyle();
            }
          }

          let cellWidth = getCellWidth(cell.x,cell.w,currentWidths||widths)

          if( editable!== false && isCellSelected([cell.y,cell.x],selectedCells) ){
            if( temporaryCellValue ){
              value = temporaryCellValue
            }
          }


          let defaultStyle = {
            position:'relative',
            /*
                          webkitBoxSizing:'border-box',
                          mozBoxSizing:'border-box',
                          boxSizing:'border-box',
                          */

            textAlign:'center', 
            height:'100%',
            padding:0,
            margin:0,
            fontSize:12,
            userSelect:'none',
            width:cellWidth,
            verticalAlign:'middle',
            minHeight:MINIMUM_ROW_HEIGHT,
            maxWidth:cellWidth
          }

          let computedStyle = {
            ...defaultStyle,
            ...style,
          }

          if( computedStyle.borderTop && style.borderTop ){
            computedStyle.borderTop = style.borderTop
          }


          return (


            <GridCell 
              {...{
                editable,
                verticalImageResizeTranslation,
                displayPlaceholder
              }}
              manualAnnotationGridDispatch={manualAnnotationGridDispatch}

              setCropIdsBeingDragged={setCropIdsBeingDragged}
              cropIdsBeingDragged={cropIdsBeingDragged}

              figurePanelId={figurePanelId}
              setDemoSelected={setDemoSelected}
              demoSelected={demoSelected}
              isDraggingCrop={isDraggingCrop}

              iiCell={iiCell}
              cell={cell}
              multiselect={multiselect}
              isCellSelected={isCellSelected}
              selectedCells={selectedCells}
              computedStyle={computedStyle}
              currentWidths={currentWidths}
              widths={widths}
              tableOutlineShowing={tableOutlineShowing}

              makeCellSelection={makeCellSelection}

              key={cell.y+"-"+cell.x+"-"+cell.i}
              textBounds={allTextBounds[iiCell]}
              rotatedRectHeights={rotatedRectHeights[iiCell]}
              rowIndex={rowIndex}

              cellLocation={[cell.y,cell.x]}
              temporaryCellValue={temporaryCellValue}
              selected={editable!==false && isCellSelected([cell.y,cell.x],selectedCells)} 
              contents={cell.data} _id={cell.i} 
              pxWidth={getCellWidth(cell.x, cell.w, currentWidths||widths)}
              cellDragEntered={cellDragEntered}
              setDontUnselectCells={setDontUnselectCells}
              setMouseIsDown={setMouseIsDown}
              setCellDragEntered={setCellDragEntered}
              onCropDrop={onCropDrop}
              setCellResizeInfo={setCellResizeInfo}
              setCurrentWidths={setCurrentWidths}
              cellResizeInfo={cellResizeInfo}

            /> 
          )
        })}

        {editable!==false && <td 
          class={""+SCREENSHOT_IGNORE}
          style={{
            verticalAlign:'top',
            //background:'pink',
          }}>

          {true && <DragRowButton {...{
            manualAnnotationGridDispatch,
            dragRowMargin,
            rowDragDispatch,
            rowIndex,
            rowDragState,
            right:-10,
            tooltipPlacement:"right",
          }}/>}


          {rowIndex > 0 && <div style={{
            pointerEvents:(inDragRowState?'none':'unset'),
            position:'relative'}}>
            <RowAdder 
              {...{tooltip:'Add Row Here',tooltipPlacement:'right',
                alwaysVisible:tableOutlineShowing,

                pointerEvents:(inDragRowState?'none':'unset'),

              }}
              level={"top"}
              side={'right'} rowIndex={rowIndex} figurePanelId={figurePanelId}/>
          </div>}
        </td>}


      </tr>

      <SpacerRowForRotatedText height={downSpacing} position={"bottom"} rowIndex={rowIndex}/>

      <RowSpacingComponent {...{
        colSpan, rowSpacing
      }}/>


      {isDraggingCrop && (
        <>

          <CropDropRow 
            figurePanelId={figurePanelId}
            setCropIdsBeingDragged={
              setCropIdsBeingDragged
            }
            cropIdsBeingDragged={cropIdsBeingDragged}
            cellDragEntered={cellDragEntered}
            setCellDragEntered={setCellDragEntered}
            rowIndex={rowIndex}/>
          <tr style={{height:rowSpacing}}></tr>
        </>
      )}

      {isLastRow && (rowDragState?.dragging !== undefined && rowDragState?.spaceAt===numRows && rowDragState?.dragging !== rowIndex) && <RowMoveDropRow 
        {...{
          dragRowLeftShift,
          totalDragRowWidth
        }}
        figurePanelId={figurePanelId}
        setCropIdsBeingDragged={
          setCropIdsBeingDragged
        }
        rowDragDispatch={rowDragDispatch}
        fromIndex={rowDragState.dragging}
        toIndex={numRows}
        rowIndex={rowIndex}
        cellDragEntered={cellDragEntered}
        setCellDragEntered={setCellDragEntered}
      />}
    </>
  )

}
