import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import A from './ActionCreators';

export default function AspectRatioEditor({ nodeId, templateId, aspect, dispatch }){
  let defaultW = aspect.w;
  let defaultH = aspect.h;

  let [ w, setW ] = useState(defaultW);
  let [ h, setH ] = useState(defaultH);


  const onBlur = () => {

    dispatch(A.setRegionExpansionNodeProperties({
      templateId,
      nodeId,
      properties:{
        dimension:{ w,h }
      }
    }))
  }

  return (

    <Tooltip title={"Aspect Ratio"}>
      <div class="full-width">


        <div class="full-width sg-row" style={{
          justifyContent:'center',
          //border:'3px solid blue',
        }}>
          <div style={{
            position:'relative'
          }} class="sg-col">
            <div style={{
              position:'absolute',
              top:0,
              left:'50%',
              color:'#aaa',
              fontSize:8,
              transform:'translate(-50%,-100%)',
            }}>W</div>
            <input 
              onChange={e => setW(e.target.value)}
              onBlur={onBlur}
              class="no-spinner" style={{width:20, fontSize:10,
                border:'1px solid #eee',
              }} 
              value={w}
              type="number" min={1} max={9} step={1}/> 
          </div>

          <div>&nbsp;:&nbsp;</div>
          <div style={{position:'relative'}} class="sg-col">

            <div style={{
              position:'absolute',
              top:0,
              left:'50%',
              color:'#aaa',
              fontSize:8,
              transform:'translate(-50%,-100%)',
            }}>H</div>

            <input 
              onChange={e => setH(e.target.value)}
              onBlur={onBlur}
              class="no-spinner" style={{width:20, fontSize:10,
                border:'1px solid #eee',
              }} 
              value={h}
              type="number" min={1} max={9} step={1}/> 
          </div>

        </div>
      </div>
    </Tooltip>
  )

}
