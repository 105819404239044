import { argmin } from './utils';
const out = arg => console.log(JSON.stringify(arg))



export function isLsInsideOtherLs(innerLs, innerLsHeight,outerLs, outerLsHeight){

  let innerLsPoints = getPointsFromLs(innerLs,innerLsHeight);
  let outerLsPoints = getPointsFromLs(outerLs,outerLsHeight);

  let pivotPoint = innerLsPoints[0];

  let lineSegmentSlope = slope(...outerLs);
  let perpSlope = -1/lineSegmentSlope;
  let angle = -Math.atan(perpSlope);


  let outerRotatedPoints = rotatePointsAboutPoint({
    pointsList:outerLsPoints,
    angle,
    pivotPoint
  })

  let innerRotatedPoints = rotatePointsAboutPoint({
    pointsList:innerLsPoints,
    angle,
    pivotPoint
  })

  let minX;
  let minY;
  let maxX;
  let maxY;

  outerRotatedPoints.forEach(rPoint => {
    let [x,y] = rPoint;
    if( !minX ){
      minX = x;
    }else{
      minX = Math.min(minX,x)
    }


    if( !minY ){
      minY = y;
    }else{
      minY = Math.min(minY,y)
    }


    if( !maxY ){
      maxY = y;
    }else{
      maxY = Math.min(maxY,y)
    }


    if( !maxX ){
      maxX = x;
    }else{
      maxX = Math.min(maxX,x)
    }
  })


  return innerRotatedPoints.every(point => {

    let inX = minX <= point[0] && point[0] <= maxX; 
    let inY = minY <= point[1] && point[1] <= maxY;
    return inX && inY

  })

}


export function getIntermediaryPoint(ls,proportion){
  let vec = minus(ls[1],ls[0]);
  let point = add(ls[0] , scale(proportion,vec));
  return point;
}

export function vectorAngleRad(vec){
  let finalVec = vec;
  if( Array.isArray(vec[0]) ){
    //console.log("vec 0 is an aray");
    finalVec = minus(...vec);
  }
  return Math.atan(finalVec[1]/finalVec[0]);
}


export function sortByX(vec){
  return [...vec].sort((a,b) => a[0]-b[0])
}

export function sortByY(vec){
  return [...vec].sort((a,b) => a[1]-b[1])
}

export function shiftLineSegement(lineSegment, directionString){

  let sortedLs = sortByX(lineSegment);
  let unitLs = unit(minus(...sortedLs))
  let perp = unitPerp(minus(...sortedLs));


  let directionMap = {
    ArrowUp:scale(1,perp), 
    ArrowDown:scale(-1,perp), 
    ArrowLeft:scale(1,unitLs), 
    ArrowRight:scale(-1,unitLs)
  };

  let toAdd = scale(
    0.001,directionMap[directionString]
  );


  let shiftedLs = sortedLs.map( point => add( point, toAdd ) ); 

  return shiftedLs;

}

export function vectorAngleDegrees(vec){
  //console.log("vec input: " + JSON.stringify(vec));
  return vectorAngleRad(vec) * 180 / Math.PI ; 
}

export function getRectCentroid(bounds){

  let {left,width,top,height} = bounds;

  left = 0;
  top = 0;

  let centroidX = left + 0.5 * width;
  let centroidY = top + 0.5 * height;

  return [centroidX,centroidY];

}

export function midpoint(v1, v2){
  return scale(1/2,add(v1,v2));
}


export function unit(vec){


  let origin = Array(vec.length).fill(0)
  let norm = distance(vec,origin)
  return scale(1/norm,vec);

}

export function createLineSegmentWithDirection(v1,v2,length){

  let direction = minus(v2,v1);
  let unitDirection = unit(direction);
  let scaledDirection = scale(length,unitDirection);
  let p2 =  add(v1, scaledDirection);

  return [v1,p2];

}

function validateIsLine(line){
  let dim = null;
  for(let point of line){
    if(!Array.isArray(point)){
      throw Error("Line elements must be points (arrays). Receied: " + JSON.stringify(line));
    }

    if(dim === null){
      dim = point.length;
    }else{
      if( point.length !== dim ){
        throw Error("All points in a line must have the samem dimension. Received: " + JSON.stringify(line));
      }
    }
  }
}



export function pointProjectionOntoLine(point,ls){

  validateIsLine(ls);

  let unitLs = unit(minus(ls[1],ls[0]));
  let vLs0ToPoint = minus(point,ls[0]);
  let dotProduct = dot(vLs0ToPoint,unitLs);
  let projectedPoint = add(ls[0],scale(dotProduct,unitLs));

  return projectedPoint;


}

export function distanceFromPointToLineSegment(point,ls){
  let lineDirection = minus(...ls);
  let lineUnitNormal = unitPerp(lineDirection);

  let vectorFromPointToAPointOnLineSegment = minus(point,ls[0]);

  let distance = dot(vectorFromPointToAPointOnLineSegment,lineUnitNormal);

  return distance;


}

export function pointsAreEqual(v1,v2){
  if(!v1.every){
    //////console.log(v1);
  }
  return v1.every((ele,ii) => ele === v2[ii]);
}

export function lineSegmentShiftedPerpendicularlyToPoint(point,ls){



  let lineDirection = minus(...ls);
  let lineUnitNormal = unitPerp(lineDirection);

  let distance = distanceFromPointToLineSegment(point,ls);

  let shiftingVector = scale(distance,lineUnitNormal);

  let shiftedSegment = ls.map( segPoint => 
    add(shiftingVector,segPoint) 
  );

  return shiftedSegment;

}

export function isPointProjectionOntoLineSegmentInLineSegment(
  pointToProject,
  lineSegmentPoints){

  //here we're just rotating the points about 
  //our mouse point
  //and seeing if our point


  let [p1Point,p2Point] = lineSegmentPoints;


  let lineSegmentSlope = slope(p1Point,p2Point);
  let perpSlope = -1/lineSegmentSlope;





  let angle = -Math.atan(perpSlope);

  //////console.log(angle * 180 / Math.PI);

  let pointsList = [p1Point,p2Point];
  let pivotPoint = pointToProject;


  let rotatedPoints = rotatePointsAboutPoint({
    pointsList,
    angle,
    pivotPoint
  })

  let yVals = rotatedPoints.map(p => p[1]);

  let pivotPointY = pivotPoint[1];

  let vals = [yVals[0], pivotPointY, yVals[1]];

  let sorted = vals.sort();

  let verdict = sorted[1] === pivotPointY;

  //////console.log(verdict);
  //////console.log("HELOOOOOO?????");

  return verdict; 

}


/**
 * Rotates by 90º unless clockwise = true is given,
 * in which case rotates by -90º
 **/
export function unitPerp(vec,clockwise){

  if( vec.length === 2 ){
    let [ux,uy] = unit(vec);
    if(!clockwise){
      return [-uy,ux];
    }else{
      return [uy,-ux];
    }
  }else{
    throw Error("unitPerp can only be applied to 2-dimensional vectors.");
  }
}

export function distance(v1, v2){

  if( v2 === undefined && v1.every(Array.isArray) ){
    //split up the first arg into two points
    v2 = v1[1]
    v1 = v1[0];
  }


  v2=v2||[0,0]


  let diff = minus(v1,v2);
  let normSquared = dot(diff, diff);
  let distance = Math.sqrt(normSquared);
  return distance;

}



function assertDefined(variable,name){
  if(variable === undefined ){
    throw Error(name + " is undefined.");
  }
}

function assertEqualLengths(v1,v2){
  if( !v1 ){
    throw Error("v1 is: " + v1);
  }
  if( !v2 ){
    throw Error("v2 is: " + v2);
  }
  if( v1.length !== v2.length ){
    throw Error("Unequal vector lengths: ("+v1.length +","+ + v2.length+")");
  }
}

function assertIsNumber(k,errorMessage){
  errorMessage = errorMessage || ("Provided number is "+k + ", cannot be NaN!");
  if(isNaN(k)){
    throw Error(errorMessage);
  }
}

export function add(v1, v2){
  assertEqualLengths(v1,v2);
  return v1.map((val,ii) => val + v2[ii]);
}

export function minus(v1, v2){
  try{
    assertEqualLengths(v1,v2);
    return v1.map((val,ii) => val - v2[ii]);
  }catch(e){
    throw Error(e.message + '; received ' + JSON.stringify({v1,v2}));
  }
}

export function dot(v1, v2){
  assertEqualLengths(v1,v2);
  let products = v1.map((val,ii) => val * v2[ii]);
  let sum = products.reduce((a,b) => a+b);
  return sum;
}

export function scale(k, v1){

  assertIsNumber(k,"The scalar you gave is `"+k+"`");
  if(v1 && !v1.map){
    throw Error(JSON.stringify(v1)+" does not have 'map");

  }
  let fun = vec => vec.map((val,ii) => val * k);
  if( v1 ){
    return fun(v1);
  }else return fun;

}

function rotatePoint( point, degrees ){
  let [x,y] = point;

  //////////console.log("degrees: " + degrees);

  let c = Math.cos(degrees);
  let s = Math.sin(degrees);

  let newX = x * c - y * s;
  let newY = x * s + y * c;

  return [newX, newY];
}

export function getPointsAsWidthProportions(points,bounds){

  return points
    .map(([x,y]) => scale(1/bounds.width, [x,y]));

}

export function getPointsFromProportions(proportionPoints,bounds,positionFixed){

  let originY = positionFixed ? bounds.top : 0;
  let originX = positionFixed ? bounds.left : 0;
  let width = bounds.width;

  return proportionPoints
    .map(([xProp,yProp]) => {

      return [
        xProp * width + originX, 
        yProp * width + originY
      ]
    })
}

export function rotatePointsAboutPoint({pointsList,angle,pivotPoint,angleDegrees}){



  let pointsRelativeToPivot = pointsList
    .map(point => minus(point,pivotPoint));


  let rotatedPoints = pointsRelativeToPivot
    .map(point => rotatePoint(point,angle))

  return rotatedPoints.map(point => add(pivotPoint,point));


}


export function rotatePointsAboutCentroid({pointsList, bounds,angle,angleDegrees}){

  assertDefined(pointsList,"pointList");
  assertDefined(bounds,"bounds");
  assertDefined(isNaN(angle)?angleDegrees:angle,"angle");


  let centroid = getRectCentroid(bounds);

  angle = !isNaN(angle)?angle:(angleDegrees * Math.PI / 180 )

  return rotatePointsAboutPoint({
    pointsList,
    angle,
    pivotPoint:centroid
  })

}

export function slope(p1,p2){
  return (p2[1]-p1[1])/(p2[0] - p1[0]);
}



const toRad = degree => {
  return Math.PI * 2 * degree / 360;
}

function getUnrotatedBounds(bounds,rotation,originalImageHeightProportion){

  /*
   We solve the equations:
    W = w * cos(t) + h * sin(t)
    H = h * cos(t) + h * cos(t)

    --> matrix solutions yield: (det = 1/cos(2t)

    w = (1/cos(2t)) * (W * cos(t) - H * sin(t))
    h = (1/cos(2t)) * (W * -sin(t) + H * cos(t))

    if cos(2t) is 0, then t is either 45º or -45º, so we use originalImageHeightProportion

    W = w * cos(t) + h * sin(t), h = w * k, k = originalImageHeightProportion
      = w * ( 1 + k ) * cos(t), // sin(45) = cos(45)
      = w * (1 + k) * 1/√2

    w = W * sqrt(2) / ( 1 + k )
    h = k * w;

*/

  let W = bounds.width;
  let H = bounds.height;

  let width;
  let height;

  if( [45,-45].includes(rotation) ){

    width = W * Math.sqrt(2) / (1 + originalImageHeightProportion ) 
    height = originalImageHeightProportion * width;

  }else{

    let theta = toRad(Math.abs(rotation))
    let det =  Math.cos(2 * theta);
    let c = Math.cos(theta);
    let s = Math.sin(theta)

    width = (W * Math.cos(theta) - H * Math.sin(theta)) / det;
    height = (H * Math.cos(theta) - W * Math.sin(theta)) /det;

  }

  let left = bounds.left + Math.abs(width - W) / 2;
  let top = bounds.top + Math.abs(height - H) / 2;

  return { left, top, width, height };

}

function assertIsLs(ls){
  if( ! ls.every( point => point.every( coord => !isNaN(coord) ) ) ){
    throw Error("ls assertion failed, given ls: " + JSON.stringify(ls));
  }
}

export function yFromXLineFunction(ls){

  assertIsLs(ls);

  let vec = minus(ls[1],ls[0]);
  let m = (ls[1][1]-ls[0][1])/(ls[1][0]-ls[0][0]);
  let x = ls[0][0];
  let y = ls[0][1];
  let b = y - m * x;

  let fun = (xpoint) => m * xpoint + b;
  fun.m = m;
  fun.b = b;
  return fun;

}

function lineIntersection(l1,l2){
  if(l1.m === undefined || l2.m === undefined){
    throw Error("lineIntersections requires object with m,b (slope, y-intercept) properties.\nReceived:\nl1: "+JSON.stringify(l1)+"\nl2:"+JSON.stringify(l2));
  }

  let linesAreParallelNotCoincident = l1.m === l2.m && l1.b !== l2.b;
  let linesAreCoincident = l1.m === l2.m && l1.b === l2.b

  if( linesAreCoincident ){
    return {x:0,y:l1(0)}
  }
  if( linesAreParallelNotCoincident ){
    return null;
  }

  let b2 = l2.b;
  let b1 = l1.b;
  let m1 = l1.m;
  let m2 = l2.m;

  let x = (b2 - b1) / (m1 - m2);
  let y = m1 * x + b1;

  return {x,y};

}

export function getFirstIntersectionInTheDirectionOfLs(ls,values){
  let intersections = getLineAndValuesIntersections(ls,values);
  if( intersections.length === 0 ){ return; }
  let indexOfIntClosestToP0 = argmin(intersections.map(int => Math.abs(int.x - ls[0][0])));

  let deltaX = ls[1][0] - ls[0][0];
  let signXDirection = deltaX/Math.abs(deltaX);

  return intersections[ indexOfIntClosestToP0 + signXDirection ];


  
}

export function getLineAndValuesIntersections(ls,values){

  // a comment

  let yOfX = yFromXLineFunction(ls);
  let indicesOfIntersection = values.map((xx,ii) => ii===values.length-1?false: (yOfX(ii) < xx) !== (yOfX(ii+1) < values[ii+1]) ? ii : false).filter(x => x!==false);


  let intPoints = indicesOfIntersection.map( xx => {

    let localLs = [[xx,values[xx]],[xx+1,values[xx+1]]];
    let lineFromValues = yFromXLineFunction(localLs);
    let intersection = lineIntersection(yOfX,lineFromValues);
    return intersection

  })

  return intPoints;

}

export function getPointsFromLs(lsToRender, height){

  let lsVec = minus(...lsToRender)


  let lsHalfPerp = scale(height/2,unitPerp(lsVec));

  let p0 = add(lsToRender[0],lsHalfPerp);
  let p1 = add(lsToRender[1],lsHalfPerp);
  let p2 = minus(lsToRender[1],lsHalfPerp);
  let p3 = minus(lsToRender[0],lsHalfPerp)

  let points = [ p0, p1, p2, p3 ]

  return points;


}




