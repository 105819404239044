import ItemCreator from './ItemCreator';
import RecordTypes, { DIRECTORIES } from './RecordTypes';
import { FILESYSTEM_NAME, FILESYSTEM_PARENT_DIRECTORY } from './Filesystem';

const emptyRecordTypeMap = () => Object.fromEntries(RecordTypes.map(type => ([type,{}]))); 

export default function createDefaultStateItems(args={}){

  const items = {
    data:emptyRecordTypeMap(),
    meta:emptyRecordTypeMap()
  }

  let { datatypeSpecificDirectoryRoots } = args;

  injectDefaultItemTypeRootDirectoryItemsIntoItems(
    items,
    datatypeSpecificDirectoryRoots
  );

  return items;

}

function injectDefaultItemTypeRootDirectoryItemsIntoItems(
  items,
  itemTypeRootDirectoryIdMap
){

    Object.entries(itemTypeRootDirectoryIdMap).forEach(([type,directoryId]) => {
    let { data, meta } = ItemCreator(undefined,{
      type:DIRECTORIES,
      _id:directoryId,
      [FILESYSTEM_NAME]:(type+"-root"),
      [FILESYSTEM_PARENT_DIRECTORY]:null
    });
      items.data[DIRECTORIES][directoryId] = data;
      items.meta[DIRECTORIES][directoryId] = meta;
  })

}
