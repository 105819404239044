import React from 'react';
import Constants from './Constants';
import ActionCreators from './ActionCreators';

import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SettingsIcon from '@material-ui/icons/Settings';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '@material-ui/icons/Home';

import TuneIcon from '@material-ui/icons/Tune';

import KitchenIcon from '@material-ui/icons/Kitchen';

import SvgIcon from '@material-ui/core/SvgIcon';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import LocalBarIcon from '@material-ui/icons/LocalBar';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';


const drawerWidth = 240;

let MenuItems = {
  HOME:'Home',
  EXPERIMENTS:'Experiments',
  SETTINGS:'Settings',
  INVENTORY:'Inventory',
  TEMPLATES:'Templates',
  BUFFERS:'Buffers & Solutions',
  PROCEDURES:'Procedures',
  PREVIEW:'Previews',
  HELP:'Help',
  DATA:'Data',
  FIGURES:'Figures & Panels',
}

function IconByText({text}){
  let map = {
    'Data':<InsertDriveFileOutlinedIcon/>,
    'Figures & Panels':<PermMediaOutlinedIcon/>,
    'Experiments':<BiotechIcon/>,

    'Home':<HomeIcon/>,
    'Recently Opened':<AccessTimeIcon/>,
    'Projects':<FolderOpenIcon/>,
    'Settings':<SettingsIcon/>,
    'Inventory':<KitchenIcon/>,
    'New Western Blot':<AddIcon />,
    'Templates':<TuneIcon/>,
    'Buffers & Solutions':<LocalBarIcon/>,
    'Procedures':<FormatListNumberedIcon/>,
    'Help':<HelpOutlineIcon/>
  }

  return map[text] || null;;

}

function actionByText(text,setMode, setOpen){
  let sceneMap = {
    [MenuItems.FIGURES]:'FIGURES_AND_PANELS',
    [MenuItems.DATA]:Constants.DATA_SCENE,
    [MenuItems.PREVIEW]:Constants.PREVIEW_SCENE,
    [MenuItems.HOME]:Constants.HOME_SCENE,
    [MenuItems.EXPERIMENTS]:Constants.EXPERIMENTS_SCENE,
    [MenuItems.SETTINGS]:Constants.RESULT_COMPARISON_PAGE,
    [MenuItems.INVENTORY]:Constants.INVENTORY_SCENE,
    [MenuItems.BUFFERS]:Constants.WESTERN_RECIPE_CREATION_SCENE,
    [MenuItems.PROCEDURES]:Constants.WESTERN_PROCEDURE_CREATION_SCENE,
    [MenuItems.TEMPLATES]:Constants.WESTERN_TEMPLATE_CREATION_SCENE,
    [MenuItems.HELP]:Constants.HELP_SCENE,
  }

  let nextScene = sceneMap[text];
  setOpen(false);
  setMode(nextScene);
}


function MainDrawer({open,setOpen,setMode}){
  let theme = useTheme();
  const classes = useStyles();
  let dispatch = useDispatch();

  return (

    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={()=>setOpen(false)}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div> 
      <Divider /> 

      <List>
        {[MenuItems.DATA,MenuItems.FIGURES].map((text, index) => (
          <div 
            onClick={e=>{
              e.preventDefault();
              actionByText(text,setMode,setOpen);
            }}>
            <ListItem button key={text}>
              <ListItemIcon><IconByText text={text}/></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </div>
        ))}
      </List>
      <Divider />
      <List>
        {[MenuItems.EXPERIMENTS].map((text, index) => (
          <div 
            onClick={e=>{
              e.preventDefault();
              setOpen(false);
              setMode(Constants.EXPERIMENTS_SCENE);
              

            }}>
            <ListItem button key={text}>
              <ListItemIcon><IconByText text={text}/></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </div>
        ))}
      </List>
      <Divider />
      {null && (<><List>
        {['Recently Opened', 'Projects'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon><IconByText text={text}/></ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
        <Divider /></>)
      }

      <List>
        {['Inventory'].map((text, index) => (
          <div
            onClick={() => actionByText(text,setOpen,setOpen)}
          >
            <ListItem button key={text}>
              <ListItemIcon><IconByText text={text}/></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </div>
        ))}
      </List>
      <Divider />

      <List>
        {[
          MenuItems.BUFFERS,
          MenuItems.PROCEDURES,
          MenuItems.TEMPLATES
        ].map((text, index) => (
          <div
            onClick={()=>actionByText(text,setOpen,setOpen)}
          >
            <ListItem button key={text}>
              <ListItemIcon><IconByText text={text}/></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </div>
        ))}
      </List>
      <Divider />

      <List>
        {['Previews','Settings','Help'].map((text, index) => (
          <div onClick={()=>actionByText(text,setOpen,setOpen)}>
            <ListItem button key={text}>
              <ListItemIcon><IconByText text={text}/></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </div>
        ))}
      </List>

      <Divider />

      <List>
        {['Logout'].map((text, index) => (
          <div
            onClick={()=>dispatch(ActionCreators.tryLogout())}
          >
            <ListItem button key={text}>
              <ListItemIcon><IconByText text={text}/></ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </div>
        ))}
      </List>


    </Drawer>
  )

}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function BiotechIcon(){
  return (
    <svg enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M7,19c-1.1,0-2,0.9-2,2h14c0-1.1-0.9-2-2-2h-4v-2h3c1.1,0,2-0.9,2-2h-8c-1.66,0-3-1.34-3-3c0-1.09,0.59-2.04,1.46-2.56 C8.17,9.03,8,8.54,8,8c0-0.21,0.04-0.42,0.09-0.62C6.28,8.13,5,9.92,5,12c0,2.76,2.24,5,5,5v2H7z"/><path d="M10.56,5.51C11.91,5.54,13,6.64,13,8c0,0.75-0.33,1.41-0.85,1.87l0.59,1.62l0.94-0.34l0.34,0.94l1.88-0.68l-0.34-0.94 l0.94-0.34L13.76,2.6l-0.94,0.34L12.48,2L10.6,2.68l0.34,0.94L10,3.97L10.56,5.51z"/><circle cx="10.5" cy="8" r="1.5"/></g></g></svg>
  )
}




export default MainDrawer; 

