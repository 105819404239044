import { useDispatch } from 'react-redux';
import A from './ActionCreators';
export default function TextRowAdder({figurePanelId,rowIndex}){

  let dispatch = useDispatch();

  return (

    <div class={`sg-row`} 
      style={{justifyContent:'center',
      }}>
      <div 

        onClick={() => {
          dispatch(A.insertRow({figurePanelId, rowIndex:0}))
        }}
        class="hoverable clickable">
        Add Row
      </div>
    </div>

  )

}
