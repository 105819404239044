import { getGlobalObject } from './versionConfig';

export default function readFilesIntoMemory(args){

  let isNotBrowser = typeof(window) === typeof(undefined)
  if( isNotBrowser ){

    let fs = require('fs');
    let buffer = require('buffer');
    let { filenames } = args;
    let urls = filenames.map(name => {
      let file;
      let dir = "files/"; 
      let path = dir+name

      try{
        file = fs.readFileSync(path);
      }catch(e){
        console.red("Error while reading '"+path+"'.");
        console.red("Files in dir are: "+ fs.readdirSync(dir));
        throw e;
      }

      let buff = new Buffer(file, 'base64');
      let base64Data = buff.toString('base64');
      let imageUrlPrefix = 'data:image/png;base64,'
      let imageDataString = imageUrlPrefix + base64Data;

      let blob = new buffer.Blob([imageDataString],{type:'image/png'});

      const objectURL = URL.createObjectURL(blob);

      return objectURL;

    });

    return urls;
  }else{
    throw Error("Shouldn't be reading files into memory with this method if we're not on CLI.");
  }


}
