import Navbar from './Navbar';
import { useState } from 'react';

const options = [ "channels","structure","regions","labels","scalebars"].map(_id => ({
  _id, label:_id.slice(0,1).toUpperCase() + _id.slice(1)
}))


export default function QuickMicroscopyTemplateForm(){

  const [selectedSection,setSelectedSection] = useState("channels");

  return (
    <div>
      <Navbar options={options} selected={selectedSection} onSelect={setSelectedSection} spread={false} />
    </div>
  )
}
