import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import LoginSettings from './LoginSettings';
import SubscriptionSettings from './SubscriptionSettings';
import HierarchyList from './HierarchyList';
import ReferralPromotionInfo from './ReferralPromotionInfo';

const order = [
  'login',
  'subscription'
]

export default function AccountSettingsModal({closeModal,args}){

  // categories:
  // security
  // subscription

  const subMenus = [
    "Login",
    "Subscription",
    "Promotions",
    "Support",
  ]

  let { defaultSubmenu } = args || {};

  const [selectedSection,setSelectedSection] = useState(defaultSubmenu || subMenus[0]);


  let selectionContent = ({
    Login:<LoginSettings/>,
    Subscription:<SubscriptionSettings/>,
    Promotions:(<div style={{
      margin:30,
      display:'flex',
      //flexFlow:'column',
      //justifyContent:'center',
      //alignItems:'center',
      //border:'1px solid blue',
      width:'100%',
      }}>
      <div>
        <ReferralPromotionInfo/>
      </div>
      </div>
    ),
    'Support':(

      <div class="fair-padding full-width" style={{
        //border:'1px solid black'
      }}>
        <div 
          class="alert alert-info bold"
        >
          Need support? Contact <a href="mailto:support@sciugo.com" target="_blank;">support@sciugo.com</a>.
        </div>
      </div>
    ),
  })[selectedSection];


  
   
  return (
    <Modal  modalName={"accountSettings"} size="lg" show={true} onHide={closeModal}>

      <Modal.Header style={{background:'#fff'}}>
        <div style={{
          width:'100%',
          fontSize:16,
          fontWeight:'bold',
        }} class="sg-row">
          User Settings
        </div>
      </Modal.Header>
      <Modal.Body style={{
        //border:'3px solid magenta',
        
        overflow:'hidden',
        minHeight:0,
        position:'relative',
        margin:0,
        padding:0,
      }}>

          <div style={{
            height:'85vh',
          }} class="sg-row">
            <div style={{
              width:200,
              borderRight:'1px solid #bbb',
              height:'100%',
              boxShadow:'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
              zIndex:20,
            }}>
              {subMenus.map(sub => (
                <div
                  item={"accountSettingsSubmenu"}
                  selectedStatus={(sub === selectedSection)?"selected":""}
                  subMenu={sub}
                  style={{
                    borderBottom:'1px solid #ccc',
                  }}
                  onClick={() => setSelectedSection(sub)} class={
                    "clickable hoverable small-padding " + (sub === selectedSection ? "selected" : "")
                  }>
                {sub}
                </div>
              ))}
            </div>


            {selectionContent}

          </div>


            
      </Modal.Body>


    </Modal>
  )
}
