import { DndProvider } from "react-dnd";
import { HTML5Backend, getEmptyImage } from "react-dnd-html5-backend";
import FilesystemDragLayer from './FilesystemListDragLayer';
import FilesystemHeader from './FilesystemHeader';

import { FilesystemItemHoverContext, 
  //FilesystemDragContext, 
  FilesystemColumnWidthContext } from './Contexts';
import FilesystemList from './FilesystemList';
import { DIRECTORIES } from './RecordTypes';
import { useRef, useState } from 'react';

import PanelPreview from './PanelPreview';
import usePresentSelector from './usePresentSelector';
import G from './Getters';


let defaultColumnWidths = [150,80,80];

const thumbnailPreviewDimension = 200;


export default function FilesystemListContainer({

  contextMenuInfo,
  setContextMenuInfo,

  filesystemContainer,

  activelyRenamingFilesystemItem,
  setActivelyRenamingFilesystemItem,


  columns,
  topLevelDirectoryId,

  dropTargetId,
  setDropTargetId,

}){




  const state = usePresentSelector(state => state);

  if( activelyRenamingFilesystemItem && G.isArchived(state,{_id:activelyRenamingFilesystemItem}) ){
    setActivelyRenamingFilesystemItem(null);
  }

  const [ sortBy, setSortBy ] = useState({sortBy:"lastEditedDate", descending:true});


  const fsBounds = useRef();
  const [ filesystemItemHovered, setFilesystemItemHovered ] = useState();
  const [ thumbnailBounds, setThumbnailBounds ] = useState();

  const onFilesystemItemHoverChange = (arg) => {
    let { enter, leave, rect } = arg;
    let item = enter || leave;
    let { _id, type, itemType } = item;
    if( type !== 'figurePanels' && itemType !== 'figurePanels' ){
      return;
    }

    if( enter ){
      setFilesystemItemHovered(_id);
      setThumbnailBounds(rect);
    }else if( leave && _id === filesystemItemHovered ){

      setFilesystemItemHovered(undefined);
      setThumbnailBounds(undefined);
    }
  }





  const [ columnMinWidths, setColumnMinWidths ] = useState(defaultColumnWidths);

  const onColumnSizeChange = (columnIndex, newSize) => {
    //alert("CHANGING SOMETHING...");
    let newColumnWidths = columnMinWidths.map((x,ii) => {
      return ii===columnIndex ? newSize:x
    });
    setColumnMinWidths(newColumnWidths);
  }

  if( thumbnailBounds ){
    //console.log({thumbnailBounds});
  }else{
    //console.log("NO THUMBNAIL BOUNDS!");
  }


  return (

    <div ref={el => {
      if( el ){
        fsBounds.current = el.getBoundingClientRect();
        //console.log({fsBounds:el.getBoundingClientRect()});
      }
    }}
      class="sg-col"
    style={{
      //border:'2px solid blue',
      flex:1,
      overflow:'scroll',
    
      //background:'green',

    }}>
      {false && <>
        <div>{JSON.stringify(sortBy)}</div>
        <div>{JSON.stringify(columnMinWidths)}</div>
      </>}

      {false && thumbnailBounds && <div style={{
        zIndex:1000,
        //pointerEvents:'none',
        position:'fixed',
        //border:'1px solid red',
        left:thumbnailBounds.left + thumbnailBounds.width,
        top:thumbnailBounds.top + thumbnailBounds.height/2 - thumbnailPreviewDimension/2,
        width:thumbnailPreviewDimension + 2 * 10,
        height:thumbnailPreviewDimension + 20,
        display:'flex',
        //justifyContent:'center',
      }}>
        <div style={{position:'relative'}}>
          <PanelPreview key={filesystemItemHovered} {...{figurePanelId:filesystemItemHovered,
          //width:thumbnailPreviewDimension, 
          title:false, editable:false
          }}/>
        </div>

      </div>}

      <DndProvider backend={HTML5Backend}>
        <FilesystemItemHoverContext.Provider value={{filesystemItemHovered,onFilesystemItemHoverChange}}>
                      <FilesystemColumnWidthContext.Provider value={{onColumnSizeChange,columnMinWidths}}>
              {true && <FilesystemHeader {...{columns, columnMinWidths,
                setSortBy,
                sortBy,
                onColumnSizeChange,
              }}/>}
              <FilesystemList {...{
                treePath:[],
                filesystemContainer,
                setActivelyRenamingFilesystemItem,
                activelyRenamingFilesystemItem,
                contextMenuInfo,
                setContextMenuInfo,
                treeLevel:0,
                _id:topLevelDirectoryId,
                itemType:DIRECTORIES,
                sortInfo:sortBy,

                dropTargetId,
                setDropTargetId
              }}/>
            </FilesystemColumnWidthContext.Provider>


            <FilesystemDragLayer/>
        </FilesystemItemHoverContext.Provider>
      </DndProvider>
    </div>

  )






}
