import { useState, useEffect, useRef } from 'react';
import useMutationObserver from './useMutationObserver';
import TutorialBoundingBox from './TutorialBoundingBox';
import A from './ActionCreators';
import { useDispatch, useStore } from 'react-redux';
import TutorialIntro from './TutorialIntro';
import TutorialMessage from './TutorialMessage';
import walkthrough from './WalkthroughOrder';
import TutorialNav from './TutorialNav';
import ConfirmExitTutorial from './ConfirmExitTutorial';
import { useSelector } from 'react-redux';




const doRectsIntersect = function(r1, r2){
  let res = !(r2.left > r1.right || 
    r2.right < r1.left || 
    r2.top > r1.bottom ||
    r2.bottom < r1.top);

  return res;
}




function requestPopupPosition({position,guideOutline, width, height}){
  //1. must be inside window
  //2. must not intersect with guideline items
  //  PROBLEM: it should be the INTERACTIVE guideline items,
  //  not the #redbeard 
  //  or the other thing surrounding the click-drag items.
  //  so we'll need to address that after.

  let [left,top] = position;
  let right = left + width;
  let bottom = top + height;


  let windowRight = window.innerWidth;
  let windowBottom = window.innerHeight;
  let insideWindow = (
    (0 <= left && right <= windowRight)
    &&
    (0 <= top && bottom <= windowBottom)
  );

  if( !insideWindow ){
    return false;
  }

  

  let guideLeft = guideOutline.left;
  let guideRight = guideOutline.left + guideOutline.width;
  let guideTop = guideOutline.top;
  let guideBottom = guideTop + guideOutline.height;

  let endpoints = Array.from(document.querySelectorAll('[tutorialrole=endpoint]')).map(x => x.getBoundingClientRect()); 
  if( endpoints.length > 0 ){

    guideLeft = Math.min(...endpoints.map(x => x.left)); 
    guideTop = Math.min(...endpoints.map(x => x.top)); 
    guideRight = Math.max(...endpoints.map(x => x.left + x.width))// - guideLeft;
    guideBottom = Math.max(...endpoints.map(x => x.top + x.height));

  }


  let RectA = { left:guideLeft, right:guideRight, top:guideTop, bottom: guideBottom };
  let RectB = { left, right, top, bottom }

  let messageIntersectsGuideline = doRectsIntersect(RectA,RectB)

  if(  messageIntersectsGuideline ){ 
    return false;
  }else{
  }

  //NO INTERSECTION WITH MUIPOPOVER:
  let muiPopoverClass = '.style-toolbar > .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded'
  let muiPopover = document.querySelector(muiPopoverClass);
  let popoverMessages = document.querySelectorAll('#tutorial-popup');

  let elementsToAvoid = [ muiPopover, ...popoverMessages ];

  let intersectsSomeElementToAvoid = elementsToAvoid.some(el => {
    if( el ){

      let elRect = el.getBoundingClientRect();
      let elLeft = elRect.left;
      let elRight = elRect.left + elRect.width;
      let elTop = elRect.top;
      let elBottom = elRect.top + elRect.height;

      let elBounds = { left:elLeft,right:elRight,top:elTop,bottom:elBottom}

      let elIntersect = doRectsIntersect(RectB, elBounds);
      return elIntersect;

    }
  })

  return !intersectsSomeElementToAvoid;

}

function computeErrorPopupPosition({position,guideOutline,tutorialState}){
  // propose
  let height = tutorialState?.message?.height || 75;
  let width = tutorialState?.message?.width || 300; 

  let oldX = position[0];
  let oldY = position[1];
 
  const extraBump = 20;
  const bigBump = 100;

  let proposedDeltas = [
    [0,0], // BELOW & RIGHT
    [-width,0], // BELOW & LEFT
    [0,-height], // ABOVE & RIGHT
    [-width,-height], // ABOVE & LEFT

    [-extraBump,0],
    [0,-extraBump],
  
    [-width -extraBump ,0],

    [0,-height-extraBump],
    [-width-extraBump,-height-extraBump],
   


    [extraBump, 0],
    [0, extraBump],

    [extraBump,extraBump],  

    [bigBump, 0],
    [0, bigBump],

    [bigBump,bigBump],  
    //[-width,0], 
    //[0,-height], // ABOVE & RIGHT
    //[-width,-height], // ABOVE & LEFT


  ]



  let minimumDistance = Number.POSITIVE_INFINITY;
  let minimumDistanceIndex;
  let posWithMinimumDistance;

  proposedDeltas.forEach(([x,y],ii) => {

    let [newX,newY] = [ x + position[0], y + position[1] ];

    let requestValue = requestPopupPosition({
      position:[newX,newY],
      guideOutline,
      width,
      height
    })

    if( requestValue ){

      let dx = (oldX - newX)**2
      let dy = (oldY - newY)**2

      let distance = Math.sqrt(dx + dy);
      if( distance < minimumDistance ){
        minimumDistance = distance;
        minimumDistanceIndex = ii;
        posWithMinimumDistance = [newX,newY];
      }
    }
  })

  return {
    position:posWithMinimumDistance || [0,0],
    background:(!posWithMinimumDistance && 'red')
  }

}

/*const setTutorialStateIndex = (dispatch,index,walkthrough) => {
  
}*/


export default function Tutorial({children}){



  const [ shouldCapture, setShouldCapture ] = useState(true);



  const [ refreshFlag, setRefreshFlag ] = useState(false);

  const [ tutorialStateIndex, setTutorialStateIndex ] = useState(0);



  let dispatch = useDispatch();

  /*
  useEffect(() => {

    dispatch(A.setTutorialState({ index:tutorialStateIndex, title:walkthrough[tutorialStateIndex].title }));

  },[tutorialStateIndex])
  */

  /*let { tutorialStateIndex, tutorialStateTitle } = useSelector(state => { return { 
    tutorialStateIndex:state.tutorialState.index,
    tutorialStateTitle:state.tutorialState.title,
  }});*/

  /*
  if( tutorialStateIndex === undefined ){
    if( !tutorialStateTitle ){
      tutorialStateIndex = 0;
    }else{
      tutorialStateIndex = walkthrough.findIndex(stage => stage.title === tutorialStateTitle);
    }
  }*/




  
  
  
  
  let store = useStore();

  let state = store.getState();

  let confirmExit = state.confirmExitTutorial === true;




  let tutorialState = walkthrough[tutorialStateIndex];


  if( tutorialState.title === 'LABEL_HEK293'
  || tutorialState.title === 'LABEL_HELA' ||
  tutorialState.selector === 'textarea[name=cellValueInput]'){

    //HACK TO MAKE SURE WE STAY FOCUSED
    //ON INPUT IN THESE STAGES
    let sel = tutorialState.selector;
    let el = document.querySelector(sel);
    el.focus();

  }



  let { selector } = tutorialState || {};

  const [ functionsToApplyOnRerender, setFunctionsToApplyOnRerender ] = useState([]);
  
  const [draggingMouse, setDraggingMouse] = useState(false);
  const [ errorPopup, _setErrorPopup ] = useState();
  const [ gotoNextStateOnClick, setGotoNextStateOnClick ] = useState(false);
  const [ gotoNextStateOnKeyUp, setGotoNextStateOnKeyUp ] = useState(false);

  const [ gotoNextStateOnRerender, setGotoNextStateOnRerender ] = useState(false);
  let [ guideOutline, setGuideOutline ] = useState();

  const setErrorPopup = (data,e) => {

    

    if( ! data ){
      _setErrorPopup(data);
      return;
    }


    let errorPopupPosition = data;
    if( guideOutline ){
      errorPopupPosition = computeErrorPopupPosition({position:data.position,guideOutline,tutorialState});
    }    


    _setErrorPopup({
      ...data,
      ...errorPopupPosition,
      tutorialState:tutorialState.title,
    });
  }

  
  const changeState = direction => {
    let nextStateIndex = tutorialStateIndex + direction;
    let nextStage = walkthrough[nextStateIndex];
    let { onEnterViaNav } = nextStage || {};

    setGotoNextStateOnClick(false);
    setGotoNextStateOnKeyUp(false);
    setGuideOutline(null);
    setErrorPopup(null);
    setTutorialStateIndex(nextStateIndex)//dispatch,nextStateIndex,walkthrough);
    
    if( !onEnterViaNav ){
    }else{
      onEnterViaNav(dispatch);
    }
    
  }

  let nextStateOnKeyUp = () => {
    setGotoNextStateOnKeyUp(true);
  }

  const nextStateOnRerender = () => {
    //setGotoNextStateOnKeyUp(true);
    setGotoNextStateOnRerender(true)
  }
  const nextState = () => { 
    if( tutorialState.beforeStateChange ){
      tutorialState.beforeStateChange(store);
    }
    changeState(1) 
  }
  window.__nextState = nextState;


  const prevState = () => { changeState(-1) }
  window.__prevState = prevState;

  useEffect(() => {
    if( gotoNextStateOnRerender ){
      setGotoNextStateOnRerender(false);
      nextState();
    }

    if( functionsToApplyOnRerender.length > 0 ){
      functionsToApplyOnRerender.forEach(fun => fun());
      setFunctionsToApplyOnRerender([]);
    }


  })



  const bodyRef = useRef(document.body);

  const draggingMouseRef = useRef(false);
  const wasMakingProgressBeforeMouseUpRef = useRef(false);

  
  let [ selectorElement, setSelectorElement ] = useState(null);

  

  const callback = (mutationList,observer) => {
    if( !guideOutline ){
      let reqElement = document.querySelector(selector);
      if( reqElement ){


        let elementBounds = reqElement.getBoundingClientRect();
        if( elementBounds.height === 0 ){
          return;
        }

        let guideBounds = {
          width:elementBounds.width,
          height:elementBounds.height,
          top:elementBounds.top,
          left:elementBounds.left,
        }

        setSelectorElement(reqElement);
        setGuideOutline(guideBounds);
      }else{

              }
    }
  }

  useMutationObserver(bodyRef, callback);

  

  const DRAW_LINE_STAGES = [
    'DRAW_BAND',
    'DRAW_BAND_1',
    'ADJUST_CROP_1',
    'ADJUST_CROP_2',
    'CREATE_MW_0',
    'RESIZE_CROP',
    //'MOVE_MW_CONTAINER',
  ]

  

  let isDragStep = tutorialState.lineStartRequirements && tutorialState.lineEndRequirements;


  function captureEvent(event){



    



    if( !tutorialState ){
      return;
    }

      

    let e = event.nativeEvent;
    if( e.key === 'Tab' ){
      e.preventDefault();
      e.stopPropagation();
      return;
    }


    if( !shouldCapture ){
      return;
    }



  
    if( e.type === 'mousedown' && e.button === 0){
      
      window.__mouseDown = true;
      window.lastMouseDownEvent = e;
      e.tutorialStateTitle = tutorialState.title;
    }
    if( e.type === 'mouseup' && e.button === 0){//  || e.type === 'keyup' ){
      window.__mouseDown = false;
      window.__downInCorrectDragRect = false;


      let startDiv = document.querySelector('[endpoint=start]');
      if( startDiv ){
        startDiv.style.opacity = 1;//'3px solid red';
      }

      let lastMouseDownEvent = window.lastMouseDownEvent;
      if( lastMouseDownEvent && tutorialState.title !== lastMouseDownEvent.tutorialStateTitle ){
        //then we want to just ignore this event...
      

        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }
    



    let tutorialNav = document.querySelector("#tutorial-nav")
    let exitTutorial = document.querySelector('#exit-tutorial');

    let allowClick = e.composedPath().find(pathElement => {
      return (
        pathElement === tutorialNav 
        || 
        pathElement === exitTutorial
      )
    })

    if( allowClick ){
      return;
    }

    if( e.type === 'click' && gotoNextStateOnClick ){
      nextState();
    }

    if( e.type === 'keyup' && gotoNextStateOnKeyUp ){
      nextState()
    }else{
    }

    if( isDragStep || DRAW_LINE_STAGES.includes(tutorialState.title) ){



      captureDrawBandEvent({e,tutorialState,guideOutline,setErrorPopup,draggingMouseRef, selectorElement, nextState, nextStateOnClick:() => setGotoNextStateOnClick(true),
        addFunctionToApplyOnRerender:(fun) => setFunctionsToApplyOnRerender([...functionsToApplyOnRerender,fun]),
        setRefreshFlag,
        refreshFlag,
      });
      return;
    }


    let { ignoreEventTypes, onEvent } = tutorialState
    if( ignoreEventTypes && ignoreEventTypes.includes(e.type) ){
      //if it's not a mouse click, allow everything to do what it needs to do?
      return;
    }

    if( onEvent ){
      onEvent({e,setErrorPopup,nextStateOnRerender,selector,nextStateOnKeyUp});
    }


    const defaultCapturedMouseEventTypes = ['click','contextmenu',
      'mousedown','mouseup'
    ]

    if( tutorialState.captureAllMouseEvents ){
      defaultCapturedMouseEventTypes.push(...[
        'mousedown','mouseup'
      ])
    }
    const isMouseEventCaptured = defaultCapturedMouseEventTypes.includes(e.type);

    if( e.composedPath && isMouseEventCaptured ){


      let requiredSelector = selector; 
      let element = document.querySelector(requiredSelector);

      let path = e.composedPath();

      debugger;


      if( !path.includes(element) ){

        e.preventDefault();
        e.stopPropagation();
        return;

      }else if( tutorialState.nextStateOnSelectorClick /*&& e.type === 'click'*/ ){
        if(  e.type !== 'click' ){
          //e.type === 'mousedown' || e.type === 'mouseup' ){
          e.preventDefault();
          e.stopPropagation();
          return;
        }

        let requiredModifiers = tutorialState.requiredModifierKeys || [];
        let modifierToEnglish = {
          shiftKey:'Shift',
        }

        let missingModifiers = requiredModifiers.filter(key => {
          return !e[key]
        })

        let haveMissingModifiers = missingModifiers.length > 0;
        if( haveMissingModifiers ){

          e.preventDefault();
          e.stopPropagation();
          
        }else{
          //setGotoNextStateOnRerender(true);
          //nextStateOnRerender();
          nextState();
        }


        
      }


      else if( tutorialState.nextStateOnSelectorContextMenu ){
        if( e.type !== 'contextmenu' ){
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        nextState();
      }
    }else{

      //console.log("we tried to stop this event!");


      if( tutorialState.nextStateOnSelectorContextMenu
        ||
        tutorialState.nextStateOnSelectorClick
      ){
        event.preventDefault();
        event.stopPropagation();

          e.preventDefault()
          e.stopPropagation();
        }
    }

  }

  function handleRender(){
    let element = document.querySelector(selector);

    if( !element ){
      setGuideOutline(null);
      setSelectorElement(null);
      setTimeout(()=> {
        setRefreshFlag(!refreshFlag)
      },500);
      return;
    }

    let elementBounds = element && element.getBoundingClientRect();

    let guideBounds = {
      width:elementBounds.width,
      height:elementBounds.height,
      top:elementBounds.top,
      left:elementBounds.left,
    }
    if( !guideOutline || (JSON.stringify(guideOutline) !== JSON.stringify(guideBounds)) ){



      setGuideOutline(guideBounds);
      setSelectorElement(element);

    }

    let interval = setInterval(() => {

      let qEl = document.querySelector(selector);
      let qElBounds = qEl && qEl.getBoundingClientRect();
      if( !qElBounds ){
        clearInterval(interval);
        return;
      }
      

      let qGuideBounds = {
        width:qElBounds.width,
        height:qElBounds.height,
        top:qElBounds.top,
        left:qElBounds.left,
      }

      if( window.__maxTutorialInterval===undefined){
        window.__maxTutorialInterval = interval;
      }else if( interval > window.__maxTutorialInterval ){
        window.__maxTutorialInterval = interval;
      }else if( interval < window.__maxTutorialInterval ){
        clearInterval(interval);
      }


      

      let boundsChanged = JSON.stringify(guideOutline) !== JSON.stringify(qGuideBounds);
      if( boundsChanged ){
        
        setGuideOutline(qGuideBounds);
      }else{

      }


    },100)

    return () => {
      clearInterval(interval);
    }



  }

  const [ refresh, setRefresh ] = useState(false);

  useEffect(handleRender,[tutorialStateIndex,guideOutline]);

  useEffect(() => {
    window.addEventListener('resize',handleRender);
    return () => window.removeEventListener('resize',handleRender);
  })


  let guideElements = <TutorialBoundingBox {...{
    draggingMouseRef,
    guideOutline,
    tutorialState, 
    isDrawLineEvent:(isDragStep || DRAW_LINE_STAGES.includes(tutorialState.title)),
    errorPopup,
    tutorialStateIndex,
    totalSteps:walkthrough.length

  }}/>;


  let selectorHole;
  if( guideOutline ){
    selectorHole = `M 0 0 h 10000 v 10000 h -10000 v -10000 z`;
    selectorHole += ` M ${guideOutline.left} ${guideOutline.top} v ${guideOutline.height} h ${guideOutline.width} v -${guideOutline.height} h -${guideOutline.width} z`
  }

  let errorPopupHole = '';
  if( errorPopup ){
    errorPopupHole = `M ${errorPopup.position[0]} ${errorPopup.position[1]} v 75 h 300 v -75 h -300 z`

  }

  let svgPath = selectorHole + ' ' + errorPopupHole;


  if( tutorialState.scene ){

    if( tutorialState.title === 'TUTORIAL_OUTRO' ){
      let guideLayer = document.querySelector(
        '#tutorial-guideline-wrapper'
      );


      if( guideLayer ){
        guideLayer.parentNode.removeChild(
          guideLayer
        )
      }

    }



    return tutorialState.scene(()=>changeState(1));
  }


  if( confirmExit ){
    return <ConfirmExitTutorial/>
  }


  let tutorialNav;
  if( process.env.NODE_ENV !== 'production' ){
    tutorialNav = <TutorialNav {...{
        shouldCapture,
        setShouldCapture,
        tutorialState,
        tutorialStateIndex,
      setTutorialStateIndex,//:(index) => setTutorialStateIndex(dispatch,index,walkthrough),
        setRefresh,
        refresh,
        prevState,
        nextState,
        walkthrough,
        guideOutline,
      }}/>
  }

  


  return (
    <div 
      layer={"tutorial"}
      stage={tutorialState.title}
      onChangeCapture={captureEvent}
      onMouseUpCapture={captureEvent}
      onMouseDownCapture={captureEvent}
      onClickCapture={captureEvent}
      onKeyUpCapture={captureEvent}
      onKeyPressCapture={captureEvent}
      onKeyDownCapture={captureEvent}
      onContextMenuCapture={captureEvent}

      style={{
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        position:'absolute',
        zIndex:0,
        //pointerEvents:'none',
      }}>
      {guideElements}
      {tutorialNav}
      {children}
      <svg style={{
        zIndex:20000,
        pointerEvents:'none',
        position:'fixed',
        top:0,
        left:0,
        width:10000,
        height:10000,
      }}>

        <path 
          style={{
            webkitBackdropFilter:'blur(10px)',
          }}
          filter="url(#tutorial-blur-filter)"
          d={svgPath} fill="black" opacity={0.3}>
                  </path>
      </svg>

      {true && errorPopup && <TutorialMessage {...{errorPopup}}/>}

    </div>
  );
}


function captureDrawBandEvent({e,tutorialState, guideOutline, setErrorPopup, draggingMouseRef, selectorElement, nextState, nextStateOnClick, 
  addFunctionToApplyOnRerender, setRefreshFlag, refreshFlag,
}){

 

  //let isMouseMove = e.type === 'mousemove';

  let { lineStartRequirements, lineEndRequirements } = tutorialState;


  

  //let requiredRect = guideOutline;

  let type = e.type;
  let isMouseDown = type === 'mousedown';
  let isMouseUp = type === 'mouseup';
  let isClick = type === 'click';

 

  if( (isClick && draggingMouseRef.current === false) || (e.button !== 0) ){
    e.preventDefault();
    e.stopPropagation();
    return;
  }



  let requiredRect;
  let endpoint;

  if( isMouseDown ){
    requiredRect = lineStartRequirements;
    endpoint = 'start';
  }else if( isMouseUp ){
    requiredRect = lineEndRequirements;
    endpoint = 'end';
  }



  let inBoundsRequiredRect = requiredRect && inOffsetRectBounds(e,guideOutline,requiredRect,endpoint);



  if( isClick && e.composedPath().includes( selectorElement ) ){
    /*if( !draggingMouseRef.current ){
      e.stopPropagation();
      e.preventDefault();
    }*/
    return;
  }


  let validClickPath = true;
  if( isMouseUp && tutorialState.isValidClickPath ){
    validClickPath = tutorialState.isValidClickPath(
      window.lastMouseDownEvent, e
    )
  }
  let inBoundsAndValidClickPath = inBoundsRequiredRect && validClickPath;


  if( inBoundsAndValidClickPath ){

    if( isMouseDown ){
      draggingMouseRef.current = true;
     
      //remove the line direction animation
      

      if( lineStartRequirements.removeWhenMouseDown ){
        let startRect = document.querySelector('[endpoint=start]');
        if( startRect ){
          startRect.style.opacity = 0;//'3px solid lime';
        }
      }

      if( lineStartRequirements.removeArrowWhenMouseDown ){

        let lineDirectionAnimation = document.querySelector('#connecting-line-svg');
        if( lineDirectionAnimation ){
          lineDirectionAnimation.parentNode.removeChild(lineDirectionAnimation);
        }
      }


    }else if( isMouseUp && draggingMouseRef.current === true ){
      // this is needed here 
      // because if you rely on mouseup,
      // there is a click event that comes directly
      // after. But the state has already changed
      // and so we get a "click" error here.
      // So, we need to wait for the 'click' event 
      // to transition so we don't get the click error.
      nextStateOnClick();
      //nextState();
    }
  }else{
    if( draggingMouseRef.current && isClick ){
      return;
    }
    if( isMouseUp && draggingMouseRef.current ){

      

      window.dispatchEvent(new KeyboardEvent('keydown', {'key': 'Escape'}));


      draggingMouseRef.current = false;

      setErrorPopup({
        position:[e.clientX,e.clientY],
        text:(tutorialState.errorMessage || (<div><b>Click and drag</b> from <b>inside</b> the red boxes where the arrow starts to <b>inside</b> where the arrow ends.</div>))
      })
    }else{

      setErrorPopup({
        position:[e.clientX,e.clientY],
        text:(tutorialState.errorMessage || 

          (<div><b>Click and drag</b> from the <span style={{color:"red", fontWeight:'bold'}}>red box</span> where the arrow starts to where the arrow ends.</div>)
        ),
      })
    }





    if( tutorialState.onFail ){
      tutorialState.onFail(e);
    }
    if( tutorialState.applyOnRerenderUponFail ){
      addFunctionToApplyOnRerender(
        tutorialState.applyOnRerenderUponFail
      )
      setRefreshFlag(!refreshFlag);
    }


    if( !tutorialState.noStopPropagation ){
      e.stopPropagation();
    }
    if( !tutorialState.noPreventDefault ){
      e.preventDefault();
    }
  }

  if( isMouseUp ){
    draggingMouseRef.current = (false);
  }

}

function inOffsetRectBounds(e, outerRect, itemSpec,endpoint){

  let targetElement = document.querySelector(`[tutorialrole=endpoint][endpoint=${endpoint}]`)

  if( !targetElement ){
    return false;
  }

  let targetBounds = targetElement.getBoundingClientRect();

  let [mouseX,mouseY] = [e.clientX,e.clientY];

  let inXBounds = targetBounds.left <= mouseX && mouseX <= targetBounds.left + targetBounds.width;

  let inYBounds = targetBounds.top <= mouseY && mouseY <= targetBounds.top + targetBounds.height;

  let inBounds = inXBounds && inYBounds;

  let { selector, startMustIncludeSelector, illegalSelectors } = itemSpec;

  if( inBounds ){

    let noIllegalElement = false;
    let includesRequiredSelector = false;

    let path = e.composedPath();

    if( illegalSelectors ){
      let illegalInPath = illegalSelectors.some(illegalSel => path.some(pathEl => {

        let notDoc = pathEl !== document;
        let notWindow = pathEl !== window;
        let htmlEl = notDoc && notWindow;

        let matches = htmlEl && pathEl.matches(illegalSel);

        if( matches ){
          console.error("MATCHED WITH ILLEGAL: " + illegalSel);
        }

        return htmlEl && matches;
      }))
      noIllegalElement = !illegalInPath;
    }else{
      noIllegalElement = true;
    }

    if( startMustIncludeSelector ){
      let endpointElement = document.querySelector(selector);
      
      let inPath = path.includes(endpointElement);
      return inPath;
    }else{
      includesRequiredSelector = true;
    }


    
    return noIllegalElement && includesRequiredSelector;


  }

  return false;
  
}


