import C from './Constants';

export const IMAGE_READ_FAILURE = 'IMAGE_READ_FAILURE';
export const LOGOUT_WITH_UNSAVED_DATA = 'LOGOUT_WITH_UNSAVED_DATA';
export const FILESYSTEM_NAME_COLLISION_ON_MOVE = 'FILESYSTEM_NAME_COLLISION_ON_MOVE';


const DialogNames = {

  EXPORT_FIGURE_FAILED:'EXPORT_FIGURE_FAILED',

  SETTINGS:"SETTINGS",

  SUBSCRIBE_ACTION:'SUBSCRIBE_ACTION',

  CONFIRM_DELETE_IMAGE_ITEM:'CONFIRM_DELETE_IMAGE_ITEM',

  SUBSCRIBE:'SUBSCRIBE',

  CONFIRM_DELETE_ITEM:'CONFIRM_DELETE_ITEM',

  WITH_NOTIFICATION_MESSAGE:'WITH_NOTIFICATION_MESSAGE',

  IMAGE_READ_FAILURE:'IMAGE_READ_FAILURE',
  LOGOUT_WITH_UNSAVED_DATA:'LOGOUT_WITH_UNSAVED_DATA',
  FILESYSTEM_NAME_COLLISION_ON_MOVE:'FILESYSTEM_NAME_COLLISION_ON_MOVE',
  FILESYSTEM_NAME_COLLISION_ON_SET:'FILESYSTEM_NAME_COLLISION_ON_SET',
  ILLEGAL_CHARACTER_IN_CELL:'ILLEGAL_CHARACTER_IN_CELL',
  UNEXPECTED_SERVER_ERROR:'UNEXPECTED_SERVER_ERROR',
  IMAGE_ARCHIVE_WARNING:'IMAGE_ARCHIVE_WARNING',

  ERROR_PROCESSING_SERVER_RESPONSE:'ERROR_PROCESSING_SERVER_RESPONSE',

  SAVE_FAILED_ON_SERVER:'SAVE_FAILED_ON_SERVER',

  NO_INTERNET_CONNECTION:'NO_INTERNET_CONNECTION',

  PRESIGNED_POST_GENERATION_FAILURE:'PRESIGNED_POST_GENERATION_FAILURE',

  PLEASE_REPORT_BUG:'PLEASE_REPORT_BUG',

  CUSTOM:'CUSTOM',

  DRAGGING_IMAGE_SETS_WITH_MULTIPLE_IMAGES_UNSUPPORTED:'DRAGGING_IMAGE_SETS_WITH_MULTIPLE_IMAGES_UNSUPPORTED',




}

const Actions = {
  DISMISS_ACTION:{
    name:C.POP_MESSAGE
  },
  PUSH_SUBSCRIBE_MODAL:{
    setModal:true,
    modal:{
      name:"SETTINGS",
      args:{
        defaultSubmenu:"Subscription"
      }
    }
  }
}

const Buttons = {

  OK_BUTTON:{
    variant:'primary',
    text:'Okay',
    actions:[
      Actions.DISMISS_ACTION
    ]
  },
  SUBSCRIBE_BUTTON:{
    variant:'success',
    text:'Subscribe',
    actions:[
      Actions.DISMISS_ACTION,
      Actions.PUSH_SUBSCRIBE_MODAL
    ]
  }

}


let allNames = [
  ...Object.keys(DialogNames),
  ...Object.keys(Actions),
  ...Object.keys(Buttons)
];

let nameSet = new Set(allNames);
if( nameSet.size < allNames.length ){
  throw Error("We've got a duplicate name somehwere in a our DialogConstants.");
}



const DialogConstants = {
  ...DialogNames,
  ...Buttons,
  ...Actions
}

const handler = {
  get(target,prop,receiver){
    if( prop === '$$typeof' ){
      return;
    }

    //console.log("DIALOG PROXY (prop = " + prop+")");
    //console.log(target);

    if(!target[prop]){
      debugger;
      throw Error(`${prop} is not a property of DialogConstants.`);
    }

    return target[prop];
    
  }
}

const DialogExport = new Proxy(DialogConstants,handler);


export default DialogExport;
