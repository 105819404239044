import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MessageFeed from './MessageFeed';
import A from './ActionCreators';
import G from './Getters';
import pop from './sounds/Pop.mp3';
import useSound from 'use-sound';


export default function ChatWindow(){

  let dispatch = useDispatch();
  let [ play, { stop } ] = useSound(pop);


 
  let lastNewMessagesString = useRef("{}");


  let {threadId, hasNewMessages, newMessages, adminIsTyping
  } = useSelector(state => {
    let adminThread = Object.values(state.threads).find(x => x.participants.includes("admin"))
    let newMessages = G.getNewMessages(state);
    let threadId = adminThread && adminThread._id;
    let hasNewMessages = newMessages[threadId];
    return {
      adminIsTyping:state.adminIsTyping,
      threadId, 
      hasNewMessages,
      newMessages,
    }
  });

  /*useEffect(() => {
    play();
  })*/

  
  const changeMark = useRef(0);

  const [ open, setOpen ] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");


  useEffect(() => {
    let newMessagesString = JSON.stringify(newMessages);
    
    let soundRefStr = lastNewMessagesString.current;

    let stringDiff = (newMessagesString !== soundRefStr);
    let notEmptyMessages = newMessagesString!=="{}";

    if( stringDiff && notEmptyMessages ){
      play();
      setOpen(true);
      lastNewMessagesString.current = newMessagesString;
    }
  })



  const [typing,setTyping] = useState(false);


  

  const textAreaRef = useRef();
  const width = 250;


  const readMessages = () => {

    dispatch(A.updateThreadMessagesStatus({
              threadId,
              status:"read",
              unreadMessages:newMessages[threadId]
            }))

  }

  const notifyTyping = (val) => {
    if( typing !== val ){
      setTyping(val);
      console.log("Typing: " + val);
      dispatch(A.sendTypingNotification({typing:val}));
    }
  }


  return (
    <div class="text-selectable" style={{
      position:'fixed',bottom:0, right:0, 
      zIndex:500,
      }}>

      


      <div 
        action={"toggle-live-help"}
        class={hasNewMessages?"blinking":""}
        onClick={() => {
          setOpen(!open);

          if(!open && hasNewMessages ){
            readMessages();
          }


        }}
        style={{display:'flex', alignItems:'center',
          width,
          height:35, 
          background:'#fcfcfc',
          borderTopLeftRadius:10,
          borderTopRightRadius:10,
          border:'1px solid #ccc',
          cursor:'pointer',
          color:'black',

        }}>
        <div style={{width:10,height:10, 
          background:'#1bbf2b', 
        borderRadius:10, marginLeft:10}}/>

        <div style={{marginLeft:10, 
          fontWeight:'bold',
          
          fontSize:14
        }}>Live Help</div>
      </div>

      {open && (

        <div style={{ 
          height:500, 
          width,
          borderLeft:'1px solid #ccc',
          borderRight:'none',
          display:'flex',
          background:'white',
          flexDirection:'column',
          position:'relative',

        }}>
          <MessageFeed {...{threadId,typing:adminIsTyping}}/>
          <div class="flex-expand"/>
          <div style={{
            borderTop:'2px solid #ccc', 
            width:'100%',
            minHeight:30,
            position:'relative',
            display:'flex',
            alignItems:'center',
          }}>
            <textarea
              info={"live-help-message-box"}
              ref={textAreaRef}
              value={currentMessage}
              maxlength={500}


              onClick={() => {
                if( hasNewMessages ){
                  readMessages();
                }
              }}

              onChange={e => {

                changeMark.current += 1
                let curChangeMark = changeMark.current;

                setCurrentMessage(e.target.value)

                if( hasNewMessages ){
                  readMessages();
                }

                setTimeout(() => {
                  if( curChangeMark === changeMark.current ){
                    notifyTyping(false);
                  }
                },5000)

                if( !typing ){
                  notifyTyping(true);
                }



              }}

              onKeyPress={e => {
                if( e.key === 'Enter' ){
                  e.preventDefault();
                  notifyTyping(false);
                  if( e.target.value.trim().length === 0 ){
                    return;
                  }

                  dispatch(A.sendMessage({
                    to:"admin",
                    threadId,
                    message:{
                      text:e.target.value
                    }
                  }))

                  let el = textAreaRef.current
                  el.selectionStart = el.selectionEnd = 0;
                  setCurrentMessage("");
                }
              }}
              style={{
                //background:(typing?'pink':'unset'),
                //border:'1px solid blue', 
                border:'1px solid #ccc',
                width:'100%',
                height:'100%',
                overflow:'visible',
              }}>
            </textarea>
          </div>
        </div>
      )}
    </div>
  )
}

