import RecordTypes from './RecordTypes';
import { produce } from 'immer';
import queueUserConfigForSync from './queueUserConfigForSync';
import getUserConfigChanges from './getUserConfigChanges';
import { IDLE, SYNC_UPDATE } from './SyncConstants';
import G from './Getters';

function getChangedIdsByRecordType(diff){
  if( !diff ){
    return {}
  }

  

  let dataDiff = diff.data || {};
  let metaDiff = diff.meta || {};

  let recordContainer = {};

  for(let type of RecordTypes){
    let dataChangedIds = Object.keys(dataDiff[type]||{});
    let metaChangedIds = Object.keys(metaDiff[type]||{});

    let allIds = Array.from(new Set([
      ...dataChangedIds,
      ...metaChangedIds
    ]));

    if( allIds.length > 0 ){
      recordContainer[ type ] = allIds;

    }
  }

  return recordContainer;

}

function updateLastEditedMetadata(draft,type,id,now){
  draft.meta[type][id].lastEditedDate = now;
}

function updateRecordSyncStatus(draft,recordType,id){

  let syncStatusByRecordType = draft.syncStatus.records;


  if( !syncStatusByRecordType[recordType] ){
    syncStatusByRecordType[recordType] = {}
  }

  syncStatusByRecordType[recordType][id] = {
    ...(syncStatusByRecordType[recordType][id]||{}),
    ...{
      _id:id,
      syncKey:SYNC_UPDATE,
      status:IDLE
    },
    meta:true,
    data:true
  }
}

function shouldUpdateSyncStatus(oldState,newState){

  let movingInOrOutOfTutorial = G.isInTutorial(oldState) || G.isInTutorial(newState);
  return !movingInOrOutOfTutorial;

}

export default function ReduceBasedOnStateDiff(oldState,newState,diff){

  let changedIdsByRecordType = getChangedIdsByRecordType(diff);

  return produce(newState,draft => {
    if( getUserConfigChanges(oldState,newState) ){
      queueUserConfigForSync(draft);
    }
    let now = Number(Date.now());

    Object.entries(changedIdsByRecordType).forEach(entry => {
      let [ type, idList ] = entry;
      for( let id of idList ){
        if( !newState.data[type][id] ){
          continue;
        }
        updateLastEditedMetadata(draft,type,id,now);

        if( shouldUpdateSyncStatus(oldState,newState) ){
          updateRecordSyncStatus(draft,type,id);
        }
        
      }
    })
  })


}
