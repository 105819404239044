import { DIRECTORIES, FIGURE_PANELS, IMAGE_SETS } from './RecordTypes';
import { FILESYSTEM_PARENT_DIRECTORY } from './Filesystem';
import G from './Getters';
import { getResolvedItemTypeName } from './RecordTypes';

export default function onCreateItem(draft,data,meta,itemType,actionArgs){



  switch(getResolvedItemTypeName(itemType)){
    case IMAGE_SETS:
    case FIGURE_PANELS:
    case DIRECTORIES:{
      
      let filesystemParentDirectory = meta[FILESYSTEM_PARENT_DIRECTORY];
      let parentDir = G.getData(draft,{itemType:DIRECTORIES,_id:filesystemParentDirectory});
      let indexOfThisIdInParent = parentDir.children.findIndex(
        item => item._id === data._id
      );
      if( indexOfThisIdInParent === -1 ){
        parentDir.children.push({ type:itemType, _id:data._id })
      }
      break;
    }
    default:{}
  }

}
