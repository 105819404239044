export default function getVenn(obj1, obj2){


  let isArr1 = Array.isArray(obj1);
  let isArr2 = Array.isArray(obj2);

  if( isArr1 ^ isArr2 ){
    throw Error("Can't get venn on arrays when only one is array and other object isnt:\n\nObject 1: " + JSON.stringify(obj1) + "\nObject 2: " + JSON.stringify(obj2));
  }

  let valueGettingFunction = (isArr1 && isArr2) ? Object.values : Object.keys;
  
  

  let valuesToVenn = [obj1,obj2].map(valueGettingFunction).flat()
  let uniqueKeys = Array.from(new Set(valuesToVenn));

  const contains = (container,value) => {
    if( Array.isArray(container ) ){
      return container.includes(value);
    }else{
      return (value in container);
    }
  }

  let leftNotInRight = [];
  let rightNotInLeft = [];
  let inBoth = new Set();

  for(let val of valuesToVenn){
    let inOne = contains(obj1,val);
    let inTwo = contains(obj2,val);

    if( inOne && !inTwo ){
      leftNotInRight.push(val);
    }else if( !inOne && inTwo ){
      rightNotInLeft.push(val);
    }else{
      inBoth.add(val);
    }
  }

  return {
    leftNotInRight,
    rightNotInLeft,
    inBoth:Array.from(inBoth)
  }
}
