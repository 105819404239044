import Modal from 'react-bootstrap/Modal';
import DocumentationWrapper from './DocumentationWrapper';






export default function DocumentationModal({closeModal}){



  return (
    <Modal style={{
      //border:'3px solid red'
    }} size="lg" show={true} onHide={closeModal}>

      <Modal.Header style={{background:'#fff'}}>
        <div style={{width:'100%',
          fontSize:24}} class="sg-row">
          Tutorial
        </div>
      </Modal.Header>
      <Modal.Body style={{
        //border:'3px solid magenta',
        
        overflow:'hidden',
        minHeight:0,
        position:'relative',
        margin:0,
        padding:0,
      }}>

        <DocumentationWrapper/>
      </Modal.Body>


    </Modal>
  )
}
