import SciugoForm from './SciugoForm';

const schema = [
  {
    type:"textarea",
    _id:"wish",
    prompt:"What do you wish Sciugo could do that it currently does not?"
  },
  {
    type:"textarea",
    _id:"otherExpTypes",
    prompt:"What other experiment types do you use often in your research? (We need to decide the next step for expansion.)"
  },
  {
    type:"textarea",
    _id:"linkSampleAntibodyProtocolInfo",
    prompt:"What if you could link sample, antibody and/or protocol information to your images. Would this be useful? Explain your ideas or anything related."
  }
]

export default function HowToImprove(){

  return <SciugoForm schema={schema}/>


}
