import Navbar from './Navbar';
import { useState } from 'react';
import { FILESYSTEM_NAME, } from './Filesystem';
import { getImageSetAnnotationsAsFilesystemItems } from './FilesystemComponentFunctions';
import G from './Getters';
import { CREATION_DATE, LAST_EDITED_DATE } from './FieldConstants';
import FilesystemListContainer from './FilesystemListContainer';
//
import { IMAGE_SETS, ANNOTATIONS } from './RecordTypes';

import { FilesystemHierarchyContext, FilesystemStore } from './Contexts';
import ProteinTargetSearchContainer from './ProteinTargetSearchContainer';
import usePresentSelector from './usePresentSelector';


const options = [{_id:"filesystem",label:"Filesystem View"}, {_id:"list",label:"List View"}];

export default function CropInsertionSelector({cropIdsSelectedForInsertion, setCropIdsSelectedForInsertion, setMode }){

  const [ selectedNavbarOption, setSelectedNavbarOption ] = useState("list");


  const topLevelDirectoryId = usePresentSelector(state => G.getTopLevelDirectoryId(state,{itemType:"imageSets"}));

  let proteinSearchBarWidth = ({ filesystem:300, list:200 })[selectedNavbarOption];

  const isThisCropSelectedForInsertion = (_id) => {
    return cropIdsSelectedForInsertion.find(item => item.annotationId === (_id))
  }

  const cropFilesystemView =  (
    <div style={{
      height:'100%',
      width:proteinSearchBarWidth,
    }}>

      <FilesystemStore.Provider value={{
        isFilesystemItemSelected:(_id) => {
          return !!isThisCropSelectedForInsertion(_id)
        },
        onFilesystemItemEvent:({_id,event, item}) => {
          if( item.type === ANNOTATIONS ){

            //console.log(event.nativeEvent);

            if( event.nativeEvent.shiftKey ){
             // console.log("Shift key was down on selection!");

              let alreadySelected = isThisCropSelectedForInsertion(_id);
              if( alreadySelected ){
                setCropIdsSelectedForInsertion(cropIdsSelectedForInsertion.filter(x => x.annotationId === _id));
              }else{
                setCropIdsSelectedForInsertion([
                  ...cropIdsSelectedForInsertion,
                  {valueType:'crop',annotationId:_id}
                ])
              }
            }else{
              setCropIdsSelectedForInsertion([{valueType:'crop', annotationId:_id}])
            }
          }
        }

      }}>
        <FilesystemHierarchyContext.Provider value={{

          [IMAGE_SETS]:(state,_id) => {
            let children = getImageSetAnnotationsAsFilesystemItems(state,_id);
            let expandable = children.length > 0;
            return { children, expandable };
          },
          [ANNOTATIONS]:(state,_id) => {
            return {children:[],expandable:false}
          }

        }}>

          <FilesystemListContainer {...{
            filesystemContainer:"crops",
            topLevelDirectoryId,
            columns:[
              FILESYSTEM_NAME,
              LAST_EDITED_DATE,
            ]

          }}/>

        </FilesystemHierarchyContext.Provider>
      </FilesystemStore.Provider>

    </div>
  )

  const cropListView = (
        <div style={{
       //   border:'3px solid orange',
          height:'100%',
          minHeight:0,
          width:proteinSearchBarWidth,
        }}>
          <ProteinTargetSearchContainer {...{
            setMode,
            isThisCropSelectedForInsertion,
            isSomeCropSelectedForInsertion:cropIdsSelectedForInsertion?.length > 0,
            setCropIdsSelectedForInsertion,
            
          }}/>
        </div>
      )


  const selector = ({filesystem:cropFilesystemView,list:cropListView})[selectedNavbarOption];


  return (
    <div class="full-height sg-col" style={{
      //border:'3px solid red', 
      minHeight:0,
      position:'relative',
      }}>
      {true && <Navbar options={options} selected={selectedNavbarOption} onSelect={setSelectedNavbarOption}/>}
      {selector}
    </div>
  )

}
