export default function GridCellBorder({computedStyle, cell}){

  let { bottomBorderWidth, topBorderWidth, leftBorderHeight, rightBorderHeight, borderBottom, borderLeft, borderRight, borderTop, bottomBracketHeight, leftBracketWidth, rightBracketWidth, topBracketHeight, 
    borderThickness,
    borderColor="black",
    ...borderlessStyle } = computedStyle;


  const getScale = bracketNumber => {
    if( bracketNumber === undefined || bracketNumber === 0 ){
      return 1;
    }else if( bracketNumber < 0 ){
      return -1;
    }else return 1;
  }

  let leftBracketScale = getScale(leftBracketWidth);
  let rightBracketScale = getScale(rightBracketWidth);
  let topBracketScale = getScale(topBracketHeight);
  let bottomBracketScale = getScale(bottomBracketHeight);



  let computedThickness = borderThickness || 1;

  return (
    <div 
      class="crisp-edges"
      style={{
      pointerEvents:'none',
      width:'100%',height:'100%', 
      //background:'magenta',
      top:0,
      left:0,
      position:'absolute',
      //overflow:'hidden'
    }}>

      {borderLeft && <div 


        cell={cell?cell.join('-'):''} 
        border={"left"}

        style={{

          //shapeRendering:'crispEdges',

          height:(leftBorderHeight??100)+'%',
          width:Math.abs(leftBracketWidth),
          pointerEvents:'none',
          position:'absolute',
          zIndex:100,
          top:'50%',
          left:leftBracketWidth < 0 ? 1 : 0,
          transformOrigin:'0% 50%',
          transform:'translate(0%,-50%) scaleX('+leftBracketScale+')',
          //overflow:'none',
          //border:'transparent'
        }}>
        <div style={{height:'100%',
          width:computedThickness,
          position:'absolute',background:borderColor, top:0, zIndex:6,

          //background:'lime',
        }}/>
        <div style={{width:Math.abs(leftBracketWidth),
          left:0,
          top:0,
          height:1,
          position:'absolute',
          background:borderColor,
          
          zIndex:6
        }}/>

        <div style={{width:Math.abs(leftBracketWidth),left:0,bottom:0,height:1,position:'absolute',background:borderColor,zIndex:6}}/>

      </div>
      }

      {borderRight && <div 

        cell={cell?cell.join('-'):''} 
        border={"right"}

        style={{

          //shapeRendering:'crispEdges',

          height:(rightBorderHeight??100)+'%',
          width:Math.abs(rightBracketWidth||0),
          pointerEvents:'none',
          position:'absolute',
          zIndex:6,
          top:'50%',
          right:(rightBracketWidth < 0 ? 1 : 0),
          transformOrigin:'100% 50%',
          transform:'translate(0,-50%) scaleX('+rightBracketScale+')',
        }}> 

        <div style={{
          height:'100%',
          width:computedThickness,
          position:'absolute',
        background:borderColor,
          //background:'red',
        top:0, zIndex:6, right:0}}/>
        <div style={{width:Math.abs(rightBracketWidth),right:0,top:0,

          height:1,
          position:'absolute',background:borderColor,
          zIndex:6
        }}/>

        <div style={{width:Math.abs(rightBracketWidth),right:0,bottom:0,
          height:1,
          position:'absolute',background:borderColor,zIndex:6}}/>

      </div>

      }

      {borderBottom && <div 

        cell={cell?cell.join('-'):''} 
        border={"bottom"}
        style={{width:(bottomBorderWidth??100)+'%',

          //shapeRendering:'crispEdges',

          height:Math.abs(bottomBracketHeight),
          bottom:(bottomBracketScale < 0 ? 1 : 0),
          pointerEvents:'none',
          transformOrigin:'50% 100%',
          position:'absolute',
          zIndex:6,
          left:'50%',
          transform:'translate(-50%,0) scaleY('+bottomBracketScale+')'
        }}>

        <div style={{width:'100%',background:borderColor, height:computedThickness, position:'absolute',bottom:0}}/>
        <div style={{height:'100%', background:borderColor, left:0, width:1, position:'absolute'}}/>
        <div style={{height:'100%', background:borderColor, right:0, width:1, position:'absolute',}}/>


      </div>

      }



      {borderTop && <div cell={cell?cell.join('-'):''} border={"top"} style={{width:(topBorderWidth??100)+'%',
        //shapeRendering:'crispEdges',
        height:Math.abs(topBracketHeight),
        
        
        top:(topBracketScale < 0 ? 1 : 0),
        position:'absolute',
        pointerEvents:'none',
        transformOrigin:'50% 0%',
        left:'50%',
        transform:`translate(-50%,0) scaleY(${topBracketScale})`,
        zIndex:6,

      }}>
        <div style={{
          
          width:'100%',
          background:borderColor, 

          //background:'blue',
          height:computedThickness, 
          position:'absolute',}}/>
        <div style={{height:'100%', background:borderColor, left:0, width:1, position:'absolute'}}/>
        <div style={{height:'100%', background:borderColor, right:0, width:1, position:'absolute',}}/>


      </div>}
    </div>
  )
}

