import NumberToolbarItem from './NumberToolbarItem';
import ToggleToolbarItem from './ToggleToolbarItem';
import PopoverToolbarItem from './PopoverToolbarItem';
import BorderToolbar from './BorderToolbar';

import ColorToolbarItem from './ColorToolbarItem';
import A from './ActionCreators';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import AngleInput from './AngleInput';

const Space = () => {
  return <div class="thin-vertical-line" style={{marginLeft:'5px',marginRight:'5px',width:'1px'}}/>
}



export default function ToolbarItem({item,editorStyle,selectedFigureItems, unifiedGroup,setRetainInputFocus,className,scale, subToolbarDirection, 
  panelGlobalStyle ,
  figurePanelId,
}){

  const dispatch = useDispatch();

  let interior = null;

    
  scale = scale || '100%';

    if( item.subToolbar !== undefined ){
      let props = {editorStyle,selectedFigureItems,
        unifiedGroup,setRetainInputFocus,className, item, subToolbarDirection, panelGlobalStyle,figurePanelId};

      interior = <PopoverToolbarItem {...props}/>

    }
    else if( item === 'space' ){
      return <Space/>
    }else if(item.type === undefined){
      interior = (
        <ToggleToolbarItem item={item} className={className}
          selectedFigureItems={selectedFigureItems}
          unifiedGroup={unifiedGroup}
          panelGlobalStyle={panelGlobalStyle}
          figurePanelId={figurePanelId}
        />
      )
    }else if( item.type === "border" ){
      interior = (
        <BorderToolbar {...{
          item,
          editorStyle,
          selectedFigureItems,
          unifiedGroup,
          setRetainInputFocus,
          panelGlobalStyle,
          figurePanelId
        }}/>
      )
    }else if( item.type === 'color' ){

      interior = <ColorToolbarItem {...{
        item,
        className,
        selectedFigureItems,
        unifiedGroup,
        figurePanelId
      }}/>
    }else if(item.type ==='number'){
      interior = (
        <NumberToolbarItem 
          item={item}
          editorStyle={editorStyle}
          selectedFigureItems={selectedFigureItems}
          unifiedGroup={unifiedGroup}
          setRetainInputFocus={setRetainInputFocus}
          panelGlobalStyle={panelGlobalStyle}
          figurePanelId={figurePanelId}
        />
      )
    }else if( item.type === 'action' ){
      interior = (
        <div onClick={e => {
          console.log(item.action);
          dispatch(item.action);
        }}
          style={{padding:'4px',borderRadius:'5px'}}
          class={"hoverable toolbar-button"}
        >

          <Tooltip title={item.hin}>
          {item.icon}
          </Tooltip>
        </div>
      )
    }else if( item.type === 'angle' ){
      let disabled = 
        selectedFigureItems.length === 0;

      const onChange = value => {
        console.log("CHANGING CELL VALUE");
        dispatch(A.setCellsValue({
          figureItems:selectedFigureItems,
          figurePanelId,
          style:{
            [item.styleProperty]:value
          }
        }))
      }

      return (
        <div class="sg-row" style={{padding:4}}>

          <div onClick={e => {
            console.log(item.action);
          dispatch(item.action);
        }}
          style={{borderRadius:'5px'}}
          class={"toolbar-button"}
        >

          <Tooltip title={item.hint}>
          {item.icon}
          </Tooltip>
        </div>

          <AngleInput {...{
            value:-((unifiedGroup.style||{})[item.styleProperty] || 0),
            disabled,
            onChange
          }}/>
        </div>
      )
    }

  let fullItem = (
    <div menuItem={item.menuItem} id={item.id}>
      {interior}
    </div>
  )
  return fullItem;




  }
