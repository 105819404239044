import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import SyncIcon from '@material-ui/icons/Sync';
import A from './ActionCreators';

export default function ResetCropLanesButton({_id}){

  let dispatch = useDispatch();

  return (
    <button 
      onClick={() => {
        dispatch(A.resetLanesAndClearQuantification({annotationId:_id}))
      }}
      style={{
        fontSize:10,
        marginTop:5,
        transform:'translate(-25%,0)',
        borderRadius:3,
      }}>
      Reset
    </button>
  )

  return (
    <div 

      onClick={() => {

        
      }}
      class="sg-row dark-hoverable"
      style={{
        display:'flex',
        //border:'2px solid black',

        borderRadius:20,
        alignItems:'end', 
        position:'relative',
        cursor:'pointer',
        //height:'100%',
        marginRight:10,

        //width:50,
      }}>

      <Tooltip enterDelay={0} placement="top" title={"Reset Lanes"}>

        <SyncIcon style={{
          fontSize:19,
          transform:'rotate(40deg)',
          padding:0,
          margin:0,
          border:'2px solid black',
          borderRadius:10,
        }}/>
      </Tooltip>
    </div>
  )
}

