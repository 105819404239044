import { createStore, applyMiddleware } from 'redux';
import onQuantificationParametersChange from './onQuantificationParametersChange';
import Reducer from './UndoRedoReducer';
import DefaultState from './DefaultState';
import C from './Constants';
import { enableBatching } from 'redux-batched-actions';
import thunk from 'redux-thunk';
import actionSanitizerPrep from './actionSanitizerPrep';
import { composeWithDevTools } from 'remote-redux-devtools';
//import { composeWithDevTools } from '@redux-devtools/extension';

import paywallMiddleware from './paywallMiddleware';

//import testDashboard from './testDashboard';

import addActionArgsOverridesMiddleware from './addActionArgsOverridesMiddleware';

import analyticsMiddleware from './analyticsMiddleware';
import {ignoreActionsOnCliServerError} from './customMiddleware';

import { isUsingWebdriver } from './versionConfig';

import actionSanitizer from './actionSanitizer';
import memoryUsageMiddleware from './memoryUsageMiddleware';

export default function initStore(args){
 let {
   initialState,
   remoteDevTools,
   devtoolsName,
   windowSessionId,
   personId,
   agentId,
   providedDirectoryRoots
 } = args || {};


  let actionSanitizerPrepMiddleware = [ ]
  if( process.env.NODE_ENV !== 'production' ){
    //actionSanitizerPrepMiddleware.push(actionSanitizerPrep);
  }

  let middlewares;
  
  middlewares=[
    //memoryUsageMiddleware,
    /*testDashboard,*/
    paywallMiddleware,
    analyticsMiddleware,
    onQuantificationParametersChange,
    thunk,
    //...actionSanitizerPrepMiddleware,
    ignoreActionsOnCliServerError,
  ];

  if( isUsingWebdriver() ){

    middlewares.push(
      addActionArgsOverridesMiddleware
    )
  }



  if( typeof(window) !== typeof(undefined) && process.env.NODE_ENV !== 'production' ){

    window.__sessionActions = []
    const fun = store => next => action => {
      window.__sessionActions.push(action);
      return next(action);
    }

    //middlewares.splice(2,0,fun);

  }

  /*if( !remoteDevTools ){
    return createStore(
      Reducer,
      applyMiddleware(...middlewares)
    )
  }*/

 
  let name;
  if( typeof(window)===typeof(undefined) ){
    name = "CLI Test";
  }else{
    if( !!navigator.webdriver ){
      name = "Selenium";
    }else{
      name = undefined;
    }
  }

  const composeEnhancers = composeWithDevTools({
    name,
    host:'localhost',
    realtime:true, 
    port:8000, 
    trace: true,
    //traceLimit:25,
    maxAge: 100,
    //actionSanitizer
  });

  let lastStoreCreationArg = remoteDevTools?
    composeEnhancers( applyMiddleware( ...middlewares ) ) : applyMiddleware(...middlewares)
    


  let resolvedInitialState;

  if( initialState ){
    
    resolvedInitialState = initialState;
  }else{
    
    resolvedInitialState = DefaultState({
      providedDirectoryRoots,
      windowSessionId,personId,agentId})
  }



  const store = createStore(
    enableBatching(Reducer),
    resolvedInitialState,
    lastStoreCreationArg
  )


  return store;


}
