
import MicroscopyTest from './images/MicroscopyTest.jpg';

import rinie1 from './images/rinie1';
import MET from './images/MET.png';
import EGFR from './images/EGFR.png';
import pEGFR from './images/pEGFR.png';
import FLAG from './images/flag-ptpra-ptpre.png';
import UBA from './images/uba.jpg';
import FakeWestern from './images/FakeWestern.png';
import FakeWesternWithTwoMembranes from './images/FakeWesternWithTwoMembranes.png'

import q0 from './images/QuantStandard.png'; 

import che1 from './images/1.jpeg';
import che2 from './images/2.jpeg';


import che7 from './images/7.jpeg';
import che51 from './images/51.jpeg';
import che52 from './images/52.jpeg';
import che53 from './images/53.jpeg';
import che54 from './images/54.jpeg';
import che35 from './images/35.jpeg';
import che36 from './images/36.jpeg';


import che37 from './images/37.jpeg';
import che38 from './images/38.jpeg';

//equivalent grouped?
import che32 from './images/32.jpeg';
import che25 from './images/25.jpeg';

import che26 from './images/26.jpeg';
import che27 from './images/27.jpeg';

import membrane37 from './images/37-membrane.png';
import membrane32 from './images/32-membrane.png';
import membrane26 from './images/26-membrane.png';


import twentySixJpeg from './images/26_jpeg.jpg';
import thirtyTwoJpeg from './images/32_jpeg.jpg';
import thirtySevenJpeg from './images/37_jpeg.jpg';
import thirtyNineJpeg from './images/39_jpeg.jpg';
import jbc_2_jpeg from './images/jbc_2_jpeg.jpg';
import jbc_1_jpeg from './images/jbc_1_jpeg.jpg';

import cmSignal from './images/CM_JPEG.jpg';
import cmColor from './images/CM_COLOR.jpg';

import DougWheeler from './images/DougWheeler.jpeg';

import Ayubi from './images/Ayubi.png';

import aktyna from './images/aktyna.jpg';
import QuantStandardWithPeaks from './images/QuantStandardWithPeaks.jpg';

let imageMap = {
  rinie1,
  QuantStandardWithPeaks,
  aktyna, 
  q0,
  cmSignal,
  cmColor,

  Ayubi,
  che1,
  '2-raw':che2,
  "jbc_1_jpeg.jpg":jbc_1_jpeg,
  "jbc_2_jpeg.jpg":jbc_2_jpeg,
  "26_jpeg.jpg":twentySixJpeg,
  "32_jpeg.jpg":thirtyTwoJpeg,
  "39_jpeg.jpg":thirtyNineJpeg,
  "37_jpeg.jpg":thirtySevenJpeg,

  membrane37,
  membrane32,
  membrane26,

  '37-raw':che37,
  '38-raw':che38,

  '32-raw':che32,
  '25-raw':che25,

  '26-raw':che26,
  '27-raw':che27,

  '7-raw':che7,
  '51-raw':che51,
  '52-raw':che52,
  '53-raw':che53,
  '54-raw':che54,
  '35-raw':che35,
  '36-raw':che36,

  'met-raw':MET,
  'egfr-raw':EGFR,
  'p-egfr-raw':pEGFR,
  'flag-ptpra-ptpre':FLAG,
  'uba-raw':UBA,
  'FakeWestern-raw':FakeWestern,
  'FakeWesternWithTwoMembranes-raw':FakeWesternWithTwoMembranes,
  'Doug-raw':DougWheeler,

  MicroscopyTest:MicroscopyTest,
}

export default imageMap;


