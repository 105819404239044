import { createRef, useState, useEffect } from 'react';

export default function ScaledContent({children,...props}){

  
  const [outerRefDims, setOuterRefDims] = useState({});
  const [innerRefDims, setInnerRefDims] = useState({});

  const outerRef = createRef();
  const innerRef = createRef();

  
  let innerStyle;
  if( innerRefDims.height && innerRefDims.width && outerRefDims.width && outerRefDims.height ){

    let scale = outerRefDims.width / innerRefDims.width;
    innerStyle = {
      transformOrigin:'top left',
      transform:'scale('+scale+')'
    }

  }else{
    innerStyle = { 
      opacity:0,
    }
  }

  let borderElement = <div style={{position:'absolute',width:'100%', height:'100%',top:0,left:0,border:'3px solid black'}}/>

  useEffect(() => {

    let refOuterRefDims = outerRef.current ? outerRef.current.getBoundingClientRect() : {}
    let refInnerRefDims = innerRef.current ? innerRef.current.getBoundingClientRect() : {}

    if( JSON.stringify(refInnerRefDims) !== JSON.stringify(innerRefDims) ){
      if( innerStyle.transform ){
      }else{
        setInnerRefDims(refInnerRefDims);
      }
    }


    if( JSON.stringify(refOuterRefDims) !== JSON.stringify(outerRefDims) ){
      setOuterRefDims(refOuterRefDims);
    }


  })


  return (
    <div ref={outerRef} {...{...props}}>
      {borderElement}
      {false && <div style={{position:'absolute', top:0, left:0 }}>
        {JSON.stringify(innerRefDims)}
        {JSON.stringify(outerRefDims)}
        <div> {JSON.stringify(innerStyle?{innerStyle}:{})} </div>
      </div>}
      <div style={innerStyle} ref={innerRef}>
        {children}
      </div>
    </div>
  )
}


