import { useDispatch} from 'react-redux';

import { useEffect, useLayoutEffect, useRef } from 'react';
import CropItems from './CropItems';
import A from './ActionCreators';
import G from './Getters';

import CropWrapper from './CropWrapper';
import RotationContainerSvg from './RotationContainerSvg';

const HTML_IMG_ID = "main-crop-preview";
const bounds = (ref) => ref.current.getBoundingClientRect();

export default function CroppableImage({
  atns,
  imgContainerRef, 
  imgRef, 
  imageSetId,
  imageId,
  temporaryAdjustments,
  temporaryAnnotationProperties,
  notifyAnnotationPropertyChange,
  setFocusedAnnotationId,
  focusedAnnotationId,
  crop,
  height,
  highlight,
  width,
  interactive,
}){

  let croppableImageRef = useRef();
  let croppableImageContainerRef = useRef();


  let dispatch = useDispatch();

  let containerWidth = (imgContainerRef && imgContainerRef.current && imgContainerRef.current.getBoundingClientRect().width);

  useEffect(() => {

    if( croppableImageRef && croppableImageRef.current ){

      let previewImageDiv = document.getElementById(HTML_IMG_ID);

      if( previewImageDiv ){
        let imgBounds = previewImageDiv.getBoundingClientRect();
        //setImgContainerBounds(imgBounds);
      }
    }
    

  },[ croppableImageRef.current ])

  let heightMultiplier = height || 1;
  let imgContainerRefHeight = containerWidth * heightMultiplier;



  let containerBounds = croppableImageContainerRef?.current && bounds(croppableImageContainerRef);

 
  let cropHeightAspect = crop.height / crop.width;

  let imageContainerWidth,imageContainerHeight, imageContainerLeft, imageContainerTop;
  if( containerBounds ){
    let cWidth = containerBounds.width;
    let cHeight = containerBounds.height;

    if( cWidth * cropHeightAspect > cHeight ){
      imageContainerWidth = cHeight / cropHeightAspect;
      imageContainerHeight = cropHeightAspect * imageContainerWidth;
    }else{
      imageContainerWidth = cWidth;
      imageContainerHeight = cropHeightAspect * imageContainerWidth;
    }

    imageContainerLeft = (containerBounds.left + 0.5 * (containerBounds.width - imageContainerWidth));
    imageContainerTop = (containerBounds.top);
  }

  let imgContainerBounds = {
    width:imageContainerWidth, height:imageContainerHeight,
    left:imageContainerLeft, top:imageContainerTop
  }

  return (

    <>

      <div ref={croppableImageContainerRef} style={{
        width:'100%',height:'100%', 
      }}>

        <div
          imageView={"image-to-annotate"}
        ref={imgContainerRef}
        style={{ 
          //border:'1px solid #ccc',
          //border:'3px solid blue',
          width:imageContainerWidth,
          height:imageContainerHeight,
          background:'rgba(238,238,238)',
          position: "relative",
          left:'50%',
          transform:'translate(-50%,0)',
          zIndex:3,
        }}
      >

        

        {containerBounds && <RotationContainerSvg {
          ...{
            imageViewTransformation:crop,
            atns,
            interactive,
            highlight,
            temporaryAnnotationProperties,
            imgRef,
            notifyAnnotationPropertyChange,
            imageSetId,
            imgContainerBounds,
            crop,
            setFocusedAnnotationId,
            focusedAnnotationId,
          } }/>}

        <CropWrapper 
          canClickToRetryDownload={true}
          temporaryAdjustments={temporaryAdjustments}
          stack={["CroppableImage"]}
          inlineCrop={crop}
          imgRef={croppableImageRef}
          overflow={false}
          htmlImageId={HTML_IMG_ID}
          
          imageId={imageId} 
          draggable={false} imageSelectable={false} 
        />
      </div>
      </div>
    </>


  )
}

