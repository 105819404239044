import CroppableImage from './CroppableImage';
import { useRef, useState, useEffect } from 'react';
export default function CroppableImageContainer({
  imageId,
  imageSetId,
  width,
  height,
  highlight,
  crop
}){
  const [rerender,setRerender] = useState(false);
  let imgContainerRef = useRef();
  let imgRef = useRef();



  useEffect(() => {
    setRerender(!rerender)

  },[imgContainerRef.current,imgRef.current]);

  return (
    <div style={{postion:'relative'}} class="small-margin">
      <div style={{width:'40%', height}}>
        <CroppableImage {
          ...{
            crop,
            imageId,
            imageSetId,
            imgRef,
            //imgContainerRef,
            highlight:true,
            //interactive:true,
          } 
          }/>
      </div>
    </div>
  )
}
