import { useState } from 'react';



const data = {
  annotation:(
    <div>annotation</div>
  ),
  captureBands:(
    <div>Capturingtons</div>
  )
}


let UNSEEN = [];
if( process.env.NODE_ENV !== 'production' ){
  UNSEEN = [
    "Greek letters",
  ]
  
}

const unreadColor = '#114fd4';

function TreeNode({_id,children,label,selectedSection,setSelectedSection}){


  const [open,setOpen] = useState(false);


  const keySplit = selectedSection?.split('__');


  let unseen = UNSEEN.includes(_id)

  //console.log({keySplitStr});
  /*if( unseen.includes(keysplitstr) ){
    alert("FOUND MATCH!, " + keySplitStr);
  }*/

  const parentKeys = keySplit.map((_,ii) => {
    return keySplit.slice(0,ii).join('__');
  })
  const forcedOpenByChild = (parentKeys.includes(_id));


  let background = "white";
  if( _id === selectedSection ){
    background = "#eee";
  }

  //either the root

  return (
    <div>
      <div style={{background}} onClick={() => {
        if( children && children.length > 0 ){
          if( !forcedOpenByChild ){
            setOpen(!open)
          }
        }else{
          setSelectedSection(_id);
        }
      }} class="sg-row v-center">
        {unseen && <div style={{width:10,height:10, 
          border:unreadColor,
          background:unreadColor,
          borderRadius:5,
        }}/>}
        {!unseen && <div style={{width:10,height:10}}/>}




        <div style={{width:20}} >{ children && <div style={{textAlign:'center'}}>{open?"-":"+"}</div> }</div>
        <div style={{fontSize:14,margin:3,
          fontWeight:(unseen?'bold':'unset'),//(children&&children.length>0)?'bold':'unset',

          //color:(unseen?'red':'unset'),

        }}>{label}</div>
      </div>
      {(open || forcedOpenByChild) && (
        <div style={{marginLeft:10}}>
        {children?.map(child => {
          return (
            <TreeNode {...{
              ...child,
              _id:(_id + '__' + child._id),
              label:child._id,
              selectedSection,
              setSelectedSection
            }}/>
          )
        })}
      </div>
      )}
    </div>
  )


}



export default function HierarchyList({tree, selectedSection, setSelectedSection}){

  //const order = getOrder(tree); 

  

  let treeComponent = tree.map(child => {
    return (
      <TreeNode {...{...child,selectedSection,setSelectedSection}}/>
    )
  })

  return (
    <div style={{
      height:'100%', 
      overflow:'scroll', 
      minHeight:0,
      //border:'2px solid green'
    }}>
      <div>
        <pre>
          {/*JSON.stringify(order,null,1)*/}
        </pre>
      </div>
      {treeComponent}
    </div>
  )



}
