import { useSelector } from 'react-redux';
import G from './Getters';
export default function LoginSettings(){

  let username = useSelector(state => state.loginInfo.username);

  return (
    <div class="fair-padding text-body">


      <div class="fair-margin">
        <h4>Username:</h4> 
          <div>{username}</div>
      </div>

      <div style={{marginTop:50}} class="fair-margin">
        <h4>Password:</h4>
        <div>
          To change your password:
          <ol class="password-change">
            <li>Goto <a href="#">https://sciugo.com/</a></li>
            <li>In the login popup, click <b>Forgot Password</b>.</li>
            <li>Fill out the form and reset.</li>
          </ol>

        </div>
      </div>



    </div>
  )

}
