import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';

import ListItemIcon from '@material-ui/core/ListItemIcon';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import WarningIcon from '@material-ui/icons/Warning';

import { useDispatch } from 'react-redux';
import G from './Getters';
import A from './ActionCreators';
import { WELCOME } from './UIConstants';
import { useSelector } from 'react-redux';

export default function UserSettingsButton({setMode,clearUserSelections, openBugModal,
  openSettingsModal,
}) {


  const state = useSelector(state => state);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let menuButtonStyle = { fontSize:12, display:'flex', alignItems:'center' };

  const buttons = [
    { 
      display:"My Account",
      button:"my-account",
      onClick:() => {
        handleClose();
        openSettingsModal();
      }
    },
    { 
      display:"Report Issue",
      button:"report-issue",
      onClick:() => {
        handleClose();
        openBugModal();
      }
    },
    { 
      display:"Logout",
      button:"logout",
      onClick:() => {

        handleClose();
        let logoutMessageContent = G.getLogoutMessageContent(state);
        if( !logoutMessageContent ){
          clearUserSelections();
          setMode(WELCOME);
        }
        dispatch(A.tryLogout());
      }
    }
  ]

  return (
    <div button="account-settings" style={{marginRight:10}}>
      <div 
        class="hoverable round-corners small-padding"
        style={{...menuButtonStyle,margin:0, }} 
        onClick={handleClick}>
        <SettingsIcon/>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {buttons.map(menuItem => {
          return (
            <MenuItem onClick={menuItem.onClick}>
              <div button={menuItem.button}>
              {menuItem.display}
              </div>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  );
}

