import Id from './IdFactory';

export default function TemplateFactory(state,item){
  let { templateType, args={} } = item;

  let { firstNodeId, regionExpansion={} } = args;

  console.log({ firstNodeId });

  let mainRegionExpansion = regionExpansion.main;

  let defaultChannels = [ 0,1,2 ].map(
    num => "channel" + num
  );

  let channels = Object.fromEntries(
    defaultChannels.map(c => ([c,{}]))
  );




  let mergedWindow = {
    _id:Id(),
    ...Object.fromEntries(
      defaultChannels.map(c => ([c,true]))
    )
  }

  let regions = {}

  
  let firstNode = {
    p:"root"
  }

  let resovledFirstNodeId = firstNodeId || Id();

  return {

    channels,


    nodes:{
      root:{ p:null, c:[resovledFirstNodeId], d:"h" },
      [resovledFirstNodeId]:firstNode,
    },
    
    // for each region,
    // the list of channels
    // we can use to assign to our template

    /*
    regionExpansion:{
      main:(mainRegionExpansion || mergedWindow),
    },
    */
    

  }

}
