

let Constants = {

  removeNodeFromEvaluatedTemplateNode:'removeNodeFromEvaluatedTemplateNode',

  

  insertNodesInEvaluatedTemplateNodes:'insertNodesInEvaluatedTemplateNodes',
  insertNodeInEvaluatedTemplateNode:'insertNodeInEvaluatedTemplateNode',

  

  setFigurePanelSettings:'setFigurePanelSettings',

  modifyRegionExpansionNodeItems:'modifyRegionExpansionNodeItems',

  moveExpansionTemplateNode:'moveExpansionTemplateNode',

  addRegionExpansionNodeItems:'addRegionExpansionNodeItems',

  setCellsValueProperties:'setCellsValueProperties',

  removeExpansionTemplateNode:'removeExpansionTemplateNode',
  insertExpansionTemplateNode:'insertExpansionTemplateNode',
  insertExpansionTemplateNodes:'insertExpansionTemplateNodes',

  setRegionExpansionNodeProperties:'setRegionExpansionNodeProperties',

  splitRegionFormatCell:'splitRegionFormatCell',

  setCellCultureDate:'setCellCultureDate',
  setCellCultureItem:'setCellCultureItem',
  addCellCultureItem:'addCellCultureItem',
  removeCellCultureItem:'removeCellCultureItem',

  removeCellCultureListItem:'removeCellCultureListItem',


  verifyEmailValidation:'verifyEmailValidation',

  sendValidationEmail:'sendValidationEmail',


  fetchSubscriptionInfo:'fetchSubscriptionInfo',

  setWarnings:'setWarnings',

  fetchEmailVerificationStatus:'fetchEmailVerificationStatus',

  setSubscriptionInfo:'setSubscriptionInfo',

  retryPayment:'retryPayment',

  registerReferrer:'registerReferrer',

  fetchAvailableSubscriptionCredits:'fetchAvailableSubscriptionCredits',



  sendMessageToAWS:'sendMessageToAWS',


  requestCheckoutLink:'requestCheckoutLink',

  cancelSubscription:'cancelSubscription',

  tryArchiveItems:'tryArchiveItems',

  sendToCloudwatch:'sendToCloudwatch',

  setTutorialState:'setTutorialState',

  setSelectedAnnotationsItemContext:'setSelectedAnnotationsItemContext',

  confirmExitTutorial:'confirmExitTutorial',
  cancelExitTutorial:'cancelExitTutorial',
  launchTutorial:'launchTutorial',


  setUserInfo:'setUserInfo',
  makeRequest:'makeRequest',
  exitTutorial:'exitTutorial',
  completeTutorial:'completeTutorial',

  receiveRequestResponse:'receiveRequestResponse',

  downloadQuantificationValues:'downloadQuantificationValues',

  loginCompleted:'loginCompleted',

  receiveSendPasswordRecoveryResponse:'receiveSendPasswordRecoveryResponse',

  setPasswordRequestFailReason:'setPasswordRequestFailReason',
  clearAuthStatusReason:'clearAuthStatusReason',
  requestPasswordRecoveryCode:'requestPasswordRecoveryCode',
  changePassword:'changePassword',

  sendRecovery:'sendRecovery',

  createGreyscale:'createGreyscale',

  insertRows:'insertRows',

  answerSurveyPostSuccess:'answerSurveyPostSuccess',
  answerSurveyPostFail:'answerSurveyPostFail',
  answerSurvey:'answerSurvey',

  markPossibleQuantificationAnnotationUpdatesSeen:'markPossibleQuantificationAnnotationUpdatesSeen',

  updateQuantificationParameters:'updateQuantificationParameters',

  updateQuantificationAnnotationToFigureAnnotation:'updateQuantificationAnnotationToFigureAnnotation',

  modifyQuantificationList:'modifyQuantificationList',


  setIntegrationRange:'setIntegrationRange',

  updateProcessStatus:'updateProcessStatus',

  registerProcesses:'registerProcesses',

  incrementLaneWidths:'incrementLaneWidths',
  shiftLaneOffsets:'shiftLaneOffsets',

  resetLanesAndClearQuantification:'resetLanesAndClearQuantification',

  openAuthModal:'openAuthModal',

  requestQuantificationAccess:'requestQuantificationAccess',
  startQuantification:'startQuantification',

  discardPendingRecords:'discardPendingRecords',

  updateMediaProcessing:'updateMediaProcessing',

  startMediaProcessing:'startMediaProcessing',


  mediaFetchFailed:'mediaFetchFailed',

  sendDomEvents:'sendDomEvents',

  setSelectedFigurePanelContext:'setSelectedFigurePanelContext',
  setUiMode:'setUiMode',

  internetConnectionError:'internetConnectionError',

  postResourceError:'postResourceError',

  showFetch:'showFetch',
  clearPostData:'clearPostData',
  
  notifyMediaPersistenceError:'notifyMediaPersistenceError',



  persistMediaWithPresignedUrls:'persistMediaWithPresignedUrls',
  receivedPresignedPosts:'receivedPresignedPosts',

  fetchPresignedPostsFailed:'fetchPresignedPostsFailed',
  fetchPresignedPostUrls:'fetchPresignedPostUrls',


  createItems:'createItems',

  readUnreadMessages:'readUnreadMessages',

  imageSyncFailure:'imageSyncFailure',


  clearAllNotifications:'clearAllNotifications',
  pushNotification:'pushNotification',
  popNotification:'popNotification',

  sendTypingNotification:'sendTypingNotification',

  receiveTypingNotification:'receiveTypingNotification',

  updateThreadMessagesStatus:'updateThreadMessagesStatus',

  fetchThreads:'fetchThreads',

  addPanelToFigure:'addPanelToFigure',
  removePanelFromFigure:'removePanelFromFigure',

  messageSendFailure:'messageSendFailure',

  createThread:'createThread',

  receivedThreadUpdateNotification:'receivedThreadUpdateNotification',
  threadUpdateRequested:'threadUpdateRequested',
  threadUpdateReceived:'threadUpdateReceived',
  updateThread:'updateThread',

  receiveMessage:'receiveMessage',
  receiveReadReceipt:'receiveReadReceipt',
  resendMessage:'resendMessage',
  sendMessage:'sendMessage',
  readMessages:'readMessages',



  setState:'setState',

  enterApp:'enterApp',


  updateRequestStatus:'updateRequestStatus',
  rejectExpiredRequestResponse:'rejectExpiredRequestResponse',



  TIMEOUT:'TIMEOUT',
  SUCCESS:'SUCCESS',
  FAILURE:'FAILURE',



  postServerAlert:'postServerAlert',
  createUnexpectedServerErrorDialog:'createUnexpectedServerErrorDialog',

  createNoInternetDialog:'createNoInternetDialog',

  exportFigureFailed:'exportFigureFailed',

  removeCorruptImageUploads:'removeCorruptImageUploads',

  alertServer:'alertServer',
  serverAlerted:'serverAlerted',
  alertingServer:'alertingServer',
  sendBugReport:'sendBugReport',

  DEFAULT_PROCESSED_IMAGE_VERSION:'raw-png',
  GREYSCALE:'greyscale',

  IDLE:'IDLE',
  PENDING:'PENDING',
  SAVED:'saved',
  UNSAVED:'unsaved',
  SAVING:'saving',



  dispatchGetter:'dispatchGetter',

  createDialog:'createDialog',

  setSessionConfig:'setSessionConfig',

  setRemoteStorageResourceId:'setRemoteStorageResourceId',

  processAuthSuccess:'processAuthSuccess',
  processAuthFailure:'processAuthFailure',
  processAuthResponse:'processAuthResponse',

  receiveAuthResponse:'receiveAuthResponse',
  fetchUserAuthResponse:'fetchUserAuthResponse',

  injectRecordsFromServer:'injectRecordsFromServer',

  queueAnonymousSessionDataForSync:'queueAnonymousSessionDataForSync',

  deleteItem:'deleteItem',

  moveFilesystemItem:'moveFilesystemItem',

  setFilesystemName:'setFilesystemName',

  overwriteAllOccurencesOfValueAtPath:'overwriteAllOccurencesOfValueAtPath',

  sendImagesToServer:'sendImagesToServer',

  finishImageProcessing:'finishImageProcessing',
  finishProcess:'finishProcess',
  startProcess:'startProcess',


  setUserSettings:'setUserSettings',

  setMediaURL:'setMediaURL',

  unexpectedCliServerError:'unexpectedCliServerError',

  startSyncImageCloudStorage:'startSyncImageCloudStorage',
  finishSyncImageCloudStorage:'finishSyncImageCloudStorage',

  fetchImages:'fetchImages',

  mergePersistedUserDataWithCurrentSessionData:'mergePersistedUserDataWithCurrentSessionData',

  googleLogin:'googleLogin',

  LOGOUT_USER:'LOGOUT_USER',
  CLEAR_STATE:'CLEAR_STATE',


  OWNER_PERMISSION:"OWNER",
  WRITE_PERMISSION:"WRITE",
  READ_PERMISSION:"READ",

  noSyncNecessary:'noSyncNecessary',

  createSampleLayout:'createSampleLayout',

  connectItems:'connectItems',
  disconnectItems:'disconnectItems',


  setSelectedPanel:'setSelectedPanel',

  setFigurePanelStyle:'setFigurePanelStyle',

  setImageProperties:'setImageProperties',


  transferItemLink:'transferItemLink',

  setItemTitle:'setItemTitle',

  setLaneOffsets:'setLaneOffsets',

  setQuantifications:'setQuantifications',

  addItemLinks:'addItemLinks',
  removeItemLinks:'removeItemLinks',

  addValidationTemplate:'addValidationTemplate',

  selectFigurePanel:'selectFigurePanel',


  syncFailure:'syncFailure',
  syncSuccess:'syncSuccess',

  sendSyncRequest:'sendSyncRequest',
  receiveSyncResponse:'receiveSyncResponse',

  processSyncResponse:'processSyncResponse',




  syncChanges:'syncChanges',
  FETCH_LOGOUT_RESPONSE:'FETCH_LOGOUT_RESPONSE',
  RECEIVE_LOGOUT_RESPONSE:'RECEIVE_LOGOUT_RESPONSE',

  tryLogout:'tryLogout',

  createItem:'createItem',

  setAnnotationLaneCount:'setAnnotationLaneCount',

  FAILED_TO_INJECT_LOGIN_RESPONSE:'FAILED_TO_INJECT_LOGIN_RESPONSE',
  RECEIVE_RESPONSE:'RECEIVE_RESPONSE',

  receiveLoginResponse:'receiveLoginResponse',
  receiveSignupResponse:'receiveSignupResponse',

  fetchLoginResponse:'fetchLoginResponse',
  fetchSignupResponse:'fetchSignupResponse',

  newFigure:'newFigure',

  selectAll:'selectAll',



  KEEP_MOVER_ANNOTATIONS_ONLY:'keepMoverAnnotationsOnly',
  KEEP_BOTH_ANNOTATIONS:'keepBothAnnotations',
  KEEP_DESTINATION_ANNOTATIONS:'keepDestinationAnnotations',

  CLEAR_HISTORY:'CLEAR_HISTORY',
  UNDO:'UNDO',
  REDO:'REDO',
  tryArchiveImages:'tryArchiveImages',
  finalizeUpload:'finalizeUpload',
  startImageUploads:'startImageUploads',
  restoreImages:'restoreImages',
  archiveImages:'archiveImages',
  moveImagesToImageSet:'moveImagesToImageSet',
  setFocusedAnnotationId:'setFocusedAnnotationId',

  deleteAnnotation:'deleteAnnotation',

  setGlobalConfig:'setGlobalConfig',


  SET_SNAP_REACH:'SET_SNAP_REACH',

  addAnnotation:'addAnnotation',
  setAnnotationProperties:'setAnnotationProperties',

  SET_HEIGHT_GROUP_PROPERTIES:'SET_HEIGHT_GROUP_PROPERTIES',
  ADD_HEIGHT_GROUP:'ADD_HEIGHT_GROUP',
  REPORT_MESSAGE:'REPORT_MESSAGE',

  popMessage:'POP_MESSAGE',
  POP_MESSAGE:'POP_MESSAGE',

  EXPORT_FIGURE:'EXPORT_FIGURE',

  SET_CROP_PROPERTY:'SET_CROP_PROPERTY',

  ROTATE_IMAGE:'ROTATE_IMAGE',

  SET_IMAGE_PROPERTY:'SET_IMAGE_PROPERTY',

  ADD_CROP:'ADD_CROP',
  DELETE_CROP:'DELETE_CROP',

  logError:'logError',

  setFigureImage:'setFigureImage',

  DELETE_ANNOTATION_LINE:'DELETE_ANNOTATION_LINE',

  SWAP_SIDES_OF_BAND_ANNOTATION:'SWAP_SIDES_OF_BAND_ANNOTATION',

  SET_LABEL_PROPERTY:'SET_LABEL_PROPERTY',

  ADD_BAND_LABEL:'ADD_BAND_LABEL',
  MERGE_BAND_LABELS:'MERGE_BAND_LABELS',


  END_SESSION:'END_SESSION',

  setCellWidths:'setCellWidths',

  INJECT_ROUTE:'INJECT_ROUTE',

  DELETE_COLUMN:'DELETE_COLUMN',


  INIT_TABLE:'INIT_TABLE',

  SELECT_CROP:'SELECT_CROP',
  DELETE_ROW:'DELETE_ROW',
  
  TRANSFORM_IMAGE:'TRANSFORM_IMAGE',

  SET_EDITOR_STYLE:'SET_EDITOR_STYLE',

  SELECT_CELLS:'SELECT_CELLS',

  SET_GEL_LANES:'SET_GEL_LANES',

  UPLOAD_IMAGE:'UPLOAD_IMAGE',

  mergeCells:'mergeCells',
  SPLIT_CELLS:'SPLIT_CELLS',

  SET_TABLE_DIMENSIONS:'SET_TABLE_DIMENSIONS',
  moveRow:'moveRow',
  insertRow:'INSERT_ROW',
  INSERT_COLUMN:'INSERT_COLUMN',

  setCellsValue:'setCellsValue',


  IMAGE_MANAGEMENT_IMAGE_DIMENSION:'IMAGE_MANAGEMENT_IMAGE_DIMENSION',


  
}
if( process.env.NODE_ENV !== 'production' ){

  const handler = {
    get(target,prop,receiver){
      if( target[prop] ){
        return target[prop];
      }else{
        throw Error(`No constant '${prop}' exists.`);
      }
    }
  }

  Constants = new Proxy(Constants,handler);
}

//IMPORT_STATEMENTS
export default Constants;

