
import { useDispatch, useSelector, useStore } from 'react-redux';
import A from './ActionCreators';

import SubscribeScene from './SubscribeScene';
import AccountSettingsModal from './AccountSettingModal';

import Modal from 'react-bootstrap/Modal';

import CustomModalBody from './CustomModalBody';
import CustomModalHeader from './CustomModalHeader';
import CustomModalFooter from './CustomModalFooter';

import React from 'react';
import G from './Getters';


function select(getter,state){
  if( getter in G ){
    return G[getter](state);
  }else{
    throw Error("Message object tried to evaluate a Getter, '"+getter+"' that doesn't exist.");
  }
}

export function executeActionList(actions,{dispatch,setModal}){

  actions.forEach(action => {

    
    let { name, args, then} = action;
    if( action.setModal ){
      let modal = action.modal;
      setModal(modal);
      return;
    }

    let actionCreator = A[name];
    if(!actionCreator){
      throw Error(name+" is not an actionCreator?");

    }
    let dispatchPromise = dispatch(A[name](args))
    return Promise.resolve(dispatchPromise).then(() => {
      if( then ){
        return executeActionList(then,dispatch);
      }
    })
  })
}


const customModalMap = args => ({
  "SUBSCRIBE_ACTION":<SubscribeScene args={args}/>,
  "SETTINGS":<AccountSettingsModal args={args}/>
})

export default function CustomModal({messageObject, modal, setModal}){

  let dispatch = useDispatch();

  let dialog = useSelector(state => G.getTopMessage(state));

  let { 
    dialogName,
    beforeHide, 
    afterHide, 
    buttons,
    body,
    header,
    args
  } = dialog;

  return (

    <Modal itemType={"sciugo-dialog"} dialogName={dialogName} size="m" show={true} onHide={() => {



      if( beforeHide ){
        executeActionList(beforeHide,{
          dispatch,setModal
        });
      }

      dispatch(A.popMessage())

      if( afterHide ){
        executeActionList(afterHide,{
          dispatch, setModal
        });
      }

      
    }}>
      {customModalMap[dialogName]?.(args)}
      {!customModalMap[dialogName] && <>
      <CustomModalHeader {...{header}}/>
      <CustomModalBody {...{body}}/>
      <CustomModalFooter {...{buttons,setModal}}/>
      </>}
    </Modal>

  )

}
