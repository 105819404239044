import usePresentSelector from './usePresentSelector';
import { ANNOTATIONS } from './RecordTypes';
import SimplePopover from './SimplePopover';
import LineSegmentBox from './LineSegmentBox';
import { useEffect, useState, useRef } from 'react';
import { useDispatch} from 'react-redux';
import A from './ActionCreators';
import C from './Constants';
import G from './Getters';
import CropPreview from './CropPreview';
import LineSegment from './LineSegment';
import GuidelineDrawingLayer from './GuidelineDrawingLayer';
import { 
  midpoint,
  scale,
  unit,
  add,
  unitPerp,
  minus,
  distance,
  rotatePointsAboutCentroid, 
  getPointsAsWidthProportions, 
  getPointsFromProportions } from './Geometry';

const bounds = ref => ref && ref.current && ref.current.getBoundingClientRect();
const rectCentroid = bounds => {

}

const __custom = "__custom";



export default function RotationContainerSvg({
  atns,
  highlight,
  notifyAnnotationPropertyChange,
  temporaryAnnotationProperties,
  imgRef,imgContainerBounds, cropId, imageSetId,
  focusedAnnotationId, 
  setFocusedAnnotationId,
  interactive,
  crop,
  imageViewTransformation

}){



  let imageSetHeight = usePresentSelector(state => G.getImageSetHeight(state,imageSetId));

  let sortedHeightGroups = usePresentSelector(G.getOrderedHeightGroups);

  //console.log({sortedHeightGroups});

  let [popoverLocation,setPopoverLocation] = useState(null);
  const [hoveredHeight,setHoveredHeight] = useState(null);

  //const [showGuidelines, setShowGuidelines] = useState(true);
  const [lastCreatedAnnotationId,setLastCreatedAnnotationId] = useState(null);


  /*
  useEffect(() => {

    const mouseMoved = e => {

      if( !showGuidelines ){
        let path = e.composedPath();
        if( path.every(ele => !ele.annotation) ){
          setShowGuidelines(true);
        }
      }
    }
    window.addEventListener('mousemove',mouseMoved);
    return () => {
      window.removeEventListener('mousemove',mouseMoved);
    }

  },[showGuidelines])
  */




  let focusedAnnotation = usePresentSelector(state => focusedAnnotationId && G.getData(state,{type:ANNOTATIONS,_id:focusedAnnotationId, ifDoesNotExist:null}));


  let anchorPosition;
  if(popoverLocation){
    anchorPosition = {
      top:popoverLocation.top - imgContainerBounds.top,
      left:popoverLocation.left - imgContainerBounds.left
    }
  }else{
    anchorPosition = {
      left: 10,
      top: 10
    }
  }


  let dispatch = useDispatch();

  let imgRefBounds = imgContainerBounds;



  let defaultCropHeightGroupId = sortedHeightGroups[0]?._id || __custom;


  let imageAnnotations = atns;



  let w = imgRefBounds.width;

  let invW = 1/w;


  let [ drawing, setDrawing ] = useState(false);
  let annotationComponents;
  const onAnnotationDelete = (deletedAnnotationId) => {
    if( lastCreatedAnnotationId === deletedAnnotationId ){
      setLastCreatedAnnotationId(null);
    }
    //setShowGuidelines(true);
    dispatch(A.deleteAnnotation({_id:deletedAnnotationId}))
    setFocusedAnnotationId(null);
  }

  /*

  const toggleGuidelinesVisible = () => visible => {
    if(showGuidelines === !visible){
      setShowGuidelines(visible)
    }
  }
  */

  const getAnnotationHeight = function(atn){

    let height;
    if( atn ){
      let {heightGroupId} = atn;
      if( heightGroupId === __custom ){
        height = atn.height * w;
      }else{
        height = sortedHeightGroups.find(gr => gr._id ===  heightGroupId ).height * w;
      }
    }
    return height;
  }

  let lineSegmentBoxStyle = !interactive? {stroke:'blue',strokeWidth:1} : {stroke:'blue', strokeOpacity:0.2};

  if( imgRefBounds.width ){

    annotationComponents = imageAnnotations.map(atn =>  (
      <g 
        //onMouseEnter={e => {if(showGuidelines && atn._id){setShowGuidelines(false)}}}
        //onMouseLeave={e=>{if(!showGuidelines){setShowGuidelines(true)}}}
        style={{pointerEvents:(drawing===true)?'none':'unset'}}
      >
        {atn.ls && (
          <LineSegmentBox {...{
            imageSetHeight,
            imageViewTransformation,
            notifyAnnotationPropertyChange,
            annotationId:atn._id,
            annotation:atn,
            setFocusedAnnotationId,
            onDelete:onAnnotationDelete,
            interactive,
            focusedAnnotationId,
            focusedAnnotation:atn,
            height:getAnnotationHeight(atn),
            w,
            imageContainerBounds:imgContainerBounds,
            ...lineSegmentBoxStyle,
            strokeWidth:(focusedAnnotationId === atn._id) ? 4 : 2

          }}/>
        )}
        {atn.mark && (
          <LineSegment setFocusedAnnotationId={setFocusedAnnotationId} highlight={highlight} isFocusedAnnotation={atn._id === focusedAnnotationId} onDelete={onAnnotationDelete} key={atn._id} annotation={atn} imgWidth={imgRefBounds.width} lsStyle={"box"}/>)}
        {false && atn.rect && (
          <LineSegment {...{
            annotation:atn,
            imgWidth:imgRefBounds.width,

          }}/>
        )}
      </g>
    ))
  }

  let lastCreatedAnnotation = usePresentSelector(state => lastCreatedAnnotationId && G.getData(state,{type:ANNOTATIONS,_id:lastCreatedAnnotationId}));
  let points = {};
  let lastCreatedAnnotationIsLineSegment = lastCreatedAnnotation && lastCreatedAnnotation.ls;

  if( lastCreatedAnnotationIsLineSegment ){
    points = {
      start:scale(w,lastCreatedAnnotation.ls[0]),
      end:scale(w,lastCreatedAnnotation.ls[0]),
    }
  }



  let selectedHeight = focusedAnnotation && focusedAnnotation.ls && getAnnotationHeight(focusedAnnotation);







  let shouldShowGuidelineDrawingLayer = (
    interactive !== false && 
    !window.someComponentIsDragging 
    //&& showGuidelines
  )



  return (
    <>

      {false && <div style={{zIndex:30,color:'red',position:'absolute',right:0,top:0}}>
        {JSON.stringify(imgContainerBounds)}
      </div>}

      <div id={"redbeard"} container={"image-annotation"} style={{overflow:'visible',position:'absolute',width:imgContainerBounds.width,height:'100%'}}>

        <svg
          id="annotation-container-svg"
          height={imgContainerBounds.height}
          width={imgContainerBounds.width}
          style={{
            zIndex:10,
            overflow:'visible',
            top: 0, left: 0,
            background: "none",
            position: "absolute",
          }}>


          <pattern id="stripePattern" width="10" height="10" patternUnits="userSpaceOnUse">
    <rect width="10" height="10" fill="transparent" />
    <line x1="0" y1="0" x2="100" y2="100" stroke="red" stroke-width="2" />
  </pattern>



          {false && points.start !== null && <line
            x1={points.start[0]}
            y1={points.start[1]}
            x2={points.end[0]}
            y2={points.end[1]}
            stroke={"orange"}
            fill="none"
            strokeWidth="3"
            style={{ position: "absolute", zIndex: 2, top: 0, left: 0 }}
          />
          }


          {shouldShowGuidelineDrawingLayer && <GuidelineDrawingLayer {...{
            imageViewTransformation,
            imageSetHeight,
            interactive,
            heightGroupList:sortedHeightGroups,
            selectedHeight,
            defaultCropHeightGroupId,
            setLastCreatedAnnotationId,
            setPopoverLocation,
            lastCreatedAnnotation,
            focusedAnnotationId,
            temporaryAnnotationProperties,
            setDrawing,
            setFocusedAnnotationId,imageSetId,imgContainerBounds,imgRefBounds}}/>}       

          {annotationComponents}




          {false && <g>
            <text x={30} y={30} stroke={"white"}>{"w: " +w}</text>
            <text x={30} y={70} stroke={"yellow"}>{"anchorPosition: " + JSON.stringify(anchorPosition)}</text>

            <rect x={0} y={0} width={anchorPosition.left} height={anchorPosition.top} stroke="yellow" fill={"none"} />
          </g>}


        </svg>








      </div>

        { focusedAnnotation && focusedAnnotation.ls && anchorPosition && <SimplePopover 
          key={"popover-"+focusedAnnotationId}



          {...{
            w,
            heightGroupList:sortedHeightGroups,
            temporaryAnnotationProperties,

            onAnnotationDelete,

            //onMouseEnter:toggleGuidelinesVisible(false),
            //onMouseLeave:toggleGuidelinesVisible(true),
            handleClose:(()=>{ setFocusedAnnotationId(null); setPopoverLocation(null); /*setShowGuidelines(true)*/}),
            notifyAnnotationPropertyChange,
            cropId,
            setFocusedAnnotationId,
            focusedAnnotation,
            anchorPosition,
            setAnchorPosition:setPopoverLocation,
            hoveredHeight,
            setHoveredHeight,
          }}/> }

    </>
  )
}

