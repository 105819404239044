import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';
import  G from './Getters';
export default function TutorialOutro({onStateChange}){

  let dispatch = useDispatch();

  let requestResponse = useSelector(state => G.getRequestResponse(state,{route:"/setUserInfo"}));


  let requestMessage;
  
  if( requestResponse?.status === "failed" ){
    requestMessage = (
    <div style={{fontWeight:"bold",color:"red"}}>
      {requestResponse.reason}
    </div>
    )

  }

  return (
    <div style={{ 
      
      //border:'1px solid green',
      alignItems:'center',
      background:'#ddd',
      paddingTop:'10%',

        
      }} 
      class="sg-col full-width full-height">
      <h3>Tutorial Complete!</h3>
      <br/>

      <div style={{fontSize:16}}>
         Enjoy Sciugo!
      </div>

      <br/>

      <div style={{textAlign:'center'}}>
        For more info, click <span><HelpOutlineIcon/></span> in the top menu
        <div>(once you continue to your account)</div>
      </div>


      <div>
        {requestMessage}
      <button 
        disabled={requestResponse?.pending}
        style={{marginTop:20}} 
        button={"complete-tutorial"}
        onClick={() => {
        dispatch(A.completeTutorial())
      }} class="btn btn-primary">Continue to my account</button>
      </div>
    </div>
  )
  
}
