import G from './Getters';
import C from './Constants';

// no changes to data
// no 

const ALLOWED_ACTIONS_ON_DATA_CHANGE = [
  C.injectRecordsFromServer
]

export default function changeIsPermittedForUser({state,stateDiff,action}){

  

  let loggedIn = G.isLoggedIn(state);
  if( !loggedIn ){
    return true;
  }

  let sciugoSubscriptionExpired = G.isProductSubscriptionExpired(state,{productId:"sciugoMain"})

  if( !sciugoSubscriptionExpired ){
    return true;
  }



  // assuming it's expired
  if( stateDiff.data ){
    let { type } = action;
    let valid = ALLOWED_ACTIONS_ON_DATA_CHANGE.includes(type)
    if( !valid ){
      return false;
    }
  }

  if( stateDiff.meta ){
    let metaChanges = stateDiff.meta;
    let changes = Object.values(metaChanges).find(changedItems => Object.values(changedItems).find(changedValues => changedValues.archived));
    if( changes ){
      return false;
    }
  }

  //check all the disallowed changes:


  
  return true;

}
