import { useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';
import G from './Getters';

const CHECKOUT_COMMENT = false;


function getComment(itemList){


  return itemList.map((str,ii) => {
    if( !str ){
      throw Error("Null item, index = " + ii);
    }
    return str[0].toUpperCase() + str.slice(1)
  }).join(' - '); 
}

export default function CheckoutButton({

  disabled,
  
  labAlreadyRequested, 


  plurality, 
  selectedPaymentCycle,
  institutionType,

  setLabAlreadyRequested,
  setShowLinkAdvisory,
  setCheckoutLinkRequested,
  setCheckoutLinkOpened,


}){

  const dispatch = useDispatch();

  let requestResponse = useSelector(state => {
    return G.getRequestResponse(state,{route:"/getCheckoutLink"})
  })

  const responsePending = requestResponse?.pending === true;

  return (

    <button 
      button={!disabled ? "checkout-link":""}
            style={{fontSize:12, width:180}}
            class="btn btn-success" 
            disabled={disabled || (plurality === "lab" && labAlreadyRequested) || responsePending}
            onClick={() => {

              dispatch(A.requestCheckoutLink({
                selectedPaymentCycle,
                institutionType,
                plurality,
              }))

              if( plurality === "lab" ){
                setLabAlreadyRequested(true);
                setShowLinkAdvisory("lab");
              }else{

                setShowLinkAdvisory(false);
                setCheckoutLinkOpened(false);
                setCheckoutLinkRequested(true);
              }
              dispatch(A.makeRequest({
                route:'/getCheckoutLink',
                body:{
                  plan:([selectedPaymentCycle,institutionType].join('-')),
                  plurality
                },
                onSuccess:() => {},
                onFailure:() => {},
              }))
              
            }}>
              <div>
                <div style={{fontSize:16}}><b>Checkout</b></div>
                {CHECKOUT_COMMENT && <div style={{fontSize:10}}>
                  <div>{getComment([plurality,selectedPaymentCycle,institutionType])}</div>
                </div>}
              </div>
          </button>
  )

}
