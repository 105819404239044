import {createElement} from 'react';
import { useSelector } from 'react-redux';
import G from './Getters';
import {ErrorBoundary} from 'react-error-boundary';

//import PaymentModal from './PaymentModal';
import NewFeaturesModal from './NewFeaturesModal';
import CommentModal from './CommentModal';
import SigninModal from './SigninModal';
import QuantificationModal from './QuantificationModal';
import DocumentationModal from './DocumentationModal';
import AccountSettingsModal from './AccountSettingModal';
import CustomModal from './CustomModal';

export const PAYMENT = "PAYMENT"; 
export const COMMENT = "COMMENT";
export const NEW_FEATURES = "NEW_FEATURES";
export const SIGNIN = "SIGNIN";
export const REPORT_BUG = "REPORT_BUG";


const ModalMap = {
  // [PAYMENT]:PaymentModal,
  [NEW_FEATURES]:DocumentationModal,
  [COMMENT]:CommentModal,
  [SIGNIN]:SigninModal,
  [REPORT_BUG]:CommentModal,
  SETTINGS:AccountSettingsModal,
}

function getModalComponent(modal,setModal){


  let modalName;
  let modalArgs;

  let typeofModal = typeof(modal);
  if( typeofModal === typeof('') ){
    modalName = modal;
    modalArgs = {};
  }else if( typeofModal === typeof({}) ){
    modalName = modal.name;
    modalArgs = modal.args;
  }


  return createElement(
    ModalMap[modalName],
    {
      args:modalArgs,
      closeModal:()=>setModal()
    }
  )
}


export default function AppModal({modal, setModal}){

  const topMessage = useSelector(G.getTopMessage);


  

  let modalComponent = (
    <>

      {topMessage && (

        <ErrorBoundary 
          onError={() => {
            //alert("alert from errr boundry");
          }}
          FallbackComponent={() => 
              (
                <div>
                  Error fallback.
                </div>
              )
          }>
          <CustomModal {...{messageObject:topMessage,modal, setModal}}/>
        </ErrorBoundary>
      )}


      {modal && getModalComponent(modal,setModal)}

    </>
  )


  return ( modalComponent )




}
