import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import G from './Getters';
import { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';


export default function NumberToolbarItem({item,unifiedGroup,editorStyle, 
  selectedFigureItems,
  setRetainInputFocus,
  panelGlobalStyle,
  figurePanelId
}){


  let selectedCells = selectedFigureItems.cells || [];
  let selectedNodes = selectedFigureItems.expansionNodes || [];
  const dispatch = useDispatch();

  const [ isValid, setIsValid ] = useState(true);

  let isGlobal = item.scope === 'global';
  let isLocal = item.scope === 'local';
  let isColumnWidth = item.styleProperty === 'columnWidth';



  const handleChange = e => {

       
    let newValue = e.target.value;
    let value = Number(newValue);



    let args = {
      uniGroupStyle:unifiedGroup.style[item.styleProperty],
      uniGroup:unifiedGroup[item.styleProperty],
      defaultValue,
      value
    }


    let min = item.minValue ?? Number.NEGATIVE_INFINITY;
    let max = item.maxValue ?? Number.POSITIVE_INFINITY;

   
    let isNumber = /^-?\d+$/.test(newValue)
    let withinRange = isNumber && (min <= value && value <= max);

    /*
    if( !withinRange ){
      alert("value = " + value+"; ("+ min + ", " + max+"); isNumber = " + isNumber);
    }*/

    let isNewValueValid = isNumber && withinRange;
   

    setTempValue(newValue)


    if( !isNewValueValid ){
      setIsValid(false);
      return;
    }else if( !isValid ){
      setIsValid(true);
    }

    let style = {[item.styleProperty]:value}

    

    if( isColumnWidth ){
      let widths = Array(selectedCells.length).fill(value);
      dispatch(A.setCellWidths({
        figurePanelId,
        cellsToSet:selectedCells,
        widths
      }));
    }else if( isGlobal ){
      dispatch(A.setFigurePanelStyle({style,figurePanelId}))
    }else if( isLocal ){
      let isColumnWidth = item.styleProperty === 'columnWidth';
      let toSet = isColumnWidth ? style : {style}


      let { activatesProperties } = item;
      if( activatesProperties && !isColumnWidth ){
        Object.entries(activatesProperties).forEach(([property,value]) => {
          if( !unifiedGroup.style[property] ){


            toSet.style[property] = value;
          }
        })
      }

      if( true || selectedCells.length > 0 ){

        dispatch(A.setCellsValue({
          figureItems:selectedFigureItems,
          figurePanelId,
          ...toSet
        }))

      }
      else if( selectedNodes.length > 0 ){

        let taregetNode = selectedNodes[0];
        let { nodeId, cellLocation } = taregetNode;
        //let targetNodeId = selectedNodes[0].nodeId;
        dispatch(A.setCellsValueProperties({
          figurePanelId,
          cells:[cellLocation],
          nodeId,
          properties:{
            ["nodeOverrides."+nodeId+"~style~"+item.styleProperty]:value
          }

        }))

      }
    }
  }


  let valueToDisplay;
  let defaultValue = item.defaultValue;
 


  let disabled = isLocal && (selectedCells.length === 0 && selectedNodes?.length === 0);
  let disabledEvalFunction = item.disabledEval;
  if( disabledEvalFunction ){
    let evalDisabled = disabledEvalFunction({unifiedGroup,selectedFigureItems});
    disabled = disabled || evalDisabled;
  }

  if( !disabled ){
    if( isLocal ){

      if( !unifiedGroup ){
        debugger;
      }


      valueToDisplay = (
        (unifiedGroup?.style||{})[item.styleProperty] ?? unifiedGroup[item.styleProperty] ?? defaultValue
      )

    }else{
      valueToDisplay = (panelGlobalStyle && panelGlobalStyle[item.styleProperty]) ?? editorStyle[item.styleProperty] ?? item.defaultValue;
    }

  }

  
  
  const [toDisplay,setToDisplay] = useState(valueToDisplay);
    const [tempValue,setTempValue] = useState();

    useEffect(() => {
      if( valueToDisplay !== toDisplay ){
        setToDisplay(valueToDisplay);
        setTempValue();
      }
    },[valueToDisplay,toDisplay])






  let finalResolvedValueToDisplay = tempValue ?? toDisplay;


  return (
    <div style={{padding:4,alignItems:'center'}} class="sg-row">

      {item.hint && <Tooltip title={item.hint}>
        {item.icon}
      </Tooltip>}
      {!item.hint && item.icon}

      <div style={{fontSize:12}}>{item.text}</div>
      <div class="flex-expand"/>
      <input 
        item={item.id || item.menuItem}
        type="number" 
        style={{
          marginLeft:4,
          width:(""+(item.maxValue??defaultValue)).length >=3 ? 40 : 40, 
          fontSize:10,
          background:(!isValid?'pink':'unset'),
          border:'1px solid #bbb',
          borderRadius:2,
        }} 
        onClick={() => {
          setRetainInputFocus(true)
        }}
        onBlur={() => {
          setIsValid(true);
          setRetainInputFocus(false)
          setTempValue();
          setToDisplay();
        }}
        value={finalResolvedValueToDisplay}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  )
}
