import FigureImageExpansionTemplateNode from './FigureImageExpansionTemplateNode';
import { ExpansionTemplateInfo } from './Contexts';
import { useContext } from 'react';

export default function FigureImageExpansionTemplateLayout({
  nodeId, 
  nodes,
  templateId,
  setContextMenuConfig,
}){

  let { computedDimensions } = useContext(ExpansionTemplateInfo);

  
  let node = nodes[nodeId];

  let { d, c, value } = node || {};

  let parent = nodes[ node.p ];



  let parentStyle = node.style || {};

 
  let nodeDims = computedDimensions[nodeId] || { w:1, h: 1};


  let isRoot = nodeId === "root";


  let nodeWidth = (nodeDims.w) * 100 + '%';
  let nodeHeight = (nodeDims.h) * 100 + '%';
  let aspectRatio;

  if( isRoot ){
    nodeWidth = '100%';
    nodeHeight = undefined;
    aspectRatio = `${nodeDims.w} / ${nodeDims.h}`
  }

  return (
    <div class={(d === "h" ? "sg-row" : "sg-col")  } style={{

      width:nodeWidth,
      height:nodeHeight,
      aspectRatio,
      position:'relative',
    }}>
      
      {value && <div class="full-width full-height flex-expand">
        <FigureImageExpansionTemplateNode {...{
          node, nodeId, nodes,
          setContextMenuConfig,
          templateId,
        }}/>
      </div>}

      {!value && (c||[]).map(nodeId => {
        return(
          <FigureImageExpansionTemplateLayout key={nodeId} {...{nodeId, nodes, 
            templateId, setContextMenuConfig}}/>
        )
      })}
    </div>
  )



}
