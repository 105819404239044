const HardCodedMicroscopyTemplateMap = {
    bb0:{ channelStructure:"012 0 1 2", regions:[{"012,0,1,2":{location:"above"} }] },

    basic:{ 
      channelStructure:"012 0 1 2", 
      regions:[
        {
          "012,2":{ // applies to 012,2 channels
            location:"inside",
            position:{ base:"bl" },
            style:{
              maxWidth:'50%',
              //background:'yellow',
            }
          }
        }
      ],
      /*
      scaleBar:[
        {
          applyAllChannels:{
            position:{ base:"br" },
            style:{ color:"white", },
            length:100,
            units:"μm",
            text:false
          },
          "012":{
            text:true
          }
        }
      ],
      */

      text:[
        { 
          applyAllChannels:{ 
            position:{ base:"tl", }, 
            label:"Label", 
            style:{ fontWeight:"bold", background:"black" } 
          }, 
          "012":{ "style.color":"white" },
          "0":{ "style.color":"red" },
          "1":{ "style.color":"lime" },
          "2":{ "style.color":"skyblue" } 
        }
      ] 
  },


    below:{ 
      channelStructure:"012 0 1 2", 

      /*
      regionStructure:"I_bl", 
      regionChannels:"012 2", 
      */

      regions:[
        {
          "012,0,1,2":{ // applies to 012,2 channels
            location:"below",
            //position:{ base:"bl" },
            /*style:{
              maxWidth:'50%',
              //background:'yellow',
            }*/
          }
        }
      ],

      text:[
        { 
          applyAllChannels:{ 
            position:{ alignItems:'start', justifyContent:'start' }, 
            label:"Label", 
            style:{ fontWeight:"bold", background:"black" } 
          }, 
          "012":{ "style.color":"white" },
          "0":{ "style.color":"red" },
          "1":{ "style.color":"lime" },
          "2":{ "style.color":"skyblue" } 
        }
      ] 
    }
  }

export default HardCodedMicroscopyTemplateMap;
