const width = 3;

const handleDim = 7;

export default function LaneSqueezerHandle({onMouseDown,side,sideInset}){

  return (

    <div 
        onMouseDown={onMouseDown}
      
      style={{
        cursor:'ew-resize',
        //border:'1px solid red',
        height:'calc(100% + 5px)',
        position:'absolute',
        [side]:(sideInset - width/2),
        top:0,
        width,
        overflow:'visible',
        //background:'black',
        zIndex:20,
        //transform:'translate(-50%,0)'
      }}>
      {true && <div onMouseDown={onMouseDown} style={{position:'absolute',
        top:-handleDim/2,
        left:'50%',
        transform:'translate(-50%,0)',
        width:handleDim,
        height:handleDim,
        borderRadius:handleDim,
        background:'blue',
        cursor:'pointer',
      }}>{false && sideInset}</div>}

      {true && <div onMouseDown={onMouseDown} style={{position:'absolute',
        cursor:'pointer',
        bottom:-handleDim/2,
        left:'50%',
        transform:'translate(-50%,-50%)',
        width:handleDim,
        height:handleDim,
        borderRadius:handleDim,
        background:'blue',
      }}>{false && sideInset}</div>}
      </div>

  )
}
