import { useEffect } from 'react';
function captureEvent(onClose){
  return function(e){

    //let path = e.composedPath();
    let path = e.nativeEvent.composedPath();

    let menuItemSelected = path.some(el => el.className && el.className.includes && el.className.includes("sg-context-menu") );


    if( !menuItemSelected ){
      e.preventDefault();
      e.stopPropagation();
      onClose();
    }
  }
}

const listenerArgs = {capture:true};

export default function SciugoContextMenu({options,x,y, onClose}){


  const listener = captureEvent(onClose);

  /*
  useEffect(() => {

    

    
    window.addEventListener('click',listener,listenerArgs);
    window.addEventListener('contextmenu',listener,listenerArgs);

    return () => {
      window.removeEventListener('click',listener);
      window.removeEventListener('contextmenu',listener);
    }

  });
  */



  return (
    <div 
      onClickCapture={listener}
      onContextMenuCapture={listener}
      style={{
      top:0,
      left:0,
      width:'100%',
      height:'100%',
      position:'fixed',
      //background:'rgb(0,0,0,0.5)',
      zIndex:10000,


    }}>
    <div 
      onClick={e => e.stopPropagation()}
      style={{
        position:'fixed',
        minWidth:100,
        background:'white',
        top:y,
        left:x,
        zIndex:100,
        border:'1px solid #ccc',

        //boxShadow:'0 2px 4px rgba(0 0 0 / 10%), 0 8px 16px rgba(0 0 0 / 10%)',
        boxSizing:'border-box',


      }}
      class="sg-context-menu">
      {options.map(({text,onClick,disabled}) => {
        return (

          <div
            style={{
              color:(disabled ? '#ccc' : 'unset'),
            }}
            item={"contextMenuItem"}
            contextMenuItem={text}
            onClick={() => {
              if( !disabled && onClick ){
                onClick();
                onClose();
              }
            }} class={`tiny-margin hoverable`}>{text}</div>

        )

      })}
    </div>
    </div>
  )



}
