import SubscribeScene from './SubscribeScene';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import G from './Getters';
import RefreshAccountMessage from './RefreshAccountMessage';
import RefreshAccountButton from './RefreshAccountButton';

import SubscriptionActivityMessage from './SubscriptionActivityMessage';


import CollapsibleSection from './CollapsibleSection';

import CancelSubscription from './CancelSubscription';

export default function SubscriptionSettings(){

  //const [showPricing, setShowPricing] = useState(true);

  const refreshNeeded = useSelector(state => state.warnings.subscriptionRefereshNeeded);

  const { 
    isCancelled,
    subscriptionActivityMessage, 
    expiry,
    productsWithExpiredSubscriptions } = useSelector(state => ({
      subscriptionActivityMessage:G.getSubscriptionActivityMessage(state,{productId:"sciugoMain"}),
      productsWithExpiredSubscriptions:G.getProductsWithExpiredSubscriptions(state),
      isCancelled:G.isSubscriptionCancelled(state,{productId:"sciugoMain"}),
      expiry:G.getSubscriptionExpiry(state)

    }));

  let inPreTrial = expiry === "never";

  let pricingDefaultOpen = inPreTrial || productsWithExpiredSubscriptions.includes("sciugoMain");


  let subActivityComponent = <SubscriptionActivityMessage {...{
    subscriptionActivityMessage,
    productsWithExpiredSubscriptions
  }}/>

    


    return (
      <div 
        class="sg-col text-body"
        style={{

          minHeight:0,
          width:'100%',
          borderBottom:'1px solid #ccc',
          position:'relative',
          overflowY:'auto',
        }}>

        <div 
          info={"subscriptionActivityMessage"} class="sg-row v-align">
          {subActivityComponent}
        </div>


        <div style={{ padding:10, }}
          class="sg-row v-center">
          {refreshNeeded && (
            <RefreshAccountMessage/>
          )}

          <div class="flex-expand"/>
          <RefreshAccountButton/>

        </div>


        <CollapsibleSection {...{
          defaultOpen:pricingDefaultOpen,
          sectionTitle:"Upgrade plans",
        }}>
          <SubscribeScene background={"#ddd"}/>
        </CollapsibleSection>

        {!inPreTrial && !isCancelled && <CollapsibleSection {...{
          sectionTitle:"Cancel renewal"
        }}>

          <CancelSubscription/> 
        </CollapsibleSection>}


      </div>



    )
}
