import ImageAnnotationModal from './ImageAnnotationModal';
import ImageResolutionSpecificationModal from './ImageResolutionSpecificationModal';
import React from 'react';

import Modal from '@material-ui/core/Modal';

const modalMap = {
  imageAnnotation:ImageAnnotationModal,
  setImageResolution:ImageResolutionSpecificationModal,
}

export default function FigureEditorModal({
  modalArgs,
  onClose,
  onEvent,
}){


  if( !modalArgs ){
    return null;
  }


  let { modal } = modalArgs;

  let ele = modalMap[modal];
  if(!ele){
    throw Error(`No modal registered for '${modal}'.`);
  }

  return <Modal style={{
    width:'90%', left:'5%',
    height:'90%', top:'5%',
    border:'3px solid #eee',
    background:'white',
    borderRadius:4,
  }} 
    open={true} 
    onClose={onClose}>


    <div style={{background:'white', 
      borderRadius:4,
      height:'100%'}}>

      {React.createElement(
        ele,
        {...modalArgs,onEvent,onClose}
      )}
    </div>
  </Modal>










}
