import ColorPicker from './ColorPicker';
import { useState } from 'react';
import CollapsibleSection from './CollapsibleSection';
export default function SpacingEditor({targetNode,onEvent,data,args,}){


  //let { style={} } = data || {};
  let { margin=0, borderWidth=0, borderColor="#ffffff", padding=0 } = data.style || {};


  let [ colorPopoverOpen, setColorPopoverOpen ] = useState(false);


  let outerPadding = data?.style?.padding||0;
  let innerPadding = data?.style?.borderWidth||0; 

  return (
    <div>
      

        <div class="sg-row">

      <div style={{width:120}}> Margin:</div>

      <input onChange={(e) => { 

        onEvent && onEvent({
          type:"change",
          event:e,
          properties:{
            "style.margin":Number(e.target.value)
          }
        })
      }}
        value={margin} style={{width:50}} type={"number"} max={10} />

      </div>



    <div class="sg-row">

      <div style={{width:120}}> Outer:</div>

      <input onChange={(e) => { 

        onEvent && onEvent({
          type:"change",
          event:e,
          properties:{
            "style.padding":Number(e.target.value)
          }
        })
      }}
        value={outerPadding} style={{width:50}} type={"number"} min={0} max={10} />
    </div>

    <div class="sg-row">
      <div style={{width:120}}> Inner:</div>
      <input value={innerPadding} style={{width:50}} onChange={e => {
        console.log("HELLO?");
        onEvent && onEvent({
          type:"change",
          event:e,
          properties:{
            "style.borderWidth":Number(e.target.value)
          }
        })
      }} type={"number"} min={0} max={10} />
    </div>

    <div class="sg-row">
      <div style={{width:120}}> Inner color:</div>

      <div onClick={() => {
        setColorPopoverOpen(!colorPopoverOpen);
      }} style={{
        position:'relative',
        width:20,
        height:20,
        borderRadius:2,
        background:(borderColor),
        border:'1px solid #aaa',
      }}>


      </div>

      {colorPopoverOpen && <ColorPicker {...{
        onChange:(color) => {
          onEvent && onEvent({
            type:"change",
            properties:{
              "style.borderColor":color
            }
          })
        }
      }}/>}

    </div>
    </div>

  )
}
