function template(){
  return {
	date:date(),
	cells:[],
	plating:[plating()],
	conditions:[condition()],
	times:[times()],
	temperatures:[temperatures()],
	
  }
}

function plating(){
  return 0;
}
function dose(){
  return '0';
}

function compound(){
  return {
	compound:'',
	doses:[dose()]
  }
}

function condition(){
  return [compound()]
}

function times(){
  return {
	value:"0",
	units:"hours"
  }
}

function temperatures(){
  return {
    value:"37",
    units:"°C"
  }
}

function date(){
  return undefined;
  /*
  let date = Date.now()
  return date.toLocaleString(
	'en-US',{ day:'numeric', month:'long', year:'numeric'}
  )
  */
}

module.exports = {
  date,
  template,
  plating,
  condition,
  times,
  temperatures,
  compound,
  dose
};

