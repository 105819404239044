export default function MicroscopyFilters(){
  return (
    <svg style={{pointerEvents:'none',position:'fixed'}} xmlns="http://www.w3.org/2000/svg" version="1.1">
  <filter id="red-channel-filter">
    <feComponentTransfer>
      <feFuncR type="identity"/>
      <feFuncG type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
      <feFuncB type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
    </feComponentTransfer>
  </filter>

  <filter id="green-channel-filter">
    <feComponentTransfer>
      <feFuncR type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
      <feFuncG type="identity"/>
      <feFuncB type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
    </feComponentTransfer>
  </filter>

  <filter id="blue-channel-filter">
    <feComponentTransfer>
      <feFuncR type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
      <feFuncG type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
      <feFuncB type="identity"/>
    </feComponentTransfer>
  </filter>

  <filter id="red-green-channel-filter">
    <feComponentTransfer>
      <feFuncR type="identity"/>
      <feFuncG type="identity"/>
      <feFuncB type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
    </feComponentTransfer>
  </filter>

  <filter id="red-blue-channel-filter">
    <feComponentTransfer>
      <feFuncR type="identity"/>
      <feFuncG type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
      <feFuncB type="identity"/>
    </feComponentTransfer>
  </filter>

  <filter id="green-blue-channel-filter">
    <feComponentTransfer>
      <feFuncR type="discrete" tableValues="0 0 0 0 0 0 0 0"/>
      <feFuncG type="identity"/>
      <feFuncB type="identity"/>
    </feComponentTransfer>
  </filter>

  <filter id="rgb-channel-filter">
    {false && <feComponentTransfer>
      <feFuncR type="identity"/>
      <feFuncG type="identity"/>
      <feFuncB type="identity"/>
    </feComponentTransfer>}
  </filter>


    <filter id="magentaTintFilter" color-interpolation-filters="sRGB">
    <feColorMatrix type="matrix" values="1 0 0 0 0
                                          0 0 0 0 0
                                          0 0 1 0 0
                                          0 0 0 1 0" />

    <feComponentTransfer>
      <feFuncR type="linear" slope="0.6" />
      <feFuncG type="linear" slope="0.1" />
      <feFuncB type="linear" slope="0.6" />
    </feComponentTransfer>
  </filter>
</svg>

  )
}
