import { WELCOME, QUANTIFICATION, ANNOTATION } from './UIConstants';

const defaultMode = WELCOME;


export default function getDefaultUiStateSlice(){
  return {
    mode:defaultMode,
    modeArgs:{},
    [QUANTIFICATION]:{
      selectedAnnotations:[]
    },
    [ANNOTATION]:{
      selectedFilesystemItem:null,
    }
  } 
  
}
