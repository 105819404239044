import Overview from './svg/Overview.svg';
export default function WelcomeOverview(){
  return(
    <div class="flex-expand" style={{
      
      position:'relative',
      width:'100%',
      minHeight:0,
      maxHeight:'100%',
      //height:'100%',
      //border:'3px solid red',
      //overflowY:'scroll',
      }}>
      <div style={{position:'relative',
        overflowY:'scroll',
        }} class="full-width">
      {true && <img style={{
        //height:'80%',
        //border:'3px solid magenta',
        objectFit:'contain',
        minHeight:0,
      }} src={Overview}/>}
        </div>
    </div>
  )
}
