import { useDispatch } from 'react-redux';
import NewPanelButton from './NewPanelButton';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import A from './ActionCreators';

export default function FigureFilesystemCreationContainer({
  activeFilesystemRoot,
  setNewPanelFilesystemRootTarget
}){

  let dispatch = useDispatch();

  return (

    <div style={{
          justifyContent:'center', 
          alignItems:'center',
          //border:'2px solid magenta',
          background:'#fafafa',

        }} 
          class="sg-row small-padding">



          <NewPanelButton {...{
            activeFilesystemRoot,
            setNewPanelFilesystemRootTarget
          }}/>






          {true && <div button="create-directory" style={{
            background:'#f4f4f4',
            borderRadius:25,
            height:45,
            display:'flex',
            alignItems:'center',
            zIndex:20,
            marginLeft:10
          }}
            onClick={() => {
              dispatch(A.createItem({
                itemType:"directories",
                inTopLevelParent:activeFilesystemRoot,
              }))
            }}
            class="small-padding full-height flex v-center dark-hoverable round-corners dark-active">




            <CreateNewFolderIcon />
          </div>}
        </div>
  )
}
