// /** @jsxImportSource @welldone-software/why-did-you-render */
import React from 'react';

if (false && process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  //const ReactRedux = require('react-redux');

  console.log({whyDidYouRender});
  if( true ){

    whyDidYouRender(React, {
      include:[
        //  /^ConnectFunction$/,
        ///FigureEditorContainer/,/GridCell/,
        ///FigureImageExpansionContainer/,
        ///Navbar/,
        /ManualAnnotationGrid/,
        ///FigureImageExpansionLayout/,
      ],
      /*trackExtraHooks:[
      [require('react-redux/lib'), 'useSelector'],
    ]*/
      //trackAllPureComponents: true,
    });
  }
}
