import TableActionToolbar from './TableActionToolbar';

export default function ContextMenu({
  y,
  x,
  onClose,
  figurePanelId,
  selectedFigureItems, 
  setSelectedCells,
  items,
  style={},
  onEvent,

}){



  if(!figurePanelId){
    throw Error("MAG Context menu was not passed `figurePanelId`");
  }


  let content;
  if( selectedFigureItems?.cells?.length ){
    content = (
      <TableActionToolbar 
        {...{
          figurePanelId,
          selectedFigureItems,
          setSelectedCells,
        }}
        location={"context-menu"}
        onItemClicked={onClose}
        shouldSplitText={false}
        itemOrder={['merge','line','deleteRow','deleteColumn','line','split']}
      />
    )
  }else{
    content = items?.map(item => {
      let { title, action, type } = item;

      //let resolvedTitle = title?.replace(/[A-Z]/g,(m) => ' ' + m.toLowerCase()).replace(/^./,(m) => m.toUpperCase()) 

      return (
        <div 
          contextMenuItem={action}
          onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onEvent({
            event:e,
            ...item
          });

          onClose();

        }}
        style={{
          pointerEvents:'auto',
          lineHeight: 1,
          borderRadius:0,
          textAlign:'left',
        }} class="toolbar-action-button toolbar-button hoverable">
          {title}
        </div>

      )
    })
  }



  

  return (
    <div class="sg-context-menu"

      onClick={e => e.stopPropagation()}

      style={{position:'fixed',minWidth:100,
        background:'white', 
        top:y, left:x, zIndex:100,
        border:'1px solid #ccc', 

        //borderRadius:5,

        boxShadow:'0 2px 4px rgba(0 0 0 / 10%), 0 8px 16px rgba(0 0 0 / 10%)',
        boxSizing:'border-box',
        ...style,

      }}>
      {false && <div>
        {JSON.stringify(items)}
      </div>}
      {content}

      


    </div>
  )
}
