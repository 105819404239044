import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import { useEffect } from 'react';

export default function QuantificationAnnotationUpdateMenu({ 
  annotationId,
  unsyncedProperties,
  setUpdateMenuActive,
  setActiveUpdatePreviewProperty,
}){


  useEffect(() => {
    if( !unsyncedProperties || unsyncedProperties.length === 0 ){
      setUpdateMenuActive(false);
    }
  },[unsyncedProperties,setUpdateMenuActive])
  

  let dispatch = useDispatch();

  let itemPropertyMap = ({window:"Crop window",imageId:"Image"});

  return (

    <div style={{
      border:'5px solid #ccc',
      borderRadius:5,

      width:'100%',
      height:'100%',
      display:'flex',
      flexDirection:'column',
      alignText:'center', 
      justifyContent:'center',
      alignItems:'center',
      margin:20,
      padding:20,
      background:'#eee',
      position:'relative',
    }}>
      <div style={{
        //border:'5px solid yellow',
        //borderRadius:5,
      }}>
        <div style={{textAlign:'center',
          fontSize:18,
          //fontWeight:'bold',
        }}>Changes Detected</div>
        {unsyncedProperties.map(prop => {
          return (
            <div class="sg-row">
              <div style={{
                margin:15,
                marginBottom:5,
              }}>{itemPropertyMap[prop]}</div>
              <div class="flex-expand"/>
              <button 
                onMouseEnter={() => {
                  setActiveUpdatePreviewProperty(prop)
                }}
                onMouseLeave={() => {
                  setActiveUpdatePreviewProperty();
                }}
                onClick={() => {
                  dispatch(A.updateQuantificationParameters({annotationId,params:[prop]}));

                }}
              item={"quantificationPropertyUpdater"} property={prop} class="crop-update-button">Update</button>
            </div>
          )
        })}
      </div>


      <div class="flex-expand"/>

      <div 
        onClick={() => {
          dispatch(A.markPossibleQuantificationAnnotationUpdatesSeen({_id:annotationId}));
          setUpdateMenuActive(false);
        }}
        action={"closeQuantificationParamUpdateMenu"}
        style={{
          position:'absolute',
          left:0,
          top:0,
          transform:'translate(-50%,-50%)',
          background:'white',
          borderRadius:20,
          cursor:'pointer',
        }}>
        <HighlightOffIcon/>
      </div>

    </div>

  )

}
