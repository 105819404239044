import usePresentSelector from './usePresentSelector';
import sendImageToServer from './sendImageToServer';
import G from './Getters';
import { useState, useEffect, createRef } from 'react';
import SvgRendering from './SvgRendering';
import { FIGURE_PANELS } from './RecordTypes';
import Id from './IdFactory';
import { ErrorBoundary } from 'react-error-boundary';

export default function SvgGrid({imageUrlMap, 
  notifyFinishedDownload,
  state,
  download,
  figurePanelId
}){

  let exportId = Id();

  if(!figurePanelId){throw Error("figurePanelId cannot be undefined.")}


  let figurePanel = state.data.figurePanels[figurePanelId];

  const svgRef = createRef();

  let cellIdGrid = figurePanel.grid;
  let cellValueMap = figurePanel.cellGroups;

  const filename = G.getFilesystemName(state,{_id:figurePanelId,type:FIGURE_PANELS});
  
  useEffect(() => {
    if( svgRef && svgRef.current ){
      //console.log("SvgGrid useEffect!");

      let s = new XMLSerializer();
      let svg = document.getElementById('svg-figure');
      let svgData = s.serializeToString(svg);
      var svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
      var svgUrl = URL.createObjectURL(svgBlob);

      sendImageToServer(svgBlob,filename,null,null,"Exported Figure");

      let isLocalhost = (
        window.location.href.includes('localhost')
      )
      if(download===true){
        var downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = filename + '.svg';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }

      notifyFinishedDownload(isLocalhost && svgUrl);

    }
  }, [svgRef,download]);


  let figureDiv = document.getElementById('figure');
  if( !figureDiv ){
    return null;
  }
  let figureBounds = figureDiv.getBoundingClientRect();
  let gridItems = Array.from(document.querySelectorAll('td[id^=grid-]'));

  console.log(gridItems.map(x => x.id));

  let viewBox = [0,0,figureBounds.width,figureBounds.height].join(' ');

  let [gridX,gridY] = [figureBounds.x, figureBounds.y];

  return (
    <svg ref={svgRef} id={"svg-figure"} viewBox={viewBox}>
      <rect style={{fill:'white'}} x="0" y="0" width="100%" height="100%"/>

      <ErrorBoundary onError={(error,info) => {
            debugger;
            notifyFinishedDownload();
          }} FallbackComponent={()=><div/>}>

      {gridItems.map( div => {
        let coord = div.id.split('-').slice(1).map(Number);
        let cellId = cellIdGrid[ coord[0]][ coord[1] ]
        let value = cellValueMap[cellId];

        return (
                    <SvgRendering 
          exportId={exportId}
          figurePanel={figurePanel}
        state={state} value={value} div={div} origin={[gridX,gridY]} cell={coord} imageUrlMap={imageUrlMap}/>
          )
      })}

      </ErrorBoundary>

    </svg> 
  )



}
