import { useState, useEffect } from 'react';
import A from './ActionCreators';
import { useDispatch } from 'react-redux';
import EditableContent from './EditableContent';
export default function ExpansionTextItem({
  node,
  nodeId,
  selected,
  label,
  textStyle,
  onEvent,
}){

  let [editing,setEditing] = useState(false);
  let [tempValue,setTempValue] = useState(label);

  useEffect(() => {
  },[label])

  
   

  if( editing ){


    let onFinishedEditing = (value) => {



      onEvent && onEvent({
        type:"setLabel",
        itemType:"expansionNode",
        //event:e,
        node,
        nodeId,
        value
      })

      setEditing(false);
      //setTempValue();

      

    }
    

    return (
      <EditableContent nodeId={nodeId} 


        class="sg-col"

        editable={editing}
        onEnter={onFinishedEditing}
        onBlur={onFinishedEditing}

        onChange={value => {
          //setTempValue(value)
        }}

        content={label}
        style={{
          ...textStyle,
          position:'relative',
          pointerEvents:'auto',
          //border:'3px solid blue',
        }}
        focusOnRender={true}

      />
    )
  }else{


    let onSomeClick = e => {

      e.preventDefault();

        e.stopPropagation();
        onEvent && onEvent({
          type:e.type,
          itemType:"expansionNode",
          event:e,
          node,
          nodeId,
        })
        //onItemClick && onItemClick(nodeId);

      }

    return (
      <div nodeId={nodeId} onClick={onSomeClick} 
        onContextMenu={onSomeClick}
        class="sg-col"

        onDoubleClick={() => {
          setEditing(true)
        }}

        style={{
          ...textStyle,
          position:'relative',
          top:0,
          left:0,
          pointerEvents:'auto',
        }}>
        {label}
        {selected && <div class="selected-item-cover"/>}
      </div>
    )
  }
}
