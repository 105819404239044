import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import A from './ActionCreators';
import G from './Getters';
import { FIGURE_PANELS } from './RecordTypes';
import MolecularWeightSideOption from './MolecularWeightSideOption';
import Id from './IdFactory';

const opposite = { right:"left", left:"right" };

export default function GelPanelSpecification({
  setSelectedFigurePanelContext, setTargetDirectoryOfNewFigurePanel, targetDirectoryOfNewFigurePanel
}){

  const [ selectedMwSide, setSelectedMwSide ] = useState("right");

  let dispatch = useDispatch();
  const [lanes, setLanes] = useState(12);

  const shouldCreateFigureWithGuidelines = useSelector(state => G.getItemCount(state,{type:FIGURE_PANELS}) < 4);


  return (

    <div style={{
      fontSize:24,margin:70,
    }}>


      <div>
        Noº of figure gel lanes
      </div>
      <div style={{fontSize:16, margin:20}}>
        <i>How many sample bands will be in each crop?</i>
      </div>
      <div>
        <input style={{fontSize:24}} value={lanes} onChange={e => setLanes(Number(e.target.value))} style={{width:100}} min={1} type="number"/>
      </div>

      {true && <><div style={{fontSize:16, margin:20}}>
        <i>What side for <b>molecular weight</b> marks?</i>
      </div>
        <MolecularWeightSideOption {...{
          side:"left",
          selectedMwSide,
          setSelectedMwSide
        }}/>



        <MolecularWeightSideOption {...{
          side:"right",
          selectedMwSide,
          setSelectedMwSide
        }}/>
      </>
      }


      <button 
        button="acceptFigurePanelSettings"
        onClick={() => {
          let _id = Id();

          setTargetDirectoryOfNewFigurePanel(false);
          //setSelectedFigurePanelContext({_id, editing:true});

          dispatch(A.createItem({
            type:FIGURE_PANELS,
            figurePanelType:'westernBlot',
            _id,
            args:{
              left:1,
              gel:lanes,
              right:1,
              labelLayout:{
                [selectedMwSide]:["mw"],
                [opposite[selectedMwSide]]:["crop"]
              },
              includeGuides:shouldCreateFigureWithGuidelines,
              template:(targetDirectoryOfNewFigurePanel === "figurePanelTemplates")
            },
            onSuccess:[
              A.setSelectedFigurePanelContext({
                context:{
                  _id,
                  figurePanelId:_id
                }
              })
            ]
          }))
        }}


        class="btn btn-primary">Create Figure</button>
    </div>
  )
}
