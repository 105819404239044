import G from './Getters';
import C from './Constants';
import getVenn from './Venn';

import { setData } from './utils';

import CellCultureCreator from './CellCultureCreator';

import { produce } from 'immer';
//import produce from './SciugoProduceWithUndoRedo';
import getDefaultState from './DefaultState';
import {CellGroup} from './FigurePanelFactory';
import { getDefaultColumnWidths } from './FigurePanelFactory';
import addMetadataForRecordIfMissing from './addMetadataForRecordIfMissing';
import stateClearedOfUserData from './stateClearedOfUserData';

import getDefaultUiStateSlice from './getDefaultUiStateSlice';


import { 
  getResolvedItemTypeName, 
  getEntityRelationshipSchema,
  CONTAINS_ONE_OF_OTHER,
  CONTAINS_MANY_OF_OTHER 
} from './RecordTypes';

import {
  FIGURE,
  QUANTIFICATION,
  ANNOTATION,
} from './UIConstants';

import Dialog from './DialogConstants';

import onCreateItem from './onCreateItem';
import onDeleteItem from './onDeleteItem';
import queueAllObjectRecordsForSync from './queueAllObjectRecordsForSync';
 
import { DEFAULT_IMAGE_VERSION } from './SciugoDefaults';

import { getValidRecordType, DIRECTORIES,
  TEMPLATES,
  FIGURE_PANELS, ANNOTATIONS, 
  IMAGE_SETS, IMAGE_UPLOADS } from './RecordTypes';

import { FILESYSTEM_NAME, FILESYSTEM_PARENT_DIRECTORY } from './Filesystem';

import { PENDING } from './SyncConstants';

import queueUserConfigForSync from './queueUserConfigForSync';

import tutorialState from './savedStates/walkthroughSetup.json';


const raw = x => JSON.parse(JSON.stringify(x));

function archiveImages(draft,imageIds){

  imageIds.forEach(id => {
    archiveRecord(draft,IMAGE_UPLOADS,id)
  });

  let impactedImageSets = imageIds.map(imageId => {
    return G.getImageSetByImageId(draft,imageId);
  })

  impactedImageSets.forEach(imageSet => {
    if( G.isEmptyImageSet(draft,{imageSet}) ){
      archiveRecord(draft,IMAGE_SETS,imageSet._id)
      archiveAnnotationsLinkedToImageSet(draft,{imageSetId:imageSet._id});
    }else{
      pickNewImageSetFigureImage(draft,{imageSet})
    }
  })
  //archiveAllEmptyImageSets(draft);

  draft.archivedImages.push(...imageIds);

}

function insertNodeInEvaluatedTemplateNode(draft,action){

  let { figurePanelId, cellLocation, nodeId, args, inside, before, after } = action;
  //figurePanelId, localTemplateId, inside, before, after, templateId, d, nodeId, nodeData, newParentNodeId, cells } = action;


  let targetNodeId;
  if( inside ){
    targetNodeId = inside;
  }else{
    targetNodeId = before || after;
  }

  let evaluatedParentNode = G.getEvaluatedFigurePanelCellNodeValue(draft,{ figurePanelId, cellLocation, nodeId:targetNodeId, excludeVirtualNodes:true });
  let parentChildren = evaluatedParentNode.c;

  let newParentChildren = [...parentChildren,nodeId];

  let parentOverrideKey = `nodeOverrides.${targetNodeId}.c`;
  let parentOverrideValue = newParentChildren;


  /*
       we just need to create the overrides
       that correspond to inserting a node.
       in the INSIDE case, it's pretty simple:
   * create the node
   * add the id to the parent
   */


  let nodeKey = `nodeOverrides.${nodeId}`;

  //get evaluated parent's children
  //and then just add the child and overwrite the parent's children.

  setCellsValueProperties(draft,{ 
    figurePanelId, 
    cells:[cellLocation], 
    properties:{ 
      [nodeKey]:TemplateExpansionNodeFactory[args.type](action),
      [parentOverrideKey]:parentOverrideValue,
    } 
  })

}

function resetLanesAndClearQuantification(draft,{annotationId}){
  let atn = G.getData(draft, {itemType:ANNOTATIONS,_id:annotationId});
  delete atn.quantifications;
  atn.laneBoundaryPositions = Array(atn.laneBoundaryPositions.length).fill(["DEFAULT","DEFAULT"])
}

function createDialog(draft,action){

  let { type, addIfNotYetQueued, source, ...warning } = action;

  let { dialogName } = warning;

  let identicalDialogExists = draft.dialogs.find(dd => {
    let dialogNameEquality = dd.dialogName === dialogName

    let bothArgsArePresent = dd.args && warning.args;
    let argEquality = dd.args === warning.args;
    if( bothArgsArePresent ){
      if( JSON.stringify(dd.args) === JSON.stringify(warning.args) ){
        return true;
      }
    }else if( argEquality ){
      return true;
    }
  })

  if( identicalDialogExists ){
    return;
  }

  try{
    draft.dialogs.push(warning)
  }catch(e){
    debugger;
    //require('inspector').waitForDebugger();
    
  }


}

function queueAnonymousSessionDataForSync(draft){
  queueAllObjectRecordsForSync(draft);
  queueUserConfigForSync(draft);
}

function injectRecordsFromServerAndUpdateMediaReferences(draft,{records}){
  injectRecordsFromServer(draft,records)
  let imageUploadRecords = records[IMAGE_UPLOADS];
  createMediaReferences(draft,imageUploadRecords);
}

function mergePersistedUserDataWithCurrentSessionData(draft, { userConfig, firstLogin, surveys } ){

  let { userRootIds } = userConfig;
        mergeAnonymousSessionFilesystemWithRecordsInjectedFromServer(
          draft,userRootIds
        );

  draft.surveys = surveys;


}



function createMediaReferences(draft,imageUploadRecords){

  imageUploadRecords.forEach(record => {
    let { meta, data, _id } = record;
    if( meta.archived ){
      return;
    }
    let { versions } = data;

    draft.media[ _id ] = Object.fromEntries(
      Object.entries(versions).map(([v,_]) => ([v,{}]))
    )
  })

}

function updateSyncStatus(draft,syncObject,updateKey){

  let { syncId } = syncObject;
  //update records
  Object.entries(syncObject.data.records).forEach(
  ([itemType,itemsToSync]) => {


    



    Object.entries(itemsToSync).forEach(
    ([itemId,item]) => {

      let draftItemTypeContainer = draft.syncStatus.records[ itemType ];

      if( !draftItemTypeContainer[itemId] ){
        throw Error("Sync status records container is missing ("+itemId+").");
      }
      draftItemTypeContainer[itemId].status = updateKey;
      draftItemTypeContainer[itemId].syncId = syncId;
    })
  })



  //update user config

  draft.syncStatus.userConfig.syncId = syncId;

  if( draft.syncStatus.userConfig.status === C.IDLE ){ 
    draft.syncStatus.userConfig.status = updateKey;
  }



}

function validateItemType(state,itemType){
  ['data','meta'].find(key => {
    if(!(itemType in state[key])){
      throw Error("Invalid itemType: '"+itemType+"'");
    }
  })
}


function setFilesystemName(draft,args){
  
  let {itemType,_id,newFilesystemName,parentDirectoryId} = args;
  setMeta(draft,args,{
    [FILESYSTEM_NAME]:newFilesystemName
  })

}


function injectRootsDirectly(keysToInjectFromPersisted,persistedRoots,objectToReceiveRootValues){
  keysToInjectFromPersisted.forEach(type => {
    objectToReceiveRootValues[type] = persistedRoots[type];
  })
}

function setMeta(draft,recordArgs,fields){
  let meta = G.getMeta(draft,recordArgs);
  Object.entries(fields).forEach(
    ([key,val]) => {
      meta[key] = val;
    }
  )
}

function addChildIdToNewParentDirectory(draft,args){

  let { parentDirectoryId, childId } = args;
  if( !parentDirectoryId ){
    throw Error("parentDirectoryId required! received: " + JSON.stringify(args))
  }
  if( !childId ){
    throw Error("childId required! received: " +JSON.strignify(args))
  }

  let dirData = G.getData(draft,{_id:parentDirectoryId});
  dirData.children.push({
    type:DIRECTORIES,
    _id:childId
  })

}

function moveSessionRootDirectoryToPersistedDirectory(draft,type,persistedRoots,sessionRoots){

  let rootIdToRename = sessionRoots[type];
  let directoryIdOfNewRoot = persistedRoots[type]; 

  let newFilesystemName = G.getNextAvailableFilesystemNameWithPrefixInDirectory(
    draft, { itemType:DIRECTORIES, _id: directoryIdOfNewRoot },
    "Saved work"
  )

  let setFilesystemNameArgs = { 
    _id:rootIdToRename, 
    itemType:DIRECTORIES, 
    newFilesystemName, 
    parentDirectoryId:directoryIdOfNewRoot
  };


  setFilesystemName(draft,setFilesystemNameArgs);


  let fieldsToSet = {
      [FILESYSTEM_PARENT_DIRECTORY]:directoryIdOfNewRoot
    }

  let recordArgs = setFilesystemNameArgs;
  setMeta(draft,recordArgs,fieldsToSet);

  addChildIdToNewParentDirectory(draft,{
    parentDirectoryId:directoryIdOfNewRoot,
    childId:rootIdToRename
  })








}

function repackageNonemptyAnonymousSessionRootDirectoriesAsSubdirectoriesOfPersistedRoots(draft,
  rootKeysInPersistedAndCurrentSession,
  persistedRoots, 
  sessionRoots
){

  rootKeysInPersistedAndCurrentSession.forEach(type => {
    let anonTopLevelDirectoryList = G.listDirectory(draft,
      {topLevelDirectory:type});

    //obviously we ignore anything that was "deleted" in
    //an anonymous session
    //I don't see why we'd hold on to those.
    if( anonTopLevelDirectoryList.length > 0 ){
      moveSessionRootDirectoryToPersistedDirectory(draft,type,persistedRoots,sessionRoots);
    }
  })


}



function mergeDatatypeRootDirectories(draft,persistedRoots){

  /*
   * Warning:
   * This function doesn't
   *
   */

  let sessionRoots = G.getDatatypeSpecificDirectoryRoots(draft);

  let persistedDatatypeSpecificDirectoryRoots = 
    persistedRoots.datatypeSpecificDirectoryRoots

  let venn = getVenn(
    persistedDatatypeSpecificDirectoryRoots,
    sessionRoots
  );

  let rootDatatypesFromServerNotInSession = venn.leftNotInRight;
  let rootDatatypesInSessionNotInServer = venn.rightNotInLeft;
  let rootDatatypesInBothSessionAndServer = venn.inBoth;


  repackageNonemptyAnonymousSessionRootDirectoriesAsSubdirectoriesOfPersistedRoots(draft,
    rootDatatypesInBothSessionAndServer,
    persistedDatatypeSpecificDirectoryRoots, 
    sessionRoots
  )

  let rootTypesToInject = [
    ...rootDatatypesFromServerNotInSession,
    ...rootDatatypesInBothSessionAndServer
  ]

    
  
  rootTypesToInject.forEach(itemType => {
    sessionRoots[itemType] = persistedDatatypeSpecificDirectoryRoots[itemType];
  })


  /*injectRootsDirectly(
    rootDatatypesFromServerNotInSession,
    persistedRoots, 
    sessionRoots
  );*/



}

function injectRecordsFromServer(draft,records){

  Object.entries(records).forEach(([itemType,items]) => {
    //beware, this will overwrite anything that's in there
    //so if you download out of date stuff,
    //it will throw it in...
    //
    //this means that if I were to broadcast
    //fetches after a login from, they would overwrite
    //stuff that isn't yet saved/sync'd to server


    items.forEach(item => {
      ['meta','data'].forEach(place => {
        if( !(itemType in draft[place] ) ){
          draft[place][itemType] = {} 
        }
        draft[place][itemType][item.data._id] = item[place];
      })
    })

  }) 
}

function mergeAnonymousSessionFilesystemWithRecordsInjectedFromServer(draft,userRootIds){
  mergeDatatypeRootDirectories(draft,userRootIds);
}

function setMediaURL(draft,{imageId,version,url}){

  if( !(imageId in draft.media) ){ 
    draft.media[ imageId ] = {}
  }

  let mediaInfo = draft.media[imageId]

  mediaInfo[version] = {
    pending:false,
    localBlobUrl:url
  }

}



function createItem(draft,data,meta,itemType,actionArgs){

  let objectTypeContainerName = itemType in draft.data ? itemType : itemType+'s';
  if( !(objectTypeContainerName in draft.data )){
    throw Error("'"+objectTypeContainerName+"' was not registered in state object types.");
  }

  validateItemType(draft,objectTypeContainerName);

  draft.data[objectTypeContainerName][data._id] = data;
  draft.meta[objectTypeContainerName][data._id] = meta;


  onCreateItem(draft,data,meta,itemType,actionArgs);


}



function Label({_id,lines,value}){
  return ({
    _id,
    lines,
    value:(value||"")
  })
}

const ImageSet = function(args){
  return ({
    images:[],
    rotation:0,
    imageSetId:args._id,
    figureImageId:(args.images||[])[0],
    ...args,
      })
}

const Image = ({ url, _id, width, height, versions, ...args }) => ({
  _id, 
  width,
  height,
  adjustments:{
    //version:1,
  },
  versions:{ ...(versions||{}) },
  ...args
})

const ud = item => item === undefined

const Crop = crop => {
  //crop should have parentImageId,
  let keys = ['_id','imageSetId','top','height','width','left','leftLabelGroupId','rightLabelGroupId'];
  let undefinedKeys = keys.filter( key => ud(crop[key]) );
  if( undefinedKeys.length > 0 ){
    throw Error("Crop was not fully defined. It needs _id,imageSetId, top, height, width, left.\nWe got:\n"+JSON.stringify(crop));
  }

  return {
    lines:{},
    labels:{},
    ...crop,
    rotation:crop.rotation||0
  }
}

function pickNewImageSetFigureImage(draft,{imageSet,_id}){
  if( !imageSet ){
    imageSet = G.getData(draft,{itemType:IMAGE_SETS,_id});
  }

  let activeIds = G.filterActiveIds(draft, {idList:imageSet.images, itemType:IMAGE_UPLOADS})

  if( activeIds.length === 0 ){
    throw Error("Somehow an empty imageSet fell through the cracks and is having an imageUpload assigned to its figureImageId property.");
  }

  imageSet.figureImageId = activeIds[0];


}

function archiveAnnotationsLinkedToImageSet(draft,{imageSetId}){
  let atnIds = Object.keys(draft.data.annotations);
  atnIds.forEach(id => {
      if( 
        draft.data.annotations[id].imageSetId
        ===
        imageSetId
    ){
      archiveRecord(draft,ANNOTATIONS,id);
    }
  })

}

function updateAnnotationsAccordingToTransfer(draft,imageId,destinationImageSetId,annotationTransferOption,newAnnotationIds){

  let moverAtns = G.getAnnotationsByImageId(draft,imageId);

  let destAtnIds = G.getAnnotationIdsByImageSetId(draft,destinationImageSetId);

  let imageIsAnOnlyChild = G.isImageIdAnOnlyChildInItsImageSet(draft,{imageId});
  if( imageIsAnOnlyChild  ){
    let moverAtnIds = moverAtns.map(atn => atn._id);
    moverAtnIds.forEach(_id => {
      draft.data.annotations[_id].imageSetId = destinationImageSetId;
    });
  }
  else if( annotationTransferOption === C.KEEP_MOVER_ANNOTATIONS_ONLY ){

    destAtnIds.forEach(id => 
      delete draft.data.annotations[id]
    );

    moverAtns.forEach((atn,ii) => draft.data.annotations[newAnnotationIds[ii]] = ({
      ...atn,
      _id:newAnnotationIds[ii],
      imageSetId:destinationImageSetId,
    }))

  }else if( annotationTransferOption === C.KEEP_BOTH_ANNOTATIONS ){


    moverAtns.forEach((atn,ii) => draft.data.annotations[newAnnotationIds[ii]] = ({
      ...atn,
      _id:newAnnotationIds[ii],
      imageSetId:destinationImageSetId,
    }))

  }else if( annotationTransferOption !== C.KEEP_DESTINATION_ANNOTATIONS && annotationTransferOption !== undefined ){
    throw Error("Unrecognized annotationTransferOption: '"+annotationTransferOption+"'");
  }


}



function makeCellGroupMap(idList,style){
  let map = {};
  idList.forEach( id => map[id] = CellGroup(id,{style}) )
  return map;

}

function mapInsert(draft,mapName,insert){
  if( Array.isArray(insert) ){
    insert.forEach( obj => {
      if( !obj._id ){
        throw Error("mapInsert on list requires that each list item have an '_id' property.");
      }

      draft[mapName][obj._id] = obj;


    })
  }else if( typeof(insert) === 'object' ){
    draft[mapName] = {
      ...draft[mapName],
      ...insert
    }
  }
}


function toggleMapProperties(map,propertyObject){

  Object.keys(propertyObject).forEach( key => {
    let potentialNewValue = propertyObject[key];
    map[key] = potentialNewValue;
    /*
    if( map[key] === potentialNewValue ){
      delete map[key];
    }else{
      map[key] = potentialNewValue;
    }
    */
  })
}





function setCellValue(draft, cellId, properties ){



  let cellGroup = draft.cellGroups[cellId];


  

  Object.keys(properties).forEach(propertyKey => {
    let givenPropertyValue = properties[propertyKey];

    if( givenPropertyValue instanceof Object ){
      let currentValue = cellGroup[ propertyKey ];
      let curPropertyValueIsString = typeof(currentValue) === 'string';

      if( curPropertyValueIsString || propertyKey==='value' ){
        cellGroup[propertyKey] = givenPropertyValue;
      }else{ 
        
        if( currentValue === undefined || currentValue === null ){
          cellGroup[propertyKey] = properties[propertyKey];
        }else{
          toggleMapProperties(currentValue,givenPropertyValue)
        }
      }


    }else{
      cellGroup[ propertyKey ] = givenPropertyValue;
    }

  })

}

function getTopLeftMostCell(cells){

  if( cells.length === 0 ){
    throw Error("Cannot find topleftmost cell in empty list of cells!")
  }

  let minCol = cells[0][1];
  let minRow = cells[0][0];

  cells.forEach( cel => {
    if( cel[0] < minRow ){ minRow = cel[0] }
  })


}

function intersect(list1, list2){
  let strList1 = list1.map(JSON.stringify);
  let strList2 = list2.map(JSON.stringify);

  return strList1.filter( item => strList2.includes(item) ).map(JSON.parse);

}

function union( list1, list2 ){

  let strList1 = list1.map(JSON.stringify);
  let strList2 = list2.map(JSON.stringify);

  let set = new Set();
  strList1.forEach( item => set.add(item) )
  strList2.forEach( item => set.add(item) )

  return Array.from(set).map(JSON.parse);

}

function setDifference( x, toRemoveFromX ){
  let strList1 = x.map(JSON.stringify);
  let strList2 = toRemoveFromX.map(JSON.stringify);

  return strList1.filter( item => !strList2.includes(item) ).map(JSON.parse);

}

function updateGridAndIdMaps(draft,{figurePanelId,newGrid,idsToRemove,newIds,numberOfLanes}){

  let figurePanel = draft.data.figurePanels[figurePanelId];

  draft.selectedCells = [];

  figurePanel.grid = newGrid;

  idsToRemove.forEach( id => {
    delete figurePanel.cellGroups[id];
  })

  newIds.forEach( id => {
    figurePanel.cellGroups[id] = CellGroup(id);
  })

}

function createDestinationImageSetIfItDoesntExist(draft,destinationImageSetId, imageIds, directoryDestination ){
  if( !(destinationImageSetId in draft.data.imageSets) ){
    draft.data.imageSets[destinationImageSetId] = ImageSet({
      _id:destinationImageSetId,
      images:[imageIds[0]]
      //figureImageId:imageIds[0]
    })

    addMetadataForRecordIfMissing(draft,{
      type:IMAGE_SETS,
      objectId:destinationImageSetId,
      directoryDestination
    });


  }
}

function removeImageIdsFromCurrentImageSet(draft,imageIds){

  imageIds.forEach(id => {
    let imageSetId = draft.data.imageUploads[id].imageSetId;
    let imageSet = draft.data.imageSets[imageSetId];
    if(!imageSet){

    }
    let indexToRemove = imageSet.images.indexOf(id);
    imageSet.images.splice(indexToRemove,1);
    if( imageSet.figureImageId === id ){
      imageSet.figureImageId = imageSet.images[0];
    }
  })
}

function archiveAllEmptyImageSets(draft){
  Object.keys(draft.data.imageSets).forEach( id => {
    let imageIds = draft.data.imageSets[id].images
    let activeImageIds = G.filterActiveIds(draft,{
      idList:imageIds,
      itemType:IMAGE_UPLOADS
    })
    if( activeImageIds.length === 0 ){
      archiveRecord(draft,IMAGE_SETS,id);
      //delete draft.data.imageSets[id];
    }
  })
}

function addImageIdsToDesitnation(draft,imageIds,destinationImageSetId){

  imageIds.forEach(id => {
    draft.data.imageUploads[id].imageSetId = destinationImageSetId;
  })
  let destination = draft.data.imageSets[destinationImageSetId];

  destination.images.push(...imageIds);

  destination.images = Array.from(new Set(destination.images))


}

function filterOutAnyImagesMovingToTheSameImageSet(draft,imageIds,destinationImageSetId){

  return imageIds.filter(id => {
    let imageSetId = draft.data.imageUploads[id].imageSetId;
    return  imageSetId !== destinationImageSetId
  })
}


function pluralized(givenName){
  if(givenName.slice(-1) === 's'){
    return givenName;
  }else{
    return givenName+'s';
  }
}

function getErrorProducedByPuttingNameInDirectoryOfItem({_id,itemType,name}){

}



function validateFsMoveArgs({from,to},action){

  if( !to ){
    throw Error("No destination received in filesystem move action: " + JSON.stringify(action));
  }

  /*if( !from ){
        throw Error("No destination received in filesystem move action: " + JSON.stringify(action));
      }*/
}

function removeObjectWithIdFromList(list,_id){
  let indexOfId = list.findIndex(item => item._id===_id);
  if( indexOfId !== -1 ){
    list.splice(indexOfId, 1);
  }
}

function archiveRecord(draft, itemType,_id){

  draft.meta[itemType][_id].archived = true;
  /*
  draft.archived[_id] = { 
    type:getResolvedItemTypeName(itemType),
    data:draft.data[itemType][_id],
    meta:draft.meta[itemType][_id]
  }

  delete draft.data[itemType][_id];
  */
  //delete draft.meta[itemType][_id];

}

function archiveImageSets(draft,ids){

  ids.map(id => 
    archiveRecord(draft,IMAGE_SETS,id)
  )

}


function getNewScalebarData(action){
  let { inside, args } = action;
  let { position } = args;
  if( !inside || !args || !position ){
    throw Error("Missing required scalebar args.");
  }

  return {
    p:inside,
    position:args.position,
    type:"scalebar",
    whiskers:0,
    textPosition:"top",
    thickness:2,
    width:{
      value:40,
      units:"%"
    },
    style:{
      margin:5,
      fontSize:10,
      color:'white',
    }
  }
}

function getNewTextNodeData(action){
  let { inside, args } = action;
  let { position } = args;
  if( !inside || !args || !position ){
    throw Error("Missing required scalebar args.");
  }

  return {
    p:inside,
    position:args.position,
    type:"text",
    style:{
      fontSize:10,
      color:'white',
      fontWeight:"bold",
      background:"black",
    }
  }
}

function getNewRegionNodeData(action){

  let { inside, args } = action;
  let { position } = args;
  if( !inside || !args || !position ){
    throw Error("Missing required scalebar args.");
  }

  return {
    p:inside,
    position:args.position,
    value:action.value || {},
    type:"region",
    style:{}
  }



}

const TemplateExpansionNodeFactory = {
  scalebar:getNewScalebarData,
  text:getNewTextNodeData,
  region:getNewRegionNodeData,
}

function setCellsValueProperties(draft,action){

  let { figurePanelId, byCellLocation, cells, properties } = action;

  let figurePanel = G.getFigurePanel(draft,{figurePanelId});


  if( byCellLocation ){

    byCellLocation.forEach(({cellLocation,properties}) => {
      setCellsValueProperties( 
        draft, 
        { 
          figurePanelId,
          cells:[cellLocation],
          properties
        }
      )
    })


  }else{


    cells.forEach(([row,col]) => {
      let cellGroupId = figurePanel.grid[row][col];
      let cellGroup = figurePanel.cellGroups[cellGroupId];
      setData(cellGroup.value, properties);

    })
  }
}

function insertExpansionTemplateNode(draft,action){

  let { figurePanelId, localTemplateId, inside, before, after, templateId, d, nodeId, nodeData, newParentNodeId } = action;

  let newParentId = newParentNodeId;

  let targetNodeId = before; 
  let spliceIndexOffset = 0;
  if( after ){
    targetNodeId = after;
    spliceIndexOffset = 1;
  }


  let template = G.getFigurePanelTemplate(draft,{templateId,figurePanelId,localTemplateId});
  let { nodes } = template;


  if( inside ){
    let targetNode = nodes[inside];
    if( !targetNode.c ){
      targetNode.c = [];
    }
    nodes[nodeId] = { ...(nodeData||{}), p:inside }

    let parent = nodes[inside];
    if( !parent.c ){
      parent.c = [];
    }
    parent.c.push( nodeId )

    return;
  }



  let targetNode = nodes[targetNodeId];
  let parentId = targetNode.p;
  let parent = nodes[parentId];


  if( parent.c && parent.c.length === 1 ){
    //if were splitting an only child, just change the direction
    parent.d = d;
  }

  let directionChange = parent.d !== d;
  if( directionChange ){

    let newSplitId = nodeId;
    parent.c = parent.c.map(x => x === targetNodeId ? newParentId : x);
    let oldParent = String(parentId);
    targetNode.p = newParentId;

    let newSplitNode = {p:newParentId, value:{}}


    let newParentNode = {
      p:oldParent,
      d,
      c:[targetNodeId,newSplitId]
    }


    nodes[newParentId] = newParentNode;
    nodes[newSplitId] = newSplitNode;

    //replace the target with the parent
    //let the target's new parent be the new parent

  }else{

    let targetIndex = parent.c.indexOf(targetNodeId);
    let spliceIndex = targetIndex + spliceIndexOffset;

    parent.c.splice(spliceIndex,0,nodeId);
    let newSplitNode = {p:targetNode.p,value:{}}
    nodes[nodeId] = newSplitNode;

  }

}

function Reducer(state,action){
  if( state === undefined ){
    return getDefaultState();
  }

  //console.log({action});


  switch( action.type ){
      /*
    case C.addRegionExpansionNodeItem:{
      let { templateId, nodeId, item } = action;
      return produce(state,draft => {
      });
    }
    */

    case C.setState:{
      return window.__injectedState;
    }
    case C.removeNodeFromEvaluatedTemplateNode:{
      let { figurePanelId, cellLocation, nodeId } = action;

      let nodeParentId = G.getEvaluatedFigurePanelCellNodeValue(state,{nodeId,figurePanelId,cellLocation}).p;
      


      let parentNode = G.getEvaluatedFigurePanelCellNodeValue(state,{nodeId:nodeParentId,figurePanelId,cellLocation});
      let { c } = parentNode;
      let newC = c.filter(x => x !== nodeId);


      let nodeKey=`nodeOverrides.${nodeId}`;
      let nodeValue = null;
      let parentOverrideKey=`nodeOverrides.${nodeParentId}.c`;
      let parentOverrideValue = newC;


      return produce(state,draft => {


        setCellsValueProperties(draft,{ 
          figurePanelId, 
          cells:[cellLocation], 
          properties:{ 
            [nodeKey]:nodeValue,
            [parentOverrideKey]:parentOverrideValue,
          } 
        })



      });
    }

    case C.insertNodesInEvaluatedTemplateNodes:{
      let { figurePanelId, targetLocations, args } = action;
      return produce(state,draft => {
        targetLocations.forEach(({inside,nodeId,cellLocation}) => {
          insertNodeInEvaluatedTemplateNode(draft,{
            figurePanelId,
            inside,
            nodeId,
            cellLocation,
            args
          })
        })
      })




    }

    case C.insertNodeInEvaluatedTemplateNode:{
      return produce(state,draft => {
        insertNodeInEvaluatedTemplateNode(draft,action);
      })
    }
    case C.modifyRegionExpansionNodeItems:{
      let { templateId, to, op, itemId, items, properties } = action;
      return produce(state,draft => {

        let template = G.getData(draft,
          {_id:templateId,itemType:TEMPLATES});

        let targetNode = template.nodes[ to ];

        switch(op){
          case 'add':{
            for(let newItemId in items){
              template.items[newItemId] = {
                p:to,
                ...items[newItemId]
              }
            }
            
            if( !targetNode.items ){
              targetNode.items = [];
            }
            targetNode.items.push(...Object.keys(items))
            return;
          }
          case 'set':{
            let item = template.items[itemId];
            debugger;
            setData(item,properties);
            return;
          }
          case 'remove':{
            return;
          }
          default:
            throw Error("Unrecognized modifyRegionExpansionNodeItems op '"+op+"'");
        }

      });

    }

    case C.moveExpansionTemplateNode:{
      let { templateId, d, before, after, inside, nodeId, withData } = action

      console.log({action});
      return produce(state,draft => {

        //we take the id out from its parent
        //and we put it in it's proper place

        let template = G.getData(draft,{_id:templateId,itemType:TEMPLATES});
        let { nodes } = template;
        let itemToMove = nodes[ nodeId ];
        for(let dataKey in (withData || {})){
          itemToMove[dataKey] = withData[dataKey];
        }
        let itemParentId = itemToMove.p;
        let itemParent = nodes[ itemParentId ];
        let parentChildren = itemParent.c;

        parentChildren.splice( parentChildren.indexOf(nodeId),1);

        

        //2. get destination list
        let targetParentId;
        let targetParent;
        let indexToSplice;

        if( before || after ){
          targetParentId = nodes[before||after].p;
          targetParent = nodes[ targetParentId ];
          indexToSplice = targetParent.c.indexOf(before||after) + (before?0:1);
        }else if( inside ){
          targetParentId = inside;
          targetParent = nodes[inside]
          indexToSplice = ((targetParent.c)||[]).length;
          if( !targetParent.c ){
            targetParent.c = [];
          }
        }

        targetParent.c.splice(indexToSplice,0,nodeId)
        nodes[nodeId].p = targetParentId;

      })
    }

    case C.setCellsValueProperties:{
      return produce(state,draft => {
        setCellsValueProperties(draft,action);
      })
    }
    case C.removeExpansionTemplateNode:{
      let { templateId, nodeId } = action;

      return produce(state,draft => {

        if( nodeId === "root" ){
          return;
        }

        let template = G.getData(draft,{_id:templateId,itemType:TEMPLATES});
        let node = template.nodes[nodeId];
        let parentNode = template.nodes[node.p];
        parentNode.c = parentNode.c.filter(cId => cId !== nodeId);
        if( parentNode.c.length === 0 ){
          delete parentNode.c;
          delete parentNode.d;
        }

        //if the parent is empty after removal...
        //then delete the 'c' and 'd' properties



        //otherwise... just remove the damn thing.

      })
    }

    case C.insertExpansionTemplateNodes:{

      return produce(state,draft => {

        let { figurePanelId, localTemplateId, parentList, insertionLocation, parentToNodeIdMap, nodeArgs, d,
          newParentNodeIds,
        } = action;
        parentList.forEach(parentId => {
          
          insertExpansionTemplateNode(draft,{
            figurePanelId,
            localTemplateId,

            [insertionLocation]:parentId,
            nodeId:parentToNodeIdMap[parentId],
            d,
            nodeData:{
              ...(nodeArgs.default||{}),
              ...(nodeArgs[parentId] || {}),
            },
            newParentNodeId:newParentNodeIds[parentId]

          });

        })

      })

    }

    
    case C.insertExpansionTemplateNode:{
      return produce(state,draft => {
        insertExpansionTemplateNode(draft,action)
      })
    }

    case C.setRegionExpansionNodeProperties:{
      let { nodeIdList=[],nodeId, properties, figurePanelId, localTemplateId } = action;
      let allNodeIds = []
      if( nodeId ){
        allNodeIds.push(nodeId);
      }

      if( nodeIdList ){
        allNodeIds.push(...nodeIdList)
      }

      return produce(state,draft => {
        let figurePanel = G.getData(draft,{itemType:FIGURE_PANELS,_id:figurePanelId});

        let template = figurePanel.config.templates[ localTemplateId ];
        if( !template ){
          throw Error("Template was not found for localTemplateId = " + localTemplateId);
        }

        allNodeIds.forEach(thisNodeId => {

          let node = template.nodes[thisNodeId];
          setData(node,properties)

        })

      });
    }

    case C.splitRegionFormatCell:{
      let { templateId, cellId, d, newIds } = action;

      return produce(state,draft => {
        let template = G.getData(draft,{itemType:TEMPLATES,_id:templateId});

        //there are 2 cases:
        //  either we split in the same direction
        //    we add a single node to the parent of the target node
        //
        //  or we split in a different direction, we need 2 new nodes:
        //    --> if the node we're splitting already had
        //        a value, it wouldn't make sense to transfer.
        //        Hence, we can just create a new node to be its parent
        //        so we don't do anything to the value.
        //        It's just quick stuff
        //
        //    1) to house the current and newly added split
        //    2) the newly added split
        //

        let targetNode = template.nodes[cellId];
        let parent = template.nodes[targetNode.p];
       

        if( parent.c && parent.c.length === 1 ){
          //if were splitting an only child, just change the direction
          parent.d = d;
        }

        let directionChange = parent.d !== d;
        if( directionChange ){

          let newParentId = newIds[0];
          let newSplitId = newIds[1];
          parent.c = parent.c.map(x => x === cellId ? newParentId : x);
          let oldParent = String(targetNode.p);
          targetNode.p = newParentId;

          let newSplitNode = {p:newParentId}


          let newParentNode = {
            p:oldParent,
            d,
            c:[cellId,newSplitId]
          }


          template.nodes[newParentId] = newParentNode;
          template.nodes[newSplitId] = newSplitNode;

          //replace the target with the parent
          //let the target's new parent be the new parent

        }else{

          let newSplitId = newIds[0]
          let newSplitNode = {p:targetNode.p}
          parent.c.push(newSplitId);
          template.nodes[newSplitId] = newSplitNode;

        }


      })


    }

    case C.setCellCultureItem:{
	  
	  let { field, fieldIndex, subField, subFieldIndex, value } = action.args; 


	  return produce(state,draft => {

		let cellCulture = G.getSelectedExperimentCellCulture(draft);

		if( field === 'date' ){
		  return produce(state,draft => {
			let cellCulture = G.getSelectedExperimentCellCulture(draft);
			cellCulture.date = value;
		  })
		}
		else if( field === 'doses' ){
		  let { compoundIndex, conditionIndex, doseIndex } = action.args;
		  cellCulture.conditions[ conditionIndex ][ compoundIndex ].doses[ doseIndex ] = value;
		  return;
		}else if( field === 'compound' ){
		  let { compoundIndex, conditionIndex } = action.args;
		  cellCulture.conditions[ conditionIndex ][ compoundIndex ].compound = value;
		  return;
		}

		let fieldObject = cellCulture[field];
		if( subField !== undefined ){
		  let subFieldObject = fieldObject[ fieldIndex ][ subField ];
		  if( subFieldIndex !== undefined ){
			subFieldObject[ subFieldIndex ] = value;
		  }else{
			fieldObject[ fieldIndex ][ subField ] = value;
		  }
		}else{
		  fieldObject[ fieldIndex ] = value;
		}
	  })

	  break;

	}

	case C.removeCellCultureItem:{
	  let { field, subField, value, fieldIndex } = action.args; 

	  if( field === 'doses' ){

		  let { conditionIndex, compoundIndex, doseIndex } = action.args;
		  return produce(state,draft => {
			let cellCulture = G.getSelectedExperimentCellCulture(draft);
			let cond = cellCulture.conditions[conditionIndex]
			cond[compoundIndex].doses.splice(doseIndex,1);
		  })

	  }else if( field === 'compound' ){
		  let { conditionIndex, compoundIndex } = action.args;
		  return produce(state,draft => {

			let cellCulture = G.getSelectedExperimentCellCulture(draft);

			cellCulture.conditions[conditionIndex].splice(compoundIndex,1)
		  })
	  }

	  let cellCulture = G.getSelectedExperimentCellCulture(state);

	  if( ! (field in cellCulture ) ){

		throw Error(field+" is not a key in CellCultureCreator ("+Object.keys(cellCulture)+")")
	  }
	  return produce(state,draft => {

		let draftCellCulture = G.getSelectedExperimentCellCulture(draft);

		let fieldObj = draftCellCulture[ field ];
		fieldObj.splice(fieldIndex,1);
	  })

	}

	case C.setCellCultureDate:{
	  let { index, date } = action;


	  let cellCultureId = 
		state.tableOrders.cellCultures[ index ];

	  return produce(state,draft => {

		let cellCulture = 
		  G.getRecordById(draft, cellCultureId);

		cellCulture.date = date;
		
	  })
	}

	case C.addCellCultureItem:{
	  
	  let { field, subField, value, fieldIndex } = action.args; 

	  let toPush;
	  if( field === 'cells' ){
		let cellLineId = state.tableOrders.cellLines[value];
		let cellLine = G.getRecordById(state,cellLineId);
		toPush = cellLine;
	  }else if( field === 'doses' ){
		return produce(state,draft => {

		  let { conditionIndex, compoundIndex } = action.args;

		  let cellCulture = G.getSelectedExperimentCellCulture(draft);

		  cellCulture.conditions[conditionIndex][ compoundIndex ].doses.push(CellCultureCreator.dose())

		})
		
	  }else if( field === 'compound' ){
		return produce(state,draft => {

		  let { conditionIndex } = action.args;

		  let cellCulture = G.getSelectedExperimentCellCulture(draft);

		  cellCulture.conditions[conditionIndex].push(
			CellCultureCreator.compound()
		  )

		})
	  }

	  return produce(state,draft => {
		let cellCulture = G.getSelectedExperimentCellCulture(draft);

		let fieldObject = cellCulture[field];
		if( fieldIndex !== undefined ){
		  fieldObject[ fieldIndex ][ subField ].push( toPush );
		}else{
		  let initGetterFunc =CellCultureCreator[field]
		  if( initGetterFunc === undefined ){
			throw Error(field + ' is not a valid CellCultureCreator factor.');
		  }
		  toPush = initGetterFunc() 
		  fieldObject.push( toPush );
		}
	  })
	}

	case C.removeCellCultureListItem:{
	  let { itemType, index } = action; 
	  return produce(state,draft => {
		let cellCulture = G.getSelectedExperimentCellCulture(draft);

		cellCulture.cells.splice(index,1);
	  })
	}

    case C.setWarnings:{
      let { type, ...warnings } = action;
      return produce(state,draft => {
        draft.warnings = {
          ...draft.warnings,
          ...warnings,
        }
      })
    }

   
    case C.setSubscriptionInfo:{
      let { type, ...info } = action;
      let { productId } = action;
      return produce(state,draft => {

        draft.userInfo.subscriptions[productId] = info;


      })
    }

    case C.tryArchiveItems:{
      let { force, items } = action;

      let itemsByType = {}


      items.forEach(item => {
        let { itemType, _id } = item;

        if( !itemsByType[itemType] ){
          itemsByType[itemType] = []
        }
        itemsByType[itemType].push(_id);
        
      })

      let archiveFunctionMap = {
        imageUploads:archiveImages,
        imageSets:archiveImageSets
      }


      return produce(state,draft => {

        
        if( force ){ 
          for(let itemType in itemsByType){
            let items = itemsByType[itemType];
            archiveFunctionMap[itemType](draft,items);
          }
        }else{
          createDialog(draft,{
            source:"contextMenu",
            dialogName:Dialog.CONFIRM_DELETE_IMAGE_ITEM,
            args:{ items, force:true }
          })
        }

        

      })
    }

    case C.setTutorialState:{
      let { title, index } = action;
      return produce(state,draft => {



        if( draft.tutorialState ){
          draft.tutorialState.title = title;
          draft.tutorialState.index = index;
        }
      })
    }

    case C.confirmExitTutorial:{
      return produce(state,draft => {
        draft.confirmExitTutorial = true;
      })
    }
    case C.cancelExitTutorial:{
      return produce(state,draft => {
        draft.confirmExitTutorial = false;
      })
    }

    case C.setUserInfo:{
      let { userInfo, type } = action;
      return produce(state,draft => {
        for(let key in userInfo){
          draft.userInfo[key] = userInfo[key];
        }
      })
    }

    case C.launchTutorial:{
      return produce(state,draft => {


        draft.dialogs = [];
        //reserve user data
        draft.reservedUserMeta = draft.meta;
        draft.reservedUserData = draft.data;
        draft.reservedUserConfig = draft.userConfig;
        draft.reservedMedia = draft.media;
        

        //move data and meta to user
        draft.meta = tutorialState.meta;
        draft.data = tutorialState.data;
        draft.userConfig = tutorialState.userConfig;
        draft.media = tutorialState.media;

        //turn on tutorial state
        draft.tutorialState = {
          tutorialStateIndex:0
        };
      })
    }

    case C.exitTutorial:
    case C.completeTutorial:{
      return produce(state,draft => {

        //rehydraft tutorial data
        draft.meta = draft.reservedUserMeta;
        draft.data = draft.reservedUserData;
        draft.userConfig = draft.reservedUserConfig;
        draft.media = draft.reservedMedia;

        draft.ui = getDefaultUiStateSlice();
        draft.ui.mode = ANNOTATION;


        delete draft.reservedUserMeta;
        delete draft.reservedUserData;
        delete draft.reservedUserConfig;
        delete draft.reservedMedia;

        draft.tutorialState = null;
      })
    }
    case C.answerSurvey:{

      let { name } = action;
      return produce(state,draft => {

        let surveys = draft.surveys;
        if( surveys ){

          let indexToRemove = draft.surveys.findIndex(survey => survey.name === name);
          surveys.splice(indexToRemove,1);

        }
                
      })
    }
    case C.markPossibleQuantificationAnnotationUpdatesSeen:{
      let { _id } = action;
      return produce(state,draft => {
        let atn = G.getAnnotation(draft,{_id});
        let quantificationAnnotation = atn.quantificationAnnotation;
        if( quantificationAnnotation ){
          quantificationAnnotation.seen = true;
        }
      })
    }
    case C.updateQuantificationParameters:{
      let { params, annotationId, _id } = action;

      _id = _id || annotationId;

      return produce(state,draft => {


        let atn = G.getAnnotation(draft,{_id});

        let paramUpdated = false;

        params.forEach(param => {
          if(param === "imageId"){

            let figureImageId = G.getImageSetFigureImageIdByAnnotationId(state,{annotationId:_id});

            atn.quantificationAnnotation.imageId = figureImageId;
            paramUpdated = true;

          }else if(param === "window"){

            atn.quantificationAnnotation.height = atn.height;
            atn.quantificationAnnotation.ls = atn.ls;
            paramUpdated = true;

          }else{
            throw Error("Unrecognized quantification parameter '"+param+"'");
          }
        })

        if( paramUpdated ){
          resetLanesAndClearQuantification(draft,{annotationId:_id})
        }


      })
    }

    case 'gelDemoDataIn':{
      return {
        ...state,
        gelDemoDataIn:true
      }
    }

    case C.discardPendingRecords:{
      let  {imageIds} = action;
      return produce(state,draft => {
        imageIds.forEach(_id => {
          delete draft.pendingRecords.imageUploads[_id];
          delete draft.mediaProcessing[_id];
        })
        
      })
    }
    case C.setUiMode:{
      let { mode } = action;
      return produce(state,draft => {
        draft.ui.mode = mode;
        if( mode !== FIGURE ){
          draft.ui.modeArgs = {};
        }
      })
    }

    case C.setSelectedAnnotationsItemContext:{
      let { _id } = action;
      return produce(state,draft => {
        draft.ui[ANNOTATION].selectedAnnotationsFilesystemId = _id;

      })
    }
    case C.setSelectedFigurePanelContext:{
      let {context} = action;
      return produce(state,draft => {
        draft.ui.modeArgs.figurePanelContext = context;
      })
    }

    case C.fetchPresignedPostUrls:{
      let { imagesToSync } = action;
      return produce(state,draft => {
        imagesToSync.forEach(imageSpec => {
          let { _id, version } = imageSpec;
          let mediaContainer = draft.media[_id];
          if( mediaContainer ){
            mediaContainer[version].postInfo = "fetching";
            delete mediaContainer[version].persistError;
          }

        })
      })
    }


    case C.receivedPresignedPosts:{
      let { postList } = action; 
      return produce(state,draft => {

        postList.forEach(({postInfo,mediaInfo}) => {

          let { _id, version } = mediaInfo;
          let mediaContainer = draft.media[_id];
          if( mediaContainer ){
            if( mediaContainer[version] ){
              mediaContainer[version].postInfo = postInfo;
            }
          }
          

        })

      })
    }

    case C.receiveTypingNotification:{
      return produce(state,draft => {
        let { typing } = action;
        draft.adminIsTyping = typing;
      })
    }

    case C.readUnreadMessages:{
      let { unreadMessages, time } = action;
      return produce(state,draft => {

        let thread = G.getThread(draft,action);
        unreadMessages.forEach(msgId => {
          thread.messages[msgId].read.admin = time;
        })

      })
    }

    case C.addPanelToFigure:{
      let { atIndex, figurePanelId, figureId } = action;


      return produce(state, draft => {

        let figure = G.getData(draft,{_id:figureId});

        figure.panels[figurePanelId] = {
          //default info about
          //panel in figure will be added here later
        };

        let panelOrder = figure.panelOrder;

        if( atIndex !== undefined ){
          panelOrder.splice(atIndex,0,figurePanelId);
        }else{
          panelOrder.push(figurePanelId);
        }
      })
    }

    case C.removePanelFromFigure:{
      let { figurePanelId, figureId } = action;
      return produce(state,draft => {
        
        let figure = G.getData(draft,{_id:figureId});
        delete figure.panels[figureId];

        figure.panelOrder = figure.panelOrder.filter(x => x !== figurePanelId);


      })
    }

    case C.receiveMessage:{
      return state;
    }

    case C.mediaFetchFailed:{
      let { imageId, version, corrupt } = action;
      return produce(state,draft => {
        let imageMediaContainer = draft.media[imageId];
        let versionContainer;
        if( !imageMediaContainer[version] ){
          imageMediaContainer[version] = {};
          versionContainer = imageMediaContainer[version];
        }else{
          versionContainer = draft.media[imageId][version];
        }
        
        delete versionContainer.pending;
        versionContainer.status = "failed";
        if( corrupt ){
          versionContainer.corrupt = true;
        }
      })
    }

    case C.setSessionConfig:{
      let { type, ...args } = action;
      return {
        ...state,
        sessionConfig:{
          ...state.sessionConfig,
          ...args
        }
      }
    }
    case C.setRemoteStorageResourceId:{
      let { version, _id, remoteStorageResourceId, storageLocation, pendingRecords } = action;

      let imageArgs = {
        version,_id,remoteStorageResourceId,
        pendingRecords:(pendingRecords === false ? false : true)
      }
      return produce(state,draft => {

        let image = G.getImage(draft,imageArgs);
        let imageVersionContainer = image.versions[version]
        imageVersionContainer.remoteStorageResourceId = remoteStorageResourceId;
        imageVersionContainer.storageLocation = storageLocation;
      })
    }

    case C.queueAnonymousSessionDataForSync:{
      return produce(state,queueAnonymousSessionDataForSync);
    }

    case C.deleteItem:{
      let { itemType } = action;
      let deletableItemTypes = [DIRECTORIES,FIGURE_PANELS];
      let resolvedItemType = getResolvedItemTypeName(itemType)
      if( !deletableItemTypes.includes( resolvedItemType ) ){
        throw Error("You can only call 'deleteItem' on "+JSON.strinigfy(deletableItemTypes)+" type, not " + itemType + ".");
      }

      return produce(state,draft => {
        archiveRecord(draft,resolvedItemType,action._id);
      });

    }
    case C.moveFilesystemItem:{

      let { from, to, fromTopLevelDirectory, toTopLevelDirectory } = action;

      let thisId = action._id;

      to = to||G.getTopLevelDirectoryId(state,{type:toTopLevelDirectory});
      //from = from||G.getTopLevelDirectoryId(state,{type:fromTopLevelDirectory});

      validateFsMoveArgs({to},action);

      return produce(state,draft => {

          let thisItemMeta = G.getMeta(draft,action);
          

        let parentDirectoryId = G.getParentDirectoryId(draft,{_id:thisId});

        

          let oldDir = G.getData(draft,{
            _id:parentDirectoryId,
            itemType:DIRECTORIES
          })
          let newDir = G.getData(draft,{
            _id:to,
            itemType:DIRECTORIES
          })

          thisItemMeta[FILESYSTEM_PARENT_DIRECTORY] = to;

          //let lenPreRm = oldDir.children.length;
          //let cleanedOlderChildren = 
          let indexToSplice = oldDir.children.findIndex(x => x._id===thisId);




          oldDir.children.splice( indexToSplice, 1 );

          newDir.children.push({
            type:action.itemType,
            _id:thisId
          });
      });
    }


    case C.setFilesystemName:{


      let newFilesystemName = action[FILESYSTEM_NAME];
      if( !newFilesystemName ){
        throw Error("No '"+FILESYSTEM_NAME+"' was found in " + JSON.stringify(action));
      }




      //find location of file and confirm that it's a valid move
      return produce(state,draft => 
        setFilesystemName(draft,{...action,newFilesystemName})
      );
    }

    case C.overwriteAllOccurencesOfValueAtPath:{
      let { path, withValue } = action;

      let currentValue = G.getByPath(state,path);


      let stringState = JSON.stringify(state);
      let replacedStringState = stringState.replaceAll(
        currentValue,
        withValue
      )

      let parsedState = JSON.parse(
        replacedStringState
      );

      return parsedState;

    }


    case C.finishImageProcessing:{
      let { processId, error } = action;
      let { dataURL, uploadWidth, height } = action.outputArgs;
      let { outputMime, input } = action.inputArgs;

      let newVersionName = 'raw-'+outputMime;
      let newVersion = { uploadWidth }

      let { imageId } = input;



      return produce(state,draft => {

        let image = G.getImage(draft,{pendingRecords:true,
          imageId});
        image.versions[newVersionName] = newVersion;
        image.height = height;

        if( error ){
          image.error = true;
        }


        if( !draft.media[ imageId ]){
          draft.media[imageId] = {};
        }


        draft.media[imageId][newVersionName] = {
          pending:false,
          localBlobUrl:dataURL
        };
        

        draft.processes[processId].status = 'complete';

      });
    }

    case C.removeCorruptImageUploads:{

      let idsToRemove = G.getIdsOfCorruptImageUploads(state);
      return produce(state,draft => {
        archiveImages(draft,idsToRemove);
      });


    }

    case C.startProcess:{
      let { type, ...processArgs } = action;
      let { processId } = processArgs;

      return produce(state,draft => {
        draft.processes[processId] = processArgs
        draft.processes[processId].status = 'pending';
      })

    } 
    case C.finishProcess:{
      let { processId, output } = action;
      //now we need to apply the changes
      //mandated by the process...
      return state;

    }

    case C.connectItems:{
      const lhsItems = action[0];
      const rhsItems = action[1];
      const lhsIds = lhsItems.ids;
      const rhsIds = rhsItems.ids;

      const types = [lhsItems,rhsItems].map(arg => arg.type);
      const relationshipSchema = getEntityRelationshipSchema(...types);

      const { relationshipStructure, defaultProperties } = relationshipSchema;

      let getEntites = (draft,itemType,idList) => idList.map(_id => G.getData(draft,{itemType,_id}));
      let connectEntitiesToIds = (draftEntities,property,ids) => {
        draftEntities.forEach(ent => {
          let allNewLinkedIds = (ent.links[property]||[]).concat(ids);
          let uniqueLinkedIds = Array.from( new Set(allNewLinkedIds) );
          ent.links[ property ] = uniqueLinkedIds;
        })
      }


      //parent like entities have only one child/edge of another entity
      const removeEdgesFromEntitiesThatCanOnlyReferenceOneOfItsNeighbour = (
        draft,
        entsWithOneNeighbourRef,
        propertyToSetInEntsWithOneNeighbourRef,
        propertyToSetInOtherEntityType) => { 

          entsWithOneNeighbourRef.forEach(ent => {

            //remove its neighbour's reference to this entity
            //and remove it's own reference to that neighbour!

            let entId = ent._id;

            let idsOfEntitiesThatNeedToHaveReferenceRemovedToThisEntity = ent.links[propertyToSetInEntsWithOneNeighbourRef]||[];
            //this should be only length 1
            if( idsOfEntitiesThatNeedToHaveReferenceRemovedToThisEntity.length > 1 ){
              console.warn("Found entity that should only have one neighbour, but it has multiple!")
            }

            let entitiesToRemoveReferenceToThis = getEntites(draft,propertyToSetInEntsWithOneNeighbourRef,
              idsOfEntitiesThatNeedToHaveReferenceRemovedToThisEntity
            );

            entitiesToRemoveReferenceToThis.forEach(entToRemoveLinkFrom => {
              entToRemoveLinkFrom.links[propertyToSetInOtherEntityType] = 
                entToRemoveLinkFrom.links[propertyToSetInOtherEntityType].filter(x => x!==entId);
            })

            //now remove it's reference to it's own neighbour
            ent.links[propertyToSetInEntsWithOneNeighbourRef] = []
          });
        }

      return produce(state,draft => {
        let lhsEntities = getEntites(draft,lhsItems.type,lhsIds);
        let rhsEntities = getEntites(draft,rhsItems.type,rhsIds);

        let propertyToSetInLhs = defaultProperties[ pluralized(lhsItems.type) ];
        let propertyToSetInRhs = defaultProperties[ pluralized(rhsItems.type) ];

        let lhsIdsToConnect = lhsIds;
        let lhsEntitiesToConnect = lhsEntities;
        let rhsIdsToConnect = rhsIds;
        let rhsEntitiesToConnect = rhsEntities;


        if( relationshipStructure[0] === CONTAINS_ONE_OF_OTHER ){
          rhsIdsToConnect = rhsIds.slice(0,1)
          rhsEntitiesToConnect = rhsEntities.slice(0,1);
        }

        if( relationshipStructure[1] === CONTAINS_ONE_OF_OTHER ){
          lhsIdsToConnect = lhsIds.slice(0,1);
          lhsEntitiesToConnect = lhsEntities.slice(0,1)
        }


        if( relationshipStructure[0] === CONTAINS_ONE_OF_OTHER ){
          removeEdgesFromEntitiesThatCanOnlyReferenceOneOfItsNeighbour(
            draft,
            lhsEntitiesToConnect,
            propertyToSetInLhs,
            propertyToSetInRhs
          )
        }

        if( relationshipStructure[1] === CONTAINS_ONE_OF_OTHER ){
          removeEdgesFromEntitiesThatCanOnlyReferenceOneOfItsNeighbour(
            draft,
            rhsEntitiesToConnect,
            propertyToSetInRhs,
            propertyToSetInLhs
          )
        }

        connectEntitiesToIds(lhsEntitiesToConnect,propertyToSetInLhs,rhsIdsToConnect);
        connectEntitiesToIds(rhsEntitiesToConnect,propertyToSetInRhs,lhsIdsToConnect);
      })
    }

    case C.disconnectItems:{

      let lhsItems = action[0];
      let rhsItems = action[1];
      const lhsIds = lhsItems.ids;
      const rhsIds = rhsItems.ids;
      const types = [lhsItems,rhsItems].map(arg => arg.type);
      const relationshipSchema = getEntityRelationshipSchema(...types);
      const { defaultProperties } = relationshipSchema;


      //disconnect all ids that have connections on rhs

      let getEntites = (draft,itemType,idList) => idList.map(_id => G.getData(draft,{itemType,_id}));

      return produce(state,draft => {

        let lhsEntities = getEntites(draft,lhsItems.type,lhsIds);
        let rhsEntities = getEntites(draft,rhsItems.type,rhsIds);
        let propertyToSetInLhs = defaultProperties[ pluralized(lhsItems.type) ];
        let propertyToSetInRhs = defaultProperties[ pluralized(rhsItems.type) ];

        lhsEntities.forEach(ent => {
          ent.links[propertyToSetInLhs] = (ent.links[propertyToSetInLhs]||[]).filter(_id => !rhsIds.includes(_id));
        })

        rhsEntities.forEach(ent => {
          ent.links[propertyToSetInRhs] = (ent.links[propertyToSetInRhs]||[]).filter(_id => !lhsIds.includes(_id));
        })




      })
    }

    case C.setIntegrationRange:{
      let { annotationId, laneIndex, rangeIndex, range } = action;
      return produce(state,draft => {
        let atn = G.getData(draft,
          {_id:annotationId,itemType:ANNOTATIONS}
      );

        let quantifications = atn.quantifications;
        
        if( quantifications ){
          let lane = quantifications[laneIndex];
          if( lane ){
            let integrationRanges = lane.integrationRanges;
            if( integrationRanges ){
              integrationRanges[rangeIndex] = range 
            }
          }

        }


      })
    }

    case C.setSelectedPanel:{
      let { _id } = action;
      return produce(state,draft => {
        draft.selectedFigurePanelId = _id;
        draft.selectedCells = [];
      })
    }

    case C.setFigurePanelStyle:{
      let { style, figurePanelId } = action;

      figurePanelId = figurePanelId || state.selectedFigurePanelId;

      return produce(state, draft => {
        let panel = G.getData(draft, {itemType:'figurePanel',_id:figurePanelId});
        Object.keys(style).forEach(key => {
          if( panel.globalStyle ){
            panel.globalStyle[key] = style[key];
          }else{
            panel.globalStyle = {
              [key]:style[key]
            }
          }
        })
      })

    }
    case C.setItemTitle:{
      let { itemType, _id, title } = action;
      return produce(state,draft => {
        let item = G.getData(draft,{itemType,_id});
        item.title = title;
      })
    }

    case C.updateProcessStatus:{
      let { _id, update } = action;
      return produce(state,draft => {
        let process = draft.processes[_id];
        for(let key in update){
          process[_id][key] = update[key];
        }
      })
    }

    case C.registerProcesses:{
      let { processes } = action;
      return produce(state,draft => {
        processes.forEach(process => {
          if( process.name === "densitometry" ){
            let staleQuantificationProcessForThisCrop = Object.values(draft.processes).find(existingProcesses => existingProcesses.annotationId === process.annotationId && process.name==="densitometry");
            if( staleQuantificationProcessForThisCrop ){
              delete draft.processes[staleQuantificationProcessForThisCrop._id];
            }
          }
          draft.processes[process._id] = process;
        })
      })
    }

    case C.incrementLaneWidths:{

      let { indicesByAnnotationIds, increment } = action;
      return produce(state,draft => {
        Object.keys(indicesByAnnotationIds).forEach(_id => {
          let atn = G.getData(draft, {itemType:ANNOTATIONS,_id});
          let indices = indicesByAnnotationIds[_id];
          indices.forEach(index => {
            atn.laneBoundaryPositions[index] = atn.laneBoundaryPositions[index].map((x,ii) => x+((ii-0.5)*2*increment));
          })
          
        })
      })

      //only allow this action if the transormation obeys the rules
      //of the lane boundaries:
      //  - it's within the bounds of the crop
      //  - it doesn't overlap with other lanes


    }

    
    case C.resetLanesAndClearQuantification:{
      let { annotationId } = action;
      return produce(state,draft => resetLanesAndClearQuantification(draft,{annotationId}))
    }
    case C.shiftLaneOffsets:{
      let { indicesByAnnotationIds, increment } = action;
      return produce(state,draft => {
        Object.keys(indicesByAnnotationIds).forEach(_id => {
          let atn = G.getData(draft, {itemType:ANNOTATIONS,_id});
          let indices = indicesByAnnotationIds[_id];
          indices.forEach(index => {
            atn.laneBoundaryPositions[index] = atn.laneBoundaryPositions[index].map(x => x+increment);
          })
          
        })
      })
    }

    case C.setLaneOffsets:{
      let { 
        _id,
        annotationId, 
        laneIndex, 
        offsets, 
        allOffsets
      } = action;
      return produce(state,draft => {
        _id = _id || annotationId;


        let atn = G.getData(draft,{itemType:ANNOTATIONS,_id});

        if( allOffsets ){
          atn.laneBoundaryPositions = offsets;
        

        }else{
          atn.laneBoundaryPositions[laneIndex] = offsets;
        }
      })
    }

    case C.modifyQuantificationList:{
      let { ids, modification } = action;
      return produce(state,draft => {
        let quantificationScene = draft.ui[QUANTIFICATION];
        if( modification === "add" ){
          quantificationScene.selectedAnnotations.push(...(ids||[]))
        }else if( modification === "remove" ){
          quantificationScene.selectedAnnotations = quantificationScene.selectedAnnotations.filter(id => !ids.includes(id));
        }else if( modification === "removeAll" ){
          quantificationScene.selectedAnnotations = [];
        }
      })

    }

    case C.updateQuantificationAnnotationToFigureAnnotation:{
      let { _id } = action;
      return produce(state,draft => {
        let atn = G.getData(draft,{_id});
        delete atn.quantificationAnnotation;
        
      })
    }

    case C.setQuantifications:{

      let { 
        _id, 
        quantifications, 
        quantificationArgs,
        error,
      } = action;


      return produce(state,draft => {
        let atn = G.getData(draft,{itemType:ANNOTATIONS,_id});


        let ls = quantificationArgs.ls;
        let height = quantificationArgs.height;
        let imageId = quantificationArgs.imageId;

        if( error ){
          atn.quantifications = {
            error:true
          }
        }else{
          atn.quantifications = quantifications
          atn.quantificationAnnotation = {
            ls, height, imageId,
          }
        }

      })

    }

    case C.addItemLinks:{

      let { itemType, _id, links } = action;
      return produce(state,draft => {

        let mainItem = G.getData(draft,{itemType,_id});
        mainItem.links.push(...links);

        links.forEach(link => {
          let itemToLink = G.getData(draft,{itemType:link.type, _id: link._id });
          itemToLink.links.push({type:itemType,_id});

        })
      })

    }

    case C.removeItemLinks:{
      let { itemType, _id, links } = action;

      let idListToRemove = links.map(x => x._id);


      return produce(state,draft => {
        let mainItem = G.getData(draft,{itemType,_id});
        let removedLinks = [];
        let linksToKeep = [];
        mainItem.links.forEach(link => {
          if( idListToRemove.includes(link._id) ){
            removedLinks.push( link );
          }else{
            linksToKeep.push( link );
          }
        })

        mainItem.links = linksToKeep;



        removedLinks.forEach(link => {
          let thatId = link._id;
          let thatType = link.type;

          let itemToRemoveLinkFrom = 
            G.getData(draft,{itemType:thatType,_id:thatId});
          let indexOfLinkToRemove = itemToRemoveLinkFrom.links.findIndex(link => link._id === _id);


          itemToRemoveLinkFrom.links.splice(indexOfLinkToRemove,1);

        })
      })



    }

    case C.addValidationTemplate:{
      let { validationId, panels, panelDataToAdd,index } = action;

      return produce(state,draft => {
        let templates = draft.data['antibodyValidations'][validationId].templates
        templates.splice(index||templates.length,1,panels);

        panelDataToAdd.forEach(panel => {
          createItem(draft,panel.data,panel.meta,'figurePanel');
        })
      })


    }
    case C.selectFigurePanel:{

      return {
        ...state,
        selectedFigurePanelId:action._id
      }

    }
    case 'CLI':{

      let isCLI = action.cli; 
      if( isCLI === false ){
        return produce(state,draft => {
          //draft.loginInfo.status = null;
          //draft.loginInfo.username = null;
          draft.CLI = false;
        })
      }

      if( state.loginInfo.status === 'loggedIn' ){
        let mainThreadId = 'guestMainThread';
        return produce(state,draft => {
          draft.CLI = isCLI;
          //draft.mainThreadId = mainThreadId;
          //draft.records.threads.byId[ mainThreadId ] = ;
        })
      }

      let userId = state.loginInfo.userId || 'guestId';

      return {
        ...state,
        CLI:(isCLI === undefined ? true : isCLI),
        loginInfo:{
          status:'loggedIn',
          username:'cliAccount',
          userId
        }
      }
    }

    case C.setPasswordRequestFailReason:{

      let { reason } = action;
      return produce(state,draft => {
        let { loginInfo } = draft;
        loginInfo.reason = reason;
      })
    }

    case C.clearAuthStatusReason:{
      return produce(state,draft => {
        let { loginInfo } = draft;
        if( loginInfo ){
          delete loginInfo.reason;
        }
      })
    }


    case C.receiveSyncResponse:
    case C.RECEIVE_RESPONSE:
    case C.syncSuccess:
    case C.syncFailure:{
      return state;
    }


    case C.processSyncResponse:{
      let { json } = action;
      let { syncId, objectsProcessed, userConfigResponse } = json;


      return produce(state,draft => {

        if( !objectsProcessed || json.reason ){
          createDialog(draft,{
            dialogName:Dialog.WITH_NOTIFICATION_MESSAGE,
            message:"Could not sync objects: " + json.reason
          })
          return;

        }

        Object.entries(objectsProcessed).forEach(([itemType,typeSpecificList]) => {
          Object.entries(typeSpecificList).map(([itemId,item]) => {
            if( draft.syncStatus.records[ itemType ][ itemId ].syncId === syncId ){
              delete draft.syncStatus.records[ itemType ][ itemId ];
            }
          })

          if( Object.keys(draft.syncStatus.records[itemType]).length === 0 ){
            delete draft.syncStatus.records[itemType];
          }
        })

        let userConfigSyncIdMatch = draft.syncStatus.userConfig.syncId === syncId;
        let userConfigSyncSuccess = (userConfigResponse && userConfigResponse.success);
        if(userConfigSyncIdMatch && userConfigSyncSuccess){
          draft.syncStatus.userConfig = {};
        }
      });
    }

    case C.createItems:{
      let { itemsByType } = action;
      return produce(state,draft => {
        Object.entries(itemsByType).forEach(entry => {
          let [itemType,item] = entry;
          let { data, meta } = item;
          createItem(draft,data,meta,itemType,action);

        })
      })
    }

    case C.createItem:{
      let { data, meta, itemType } = action;

      return produce(state,draft => {
        createItem(draft,data,meta,itemType,action)
      })
    }

    case C.setMediaURL:{
      let { imageId,version,url } = action;
      return produce(state,draft => {
        setMediaURL(draft,{imageId,version,url});
      })

    }

    case C.finalizeUpload:{
      let { uploadWidth, imageId, height, url, version } = action;

      let targetVersion = version || DEFAULT_IMAGE_VERSION;

      return produce(state,draft => {
        let image = draft.data.imageUploads[imageId];
        image.uploadWidth = uploadWidth;
        image.height = height;
        delete image.loading;

        image.versions[targetVersion] = {
          uploadWidth,
        }

        setMediaURL(draft,{imageId,version:targetVersion,url});



      })
    }
    case C.startImageUploads:{
      let { type, urls, filenames, imageSetIds, imageIds, uploadWidths, heights } = action;

      /* 
       * The filesystem meta creation is taken care of
       * for imageSets in the ChangeReducer.
       *
       * While ideally we would just use the 
       * createItem(s) endpoint, this is 
       * technical debt that we'll need to pay off.
       * 
       * Really, this is an "object" which implements/extends
       * filesystem behaviour and it would be great
       * if we could use some nice ORM or design to 
       * ensure we implement the required methods
       * for those requred functionalities.
       *
       * Now, it does seem like OOP is really useful.
       * */

      let moveToPendingRecords = action.pendingRecords;
      let targetContainerName = moveToPendingRecords ? "pendingRecords" : "data";





      let images = imageIds.map((imageId,ii) => {
        return Image({ 
          _id:imageId, 
          loading:true, 
          filename:filenames[ii],
          height:(heights && heights[ii]),
          imageSetId:imageSetIds[ii], 
          versions:{
            [DEFAULT_IMAGE_VERSION]:{
              uploadWidth:(uploadWidths && uploadWidths[ii])
            }

          }
        })
      })

      return produce(state,draft => {

        let targetContainer = draft[targetContainerName];
        if( !targetContainer[IMAGE_SETS] ){
          targetContainer[IMAGE_SETS] = {};
        }
        if( !targetContainer[IMAGE_UPLOADS] ){
          targetContainer[IMAGE_UPLOADS] = {};
        }


        //let imageSets = draft.targetContainer[IMAGE_SETS];
        images.forEach((image,ii) => {

          let { _id, imageSetId } = image;

          if( !moveToPendingRecords ){
            let imageSets = targetContainer[IMAGE_SETS];
            let targetImageSet = imageSets[imageSetId]
            if( targetImageSet ){
              targetImageSet.images.push(_id);
            }else{
              imageSets[imageSetId] = 
                ImageSet({
                  _id:imageSetId,
                  images:[_id] 
                }); 

            }
          }

          draft[targetContainerName][IMAGE_UPLOADS][ _id ] = 
            image;

         
          let imageId = _id;

          if( !(imageId in draft.media) ){
            draft.media[ imageId ] = {
              [DEFAULT_IMAGE_VERSION]:{
                pending:false,
                localBlobUrl:urls[ii]
              }
            }
          }

        })
      })

    }
    case C.restoreImages:{

      let { imageIds, destinationImageSetId } = action; 
      let setToRemove = new Set(imageIds);

      return produce(state,draft => {
        createDestinationImageSetIfItDoesntExist(draft,destinationImageSetId,imageIds);

        draft.archivedImages = draft.archivedImages.filter(id => 
          !setToRemove.has(id)
        )

        addImageIdsToDesitnation(draft,imageIds,destinationImageSetId);





      })
    }
    case C.archiveImages:{

      let { imageIds } = action;
      return produce(state,draft => {
        archiveImages(draft,imageIds);
      })


    }

    case C.moveImagesToImageSet:{

      let { 
        imageIds, 
        destinationImageSetId, 
        annotationTransferOption, 
        newAnnotationIds,
        imageSetFilesystemDestination
      } = action;

      return produce(state,draft => {

        let imageIdsToMove = filterOutAnyImagesMovingToTheSameImageSet(draft,imageIds,destinationImageSetId);

        let x = raw(imageIdsToMove);

        createDestinationImageSetIfItDoesntExist(draft,destinationImageSetId,imageIdsToMove,imageSetFilesystemDestination);


        
        if( imageIdsToMove.length > 0 ){
          updateAnnotationsAccordingToTransfer(draft,
            imageIdsToMove[0],
            destinationImageSetId,
            annotationTransferOption,
            newAnnotationIds
          );
        }

        removeImageIdsFromCurrentImageSet(draft,imageIdsToMove);
        addImageIdsToDesitnation(draft,imageIdsToMove,destinationImageSetId);
        archiveAllEmptyImageSets(draft);


      })

    }

    

    case C.setAnnotationLaneCount:{
      let { _id, laneCount, ids } = action;

      let idsToSet = Array.from ( new Set([ ...(ids||[]), (_id||[])].flat()) )



      let defaultOffsets = () => (['DEFAULT','DEFAULT']); 

      let newLaneBoundaryPositions = Array(laneCount).fill(null).map(defaultOffsets);

      return produce(state,draft => {

        idsToSet.forEach(atnId => {
          let annot = draft.data.annotations[atnId];
          annot.laneBoundaryPositions = newLaneBoundaryPositions;
        })
      })
    }
    case C.setAnnotationProperties:{

      //defaultLaneSpacing;; (or spaceBetweenLanes) is a fraction
      // which is the size of the default lane;;; lsSize / number of lanes
      // then spacing is how much of that (lsSize/numberOfLanes) should be space

      let { type, _id, laneCount, ids, ...properties } = action;
      let idsToSet = Array.from ( new Set([ ...(ids||[]), (_id||[])].flat()) )

      let toSet = {
        ...state.data.annotations[action._id],
        ...properties
      }

      let imageSetId = G.getImageSetIdByAnnotationId(state,{_id});
      return produce(state,draft => {
        idsToSet.forEach(atnId => {
          let atn = G.getData(draft,{itemType:ANNOTATIONS,_id:atnId});
          
          Object.entries(properties).forEach(([key,val]) => atn[key] = val);


          if( properties.ls && !atn.quantifications ){

            let expectedLaneProperties = G.getExpectedLaneProperties(draft, {imageSetId,ls:properties.ls});
            for(let key in expectedLaneProperties){
              atn[key] = expectedLaneProperties[key];
            }

          }

        })

        



        //draft.data.annotations[action._id] = toSet;
      })

    }

    case C.deleteAnnotation:{
      return produce(state,draft => {
        archiveRecord(draft,ANNOTATIONS,action._id);
        //let meta = G.getMeta(draft,{type:ANNOTATIONS,_id:action._id});
        
        //delete draft.data.annotations[action._id]
      })

    }
    case C.addAnnotation:{
      let { type, ...annotation } = action; 

      let {imageSetId} = annotation;
      if( !imageSetId ){
        throw Error("You can't add an annotation without providing the imageSetId it belongs to.");
      }

      

      
      let fullAnnotation = {
        label:"",
        ...annotation,
        links:{}
      };

      if( fullAnnotation.ls ){

        let expectedLaneProperties = G.getExpectedLaneProperties(state, {imageSetId,ls:annotation.ls});
        for(let key in expectedLaneProperties){
          fullAnnotation[key] = expectedLaneProperties[key];
        }


        fullAnnotation.ls = 
          fullAnnotation.ls.sort((a,b) => (a[0] - b[0]));
      }

      return produce(state,draft => {
        draft.data.annotations[annotation._id] = fullAnnotation;
        draft.focusedAnnotationId = annotation._id
      })


    }

    case C.setGlobalConfig:{
      let { type, ...properties } = action;
      return {
        ...state,
        ...properties
      }
    }
    case C.SET_HEIGHT_GROUP_PROPERTIES:{

      let { type, _id, __registeredName, ...properties } = action;
      return produce(state,draft => {
        Object.entries(properties).forEach(([k,v]) => {
          draft.heightGroups[_id][k] = v
        })
      });
    }
    case C.ADD_HEIGHT_GROUP:{

      let { type,__registeredName, ...args } = action;
      return produce(state,draft => {
        draft.heightGroups[args._id] = args
      })
    };
    case C.REPORT_MESSAGE:{
      return produce(state,draft => {
        draft.dialogs.push( { messageType:"error", ...action.messageData } );
      })
    }
    case C.POP_MESSAGE:{
      return produce(state,draft => {
        draft.dialogs.splice(0,1);
      })
    }
    case C.EXPORT_FIGURE:{return state;}
    case C.SET_CROP_PROPERTY:{
      let { _id, ...properties } = action;
      return produce(state,draft => {
        Object.entries(properties).forEach( entry => {
          draft.data.crops[ _id ][ entry[0] ] = entry[1];
        })
      })

    }
    case C.ROTATE_IMAGE:{
      let { imageSetId, rotation } = action;
      return produce(state,draft => {
        draft.data.imageSets[imageSetId].rotation = rotation;
      })
    }
    case C.SET_IMAGE_PROPERTY:{
      let { imageId, property, value, ...args } = action;
      return produce(state,draft => {
        let currentValue = draft.data.imageUploads[imageId][property];
        let valueToSet = value;
        let adjustments = draft.data.imageUploads[imageId].adjustments;
        adjustments[property] = valueToSet;
        if( !adjustments.version ){
          //adjustments.version = 1;
        }

      })

    }
    case C.setImageProperties:{
      let { imageId, properties } = action;
      return produce(state,draft => {
        let item = G.getData(draft,{itemType:'imageUpload',_id:imageId});
        setData(item,properties)
      })
    }

    
    case C.ADD_CROP:{
      let {type,...args} = action;
      let _id = action._id;

      return produce(state,draft => {

        draft.data.crops[ _id ] = Crop({
          ...args,
        })
      })
    }
    case C.createDialog:{
      return produce(state, draft => {
        createDialog(draft,action)
      });
    }
    
    case C.setFigureImage:{
      let { imageSetId, imageId } = action;
      if( !imageSetId ){
        imageSetId = G.getImageSetIdByImageId(imageId);
      }
      return produce(state, draft => {
        draft.data.imageSets[imageSetId].figureImageId = imageId;
      })
    }
    case C.DELETE_ANNOTATION_LINE:{
      let { cropId, lineId, lineIds } = action;


      if( lineIds === undefined ){
        lineIds = [lineId]
      }

      let lineIdsToRemove = lineIds;

      return produce(state,draft => {

        let crop = draft.data.crops[cropId];
        let lines = crop.lines;
        let labels = crop.labels;
        //delete line records
        lineIdsToRemove.forEach(lineId => delete lines[lineId]);

        //remove lines from labels
        Object.keys(labels).forEach( labelId => {
          let newLines = labels[labelId].lines.filter(
            lineId => !lineIdsToRemove.includes(lineId)
          )
          if( newLines.length === 0 ){
            delete labels[labelId];
          }else{
            labels[labelId].lines = newLines;
          }
        })
      })

    }
    case C.SWAP_SIDES_OF_BAND_ANNOTATION:{
      let { cropId } = action;
      let leftLabelGroupId = state.data.crops[cropId].leftLabelGroupId;
      let rightLabelGroupId = state.data.crops[cropId].rightLabelGroupId;

      return produce(state,draft => {
        let crop = draft.data.crops[cropId];
        crop.leftLabelGroupId = rightLabelGroupId;
        crop.rightLabelGroupId = leftLabelGroupId;

      })
    }

    case C.SET_LABEL_PROPERTY:{
      let { cropId, labelIds, property, value } = action;

      return produce(state,draft => {
        labelIds.forEach( labelId => 
          draft.data.crops[cropId].labels[labelId][property] = value
        )
      })
    }


    case C.ADD_BAND_LABEL:{

      let { cropId, lineId, rightLabelId, leftLabelId, height,position } = action;

      position = height || position;

      return produce(state,draft => {
        let crop = draft.data.crops[ cropId ]; 

        crop.lines[ lineId ] = {
          _id:lineId,
          labels:{
            [crop.leftLabelGroupId]:leftLabelId,
            [crop.rightLabelGroupId]:rightLabelId,
          },
          position,
        }

        crop.labels[ leftLabelId ] = 
          Label({_id:leftLabelId, lines:[lineId]})


        crop.labels[ rightLabelId ] = 
          Label({_id:rightLabelId, lines:[lineId]})


      })

    }

    case C.END_SESSION:{
      return state;
    }

    case C.setCellWidths:{
      let { figurePanelId, cellsToSet, widths } = action; 

      figurePanelId = figurePanelId||state.selectedFigurePanelId;

      if(!figurePanelId){

        throw Error("figurePanelId cannot be undefined.");

      }

      return produce(state,draft => {

        let figurePanel = draft.data.figurePanels[figurePanelId];



        cellsToSet.forEach( (cell,iiCell) => {

          let width = widths[iiCell];
          figurePanel.columnWidths[cell[1]] = width;

        })

      })
    }

    case C.INJECT_ROUTE:{
      return {
        ...state, route:action.route
      }
    }
    

    case '@@INIT':{ 
      return state;
    }
    case C.SELECT_CROP:{
      return produce(state,draft => {
        draft.selectedCells = [];
        draft.selectedCrop = action.cropId;

      })
    }
    case C.DELETE_COLUMN:{
      let { columnIndices, figurePanelId } = action;

      let uniqueColumnIndices = new Set(columnIndices);
      console.log({uniqueColumnIndices});
      return produce(state,draft => {
        let figurePanel = draft.data.figurePanels[figurePanelId];
        figurePanel.grid.forEach((row,iiRow) => {
          figurePanel.grid[iiRow] = figurePanel.grid[iiRow].filter((_,iiCol) => !uniqueColumnIndices.has(iiCol)
          )

        })

        figurePanel.columnWidths = 
          figurePanel.columnWidths.filter((_,ii) => {
            return !uniqueColumnIndices.has(ii);
          })


        figurePanel.grid = figurePanel.grid.filter( row => row.length > 0 );

      })
    }
    case C.DELETE_ROW:{

      let { rowIndices, figurePanelId } = action;

      let uniqueRowIndices = Array.from(new Set(rowIndices));


      return produce(state,draft => {
        let figurePanel = draft.data.figurePanels[figurePanelId];



        let reverseSorted = uniqueRowIndices.sort((a,b)=>b-a);

        reverseSorted.forEach(index => 
          figurePanel.grid.splice(index,1)
        )

      })
    }
    case C.SET_EDITOR_STYLE:{
      let args = action.args;


      return produce(state,draft => {
        toggleMapProperties(draft.editorStyle,args)
        
      })
    }
    case C.TRANSFORM_IMAGE:{
      let { rotation, crops, imageSetId } = action;

      return produce(state,draft => {

        let imageSet = draft.data.imageSets[imageSetId];

        imageSet.rotation = rotation;

        let cropIds = crops.map( crop => crop._id )

        crops.forEach( crop => {

          draft.data.crops[crop._id] = Crop({
            ...crop,
            imageSetId,
            cropIds
          });

        })


      })
    }
    case C.INSERT_COLUMN:{

      return produce(state,draft => {
        updateGridAndIdMaps(draft,action);
        let figurePanel = draft.data.figurePanels[action.figurePanelId];
        let { figurePanelType } = figurePanel;

        let currentWidths = [
          figurePanel.columnWidths[action.columnIndex - 1],
          figurePanel.columnWidths[action.columnIndex]
        ].filter(x => x);

        let newColWidth = Math.min(...currentWidths) || 30;

        figurePanel.columnWidths.splice(action.columnIndex, 0, newColWidth)
      })
    }

    case C.UPLOAD_IMAGE:{

      let { 
        imageSetIds,
        urls, imageIds, uploadWidths, heights, cropIds, filenames } = action;


      return produce(state,draft => {

        //1. inject image object
        //2. inject crop object

        imageSetIds.forEach((imageSetId,ii) => {
          let imageSet = draft.data.imageSets[ imageSetId ];
          if( imageSet === undefined ){
            imageSet = ImageSet({
              _id:imageSetId, 
              rotation:0,
              figureImageId:imageIds[ii],
              images:imageSetIds.map((id,jj) => {
                return imageSetId === id ? jj : undefined
              }).filter(x => x!==undefined).map(index => imageIds[index])
            })

            draft.data.imageSets = {
              [imageSetId]:imageSet,
              ...draft.data.imageSets
            }
          }
        });


        let imageUploadsMap = {};
        imageIds.forEach((imageId,ii) => {

          draft.media[ imageId ] = { 
            [DEFAULT_IMAGE_VERSION]:{
              pending: false, 
              localBlobUrl: urls[ii]
            }
          };

          imageUploadsMap[imageId] = {
            imageSetId:imageSetIds[ii],
            _id:imageId,
            //url:urls[ii],
            height:heights[ii],
            versions:{
              [DEFAULT_IMAGE_VERSION]:{
                uploadWidth:uploadWidths[ii],
              }
            },
            filename:filenames[ii],
            defaultCropId:cropIds[ii],
            adjustments:{}
          }
        })

        draft.data.imageUploads = {
          ...draft.data.imageUploads,
          ...imageUploadsMap
        }


      })

    }
    case C.setCellsValue:{


      let { figurePanelId, type, cells, cellGroupIds, ...properties } = action;

      let { value, ...valueProperties } = properties;




      let currentFigurePanel = state.data.figurePanels[figurePanelId];
      if(!currentFigurePanel){
        throw Error("Can't set cells value without a figure panel to set them on.");

      }
      if( cellGroupIds === undefined ){


        if( !cells ){
          debugger;
        }
        if( !cells.map ){ debugger; }
        cellGroupIds = cells.map(cell => {
          let [row,col] = cell;

          return currentFigurePanel.grid[row][col];
        })
      }

      let idToCellMap = G.getIdToCellMap(state,{figurePanelId});

     
      cellGroupIds = Array.from(new Set(cellGroupIds));


      return produce(state,draft => {

        let figurePanel = draft.data.figurePanels[figurePanelId];
        cellGroupIds.forEach( groupId => {

          let targetGroup = figurePanel.cellGroups[ groupId ]
          if( !targetGroup ){

          }
          let currentValue = targetGroup.value;
          let settingToString = typeof(properties.value)==='string';
          let settingToImage = properties.value && properties.value.valueType === 'image';
          if( currentValue.valueType === 'image' && settingToString ){
            let adjacentGroupIds = G.getAdjacentGroupIds(state,{groupId,figurePanelId});
            let leftGroup = state.cellGroups[ adjacentGroupIds.left ];
            let rightGroup = state.cellGroups[ adjacentGroupIds.right ];


            let lgv = leftGroup.value;
            let rgv = rightGroup.value;



            if( lgv.valueType === 'bandAnnotation' && lgv.sideRelativeToImage === 'left' && !cellGroupIds.includes(leftGroup._id) ){
              setCellValue(figurePanel,leftGroup._id,{ value:"" });
            }

            if( rgv.valueType === 'bandAnnotation' && rgv.sideRelativeToImage === 'right' && !cellGroupIds.includes(rightGroup._id) ){
              setCellValue(figurePanel,rightGroup._id,{ value:"" });
            }
          }



          setCellValue(figurePanel,groupId,properties);
        })
      })





    }
    case C.mergeCells:{
      return produce(state,draft => {

        let { cells, figurePanelId } = action;
        if(!figurePanelId){ throw Error("figurePanelId cannot be undefined."); }
        let figurePanel = draft.data.figurePanels[figurePanelId];

        if( cells.length <= 1 ){
          createDialog(draft,{
            dialogName:Dialog.WITH_NOTIFICATION_MESSAGE,
            message:"You need at least 2 cells to merge."
          });
          return;
        }

        let rows = new Set();
        let cols = new Set();
        cells.forEach(cell => {
          rows.add(cell[0])
          cols.add(cell[1])
        })

        if( rows.size > 1 && cols.size > 1 ){

          createDialog(draft,{
            dialogName:Dialog.WITH_NOTIFICATION_MESSAGE,
            message:"You can only merge cells in the same row or column."});
          return;

        }

        //take the first cell of the list as the new id for each of the cells
        let newId = figurePanel.grid[ cells[0][0] ][ cells[0][1] ];


        cells.slice(1).forEach( cell => {
          let [row,col] = cell;

          let cellsInGroup = G.getCellsInGroup(state,cell,figurePanelId);

          let targetRow = figurePanel.grid[row]
          if( !targetRow ){

          }
          let idToRemove = targetRow[col];
          figurePanel.grid[row][col] = newId;

          cellsInGroup.forEach(adjacentCell => {
            figurePanel.grid[adjacentCell[0]][adjacentCell[1]] = newId;
          })


          delete figurePanel.cellGroups[idToRemove];


        })

        //draft.selectedCells = [];





      })

    }

    case C.moveRow:{

      let { figurePanelId, fromIndex, toIndex, mergeWith, newGroupIds } = action;

      let insertionInfo = G.getNewRowGroupsAfterMove(state,action);
      let { grid, cellGroups } = insertionInfo;
      return produce(state,draft => {

        let figurePanel = G.getFigurePanel(draft,{figurePanelId});


        //let { grid } = figurePanel;

        //let toMove = grid.splice(fromIndex,1)[0];


        

        


        figurePanel.grid = grid;
        figurePanel.cellGroups = cellGroups;

        /*

        toIndex = Math.min(toIndex,grid.length);
        let resolvedToIndex = toIndex;//fromIndex < toIndex ? toIndex - 1 : toIndex;
        grid.splice(resolvedToIndex,0,toMove)
        */


      })


    }
    case C.insertRow:{

      let { figurePanelId, rowIndex, newIdRow, entriesOfValuesToSet } = action;



      let currentFigurePanel = state.data.figurePanels[figurePanelId];
      let newIds = newIdRow.filter( id => {
        return !(id in currentFigurePanel.cellGroups)
      })

      let cellGroupMap = makeCellGroupMap(newIds,state.editorStyle);

      entriesOfValuesToSet = entriesOfValuesToSet||[];

      entriesOfValuesToSet.forEach( ([groupId,groupDict]) => {
        let group = cellGroupMap[ groupId ];

        cellGroupMap[ groupId ] = {
          ...group,
          ...groupDict
        }
      })




      return produce(state,draft => {

        let figurePanel = draft.data.figurePanels[figurePanelId];
        let { figurePanelType } = figurePanel;

        if( figurePanel.grid.length === 0 ){

          figurePanel.columnWidths = Array(newIdRow.length).fill(
            getDefaultColumnWidths({figurePanelType})
          )
        }

        mapInsert(figurePanel,'cellGroups',cellGroupMap)
        //let x = JSON.parse(JSON.stringify(figurePanel.grid));
        //x.splice(rowIndex,0,rowToInsert);
        figurePanel.grid.splice(rowIndex,0,newIdRow)

      })

    }

    case C.SPLIT_CELLS:{
      let { cells,splitInfoForEachCell,figurePanelId } = action;

      return produce(state, draft => {
        cells.forEach( (cell,iiCell) => {
          let figurePanel = draft.data.figurePanels[figurePanelId];
          let cellId = G.idAtCell(state,cell,figurePanel);
          let cellGroup = G.getCellGroup(state,cellId,figurePanel);
          let splitInfo = splitInfoForEachCell[iiCell];
          let { cellsMerged, ids } = splitInfo;



          cellsMerged.forEach( (mergedCell,iiMergedCell) => {

            let [mcrow, mccol] = mergedCell;

            let newId = ids[iiMergedCell];

            figurePanel.grid[mcrow][mccol] = newId;
            figurePanel.cellGroups[ newId ] = cellGroup;

          })
        })

        draft.selectedCells = [];
      })

    }

    case C.receiveAuthResponse:{

      let { response,status,username,receivedCookie,userId,reason, authAttemptId, userInfo } = action;

      //let { type, ...actionLoginInfo } = action;

      if( action.isOffline ){
        return produce(state, draft => {
          draft.loginInfo.authPending = false;
          draft.loginInfo.reason = action.reason;
          draft.loginInfo.authAttemptId = authAttemptId;
        })
      }



      let loginInfo = {
        status,
        username,
        cookie:receivedCookie,
        userId,
        reason,
        response,
        authAttemptId,
        authPending:false,
      }
      
      return {
        ...state,
        loginInfo,
        userInfo
      }
      
    }


    case C.receiveSignupResponse:{

      let newSignupInfo = {
        ...state.signupInfo,
        status:action.status,
        username:action.username,
        response:action.response,
        cookie:action.receivedCookie,
        reason:action.reason
      }

      return {
        ...state,
        loginInfo:newSignupInfo
      }

    }

    case C.receiveLoginResponse:{


      if( action.status.response === 'failed' ){

        let reason = action.status.reason;
        if( typeof(document) === 'undefined' ){
          //throw Error("\n\n\tCould not connect to server: " + reason + ".\n");
          return {}
        }

        return produce(state,draft => {
          draft.loginInfo = {
            ...draft.loginInfo,
            loggedIn:false,
            reason:action.status.reason
          }
        });
      }

      let newLoginInfo = {
        ...state.loginInfo,
        status:action.status,
        username:action.username,
        response:action.response,
        cookie:action.receivedCookie,
        userId:action.json.userId,
        reason:action.json.reason
      }

      return produce(state,draft => {
        draft.loginInfo = newLoginInfo
      })

    }
    case C.injectRecordsFromServer:{
      //let { userConfig, firstLogin, surveys } = action;
      return produce(state,draft => {
        injectRecordsFromServerAndUpdateMediaReferences(draft,{records:action.records});

        /*
        draft.userConfig = userConfig;
        draft.firstLogin = firstLogin;
        draft.surveys = surveys;
        */

      })
    }
    case C.mergePersistedUserDataWithCurrentSessionData:{
      //let { userConfig, firstLogin } = action;
      return produce(state,draft => {
        mergePersistedUserDataWithCurrentSessionData(draft,action);
      })
    }
    case C.FAILED_TO_INJECT_LOGIN_RESPONSE:{
      return state;
    }

    case C.LOGOUT_USER:{
      return stateClearedOfUserData(state);
      //getDefaultState(); 
    }

    case C.makeRequest:{
      let { route } = action;
      return produce(state,draft => {
        draft.requests[route] = {
          pending:true
        }
      })
    }

    case C.receiveRequestResponse:{
      let { route, json } = action;
      return produce(state,draft => {
        let requests = draft.requests;
        requests[route] = json;
      })


    }

    case C.updateRequestStatus:{
      let { requestId, status, timestamp } = action;
      return produce(state,draft => {
        let req = draft.requests.sync[requestId];
        req.updates.push({
          status,
          timestamp
        })
      })
    }

    case C.sendSyncRequest:{

      let { syncObject } = action;

      let { timestamp, } = action;
      let { syncId, requestStatusOnSend } = syncObject;


      return produce(state,draft => {
        updateSyncStatus(draft,syncObject,PENDING);

        draft.requests.sync[ syncId ] = {
          _id:syncId,
          updates:[{
            status:(requestStatusOnSend||"PENDING"),
            timestamp
          }]
        }

        //draft.requests.sync[ syncObject.

      })
    }

    case C.requestQuantificationAccess:{
      return produce(state, draft => {

        draft.quantificationAccessRequested = true;

      })
    }

    case C.finishSyncImageCloudStorage:{
      let { imagesToSync, syncResults, storageLocation } = action
      return produce(state,draft => {

        imagesToSync.forEach((toSyncInfo,ii) => {
          let resultingSyncId = syncResults[ii];

          if( resultingSyncId ){

            let imageId = toSyncInfo._id;

            let image =G.getImage(draft,{pendingRecords:true,
              imageId});
            let imageVersion = image.versions[toSyncInfo.version];
            //imageVersion.remoteStorageResourceId = resultingSyncId;
            //imageVersion.storageLocation = storageLocation;

            if( toSyncInfo.version !== 'raw' ){

              draft.data[IMAGE_UPLOADS][imageId] = image;
              delete draft.pendingRecords[IMAGE_UPLOADS][imageId];

              let imageSetId = image.imageSetId;
              if( !draft.data.imageSets[imageSetId] ){
                draft.data.imageSets[imageSetId] = ImageSet({
                  _id:imageSetId,
                  images:[imageId]
                })
              }
            }
          }

        })

      })
    }

    case C.updateThread:{
      let { thread } = action;
      return produce(state,draft => {
        draft.threads[thread._id] = thread;
      })
    }


    case C.readMessages:{
      let { threadId, messageIds } = action;
      let personId = state.personId;
      let now = Number(Date.now())
      return produce(state,draft => {
        let thread = draft.threads[threadId];
        if( !thread ){
          debugger;
        }
        let { messages } = thread;
        
        messageIds.forEach(_id => {
          messages[_id].read[
            personId
          ] = now
        })
      })
    }


    case C.createThread:{
      let { thread } = action;
      return produce(state, draft => {
        draft.threads[thread._id] = thread;
      })
    }

    case C.messageSendFailure:{
      return produce(state,draft => {
        let messageArg = action.message;
        let messageId = messageArg.messageId || messageArg._id;
        let thread = draft.threads[action.threadId];
        let message = thread.messages[ messageId ];
        
        message.sendFailure = true;
      })
    }

    case C.sendMessage:{

      return produce(state, draft => {

        let messageArg = action.message;
        let messageId = messageArg._id;

        let thread = draft.threads[action.threadId];
        if( !thread ){
          debugger;
        }
        let messages = thread.messages;
        
        messages[messageId] = {
          _id:messageId,
          ...action.message,
          from:(draft.loginInfo.userId || draft.personId),
          read:{},
          delivered:{}
        }
      })
    }

    case C.threadUpdateRequested:{
      return produce(state,draft => {
        draft.requestingThreads = true;
      });
    }
    case C.threadUpdateReceived:{
      return produce(state,draft => {

        let { thread } = action;
        if( thread ){
          draft.threads[thread._id] = thread;
        }

      });
    }


    case C.resendMessage:{
      return produce(state,draft => {
        let message = G.getMessage(draft,action);
        delete message.sendFailure;
      })
    }

    case C.pushNotification:{
      let { type, ...notification } = action;
      return produce(state,draft => {
        draft.notifications.push(notification);
      })
    }
    case C.popNotification:{
      let { _id } = action;
      return produce(state,draft => {
        draft.notifications = draft.notifications.filter(x => x._id !== _id)
      })
    }

    case C.clearAllNotifications:{
      return produce(state,draft => {
        draft.notifications = [];
      })
    }


    case C.notifyMediaPersistenceError:{
      let { imageSpec, errorType } = action;
      return produce(state,draft => {
        let mediaItem = G.getMediaItem(draft,imageSpec);
        mediaItem.persistError = errorType;

        delete mediaItem.postInfo;

        /*
        if( mediaItem.postInfo === "fetching" ){
          delete mediaItem.postInfo;
        }
        */
      })
    }

    case C.clearPostData:{
      let { imageSpec } = action;
      let { _id, version } = imageSpec;
      return produce(state,draft => {

        delete draft.media[_id][version].postInfo;
      })
    }

    case C.startSyncImageCloudStorage:{
      let { imagesToSync } = action;

      return produce(state,draft => {

        for(let imageSpec of imagesToSync){

          let { _id,version } = imageSpec;

          let versionMap = draft.media[(_id)]
          let mediaItem = versionMap[version]

          delete mediaItem.postInfo;
          delete mediaItem.persistError;
        }
        

      })
    }

    case C.processAuthSuccess:{
      let { records, userConfig, firstLogin } = action;
      return produce(state,draft => {
        queueAnonymousSessionDataForSync(draft);
        injectRecordsFromServerAndUpdateMediaReferences(draft,{records});
        mergePersistedUserDataWithCurrentSessionData(draft,{userConfig,firstLogin})
      })
    }


    case C.startMediaProcessing:{

      let { imageId, processArgs } = action;

      return produce(state,draft => {

        draft.mediaProcessing[imageId] = {
          errors:[],
          status:"pending",
          processArgs
        }

      })

    }

    case C.fetchImages:{
      let { imagesToFetchInfoList } = action; 
      return produce(state,draft => {
        let media = draft.media;

        imagesToFetchInfoList.forEach(imageSpec => {
          let { imageId, version } = imageSpec;
          let imageMediaContaner = media[imageId];
          if( !imageMediaContaner ){
            media[imageId] = {};
            imageMediaContaner = media[imageId];
          }

          let versionInfoContainer = imageMediaContaner[version];
          if( !versionInfoContainer ){
            imageMediaContaner[version] = {}
            versionInfoContainer = imageMediaContaner[version];
          }

          versionInfoContainer.pending = true;
          delete versionInfoContainer.status;

        })

      })
    }

    case C.receiveSendPasswordRecoveryResponse:{
      let { data } = action;
      return produce(state,draft => {
        draft.passwordResetInfo = {
          ...draft.passwordResetInfo,
          ...(data||{}),
        }
      })
    }



  

    case C.fetchUserAuthResponse:{
      return produce(state, draft => {
        draft.loginInfo.authPending = true;
      })
    }

    case C.loginCompleted:{
      return produce(state,draft => {
        draft.loginInfo.completed = true;
      })
    }

    case C.requestPasswordRecoveryCode:{
      let { type, ...data } = action;
      return produce(state,draft => {
        draft.passwordResetInfo.user = (data.email || data.user)
      })
    }



    case C.sendImagesToServer:
    
    case C.FETCH_LOGOUT_RESPONSE:
    case C.noSyncNecessary:

    

    case C.dispatchGetter:
    case C. showFetch:

    case C.alertingServer:
    case C.postServerAlert:
    case C.rejectExpiredRequestResponse:
    case C.registerReferrer:
    case C.answerSurveyPostFail:
    
    case C.answerSurveyPostSuccess:

    case C.requestCheckoutLink:
    case C.enterApp:
    case C.openAuthModal:
    case C.changePassword:
    case C.downloadQuantificationValues:
    case C.serverAlerted:{
      return state;
    }

    case C.updateMediaProcessing:{
      let { imageId, status, errors } = action;
      return produce(state,draft => {
        let mediaProcessingInfo = draft.mediaProcessing[imageId];

        mediaProcessingInfo.status = status;
        mediaProcessingInfo.errors.push(...errors);
        
      })
    }
    case "CLEAR_STATE":{
      return {}
    }

    

    default:{
      if( action.type.includes('@@redux') ){
        return state;
      }
      throw Error("Unhandled action type '"+action.type+"'");
    }
  }


}

export default Reducer;
