import { useContext } from 'react'
import { GridBoundaryAdjustmentContext, ColumnResizeContext } from './Contexts';
import { GRID_COLUMN_RESIZE_Z_INDEX } from './UIConstants';

export default function ResizeElement({
  side,
  resizeFrom,

  leftResize,
  cell,
  cellResizeInfo,
  onMouseEnter,
  onMouseLeave,
  setDontUnselectCells,
  setMouseIsDown,
  setCurrentWidths,
  setCellResizeInfo,
  cellX,
  w,
  widths
}){

  let width = resizeFrom === "inside" ? 3 : 1;

  const columnResizeContext = useContext(ColumnResizeContext);
  const { setColumnResizeInfo } = (columnResizeContext||{});

  const { setAdjustmentSideContext, adjustmentSide } = useContext(GridBoundaryAdjustmentContext);

 
  //onMouseLeave = () => setColumnResizeInfo();

  /*if( adjustmentSide ){
    return null;
  }*/

  return (
    <div 
      objectType={"ResizeElement"}
      cell={[cell.y,cell.x].join('-')}
      resizeElement={side}
      class=" blue-hoverable cell-width-resizer-element"
      onMouseEnter={() => {
        if( !cellResizeInfo && !adjustmentSide ){
          setColumnResizeInfo({ cell:[cell.y,cell.x], resizeFrom, cellSide:side })
        }
      }}
      onMouseLeave={() => {

        //desired behaviour:
        //  if we we leave and we're resizing; do nothing
        //  if we leave and we're not resizing; 
        if( !cellResizeInfo && !adjustmentSide ){
          setColumnResizeInfo();
        }
      }}
      onMouseDown={e=>{

        

        if( e.button !== 0 ){
          return;
        }

        console.log("MOUSE DOWN ON RESIZE ELEMENT!");
        

        setDontUnselectCells(true);

        //e.stopPropagation();
        setMouseIsDown(true);
        setAdjustmentSideContext(side);
        setCurrentWidths(widths);
        setCellResizeInfo({
          cell:[cell.y,cell.x],
          cellX,
          cellXList:Array(w).fill(cell.x).map((x,ii) => x+ii),
          startX:e.clientX,
          currentX:e.clientX,
          leftResize:(side==="left" && resizeFrom==="inside")
        })
      }}

      onClick={e => {
        e.stopPropagation()
      }}
      onContextMenu={e => {
        e.preventDefault()
      }}
      style={{
        position:'absolute',
        [side]:0,
        top:0,height:'100%',
        background: (
          cellResizeInfo?.leftResize !== true && 
          cellResizeInfo?.cellX===(cell.x+cell.w-1)?'blue':undefined),
        width,
        cursor:'ew-resize',
        zIndex:GRID_COLUMN_RESIZE_Z_INDEX,

      }}>
    </div>
  )

}
