import G from './Getters';
import { useSelector } from 'react-redux';

import { ExpansionTemplateInfo, ExpansionWindowFocusContext } from './Contexts';


import FigureImageExpansionTemplateLayout from './FigureImageExpansionTemplateLayout';

export default function FigureImageExpansionTemplateLayoutContainer({template,onItemClick, thumbnail}){

  let { nodes } = template;

  let computedDimensions = useSelector(state => G.getComputedTemplateDimensions(state,{
    template,
    regions:{},
  })); 


  return (
    <ExpansionTemplateInfo.Provider value={{computedDimensions, thumbnail}}>

      <FigureImageExpansionTemplateLayout {...{
        nodeId:"root",nodes, 
        computedDimensions, template, 
      }}/>

    </ExpansionTemplateInfo.Provider>

  )
}
