import HistoryUpdatingProducer from './SciugoProduceWithUndoRedo';
import ActionTypesInvokedByServerResponse from './ActionTypesInvokedByServerResponse';
import ChangeReducer from './ChangeReducer';

export default function stateWithUpdatedChangeHistory(state,action){

  let changeReducedState = ChangeReducer(state,action);

  if( ActionTypesInvokedByServerResponse.includes(action.type) ){
    return changeReducedState;
  }

  let keysInNew = Object.keys(changeReducedState);
    let newKeySet = new Set(keysInNew);
    let keysNotInNew = Object.keys(state).filter(key => 
      !newKeySet.has(key)
    )

  return HistoryUpdatingProducer(state,draft => {

    keysInNew.forEach(key => {
      draft[key] = changeReducedState[key];
    })

    keysNotInNew.forEach(key => {
      delete draft[key];
    })
  })
}
