import Tooltip from '@material-ui/core/Tooltip';

export default function SupportSigninButton(){

  return (

    <div style={{
      //background:'red',
      fontSize:20, alignItems:'center', justifyContent:'center'}} class="sg-row">
    
      <Tooltip style={{fontSize:20}} title={<h4>{"support@sciugo.com"}</h4>} placement={"right"}>

        <div style={{width:'90%'}}>
        <button style={{
          width:'100%', marginBottom:8}} button={"need-support"} class="btn btn-warning bold">
      Need Support?
    </button>

      </div>
    </Tooltip>
   
    </div>

  )

}
