import { v4 } from 'uuid';

export default function Id(){
  return v4();
}

export function Ids(num){
  return Array(num).fill("").map(v4);
}

