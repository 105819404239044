import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import A from './ActionCreators';

export default function QuantificationCropInfoHeader({
  atn,
  entered,
  laneCount,
}){

  let dispatch = useDispatch();


  return (

    <div style={{
      marginRight:10,

    }} class="sg-row tiny-padding">
      <div style={{marginLeft:15}}>
      {atn.label && <div>{atn.label}</div>}
      {!atn.label && <div style={{color:'red',fontWeight:'bold'}}>Unlabeled target</div>}
      </div>
      <div class="flex-expand"/>
      {entered && (
        <div style={{marginRight:10}}>
          Lanes: <input 
            min={1}
            onChange={e => {
              let newLaneCount = Number(e.target.value);

              let isANum = !isNaN(newLaneCount);
              let positive = isANum && newLaneCount > 0;

              if( positive ){
                dispatch(A.setAnnotationLaneCount({_id:atn._id, laneCount:newLaneCount}));
              }
            }}
            
            value={laneCount} style={{
              lineHeight:0.7,
            padding:0, 
            fontSize:9,
          width:30}} type="number"/>
        </div>
      )}
    </div>

  )

}
