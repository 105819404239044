import LaneSettings from './LaneSettings';
import QuantificationActionButtons from './QuantificationActionButtons';

export default function QuantificationActionHeader({
  localState,
  localDispatch,
  ids,
  quantifyAll,
  downloadMacro,
  atns,
  atnQuantInfoList,
  loadingControlFeature
}){

  return (
    <>

      <div style={{
        //border:'3px solid orange'
      }} class="sg-row">
        <div style={{width:'50%', 
          //border:'3px solid orange'
        }}> 
          {false && <LaneSettings {...{ tempSettings:localState,
            tempSettingsChangeDispatch:localDispatch }} annotationIds={ids}/>}

          <QuantificationActionButtons {...{
            atnQuantInfoList,
            quantifyAll, downloadMacro, atns
          }}/>

        </div>


      </div>


      {loadingControlFeature && <div style={{
        //background:'yellow',
        width:'50%',textAlign:'right',fontSize:18,position:'relative',height:30}}>
        <div style={{position:'absolute',right:0,top:0,fontSize:18,fontWeight:'bold',transform:'translate(50%,0)'}}>
          Loading<br/>
          Control
        </div>
      </div>}

    </>
  )

}
