import { useState } from 'react';
import A from './ActionCreators';
import G from './Getters';
import { useDispatch, useSelector } from 'react-redux';
import Id from './IdFactory';
import { FIGURE_PANELS } from './RecordTypes';
import CancelIcon from '@material-ui/icons/Cancel';
import Navbar from './Navbar';
import GelPanelSpecification from './GelPanelSpecification';
import MicroscopyPanelSpecification from './MicroscopyPanelSpecification';


const WESTRN_BLOT_TYPE = "westernBlot";
const MICROSCOPY_PANEL_TYPE = "microscopy";

const options = [
  { _id:WESTRN_BLOT_TYPE, label:"Gel/Blot",  },
  //{ _id:MICROSCOPY_PANEL_TYPE, label:"Microscopy" }
]

export default function NoFigureSelectedScene({setSelectedFigurePanelContext, setTargetDirectoryOfNewFigurePanel, targetDirectoryOfNewFigurePanel}){

  
  let dispatch = useDispatch();

  const [figurePanelType, setFigurePanelType] = useState(WESTRN_BLOT_TYPE);
  let isGel = figurePanelType === WESTRN_BLOT_TYPE;
  let isMicroscopy = figurePanelType === MICROSCOPY_PANEL_TYPE;


  let panelSpecProps = {
        setSelectedFigurePanelContext, setTargetDirectoryOfNewFigurePanel, targetDirectoryOfNewFigurePanel
      }
  return (<>
    <div 
      containerType={"sceneContainer"}
      scene={"panelSpecification"}
      style={{
        textAlign:'center', 
        background:'#eee', 
        position:'relative',
      }} class="sg-col full-width">

      <div button="cancelFigureCreation" style={{
        position:'absolute',
        top:0, left:0, margin:10,
        cursor:'pointer'
      }}
        onClick={() => {
          setTargetDirectoryOfNewFigurePanel(false);
        }}
      >
        <CancelIcon />
      </div>

      <div style={{
        marginTop:50,
        //border:'1px solid blue',
        justifyContent:'center',
      }} class="sg-row v-align">

        <div>
          <Navbar style={{fontSize:18}} options={options} spread={false} onSelect={setFigurePanelType} selected={figurePanelType}/>
        </div>

      </div>

      {isGel && <GelPanelSpecification {...panelSpecProps}/>}
      {isMicroscopy && <MicroscopyPanelSpecification {...panelSpecProps}/>}

      
    </div> 
  </>)
}
