const valLengthOpMap = ['set','add','set','remove'];
// see documentation of jsondiffpatch for details
// https://github.com/benjamine/jsondiffpatch/blob/HEAD/docs/deltas.md

const getStateChangeOperations = (pathUntilThisNode, thisNode) => {

  let descendentPaths = Object.entries(thisNode).map(([key,val]) => {
    let pathWithChild = [...pathUntilThisNode,key]
    if( !Array.isArray(val) && (val._t !== 'a') ){
      return getStateChangeOperations(pathWithChild,val).flat();
    }else{

      let valLength = val.length || 0;
      let op = valLengthOpMap[valLength];
      return {path:pathWithChild, op};
    }
  })

  return descendentPaths;

}

export default function convert(diff){

  let ops = getStateChangeOperations([],diff).flat();
  return ops;


  

}
