import { useContext } from 'react';
import { PricingConfigContext } from './Contexts';
import SubscriptionPrice from './SubscriptionPrice';
const SELECTION_BORDER = 'rgba(0,129,255)'

let priceFontStyle ={
  fontSize:'2vw',
  fontWeight:'bold',
}
let commentStyle = {
  fontSize:12,
  color:'grey'
}

export default function SubsciptionPlanOptionButton({

  discountData, 
  selectedPlurality,
  selectedPaymentCycle,

  optionPlurality,
  optionPaymentCycle,
  //onClick,
  price,
  //priceComment,

  setPaymentCycle,
  setPlurality,

}){


  let { institutionType } = useContext(PricingConfigContext);


  

  let priceComment;



  if( optionPaymentCycle === "30day" ){
    priceComment = (
      <div style={commentStyle}>
        / 30 days
      </div>
    )
  }else{
    priceComment = (
      <div style={commentStyle}>
        / month (paid yearly)
      </div>

    )
  }

  let DISCOUNT = discountData?.length > 0;
  let discountPrice = 0;
  if( DISCOUNT ){

    let discountObject = discountData[0];

    let discountFraction = 1-(discountObject.percent_off/100)

    discountPrice = DISCOUNT && (Number(discountFraction * price).toFixed(2));

    let thisPlan = optionPaymentCycle+'-'+institutionType+'-'+optionPlurality;

    if( !discountObject.plans.includes(thisPlan) ){
      discountPrice = 0;
    }
  }



  let priceComponent = <SubscriptionPrice {...{
    discount:discountPrice,
    comment:priceComment,
    price
  }}/>

  

  let paymentCycleHtmlTag = optionPaymentCycle === "30day" ? "_30day" : "annual";



  let buttonSelected = (selectedPaymentCycle === optionPaymentCycle && selectedPlurality === optionPlurality);

  let border = buttonSelected ? ('3px solid '+SELECTION_BORDER) : '1px solid #ccc';

  let padding = buttonSelected ? 0 : 3;
  let background = buttonSelected ? "#c4d8f2" : "white";


  return (

    <div


      plurality={optionPlurality}

      paymentCycle={paymentCycleHtmlTag}
      onClick={() => {
        setPaymentCycle(optionPaymentCycle)
        setPlurality(optionPlurality)
      }}
      class="clickable hoverable small-margin flex-expand"
      style={{
        ...priceFontStyle,
        border,
        borderRadius:10,
        background,


        //background:'pink',



        padding,
        marginLeft:8,
        marginRight:8,
        width:100,

      }}>
      {priceComponent}
    </div>

  )
}
