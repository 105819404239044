import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import Tooltip from '@material-ui/core/Tooltip';
import { toggleStyleProperties } from './toolbarOrder';
import debounce from './debounce';
import ColorPicker from './ColorPicker';

export default function ColorToolbarItem({
  item,
  className,
  selectedFigureItems,
  unifiedGroup,
  figurePanelId
}){


  const dispatch = useDispatch();

  let styleProperty = item.styleProperty;
  let valueToSet = item.valueToSet;
  let currentValue = unifiedGroup.style[ styleProperty ];

  let selected = valueToSet === currentValue ? "selected" : "";

  if(item.valueToSet === 'bold'){
  }

  

  const debouncedChange = debounce((value,selectedFigureItems) => {

    let args = {
      figureItems:selectedFigureItems,
      figurePanelId,
      style:{
        [item.styleProperty]:value
      }
    }
    debugger;
    dispatch(A.setCellsValue(args))

  },100)


  return (
    <div 
      style={{
        borderRadius:'5px',
        position:'relative',
        width:240,
      }}
      class={selected+" toolbar-button"}
    >
      <ColorPicker {...{color:currentValue,
        onChange:(color) => {

          let args = {
            figureItems:selectedFigureItems,
            figurePanelId,
            style:{
              [item.styleProperty]:color
            }
          }

          dispatch(A.setCellsValue(args))
        }
      }}/>
      

    </div>
  )


}
