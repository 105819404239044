import FigureImageExpansionLayoutValue from './FigureImageExpansionLayoutValue';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import FigureImageExpansionTemplateNode from './FigureImageExpansionTemplateNode';
import { ExpansionTemplateInfo } from './Contexts';
import { useContext } from 'react';

export default function SubregionWindow({
  onItemClick,

  evaluate,
  nodeId,
  node,
  nodes,
  regions,
  parentBox,
  thumbnail,
  style,
  selected,



}){



  let {computedDimensions} = useContext(ExpansionTemplateInfo);

  //console.log(JSON.stringify(computedDimensions));
 
 
  let thisDimensions = computedDimensions[nodeId];

  
  //console.log({nodeId,thisDimensions,computedDimensions});

  let maxDimScale = 0.4;



  return (
      <div nodeId={nodeId} onClick={(e) => {


        //alert("Item clicked!");
        //e.stopPropagation();
        onItemClick && onItemClick(nodeId);

      }} style={{
        //border:'1px solid red',
        //background:'yellow',



        position:'relative',
        //...style,
        margin:style.margin,
        
        //minHeight:'40%',
        //minWidth:'40%',
        //maxWidth:'50%',
        //maxHeight:'50%',
        pointerEvents:'auto',
        //background:'white',

      }}>
        {evaluate && (
          <FigureImageExpansionLayoutValue {...{
            nodeId,
            node,
            nodes,
            value:node.value,
            regions,
            parentPixelDimensions:{
              w:parentBox.width * thisDimensions.w * 0.4,


              // this 0.5 here 
              // is to just test 
              // what happens when 
              // we take the exact required 
              // dimensions
              // for some reason, there's an
              // off-by-1 issue
              // that's causing space
              // where there should not be


              h:parentBox.width * 0.4 * thisDimensions.height
            }}}/>
        )}
        {false && <div style={{color:'magenta', position:'relative',top:0,left:0}}>{
        JSON.stringify(thisDimensions)
        }</div>}
        {!evaluate && (
          <>
            {thumbnail && (<div style={{
              fontSize:'1vw',
              position:'absolute',
              top:'50%',
              left:'50%',
              transform:'translate(-45%,-55%)'

            }} class="sg-row h-center v-center">

                <ImageSearchIcon style={{
                  fontSize:'1.5vw',
                  top:0,
                  left:0,
                }}/>
              {false &&<>
              Crop
              <br/>
              {nodeId.split('-')[1]}
              </>}
            </div>
            )}
          {!thumbnail && <FigureImageExpansionTemplateNode {...{
              nodeId,
              node,
              nodes,
              regions,
              value:node.value,

            }}/>}
          </>
        )}
      </div>
    )
}
