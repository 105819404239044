import A from './ActionCreators';


import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarOutline';

import G from './Getters';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';



function ClickToMakeFavourite({imageId,imageSet}){
  let dispatch = useDispatch()
  return (

    <div onClick={e => {

      e.stopPropagation();
      dispatch(A.setFigureImage({
        imageSetId:imageSet._id,
        imageId
      }))

    }}
      style={{ 
        color:'#ccc',
        fontSize:10,
      }}>
      <Tooltip title={"Set THIS as final figure image"}>
        <StarBorderIcon style={{ cursor:'pointer', color:'gold'}}/>
      </Tooltip>

    </div>
  )
}

function FilledFavourite(){
  return (

    <div style={{ 
        color:'#ccc',
        fontSize:10,
        pointerEvents:'none'
      }}>
        <Tooltip title={"Final figure image"}>
          <StarIcon style={{cursor:'pointer',color:'gold'}}/>
        </Tooltip>

      </div>
  )
}



export default function SetFigureImageButton({
  imageId,
  imageSet,
  treePath
}){


  let isFavourite = imageId === imageSet.figureImageId;

  let innerContent;
  if( !isFavourite ){
    innerContent = <ClickToMakeFavourite {...{
      imageId,
      imageSet
    }}/>
  }else{
    innerContent = <FilledFavourite/>
  }

  return (
    <div 
      treePath={treePath?.join('-')}
      action={"setFigureImage"} 
      imageId={imageId} 
      isFigureImage={""+isFavourite}>
      {innerContent}
    </div>
  )

}
