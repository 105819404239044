import initStore from './initStore';
export default function initSession( args ){

  let { 
    initialState,
    remoteDevTools,
    websocketTarget,
    windowSessionId,
    agentId,
    personId
  } = args;


  let store = initStore({ initialState, remoteDevTools, windowSessionId, agentId, personId })

  let session = { store }

  return session;
}
