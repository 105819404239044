import { useState, useReducer } from 'react';
import usePresentSelector from './usePresentSelector';
import Plot from './Plot';

import Modal from 'react-bootstrap/Modal';
import { produce } from 'immer';
import { smooth,argmax,argmin,diff } from './utils';

const initialState = n => Array(n).fill(0).map(_ => ([]));

function reducer(state,action){

  let { plotIndex } = action;
  switch(action.type){

    case 'add':{
      return produce(state,draft => {
        draft[plotIndex].push(action.peakBounds);
      })
    }
    case 'delete':{
      return produce(state,draft => {
        draft[plotIndex].splice(action.peakIndex,1);
      })
    }
    default:{
      throw Error("Illegal action type '"+action.type+"'"); 
    }
  }

}

export default function QuantificationModal({quantifications,open,onClose}){

  let [plotIndex,setPlotIndex] = useState(0);

  //let {lanePixelValues,lanePeakBoundaries} = quantifications;



  const [state,dispatch] = useReducer(reducer,
    quantifications.map(x => x.integrationRanges)
  )
    //initialState(lanePixelValues.length)); 


  let plotValues = quantifications[plotIndex].plotValues;

  console.log(plotValues);


  let containerWidth = plotValues.length*6;

  let onNavigation = (action) => {
    let increments = {
      next:1,
      prev:-1
    }
    let toIncr = increments[action];
    let nextIndex = (quantifications.length + plotIndex + toIncr) % quantifications.length;
    setPlotIndex(nextIndex);
  }

  return (

    <Modal size="lg" show={true} onHide={onClose}>

      <Modal.Header style={{background:'#fff'}}>
        <div class="sg-row">
          <div> Quantification Modal</div>
          <div class="flex-expand"/>
          <button style={{marginLeft:900}}>Done</button>
        </div>

      </Modal.Header>

      <Modal.Body style={{background:'#f8f8f8'}}>

        <div style={{alignItems:'center',position:'relative',
        }} class="sg-row">
          <div class="flex-expand"/>

          <div class="fair-margin">
            <button onClick={() => onNavigation("prev")}>Back</button>
          </div>

          {false && <div style={{width:containerWidth,fontSize:8}}>

            <Nav {...{currentIndex:plotIndex,end:quantifications.length,
            }} />

            <Plot {...{
              dispatch,
              plotIndex,
              peaks:state[plotIndex],
              windowScale:1,
              values:plotValues
            }}/>

            <div style={{textAlign:'center', fontSize:16}}>{(plotIndex+1)+" of " +quantifications.length}</div>

          </div>}

          <div style={{width:containerWidth,fontSize:8}}>

            <Nav {...{currentIndex:plotIndex,end:quantifications.length}} />

            <Plot {...{
              points:plotValues.map(x => x * plotValues),
              windowScale:6,
              dispatch,
              plotIndex,
              peaks:state[plotIndex],
              values:plotValues
            }}/>


            <div style={{textAlign:'center', fontSize:16}}>{(plotIndex+1)+" of " +quantifications.length}</div>

            <div><button onClick={() => {
              let el = document.querySelector("#copy-text")
              el.focus();
              el.select();
              document.execCommand('copy');
            }}>Copy values</button></div>

          </div>



          <div style={{margin:20}}>
            <div class="flex-expand"/>
            <button onClick={()=>onNavigation("next")}>Next</button>
          </div>

          <div class="flex-expand"/>
        </div>       

        <div><textarea id="copy-text" value={plotValues.join('\t')}/></div>



      </Modal.Body>

    </Modal>

  )
}

function Nav({currentIndex, onNavigation, end}){
  return (
    <div style={{width:'100%'}} class="sg-row">

      <div class="flex-expand"/>


      <div class="flex-expand"/>


    </div>
  )
}
