import C from './Constants';
const types = [
  C.injectRecordsFromServer,
  //C.processAuthSuccess
]

let indexOfUndefined = types.indexOf(undefined);
if( indexOfUndefined !== -1 ){
  throw Error("Found undefined constant in 'ActionTypesInvokedByServerResponse' at index: " + indexOfUndefined);
}

export default types;
