import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import G from './Getters';
import usePresentSelector from './usePresentSelector';
export default function DropCell({rowIndex, colIndex, length, structure, cellDragEntered, setCellDragEntered,
  figurePanelId,

  //onDrop,


  cropIdsBeingDragged,
  setCropIdsBeingDragged
}){

  if(!figurePanelId){
    throw Error("DropCell requires prop `figurePanelId`");
  }

  

  let dispatch = useDispatch();

  let numRows = usePresentSelector(state => G.getFigurePanel(state,{figurePanelId}).grid.length);


  if( length < 0 ){
    return null;
  }


  const onDrop = () => {

    if( cropIdsBeingDragged.length === 0 ){
      return;
    }

    let targetRowIndex = Math.ceil(rowIndex);
    let rowValues = Object.fromEntries(cropIdsBeingDragged.map((xx,ii) => ([ii+targetRowIndex,{columnIndices:[colIndex],groupValues:[{value:xx}]}])));

    let insertRowsArgs = {
          figurePanelId,
          addExtraRowAbove:(numRows===0),
          rowIndex:Math.ceil(rowIndex),
          rowValues,
          structure:structure,
          //columnIndices:[colIndex],
          //groupValues:[{value:cropIdsBeingDragged[0]}]
        }

    console.log(JSON.stringify({insertRowsArgs}));

    let action = A.insertRows(insertRowsArgs);
    dispatch(action);
    setCropIdsBeingDragged([]);

  }

  let [enteredRow, enteredCol] = cellDragEntered;

  let isDragEntered = rowIndex === enteredRow && enteredCol === colIndex;

  return <td
    onMouseEnter={() => { setCellDragEntered([rowIndex,colIndex]) }}
    
    onClick={onDrop}
    onMouseUp={onDrop}
    
    class={isDragEntered ? "selected" : ""}
    colSpan={length}>

    <div style={{
      padding:3,
      border: '2px dashed #ddd',
      textAlign:'center',
      fontWeight:'bold',
      color:'#dfdfdf',
    }}>
    +
    </div>
    </td>

}
