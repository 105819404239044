import { HORIZONTAL_CROP_RESIZER_Z_INDEX } from './UIConstants';
import { useState, useEffect } from 'react';
import UndoIcon from '@material-ui/icons/Undo';

export default function HorizontalCropAdjuster({side,onStart,height, cropResizeInfo, cellLocation}){

	

	
	let [ showRotate, setShowRotate ] = useState(false);


	const onKeyDown = e => {
		if( !cropResizeInfo ){
		if( e.key === 'Shift' ){
			setShowRotate(true);
		}
		}
	}

	const onKeyUp = e => {
		if( !cropResizeInfo ){
			if( e.key === 'Shift' ){
				setShowRotate(false);
			}
		}
	}


	useEffect(() => {
		
		if( !cropResizeInfo && showRotate ){
			//this is to make sure that 
			//if we aren't doing anything
			//it by default shows the <-->
			//
			//Although eventually, 
			//we might just combine the two
			//crop adjustments into one...
			//It kind of makes sense
			//that way doesn't it.
			setShowRotate(false);
		}

		window.addEventListener('keydown',onKeyDown);
		window.addEventListener('keyup',onKeyUp);

		return () => {
			window.removeEventListener('keydown',onKeyDown);
			window.removeEventListener('keyup',onKeyUp);
		}

	},[cropResizeInfo])



	let sideToSide = "◂▸";
	let rotating = "R";


	let rotate = (
		<div style={{position:'relative',
			top:-5

			}}>
			<UndoIcon style={{
				position:'absolute',
				top:0,
				left:(side === 'left' ? -2 : 0),
				transform:`rotate(90deg) ${side === 'left'?'scaleY(-1)' : ''}`,
				//border:'1px solid black',
				padding:0,
				margin:0,
				fontSize:10,

				//display:'none',
			}}/>

			{false && <UndoIcon style={{
				

				position:'absolute',
				top:0,
				left:0,

				transform:'rotate(90deg) scaleX(-1)',
				//border:'1px solid black',
				padding:0,
				margin:0,
				fontSize:11,
			}}/>}

		</div>
	)

	let icon = showRotate ? rotate : sideToSide;

	let oppositeSide = ({left:'Right',right:'Left'})[side];

	

	let radiusStyleProps = {
		[("borderTop"+oppositeSide+"Radius")]:height,
		[("borderBottom"+oppositeSide+"Radius")]:height
	};


	return (
		<div 

			cellLocation={cellLocation.join('-')}
			side={side}
			action={"adjust-crop"}
			class="opaque-on-hover"
			onMouseDown={e => {
				e.stopPropagation();
				onStart(e);
			}}
			onClick={e => e.stopPropagation()}

			style={{
				cursor:'relative',
				position:'absolute',
				display:'flex',
				alignItems:'center',
				justifyContent:'center',
				...radiusStyleProps,
				[side]:0,
				top:'50%',
				width:height/2,
				height,
				background:'orange',
				opacity:0.7,
				transform:'translate(0,-50%)',
				zIndex:HORIZONTAL_CROP_RESIZER_Z_INDEX,
			}}>
			<div style={{
				position:'absolute',
				fontSize:9,
				//height:3,
				width:'100%',
				color:'black',
				//transform:'translate(0,-50%)',
				//border:'1px solid black'
			}}>
				{icon}
				
			</div>
		</div>

	)
}
