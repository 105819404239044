
const Letters = "°º±–— `1234567890-=qwertyuiop[]\\asdfghjkl;'zxcvbnm,./~!@#$%^&*()_+QWERTYUIOP{}|ASDFGHJKL:ZXCVBNM<>?ςερτυθιοπασδφγηξκλζχψωβνμΕΡΤΥΘΙΟΠΑΣΔΦΓΗΞΚΛΖΧΨΩΒΝΜœ∑´®†¨ˆøπ“‘«»”’ÅÍÎÏ˝ÓÔÒÚÆåß∂ƒ©˙∆˚¬æΩ≈ç√∫˜µ≤≥÷¿˘¯Â˜ı◊Ç˛¸¡™£¢∞§¶•ª‚‡ﬂﬁ›‹€⁄ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ";

const LetterSet = new Set(Array.from(Letters));

export default Letters;

export function isMeasuredString(string){
  return Array.from(string).every(char => LetterSet.has(char));
}
