import usePresentSelector from './usePresentSelector';
import ExportButton from './ExportButton';
import SciugoTextArea from './SciugoTextArea';
import { useDispatch } from 'react-redux';
import { useRef, useState, useEffect, useCallback } from 'react';
import G from './Getters';
import A from './ActionCreators';
import Tooltip from '@material-ui/core/Tooltip';
import { isMeasuredString } from './Letters';
import Dialogs from './DialogConstants';

import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';


//import * as htmlToImage from 'html-to-image';
//import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

export default function ValueDisplay({
  tableOutlineShowing,setTableOutlineShowing,
  setLaneWidths,laneWidths,selectionJustMade,
  setSelectionJustMade,cellValueInputRef,
  temporaryCellValue,setTemporaryCellValue,
  setSvg,
  retainInputFocus,
  selectedCells,
  figurePanelId,

  focusedOnValueDisplay,
  setFocusedOnValueDisplay,

  handleArrowKey,

}){
  const dispatch = useDispatch();
  let unifiedGroup = usePresentSelector(state => G.getUnifiedGroupFromSelectedCells(state,{selectedCells,figurePanelId}));

  const isImageType = type => ['image','crop'].includes(type);


  let value = unifiedGroup.value;

  let selCellsPropString = JSON.stringify(selectedCells);



  let [lastSelectedCells,setLastSelectedCells] = useState(selCellsPropString);

  useEffect(() => {

    if( lastSelectedCells !== selCellsPropString ){
      setLastSelectedCells(selCellsPropString);
      setClicked(false);
    }

  })


  

  if( selectedCells.length === 0){
    value = "";
  }else if( temporaryCellValue !== null ){
    value = temporaryCellValue
  }
  else if( value instanceof Object && isImageType(value.valueType)){
    value = '📷';
  }else if( value instanceof Object && value.valueType === 'bandAnnotation' ){
    value = JSON.stringify(unifiedGroup.value);
  }

  const [ clicked, setClicked ] = useState(false);


  const setInputCursorValueRef = useRef(null);

  useEffect(() => {


    if( cellValueInputRef.current && setInputCursorValueRef.current !== null ){
      let caretPos = setInputCursorValueRef.current
      cellValueInputRef.current.setSelectionRange(caretPos, caretPos);
      setInputCursorValueRef.current = null;
    }

    if( unifiedGroup.value !== undefined && cellValueInputRef && cellValueInputRef.current && !retainInputFocus ){

      if( selectionJustMade && !clicked ){

        //console.log("DOING SOME SELECTION!");
        cellValueInputRef.current.select();
      }

    }
  })


  



  


  let inputStyle;
  let inputPlaceholder;

  if( !temporaryCellValue && value && value.multipleValues ){
    value="";
    inputPlaceholder = "Multiple values";
    inputStyle = { fontStyle:'italic' }
  }


  const computeRows = value => {
    if(value && !value.split){
      debugger;
    }else{
      return value?value.split('\n').length : 1
    }
  }

  




  return (
    <div style={{
     // background:'yellow'
      }} class="small-margin">
      <div class="sg-row">

        <div style={{
          display:'flex',
          marginRight:5,
          alignItems:'center'
        }}>Value:</div>
        

          <div style={{position:'relative',display:'flex',alignItems:'center'}}>

            <Tooltip disableFocusListener title={"Multiple lines: Alt + Enter"}>
          <SciugoTextArea 
            name={"cellValueInput"}
            consumeArrowKeys={String(clicked)}
            class="value-textarea"
            rows={computeRows(value)}
            textareaRef={cellValueInputRef}
            //ref={cellValueInputRef}
            value={value} 
            disabled={value===undefined||selectedCells.length === 0}

            onClick={() => {
              if( !focusedOnValueDisplay ){
                setFocusedOnValueDisplay(true);
              }
              setClicked(true)
            }}
            onBlur={(e) => {

              setClicked(false);
              setSelectionJustMade(false);
              setFocusedOnValueDisplay(false);

              if( temporaryCellValue!==null ){
                dispatch(A.setCellsValue({figurePanelId,cells:selectedCells,value:temporaryCellValue}));
                setTemporaryCellValue(null);
              }
            }}

            //onKeyUp={handleKeyDown(setClicked,handleArrowKey,value,setTemporaryCellValue)}
            onKeyDown={e => handleKeyDown(e,clicked,setClicked,handleArrowKey,value,setTemporaryCellValue,setInputCursorValueRef)}
            onChange={e=>{

              setSelectionJustMade(false);
              let string = e.target.value;



              if( value.valueType === 'image' ){
                if(string !== ''){
                  return;
                }
              }

              if( !isMeasuredString( 
                Array.from(string).filter(x=>x!=='\n') ) ){
                dispatch(A.createDialog({
                  source:'ValueDisplay',
                  dialogName:Dialogs.ILLEGAL_CHARACTER_IN_CELL,
                  args:{string}
                }))

              }else{ 
                setTemporaryCellValue(string);
              }
            }}
            placeholder={inputPlaceholder}
            style={{
              //background:(clicked?'pink':'white'),
              //border:selectionJustMade?'3px solid red':'auto',
              ...inputStyle,
              caretColor:((clicked||focusedOnValueDisplay)?'auto':'transparent')
            }}
          />
            </Tooltip>

        </div>
        <div class="flex-expand"/>


        <ExportButton {...{ 
          button:"top-export-button",
          key:figurePanelId, 
          setSvg, 
          figurePanelId 
          }}/>

        <div class="flex-expand"/>

          <Tooltip title={(tableOutlineShowing?"Hide":"Show") + " Gridlines"}>
                <div class="outline-toggler">
                  <div
                    onClick={() => setTableOutlineShowing(!tableOutlineShowing)}
                    class="outline-toggler tiny-padding hoverable clickable-text toolbar-button">
                    {tableOutlineShowing && <GridOffIcon/>}
                    {!tableOutlineShowing && <GridOnIcon/>}
                  </div>
                </div>
              </Tooltip>

        <div class="flex-expand"/>

      </div>
    </div>
  )
}

let ArrowKeySet = new Set('ArrowUp','ArrowLeft','ArrowRight','ArrowDown');

function handleKeyDown(e,clicked,setClicked,handleArrowKey,curValue,setTemporaryCellValue,setInputCursorValueRef){

  //this is a comment;



  if( e.key === 'Enter' ){
    e.preventDefault();

    if( e.altKey ){


      let targetEl = e.target;
      let cursorStart = targetEl.selectionStart;
      let cursorEnd = targetEl.selectionEnd;


      let newValue = curValue.slice(0,cursorStart) + '\n' + curValue.slice(cursorEnd);

      setInputCursorValueRef.current = cursorStart + 1;
      e.preventDefault();

      setTemporaryCellValue(newValue);

    }else if( !clicked ){

      //clicked means we're focused with a carot and have arrow keys to edit
      if( e.shiftKey ){
        handleArrowKey('ArrowLeft',e);
      }else{
        handleArrowKey('ArrowRight',e);
      }
      setClicked(false);
    }
  }else if( e.key === 'Escape' || ArrowKeySet.has(e.key) ){
    //hitting this
    //setSelectionJustMade(false);
    setClicked(false);
  }


}
