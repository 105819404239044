export default function Barplot({values, labels, outerProps}){

  let maxVal = Math.round(Math.max(...values));

  return (

    <div 
      class="sg-row flex-expand"
      style={{
        width:100,
        height:100,
        //border:'2px solid red',
        //height:400,
        position:'relative'}}
      {...outerProps}
    >

      {values.map( (laneQuant,iiLane) => {

        let height = (Math.round(values[iiLane])/maxVal * 100)+'%'
        return (
          <div style={{width:(100/values.length)+'%', position:'relative'
          }}>
            <div style={{
              //paddingLeft:'5%',
              //paddingRight:'5%',
              height:'100%',
              width:'100%',
              //border:'1px solid #ccc',
              display:'flex', flexDirection:'column',position:'relative',
            }}>
              <div class="flex-expand"/>
              <div style={{
                background:'darkblue',
                width:'100%',
                height,
                position:'relative',
              }}>

                <div style={{
                  position:'absolute',
                  borderLeft:'0.5px solid white',
                  borderRight:'0.5px solid white',
                  height:'100%',
                  width:'100%'
                }}/>

              </div>
              {labels && <div style={{
                display:'flex',
                justifyContent:'center',
                fontSize:7,
                transformOrigin:'0% 50%',
                transform:'translate(50%,0) rotate(45deg)'

              }}>{labels[iiLane]}</div>}




            </div>
          </div>
        )
      })}
    </div>
  )
}
