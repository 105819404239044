import A from './ActionCreators';

import { isCLI } from './versionConfig'; 
import fetch from 'cross-fetch';
import injectTestSessionHeaders from './injectTestSessionHeaders';
import { getGlobalObject } from './versionConfig';


export const NO_INTERNET = -4444;

export function NoInternetConnectionResposne(){
  return new fetch.Response(
    null, {
      status:NO_INTERNET
    }
  )
}

export function isOnline(){
  if( isCLI() ){
    let netConfig = process.__testMeta.__networkConfig;
    return !netConfig || netConfig.connected;
  }else{
    return window.navigator.onLine;
  }

}

function undefinedsRemoved(map){
  let cleanMap = {};
  for(let key in map){
    if( map[key] ){
      cleanMap[key] = map[key];
    }
  }
  return cleanMap;
}

function getMockMap(){

  let theMap;
  if( isCLI() ){
    let testMeta = process.__testMeta;
    if( testMeta ){
      theMap = testMeta.__sciugoMocks;
    }
  }else{ 

    let testMeta = window.__testMeta;
    if( testMeta ){
      theMap = testMeta.__sciugoMocks;
    }
  }
  if( theMap ){ 
    return undefinedsRemoved(theMap);
  }
}

function throwErrorIfInCliAndNotConnected(){
  if( process.env.NODE_ENV !== 'production' ){
    if( isCLI() && !isOnline() ){
      throw Error("Not connected to internet.");
    }
  }
}

function getMockResponse(route,args){

  throwErrorIfInCliAndNotConnected();

  let mockMap = getMockMap();
  if( mockMap ){


    let patterns = Object.keys(mockMap);
    let matchedPatterns = patterns.filter(pat => route.match(pat));


    if( matchedPatterns.length === 0 ){
      return;
    }else if( matchedPatterns.length > 1 ){
      throw Error("Route matched multiple mocked patterns (route = '"+route+"', patterns = "+JSON.stringify(matchedPatterns));
    }
    


    let mockedValue = mockMap[
      matchedPatterns[0]
    ];


    let mockedResponse = createMockReponse(mockedValue);

    return mockedResponse;
  }

}

function createMockReponse( mock ){


  let { body, options } = mock; 

  Object.entries({body,options}).forEach(([key,val]) => {
    if( val === undefined ){
      throw Error(key+" is undefined in mock response.");
    }
  })


  let { delay } = options;


  return new Promise((res,rej) => {
    setTimeout(() => {
      res(
        new fetch.Response(
          body, options
        )
      )
    },(delay||0))
  })



}



export default async function SciugoFetch(route,args,extraArgs){



  if( process.env.NODE_ENV !== 'production' ){
    let mock;
    try{
      mock = await getMockResponse(route,args);
    }catch(e){
      throw(e);
    }


    if( mock ){
      return mock;
    }
  }

  extraArgs = extraArgs || {};
  extraArgs.route = route;

  let resolvedArgs = injectTestSessionHeaders(args,extraArgs);

  let timeoutId;
  if( resolvedArgs.timeoutConfig ){

    let { 
      timeout, 
      onTimeout 
    } = resolvedArgs.timeoutConfig;

    const controller = new AbortController();
    resolvedArgs.signal = controller.signal;

    timeoutId = setTimeout(() => {
      controller.abort();
      onTimeout();
    }, timeout)

  }

  if( process.env.NODE_ENV !== 'production' ){
    extraArgs.dispatch(A.showFetch({route,resolvedArgs}))
  }


  resolvedArgs.mode = 'cors';
  //hesoledArgs.headers['Access-Control-Allow-Origin']


  if( process.env.NODE_ENV !== 'production' ){

        let testMeta = getGlobalObject().__testMeta;
        let currentSession;
        if( testMeta ){
          currentSession = testMeta.getCurrentSession && testMeta.getCurrentSession();
        }



        if( (currentSession && currentSession.__forceFetchFailure) || getGlobalObject().__forceFetchFailure ){
          //console.error("Fetch failure forced.");
          throw new Error("Fetch failure forced.");
        }
      }



  return fetch(route,resolvedArgs).then(
    res => {

      if( process.env.NODE_ENV !== 'production' ){

        let testMeta = getGlobalObject().__testMeta;
        let currentSession;
        if( testMeta ){
          currentSession = testMeta.getCurrentSession && testMeta.getCurrentSession();
        }



        if( (currentSession && currentSession.__forceFetchFailure) || getGlobalObject().__forceFetchFailure ){
          //console.error("Fetch failure forced.");
          throw new Error("Fetch failure forced.");
        }
      }


      /*if( !route.includes('getPresignedFetchUrl') ){
      //debugger;
        }
        */
      if( timeoutId ){
        clearTimeout(timeoutId);
      }
      return res;
    }
  )/*.catch(error => {
    console.error({
      error,
      route,
      resolvedArgs
    })

  })*/
    
}
