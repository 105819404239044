//import G from './Getters';
import { useState } from 'react';
import MediaPersistanceActionButton from './MediaPersistanceActionButton';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function UploadItemInfo({info}){

  let {_id,filename,message,canRestart} = info;
  //need filename, error message, loading status

  let pending = message === "pending";

  return (
    <div style={{
      borderBottom:'1px solid #ccc', 
      width:'100%',
      overflow:'wrap',
      textOverflow:'wrap',
      //border:'2px solid purple',
      minWidth:0,
      position:'relative',
      paddingRight:10,
      //display:'flex',
      //flexDirection:'row-reverse',

    }}
      class="sg-row">

      <div style={{
        fontSize:10,
        padding:5,
        //width:'100%',
        //border:'3px solid red',
        minWidth:0,

        //background:'yellow'
      }} class="flex-expand">

        <div style={{
          //border:'2px solid blue',
          textWrap:'wrap',
          overflowWrap:'break-word',
          fontSize:12,
          pendingUploadItem:"filename",

        }}>
          {info.image?.filename || "MISSING FILENAME"}
          {false && <div>
            {JSON.stringify(info)}
          </div>}
        </div>
        
        {pending && <div style={{height:10, justifyContent:'center'}}>
          <LinearProgress style={{top:'50%',transform:'translate(0,-50%)'}} />
        </div>}
        {!pending && <div text={"uploadErrorMessage"} 
          style={{color:'red',fontSize:10,
          textWrap:'wrap',
          overflowWrap:'break-word',
        }}>
          {message}
        </div>}
      </div>

    {message !== "pending" &&
      <div style={{display:'flex',alignItems:'center',justifyContent:'center', 
        width:30,
        minWidth:30,
        //marginRight:10,
        //background:'red',
      }}>

        
         <MediaPersistanceActionButton {...{action:(canRestart?"restart":"discard"),_id}}/>
      </div>}

    </div>


  )
}

