import App from './App';
import { useState } from './sciugo-hooks';
import { useEffect } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';
import G from './Getters';
import TestFallback from './TestFallback';
//import ErrorBoundary from './ErrorBoundary';
import { ErrorBoundary } from 'react-error-boundary';
import ComponentWrapper from './ComponentWrapper';

import { FIGURE_BUILDER, WELCOME, ANNOTATION, FIGURES_AND_PANELS, QUANTIFICATION, FIGURE} from './UIConstants';


export default function AppWrapper(){

  const [appRenderKey,setAppRenderKey] = useState(Math.random())


  const dispatch = useDispatch();
  const setMode = (mode) => {
    dispatch(A.setUiMode({mode}));
  }

  const {mode,loginStatus} = useSelector(state => ({
    loginStatus:state.loginInfo.status,
    mode:state.ui.mode
  }));

  //const isLoggedIn = useSelector(G.isLoggedIn);
  

  let errorBoundary = app => (
    <ErrorBoundary 
      fallbackRender={({error,resetErrorBoundary,componentStack}) => {
        return (
          <TestFallback 
            resetErrorBoundary={resetErrorBoundary}
        resetAppKey={()=>{
            setMode(ANNOTATION);
            setAppRenderKey(Math.random())
            
          }}/>
        )
      }}>
      {app}
      
    </ErrorBoundary>
  )

  let app = <App {...{mode,setMode}} key={appRenderKey}/>;

  return errorBoundary(app);
    


}
