import ScalebarTextPositionSetter from './ScalebarTextPositionSetter';

export default function ScalebarTextPlacementStructure({onEvent,textPosition,data}){

  const previewHeight = 60;
  const topBottomHeight = '60%';
  const color = "red";
  let { whiskers, thickness } = data;


  let left = (
      <div class="scalebar-text-container">
        <ScalebarTextPositionSetter {...{
          onEvent, position:"left", height:'30%', textPosition
        }}/>
      </div>
  )

  let middle = (

    <div class="sg-col position-relative">

      <div class="sg-row h-center full-width v-center position-relative flex-expand">

        <ScalebarTextPositionSetter {...{
          position:"top",
          onEvent,
          height:topBottomHeight,
          textPosition
        }}/>

      </div>


      <div class="sg-row scalebar-preview full-width" style={{ 
        background:"red", 
        height:thickness,
        pointerEvents:'none',
      }}>

        <div class="full-width" style={{
          height:whiskers*2,
          borderLeft:`${thickness}px solid ${color}`,
          borderRight:`${thickness}px solid ${color}`,
        }}/>

        <div style={{alignItems:'center', justifyContent:'center', 
        }} class="absolute-cover sg-row">
          <div class="full-width" style={{
            height:whiskers*2, 
            borderLeft:'3px solid red',
            borderRight:'3px solid red',
          }}/>
        </div>
      </div>

      <div style={{
      }} class="sg-row h-center full-width v-center position-relative flex-expand">
        <ScalebarTextPositionSetter {...{
          onEvent,
          position:"bottom",
          height:topBottomHeight,
          textPosition
        }}/>
      </div>
    </div>
  )

  let right = (

      <div class="scalebar-text-container">
        <ScalebarTextPositionSetter {...{
          onEvent,
          position:"right", 
          height:'30%',
          textPosition
        }}/>
      </div>

  )

  let structurePreview = (
    <div style={{height:previewHeight,border:'1px solid black'}} class="scalebar-structure-container sg-row full-width">
      {left}
      {middle}
      {right}
    </div>
  )

  return structurePreview;

}
