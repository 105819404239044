import { useDispatch, useSelector } from 'react-redux';
import G from './Getters';
import A from './ActionCreators';
import C from './Constants';
export default function SaveButton(){

  let dispatch = useDispatch();

  let saveStatus = useSelector(state => G.getSaveStatus(state))
  const buttonInfo = {
    unsaved:{ text:"Save changes", type:"warning", },
    saving:{ text:"Saving...", type:"warning" },
    saved:{ text:"Changes saved", type:"light" }
  }

  const disabled = saveStatus !== C.UNSAVED ? "disabled" : "";

  let { text, type } = buttonInfo[saveStatus];

  return (
    <div class={ `btn btn-${type} btn-sm ` + disabled } 
      style={{ fontWeight:'bold', marginRight:10 }}
      onClick={() => {
      if( saveStatus === C.UNSAVED ){
        dispatch(A.syncChanges())
      }
    }}
      info="save-status" savestatus={saveStatus}>
      {text}
    </div>

  )
}
