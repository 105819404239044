import ExpansionTextItem from './ExpansionTextItem';
import { useSelector } from 'react-redux';
import G from './Getters';
import React, { useState } from 'react';
import SelectedCover from './SelectedCover';

import useRenderedBoundingBox from './useRenderedBoundingBox';





export default function Scalebar({
  ref,
  lineStyle, 
  style, 
  position, 
  nodeId, 
  node, 
  nodes, 
  onEvent, 
  regions, 
  selected,
  parentBox,
}){

  let [ showModal, setShowModal ] = useState();

  let [ bounds, boundsListener ] = useRenderedBoundingBox();

  
  const onSomeClick = e => {
    e.preventDefault();
    e.stopPropagation();

    debugger;

    onEvent({
      type:e.type,
      itemType:"expansionNode",
      node,
      nodeId,
    })
  }

  let textStyle = {...style};

  let borderProps = ["borderTop","borderBottom","borderLeft","borderRight"];

  let borderStyles = {
    border:'2px solid blue',
  };



  borderProps.forEach(prop => {
    let borderStyleInfo = textStyle[prop];

    let { borderThickness=1, borderColor="black" } = textStyle || {};
    if( borderStyleInfo ){
      borderStyles[prop] = 
        `${borderThickness}px solid ${borderColor}`;
    }
  })


  let { textPosition, thickness } = node;
  let left = textPosition === "left";
  let right = textPosition === "right";
  let top = textPosition === "top";
  let bottom = textPosition === "bottom";

  let textComp = <div style={{
    marginLeft:5,
    marginRight:5,
    textWrap:'none',
    whiteSpace:'nowrap',
  }}>{node?.computed?.label || "ERROR" }</div>;


  let width = node.computed.widthAsRegionProportion * parentBox.width;

  let structure = (
    <div style={{color:node.style.color, position:'relative',

      //border:'3px solid transparent',
      }} class="sg-row v-center">
      {left && textComp} 
      <div style={{ }}
        class="sg-col h-center full-width">
        {top && textComp}
        {width && <div class="sg-row full-width h-center"><div style={{
          width, 
          height:thickness, 
          background:node.style.color
        }}/>
          

          {SPACE_FOR_EAISER_HOVER}

        </div>
        }
        {bottom && textComp}
      </div>
      {right && textComp}
    </div>
  )

  

  return (
    <>
    <div 

      ref={boundsListener}

      nodeId={nodeId}
      onContextMenu={onSomeClick}
      onClick={onSomeClick}
      item={"scalebar-label"}

      style={{
        pointerEvents:'auto',
        //minWidth:40,//node?.computed?.width|| (40 + '%'),
        //border:'2px solid blue',
        //color:'blue',
        fontWeight:'bold',
        ...textStyle,
        position:'relative',
        //zIndex:10000,

      }}>
      {false && <div style={{color:'white'}}>{JSON.stringify(node?.computed)}</div>}

      {structure}

      {selected && <SelectedCover nodeId={nodeId}/>}


      

      
    </div>

    </>
  )
}

const SPACE_FOR_EAISER_HOVER = (<div style={{position:'absolute',
  top:-3,
  height:'calc(100% + 6px)',
  width:'100%',
}}/>)

