import usePresentSelector from './usePresentSelector';

import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import G from './Getters';
import A from './ActionCreators';
import EditableContent from './EditableContent';
import { ANNOTATIONS } from './RecordTypes';

import getLabelProperty from './getLabelProperty';

import Spinner from 'react-bootstrap/Spinner'

import { CropAdjustmentContext } from './Contexts';

const randomColor = () => '#' + Math.floor(Math.random()*16777215).toString(16); 

const anchorSideMap = {
  'right':'left',
  'left':'right'
}

//we keep BandAnnotation re-rendering during
//and image resize so that the user can see
//if there are more marks coming in their resize

export default function BandAnnotation({ 
  figurePanelId, 
  imageSetId, 
  bandsEditable, 
  imgRefHeight, width, cell, value, 
  style,
  verticalImageResizeTranslation,
  editable
}){

  let cropAdjustmentContext = useContext(CropAdjustmentContext);
  let { cropResizeInfo } = cropAdjustmentContext || {};
  let tempHeight = cropResizeInfo?.height;

  if( imageSetId !== undefined ){
    throw Error("imageSetId is an illegal BandAnnotation prop.");
  }

  let { sideRelativeToImage, annotationId } = value;

  let directionOfCrop = ({right:-1,left:1})[sideRelativeToImage];
  let [thisRow,thisCol] = cell;
  let cropCell = [ thisRow, thisCol + directionOfCrop ];

  let dispatch = useDispatch();


  let labelLayout = usePresentSelector(state => G.getLabelLayout(state,{figurePanelId}));

  let labelType = labelLayout[sideRelativeToImage][0];


  let labels = usePresentSelector(state => G.getMarkLabels(state,{annotationId,labelType}));



  let { globalPanelStyle, cropCellWidth, inlineCrop, cropIsArchived } = usePresentSelector(state => {
    let globalPanelStyle = G.getFigurePanelGlobalStyle(state,{figurePanelId})
    let cropCellWidth = G.getCellWidth(state,{figurePanelId,cell:cropCell});
    let cropIsArchived = G.isArchived(state,{_id:annotationId,type:ANNOTATIONS});
    let inlineCrop = G.getCropFromAnnotation(state,{annotationId}) || {};
    return { globalPanelStyle, cropCellWidth, cropIsArchived, inlineCrop }
  });


  let currentWidthScaledHeight = tempHeight || inlineCrop.height;
  
  const height = (cropCellWidth/inlineCrop.width)*currentWidthScaledHeight;



  if(labels === undefined){
    debugger;
  }

  const avg = positions => {
    if( positions.length === 0 ){
      return 0;
    }
    return positions.reduce((a,b)=>a+b) / positions.length
  }



  let pointerSide = anchorSideMap[sideRelativeToImage];

  let markerWidth = getLabelProperty(style,globalPanelStyle||{},'pointerWidth');

  let fontSize = getLabelProperty(style,globalPanelStyle||{},'labelFontSize') 

  let spacing = getLabelProperty(style,globalPanelStyle||{},'textPointerSpacing');

  let labelConnector = getLabelProperty(style,globalPanelStyle||{},'labelConnector');


  let y = 0, untilRowIndex = 0;
  if( editable!==false && verticalImageResizeTranslation ){
    y = verticalImageResizeTranslation.y;
    untilRowIndex = verticalImageResizeTranslation.untilRowIndex;
  }

  //let shiftForImageResize = ''//untilRowIndex === cell[0] ? 'translate(0,'+-y+'px)' :''
  let transform = 'translate(0,-50%) '// + shiftForImageResize;


  if( height === 0 ){
  }


  let overflow = 'visible';//bandsEditable?'visible':'hidden';
  //overflow = 'hidden';

  if( cropIsArchived ){
    return (
      <div style={{height:'100%', width:'100%'}}>
      </div>
    )
  }

  if( untilRowIndex === cell[0] ){
    return null &&(
      <div style={{textAlign:'centre',height:'100%',width:'100%'}}>

      </div>
    )
  }



  return (
    
      <div

        id={'grid_bandAnnotation-'+cell.join('-')}
        onMouseDown={e => {
        }}
        onClick={e => {
        }}
        class="band-annotation-contents" style={{
          position:'absolute', 
          top:'50%',
          transform,
          overflow,
          //width:'100%', 
          width,
          height,
        

          //background:'purple'
        }}>

      
      {height!==undefined && (<div 
        
        style={{position:'relative',height:'100%'}}>

        { labels.map( (label,iiLabel) => {

          /*if( !bandsEditable ){
            /*(label.label === '' || label.label === undefined || label.label === null || !label.label) ){
            return null;
        }*/

          let labelPositions = label.positions

          let textPosition = avg(labelPositions);


          let min = Math.min(...labelPositions);
          let max = Math.max(...labelPositions);

          let containerDivStyle = {
            position:'relative',
            top:0,
            left:0,
            height:'100%',
            width:'100%',
            background:'yellow',
            zIndex:20
          }

          let idWithDescriptionFollowedByDashesToSeparateDescriptionAndEachCellLocationCoordinatesAsUsedByTheOnClickCellSelectionFramework = (['grid_bandAnnotation',...cell,'label',iiLabel].join('-'));


          
          return (
            <>


              {!bandsEditable && <div
                id={cell&&idWithDescriptionFollowedByDashesToSeparateDescriptionAndEachCellLocationCoordinatesAsUsedByTheOnClickCellSelectionFramework}
                style={{
                  position:'absolute',
                  top: 100*textPosition+'%',//*height),
                  fontSize:Number(fontSize)+'pt',
                  transform,
                  fontStyle:(!label.label?'italic':'unset'),
                  color:(!label.label?'#ccc':'unset'),
                  [pointerSide]:(spacing + markerWidth),
                  whiteSpace:'nowrap',
                  overflow:'hidden',
                }}
              >{label.label||"Unlabeled mark"}</div>}


              <div 
                onClick={e => {
                  if( bandsEditable ){
                    /*
                    e.stopPropagation();
                    let itemId = labelIds[ iiLabel ];
                    makeSelection({itemId,shiftDown:e.shiftKey})
                    */
                  }
                }}
                class={""/*classForTheDiv*/} 
                style={{
                  position:'absolute',
                  [pointerSide]:(markerWidth), 
                  top:100*textPosition+'%',//*height,
                  //background:'green',
                  //transform,
                  
                  cursor:'pointer',
                  //width:120,
                  height:(height*(max-min)+10),
                  //background:'pink',
                  minHeight:(1.5 * 16),
                  display:'flex',
                  flexDirection:'column',
                }}>

                
                


                {bandsEditable && <EditableContent 
                  editable={bandsEditable===true}
                  onChange={value => {
                    /*dispatch(A.setLabelProperty({
                      imageSetId,labelIds:[labelIds[iiLabel]],property:"value",
                      value
                    }))*/
                  }}
                  showBorder={bandsEditable===true}
                  style={{
                    textAlign:pointerSide,
                    fontSize:Number(fontSize)+'pt',
                    background:0,
                    position:'absolute',
                    top:'50%',
                    /*width:'60%',*/
                    //transform,
                    [pointerSide]:spacing,


                  }} 
                  content={label.value} 
                  showBorder={true} 
                  defaultValue={"Empty label"}
                />}
              </div>



              {labelPositions.map( (pos,iiPos) => {

                return (<>{sideRelativeToImage === 'right' && <div style={
                  {position:'absolute',
                    top:(height*pos),
                    background:'black',
                    height:1,
                    //transform:shiftForImageResize,
                    //transformOrigin:'0% 0%',
                    width:markerWidth, 
                    [pointerSide]:0, 
                    zIndex:10,
                    transform
                  }
                  }/>}

                  {sideRelativeToImage==='left' && <div style={
                    {position:'absolute',
                      top: (height*pos),
                      height:1,
                      zIndex:10,
                      background:'black',
                      width:markerWidth, 
                      [pointerSide]:0, 
                      transform
                    }}/>}

                </>
                )})}
            </>
          )}
        )
        }
      </div>)}
    </div>
  )


}
