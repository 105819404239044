import { useSelector } from 'react-redux';
import G from './Getters';
import { useRef, useState, useEffect } from 'react';
import ImageCroppingContainer from './ImageCroppingContainer';
import { AnnotationStyleContext } from './Contexts';

import ImageAnnotationModal from './ImageAnnotationModal';
import CroppableImage from './CroppableImage';
//import CroppableImageContainer from './CroppableImageContainer';

function LocalCroppableImageContainer({
  filename,
  imageSetId,
  imageId,
  crop,
  cropName,
}){

  const imgRef = useRef(null); 
  let imgContainerRef = useRef(null);
  const [w,sw] = useState(); 

  useEffect(() => {
    //alert(`Render imgReg: ${imgRef.current}, imgContainerRef: ${imgContainerRef.current}`)
    if( imgContainerRef.current ){
      let rect = imgContainerRef.current.getBoundingClientRect();
      let nw = rect.width;
      if( nw !== w ){
        sw(nw);
      }
    }

  },[imgContainerRef.current,imgRef.current])



  return (
    <div 
      ref={imgContainerRef}
      style={{
        width:'100%', 
        border:'3px solid black', 
        pointerEvents:'none',
        //width:500
        minHeight:260,
        //height:270,
        //maxHeight:400,
        //width:800,
        margin:20,
        position:'relative',
      }}>
      <div style={{position:'absolute',
        top:-35,
        fontWeight:'bold',
        fontSize:20,
      }}>
        {cropName}
      </div>
      {false && <div ref={imgContainerRef} style={{
        //fontWeight:'bold'
      }}>{filename}</div>}

      <AnnotationStyleContext.Provider value={{
        mwMarkers:{
          point:{ 
            r:2
          },
          label:{
            extrasSurroundingLabelForMovability:false
          }
        },
        cropBoxes:{
          rotationHandles:false
        }

      }}>

        <CroppableImage {...{imageSetId, imageId,crop, imgRef, imgContainerRef}}/>
      </AnnotationStyleContext.Provider>
    </div>
  )


}

export default function RawImagesFigureView({figurePanelId, width}){

  let state = useSelector(state => state);
  let [selectedImageId,setSelectedImageId] = useState(null);


  let data = G.getFigurePanel(state,{figurePanelId});

  let { cellGroups, grid } = data;

  let gridIds = new Set(grid.flat().flat())

  let filterFunction = group => group.value.valueType === 'crop' && gridIds.has(group._id) 
  let atnIds = Object.values(cellGroups).filter(filterFunction).map(x => x.value.annotationId);

  let crops = atnIds.map(annotationId => G.getCropFromAnnotation(state,annotationId));
  let imageIds = crops.map(crop => crop.imageId);

  let namesByImageIds = imageIds.map(_id => G.getAnnotationsByImageId(state,_id).filter(x => x.annotationType==='ls').map(x => x.label)[0])


  let imageAnnotationModal = selectedImageId && (
    <ImageAnnotationModal { ...{
        closeModal:() => setSelectedImageId(),selectedImageId
      }}/>
  )

  return (
    <>
      
        {imageIds.map((imageId,ii) => { 
          let image = G.getData(state,{itemType:'imageUploads',_id:imageId});

          let { height, imageSetId, filename } = image;

          let imageSet = G.getData(state,{itemType:'imageSets',_id:imageSetId});
          let imageUploads = imageSet.images;
          let otherImages = imageUploads.filter(x => x!== imageId);

          let crop = { top:0, left: 0, width:1, height, rotation:0 };

          return (
            <div 
              onClick={() => setSelectedImageId(imageId)}
              style={{
                paddingTop:20,
                background:'#eee',
                borderRadius:5,


                //border:'3px solid green',
                marginTop:20,
              }} class="sg-row dark-hoverable">
              <LocalCroppableImageContainer {...{ 
                filename, imageSetId, imageId, crop, width, cropName:namesByImageIds[ii] }}/>
              {otherImages.map(otherImageId => {
                let filename = G.getFilesystemName(state,{_id:otherImageId});
                return <LocalCroppableImageContainer {...{filename, imageSetId,imageId:otherImageId,crop, width}}/>
              })}
            </div>
          )
        })}
      
      {imageAnnotationModal}
    </>
  );

}
