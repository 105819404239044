import StyledSubstring from './StyledSubstring';

export default function SubscriptionActivityMessage({
  subscriptionActivityMessage,
  productsWithExpiredSubscriptions
}){


  let subActivitySentences = (
    subscriptionActivityMessage.split('.').filter(
      x => x.length > 0
    ).map(line => line+'.')
  );

  let isExpired = productsWithExpiredSubscriptions.includes('sciugoMain');

  let boldedSubstring = (
    isExpired ? "limited access" : "full access"
  );

  let toBold = [
    boldedSubstring,
    "without renewal"
  ]


  let alertClass = `alert alert-${isExpired ? 'warning' : 'success'}`;

  let lineInfoTags = [
    'account-access',
    'subscription-event-date'
  ]


  let subActivityComponent = (
    <div style={{fontSize:14, padding:10, margin:10}} 
      class={`sg-row full-width ${alertClass}`}>
      {subActivitySentences.map((line,ii) => ([
        <div info={lineInfoTags[ii]}>
          <StyledSubstring style={{fontWeight:'bold'}} text={line} substring={toBold[ii]}/></div>,
        (ii < subActivitySentences.length -1) && <div class="flex-expand"/>
      ]))}
    </div>
  )

  return subActivityComponent;

}
