import LayoutValueImage from './LayoutValueImage';
import { GridCellContext, ExpansionWindowFocusContext } from './Contexts'; 
import { useContext, useMemo, useRef, useState } from 'react';
import React from 'react';

const FILTER_CLASS_MAP = {
  //"012":"rgb-channel",
  "01":"red-green-channel",
  "02":"red-blue-channel",
  "12":"green-blue-channel",
  "0":"red-channel",
  "1":"green-channel",
  "2":"blue-channel"
}

/*

Need to paint the drawn / extra regions.
There's also a layer for arrows, etc.
Labels, in general.

Is this the region where we'd be painting the outlines.
If so (i.e, it's MAIN region), then we look to see what other regions there are.


Then, barring overrides, we just paint everything down.
And by default we paint, we may not even have 

*/





function FigureImageExpansionLayoutValue({
  value, 
  regions, 
  node, 
  nodeId, 
  nodes, 
  parentPixelDimensions 
}){
  let { 
    channels,
    regionId,
  } = value;

  let {cellLocation} = useContext(GridCellContext);
  //let { cellLocation } = gridCellContext;



  let { style } = node;
  let { padding=0, borderWidth=0, borderColor='white' } = style || {};



   let { selectedNodes, onItemClick, onEvent } = useContext(ExpansionWindowFocusContext);




  let annotationId = regions[regionId];


  let thisPixelDimensions = { 
    w:parentPixelDimensions.w,
    h:parentPixelDimensions.h,
  }



  let children = node.c;



  const resolvedOuterPadding = Math.max(0,padding - borderWidth);
  const resolvedBorderPadding = Math.min(padding, borderWidth)


  let selectedItemCover;

  let isSelected = selectedNodes?.find((item) => {
    let thatNodeId = item.nodeId;
    let thatNode = item;

    let strNodeCL = JSON.stringify(thatNode.cellLocation);
    let strCL = JSON.stringify(cellLocation);

    if( nodeId === "012-text-0" ){
      // debugger;
    }
    return thatNodeId === nodeId &&  strNodeCL === strCL;
  })


  if( isSelected ){
    selectedItemCover = <div style={{zIndex:100,color:'white', fontWeight:'bold', pointerEvents:'none'}} class="selected-item-cover"/>
  }

  //nodeId.includes('012') && alert("Rendering " + nodeId);

  let onSomeClick = (e) => {

    e.stopPropagation();
    e.preventDefault();


    onEvent && onEvent({
      type:e.type,
      itemType:"expansionNode",
      node,
      event:e,
      nodeId,
    })
  }

  let width = parentPixelDimensions.w;
  let height = parentPixelDimensions.h;


  return (
    <div hello={"world"} layer={"outer"} nodeId={nodeId} style={{
      position:"relative",
      pointerEvents:'auto',
      width,
      height,
      background:'lime',
      //border:'1px solid magenta',
    }} 

      onClick={onSomeClick}

    >





      <LayoutValueImage {...{annotationId,style,node,regionId,regions, children, selectedNodes, nodes, onEvent, nodeId, parentPixelDimensions:thisPixelDimensions }}/>






      {selectedItemCover}


    </div>
  )
}

export default React.memo(FigureImageExpansionLayoutValue);
