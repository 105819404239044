import { io } from 'socket.io-client';
import A from './ActionCreators';
import { post } from './io';

export default function initSocket(store,args){



  let { 
    websocketTarget,
    windowSessionId,
    agentId,
    personId
  } = args;


  let socket = new Promise((res,rej) => {

    if( websocketTarget ){

      let socket = io(websocketTarget);

      socket.on('receiveThreadUpdateNotification',data => {
        store.dispatch(A.receivedThreadUpdateNotification(data))
      })

      socket.on('connect',data => {

 

        let postArgs = {
          route:'/registerWebsocket',
          body:{
            personId:(personId||agentId),
            socketId:socket.id
          },
          state:store.getState(),
          onSuccess:() => {
          },
          onServerFailure:() => {
          },
          onInternetFailure:() => {
          },
          dispatch:store.dispatch
        }
        post(postArgs,store.dispatch)
      })

      socket.on('receiveTypingNotification',data => {
        debugger;
        store.dispatch(A.receiveTypingNotification(data));
      })

      /*
      socket.on('subscriptionEventUpdate',data => {
        store.dispatch(A.updateSubscriptionInfo(data));
      })
      */


      socket.on('ping',data => {
        let state = store.getState();
        let username = state.loginInfo.username;

        let postArgs = {
          route:'/pong',
          body:{
            ...data,
            sid:socket.id,
            windowSessionId,
            username,
          },
          state:store.getState(),
          onSuccess:() => {
          },
          onServerFailure:() => {
          },
          onInternetFailure:() => {
          },
          dispatch:store.dispatch
        }
        post(postArgs,store.dispatch)

              })

      socket.on('connect',data => {
        res(socket);
      })

    }else{
      res({
        disconnect:() => {}
      });
    }

  })

  return socket;

}
