
import DocumentationWrapper from './DocumentationWrapper';

function Feature({title,description,media}){
  return (
    <div 
      style={{
        borderBottom:'1px solid #ccc',
        minHeight:300,
        paddingTop:50,
        paddingLeft:200,
        paddingRight:200,

      }}
      class="full-width fair-padding">
      <div 
        //class="feature-placement"
        style={{
          textAlign:'left',
          fontSize:24,
        }}>
        {title}
      </div>
    </div>
  )
}

export default function WelcomeFeatures(){

  let features = [
    { title:"Automatic molecular-weight-marker placement" },
    { title:"Blazingly fast figure creation" },
    { title:"Rapid band alignment" },
    { title:"Quantification" },
    { title:"Supplementary figure creation" }
  ]


  let featuresComp = (
    <div style={{height:'100%',
      overflowY:'scroll',
      marginTop:50,
      }}>
      {features.map(x => <Feature {...x}/>)}
    </div>
  )


  return (
    <div style={{
      //marginTop:100,
      //height:100,
      height:'100%',
      position:'relative',
    }}>
    <DocumentationWrapper/>;
    </div>
  )

  return featuresComp;
}
