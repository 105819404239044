import { ANNOTATION_IMAGE_ID } from './UIConstants';
import { getIntermediaryPoint } from './Geometry';
import usePresentSelector from './usePresentSelector';
import QuantificationResults from './QuantificationResults';
import A from './ActionCreators';
import G from './Getters';
import { distance } from './Geometry';
import ProcessImage from './ProcessImage';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import QuantificationModal from './QuantificationModal';

import imageMap from './imageMap';

export default function QuantificationViewer(
  {focusedAnnotationId, fullImageWidth}
){

  let dispatch = useDispatch();


  let {atn, evaluatedLaneOffsets, annotationHeight, atnImageSrc } = usePresentSelector(state => {

    let atnImageSrc = G.getFigureImageUrlByAnnotationId(state,{annotationId:focusedAnnotationId});
    let atn = G.getData(state,{itemType:'annotations',_id:focusedAnnotationId});

    let evaluatedLaneOffsets = G.getLaneLineOffsetEvaluations(state,{_id:focusedAnnotationId})

    let annotationHeight = G.getAnnotationHeight(state,focusedAnnotationId);

    return { atn, evaluatedLaneOffsets, annotationHeight, atnImageSrc }


  })

  let annotationId = focusedAnnotationId;


  let quantifications = usePresentSelector(state => G.getAnnotationQuantification(state,{_id:annotationId}));



  let ls = atn?.ls;
  let dist = distance(...atn.ls);

  let [quantModalOpen,setQuantificationModalOpen] = useState(false);

  return (
    <div style={{alignItems:'center',
      position:'relative',
      height:'100%',
    }} class="sg-row">



      {quantifications && (
        <div style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          // background:'lime'
        }} class="sg-col full-height">
          <div style={{
            alignItems:'center',
            justifyContent:'center',
          }}>

            <div id="quantify-all" style={{
              cursor:'pointer',
              background:'#f0f0f0',

            }} 
              onClick={() => setQuantificationModalOpen(true)}
              class={"hoverable round-corners small-padding small-margin"}>Peaks</div>
          </div>
        </div>
      )}
      <QuantificationResults annotationId={focusedAnnotationId}/> 



      {!focusedAnnotationId && (
        <div style={{
          color:'#ccc',
          height:'100%',
          fontSize:36,
          position:'relative',
          top:'25%',
          textAlign:'center',
        }}>
          Select a crop to<br/> view quantifications.
        </div>
      )}

      {quantModalOpen && <QuantificationModal {...{quantifications,onClose:()=>setQuantificationModalOpen(false)}}/>}






    </div>

  )
}
