import { useState } from './sciugo-hooks';
import { useRef, useEffect } from 'react';
import Tutorial from './Tutorial';
import SurveyContainer from './SurveyContainer';
import A from './ActionCreators';
import G from './Getters';
import usePresentSelector from './usePresentSelector';
import AppModal from './AppModal';
import FiguresAndPanels from './FiguresAndPanels';
import ChatWindow from './ChatWindow';
import Playground from './Playground';
import ShortcutListener from './ShortcutListener';
import AntibodyValidation from './AntibodyValidation';
import AnnotationTutorialCover from './AnnotationTutorialCover';
import QuantificationSceneWrapper from './QuantificationSceneWrapper';

import { FIGURE_PANELS } from './RecordTypes';


import { useDispatch } from 'react-redux';
import './protocol_style.css';
import './bootstrap/bootstrap.min.css';

import MembraneGroupContainer from './MembraneGroupContainer';
import AppImageUploader from './AppImageUploader';

import ConfirmModal from './ConfirmModal';
import ImageAnnotation from './ImageAnnotation';
import ErrorModal from './ErrorModal';
import PaymentModal from './PaymentModal';

import AppNavbar from './AppNavbar';

import Notifications from './Notifications';

import FigureScene from './FigureScene';
import WelcomeSceneWrapper from './WelcomeSceneWrapper';

import ImageStorage from './ImageStorageScene';
import FigureBuilder from './FigureBuilder';

import GelsScene from './GelsScene';

import { NEW_FEATURES, SIGNIN, WELCOME_NEW_USER_MODAL } from './AppModal';

import PlotTest from './PlotTests';


import ProtocolsScene from './ProtocolsScene';
import ComingSoon from './ComingSoon';

/*
export const QUANTIFICATION="QUANTIFICATION";
export const ANNOTATION="ANNOTATION";
export const DEMO = "DEMO";
export const DROPBOX = "DROPBOX";
export const ANTIBODY_VALIDATION = "ANTIBODY_VALIDATION";
export const FIGURES_AND_PANELS = "FIGURES_AND_PANELS";
export const WELCOME = "WELCOME";
export const IMAGE_STORAGE = "IMAGE_STORAGE";
export const FIGURE_BUILDER = "FIGURE_BUILDER";
*/

import {
  QUANTIFICATION,
  ANNOTATION,
  DEMO,
  DROPBOX,
  ANTIBODY_VALIDATION,
  FIGURES_AND_PANELS,
  WELCOME,
  IMAGE_STORAGE,
  FIGURE_BUILDER,
  FIGURE,
  WESTERN_BLOTS,
  PROTOCOLS,
  INVENTORY,
} from './UIConstants';

let defaultSelectedFigurePanelId = null;
let defaultModal = NEW_FEATURES;

if( process.env.NODE_ENV !== 'production' ){
  defaultSelectedFigurePanelId = null;
  defaultModal = null;
}



export default function App({mode,setMode}){

  let dispatch = useDispatch();
  let {state,selectedFigurePanelContext} = usePresentSelector(state => {
    let context = G.getUiSelectedFigurePanelContext(state);

    let figurePanelId = context?.figurePanelId;
    if( figurePanelId && G.doesRecordExistInCache(state,{type:FIGURE_PANELS,_id:figurePanelId}) && !G.isArchived(state,{type:FIGURE_PANELS,_id:figurePanelId}) ){ 
      return {
        state,
        selectedFigurePanelContext:context,
        editing:context?.editing
      }
    }else{
      return {
        state,
      }
    }
  });

  let selectedFigurePanelId = selectedFigurePanelContext?.figurePanelId;

  const setSelectedFigurePanelContext = (context) => {
    if( context ){
      context.figurePanelId = context._id || context.figurePanelId;
    }
    dispatch(A.setSelectedFigurePanelContext({
      context
    }))
  }

  let loginStatus = state.loginInfo.status;

  let isLoggedIn = G.isLoggedIn(state);

  let doingWalkthrough = usePresentSelector(state => 
    G.isInTutorial(state)
  );

  
  const [ _, setDoingWalkthrough ] = useState(false);

  let [modal, setModal] = useState(defaultModal, "SET_APP_MODAL");
  const [svg, setSvg ] = useState(undefined,"SET_APP_SVG");

  useEffect(() => {

    if( modal === NEW_FEATURES && doingWalkthrough ){
      setModal();
    }

    /*if(isLoggedIn && modal === SIGNIN){
      setModal();
    }*/

  },[isLoggedIn,modal,doingWalkthrough])


  const sceneDefaultsRef = useRef();

  let { surveys } = state;

  let survey = G.peekSurveys(state);
  if( survey && isLoggedIn ){
    return <SurveyContainer survey={survey}/>
  }


  //return <AnnotationTutorialCover/>


  //return <Gigs/>


  //return <Playground/>

  if( mode === WELCOME ){
    return <WelcomeSceneWrapper {...{setMode}}/>
  }

  const clearUserSelections = () => {
    setSelectedFigurePanelContext(null);
    //setMode(FIGURES_AND_PANELS);
  }

  let figuresAndPanels = <FiguresAndPanels setMode={setMode} setSelectedFigurePanelContext={setSelectedFigurePanelContext} selectedFigurePanelContext={selectedFigurePanelContext}/>;

  let figureScene = <FigureScene 
    key={loginStatus + "-" + selectedFigurePanelId}
    {...{
      setMode, 
      setSvg, 
      svg, 
      setSelectedFigurePanelContext, 
      figurePanelId:selectedFigurePanelId 
    }}/>



    let app = (


      <div 
        key={"tutorial-"+doingWalkthrough}
        
        info={"app"} style={{position:'relative'}} class="flex-expand full-height" id="app">


        <ShortcutListener mode={mode}/>

        <div style={{flex:1, display:'flex', flexDirection:'column', position:'relative'}} class="full-height">

          <AppNavbar {...{mode,
            setModal,
            isLoggedIn,
            clearUserSelections,
            setMode:((mode) => {
              dispatch(A.CLEAR_HISTORY());
              setMode(mode);
            }),
            state, setSvg, 
          }}/>
          <AppModal {...{
            modal,
            setModal
          }}/>



          <div style={{
            display:'flex', 
            flex:1, 
            overflowY:'hidden'

            // height:'100%'
          }}>

            {mode===FIGURE_BUILDER && <FigureBuilder/>}

            {mode === IMAGE_STORAGE && <ImageStorage/>}
            {mode === FIGURES_AND_PANELS && figuresAndPanels}
            {mode === FIGURE && figureScene}
            {mode === ANNOTATION && <ImageAnnotation state={state}/>}
            {mode === DEMO && <MembraneGroupContainer />}
            {mode === ANTIBODY_VALIDATION && <AntibodyValidation/>}
            {mode === QUANTIFICATION && <QuantificationSceneWrapper/>}
            {mode === WESTERN_BLOTS && <GelsScene/>}
            {mode === PROTOCOLS && <ComingSoon/>}
            {mode === INVENTORY && <ComingSoon/>}

          </div>
        </div>



        {!doingWalkthrough && <AppImageUploader onDrop={() => {
          if( mode !== WESTERN_BLOTS ){
            setMode(ANNOTATION)
          }else{
            dispatch({type:'gelDemoDataIn'})
          }
        }}/>}


        <ChatWindow/>
        <Notifications/>



      </div>
    )

  if( doingWalkthrough ){
    return (
        <Tutorial>
          {app}
      </Tutorial>
    )
  }else{
    return app;
  }




}
