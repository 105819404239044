import welcomeVideo from './media/welcome.mp4';
import { useRef } from 'react';
import { isUsingWebdriver } from './versionConfig';

export default function WelcomeVideo(){

  const videoRef= useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 2;
  };

 
  let videoSource;
  if( !isUsingWebdriver() ){
    //we have this in here
    //to speed up UI tests
    //so it doesn't download this movie
    videoSource = welcomeVideo;
  }


  return (

    <video 
      ref={videoRef} style={{width:'100%'}} 
      onCanPlay={() => setPlayBack()}
      defaultPlaybackRate={1.5}
      playbackRate={1.5}
      controls={true} loop autoPlay={true} playsInline={true} muted>
      <source type="video/mp4" src={videoSource}/>
      <p>Short demo video of how to make a western blot figure or how to make a gel figure in sciugo.</p>
    </video>

  )

}
