import { useState } from 'react';
import HierarchyList from './HierarchyList';
import DocumentationContent from './DocumentationContent';


import CaptureBands from './gif/CaptureBands.gif';
import Borders from './gif/Borders.gif';
import Brackets from './gif/Brackets.gif';
import AlignCropBands from './gif/AlignCropBands.gif';
import MolecularWeightMarks from './gif/MolecularWeightMarks.gif';

import PlaceCropsInFigure from './gif/PlaceCropsInFigure.gif';
import VerticalResizeInFigure from './gif/VerticalResizeInFigure.gif';

import EmptyGridExample from './gif/EmptyGridExample.png';
import FilledGridExample from './gif/FilledGridExample.png';
import GridItemPurposes from './gif/GridItemPurposes.png';
import AnglingText from './gif/AnglingText.gif';
import DragFileOntoAnother from './gif/DragFileOntoAnother.png';

import LadderAnnotations from './gif/LadderAnnotations.png';

import SignalOnlyAnnotations from './gif/SignalOnlyAnnotations.png';
import StarTheFigureImage from './gif/StarTheFigureImage.png';


import che1 from './images/1.jpeg';
import che2 from './images/2.jpeg';



function GreekLetterDisplay({ entries }){

  return <div>{Object.entries(entries).map(([code,output]) => (
    <div style={{

      background:'#eee',
      margin:3,
      padding:5,
      borderBottom:'1px solid #ccc',
      borderRight:'1px solid #ccc',


      width:200
    }} class="sg-row v-center">
      <div style={{width:100}}><pre>{code}</pre></div>
      <div>{output}</div>
    </div>
  ))}</div>
}





const tree = [
  {
    _id:"Sciugo Tutorial",
    label:"Sciugo Tutorial",
    html:(
      <div style={{height:'80%'}}>
        {false && <p><b>1. The live chat at the bottom right is A REAL HUMAN.<br/>2. If you get stuck, ask a question.<br/></b></p>}

        <div style={{width:'100%',
          //border:'1px solid blue',
          display:'flex', height:'100%',}}>
        <iframe 
          width="100%"
          style={{
            //flex:1
          }}
        src="https://www.youtube.com/embed/nDq93d59Pog" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        {false && <p>The next sections of this tutorial guide you through creating a figure:</p>}
        {false && <ol class="tutorial-list">
          <li>Capture and label protein targets.</li>
          <li>Label molecular weights in the image.</li>
          <li>Create a figure (in the <i>My Figures</i> tab at the top)</li>
          <li>Insert crop regions into your figure</li>
          <li>Label your samples <b>in the figure</b></li>
          <li>Styling</li>
          <li>Export as SVG (which can be opened in Illustrator)</li>
        </ol>}
      </div>
    ),
  },
  {
    _id:"Capture bands and MWs",
    label:"Capture bands and MWs",
    children:[
      {
        _id:"Capture bands",
        html:(
          <div>
            <div>To create a figure or quantify, you'll want to capture band regions.</div>
            <div>
              {true && <ol class="tutorial-list">

                {["Go to the 'Upload and Label' tab from the top menu. ","Using the 'Line Crop' tool, click-and-drag a line over the bands to capture.","Label the protein target."].map(x => <li>{x}</li>)}
              </ol>}
              
            </div>
          </div>
        )
            ,
        gif:CaptureBands

      },
      {
        _id:"Molecular weights",
        text:"Click on a protein ladder band and label to have it show up in your figure.\nYou can move the MW label text-field by dragging it around.\nIf the line from the marker to the 'crop region' is green, the mark will appear in your figure.",
        gif:MolecularWeightMarks,

      },
      {
        _id:"Images without ladders",
        text:"Suppose the exposure you want for your figure (and quantification) doesn't contain the protein ladder.\nYou can capture MW marks using the guide below.",
        grid:[
          [{
            caption:"Image without ladder",
            image:che2
          },
          {
            caption:"Image with ladder",
            image:che1
          }
          ],
          [
            { caption:"Group images by dragging together in filesystem.",
              image:DragFileOntoAnother
            },
            {
              caption:"Star the image you want to show up in the figure.",
              image:StarTheFigureImage
            }
          ],
          [
            { 
              caption:"Label MWs in the image with ladder.",
              image:LadderAnnotations
            },
            {
              caption:"The annotations will apply to both images.",
              image:SignalOnlyAnnotations
            }
          ]
        ]

      }
    ]
  },

  {
    _id:"Creating figures",
    label:"Creating Figures",
    children:[
      {
        _id:"The figure grid",
        //text:"",
        //text:"By default, the figure you create 2 extra lanes: 1 on left the left for the protein label and one on the right for the molecular weight label.\nYou can select multiple cells simultaneously by dragging over the cells or by holding Shift when you click on a cell.\nRight click on a cell to split cells, merge cells or delete the row or column.",
        grid:[
          [{
            caption:"This is an empty grid of 12 lanes:",
            image:EmptyGridExample,
            width:100,
          }],
          [{
            caption:"The usual purpose for each part of the grid:",
            image:GridItemPurposes,
            width:60
          }],
          [{
            caption:"For example:",
            image:FilledGridExample
          }]
        ]
      },
  {
    _id:"Inserting crop regions",
    text:"Click the crop to insert and then click on the destination cell.",
    gif:PlaceCropsInFigure
  },
  /*{ 
    _id:"Add/delete rows/cols",
    text:"INser stuff here!",
  },*/
  {
    _id:"Aligning bands",
    text:"Align bands by dragging the alignment tool left or right.\nThis adjustment scales height and width proportionally.",
    gif:AlignCropBands,
  },
  { 
    _id:"Edit crop height",
    text:"Drag the vertical bounds of a 'crop region' bounds to adjust them.",
    gif:VerticalResizeInFigure
  },
  /*{
    _id:"Column widths",
    children:[
      { 
        _id:"Adjusting column widths",
        text:"This is how we do it!",
        grid:[
          [{ 
            caption:"To resize multiple cells simultaneously, use the toolbar:",
            gif:ColumnWidthsToolbar,
          }],

          [{ 
            caption:"Dragging column bounds that are within image bounds will always widen one cell and narrow the other cell:",
            gif:ColumnWidthsImageInterior,
          }],

          [{ 
            caption:"Dragging column bounds on the boundary of an:",
            gif:ColumnWidthsImageBoundary,
          }]

        ]
      }
    ]
  },*/
  {
    _id:"Angled text",
    text:"Select the cells you want to style and click the angle direction.\nMake sure the angle is not zero.",
    gif:AnglingText

  },
  {
    _id:"Borders",
    text:"Select the cells you want to style and choose the desired border styling.",
    gif:Borders,
  },
  {
    _id:"Brackets",
    text:"You can put brackets above/below the line with a positive/negative bracket value, respectively.",
    gif:Brackets,
  },
    ]
  },

  {
    _id:"Greek letters",
    label:"Greek letters",
    html:(
      <div class="text-body">

        <div>Typing <b>\</b> (backslash) before the letter name gets the greek letter.</div>

        <div class="sg-row fair-margin" style={{fontSize:16}}><GreekLetterDisplay entries={{
          '\\alpha': 'α',
          '\\beta': 'β',
          '\\gamma': 'γ',
          '\\delta': 'δ',
          '\\epsilon': 'ε',
          '\\zeta': 'ζ',
          '\\eta': 'η',
          '\\theta': 'θ',
          '\\iota': 'ι',
          '\\kappa': 'κ',
          '\\lambda': 'λ',
          '\\mu': 'μ',
          '\\nu': 'ν',
          '\\xi': 'ξ',
          '\\omicron': 'ο',
          '\\pi': 'π',
          '\\rho': 'ρ',
          '\\sigma': 'σ',
          '\\tau': 'τ',
          '\\upsilon': 'υ',
          '\\phi': 'φ',
          '\\chi': 'χ',
          '\\psi': 'ψ',
          '\\omega': 'ω',
          }}/>
        <GreekLetterDisplay entries={{
          '\\Alpha': 'Α',
          '\\Beta': 'Β',
          '\\Gamma': 'Γ',
          '\\Delta': 'Δ',
          '\\Epsilon': 'Ε',
          '\\Zeta': 'Ζ',
          '\\Eta': 'Η',
          '\\Theta': 'Θ',
          '\\Iota': 'Ι',
          '\\Kappa': 'Κ',
          '\\Lambda': 'Λ',
          '\\Mu': 'Μ',
          '\\Nu': 'Ν',
          '\\Xi': 'Ξ',
          '\\Omicron': 'Ο',
          '\\Pi': 'Π',
          '\\Rho': 'Ρ',
          '\\Sigma': 'Σ',
          '\\Tau': 'Τ',
          '\\Upsilon': 'Υ',
          '\\Phi': 'Φ',
          '\\Chi': 'Χ',
          '\\Psi': 'Ψ',
          '\\Omega': 'Ω',
        }}/>
      </div>
      </div>
    )
  }
]


function getOrder(children){
  let toReturn = [];
  children.forEach(child => {
    if( child.children ){
      toReturn.push( ...getOrder(child.children).map(x => child._id + '__' + x));
    }else{
      toReturn.push(child._id);
    }
  })
  return toReturn;
  
}

export default function DocumentationWrapper(){

  const order = getOrder(tree);

  const [selectedSection,setSelectedSection] = useState(order[0]);

  let selectedSectionPath = selectedSection.split('__');

  let selectionContent = selectedSectionPath.reduce((treeNode,keyPathItem,ii) => {
    //console.log({treeNode,keyPathItem,ii});
    let node = treeNode.find(child => {
      //console.log({_id:child._id, keyPathItem});
      return child._id === keyPathItem
    });
    if( ii === (selectedSectionPath.length - 1) ){
      return node;
    }else{
      return node.children;
    }
  },tree)


  let selectedIndex = order.findIndex(item => item === selectedSection);
  let nextSection = order[selectedIndex+1];
  let previousSection = order[selectedIndex-1];

  const onNextSection = () => { setSelectedSection( nextSection ); }
  const onPreviousSection = () => { setSelectedSection( previousSection ); }



  return (

    <div style={{
      //border:'3px solid orange',
      //height:'100%',
      maxHeight:'80vh',
      minHeight:'80vh',
      //height:700,
      //overflow:'wrap'
    }} class="sg-row">
      <div style={{
        width:300,
        borderRight:'1px solid #ccc',
        height:'100%',
        //border:'3px solid purple',
      }} class="small-padding">
        <HierarchyList {...{tree,selectedSection,setSelectedSection}}/>
      </div>


      <div style={{
        borderLeft:'1px solid #ccc',
      }} class="sg-col full-width">
        <div style={{ flex:1, overflowY:'scroll', minHeight:0 }}>
          <DocumentationContent {...{selectedSection,selectionContent,path:selectedSection}}/>
        </div>
        <DocumentationNavigation {...{
          nextSection,
          previousSection,
          onNextSection,
          onPreviousSection
        }}/>
      </div>
    </div>

  )
}


const navButtonStyle={
  flex:1,
 
  fontSize:14,
  padding:10,
  margin:10,
  borderRadius:10,
  //background:'green'
}

function NavigationButton({onClick,targetSection,direction}){




  return (
    <div onClick={() => {
      if( targetSection ){
        onClick();
      }
    }} class={targetSection && "dark-hoverable clickable-text"} style={{
      ...navButtonStyle,
      border:(targetSection?'1px solid #ccc':'unset'),
      textAlign:(direction==='Next' ? 'right' : 'left'),
    }}>
      {targetSection && <div><b>{direction + " section:"}</b></div>}
      <div class="text-primary">{targetSection && targetSection.replace('__',': ')}</div>
    </div>

  )
}

function DocumentationNavigation({onNextSection,onPreviousSection, currentIndex, nextSection, previousSection }){

  return (
    <div class="sg-row" style={{
      borderTop:'1px solid #ccc',
      width:'100%'
      }}>
      <NavigationButton {...{ onClick:onPreviousSection, targetSection:previousSection, direction:"Previous" }}/>
      <NavigationButton {...{ onClick:onNextSection, targetSection:nextSection, direction:"Next" }}/>
      {false && <div onClick={onPreviousSection} style={navButtonStyle}>Previous section</div>}
      {false && <div onClick={onNextSection} style={navButtonStyle}>Next section</div>}
    </div>
  )


}
