import { useState, useReducer } from 'react';
import { produce } from 'immer';
import Navbar from './Navbar';
import CollapsedIcon from '@material-ui/icons/ArrowRight';
import ExpandedIcon from '@material-ui/icons/ArrowDropDown';
import CollapsibleProtocol, { EntityFormItem, ElectricityFormItem } from './CollapsibleProtocol';

function Reducer(state,action){
  switch(action.type){
    case 'edit':
      return produce(state,draft => {
        draft[action.index] = action.value;
      })
    case 'add':
      return [...state,'']
    case 'delete':
      return state.filter((x,ii) => ii !== action.index);
  }
}


export default function GelBlot(){
  const [ blotType, setBlotType ] = useState(false);
  const [ state, dispatch ] = useReducer( Reducer, ['']);

  const [ targetsOpen, setTargetsOpen ] = useState(true);

  let showForm = blotType === "Western";


  const blotTypes = [
    'Western',
    'Southern',
    'Northern',
  ]
  let options = blotTypes.map(x => ({_id:x, label:x}));


  const protocolContent = (

    <div class="sg-col">

      <div class="sg-row">

        <div><b>Blot type:</b></div>
        &nbsp;&nbsp;&nbsp;
        <select>
          <option>Wet</option>
          <option>Semi-dry</option>
          <option>Dry</option>
        </select>
      </div>

      <div class="sg-row fair-margin-top">
        <EntityFormItem title={"Transfer paper"} entity={"Nitrocellulose"}/>
      </div>

      <div class="fair-margin-top"> 
        <ElectricityFormItem {...{bufferType:"Transfer Buffer"}}/>
      </div>




    </div>

  )


  let navbar = <Navbar options={options} onSelect={_id => setBlotType(_id)} selected={blotType}/>


    let targetForm = ( <>
      <div class="sg-row">
        <input value={"Protein target"} style={{background:'none',border:'white',pointerEvents:'none'}}/>
        <div style={{marginLeft:10}}>Loading<br/>Control</div>
      </div>
      {state.map((x,ii) => {
        return (
          <div class="sg-row" style={{marginTop:20}}>
            <input 
              ref={el => {
                if( el ){
                  el.focus();
                }
              }}
              onKeyUp={e => {
                if( e.key === 'Enter' ){
                  dispatch({type:'add'})
                }else if( e.key === 'Backspace' || e.key === 'Delete' ){
                  if( x === '' ){
                    dispatch({
                      type:'delete',
                      index:ii
                    })
                  }
                }
              }}
              //value={x} 
              onChange={e => {

                console.log(e);

                if( e.key === 'Tab' || e.key === 'Enter' ){
                  alert("Enter!");
                  e.preventDefault();
                  dispatch({ type:'add' });
                }
                else if( x === '' && e.key === 'Backspace' ){
                  dispatch({ type:'delete',index:ii })
                }else{

                  /*
                  dispatch({
                    type:'edit',
                    index:ii,
                    value:e.target.value
                  })
                  */
                }

              }}/>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <input style={{marginLeft:20}} type="checkbox"/>
            </div>
          </div>
        )
      })}
    </>)


  const form = (
    <div style={{marginTop:20}}>
      <CollapsibleProtocol {...{
        heading:"Protein targets",
        content:targetForm,
        defaultOpen:true,
      }}/>

      <div class="fair-margin-top">
        <CollapsibleProtocol {...{heading:"Details", content:protocolContent}}/>
      </div>

    </div>
  )

  return (
    <div>
      <div>{navbar}</div>
      {showForm && form}
    </div>
  )
}
