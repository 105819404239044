const defaultsMap = {
  'pointerWidth':10,
  'fontSize':9,
  textPointerSpacing:0,
  labelConnector:'solid'
}

export default function getLabelProperty(style,editorStyle,property) {

  /*let props = Array.from(new Set( labels.map(lab => lab[property]) )).filter(
    x => x
  )*/
  let toReturn = style[property];
  if( isNaN(toReturn) ){
    toReturn = editorStyle[property];
  }
  if( isNaN( toReturn ) ){
    toReturn = defaultsMap[property]
  }
  return toReturn;

  //return style[property] || editorStyle[property] || defaultsMap[property];

}
