import Modal from '@material-ui/core/Modal';
import { useSelector, useDispatch } from 'react-redux';
import G from './Getters';
import imageMap from './imageMap';
import { useState, useEffect } from 'react';
import A from './ActionCreators';

let fallBackOnRaw = false;
if( process.env.NODE_ENV !== "production" ){
  fallBackOnRaw = true;
}


const UNIT_MAP = {
  "ppum":"pixels / μm"
}

export default function ImageResolutionSpecificationModal({
  imageId, 
  onClose, 
  mode, 
  onEvent 
}){

  let dispatch = useDispatch();

  let { output, resolution={}, imageUpload } = useSelector(state => {
    let imageRecordUrl = G.getImageRecordUrl(state,{imageId, fallBackOnRaw, returnVersion:true});


    let resolution  = G.getImageResolution(state,{imageId});
    let imageUpload = G.getImage(state,
      {
       imageId 
      }
    )
    return { output:imageRecordUrl, resolution, imageUpload };
  })


  

  const [ isotropic, setIsotropic ] = useState(true);
  const [ xRes, setXRes ] = useState();
  const [ yRes, setYRes ] = useState();
  const [ units, setUnits ] = useState();



  let rawCrop = {
    width:1,
    height:imageUpload.height,
    left:0,
    top:0
  }


  let { url, version } = output;

  let iso = isotropic;

  let submitDisabled = !(
    (!iso || (xRes))
    &&
    (iso || (xRes && yRes))
  )

  //Boolean(Number(xRes)) && (isotropic || Boolean(Number(yRes)));

  let body = (
    <div>
      <img src={imageMap[url]||url} style={{width:'50%'}}/>
      <div>{url}</div>

      <div>What is the image resolution (PPI)?</div>
      <div><input input={"image-resolution-x"} onChange={e => setXRes(Number(e.target.value))} type="number" value={xRes} min={0}/></div>

      {!isotropic && (
        <div><input input={"image-resolution-y"} onChange={e => setYRes(e.target.value)} type="number" value={yRes} min={0}/></div>
      )}


      <button option={"accept"} onClick={() => {

        let properties = {
          "versions.raw.resolution.units":"ppum",
        }

        if( isotropic ){
          let key = "versions.raw.resolution.isotropic";
          properties[key] = xRes;
        }else{

          let xKey = "versions.raw.resolution.x";
          let yKey = "versions.raw.resolution.y";

          properties[xKey] = xRes;
          properties[yKey] = yRes;

        }



        dispatch(A.setImageProperties({
          imageId:imageUpload._id,
          properties
        }))

        onClose();
      }} disabled={submitDisabled} class="btn btn-success">Okay</button>

    </div>
  )

  return body;


}
