import usePresentSelector from './usePresentSelector';
import { useDispatch } from 'react-redux';
//import Navbar from './Navbar';
//import LaneBox from './LaneBox';
import useLocalReducerWithGlobalDispatch from './useLocalReducerWithGlobalDispatch';
import { useState, useRef, useEffect, useContext, createContext, useReducer } from 'react';
import A from './ActionCreators';
import G from './Getters';
import { QUANTIFICATION } from './UIConstants';


//import WesternBlotAnnotationContainer from './WesternBlotAnnotationContainer';

import { produce } from 'immer';

//import QuantifiableItemsContainer from './QuantifiableItemsContainer';
import CropListLaneAdjustmentContainer from './CropListLaneAdjustmentContainer';


import QuantificationFilesystemContainer from './QuantificationFilesystemContainer';


import useLocalReducer from './useLocalReducer';


import { FilesystemHierarchyContext, QuantificationListContext } from './Contexts';





const ListReducer = (state,action) => {
  let { globalDispatch } = action;
  let type = action.type;
  if( !type ){
    throw Error("Trying to reduce action (QuantificationScene) without a type!");
  }
  let { ids } = action;
  switch(type){
    case "add":{
      globalDispatch(A.modifyQuantificationList({
        ids,
        modification:"add"
      }));
      return produce(state,draft => {
        ids.forEach(id => draft[id] = [])
      })
      //Array.from(new Set([...list,...ids]))
    }
    case "remove":{

      globalDispatch(A.modifyQuantificationList({
        ids,
        modification:"remove"
      }));

      return produce(state,draft => {
        ids.forEach(id => delete draft[id]);
      })
      //return list.filter(x => !ids.includes(x))
    }
    case "removeAll":{

      globalDispatch(A.modifyQuantificationList({
        modification:"removeAll"
      }));

      return {};
    }
    case "selectCells":{

      //if not multiselect, then just clear everything
      //and select what was given
      //

      let { indicesByAnnotationIds, multiselect } = action;
      return produce(state,draft => {


        if( ! multiselect ){

          Object.keys(draft).forEach(id => {
            if( id in indicesByAnnotationIds ){

              draft[id] = indicesByAnnotationIds[id]
            }else{
              draft[id] = []
            }
          })
        }else{
          for( let id in indicesByAnnotationIds ){
            for( let index of indicesByAnnotationIds[id] ){
              let indexOfIndex = draft[id].indexOf(index);
              if( indexOfIndex === -1 ){
                draft[id].push(index);
              }else{
                draft[id].splice(indexOfIndex,1);
                //draft[id].add(index)
              }
            }
          }
        }
      })
    }
    case "clearSelections":{
      return Object.fromEntries(Object.entries(state).map(([key]) => ([key,[]])));
    }
    default:
      throw Error("the type '"+action.type+"' was not found in QuantificationScene local reducer.");
      //return state;
  }
}


let defaultQuantState = {a0:[0,1],aktyna0:[]}
defaultQuantState = {};

function getAnnotationSelectionState(state){
  let annotations = state.ui[QUANTIFICATION].selectedAnnotations;
  let object = Object.fromEntries(annotations.map(id => [id,[]]));
  return object;
}

export default function QuantificationScene(){


  const dispatch = useDispatch();

  
  let state = usePresentSelector(state => state);

  const [ quantificationList, quantificationListDispatch ] = useLocalReducerWithGlobalDispatch(
    ListReducer,
    getAnnotationSelectionState(state),
    dispatch
  );

  const [windowResizeMarker,setWindowResized] = useState(0);

  const onResize = () => {
    setWindowResized(!windowResizeMarker);
  }

  function onKeyUp(e){
    let key = e.key;
    let shift = e.shiftKey;
    const increment = 0.0001;
    const responses = {
      ArrowUp:shift => {
      },
      ArrowDown:shift => {
      },
      ArrowLeft:shift => {

        let action = shift ? A.incrementLaneWidths : A.shiftLaneOffsets;
        dispatch(action({
          indicesByAnnotationIds:quantificationList,
          increment:-increment,
        }))
      },
      ArrowRight:shift => {

        let action = shift ? A.incrementLaneWidths : A.shiftLaneOffsets;

        dispatch(action({
          indicesByAnnotationIds:quantificationList,
          increment,
        }))
      },
      /*Tab:shift => {


        let atnKeys = Object.keys(quantificationList)
        let keysWithSelection = atnKeys.filter(key => quantificationList[key].length > 0);
        if( keysWithSelection.length === 1 ){
          
          let selectedAtnId = keysWithSelection[0];
          let selectedIndex = quantificationList[selectedAtnId];

          let atn = G.getData(state,{itemType:ANNOTATIONS,_id:selectedAtnId});

          if( 



          let indexOfKey = atnKeys.indexOf(selectedAtnId)

        }
      }*/
    }

    responses[key] && responses[key](shift);

  }

  useEffect(() => {
    window.addEventListener('resize',onResize);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('resize',onResize);
      window.removeEventListener('keyup',onKeyUp);
    };
  });

  let shouldDisplayQuant = Object.keys(quantificationList).length > 0;



  return (

    <QuantificationListContext.Provider value={{
      quantificationList, quantificationListDispatch
    }}>

      <div 
        style={{
          minWidth:0,
          //border:'3px solid blue',
          position:'relative',
          width:'100%',
        }}
        class="sg-row full-width full-height">

        <QuantificationFilesystemContainer /> 

        <div class="thin-vertical-line"/>

        <div 
          class="flex-expand"
          style={{
          //border:'3px solid red',
          position:'relative',
          
          //width:
          //maxWidth:'calc(100%-350px)',
        }}>


          {false && <div>{JSON.stringify(Object.fromEntries(Object.entries(quantificationList).map(entry => ([entry[0],Array.from(entry[1])]))))}</div>}


          {shouldDisplayQuant && (
            <CropListLaneAdjustmentContainer selectionInformation={quantificationList} quantificationListDispatch={quantificationListDispatch}/>
          )}
          {!shouldDisplayQuant && (
            <div class="full-height full-width" style={{
              display:'flex',
              position:'relative',
              //alignItems:'center',
              justifyContent:'center',
              //background:'blue'
            }}><h2 style={{marginTop:200, textAlign:'center'}}>To quantify, <br/>add crops from the <br/>explorer on the left.</h2>
            </div>
          )}
        </div>

      </div>
      {/*false && <div>
        <button onClick={()=>setError(true)}>Throw</button>
      </div>*/}

    </QuantificationListContext.Provider>

  )

}
