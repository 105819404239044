import { useState as reactUseState } from 'react';

export const useState = (initialValue,globalTraceableName) => {

  let hookReturnValues = reactUseState(initialValue);
  let [val, reactSetter] = hookReturnValues;

  let callerStack = Error().stack.substring(6).split("    at ");


  let patchedSetter = (newValue) => {
    let trace = Error().stack.substring(6).split("    at ");
    window.__hookTrace['useState'] = { 
      hookname:globalTraceableName, 
      hookInitStack:callerStack,
      value:(newValue||null),
      trace
    };
    reactSetter(newValue);
  }

  return [ val, patchedSetter ];




}
