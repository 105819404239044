import { useState } from 'react';
import usePresentSelector from './usePresentSelector';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';

const placeholders = {
  email:"Email address (so we can follow up)",
  name:'Full name',
  password:'Password',
  comment:'Your complaint, comment, request or feature request...'
}
const forms = {
  signin:{
    fields:['email','password'],
    action:"Login"
  },
  signup:{
    fields:['name','email','password'],
    action:'Sign up!',
  },
  forgot:{
    fields:['email'],
    action:'Recover',
  },
  comment:{
    fields:['comment'],
    action:'Send feedback!'
  }
}

const signupText = (
  <div>
    Thanks for the feedback!
    <br/><br/>
    We'll contact you if we have any questions or to let you know that your request has been resolved!
    <br/><br/>
    If you know others scientists who would also love this tool, they should sign up ASAP to get in on the next batch.

  </div>
)

const Form = ({scene,setScene,closeModal}) => {

  let dispatch = useDispatch();
  let [form,setForm] = useState({ email:'', comment:'' })
  let [feedbackText,setFeedbackText] = useState(null);
  let [succesfulSignup,setSuccessfulSignup] = useState(false);
  let [submitButtonEnabled,setSubmitButtonEnabled] =useState(true);

  let route = usePresentSelector(state => state.route || 'guest');

  if( succesfulSignup ){
    return signupText;
  }



  return (
    <div class="fair-margin-bottom" style={{textAlign:'center'}}>

      {feedbackText && <div style={{
        color:(feedbackText.type === 'success' ? 'green' :'red')
      }} class="bold">{feedbackText.text}</div>}

      {forms[scene].fields.map( field => {
        return (

          <div class="fair-margin">
            {field !== 'comment' && <input 
              style={{fontSize:11}}
              onChange={e=>setForm({
                ...form,
                [field]:e.target.value
              })}
              form="report-bug"
              formComponentType="field"
              field={field}
              required={true}
              autoComplete="new-password"
              class="form-control" type={field} placeholder={placeholders[field]} value={form[field]}/>}
            {field === 'comment' && <textarea placeholder={placeholders[field]}
              value={form[field]} style={{fontSize:11,width:'100%',height:150}}
              form="report-bug"
              formComponentType="field"
              field="comment"
              class={"form-control"}
              rows={10}
              onChange={e=>{setForm({
                ...form,
                [field]:e.target.value
              })
                setFeedbackText(null);
              }}
            />}
          </div>

        )
      })}

      <div class="fair-margin">
        <button disabled={!submitButtonEnabled} onClick={ () => {
          if( scene === 'comment' ){

            let validEmail = true; //form.email.trim().length > 5 && form.email.includes('@');
            let validMessage = form.comment.length > 10;
            if( !validEmail ){

              setFeedbackText({text:"Please provde a valid contact to follow up.", type:"fail"});


            }else if( !validMessage ){

              setFeedbackText({text:"Comment should be longer than 10 characters.", type:"fail"});

            }else{
              setSubmitButtonEnabled(false);
            dispatch(A.sendBugReport(form)).then(() => {
              setFeedbackText({text:"Submission success!", type:"success"});
              setTimeout(closeModal,1000);

            })
          }}
        }}
          form="report-bug"
          formComponentType="submit"
          class="form-control btn btn-primary bold">{forms[scene].action}</button>
      </div>
    </div>

  )

}



export default function SigninModal({closeModal}){
  const scene = 'comment'

  return (

    <Modal size="m" show={true} onHide={closeModal}>

      <Modal.Header style={{background:'#fff'}}>
        <Modal.Title>
          {forms[scene].action}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{background:'#f8f8f8'}}>
        <Form closeModal={closeModal} scene={scene}/>
      </Modal.Body>

    </Modal>

  )
}
