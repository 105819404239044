import C from './Constants';

import getFilesystemMetadataArgs from './getFilesystemMetadataArgs';

import { CREATION_DATE, LAST_EDITED_DATE, CREATOR_ID, PERMISSIONS } from './FieldConstants';

export default function DefaultMetadata(state,args){
  state = state || {};
  let userRelatedMetadata = {};
  let { userId } = state.loginInfo || {};

  if( state && userId ){
    userRelatedMetadata = {
      [PERMISSIONS]:{ [userId]:C.OWNER_PERMISSION },
      [CREATOR_ID]:userId,
    }
  }

  let extraArgsResult = getFilesystemMetadataArgs(state,args);
  let extraArgs = extraArgsResult.metaArgs;
  let error = extraArgsResult.error;

  if( error ){
    return {
      error
    }
  }
  
  //alldef({userId});
  let curDate = Number(Date.now());
    
  let metadata = {
    _id:args._id,
    ...userRelatedMetadata,
    [CREATION_DATE]:curDate,
    [LAST_EDITED_DATE]:curDate,
    requiredAsByRole:{},
    requirementsByRole:{},
    ...extraArgs
  }

  return { meta: metadata };



}
