import { useState, useEffect, useRef } from 'react';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import UpIcon from '@material-ui/icons/ArrowDropUp';

function ResizeBar({onColumnSizeChange, width, minWidth, columnIndex, renderedWidthRef}){

  const [ originalWidth, setOriginalWidth ] = useState(width);
  const [ mouseDownStart, setMouseDownStart ] = useState(null);


  const mouseUp = () => {
    setMouseDownStart(null);

  }
  const mouseMove = e => {

    if( mouseDownStart !== null ){

      let deltaWidth = -(mouseDownStart - e.clientX)
      let newWidth = originalWidth + deltaWidth; 

      if( minWidth && !isNaN(minWidth) ){
        if( newWidth >= minWidth ){
          onColumnSizeChange(columnIndex, newWidth);
        }
      }else{
        onColumnSizeChange(columnIndex, newWidth);
      }
    }
  }


  useEffect(() => {
    window.addEventListener('mouseup',mouseUp);
    window.addEventListener('mousemove',mouseMove);
    return () => {
      window.removeEventListener('mouseup',mouseUp);
      window.removeEventListener('mousemove',mouseMove);
    }
  })



  
  return (



    <div onMouseDown={e => {
      e.stopPropagation();
      e.preventDefault();
      setMouseDownStart(e.clientX);
      //let originalWidth = e.target.getBoundingClientRect().width;
      console.log("renderedWidthRefCurrent = " + renderedWidthRef.current);
      setOriginalWidth(renderedWidthRef.current);

    }}
      onClick={e => {
        //this makes sure that when you resize this column
        //it doesn't resort them on the column you click
        e.stopPropagation();
      }}
      style={{
      cursor:'ew-resize',
      position:'absolute',
      right:0,
      top:0,
      height:'100%',
      background:'#ccc',
      width:4
    }}/>

  )






}




function FilesystemColumnHeader({
  perscribedWidth,
  sortBy,
  setSortBy,
  onColumnSizeChange,
  iiCol,
  col,
}){

  const renderedWidthRef = useRef();

  let { property, display } = col;


  return (
    <div 
      filesystemHeader={display}
      style={{
      position:'relative',
      width:perscribedWidth,
      paddingLeft:5,
      textOverflow:'ellipsis',
      overflow:'hidden',

    }}
      ref={el => {
        if( el ){
          //console.log("RESIZING " + JSON.stringify(col));
          //console.log({perscribedWidth:, actualWidth:el.getBoundingClientRect().width})
          let renderedWidth = el.getBoundingClientRect().width;
          renderedWidthRef.current = renderedWidth;
        }
      }}

      onClick={() => {

        if( sortBy && sortBy.sortBy === property ){
          setSortBy({
            sortBy:property,
            descending:!sortBy.descending
          })
        }else{
          setSortBy({
            sortBy:property,
            descending:true
          })
        }


      }}

    >
      <div style={{
        width:'100%',
        alignItems:'center',
      }} class="sg-row">
        <div> {display} </div>
        <div class="flex-expand"/>
        {sortBy && sortBy.sortBy === property && (
          sortBy.descending ? <DownIcon style={{fontSize:18,paddingRight:5}}/> : <UpIcon style={{fontSize:18,paddingRight:5}}/>
        )}
      </div>

      <ResizeBar {...{
        columnIndex:iiCol,
        width:perscribedWidth,
        onColumnSizeChange,
        renderedWidthRef,
        minWidth:80
      }}
      />


      {false && <div style={{color:'red'}} class="abs-right">
        {iiCol+"; "+perscribedWidth}
      </div>}


    </div>
  )

}

const DEFAULT_COLUMNS = [
  { property:"filesystemName", display:"Name"},
  { property:"lastEditedDate", display:"Edited"},
  { property:"creationDate", display:"Created"}
]

export default function FilesystemHeaderRow({
  columnMinWidths,
  onColumnSizeChange, 
  setSortBy, 
  sortBy ,
  columns
}){

  return (
    <div class="sg-row" style={{
      background:'#eee',
      border:'1px solid #ccc'
      }}>
     


      {(DEFAULT_COLUMNS).map((col,iiCol) => {
        //let { property, display } = col;
        return <FilesystemColumnHeader {...{
          perscribedWidth:columnMinWidths[iiCol],
          sortBy,
          setSortBy,
          iiCol,
          col,
          onColumnSizeChange
        }}/>
      })}
    </div>
  )



}
