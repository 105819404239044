import helvetica from './helveticaGlyphWidths.json';

export function getTextWidth({text,fontSize}){

  if( text === null || text === undefined ){ throw Error("Missing text arg.") }
  if( !fontSize ){ throw Error("Missing font size.") }

  return Array.from(text).reduce((width,letter) => {
    if( !(letter in helvetica) ){
      throw Error("Cannot measure character '"+text+"'. Text will not be positioned correctly.");
    }
    return width + Number(helvetica[letter]) * fontSize;
  },0)
}
