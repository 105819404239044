import React from 'react';
import Modal from 'react-bootstrap/Modal';

function reactRender(elementTree){

  if( typeof(elementTree) === typeof('') ){
    return <>{elementTree}</>;
  }else if( Array.isArray(elementTree) ){
    return elementTree.map(reactRender);
  }else{
    if( !elementTree ){
      debugger;
    }
    let { tag, children } = elementTree;
    if( tag === 'br' ){
      return <br/>;
    }
    return React.createElement(tag,[],reactRender(children));
  }

}

export default function CustomModalBody({body}){

  return (
    <Modal.Body>
      {reactRender(body)}
    </Modal.Body>
  )
  

  
}
