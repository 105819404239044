import ToolbarItem from './ToolbarItem';

import HeightIcon from '@material-ui/icons/Height';
import BorderOuterIcon from '@material-ui/icons/BorderOuter';

import BorderAllIcon from '@material-ui/icons/BorderAll';
import BorderBottomIcon from '@material-ui/icons/BorderBottom';
import BorderLeftIcon from '@material-ui/icons/BorderLeft';
import BorderRightIcon from '@material-ui/icons/BorderRight';
import BorderTopIcon from '@material-ui/icons/BorderTop';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';

import LineWeightIcon from '@material-ui/icons/LineWeight';
import BorderColorIcon from '@material-ui/icons/BorderColor';



const fontSize = 18;
const verticalBorderToolbar = {
  subToolbarDirection:'vertical',
  subToolbar:[
    [{
      menuItem:"bottom-border-toggle",
      icon: <BorderBottomIcon style={{fontSize}}/>,
      styleProperty: 'borderBottom',
      valueToSet: '1px solid black',
      hint:'Bottom Border',
    },
      {
        icon:<HeightIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        menuItem:"bottom-border-length",
        hint:'Border % Length',
        type:'number',
        styleProperty:'bottomBorderWidth',
        activatesProperties:{borderBottom:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderBottom),
        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{fontSize}}/>,
        menuItem:"bottom-border-bracket-length",
        hint:'Bracket Length',
        type:'number',
        styleProperty:'bottomBracketHeight',
        activatesProperties:{borderBottom:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderBottom),
        scope:'local',
        defaultValue:0,
      }],
    [{
      icon: <BorderLeftIcon style={{fontSize}}/>,

      hint:'Left Border',

      styleProperty: 'borderLeft',
      valueToSet: '1px solid black'
    },
      {
        icon:<HeightIcon  style={{fontSize}}/>,

        hint:'Border % Length',

        type:'number',
        styleProperty:'leftBorderHeight',

        activatesProperties:{borderLeft:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderLeft),
        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        type:'number',

        hint:'Bracket Length',

        styleProperty:'leftBracketWidth',
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderLeft),

        activatesProperties:{borderLeft:'1px solid black'},

        scope:'local',
        defaultValue:0,
      }],

    [
      {
        icon: <BorderTopIcon style={{fontSize}}/>,
        hint:'Top Border',
        styleProperty: 'borderTop',
        valueToSet: '1px solid black'
      },
      {
        icon:<HeightIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        type:'number',
        styleProperty:'topBorderWidth',

        activatesProperties:{borderTop:'1px solid black'},

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderTop),

        scope:'local',
        defaultValue:100,
        hint:'Border % Length',
        minValue:0
      },

      {
        icon:<SpaceBarIcon style={{transform:'rotate(180deg)', fontSize}}/>,
        hint:'Bracket Length',
        type:'number',
        styleProperty:'topBracketHeight',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderTop),

        activatesProperties:{borderTop:'1px solid black'},
        scope:'local',
        defaultValue:0,
      }
    ],
    [

      {
        icon: <BorderRightIcon style={{fontSize}}/>,

        hint:'Right Border',

        styleProperty: 'borderRight',
        valueToSet: '1px solid black'
      },
      {
        icon:<HeightIcon  style={{fontSize}}/>,
        hint:'Border % Length',
        type:'number',
        styleProperty:'rightBorderHeight',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderRight),
        activatesProperties:{borderRight:'1px solid black'},

        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{transform:'rotate(-90deg)', fontSize}}/>,
        type:'number',
        hint:'Bracket Length',
        styleProperty:'rightBracketWidth',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderRight),
        activatesProperties:{borderRight:'1px solid black'},

        scope:'local',
        defaultValue:0,
      }
    ]
  ]
}

let borderPropertyToolbarItems = [
  {
    icon:<LineWeightIcon style={{fontSize}}/>,
    type:'number',
    hint:'Border Thickness',
    styleProperty:'borderThickness',
    scope:'local',
    defaultValue:1,
    id:'toolbar-item-line-weight',
    minValue:1,

  },

  {
    scope:'local',
    hint:'Border Color',
    icon:({borderColor}) => {
      return <div style={{alignItems:'center', justifyContent:'center',

      }} class="sg-col">

        <div style={{
          paddingTop:2,
          fontSize:16,
          fontWeight:'bold',
          lineHeight:0.7,
          //marginBottom:3,
        }}><BorderColorIcon style={{fontSize}}/></div>

        <div style={{
          marginTop:-3,
          position:'relative',
          width:18,height:5, 
          background:(borderColor||"#000000"),
          //borderRadius:3,
          border:'1px solid #aaa',
        }}/>
      </div>
    },
    subToolbar:[{
      scope:'local',
      valueToSet:'borderColor',
      //icon: <FormatUnderlinedIcon style={{fontSize}}/>,
      styleProperty:'borderColor',
      type:'color'
    }],
  }
]


export default function BorderToolbar(props){

  return (
    <div class="sg-row">
      <div>

      {verticalBorderToolbar.subToolbar.map(subToolbar => {
        return (
          <div class="sg-row v-center">
            {subToolbar.map(item => (
                <ToolbarItem {...{ ...props, item }}/>
            ))}
          </div>
        )
      })}
      </div>

      <div style={{
        marginTop:40,
      }} class="fair-margin-left sg-col">
      
    <ToolbarItem {...{
        ...props,
        item:borderPropertyToolbarItems[1]
      }}/>

    <ToolbarItem {...{
        ...props,
        item:borderPropertyToolbarItems[0]
      }}/>

      </div>

      
      

    
      
    </div>
  )
}
