import FigureScene from './FigureScene';
import G from './Getters';
import { FIGURE_PANELS } from './RecordTypes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import usePresentSelector from './usePresentSelector';
import NoFigureSelectedScene from './NoFigureSelectedScene';
import { FIGURE } from './UIConstants';
import FigureFilesystemContainer from './FigureFilesystemContainer';
import PanelTemplateView from './PanelTemplateView';

import { FilesystemStore } from './Contexts';

export default function FiguresAndPanels({
  setMode,

  selectedFigurePanelContext,
  setSelectedFigurePanelContext,

  svg,
  setSvg

}){


  let state = usePresentSelector(state => state);


  let dispatch = useDispatch();

  //check if the currently selected figure panel
  //is actually archived, if so, then unselected it

  let isTemplateSelected = usePresentSelector(state => {

    return selectedFigurePanelContext && G.isItemPanelTemplate(state,{_id:selectedFigurePanelContext.figurePanelId});
  })


  let [targetDirectoryOfNewFigurePanel,setTargetDirectoryOfNewFigurePanel] = useState();

  //newPanelFilesystemRootTarget: we're creating a panel, and the content is key name of the directory its to be stored in





  if( selectedFigurePanelContext && G.isArchived(state,{
    _id:selectedFigurePanelContext.figurePanelId,
    itemType:"figurePanels",
  }) ){
    setSelectedFigurePanelContext(null);
  }


  let selectedFigurePanelId = selectedFigurePanelContext?.figurePanelId;


  let view;
  if( targetDirectoryOfNewFigurePanel ){
    view = <NoFigureSelectedScene {...{
      targetDirectoryOfNewFigurePanel,
      setTargetDirectoryOfNewFigurePanel,
      setSelectedFigurePanelContext}}/>
  }else if( selectedFigurePanelId ){
    let viewArgs = {
      setSvg,
      svg,
      setMode,
      selectedFigurePanelContext,
      setSelectedFigurePanelContext
    }

    if( !isTemplateSelected ){
      view = <FigureScene {...viewArgs}/>
    }else{
      view = <PanelTemplateView key={selectedFigurePanelContext?.figurePanelId} {...viewArgs}/>
    }

    
    //view = <PanelsThumbnailView {...{setMode,setSelectedFigurePanelId:startEditingFigureWithId,setIsCreatingNewFigure}} />
  }else{
    view = (
      <div containerType={"sceneContainer"} 
        scene={"nothingSelected"}
      style={{
        flex:1,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        }}
        class="full-height">
        <div style={{
          fontSize:36,
          color:'#aaa',
          textAlign:'center',
        }}>
          Select a figure panel 
          <br/>
          on the left or 
          <br/>
          <a onClick={() => setTargetDirectoryOfNewFigurePanel("figurePanels")} href="#" class="link-primary">create a new one.</a>

        </div>
      </div>
    )
  }

  
  return (
    <div style={{
      height:'100%', 
      width:'100%',
      //border:'2px solid lime',
      position:'relative',
      //overflow:'hidden',
    }} class="sg-row">

      <FilesystemStore.Provider value={{
        isFilesystemItemSelected:(_id) => {
          return _id === selectedFigurePanelId
        },
        onFilesystemItemEvent:({_id,event}) => {

          let itemType = G.getItemType(state,_id);
          if( itemType === FIGURE_PANELS ){
            setSelectedFigurePanelContext({
              _id
            })
          }
        }


      }}>

        <FigureFilesystemContainer {...{
          filesystemContainer:"figurePanels",
          setTargetDirectoryOfNewFigurePanel,

          focusedFsItemId:selectedFigurePanelId,
          setSelectedFigurePanelContext,
        }}/>
      </FilesystemStore.Provider>


      {view}
    </div>
  )
}
