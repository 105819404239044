import { ContextMenuContext } from './Contexts';
import { useReducer, useState, useEffect } from 'react';
import ContextMenu from './ContextMenu';
import ChooseCropIcon from './ChooseCropIcon';
import CropIcon from '@material-ui/icons/Crop';

function ContextMenuReducer(state,action){
}

const menuItemToActionMap = {
  editRegion:"specifyExpansionNodeRegion",
}
const menuItemToTitleMap = {

  editRegion:(
    <div class="sg-row v-center">
      <CropIcon style={{color:'#666'}}/>
      <span style={{paddingLeft:5}}>Draw subregion</span>
    </div>
  ),
  setRegionVariable:(
    <div class="sg-row v-center">
      <ChooseCropIcon style={{color:'#666'}}/>
      <span style={{paddingLeft:5}}>Choose region</span>
    </div>
  )
}

export default function FigureEditorContextMenuLayer({
  children, 
  figurePanelId,
  selectedFigureItems,
  setSelectedCells,
  possibleActions,
  onEvent,
}){

  let selectedCells = selectedFigureItems.cells;

  let [contextMenuInfo, _setContextMenuInfo] = useState();
  let [ menuLocations, setMenuLocations ] = useState([]);

  const setContextMenuInfo = args => {
    _setContextMenuInfo(args);
    setMenuLocations([]);
  }

  let { location, items } = contextMenuInfo || {};



  let urgentActions = Object.entries(possibleActions||{}).filter(([key,value]) => value?.urgent)

  let urgentLocations;
  let holePaths;



  useEffect(() => {

    if( urgentActions.length > 0 ){
      let newMenuLocations = Array.from(document.querySelectorAll('.selected-item-cover')).map(r => {
        let domRect = r.getBoundingClientRect();
        let jsRect = { x:domRect.x, y:domRect.y, w:domRect.width, h:domRect.height };
        return jsRect;
      })

      if( JSON.stringify(newMenuLocations) !== JSON.stringify(menuLocations) ){
        setMenuLocations(newMenuLocations);
      }

      

    }else if( !location ){
      setMenuLocations([]);
    }
  },[selectedFigureItems]);

  

  if( urgentActions.length > 0 ){

    items = urgentActions.map(([key,value]) => {
      let type = (menuItemToActionMap[key] || key)
      return {
        type,
        action:key,
        title:(menuItemToTitleMap[key]||type),
        args:value,
        //key.slice(0,1).toUpperCase() + key.slice(1).replace(/[A-Z]/g, m => ' ' + m.toUpperCase())
      }
    })
    
  }


      


  if( contextMenuInfo ){
    //debugger;
  }


  let focusedElementsHole;



  let locis = [];
  if( location ){
    locis.push(location);
  }else{
    locis.push(...menuLocations);
  }


  const onContextMenu = selectedCells => e => {

    e.preventDefault();

    let path = e.composedPath();
    let contextMenuGridItem = path.find( comp => comp.id?.indexOf('grid') === 0 )

    let expansionNodeItem = path.find( comp => comp.nodeId );
    if( expansionNodeItem ){
      debugger;
    }




    if( contextMenuGridItem ){

      let [clickedY,clickedX] = contextMenuGridItem.id.split('-').slice(1).map(Number);



      let contextMenuOnSelectedCell = (selectedCells||[]).find(([cellY,cellX]) => {

        return ((cellY === clickedY) && (cellX === clickedX));

      })

      e.preventDefault();

      if(! contextMenuOnSelectedCell ){
        setSelectedCells([[clickedY,clickedX]])
      }

      setContextMenuInfo({location:{x:e.clientX,y:e.clientY}});

    }

  }



  useEffect(() => {
    let handleContextMenu = onContextMenu(selectedCells);
    window.addEventListener('contextmenu', handleContextMenu);
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    }
  },[selectedCells])



  return (
    <ContextMenuContext.Provider value={{contextMenuInfo,setContextMenuInfo}}>
      {children}
      {locis.length > 0 && (
        <>
          {false && <svg style={{
            position:'fixed',
            top:0,
            left:0,
            width:'100%',
            height:'100%',
            zIndex:100,

          }}>
    <mask id="circle-hole-mask">
        <rect x="0" y="0" width="100%" height="100%" fill="white" />
      {menuLocations.map(rect => {
        return <rect x={rect.x} y={rect.y} height={rect.h} width={rect.w} fill="black"/>
      })}

    </mask>

    <rect x="0" y="0" width="100%" height="100%" fill="black" opacity={0.3} mask="url(#circle-hole-mask)" />
</svg>}


          {false && <svg 
            //viewBox="0 0 100 100"

            style={{
            position:'fixed',
            top:0,
            left:0,
            width:'100%',
            height:'100%',
            zIndex:100,
            border:'3px solid blue',
            //background:'black',
            opacity:1,
          }}>
              <clipPath id="clip">
                <rect x="0" y="0" width="100" height="100" fill={"blue"}/>
              </clipPath>
            <rect id="back" x={0} y={0} height={"1000"} width={"1000"} fill={"orange"}/>
            {/*false && <path fillRule={"nonzero"} d={svgWrapPath} stroke={"red"} strokeWidth={5} fill={"red"}/>*/}
            <use clipPath="url(#clip)" href="#back" fill="yellow"/>
          </svg>}

          {locis.map(thisLocation => {
            return <ContextMenu {...{
              x:thisLocation.x,
              y:thisLocation.y,
              figurePanelId,

              items,

              selectedFigureItems,
              setSelectedCells,
              style:{ transform:`translate(calc(-50% + ${thisLocation.w/2}px),calc(-100% - 5px))` },

              onEvent,

              onClose:() => { 
                setContextMenuInfo(null)
              }
            }}/>})}


        </>

      )}
    </ContextMenuContext.Provider>
  )

}
