import G from './Getters';
import { isCLI } from './versionConfig';
import A from './ActionCreators';
import SciugoFetch, { isOnline, NoInternetConnectionResposne, NO_INTERNET } from './SciugoFetch';
import { getGlobalObject } from './versionConfig';

import injectTestSessionHeaders from './injectTestSessionHeaders';


import Dialog from './DialogConstants';

import ActionCreators from './ActionCreators';

function inStatusRange(int){
  let hundredsColumn = Math.floor(int/100);
  let lowest = hundredsColumn * 100;
}

const isSuccess = int => {
  return 200 <= int && int <= 299;
}

const isClientError = int => {
  return 400 <= int && int <= 499
}

const isServerError = int => {
  return 500 <= int && int <= 599;
}

const isOffline = int => {
  return int === NO_INTERNET
}

const isFetchError = res => {
  return !!(res && res.fetchError)
}

function handleResponse({
  onServerError,
}){

  return response => {


    let contentType = response.headers.get('content-type');



    if( contentType === 'application/json' ){

      return response.json().then(json => {

        /*if( !json ){
        }

        try{
          let x = json.serverError
        }catch(e){
        }*/
        

        if( json && json.serverError ){
          console.log("THERE WAS A SERVER ERROR!");
          onServerError(json);
        }

        //if we don't return a response,
        //then we'll get an error
        //because the request caller
        //will still try to deconstruct the response object
        response.json = json;

        return response;
      })
    }else if( contentType === 'binary/octet-stream' ){

      return new Promise((resolve,reject) => {
        return response.blob().then(blobData => {
          response.blobData = blobData;
          response.blobType = contentType;

          resolve( response )
        })
        //}
      })

    }else if(response.status === 204){
      return response;
    }else{

      console.warn("Content-type ("+contentType+") response is unhandled.");
      return response;
      
     }
  }
}



function getErrorArgs(args,dispatch){

  let onServerError = args.onServerError || ((errorJson) => {
    delete errorJson.type;
    let action = isCLI() ? A.unexpectedCliServerError(errorJson) : A.createDialog({
      dialogName:Dialog.UNEXPECTED_SERVER_ERROR,
      source:'getErrorArgs',
      args
    })
    dispatch(action);
  })

  return {
    onServerErrorMessage:args.onServerErrorMessage,
    onServerError,
    dispatch
  }
}

function validateDispatch(dispatch){
  if(!dispatch){
    throw Error("All requests require a dispatch arg to communicate potential server errors with state.");
  }
}

function getHeaders(state,args,method){
  let { routeOrigin } = args;
  if( !routeOrigin ){
    let headers = {};
    if( method === 'POST' ){
      headers = {
        'Content-Type':'application/json;charset=utf-8'
      }
    }

    if( isCLI() ){
      let cookie = G.getSessionCookie(state);
      headers.cookie = cookie;
    }

    /*if( method === "GET" ){
      return;
    }*/

    //let resolvedHeaders = injectTestSessionHeaders(headers);

    return headers;
  }

}

function getResolvedRoute(route,resolvedRouteOrigin){

  let fixedRoute = route;
  if( ! route.includes('localhost') ){
    fixedRoute = resolvedRouteOrigin+route
  }
  return fixedRoute;
}

function getResolvedBody(args){
  let { body, stringify } = args;
  if( stringify === false ){
    return body;
  }

  let stringified;
  try{
    stringified = JSON.stringify(body);
  }catch(e){
    debugger;
  }

  return stringified;

}

export function post(args,dispatch){

  validateDispatch(dispatch);
  args.dispatch = dispatch;

  let {route,body,state,routeOrigin,stringify} = args;


  let resolvedRouteOrigin = routeOrigin || (
    isCLI() ? 'http://localhost:80' : ''
  )

  let headers = getHeaders(state,args,"POST");
  let fixedRoute = getResolvedRoute(route,resolvedRouteOrigin);
  let resolvedBody = getResolvedBody(args);

  let fetchArgs = {
    method:'POST',
    mode:'no-cors',
    headers,
    body: resolvedBody
  }


  //args.testName = process.__testMeta.officialTestName; 
  return sendRequest(fixedRoute,fetchArgs,args,dispatch);

}

function validateCallbacks(requiredCallbacks){
  let undefinedCallback = Object.keys(requiredCallbacks).find(name => {
    return !requiredCallbacks[name];
  });
  if( undefinedCallback ){
    throw Error(`Required callback ${undefinedCallback} was undefined.`);
  }
}

const DEFAULT_INTERNET_FAILURE_CALLBACK = dispatch => (res,args) => {
  return dispatch(
    ActionCreators.createNoInternetDialog(args)
  )
}

const DEFAULT_FETCH_ERROR_CALLBACK = dispatch => (res,args) => {
  
  

  let errorHandler = getGlobalObject().__errorHandler 
  //console.log({errorHandler});
  if( errorHandler ){
    errorHandler(res.fetchError);
  }else{
    return dispatch(A.alertServer(res));
  }
  

}

async function sendRequest(route,requestArgs,args,dispatch){


  let { 
    skipGlobalResponseHandler,
    onSuccess, 
    onServerFailure, 
    onInternetFailure,
    onFetchError,
  } = args;

  onInternetFailure = onInternetFailure || DEFAULT_INTERNET_FAILURE_CALLBACK(dispatch);

  onFetchError = onFetchError || DEFAULT_FETCH_ERROR_CALLBACK(dispatch);

  let requiredCallbacks = {
    onSuccess,
    onServerFailure,
  }

  validateCallbacks(requiredCallbacks);

  if( process.env.NODE_ENV !== 'production'){
    if( isCLI() && ['80','9999','aws'].every(port => !route.includes(port) )){
      throw Error("Route ("+route+") does not included required ports.")

    }
  }
  try{


    let res = await new Promise(async (resolve,reject) => {

      let attemptsMade = 0;
      let shouldRetry = true;
      let fetchRes;

      while(shouldRetry){
        try{

          /*
          if( attemptsMade > 0 ){

          }
          */

          fetchRes = await SciugoFetch(route,requestArgs,args);


          attemptsMade++;
          shouldRetry = args.shouldRetry && args.shouldRetry(fetchRes,attemptsMade);

        }catch(e){
          if( !isOnline() ){
            console.log("We're offline!");
            shouldRetry = false;
            fetchRes = NoInternetConnectionResposne();;
          }else{
            //console.error("We've got an uncaught error:");

            if( console.red ){
              console.red(e);
            }else{
              console.error(e);
            }
            

            fetchRes = {
              route,
              fetchError:e
            }
            //console.log(fetchRes);
            shouldRetry = false;
            
          }
        }
      }
      resolve(fetchRes);
    })

    //console.log("We got here!");

    //console.log({res});

    let status = await res.status;



    let handledRes;
    if( skipGlobalResponseHandler ){
      handledRes = res;
    }else if( res && res.fetchError ){
      handledRes = res;
    }else{
      handledRes = await handleResponse(getErrorArgs(args,dispatch))(res);
    }


    //console.log({status});




    if( isSuccess(status) ){
      return Promise.resolve(onSuccess(handledRes));
    }else if( isClientError(status) || isServerError(status) ){
      return Promise.resolve(onServerFailure(handledRes));
    }else if( isOffline(status) ){
      return Promise.resolve(onInternetFailure(handledRes));
    }else if( isFetchError( res ) ){

      return Promise.resolve(onFetchError(handledRes));
    }else{
      throw Error("NONE OF THE REQUEST RESPONSE CONDITIONS APPLY!");
    }


    /*

    let contentType = await res.headers.get('content-type');
    if( (await res).status === 204 ){
      return (await res);
    }else{
      return handleResponse(getErrorArgs(args,dispatch))(res)
    }
    */

  }catch(e){

    console.log("PROBLEM WITH FETCH");
    console.error(e.message);
    throw e;

  }


}


export function get(args,dispatch){

  let {route,state,routeOrigin} = args;
  args.dispatch = dispatch;


  let resolvedRouteOrigin = routeOrigin || (
    isCLI() ? 'http://localhost:80' : ''
  )

  let fixedRoute = getResolvedRoute(route,resolvedRouteOrigin);
  let headers = getHeaders(state,args,"GET");

  let fetchArgs = {
    headers
  }

  return sendRequest(fixedRoute,fetchArgs,args,dispatch);


}
