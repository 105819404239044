import { useStore } from 'react-redux';
import { v4 } from 'uuid';
export default function TestFallback(props){
 
  let { resetAppKey, resetErrorBoundary, componentStack } = props;
  let store = useStore();
  let state = store.getState();


  return (
    <div style={{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      //background:'red',
      width:'100%',height:'100%'}}>
      <div>
      <div>
        <h3>Sciugo encountered an error.</h3>
      </div>

        {false && <div style={{color:'grey',marginTop:5,display:'flex',justifyContent:'center'}}>{v4()}</div>}
      <br/>
        <div style={{width:'100%',
          //background:'yellow',
          alignItems:'center', justifyContent:'center',display:'flex',}}>

          <button class="btn btn-primary" onClick={() => {
            resetAppKey()
            resetErrorBoundary()
          }}>Click here to go back!</button>

          
      </div>

        
        
      </div>
      
    </div>
  )
}
