export default function DrawOnBandsIconLarge(){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="106" height="92" viewBox="0 0 106 92" fill="none">
      <rect width="106" height="92" fill="white"/>
      <g filter="url(#filter0_f)">
        <rect x="9.9375" y="43.125" width="16.5625" height="5.75" rx="2.875" fill="black"/>
      </g>
      <g filter="url(#filter1_f)">
        <rect x="33.125" y="43.125" width="16.5625" height="5.75" rx="2.875" fill="black" fill-opacity="0.74"/>
      </g>
      <g filter="url(#filter2_f)">
        <path d="M56.3125 46C56.3125 44.4122 57.5997 43.125 59.1875 43.125H70C71.5878 43.125 72.875 44.4122 72.875 46V46C72.875 47.5878 71.5878 48.875 70 48.875H59.1875C57.5997 48.875 56.3125 47.5878 56.3125 46V46Z" fill="black" fill-opacity="0.49"/>
      </g>
      <g filter="url(#filter3_f)">
        <rect x="79.5" y="43.125" width="16.5625" height="5.75" rx="2.875" fill="black" fill-opacity="0.3"/>
      </g>
      <path d="M80.2071 46.7071C80.5976 46.3166 80.5976 45.6834 80.2071 45.2929L73.8431 38.9289C73.4526 38.5384 72.8195 38.5384 72.4289 38.9289C72.0384 39.3195 72.0384 39.9526 72.4289 40.3431L78.0858 46L72.4289 51.6569C72.0384 52.0474 72.0384 52.6805 72.4289 53.0711C72.8195 53.4616 73.4526 53.4616 73.8431 53.0711L80.2071 46.7071ZM6.625 47H79.5V45H6.625V47Z" fill="#1400FF"/>
      <rect x="7.625" y="38.375" width="90.75" height="15.25" stroke="#001AFF" stroke-width="2"/>
      <defs>
        <filter id="filter0_f" x="6.9375" y="40.125" width="22.5625" height="11.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter1_f" x="30.125" y="40.125" width="22.5625" height="11.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter2_f" x="53.3125" y="40.125" width="22.5625" height="11.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter3_f" x="76.5" y="40.125" width="22.5625" height="11.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </svg>
  )
}
