export default function BrowserSupportMessage({browserSupport}){

  return (
    <div class="fair-margin">
      <h4>Sciugo currently does not support {browserSupport.agent}.</h4>
      <p>Please use Google Chrome. If this is a problem, please email <a href="#">western.blot.figure.builder@gmail.com</a>
      </p>
    </div>
  )

}
