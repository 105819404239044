import usePresentSelector from './usePresentSelector';
import NoFigureSelectedScene from './NoFigureSelectedScene';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FigureEditorContainer from './FigureEditorContainer';
import CanvasFigure from './CanvasFigure';
import CropInsertionSelector from './CropInsertionSelector';
import G from './Getters';

export default function FigureScene({
  setSvg, 
  svg, 
  setMode, 
  showSearchBar, 
  showExportButton, 
  selectedFigurePanelContext,
  setSelectedFigurePanelContext
}){

  

  let figurePanelId = selectedFigurePanelContext.figurePanelId; 

  let isLoggedIn = useSelector(state => G.isLoggedIn(state));


  let selectedFigurePanelRootName = useSelector(state => !figurePanelId ? undefined : G.getFilesystemName(state, { _id:G.getAbsoluteFsPath(state,{_id:figurePanelId})[0]}));


  const [currentLoginStatus,setCurrentLoginStatus] = useState(isLoggedIn);

  useEffect(() => {

    if( isLoggedIn !== currentLoginStatus ){
      setSelectedFigurePanelContext(null);
      setCurrentLoginStatus(isLoggedIn);
    }

  },[isLoggedIn,figurePanelId,setSelectedFigurePanelContext,currentLoginStatus])



  const [cropIdsSelectedForInsertion,_setCropIdsSelectedForInsertion] = useState([]);
  function setCropIdsSelectedForInsertion(value){


    if( value?.length === 0 && cropIdsSelectedForInsertion?.length === 0 ){
      return;
    }
    _setCropIdsSelectedForInsertion(value||[]);
  }

  if( !figurePanelId ){
    return <NoFigureSelectedScene {...{setSelectedFigurePanelContext}} />
  }




  
  let cropInsertionSelector = showSearchBar !== false && !selectedFigurePanelRootName.includes("Templates") && <CropInsertionSelector {...{ cropIdsSelectedForInsertion, setCropIdsSelectedForInsertion, setMode }}/>
  
  


  return (

    <div 
      style={{
        flexGrow:1, 
        minWidth:0,
      }} id="figure-scene-container" class="sg-row full-height full-width">

      {cropInsertionSelector} 

      <div class="thin-vertical-line"></div>

      {selectedFigurePanelRootName?.includes("Templates") && <div style={{width:15}}>
        {""}
      </div>}
      

      <FigureEditorContainer {...{ 
        cropIdsBeingDragged:cropIdsSelectedForInsertion,
        setCropIdsBeingDragged:setCropIdsSelectedForInsertion,
        showExportButton,
        setSvg,
        svg,
        figurePanelId,
      }}/>

      {false && <CanvasFigure {...{figurePanelId}}/>}

    </div>


  )
}
