import { useDispatch } from 'react-redux';
import usePresentSelector from './usePresentSelector';
import { useEffect, useState } from 'react';
import A from './ActionCreators';
import G from './Getters';


import toolbarOrder, { keyboardShortcuts, toggleStyleProperties } from './toolbarOrder';
import ToolbarItem from './ToolbarItem';

const shortcuts = keyboardShortcuts();

export default function StyleToolbar({
  setRetainInputFocus,
  figurePanelId,
  selectedFigureItems,
  unifiedGroup,
}){


  if( !figurePanelId ){
    throw Error("StyleToolbar requires `figurePanelId`.");
  }
  const dispatch = useDispatch();


  

  let {
    editorStyle, panelGlobalStyle } = usePresentSelector(state => {
      return {
        editorStyle:state.editorStyle,
        panelGlobalStyle:G.getFigurePanel(state,{figurePanelId}).globalStyle
      }
    });

  let selectedCells = selectedFigureItems.cells || [];
  let selectedNodes = selectedFigureItems.expansionNodes;
  



  const shortcutHandler = e => {

    let ctrl = e.ctrlKey;
    let cmd = e.metaKey;

    let ctrlOrCmd = ctrl || cmd;

    if( !ctrlOrCmd ){
      return;
    }

    //console.log(e.key);

    let key = e.key;
    let item = shortcuts[ key ];
    if( item ){
      e.preventDefault();

      let somethingIsSelected = Object.values(selectedFigureItems).some(itemTypeList => itemTypeList.length > 0);
      if( somethingIsSelected ){

        let curValue = unifiedGroup.style[item.styleProperty];
        let toSet = item.valueToSet;

        debugger;

        let action = A.setCellsValue({ 
          style:{
            [item.styleProperty]:(
              curValue ? undefined : toSet
            )
          }, 
          figurePanelId,
          figureItems:selectedFigureItems,
        })
        dispatch(action);
      }

      /*
      toggleStyleProperties({
        dispatch,
        selectedFigureItems,
        item,
        figurePanelId
      })*/
    }
  }

  useEffect(() => {
    window.addEventListener('keydown',shortcutHandler);
    return () => window.removeEventListener('keydown',shortcutHandler);
  })



  const toolbarItemClass = (property,targetValue) => {


    let currentValue;
    if( selectedCells.length > 0 ){
      currentValue = unifiedGroup.style[ property ] || unifiedGroup[ property ];
    }else{
      currentValue = editorStyle[property];
    }




    let matchesTarget = currentValue === targetValue;

    return "hoverable " + (matchesTarget?"selected":"");

  }



  let toolbarComponents = toolbarOrder.map( (item,iiItem) => {
    return (
      <ToolbarItem {
        ...{item,selectedFigureItems,editorStyle,unifiedGroup, figurePanelId,
          panelGlobalStyle,
          className:toolbarItemClass(item.styleProperty,item.valueToSet),
          setRetainInputFocus
        }
        }/>

    )
  })

  return (
    <>
      {/*false && <div>{JSON.stringify(unifiedGroup)}</div>*/}
    <div class="style-toolbar sg-row">
      {toolbarComponents}
    </div>
    </>
  )

}


