import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import Tooltip from '@material-ui/core/Tooltip';
import { toggleStyleProperties } from './toolbarOrder';

export default function ToggleToolbarItem({
  item, 
  className, 
  selectedFigureItems, 
  unifiedGroup,
  figurePanelId
}){

  const dispatch = useDispatch();

  let styleProperty = item.styleProperty;
  let valueToSet = item.valueToSet;
  let currentValue = (unifiedGroup?.style||{})[ styleProperty ];

  let selected = valueToSet === currentValue ? "selected" : "";

  if(item.valueToSet === 'bold'){
  }

  


  return (
    <div 
      onClick={e => {

        dispatch(A.setCellsValue({
          figureItems:selectedFigureItems,
          figurePanelId,
          style:{
            [item.styleProperty]:(
              currentValue ? null : valueToSet
            )
          }
          
        }))

        //toggleStyleProperties({dispatch,selectedFigureItems,item, figurePanelId})
      }}
      style={{
        padding:'4px',borderRadius:'5px'
      }}
      class={selected+" hoverable toolbar-button"}
    >

      {item.hint && item.icon && <Tooltip title={item.hint}>
          {item.icon}
          </Tooltip>}
      {!item.hint && item.icon}

    </div>
  )
}
