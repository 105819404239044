export default function DeleteButton({cx,cy,r,fontSize,onClick}){
  
  return (
    <>
      <circle onClick={onClick} class="svg-delete" stroke={"black"} strokeWidth={1} cx={cx} cy={cy} r={r}/>
      <text style={{fontSize,pointerEvents:'none'}} textAnchor={"middle"} dominantBaseline={"middle"} x={cx} y={cy}>×</text>
    </>
  )

}
