export default function ComingSoon(){
  return (
    <div style={{background:'#eee', textAlign:'center',
      paddingTop:'30%',
      }}
      class="full-width full-height">
      <h3>
        Coming soon!
      </h3>
    </div>
  )
}
