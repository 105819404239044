import { unitPerp, add, scale, vectorAngleRad, minus, distance, pointProjectionOntoLine, dot } from './Geometry';

import { rotatePointsAboutCentroid } from './Geometry';

import { isDeleteObjectKey } from './EventUtils';

import usePresentSelector from './usePresentSelector';
import DeleteAnnotationButton from './DeleteAnnotationButton';
import G from './Getters';
import LaneBox from './LaneBox';
import { useContext, useState, useEffect } from 'react';

import SvgTrace from './SvgTrace';
import AdjustableLaneLine from './AdjustableLaneLine';
import { getCursorPerpendicularToLs } from './UIUtils';

import { useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';
import { HEIGHT_GROUP_LABEL_INPUT } from './UIConstants';
import { getPointsFromLs, pointsAreEqual, vectorAngleDegrees } from './Geometry';

import { AnnotationStyleContext } from './Contexts';

const __custom = "__custom";

export default function LineSegmentBox({
  imageSetHeight,
  imageViewTransformation,

  annotation,
  annotationId,
  imageContainerBounds,
  focusedAnnotationId,
  setFocusedAnnotationId,
  focusedAnnotation,
  temporaryAnnotationProperties,
  onMouseEnter,
  onMouseLeave,
  w,
  fill,
  stroke,
  strokeWidth,
  strokeOpacity,
  onDelete,
  interactive,
  height}){

  const dispatch = useDispatch();

  const annotationStyleContext = useContext(AnnotationStyleContext);

  const onKeyPress = e => {
    if( annotationId === focusedAnnotationId ){
      if( isDeleteObjectKey(e) ){

        let { activeElement } = document;
        let { tagName } = activeElement || {};

        if( tagName !== 'INPUT' ){
          onDelete(annotationId);
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown',onKeyPress);
    return () => {
      window.removeEventListener('keydown',onKeyPress);
    }
  })



  let ls = temporaryAnnotationProperties?.ls || focusedAnnotation?.ls;




  let focusedScaledLs = ls?.map(scale(w));

  let selectedFigureBand = usePresentSelector(state => state.selectedFigureBand);

  //USED TO LOOK VISUALIZE LINE-CREATION ALGORITHM
  //let annotationLines = usePresentSelector(state => G.getLinesOfPixelPositions(state,{annotationId,_id:annotationId}));

  let showTitle = interactive === false && (selectedFigureBand === focusedAnnotation.label || focusedAnnotation.label === 'Actin');

  if( interactive === false && focusedAnnotation.label !== selectedFigureBand ){
    stroke = 'gray';
  }

  if( interactive === false && focusedAnnotation.label === 'Actin' && selectedFigureBand !== 'Actin' ){
    stroke='red';
  }



  showTitle = (interactive === false);

  let [ draggingLineSegment, setDraggingLineSegment ] = useState([]);
  let lineSegmentShiftVec = [0,0];

  const [draggedItem,setDraggedItem] = useState(null);
  let [ isDragging, setIsDragging ] = useState(null);
  const [ mousePos, setMousePos ] = useState(false);

  let lsToRender = focusedScaledLs;

  if( isDragging ){
    let indicesToShift = ({
      rect:[0,1],
      rotate0:[0],
      rotate1:[1]
    })[draggedItem] || [];


    lsToRender = focusedScaledLs.map((point,ii) => {
      if( indicesToShift.includes(ii) ){
        return add(point, minus(mousePos , isDragging))
      }else{
        return point
      }
    })


  }

  let points = getPointsFromLs(lsToRender,height);


  if( draggingLineSegment.length > 0 && isDragging ){
    let mouseInImageCords = [mousePos[0] - imageContainerBounds.left,mousePos[1] - imageContainerBounds.top];

    let projPoint = pointProjectionOntoLine(mouseInImageCords,draggingLineSegment.map(index => points[index]));

    lineSegmentShiftVec = minus(mouseInImageCords,projPoint);
  }

  draggingLineSegment.forEach(pointIndex => {
    points[pointIndex] = add(points[pointIndex],lineSegmentShiftVec);
  })


  let contextInteractive = annotationStyleContext?.stylingByAnnotationId?.[annotationId]?.interactive ?? annotationStyleContext?.defaultStyling?.interactive;


  




  let linePointerEvents = contextInteractive === false ? "none" : "auto";

  let lineClass = contextInteractive === false ? "" : "thick-on-hover";



  let lines = points.map((p,ii) => {
    let x = p;
    let y = points[(ii+1)%4];
    let cursor = contextInteractive === false ? "not-allowed" :
      getCursorPerpendicularToLs([x,y]);


    
    let stroke = (
      annotationStyleContext?.stylingByAnnotationId?.[annotationId]?.color || annotationStyleContext?.defaultStyling?.stroke
    ) || "royalblue";

    let resolvedStrokeWidth = (

      annotationStyleContext?.stylingByAnnotationId?.[annotationId]?.width || annotationStyleContext?.defaultStyling?.strokeWidth
    ) || strokeWidth;

    if( stroke === "red" ){
      debugger;
    }

    

    






    return (
      <line
        class={lineClass}
        {...{
          x1:p[0],
          y1:p[1],
          x2:y[0],
          y2:y[1],
        }}
        onMouseDown={e => {
          if( contextInteractive === false ){
            return;
          }
          setDraggingLineSegment([ii,(ii+1)%4]);
          setIsDragging([e.clientX,e.clientY]);
          setMousePos([e.clientX,e.clientY]);
        }}
        style={{
          cursor, 
          //pointerEvents:linePointerEvents
        }}
        fillOpacity={1 || fill ? 0.2 : 0}
        stroke={stroke}
        strokeWidth={resolvedStrokeWidth}
        strokeOpacity={1}
      />
    )
  })

  let pathD = "M " + points.map(p => p.join(" ")).join(" L ") + " Z";


  const onMouseUp = () => {

    

    if( isDragging && !pointsAreEqual(isDragging,mousePos) ){

      let newHeight = distance(minus(points[0],points[3]))/w;

      let unOrderedNewLs = [
        scale(0.5/w,add(points[1],points[2])),
        scale(0.5/w,add(points[0],points[3])),
      ]

      


      let anchorCoord = draggedItem === 'rotate0' ? 1 : 0;
      let unchangedPoint = annotation.ls[ anchorCoord ];
      let distAnchorToNew0 = distance(unOrderedNewLs[0], unchangedPoint);
      let distAnchorToNew1 = distance(unOrderedNewLs[1], unchangedPoint);

      //put the point that didn't move in it's proper place.

      let newLs = [null,null];

      let angleDiff;

      if( distAnchorToNew0 < distAnchorToNew1 ){
        //new 0 is the anchor
        newLs[ anchorCoord ] = unOrderedNewLs[0];
        newLs[ Number(!anchorCoord) ] = unOrderedNewLs[1];

      }else{

        newLs[ anchorCoord ] = unOrderedNewLs[1];
        newLs[ Number(!anchorCoord) ] = unOrderedNewLs[0];

      }


      let dotted = dot(minus(...newLs), minus(...annotation.ls));
      console.log({dotted});

      




      let oldAngle = vectorAngleDegrees(minus(...annotation.ls));
      let newAngle = vectorAngleDegrees(minus(...newLs));

      //let angleDiff = Math.abs(oldAngle - newAngle);

      //console.log({angleDiff});

      if( dotted < 0 ){

        newLs.sort((a,b) => {

          let xComp = a[0] - b[0];
          //if x coord is the same
          if( xComp === 0 ){

            //sort by large y coord
            return b[1] - a[1]
          }else{
            return xComp;
          }

        })
      }


     let transformedGeometry = { 
       ls:newLs, height:newHeight 
     }
     
      let absoluteAtnGeometry = G.getAbsoluteLsGeometry(null, { 
        transformation:imageViewTransformation, 
        transformedGeometry,
        imageSetHeight
      });


      dispatch(A.setAnnotationProperties(
        {_id:annotationId,...absoluteAtnGeometry}
      ));

    }


    

    setIsDragging(null);
    setMousePos(false);
    setDraggedItem(null);
    setDraggingLineSegment([]);

  }

  const onMouseMove = e => {
    if(isDragging){
      setMousePos([e.clientX,e.clientY])
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup',onMouseUp);
    window.addEventListener('mousemove',onMouseMove);

    return () => {
      window.removeEventListener('mouseup',onMouseUp);
      window.removeEventListener('mousemove',onMouseMove);

    }
  })

  const onMouseDown = draggedItem => e => {
    setIsDragging([e.clientX,e.clientY]); 
    setMousePos([e.clientX,e.clientY])
    setDraggedItem(draggedItem);
  }

  let defaultBoxPathArgs = {
    stroke,
    strokeWidth,
    fill:'rgba(0,0,0,0)',
    strokeOpacity:0.5
  }
  let resolvedBoxPathArgs;

  let stylingByAnnotationId = {};
  if( annotationStyleContext ){

    stylingByAnnotationId = annotationStyleContext.stylingByAnnotationId || {};

    let resolvedStroke = (
      stylingByAnnotationId?.[annotationId]?.color || annotationStyleContext?.defaultStyling?.stroke
    ) || "royalblue";

  let resolvedStrokeWidth = (

      stylingByAnnotationId?.[annotationId]?.width || annotationStyleContext?.defaultStyling?.strokeWidth
    ) || strokeWidth;


    let resolvedFill = stylingByAnnotationId?.[annotationId]?.fill || annotationStyleContext?.defaultStyling?.fill || "transparent";


   let resolvedStyle = ( stylingByAnnotationId?.[annotationId]?.strokeDasharray || annotationStyleContext?.defaultStyling?.strokeDasharray
    ) || "0 0";

    resolvedBoxPathArgs = {
      stroke:resolvedStroke,
      strokeWidth:resolvedStrokeWidth,
      strokeOpacity:1,
      strokeDasharray:resolvedStyle,
      fill:resolvedFill,
    }



  }



  


  let boxPath = (
    /*
     * Yes, it's true that as
     * the width of this box increases,
     * it will increase on both inside and outside.
     * So if we wanted to increase just from outside,
     * we couldn't do it like this.
     * Here's a potential solution for making outer strokes:
     * https://alexwlchan.net/2021/inner-outer-strokes-svg/
     * Check at the bottom of that and maybe just copy/change 
     * the code.
     *
    */

    <g>

              <path 
          
      onContextMenu={e => e.preventDefault()}
      objectType={"annotationCropBox"}
      annotationId={annotationId}
      id={"line-segment-enclosing-box"}
      onMouseDown={(e) => {
        //alert("HELLO?");
        if( contextInteractive === false ){ return; }
        onMouseDown('rect')(e)
      }}
      onClick={(e) => {
        debugger;
        //if( contextInteractive === false ){ return; }
        setFocusedAnnotationId(annotationId,e)
      }}
      class={"grabbable thick-on-hover"}
      {...{d:pathD,

        fill:"transparent",
        ...(resolvedBoxPathArgs||defaultBoxPathArgs),


        shapeRendering:"geometricPrecision",
        style:{
          //pointerEvents:linePointerEvents,
          cursor:contextInteractive === false ?? "not-allowed",
          ...(stylingByAnnotationId?.[annotationId] || {}),

          //pointerEvents:'none',
        }
      }}/>


    </g>
  )


  //let pixelDims = usePresentSelector(state => G.getImagePixelDimensionsByAnnotationId(state,{annotationId}));

  //let imagePixelWidth = pixelDims.width;
  //let imagePixelHeight = pixelDims.height;



  //let pixelsToMap = annotationLines.length && annotationLines[0]
 

  let circleOne = {
    cx:0.5 * (points[1][0] + points[2][0]),
    cy:0.5 * (points[1][1] + points[2][1])
  }

  let circlePoint2 = {
    cx:0.5 * (points[0][0] + points[3][0]),
    cy:0.5 * (points[0][1] + points[3][1]),
  }

  //console.log(circleOne);
  //console.log(circlePoint2);

  const rotateCircleRadius = 6;



  let shouldShowRotationCircles = true;
  let rotationCircles;

  if( annotationStyleContext ){
    let stylingByAnnotationId = annotationStyleContext.stylingByAnnotationId || {};
    let cropBoxes = stylingByAnnotationId?.[annotationId]?.cropBoxes || annotationStyleContext.cropBoxes
    if( cropBoxes ){
      let { rotationHandles } = cropBoxes;
      if( rotationHandles === false ){
        shouldShowRotationCircles = false;
      }
    }
  }

  if( shouldShowRotationCircles ){

    rotationCircles = [
      <circle style={{cursor:'pointer'}} onMouseDown={e => {
        onMouseDown('rotate1')(e)
        e.stopPropagation();

      }} 
        cx={circleOne.cx} 
        cy={circleOne.cy} 

        opacity={0.8}
        fill={"orange"} r={rotateCircleRadius}/>,



        <circle style={{cursor:'pointer'}} onMouseDown={e => {
          onMouseDown('rotate0')(e);
          e.stopPropagation();
        }} 

          cx={circlePoint2.cx}
          cy={circlePoint2.cy} 
          opacity={0.8}
          fill={"orange"} r={rotateCircleRadius}/>,

          <text key={Math.random()} pointerEvents={"none"} fontSize={9} textAnchor={"middle"} dominantBaseline={"middle"} x={circlePoint2.cx} y={circlePoint2.cy} fontWeight={"bold"}>
            L
          </text>,

          <text key={Math.random()} pointerEvents={"none"} fontWeight={"bold"} fontSize={9} textAnchor={"middle"} dominantBaseline={"middle"} x={circleOne.cx} y={circleOne.cy}>
            R
          </text>
    ]
  }



  return (

    <>

      {/*annotationLines && annotationLines.map(line => {
        let r = Math.round(Math.random() * 255);
        let g = Math.round(Math.random() * 255);
        let b = Math.round(Math.random() * 255);
        return line.map(pixel => {
        
        return (
          <rect {...{
            x:pixel[0]/imagePixelWidth*w,
            y:pixel[1]/imagePixelHeight*imageContainerBounds.height,
            width:imageContainerBounds.width/imagePixelWidth,
            height:imageContainerBounds.height/imagePixelHeight,
            stroke:`rgb(${r},${g},${b})`,
            strokeWidth:2,
            fill:`rgb(${r},${g},${b},0.5)`,


          }}/>
        )
        })
      })
      })*/}

      {/* <text x={circlePoint2.cx} y={circlePoint2.cy}>{JSON.stringify(crop)}</text>*/}

      {focusedScaledLs && (
        <g>
          
          {showTitle && <text fontSize={10} x={lsToRender[0][0]} y={lsToRender[0][1]-8} fill={stroke}>{focusedAnnotation.label}</text>}
          {false && interactive!==false && <DeleteAnnotationButton {...{
            cx:focusedScaledLs[1][0],
            cy:focusedScaledLs[1][1],
            r:7,
            annotationId:focusedAnnotation._id,
            onDelete
          }}/>}

        </g>
      )
      }
      {boxPath}
      {interactive !== false && lines}
      {rotationCircles}


      


    </>
  )
}
