
import RowAdder from './RowAdder';
import ManualAnnotationRow from './ManualAnnotationRow';
import A from './ActionCreators';
import CropDropRow from './CropDropRow';
import usePresentSelector from './usePresentSelector';
import { SCREENSHOT_IGNORE, MAIN_FIGURE_ID } from './UIConstants';
import G from './Getters';

import { useState, createContext } from 'react';

import InvisibleRowWhichMaintainsProperColumnWidths from './InvisibleRowWhichMaintainsProperColumnWidths';



export default function ManualAnnotationGridTable({
  editableTableRef,

  editable,

  dispatch,
  
  hideAddRow,
  verticalImageResizeTranslation,
  tableOutlineShowing,
  setCropIdsBeingDragged,
  cropIdsBeingDragged,
  setCellDragEntered,


  editorStyle,

  localDispatch,
  figurePanelId,
  setDemoSelected,
  demoSelected,
  angle,
  cellDragEntered,
  onCropDrop,
  makeCellSelection,
  isCellSelected,
  selectedCells,
  multiselect,
  isDraggingCrop,
  setCurrentWidths,
  setCellResizeInfo,
  setDontUnselectCells,
  temporaryCellValue,
  widths,
  currentWidths,
  setMouseIsDown,
  cellResizeInfo,


}){

  if( !editable && !isCellSelected ){
    isCellSelected = () => false;
  }


  let {gelBounds,rowArrangedGridData,computedWidths,panelGlobalStyle} = usePresentSelector(state =>{
    let gelBounds = G.getGelLaneSpan(state,figurePanelId)
    let rowArrangedGridData = G.getCompleteGridLayoutData(state,{figurePanelId});
    let computedWidths;
    let panelGlobalStyle = G.getFigurePanelGlobalStyle(state,{figurePanelId});


    if(!widths){
      computedWidths = G.getColumnsWidths(state,{figurePanelId})
    }
    return {
      gelBounds,
      rowArrangedGridData,
      computedWidths,
      panelGlobalStyle
    }
  })

  let rowSpacing = panelGlobalStyle?.rowSpacing || 0;

  if( !widths ){
    widths = computedWidths;
  }

  

  

  let [rows,cols] = usePresentSelector(state => G.getTableDimensions(state,figurePanelId));

  //let totalTableWidth = (currentWidths||widths).reduce((a,b) => a+b);

  return (
    <>

      

      
    <div style={{
      display:'flex',

      }}>
    <table
      style={{
        
        //display:'flex',
        tableLayout:'fixed',
        borderSpacing:'0px 0px',
        borderCollapse:'separate', 


        //background:'orange'


        //transform:'translate('+horizontalTranslationFromShrinkingACellFromItsLeft+'px,0)',

      }}
      class={"column-to-resize " + MAIN_FIGURE_ID}
      container={"figure-panel"}
      id="figure">
      <tbody>

        {editable!==false && !hideAddRow && <tr 
          class={""+SCREENSHOT_IGNORE}
          style={{transform:'translate(0px,'+verticalImageResizeTranslation?.y+'px)'}}> 

          <td style={{
            height:50, textAlign:'center',
            verticalAlign:'top',
          }}
            colSpan={cols+2}>
            <div class="sg-row">
              <div class="flex-expand"/>
                            <div class="flex-expand"/>
            </div>
          </td>

        </tr>}

        <tr 
          class={""+SCREENSHOT_IGNORE}
          style={{transform:'translate(0,'+verticalImageResizeTranslation?.y+'px)'}} >
          <td></td>
          {editable!==false &&
            Array(cols).fill("").map((_,ii) =>{
              return (
                <td style={{
                  textAlign:'center',position:'relative',
                  color:(tableOutlineShowing ?'#ccc' : 'white')}}>
                  {(gelBounds.start <= ii && ii <= gelBounds.end)
                    ? (ii-gelBounds.start+1) : ""
                  }
                  {ii > 0 && <div 
                    class={ii===5 ? 'place-to-add-column' : ''}
                    style={{top:-20,position:'absolute', height:25,zIndex:3,width:'100%',
                    }}>
                    {ii}
                  </div>}
                </td>
              )
            })
          }
          <td></td>
        </tr>



        {editable!==false && (<tr 
          class={""+SCREENSHOT_IGNORE}
          style={{transform:'translate(0,'+verticalImageResizeTranslation?.y+'px)'}}>
          <td></td>
          {
            Array(cols).fill("").map((_,ii) =>{
              return (
                <td style={{
                  textAlign:'center',position:'relative',
                  color:(tableOutlineShowing ?'#aaa' : 'white')}}>
                  {(gelBounds.start <= ii && ii <= gelBounds.end)
                    ? (ii-gelBounds.start+1) : ""
                  }


                  <div 
                    class={ii===5 ? 'place-to-add-column' : ''}
                    style={{left:-10,top:-25,position:'absolute', 
                      height:25,
                      zIndex:3,
                    }}>

                    <RowAdder {...{alwaysVisible:tableOutlineShowing}}
                      tooltip={"Add Column Here"}
                      tooltipPlacement={"top"}
                      figurePanelId={figurePanelId} level={'top'} columnIndex={ii}
                    />
                  </div>





                </td>
              )
            })
          }
          <td></td>
        </tr>)}

        {editable!==false && isDraggingCrop && (
          <>

            <CropDropRow 
              figurePanelId={figurePanelId}
              setCropIdsBeingDragged={setCropIdsBeingDragged}
              cropIdsBeingDragged={cropIdsBeingDragged}
              cellDragEntered={cellDragEntered}
              setCellDragEntered={setCellDragEntered}
              rowIndex={-1}/>
            <tr style={{height:rowSpacing}}></tr>
          </>
        )}

        {rowArrangedGridData.map( (row,rowIndex) => {

          return <ManualAnnotationRow
            {...{
              editableTableRef,

              rowSpacing, 

              editable,
              manualAnnotationGridDispatch:localDispatch,
              verticalImageResizeTranslation,
              figurePanelId,
              setDemoSelected,
              demoSelected,
              angle,
              row,
              rowIndex,
              cellDragEntered,
              setCellDragEntered,
              onCropDrop,
              makeCellSelection,
              isCellSelected,
              selectedCells,
              multiselect,
              isDraggingCrop,
              setCurrentWidths,
              setCellResizeInfo,
              setDontUnselectCells,
              tableOutlineShowing,
              temporaryCellValue,
              widths,

              cropIdsBeingDragged,
              setCropIdsBeingDragged,


              editorStyle,
              //getCellWidth,
              currentWidths,
              setMouseIsDown,
              cellResizeInfo,
              numRows:rowArrangedGridData.length,
            }}/>
        })}
        

        {true && <InvisibleRowWhichMaintainsProperColumnWidths {
          ...{ 
            editable,
            widths:(currentWidths||widths) }}/>}
        


      </tbody>
    </table> 
    </div>
    </>
  )

}
