import FigureScene from './FigureScene';
import { useState } from 'react';
import PanelPreview from './PanelPreview';

export default function PanelTemplateView(props){

  let { selectedFigurePanelContext } = props;
  let { figurePanelId, editing } = selectedFigurePanelContext;

  //const [ editingTemplate, setEditingTemplate ] = useState(Boolean(props.editingTemplate));

  if( editing ){
    return (
      <FigureScene {...props}/>
    )
  }else{
    return (
      <div containerType={"sceneContainer"} scene={"templatePreview"} style={{
        position:'relative',
        //border:'1px solid blue',
        background:'#f0f0f0',
        flex:1,
        justifyContent:'center',
        //alignItems:'center'
      }} class="sg-row">
        <div>
          <div style={{
            margin:30,
            justifyContent:'center'}} class="sg-row">
            <button action={"editTemplate"} onClick={() => {
              props.setSelectedFigurePanelContext({
                _id:figurePanelId,
                editing:true
              });
            }} class="btn btn-primary">
              Edit template
            </button>
          </div>
          <div style={{
            marginTop:50,
          }} class="sg-row">
          <PanelPreview {...{
            figurePanelId,
            width:800,
            editable:false,

          }}/>
          </div>
        </div>
      </div>

    )
  }
}
