import { isCLI } from './versionConfig';

const base64Mark = 'data:image/png;base64,';

export default async function SciugoBlob(data,type){
  if( isCLI() ){
    let Blob = require("buffer").Blob
    let blob = new Blob([data],{type});
    return blob;
  }else{
    if( typeof(data) === typeof('') && data.includes(base64Mark) ){
      //alert("Data includes base64 mark.");
      return await (await fetch(data)).blob()
    }else{
      return new Blob([data],{type})
    }
  }
}
