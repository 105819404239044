
import CropWrapper from './CropWrapper';
import { useRef, useEffect } from 'react';
import  {useDispatch} from 'react-redux';
import usePresentSelector from './usePresentSelector';
import WesternBlotAnnotationContainer from './WesternBlotAnnotationContainer';
import G from './Getters';
import A from './ActionCreators';
import AddWesternBlotButton from './AddWesternBlotButton';
import { useState } from 'react';
import { ANNOTATION_IMAGE_ID } from './UIConstants';
import PanelPreview from './PanelPreview';
import SampleLayoutChooserModal from './SampleLayoutChooserModal';

import WesternBlotExplorerSidebar from './WesternBlotExplorerSidebar';
import BarChartIcon from '@material-ui/icons/BarChart';

import useLocalReducer from './useLocalReducer';


import {distance} from './Geometry';

const WESTERN_BLOT_ORGANIZATION_FEATURE = false;


export default function CropPreviewContainer({
  atns,
  imageId,
  temporaryAnnotationProperties,
  notifyAnnotationPropertyChange,
  focusedAnnotationId,
  setFocusedAnnotationId,
  imgRef,imageSetId, temporaryAdjustments,labelEditable}){

  let dispatch = useDispatch();



  let [ localState, localDispatch ] = useLocalReducer({
    showQuantificationRegions:true
  })

  const [isChoosingSampleLayout,setIsChoosingSampleLayout] = useState(false);

  let [movingId, setMovingId] = useState();


  const [ quantifyingAnnotation, setQuantifyingAnnotations ] = useState(atns.length && atns[0]);

  let state = usePresentSelector(state => state);

  let westernBlots = usePresentSelector(state => G.getDataItems(state,"westernBlots"));

  let focusedAnnotation = state.data.annotations[focusedAnnotationId];

  let previewRef = useRef();
  const [ cropPreviewContainerWidth, setCropPreviewContainerWidth ] = useState();

  let annotationsNotInWesternBlots = WESTERN_BLOT_ORGANIZATION_FEATURE && atns.filter(atn => atn.ls && (!atn.links || !atn.links.find(link => link.type === 'westernBlot')));

  const onSelectAnnotation = atnId => setFocusedAnnotationId(atnId)

  const cropPreviewContainerRef = useRef();
  useEffect(() => {
    if( !cropPreviewContainerWidth && cropPreviewContainerRef.current ){
      setCropPreviewContainerWidth(cropPreviewContainerRef.current.getBoundingClientRect().width);
    }
  })

  return (
    <>

      <div ref={cropPreviewContainerRef} onClick={e => {

        focusedAnnotation && dispatch(A.setAnnotationProperties({
          _id:focusedAnnotation._id, 
          ...temporaryAnnotationProperties, 
        }))

        debugger;

        setFocusedAnnotationId(null);
      }}
        class="sg-col"
        style={{width:'100%',height:'100%',
          //background:'pink', 
          overflow:'hidden',
          padding:20}}>
        {false && <div>{"Width: " + cropPreviewContainerWidth}</div>}

        {WESTERN_BLOT_ORGANIZATION_FEATURE && <AddWesternBlotButton/>}

        {WESTERN_BLOT_ORGANIZATION_FEATURE && westernBlots.map((wb,iiWb) => {
          return <WesternBlotAnnotationContainer {
            ...{
              movingId,
              setMovingId,
              data:wb,
              focusedAnnotationId,
              onSelectAnnotation,
            }}/>
        })}   

        {WESTERN_BLOT_ORGANIZATION_FEATURE && <div style={{textAlign:'middle'}}>
          <div style={{marginTop:10,marginBottom:10,height:3,width:'100%', borderTop:'1p solid #ccc',background:'#ccc'}}/>
        </div>}

        {WESTERN_BLOT_ORGANIZATION_FEATURE && <WesternBlotAnnotationContainer {...{
          data:{ atns:annotationsNotInWesternBlots },
          onSelectAnnotation,
          focusedAnnotationId,
          setMovingId,
          movingId,
        }}/>}


        {false && <>
          <div class="full-width sg-row">
            <div style={{fontSize:24}}>Sample Layouts</div>
            <div class="flex-expand"/>
            <div><button onClick={() => setIsChoosingSampleLayout(true)}>Add Layout</button></div>
          </div>
          <div style={{fontSize:24}}>Loose Crops</div>
        </>}

        {false && <div class="small-margin">
          <input type="checkbox" checked={localState.showQuantificationRegions} onChange={e => localDispatch({showQuantificationRegions:e.target.checked})}/> Show quantification regions
        </div>}


        {true && <div style={{width:'100%', 
          display:'flex',
          flexWrap:'wrap', 
          //border:'1px solid blue',
        }}>
          {atns.filter(atn => atn.ls!==undefined).map( (atn,ii) => {
            let atnOverride = focusedAnnotationId === atn._id ? temporaryAnnotationProperties : {};
            let inlineCrop = G.getCropFromAnnotation(state,atn._id, atnOverride);

            let isFocusedAnnotation = focusedAnnotationId === atn._id;
            let className = isFocusedAnnotation ? 'selected' : undefined;

            let label;
            if( isFocusedAnnotation ){
              if( typeof(temporaryAnnotationProperties.label) !== 'string' ){
                label = atn.label;        
              }else{
                label = temporaryAnnotationProperties.label
              }
            }else{
              label=atn.label;
            }
            return (
              <div 
                
                onClick={e => {
                  e.stopPropagation();
                  setFocusedAnnotationId(atn._id)
                }}
                class={className} style={{width:'45%',margin:'1.5%',padding:5,borderRadius:3,border:'1px solid #eee',
                  display:'flex',
                  flexDirection:'column',
                  //background:'#ccc',
                  //justifyContent:'center'
                }}>
                <div class="sg-row">
                  <div annotationIndex={""+ii} info={"annotationLabel"} style={{fontSize:12}}>{label||"Unlabled target"}</div>
                  <div class="flex-expand"/>
                </div>
                <div style={{flex:1}}>
                  <CropWrapper 
                    htmlImageId={ANNOTATION_IMAGE_ID}
                    imgRef={previewRef} 
                    imageId={imageId}
                    inlineCrop={inlineCrop}/>
                </div>
              </div>
            )
          })}
        </div>}

        <div class="flex-expand"/>



        {false && <div>{JSON.stringify(Object.keys(state.data.sampleLayouts))}</div>}

        {false && cropPreviewContainerWidth &&
            Object.values(state.data.sampleLayouts).map(sl => {
              return (<>
                {false && <div>{JSON.stringify(sl)}</div>}
                <div style={{width:'45%',border:'1px solid black'}}>
                  <PanelPreview {...{figurePanelId:sl.figurePanelId,width:0.8*0.45* cropPreviewContainerWidth}}/>
                </div>
              </>)
            })}

        {false && isChoosingSampleLayout && <SampleLayoutChooserModal {...{closeModal:() => setIsChoosingSampleLayout(false)}}/>}

        {false && <div style={{
          borderRight:'1px solid #ccc',
          borderLeft:'1px solid #bbb',
          zIndex:1000,
          top:0,position:'absolute',height:'100%',left:-400,background:'white',padding:20,
          maxWidth:400
        }}>

          <WesternBlotExplorerSidebar width={cropPreviewContainerWidth}/>
        </div>}

      </div>
      

    </>
  )
}
