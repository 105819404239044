import G from './Getters';
import { useSelector } from 'react-redux';
import MicroscopyTemplateThumbnail from './MicroscopyTemplateThumbnail';

import HardCodedMicroscopyTemplateMap from './HardCodedMicroscopyTemplateMap';


export default function SuggestedMicroscopyTemplates({selectedTemplate, setSelectedTemplate}){



  /*let suggestedTemplates = useSelector(state => G.getSuggestedMicroscopyTemplates(state));
  */

  let localTemplates = HardCodedMicroscopyTemplateMap; 


  let basicExpansion = G.getTemplateExpansion(null,localTemplates.basic);

  let belowExpansion = G.getTemplateExpansion(null,localTemplates.below);


  return (
    <div class="sg-row flex-expand" style={{
      //border:'1px solid blue',
      minHeight:0,
      }}>
     
      <div style={{width:'25%'}}>
        {Object.keys(localTemplates).map(key => {
          let expansion = G.getTemplateExpansion(null,localTemplates[key]);
          return (
            <div onClick={() => setSelectedTemplate({key,data:localTemplates[key]})} class={`small-padding fair-margin round-corners ${selectedTemplate?.key === key ?"selected":"extra-dark-hoverable"}`} style={{
              width:'100%',
              background:'#fafafa',
              border:'1px solid #ccc',
            }}>
              <MicroscopyTemplateThumbnail template={expansion} templateId={key}/>
            </div>
          )
        })}
      </div>
      {selectedTemplate && <div style={{overflowY:'auto'}}>
        <pre style={{textAlign:'left'}}>
        {JSON.stringify(G.getTemplateExpansion(null,selectedTemplate.data),null,1)}
        </pre>
      </div>}
        
        

      {[].map(templateId => (
        <div onClick={() => setSelectedTemplate(templateId)}>
          <div style={{
            width:300,
            //height:300,
            border:'1px solid red',

          }}>
            <MicroscopyTemplateThumbnail templateId={templateId}/>
          </div>
        </div>
      ))}
    </div>
  )


}
