import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Tooltip from '@material-ui/core/Tooltip';

export default function FigurefilesystemAccessibilityButton({
  activeFilesystemRoot,
  setActiveFilesystemRoot,
  setShowFilesystem
}){
  return (

    <div style={{
    
      paddingTop:20,
      height:50,
      }} class="sg-row v-center small-padding">

          <div>
            {true && <select dropDown={"filesystemRootDirectorySelect"} value={activeFilesystemRoot} selected={activeFilesystemRoot} onChange={e => { 
              //alert("CHANGED: " + e.target.value);

              setActiveFilesystemRoot(e.target.value)

            }}  class="custom-select" style={{
              //width:120
            }}><option value={"figurePanels"}>Figures</option><option value={"figurePanelTemplates"}>Templates</option></select>}
          </div>


          <div 
            onClick={() => setShowFilesystem(false)}
            button="hide-filesystem-drawer" 
            class={
              "small-padding dark-hoverable round-corners"} style={{
                position:'absolute',

                // background:'#f4f4f4',


                display:'flex',
                borderRadius:25,
                alignItems:'center',
                justifyContent:'center',
                right:5,
              }}
          >


            <Tooltip title={"Hide Filesystem Window"}>
              <ArrowBackIosIcon style={{
                padding:0,
                margin:0,
                paddingLeft:5,
              }}/>

            </Tooltip>
          </div>


        </div>
  )
}
