import CropDisplay from './CropDisplay';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import G from './Getters';
import { useSelector } from 'react-redux';
/*

  This requires we only have one group of regions (i.e, regions from a single cell) are selected.

*/

export default function ImageRegionChoiceSubmenu(props){
  let {
    selectedFigureItems,
    data,
    onEvent,
    onChange,
    figurePanelId,
    args
  } = props;

  let templateArgs;
  let { expansionNodes, templateNodes } = selectedFigureItems;


  let cellLocation;
  let localTemplateId;


  if( expansionNodes ){

    let uniqLocs = Array.from(new Set(
      expansionNodes.map(node => JSON.stringify(node.cellLocation))
    ));

    if( uniqLocs.length === 1 ){
      cellLocation = JSON.parse(uniqLocs[0]);
    }



  }else if( templateNodes ){

    localTemplateId = Array.from(new Set(
      templateNodes.map(node => node.localTemplateId)
    ))[0];

    if( !localTemplateId ){
      throw Error("localTemplateId should be provided as selection arguments to template nodes.");
    }

  }



  // need to figure out what our region options are.
  let template = useSelector(state => G.getFigurePanelTemplate(state,{
    figurePanelId,
    localTemplateId,
    cellLocation
  }))

  let { regions } = template;
  let regionList = Object.keys(regions);

  let focusedRegionId = data?.value?.regionId;
  let selectedAnnotationId = regions[focusedRegionId];

    


  return (
    <div>
      {selectedAnnotationId && (

        <div style={{border:'1px solid #aaa', padding:10}} class="full-width">
          <div style={{textAlign:'center', width:'80%',border:'1px solid #ccc'}}>
            <CropDisplay annotationId={selectedAnnotationId}/>

          </div>

          <button onClick={(e) => {

            let args = {type:"specifyExpansionNodeRegion", targetAnnotationId:selectedAnnotationId, event:e}

             onEvent && onEvent(args)

          }}>Edit Subregion</button>
        </div>
      )}
      
      <button onClick={(e) => {
        onEvent && onEvent({
          key:"regionId",
          value:null,
          event:e
        })
      }}>
        Remove
      </button>

      <Select
        value={focusedRegionId}
        style={{
          padding:3,
          borderRadius:3,
          border:'1px solid #ccc',
          background:'white',
        }}
        onChange={e => {

          onEvent && onEvent({
            type:"change",
            //regionId:chosenRegionId,
            key:"regionId",
            value:e.target.value,
            event:e,
          })
        }}
      >
        {["main",...Object.keys(regionList)].map(value => {
          return (
            <MenuItem value={value}>
              <div>{value}</div>
            </MenuItem>
          )
        })}
      </Select>
    </div>
  );
}
