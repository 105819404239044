import FilesystemItem from './FilesystemItem';
import G from './Getters';
import A from './ActionCreators';

import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { ChildTypes } from './Filesystem';
import { useContext } from 'react';
import { FilesystemHierarchyContext } from './Contexts';

export default function FilesystemList({
  paddingLeft,
  treePath,
  filesystemContainer,
  itemType,
  _id,
  treeLevel,
  contextMenuInfo,
  setContextMenuInfo,
  setActivelyRenamingFilesystemItem,
  activelyRenamingFilesystemItem,
  parentFilesystemId,
  dropTargetId,
  setDropTargetId,
  sortInfo,
}){

  let dispatch = useDispatch();

  const filesystemHierarchyContext = useContext(FilesystemHierarchyContext);

  //let state = useSelector(state => state);

 
  let sortByObj = sortInfo || {};

  let {state, items} = useSelector(state => {
    let itemGetterFunction = filesystemHierarchyContext && filesystemHierarchyContext[itemType];
    if(true && itemGetterFunction){
      let hierarchyConfig = itemGetterFunction(state,_id)
      let items = hierarchyConfig.children;
      return {state,items};
    }else{ 
      return ({
        state,
        items:G.listDirectory(state,{
          itemType,
          _id,
          show:{
            filesystemName:1,
            //_id:1,
            lastEditedDate:1,
            creationDate:1,
          },
          ...sortByObj
        })
      })
    }
  });


  let isDropTarget = dropTargetId === _id;


  function distinctDropTargets(t1, t2){
    return JSON.stringify(t1) !== JSON.stringify(t2);
  }



  const [{ resolvedDropTargetId, canDrop, hover }, drop] 
    = useDrop( () => ({
      accept: ChildTypes[itemType],

      drop: (draggedItem,monitor) => {

        /*let resolvedDropTargetId = G.getDropTargetId(state,{
          draggedId:draggedItem._id,
          dropTargetId:dropTargetId
        })*/

        
        let dropNotYetHandled = !monitor.didDrop();

        /*
        console.error(JSON.stringify({
          dropTargetId,
          dropNotYetHandled
        }));
        */

        if(dropTargetId && dropNotYetHandled){

          
          dispatch(A.moveFilesystemItem({
            _id:draggedItem._id,
            itemType:draggedItem.type,
            to:dropTargetId
          }));
        }
      },
      collect: (monitor) => {
        
        let isOver = !!monitor.isOver({ shallow: true });
        if( monitor.didDrop() ){

          //alert("collect (didDrop()) (FsList)");
          return {
            resolvedDropTargetId:null
          }
        }

        let resolvedDropTargetId;
        if( !isOver ){
          //alert("collect && !isOver (FsList)");

          return {
            resolvedDropTargetId
          }
        }



        let draggedItem = monitor.getItem();


        if( draggedItem ){

          resolvedDropTargetId = G.getDropTargetId(state,{
            draggedId:draggedItem._id,
            dropTargetId:_id
          });

          if( distinctDropTargets(resolvedDropTargetId, dropTargetId) ){
            setDropTargetId && setDropTargetId(resolvedDropTargetId);
          }
        }
        return { 
          resolvedDropTargetId 
        };
        //canDrop: !!monitor.canDrop()
        //hover: !!monitor.hover()
      }
    }),
    [state,dropTargetId]
  );


  return (
    <div 
      treePath={treePath.length === 0 ? "root" : treePath.join('-')}
      class="flex-expand" 
      style={{
        position:'relative',
        height:(treePath.length === 0 ?'100%':'unset')
      }} 
      ref={drop}>

      {items.map((item,iiItem) => {
        return (
          <FilesystemItem 
            key={item._id}
            {...{
              paddingLeft,
              filesystemContainer,
              treePath:[...treePath,iiItem],
              treeLevel,
              item,
              contextMenuInfo,
              setContextMenuInfo,
              parentFilesystemId:_id,
              setActivelyRenamingFilesystemItem,
              activelyRenamingFilesystemItem,
              sortInfo,
              setDropTargetId,
              dropTargetId,


            }}/> 
        )
      })}
      {(isDropTarget) && treeLevel === 0 && <div 
        info="root-drag-hover-halo"
        style={{
        top:0,
        left:0,
        position:'absolute',
        pointerEvents:'none',
        boxSizing:'border-box',
        borderRadius:4,
        border:'2px solid deepskyblue',
        width:'100%',
        height:'100%',
        zIndex:30,
      }}/>}

    </div>
  )


}


