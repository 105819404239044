import usePresentSelector from './usePresentSelector';
import CropGridValue from './CropGridValue';
import CropWrapper from './CropWrapper'; 
import FigureText from './FigureText';
import FigureImageExpansionContainer from './FigureImageExpansionContainer';
import BandAnnotation from './BandAnnotation';
import G from './Getters';

export default function RenderedValue({

  editable,

  isAdjustingCropVertically,

  manualAnnotationGridDispatch,
  figurePanelId,
  value,width,expandImage,gridCellId,cellLocation,
  tdBounds, tdRef, cell, textBounds, rotatedRectHeight,
  tableOutlineShowing,
  verticalImageResizeTranslation,
  onCellInteractionEvent,
}){


  if(!figurePanelId){
    throw Error("figurePanelId cannot be undefined.");
  }

  let state = usePresentSelector(state=>state);

  if( value instanceof Object ){
    if( value.valueType === 'imageTemplate'){
      return <div style={{overflow:'scroll'}}>{JSON.stringify(value)}</div>
    }
    else if( value.valueType === 'crop' ){

      if( value.expandable ){

        return (
          <FigureImageExpansionContainer {...{value,figurePanelId, onEvent:onCellInteractionEvent, width, cellLocation }}/>
        )
      }


      return (
        <CropGridValue 
          key={"crop-"+value.annotationId}
          {...{
            editable,
            placementEditable:editable,
            tableOutlineShowing,
            manualAnnotationGridDispatch,
            value,
            width,
            gridCellId,
            expandImage,
            figurePanelId,
            cellLocation,
          }}/>
      );
    }
    else if( value.valueType === 'image' ){
      let cropId = value.cropId;
      return (
        <CropWrapper 
          key={gridCellId+'_'+cropId}
          htmlImageClipDivId={
            "grid-image-"+cellLocation[0]+"-"+cellLocation[1]
          }
          placementEditable={true}
          imageSelectable={false} 
          cropId={cropId} 
          width={width} 
          expandImage={expandImage}
          gridCellId={gridCellId}
        />
      )
    }else if( value.value !== undefined && value.value.valueType === 'bandAnnotation' ){
      if( !cellLocation ){
        throw Error("cell undefined");
      }
      if( isAdjustingCropVertically ){
        return null;
      }
      return <BandAnnotation {...{
        //key:Math.random(),
        verticalImageResizeTranslation,
        bandsEditable:false, 
        editable,
        value:value.value, 
        width, 
        cell:cellLocation, 
        style:value.style,
        figurePanelId,
      }}/>
    }else{
      return <div style={{color:'red'}}>{JSON.stringify(value)}</div>
    }
  }else if( typeof(value) === 'string' ){
    return <FigureText {...{
      textBounds,
      rotatedRectHeight,
      value,
      cell,
      cellLocation,
      tdBounds,
      tdRef
    }}/>

  }else{
    return (
      <div style={{color:'red'}}>
        {value}
      </div>
    )
  }
}

