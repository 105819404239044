import A from './ActionCreators';
import usePresentSelector from './usePresentSelector';
import { isDeleteObjectKey } from './EventUtils';
import SciugoInput from './SciugoInput';

import useComponentDrag from './useComponentDrag';

import G from './Getters';
import { add, minus } from './Geometry';
import {useEffect,useState,useRef} from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import ListItemIcon from "@material-ui/core/ListItemIcon";
//import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AddCircleIcon from '@material-ui/icons/AddCircle';



import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { shiftLineSegement } from './Geometry';

import { HEIGHT_GROUP_LABEL_INPUT } from './UIConstants';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(0)
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }
}));





export default function SimplePopover({
  w,
  onMouseEnter,
  onMouseLeave,
  setFocusedAnnotationId,
  focusedAnnotation,
  isFocusedAnnotation,
  annotation,
  notifyAnnotationPropertyChange,
  temporaryAnnotationProperties,

  setHoveredHeight,
  handleClose,

  anchorPosition,
  setAnchorPosition,

  onAnnotationDelete,

}){

  let popoverRef = useRef();

  let ls = temporaryAnnotationProperties.ls || focusedAnnotation.ls;

  let onKeyUp = key => {

    notifyAnnotationPropertyChange({
      _id:focusedAnnotation._id,
      ls:shiftLineSegement(ls,key)
    })

  }

  let laneCount = usePresentSelector(state => G.getAnnotationLaneCount(state,{_id:focusedAnnotation._id}));

  let alreadyFocusedOnLoad = useRef(false);

  let selectedHeightGroupId = focusedAnnotation.heightGroupId;

  let __custom = "__custom";

  let heightGroupList = usePresentSelector(G.getOrderedHeightGroups);

  const [mouseDown,setMouseDown] = useState(null);


  let dispatch = useDispatch();
  const classes = useStyles();
  //const [anchorPosition, setAnchorPosition] = React.useState(null);

  const handleClick = (event) => {
    //setAnchorEl(event.currentTarget);
  };

  const [newHeightGroupLabel,setNewHeightGroupLabel] = useState("");


 

  const open = Boolean(anchorPosition);
  const id = open ? "simple-popover" : undefined;

  let fontSize = 10;

  const [isAddingHeightGroup,setIsAddingHeightGroup] = useState(false);

  let customHeight = Number(temporaryAnnotationProperties.height * w || focusedAnnotation.height * w || 30).toFixed(0);

  let targetName;
  if( typeof(temporaryAnnotationProperties.label) !== 'string' ){
    targetName = focusedAnnotation.label
  }else{
    targetName = temporaryAnnotationProperties.label;
  };

  function onMouseMoveAndDown(e){

    if( mouseDown ){

      let popoverPositionToSet = minus([e.clientX,e.clientY],mouseDown);
      let xx = {
        left:popoverPositionToSet[0],
        top:popoverPositionToSet[1]
      }
      //console.log(JSON.stringify(xx));
      setAnchorPosition(xx);
    }

  }

  let onMouseDown = (e) => {
    if( popoverRef && popoverRef.current ){
      let popoverBounds = popoverRef.current.getBoundingClientRect();
      let popoverPosition =[
        e.clientX-popoverBounds.left,
        e.clientY-popoverBounds.top
      ];
      setMouseDown(popoverPosition);
    }
  }


  let onComponentMouseDown = useComponentDrag({
    ref:popoverRef,
    onMouseMoveAndDown,
    onMouseUp:(() => { setMouseDown(false); }),
    onMouseDown
  })

  return (
    <>
      


      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ 
          zIndex:50,
          position:'fixed',
          ...anchorPosition}}
        anchorReference="anchorPosition"
        id={id}
        open={open}
        anchorPosition={anchorPosition}
        onClose={handleClose}
      >

        {false && mouseDown && <div style={{
          border:'1px solid orange',
          width:mouseDown[0],
          height:mouseDown[1],
          position:'absolute',top:0,left:0}}/>}

        <div onClick={e => {
          e.stopPropagation();

          dispatch(A.setAnnotationProperties({
            _id:focusedAnnotation._id, 
            ...temporaryAnnotationProperties,

          }))
          handleClose();

        }}
          class="hoverable" style={{position:'absolute', 
            width:15, height:15, 
            top:-7.5,
            right:-7.5,
            borderRadius:15,
            cursor:'pointer',
            lineHeight:1,
            fontSize:13,
            fontWeight:'bold',
            textAlign:'center',
            background:'#eeeeee',
            color:'black',
            zIndex:3,
          }}>
          ×
        </div>

        <Typography className={classes.typography}>


          <div 
            style={{ 
              borderRadius:4,    
              boxShadow:'0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            }}
            onMouseLeave={() => setHoveredHeight(null)} 
            className={classes.root}>

            <div 
              ref={popoverRef}
              onMouseDown={onComponentMouseDown}

              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={{
                width:'100%',height:10,
                cursor:(mouseDown?'grabbing':'grab'), 
                zIndex:2,
                borderRadius:'2px 2px 0px 0px',
                background:'rgba(222,225,231)',
                position:'absolute',
                borderBottom:'1px solid rgba(190,190,190)'
              }}/>


            <List style={{fontSize}}>

              <ListItem style={{padding:7}}>

                <SciugoInput 
                  input={"crop-label-field"}
                  inputRef={el => {
                    if(!targetName && el && alreadyFocusedOnLoad.current === false){
                      alreadyFocusedOnLoad.current = true;
                      el.focus()
                    }
                  }}

                  onKeyUp={e => {


                    if(e.key === 'Enter' && targetName.trim() !== ''){


                      dispatch(A.setAnnotationProperties({
                        _id:focusedAnnotation._id, 
                        ...temporaryAnnotationProperties,
                        label:e.target.value
                      }))
                      handleClose();
                    }
                  }}

                  onKeyDown={e => {

                    //console.error("Typing something! " + e.key);

                    if( isDeleteObjectKey(e) && targetName === '' ){
                      onAnnotationDelete(focusedAnnotation._id);
                    }
                  }}


                  value={targetName}
                  onBlur={e => {
                    dispatch(A.setAnnotationProperties({
                      _id:focusedAnnotation._id, 
                      ...temporaryAnnotationProperties,
                      label:e.target.value,

                    }))
                  }}
                  onChange={e => {
                    notifyAnnotationPropertyChange({label:e.target.value})
                  }}
                  onClick={e => e.stopPropagation()}
                  style={{
                    margin:0,fontSize:10, width:150,opacity:0.95}} 
                  placeholder={"Protein name"}/>



              </ListItem>
              <Divider/>
              <ListItem 
                button={selectedHeightGroupId!==__custom}
                onMouseEnter={() => {
                  setHoveredHeight(customHeight)
                }}
                selected={selectedHeightGroupId===__custom}
                onClick={() => {
                  dispatch(A.setAnnotationProperties({
                    _id:focusedAnnotation._id,
                    heightGroupId:__custom,
                    height:(customHeight/w)

                  }))
                }} style={{padding:7}}>
                Height: 

                <div class="flex-expand"/>
                <input 
                  style={{width:40, marginLeft:10, fontSize}} type="number" value={customHeight} onChange={e =>{ notifyAnnotationPropertyChange({
                    heightGroupId:__custom,
                    height:Number(e.target.value)/w
                  })
                  }}
                />
                
                {false && <div style={{cursor:'pointer'}} onClick={() => setIsAddingHeightGroup(true)}>
                  <AddCircleIcon style={{marginLeft:3,fontSize:18,color:'green'}}/>
                </div>}
              </ListItem>
              {selectedHeightGroupId === __custom && isAddingHeightGroup && <ListItem selected={isAddingHeightGroup} style={{paddingLeft:5}}>
                <div class="sg-row">
                  <input 
                    id={HEIGHT_GROUP_LABEL_INPUT}
                    onChange={e => setNewHeightGroupLabel(e.target.value)}
                    style={{fontSize:10,width:100}} 
                    placeholder={"Height group label"}
                  />


                  <div style={{cursor:'pointer'}} onClick={() => {
                    setIsAddingHeightGroup(false);
                    setNewHeightGroupLabel("");
                  }}>
                    <CancelIcon style={{marginLeft:5, fontSize:18}}/>
                  </div>

                  <div style={{cursor:'pointer'}} onClick={() => {
                    if( newHeightGroupLabel === "" ){
                      return;
                    }
                    dispatch(A.addHeightGroup({label:newHeightGroupLabel, height: customHeight/w }));
                    setIsAddingHeightGroup(false);
                    setNewHeightGroupLabel("");

                  }}>
                    <CheckCircleIcon style={{marginLeft:5, fontSize:18}}/>
                  </div>

                </div>
              </ListItem>}
              <Divider/>
            {heightGroupList.map((opt,ii) => (
              <>
                <ListItem 
                  onMouseEnter={() => setHoveredHeight(opt.height*w)}
                  onClick={() => {
                    dispatch(A.setAnnotationProperties({
                      _id:focusedAnnotation._id,
                      heightGroupId:opt._id
                    }))
                  }}
                  style={{padding:7}} button 
                  selected={opt._id===selectedHeightGroupId}>
                  <div>
                    {opt.label}
                  </div>

                </ListItem>

              </>

            ))}
            </List>
          </div>
        </Typography>
      </div>
    </>
  );
}

