import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import G from './Getters';
import RawImagesFigureView from './RawImagesFigureView';
import MicroscopyLayoutDev from './MicroscopyLayoutDev';
import A from './ActionCreators';
import TemplatePositionLock from './TemplatePositionLock';
import ChannelSelector from './ChannelSelector';
import CropWrapper from './CropWrapper';
import CropDisplay from './CropDisplay';
import SubregionOutlineMenu from './SubregionOutlineMenu';
import NodeExpansionItemAdder from './NodeExpansionItemAdder';
import SpacingEditor from './SpacingEditor';
import CollapsibleSection from './CollapsibleSection';
import ImageRegionChoiceSubmenu from './ImageRegionChoiceSubmenu';
import { isUsingWebdriver } from './versionConfig';
import ScalebarEditor from './ScalebarEditor';



const expansionHeight = 400;
const sidebarWidth = 200;

const DEFAULT_OPEN_SET = new Set(["scalebar"])

const sectionHeaderStyle = {
  fontSize:12, 
  fontWeight:'bold',
}

// CAN EDIT TEMPLATE:
//  * if there a single template referenced from the selectedFigureItems

function getChangesByCellLocation(e){

  //targetLocations:{nodeId,cellLocation}
  let { targetLocations, properties } = e;

  if( !targetLocations ){
    debugger;
  }


  let byCell = {};

  //need to replace . with ~ so we can 
  //put the '.' at the front with the nodeOverrides.nodeId
  let fixedPropertyEntries = Object.entries(properties).map(([key,value]) => {
    return [key.replaceAll('.','~'),value]
  });

  targetLocations.forEach(({cellLocation,nodeId}) => {

    let cellLoci = cellLocation.join('-');
    if( !byCell[ cellLoci ] ){
      byCell[ cellLoci ] = {}
    }


    //set the properties to be set in each cell
    fixedPropertyEntries.forEach(([key,value]) => {
      let finalKey = `nodeOverrides.${nodeId}.${key}`;
      byCell[ cellLoci ][ finalKey ] = value;
    })
  })


  let byCellLocation = Object.entries(byCell).map(([cellKey,properties]) => {
    return { 
      cellLocation:cellKey.split('-').map(Number),
      properties
    }
  })

  return byCellLocation;

}

function handleMenuEvent({e,figurePanelId,dispatch,
  selectedNodes,
  selectedTemplateNodes
}){
    //decide what kind of event we're dispatching

   
    let { type } = e;

    switch(type){
      /*
      case "add":{

        if( selectedNodes && selectedNodes.length > 0 ){

          let { args } = e;


          dispatch(A.insertNodesInEvaluatedTemplateNodes({

          }))

        }

        break;
      }
      */
      case "change":{

        if( selectedNodes.length > 0 ){


          let byCellLocation = getChangesByCellLocation({

            targetLocations:(selectedNodes),
            ...e,
            
          });



          dispatch(A.setCellsValueProperties({
            figurePanelId,
            byCellLocation,
          }))

        }else if( selectedTemplateNodes.length > 0 ){

          throw Error("Haven't linked dispatch to editing template node properties.");
        }


        break;
      }
      default:{
        throw Error(`Event type ${type} is not a handled menu event.`);
      }
    }
  }



export default function FigureInfoSidebar({
  possibleActions,
  unifiedGroup,
  selectedFigureItems, 
  figurePanelId, 
  initialArgs, 
  onFigureInteractionEvent 
}){

  let dispatch = useDispatch();
  let data = unifiedGroup;

  let selectedTemplateNodes = selectedFigureItems.templateNodes || [];
  let selectedNodes = selectedFigureItems.expansionNodes || [];
  let selectedCells = selectedFigureItems.cells || [];


 /* 
  let {data, selectedNodeInfo } = useSelector(state => {
    return {
      data:G.getUnifiedGroupFromNodeValues(state,{figurePanelId,nodeDataList:(selectedNodes||selectedTemplateNodes)
      }),

      //selectedNodeInfo : G.getSelectedEvaluatedNodeInfo(state,{figurePanelId,selectedNodes})
    }
  });
*/


  



    
  let componentOrder = {
    scalebar:ScalebarEditor,

    addItems:NodeExpansionItemAdder,
    
    position:TemplatePositionLock,

    spacing:SpacingEditor,
    setRegionVariable:ImageRegionChoiceSubmenu,

    regionOutlines:SubregionOutlineMenu,



    imageFilters:ChannelSelector,
  }


  const standardEventResponse = e => {
    handleMenuEvent({
      e,
      figurePanelId,
      selectedNodes,
      selectedTemplateNodes,
      dispatch
    })
  }

  const customEventHandler = ({
    position:({event,position}) => {
      handleMenuEvent({
        event:event,
        type:"change",
        properties:{
          "position.base":position
        }
      })
    },
    addItems:({event,args}) => {
      onFigureInteractionEvent({
        type:"add",
        event,
        args
      })
      if( selectedNodes && selectedNodes.length > 0 ){ 
        
      }

    }
  })



  let sidebarMenus = Object.entries(possibleActions||{}).map(([menuKey,menuArgs]) => {

    if( !menuArgs ){
      return null;
    }

    let component = componentOrder[menuKey];

    if( !component ){
      component = () => (
        <div style={{color:"red",fontWeight:"bold"}}>
          {`No mneu found for '${menuKey}'.`}
        </div>
      )
      
    }

    if( !selectedNodes || selectedNodes.length === 0 ){
      return null;
    }



    return (
      <CollapsibleSection sectionTitle={menuKey} titleStyle={sectionHeaderStyle} defaultOpen={DEFAULT_OPEN_SET.has(menuKey)||false}>
      {React.createElement(component,{
        onEvent:(
          customEventHandler[menuKey] || standardEventResponse),
        selectedFigureItems,
        figurePanelId,
        data,
        args:menuArgs,
      })}
      </CollapsibleSection>
    )


  })


 


  return (
    <>


      <div id={"figure-info-sidebar"} class={"figure-info-sidebar"}>

        <pre>
      {false && JSON.stringify(selectedFigureItems,null,1)}
      </pre>

        
        {true && <div>
          <pre style={{fontSize:8}}>
          
            <span style={{color:"blue"}}>{true && JSON.stringify(data,null,1)}</span>


          </pre>



        </div>

        }

        {sidebarMenus}


        

      </div>
    </>
  )
    

}
