import G from './Getters';
import usePresentSelector from './usePresentSelector';
import Toolbar from './Toolbar';
import ValueDisplay from './ValueDisplay';
import ProteinQuantificationViewer from './ProteinQuantificationViewer';
import { useState, useRef, createContext } from 'react';
import RawImagesFigureView from './RawImagesFigureView';
import SvgGrid from './SvgGrid';

import ExportButton from './ExportButton';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import ManualAnnotationGrid from './ManualAnnotationGrid';

import FigureInfoSidebar from './FigureInfoSidebar';

import { FigureInteractionContext, ColumnResizeContext } from './Contexts';

import FigureEditorModal from './FigureEditorModal';



import FigureEditorContextMenuLayer from './FigureEditorContextMenuLayer';

import MicroscopyFilters from './MicroscopyFilters';

import ErrorBoundary from './ErrorBoundary';

import { produce } from 'immer';

import { Ids } from './IdFactory';

export default function FigureEditorContainer({
  cropIdsBeingDragged,
  setCropIdsBeingDragged,
  showExportButton, 
  setSvg, 
  svg, 
  figurePanelId 
}){


  let dispatch = useDispatch();


  

  const [ modalArgs, setModalArgs ] = useState(/*{regionType:"crop",referenceAnnotationId:"a0", cells:[[2,1]], regionId:"region-0", figurePanelId, }*/);


  let [rows,cols] = usePresentSelector(state => G.getTableDimensions(state,figurePanelId));

  const [temporaryCellValue,setTemporaryCellValue] = useState(null);

  const [selectedFigureItems,setSelectedFigureItems] = useState(
    {
      //cells:[], 
      //expansionNodes:[{nodeId:"v_p=012;t=ro;r=region-0", cellLocation:[2,1]}],

      expansionNodes:[
        //{nodeId:"1-scalebar", cellLocation:[2,1]}
      ],

    });



  let possibleActions = usePresentSelector(state => G.getPossibleFigureEditorActions(state,{figurePanelId,selectedItems:selectedFigureItems}));



  const selectedCells = selectedFigureItems.cells || [];
  const selectedNodes = selectedFigureItems.expansionNodes || [];
  const selectedTemplateNodes = selectedFigureItems.templateNodes || [];


  let unifiedGroup = usePresentSelector(state => {
    if( selectedNodes?.length > 0 ){
      return G.getUnifiedGroupFromNodeValues(state,{figurePanelId,nodeDataList:selectedNodes});
    }else{
      return G.getUnifiedGroupFromSelectedCells(state,{figurePanelId,selectedCells})
    }
  });


  let nothingSelected = Object.keys(selectedFigureItems).length === 0 || Object.values(selectedFigureItems).every(list => !list || list.length === 0);



  const figurePanel = usePresentSelector(state => G.getFigurePanel(state,{figurePanelId}));


  function setSelectedCells(cells){
    setFocusedOnValueDisplay(false);
    setSelectedFigureItems({
      cells
    });
    setTemporaryCellValue(null);
  }

  let rowArrangedGridData = usePresentSelector(state => {
    return G.getCompleteGridLayoutData(state,{figurePanelId})
  });


  const makeCellSelection = (cells,multiselect) => {



    if( cells.length === 0 && nothingSelected ){
      return;
    }

    setSelectionJustMade(true);
    setSelectedCells(G.getNewSelectedCellList(null,{currentlySelectedCells:selectedCells,newlySelectedCells:cells,multiselect}));


    if( cellValueInputRef && cellValueInputRef.current ){
      cellValueInputRef.current.focus();
    }

  }

  //this is here to expose to the tuturial
  window.setSelectedCells = setSelectedCells;

  const [ quantificationToolbarVisible, setQuantificationToolbarVisible ] = useState(true);

  const [ selectionJustMade, setSelectionJustMade ] = useState(false);
  const [tableOutlineShowing,setTableOutlineShowing] = useState(true);
  const [retainInputFocus, setRetainInputFocus] = useState(false);
  const [focusedOnValueDisplay,setFocusedOnValueDisplay]= useState(false);

  function handleArrowKey(key,e){

    let activeTag = document.activeElement?.tagName;
    if( activeTag === 'INPUT' ){
      return;
    }else if( activeTag === 'TEXTAREA' ){
      if( focusedOnValueDisplay && e.key !== 'Enter' ){
        return;
      }
    }

    let directionMap = {
      ArrowUp:[-1,0],
      ArrowLeft:[0,-1],
      ArrowRight:[0,1],
      ArrowDown:[1,0]
    }



    if( selectedCells[0] && directionMap[key] ){
      e && e.preventDefault();
      let direction = directionMap[key]
      let newSelectedPosition = G.getFigurePanelGridCoordByTravelingDirectionFromCell(null,{
        cell:selectedCells[0],
        direction,
        rowArrangedGridData,
        figurePanelId,
        rows,
        cols
      });

      if( cellValueInputRef && cellValueInputRef.current ){
        cellValueInputRef.current.blur()
      }

      if( temporaryCellValue ){
        dispatch(A.setCellsValue({cells:selectedCells,figurePanelId,value:temporaryCellValue}));
        setTemporaryCellValue(null);
      }



      makeCellSelection([newSelectedPosition]);

    }else if( directionMap[key] && rowArrangedGridData[0] && rowArrangedGridData[0][0] ){
      makeCellSelection([[0,0]]);
    }
  }

  const cellValueInputRef = useRef();


  let valueDisplayProps = {
    selectionJustMade,setSelectionJustMade,
    tableOutlineShowing,setTableOutlineShowing, 
    setTemporaryCellValue,temporaryCellValue,
    cellValueInputRef,
    retainInputFocus,
  }

  


  const [columnResizeInfo,setColumnResizeInfo] = useState();

  const state = usePresentSelector(state => state);

  const _setColumnResizeInfo = cellResizeInfoArgs => {
    if( cellResizeInfoArgs ){
      let resizeInfo;
      try{ 
        resizeInfo = G.getCellResizeInfo(state,{
          figurePanelId,
          ...cellResizeInfoArgs
        })
      }catch{
        debugger;
      }

      setColumnResizeInfo(resizeInfo);
    }else{
      setColumnResizeInfo();
    }
  } 


  function onFigureInteractionEvent(e){
    let { type, ...args } = e;
    switch(type){
      case "add":{
        let eventArgs = args.args;
        let { event } = e;
        if( selectedNodes && selectedNodes.length > 0 ){

          let newNodeIds = [
            ...([window?.__actionArgsOverrides?.nodeId].filter(x => x)), 
            ...Ids(selectedNodes.length),
          ]

          let targetLocations = selectedNodes.map((node,ii) => {
            return {
              inside:node.nodeId,
              nodeId:newNodeIds[ii],
              cellLocation:node.cellLocation
            }
          })


          dispatch(A.insertNodesInEvaluatedTemplateNodes({
          figurePanelId,
          targetLocations,
            args:eventArgs
        }));

         setSelectedFigureItems({
           expansionNodes:targetLocations
         })

        }
        break;

      }
      case "setLabel":{

        let { nodeId } = args;

        let cells = [args.cellLocation];
        let propKey = "nodeOverrides."+nodeId+".label";
        dispatch(A.setCellsValueProperties({
          figurePanelId,
          cells,
          properties:{
            [propKey]:args.value
          }



        }))

        break;
      }
      case "setImageResolution":{
        
        setModalArgs({
          modal:"setImageResolution",
          ...args.args,
        })
        break;
      }
      case "specifyExpansionNodeRegion":{
        //let referenceAnnotationId = args.cell.data.value.annotationId;
        debugger;
        let modalArgs = args.args;
        let { referenceAnnotationId } = modalArgs;
        let targetAnnotationId;
        
        setModalArgs({
          modal:"imageAnnotation",
          mode:"regionChoice",
          figurePanelId, ...args, referenceAnnotationId,targetAnnotationId
        })
        break;
      }

      case "specifiedExpansionNodeRegion":{
          let { annotationId } = args;
          let targetRegion = unifiedGroup.value.regionId;

          let regionKey = "regions."+targetRegion

          dispatch(A.setCellsValueProperties({
            figurePanelId,
            cells:[selectedNodes[0].cellLocation],
            properties:{
              [regionKey]:annotationId
            }


          }))
          setModalArgs();
          break;
        }
      case "click":{


        let { event, ...dataArgs  } = args;
        let { itemType, nodeId, cellLocation } = dataArgs;

        debugger;


        if( itemType === "expansionNode" ){

          if( false && (event.ctrlKey || event.shiftKey) ){

            let indexOfSelected = (
              selectedFigureItems.expansionNodes||[]
            ).findIndex(item => {
              return item.nodeId === nodeId && item.cellLocation[0] === cellLocation[0] && item.cellLocation[1] === cellLocation[1] });

            setSelectedFigureItems(produce(selectedFigureItems,draft => {

              if( indexOfSelected === -1 ){
                draft.expansionNodes = [
                  ...(selectedFigureItems.expansionNodes || []),
                  dataArgs
                ]
              }else{
                draft.expansionNodes.splice(indexOfSelected,0);
              }
            })
            )

          }else{
            //alert("Selecting: " + JSON.stringify(dataArgs));
            setSelectedFigureItems({
              expansionNodes:[dataArgs]
            })
          }
        }else{
          throw Error("Unrecognized itemType: '"+itemType+"'");
        }
        break;
      }
      case "contextmenu":{
        let { event, ...dataArgs } = args;
        let { itemType } = dataArgs;

        if( itemType === "expansionNode" ){
          setSelectedFigureItems({
            expansionNodes:[dataArgs]
          })
        }
        break;
      }
      default:{
        throw Error(`No registered event type '${type}'.`);
      }
    }
  }


  let figureContent = (


      <FigureEditorContextMenuLayer {...{
        figurePanelId,
        selectedFigureItems,
        setSelectedCells,
        possibleActions,
        onEvent:onFigureInteractionEvent,
      }}>

        <FigureInteractionContext.Provider value={{ 
          onFigureInteractionEvent,
          selectedFigureItems 
        }}>

          <ColumnResizeContext.Provider value={{columnResizeInfo,setColumnResizeInfo:_setColumnResizeInfo}}>

            {true && <ManualAnnotationGrid 
              {...{
                editable:!Boolean(modalArgs),
                selectedFigureItems,
                rows,
                cols,
                setTemporaryCellValue,
                selectedCells,
                setSelectedCells,
                rowArrangedGridData,
                handleArrowKey,
                makeCellSelection
              }}
              figurePanelId={figurePanelId}
              temporaryCellValue={temporaryCellValue}
              tableOutlineShowing={tableOutlineShowing}
              setSelectionJustMade={setSelectionJustMade}
              isDraggingCrop={cropIdsBeingDragged?.length > 0}

              cropIdsBeingDragged={cropIdsBeingDragged}
              setCropIdsBeingDragged={setCropIdsBeingDragged}



              onCropDrop={(cell) => {

                dispatch(A.setCellsValue({
                  figurePanelId,
                  cells:[cell],
                  value:{
                    valueType:'crop',
                    annotationId:cropIdsBeingDragged[0].annotationId
                  }
                }))
                setCropIdsBeingDragged([]);

              }}
            />}
          </ColumnResizeContext.Provider>
        </FigureInteractionContext.Provider>
      </FigureEditorContextMenuLayer>
    )


  let beginnerInterface = (
    <div class="sg-col full-height flex-expand" style={{
      minHeight:0,
      position:'relative',
    }}>
      <div style={{justifyContent:'center',
        alignItems:'center',
      }}>
        Select crop regions to start your figure!
      </div>
    </div>
  )
  //return beginnerInterface;


  let figureEditorModal = <FigureEditorModal {
      ...{ 
        modalArgs, 
        onEvent:onFigureInteractionEvent,
        onClose:() => setModalArgs(),
      }}/>
  return (

    <div style={{
      minHeight:0,
      minWidth:0,
      position:'relative',
    }} 

      containerType={"sceneContainer"}
      scene={"panelEditor"}
      class="sg-col full-height flex-expand full-width">
      {false && <div>{JSON.stringify(selectedFigureItems)}</div>}


      {modalArgs && figureEditorModal}


      <div style={{
        display:'flex',
      }}>
        <Toolbar {...{
          unifiedGroup,
          figurePanelId,
          quantificationToolbarVisible,
          setQuantificationToolbarVisible,
          setRetainInputFocus,
          setSelectedCells,
          selectedFigureItems
        }}/>
      </div>
      <div style={{
        position:'relative',
        minHeight:0,
        minWidth:0,
        background:'none',
      }} class="flex-expand sg-col full-width">

        <ValueDisplay {...{...valueDisplayProps, figurePanelId, selectedCells, handleArrowKey, setFocusedOnValueDisplay, focusedOnValueDisplay }}/>

        <div style={{ 
          minHeight:0,
          minWidth:0,
          justifyContent:'center',
        }} class="sg-row">
          {figureContent}
          

          {showExportButton !==false && <div style={{
            position:'absolute',
            bottom:5,
            left:'50%'
          }}>
            <ExportButton {...{setSvg,color:'green', figurePanelId, button:"bottom-export-button"}}/>
          </div>}



          {false && quantificationToolbarVisible && <ProteinQuantificationViewer/>}


        </div>

        {Object.keys(possibleActions||{}).length > 0 && (
          <FigureInfoSidebar {...{ figurePanelId, selectedFigureItems, 

            onFigureInteractionEvent,

            possibleActions, unifiedGroup }}/>
        )}


      </div>

      {true && <MicroscopyFilters/>}

    </div>
  )
}



