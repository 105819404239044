import { useDispatch } from 'react-redux';
import { useState, useEffect, useContext } from 'react';
import usePresentSelectom from './usePresentSelector';
import { useRef } from 'react';
import A from './ActionCreators';
import useLocalReducer from './useLocalReducer';

import { QuantificationListContext } from './Contexts';

const FEATURE_FLAGS = {
  INDIVIDUAL_LANE_RESIZE:false
}




export default function QuantificationLaneBox({
  laneIndex,
  laneBoundaryPositions,
  containerTop,
  height, 
  lsDistance, 
  containerStart, 
  containerWidth, 
  annotationId,
  leftDragLimit,
  rightDragLimit,
  quantification
}){

  

  const quantificationListContext = useContext(QuantificationListContext);

  let { quantificationList, quantificationListDispatch } = quantificationListContext || {};
  //let { quantificationListDispatch } = quantificationListContext || {};

  let selected = (quantificationList||{})[annotationId]?.includes(laneIndex); 

  let dispatch = useDispatch();
  let [ localState, localDispatch ] = useLocalReducer({
    containerWidth,
    containerStart
  })

 
  let allRanges = quantification?.integrationRanges;
  let integrationRange = [];
  if( allRanges ){
    integrationRange = allRanges[0];
  }

  //let integrationRange = [0] || [];


  useEffect(() => {


    localDispatch({containerWidth,containerStart});

  },[containerWidth,containerStart])

 
  /*
  let [localState,localDispatch] = useLocalReducer({
    leftActive:false,
    rightActive:false,
    sideOffsets:false,
    left:laneBoundaryPositions[0],
    right:laneBoundaryPositions[1]
  })

  let { leftActive, rightActive, sideOffsets, left, right } = localState;
  */

  let [left,setLeft] = useState(laneBoundaryPositions[0]);
  let [right,setRight] = useState(laneBoundaryPositions[1]);
  let [top,setTop] = useState(integrationRange[0] || 0);
  let [bottom,setBottom] = useState(integrationRange[1] || 1);

  let [leftActive,setLeftActive] = useState(false);
  let [rightActive,setRightActive] = useState(false);
  let [topActive, setTopActive] = useState(false);
  let [bottomActive, setBottomActive] = useState(false);

  let [sideOffsets,setSideOffsets] = useState(false);

  //let [downPosition,setDownPosition] = useState();


  let props = { 
    strokeWidth:3, 
    stroke:'blue', 
    opacity:0.8,
    zIndex:10
  };

  useEffect(() => {
    setLeft(laneBoundaryPositions[0])
    setRight(laneBoundaryPositions[1])
  },[laneBoundaryPositions])

  const laneBoxRef = useRef();

 
  const scaleFactor = localState.containerWidth / lsDistance;

  const onMouseMove = e => {

    if( leftActive ){
      setLeft( (e.clientX - containerStart) / scaleFactor );
    }else if( rightActive ){
      setRight( (e.clientX - containerStart) / scaleFactor );
    }else if( sideOffsets ){

      let newLeft = ( (e.clientX + sideOffsets[0] - containerStart) / scaleFactor );
      let newRight = ( (e.clientX + sideOffsets[1] - containerStart) / scaleFactor );

      if( newLeft > leftDragLimit && newRight < rightDragLimit ){

      setLeft(newLeft)
      setRight(newRight)
      }
    }else if( topActive ){
      let newTop = (e.clientY - containerTop) / height;
      if( newTop > 0 && newTop < 1 ){
        setTop(newTop);
      }
      
    }else if( bottomActive ){

      let newBottom = (e.clientY - containerTop) / height;
      if( newBottom > top && newBottom < 1 ){
        setBottom(newBottom);
      }
    }

  }

  
  /*const onMouseUp = e => {

    if( [leftActive,rightActive,sideOffsets].some(x => x) ){
      dispatch(A.setLaneOffsets({annotationId,laneIndex,offsets:[left,right]}));
    }
    let toDispatchLocally = {};

    if( leftActive ){ 
      setLeftActive(false)
      //toDispatchLocally.leftActive = false;//setLeftActive(false) 
    }
    else if( rightActive ){ 
      setRightActive(false);
      //toDispatchLocally.rightActive = false;//setRightActive(false) 
    }
    else if( sideOffsets ){ 
      setSideOffsets(false);
      //toDispatchLocally.sideOffsets = false;
    }
    //localDispatch(toDispatchLocally);
  }*/

  const onMouseDown = e => {
    //setDownPosition(e.clientX);
  }

  function onMouseUp(e){
    let resizingActive = [ topActive, bottomActive, leftActive, rightActive ].some(x => x);
    let laneBoxEl = laneBoxRef.current;

    if( !resizingActive && laneBoxEl ){
      let mouseUpInThisBox = e.composedPath().includes(laneBoxEl);
      if( !selected && mouseUpInThisBox ){
        if( quantificationListDispatch ){
        quantificationListDispatch({
          type:"selectCells",
          multiselect:e.shiftKey,
          indicesByAnnotationIds:{
            [annotationId]:[laneIndex]
          }
        })
        }
      }
    }

    if( sideOffsets ){


      let newSideOffsets = [left,right];
      setSideOffsets(false);
      dispatch(A.setLaneOffsets({annotationId,laneIndex,offsets:newSideOffsets}));

    }

    if( topActive ){
      setTopActive(false);

      dispatch(A.setIntegrationRange({
        annotationId,
        laneIndex,
        rangeIndex:0,
        range:[top,bottom]
      }))



    }
    if( bottomActive ){
      setBottomActive(false);

      dispatch(A.setIntegrationRange({
        annotationId,
        laneIndex,
        rangeIndex:0,
        range:[top,bottom]
      }))

    }
    
  }

  useEffect(() => {
    window.addEventListener('mousemove',onMouseMove);
    window.addEventListener('mouseup',onMouseUp, /*{capture:true}*/);
    window.addEventListener('mousedown',onMouseDown);

    return () => {
      window.removeEventListener('mousemove',onMouseMove);
      window.removeEventListener('mouseup',onMouseUp);
      window.addEventListener('mousedown',onMouseDown);

    }
  })


  let drawLeft = left * scaleFactor;
  let drawRight = right * scaleFactor;
  let drawTop = top * height;
  let drawBottom = bottom * height;

  let isFocused = (sideOffsets||selected)
  let fill = "rgba(0,165,255,"+(isFocused?0.1:0)+")"


  let leftLineArgs = FEATURE_FLAGS.INDIVIDUAL_LANE_RESIZE ? {
    class:"thick-stroke-on-hover" ,
    style:{cursor:'ew-resize'},
    onMouseDown:(() => setLeftActive(true))
  } : {};


  let rightLineArgs = FEATURE_FLAGS.INDIVIDUAL_LANE_RESIZE ? {
    class:"thick-stroke-on-hover" ,
    style:{cursor:'ew-resize'},
    onMouseDown:(() => setRightActive(true))
  } : {};


  let topLineArgs = quantification ? {
    class:"thick-stroke-on-hover" ,
    style:{cursor:'ns-resize',

      zIndex:10,
    },
    onMouseDown:(() => {
      setTopActive(true)
    })
  } : {};


  let bottomLineArgs = quantification ? {
    class:"thick-stroke-on-hover" ,
    style:{cursor:'ns-resize', zIndex:10},
    onMouseDown:(() => setBottomActive(true))
  } : {};




  const cornerColor = "magenta";

  return [

    <rect 
      ref={laneBoxRef}

      onMouseUp={e => {
        
      }}
      
      onMouseDown={e => {
        setSideOffsets([drawLeft - (e.clientX - containerStart), drawRight - (e.clientX - containerStart)])
      }}
      style={{cursor:sideOffsets?'grabbing':'grab'}} 
      x={drawLeft} y={0} height={height} width={(drawRight-drawLeft)} 
      fill={fill}
    />,

     <line 

    {...{...props,...leftLineArgs}} x1={drawLeft} y1={0} x2={drawLeft} y2={height}/>,

    <line 
      {...{...props,...rightLineArgs}} 
      x1={drawRight} y1={0} x2={drawRight} y2={height}
    />,

    <line {...{...props, ...topLineArgs}} x1={drawLeft} y1={drawTop} x2={drawRight} y2={drawTop}/>,

    <line {...{...props, ...bottomLineArgs}} x1={drawLeft} y1={drawBottom} x2={drawRight} y2={drawBottom}/>,




    //bottom-left-corner
    <line {...{...props, stroke:cornerColor}} x1={drawLeft} y1={drawBottom} x2={drawLeft} y2={drawBottom-10} stroke={cornerColor} zIndex={15}/>,
    <line {...{...props, stroke:cornerColor}} x1={drawLeft} y1={drawBottom} x2={drawLeft + 10} y2={drawBottom} stroke={cornerColor} zIndex={15}/>,

    //bottom-right-corner
    <line {...{...props, stroke:cornerColor}} x1={drawRight} y1={drawBottom} x2={drawRight} y2={drawBottom-10} stroke={cornerColor} zIndex={15}/>,
    <line {...{...props, stroke:cornerColor}} x1={drawRight} y1={drawBottom} x2={drawRight-10} y2={drawBottom} stroke={cornerColor} zIndex={15}/>,

    //top-left-corner
    <line {...{...props, stroke:cornerColor}} x1={drawLeft} y1={drawTop} x2={drawLeft} y2={drawTop+10} stroke={cornerColor} zIndex={15}/>,
    <line {...{...props, stroke:cornerColor}} x1={drawLeft} y1={drawTop} x2={drawLeft + 10} y2={drawTop} stroke={cornerColor} zIndex={15}/>,

    //top-right-corner
    <line {...{...props, stroke:cornerColor}} x1={drawRight} y1={drawTop} x2={drawRight} y2={drawTop+10} stroke={cornerColor} zIndex={15}/>,
    <line {...{...props, stroke:cornerColor}} x1={drawRight} y1={drawTop} x2={drawRight-10} y2={drawTop} stroke={cornerColor} zIndex={15}/>,


    false &&  <text x={drawLeft} y={-20} width={50} height={100}>
        {Number(Number(height).toFixed(2))}
    </text>,

    false && <text x={drawLeft} y={-20} width={50} height={100}>
      {Number(top).toFixed(3)}
    </text>,

    false && <text x={drawRight-20} y={height+20} width={50} height={100}>
      {Number(bottom).toFixed(3)}
    </text>


  ]





}
