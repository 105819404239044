import GetLines from './GetLine';
import util from 'util';

import GetCoordinateAreaProportions from './GetCoordinateAreaProportions';

import { argmax, diff } from './utils';
import G from './Getters';

//export function getLines

export function getPixelValues(raw,ls,annotationHeightDividedByWidth){

  let { width, bitDepth } = raw;

  let lines = G.getLinesOfPixelPositions(null,{ls,height:annotationHeightDividedByWidth, imagePixelWidth:width
  });




  let values = [];

  for(let pointList of lines){
    let lineVals = []
    for(let point of pointList){

      let val = getPointValue(raw,point);
      if( isNaN(val) ){
        lineVals.push(0);
      }else{
        lineVals.push(val);
      }
    }
    values.push(lineVals);
  }

  /*let values = lines.map((points) =>
    points.map((point) => {
      let val = getPointValue(raw, point)
      if( isNaN(val) ){
        lines;
        debugger;
        getPointValue(raw,point);
      }
      return val;
    })
  );*/

  return values;



}


export default function Quantify(raw, ls, heightPadding) {


  //take the width of the original imag
  ////take the width of the original imagee

  let { width, bitDepth } = raw;
 
  let values = getPixelValues(raw,ls,heightPadding);

  let maxPixelSize = 2** bitDepth - 1;
  let divisor = 1;
  /*if( bitDepth > 8 ){
    divisor = 2 ** (bitDepth-1);
  }*/

  let avgs = values.map((line) => line.reduce((a, b) => a + b) / line.length);
  let flipped = avgs.map(x => (maxPixelSize - x)/divisor).map(v => Number(v.toFixed(2)));


  return flipped;
}

function getPointValue(src, point) {
  let coordProps = GetCoordinateAreaProportions(point);

  let data = src.data;


  let value = coordProps.reduce((sum, coordInfo) => {
    if( coordInfo.area === 0 ){
      return sum;
    }



    let [x, y] = coordInfo.coord;

    let dataIndex = y * src.width + x;
    let subValue = src.data[dataIndex];
    let scaledByArea = subValue * coordInfo.area;
    return sum + scaledByArea;
  }, 0);

  return value;
}

export function getPeakBoundary(values,bitDepth){

  let scaledValues = values;
  if( bitDepth === 16 ){
    scaledValues = values.map(x => x/256);
  }

  let peakIndex = argmax(scaledValues);
 

  let firstDiff = diff(scaledValues);


  let peakBegin = 0;
  let peakEnd = values.length-1;

  //starting from the left of the lane,
  //find the index before the first diff of >= 1
  while(peakBegin < peakIndex && firstDiff[peakBegin] <= 2){ 
    peakBegin++;
  }

  //starting from the RIGHT of the lane,
  //find the index AFTER the first slope of >= -1
  while(peakEnd > peakIndex && firstDiff[peakEnd] >= -2){
    peakEnd--;
  }



  
  let boundaries = [[peakBegin/values.length,peakEnd/values.length].map(x => Number(Number(x).toFixed(3)))];

  return boundaries;

}
