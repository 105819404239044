import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import CropWrapper from './CropWrapper';
import G from './Getters';
import TemplateNodePositionedItem from './TemplateNodePositionedItem';
import { GridCellContext, ExpansionWindowFocusContext } from './Contexts'; 
import RotationContainerSvg from './RotationContainerSvg';
import { AnnotationStyleContext } from './Contexts';
import SubregionOutlineLayer from './SubregionOutlineLayer';

const FILTER_CLASS_MAP = {
  //"012":"rgb-channel",
  "01":"red-green-channel",
  "02":"red-blue-channel",
  "12":"green-blue-channel",
  "0":"red-channel",
  "1":"green-channel",
  "2":"blue-channel"
}

export default function LayoutValueImage({
 nodeId, annotationId,style,node,regions,regionId, children, selectedNodes, onEvent, 
  nodes,
  parentPixelDimensions 
}){


  const [ parentBox, setParentBox ] = useState();

  let { value={} } = node || {};
  let { channels, grayscale } = value;

  let state = useSelector(state => state);
  let inlineCrop = G.getCropFromAnnotation(state,annotationId);
  let imgRef = useRef();

  const outerPadding = 2;
  const { borderColor='white', borderWidth=0, padding=0 } = style || {};


  let subregionAtns;
  if( regionId === "main" ){
    subregionAtns = Object.keys(regions).map(key => {
      if( key !== "main" ){
        return G.getData(state,{_id:regions[key],type:"annotations"});
      }
    }).filter(x => x);
  }




  let filter;
  if( grayscale ){
    //it's a percentage, obviously.
    filter = `grayscale(${grayscale})`;
  }


  const resolvedOuterPadding = Math.max(0,padding - borderWidth);
  const resolvedBorderPadding = Math.min(padding, borderWidth);

  let content;
  if( !regionId ){
  }
  else if( annotationId ){
    content = (

      <div style={{position:'relative'}} class="sg-row hoverable">
        <div style={{ filter }} className={
          `hoverable-sibling clickable full-width full-height ${FILTER_CLASS_MAP[channels]}`
          }>
          <CropWrapper {...{
            annotationId,
            inlineCrop,
            imageId:inlineCrop.imageId,
            imgRef
          }}/>

        </div> 

        {true && <div style={{outline:'5px solid #a6d3f7',
        zIndex:100,pointerEvents:'none'}} class="visible-on-sibling-hover sg-row absolute-cover"/>}
      </div>
    )
  }else{

    content = (
      <div 
        nodeId={nodeId}
        onClick={ (e) => {

          /*


          debugger;


          e.stopPropagation();


          //bring up the figure expansion editor

          onEvent({
            event:e,
            type:"specifyExpansionNodeRegion",
            regionId,
          }) 
          */
        }}
        className={"no-region-specified sg-row flex-expand v-center h-center"} style={{
          position:'relative',
          top:0,
          left:0,
          padding,
          width:'100%',//parentBox.width, 
          height:'100%',//parentBox.height,
          fontSize:10,
          //color:'#aaa',
          color:'black',
          background:'pink',
          pointerEvents:'auto',
        }}>
        <div style={{fontSize:36}}>
          ?
        </div>

      </div>
    )
  }


  let resolvedWidth = parentPixelDimensions.w - resolvedOuterPadding *2;
  let resolvedHeight = parentPixelDimensions.h - resolvedOuterPadding *2;



  return (
    <div nodeId={nodeId} component={"layoutvalueimage"} onClick={(e) => {
    }} style={{

      
      //padding:resolvedOuterPadding,


      left:resolvedOuterPadding,
      top:resolvedOuterPadding,
      width:resolvedWidth,
      height:resolvedHeight,

      background:borderColor,

      //background:'lime',

      padding:resolvedBorderPadding,
      position:'relative',
    }}>
      {false && <div style={{color:'magenta',position:'absolute',}}>
        {JSON.stringify(parentPixelDimensions)}
      </div>}


      <div class="full-width full-height" 
        onMouseDown={() => {
        }}
        onClick={() => {
        }}
        style={{

          /*
        left:resolvedBorderPadding,
        top:resolvedBorderPadding,
        width:`calc(100% - ${resolvedBorderPadding*2}px)`,
        height:`calc(100% - ${resolvedOuterPadding*2}px)`,
        */

          //padding:resolvedBorderPadding,
          position:'relative',
          top:0,
          left:0,


        }}>
        <div  onClick={() => {
          }}
          ref={el => {

            if( el ){
              let parentRect = el.getBoundingClientRect();
              let parentBoxToSet = {
                width:parentRect.width,
                left:parentRect.left,
                height:parentRect.height,
                top:parentRect.top
              }



              let newPB = JSON.stringify(parentBoxToSet);
              let curPB = JSON.stringify(parentBox);

              if( !parentBox || (newPB !== curPB) ){
                //console.log("Setting pb of " + nodeId + " to " + newPB);
                setParentBox(parentBoxToSet)
              }




            }
          }} style={{
            position:'relative',
            top:0, 
            left:0, 
            pointerEvents:'auto', 
            //border:`3px solid rgb(${[Math.random()*256, Math.random()*256, Math.random()*256].join(',')})`,
          }} class={`full-height full-width`}>
          {content} 


          {true && (<>

          {/* this order makes sure the region stays on top */}
          {node.c && subregionAtns && <SubregionOutlineLayer {...{ state, subregionAtns, inlineCrop, imageSetId:inlineCrop.imageSetId, 
            nodeId,
            annotationId, 
            node, 
            nodes,
            regions,
            selectedNodes,
            onEvent,
          }}/>}

          {parentBox && children && <div class="absolute-cover" style={{
            pointerEvents:'none',
            zIndex:10,
          }}>
            {children.map(cId => {
              return (
                <TemplateNodePositionedItem {...{
                  selectedNodes,
                  onEvent,
                  evaluate:true,
                  nodeId:cId,
                  node:nodes[cId],
                  parentBox,
                  parentPixelDimensions,
                  nodes,
                  regions,
                }}/>
              )
            })}
          </div>}

        </>)}

        </div>

        
        





    </div>


    
    </div>)

}
