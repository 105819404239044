import GetCoordinateAreaProportions from "./GetCoordinateAreaProportions";

import { add, minus, unitPerp, scale } from "./Geometry";

function getLine(p1,p2){
  let [x0,y0] = p1;
  let [x1,y1] = p2;
  if( [x0,y0,x1,y1].some(x => isNaN(x)) ){
    throw Error("getLine requires 2 points each with numeric values. received p1 = " + JSON.stringify(p1) + ", " + JSON.stringify(p2));
  }
  let pixels = [];
  var dx = Math.abs(x1 - x0);
  var dy = Math.abs(y1 - y0);
  var sx = x0 < x1 ? 1 : -1;
  var sy = y0 < y1 ? 1 : -1;
  var err = dx - dy;

  let ii = 0; 
  while (true) {
    pixels.push([x0, y0]); // Do what you need to for this

    if (x0 === x1 && y0 === y1) break;
    var e2 = 2 * err;
    if (e2 > -dy) {
      err -= dy;
      x0 += sx;
    }
    if (e2 < dx) {
      err += dx;
      y0 += sy;
    }
  }
  ii++;

  if( ii > 1000 ){
    alert("+1000");
  }

  return pixels;
}

export default function GetLines(p1, p2, thickness = 2) {
  if (thickness % 2 !== 0 || thickness < 2) {
    throw Error("Line thickness must be an even number > 0, received: " + thickness);
  }


  //this uses the above "algorithm"
  //to get a 'line' between the two integer points
  let line = getLine(p1, p2);


  let uperp = unitPerp(minus(p2, p1));

  //addList(uperp);

  let allLines = Array(thickness).fill(0);

  for (let ii = 0; ii <= thickness / 2; ii++) {
    let shiftedUp = line.map((p) => add(p, scale(ii, uperp)));
    let shiftedDown = line.map((p) => add(p, scale(-ii, uperp)));
    allLines[thickness / 2 - ii] = shiftedDown;
    allLines[thickness / 2 + ii] = shiftedUp;

    //allLines.push(...[shiftedUp, shiftedDown]);


    if( ii > 1000 ){
      alert("STUCK IN ALL LINES THING");
    }
  }

  return allLines;
}

function getMat(start, end) {
  let maxX = Math.max(start[0], end[0]);
  let maxY = Math.max(start[1], end[1]);

  let matDimX = maxX + 2;
  let matDimY = maxY + 2;

  let mat = Array(matDimY)
    .fill()
    .map((_) =>
      Array(matDimX)
        .fill()
        .map((__) => " ")
    );


  let line = getLine(start, end);

  line.forEach(([x, y]) => {
    mat[y][x] = "\\";
  });


  return mat.map((l) => l.join(""));
}
