import GoogleLogin from 'react-google-login';
import { useState, useEffect } from 'react';
import ActionCreators from './ActionCreators';

import { useDispatch } from 'react-redux';

const CLIENT_ID = '544527896095-ab70qssrgk6o5gos4644nugrs4h1ikvo.apps.googleusercontent.com';
const API_KEY = 'AIzaSyB_5RV6IMhTToMaip8WWsR89dp96tKP430';
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const SCOPES = 'https://www.googleapis.com/auth/drive.appdata';//.appdata';


function downloadFile(id){
  window.gapi.client.drive.files.get({
    fileId: id,
    alt: 'media'
  })
    .then(function (fileDownload) {
      console.log({fileDownload});

      let img = document.createElement("IMG");
      img.src = fileDownload.body;
      img.style.width = '300px';
      let imgContainer = document.getElementById('image-container');
      imgContainer.appendChild(img);

      console.log('Done');
    })
  /*.on('error', function (err) {
      console.log('Error during download', err);
    })*/
}


function refreshFiles(setFiles){

  //window.gapi.client.request({
  //})

  window.gapi.client.drive.files.list({
    pageSize:10, 
    apiKey:API_KEY,
    fields: "nextPageToken, files(id, name)",
    spaces:'appDataFolder',
    parents:['appDataFolder'],
    scope:SCOPES
  },function(err,res){
    if (err) {
      // Handle error
      console.error(err);
    } else {
      res.files.forEach(function (file) {
        console.log('Found file: ', file.name, file.id);
      });

    }
  }).then(response => {
    console.log({files:response.result.files})
    setFiles(response.result.files);
  });

}

function createFile(){

  window.gapi.client.drive.files.create({resource:{
    name:'newFile.txt',
    parents:['appDataFolder']
  }}).then(console.log);
}

function uploadFile(file){

  let reader = new FileReader();

  let url = URL.createObjectURL(file);

  const boundary='foo_bar_baz'
  const delimiter = "\r\n--" + boundary + "\r\n";
  const close_delim = "\r\n--" + boundary + "--";
  var fileName='mychat123';
  //var fileData='this is a sample data';
  var contentType=file.type;
  var metadata = {
    'name': file.name,
    'mimeType': contentType,
    parents:['appDataFolder'],
  };



  reader.onload = e => {

    let fileData = e.target.result;
    console.log({fileData});



    var multipartRequestBody =
      delimiter +
      'Content-Type: application/json; charset=UTF-8\r\n\r\n' +
      JSON.stringify(metadata) +
      delimiter +
      'Content-Type: ' + contentType + '\r\n\r\n' +
      fileData+'\r\n'+
      close_delim;

    console.log(multipartRequestBody);

    //console.log(fethResp)

    var request = window.gapi.client.request({
      'path': 'https://www.googleapis.com/upload/drive/v3/drive.appdata',
      'method': 'POST',
      'params': {
        'uploadType': 'multipart'
      },
      'headers': {
        'Content-Type': 'multipart/related; boundary=' + boundary + ''
      },
      'body': multipartRequestBody});

    request.execute(function(file) {
      console.log("Output from execution: " + file) 
    });
  }


  reader.readAsDataURL(file);




}


export default function ImageStorageScene(){

  const [ firstRender, setFirstRender ] = useState(true);
  const [ userInfo, setUserInfo ] = useState(null);
  const [ signedIn, setSignedIn ] = useState(false);
  const [ files, setFiles ] = useState([]);

  let dispatch = useDispatch();




  return (
    <div class="sg-row">
      <div id="image-scene-container">
        <button onClick={() => refreshFiles(setFiles)}>List Files</button>

        <button onClick={() => uploadFile()}>Create File</button>

        <div>This component clears <code>window.gapi</code> for dev purposes.</div>

        {userInfo && <div>{JSON.stringify(userInfo)}</div>}


        <input type="file" onChange={e => {

          let toUpload = e.target.files[0]
          console.log({toUpload});
          uploadFile(toUpload)
        }}/>

        <div>
          {files.map(x => {
            return (
              <div onClick={() => downloadFile(x.id)}
                class="small-margin hoverable">
                <div>{x.id}</div>
                <div>{x.name}</div>
              </div>
            )
          })}
          <pre>{JSON.stringify(files,null,1)}</pre>
        </div>

      </div>
      <div id="image-container">
      </div>
    </div>
  )

}
