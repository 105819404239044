import Modal from 'react-bootstrap/Modal';
import Button from '@material-ui/core/Button';

import A from './ActionCreators';
import C from './Constants';
import { useDispatch } from 'react-redux';


let messageMap = { 
  allAnnotationTransferOptions:"Copy annotations to new image set?"
}

let optionsMap = {
  allAnnotationTransferOptions:[
    {label:"Cancel"},
    {
      label:"No",
      action:C.KEEP_DESTINATION_ANNOTATIONS
      
    },
    {
      label:"Copy",
      action:C.KEEP_BOTH_ANNOTATIONS,
      color:"primary"
    }
  ]
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function ImageManagementMessageModal({action,closeModal}){
  let dispatch = useDispatch();

  let plurality = action.imageIds.length === 1 ? "" : "s";
  let title = capitalizeFirstLetter(action.action) + " image"+plurality+"?";

  let { imageIds, destinationImageSetId } = action;


  return (

    <Modal size="m" show={action} onHide={closeModal}>

      <Modal.Header style={{background:'#fff'}}>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{background:'#f8f8f8'}}>

        {messageMap[action.messageKey]}
        
      </Modal.Body>

      <Modal.Footer>

        {optionsMap[action.messageKey].map( item => (
          <Button

            class={"btn btn-"+(item.color||'light')}
            color={item.color}
            style={{fontFamily:'Helvetica'}}
            variant="contained" 
            onClick={()=>{
              item.action && dispatch(A.moveImagesToImageSet({
                imageIds,destinationImageSetId,
                annotationTransferOption:item.action
              }))
              closeModal();
            }}>
            {item.label}
          </Button>
        ))}

      </Modal.Footer>

    </Modal>

  )

}


