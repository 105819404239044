import A from './ActionCreators';
import { v4 } from 'uuid';

export default function onUploadFilesSelected(files,dispatch){

  let urls = files.map(URL.createObjectURL)
  let mimes = files.map(f => f.type);
  let filenames = files.map( f => f.name );
  let imageSetIds = files.map( _ => v4() );
  let imageIds = files.map( _ => v4() );

  dispatch(A.startImageUploads({
    filenames,
    imageSetIds, 
    imageIds, 
    urls, 
    convertTo:"png", 
    storageLocation:"s3"
  }))
}
