import ManualAnnotationGrid from './ManualAnnotationGrid';
export default function AntibodyValidationTemplate({template}){

  return (
    <div style={{borderRadius:10, background:'#f8f8f8'}} class="sg-row fair-margin fair-padding">
      <div class="small-margin">

        <h5 style={{textAlign:'right',marginRight:40}}>Panel Template</h5>
      <ManualAnnotationGrid {...{
        figurePanelId:template.panelTemplateId,
        tableOutlineShowing:true
      }}/>
      </div>

      <div class="flex-expand"/>

      <div class="small-margin">
        <h5 style={{textAlign:'right',marginRight:15}}>Antibody Placement</h5>
      <ManualAnnotationGrid {...{
        figurePanelId:template.figureLayout,
        tableOutlineShowing:true
      }}/>
      </div>

      <div class="flex-expand"/>

      

      <div>

        <h5 style={{textAlign:'right',marginRight:15}}>Figure Preview</h5>
      </div>

      <div class="flex-expand"/>


    </div>
  )
}
