export default function getBoundingCorners(point) {
  let [x, y] = point;

  let flx = Math.floor(x);
  let cx = Math.floor(x + 1);
  let fly = Math.floor(y);
  let cy = Math.floor(y + 1);

  let deltaLeft = cx - x;
  let deltaRight = x + 1 - cx;
  let deltaTop = cy - y;
  let deltaBottom = y + 1 - cy;
  //console.log([deltaLeft,deltaTop,deltaRight,deltaBottom]);

  /*let spaces = [deltaLeft, deltaRight, deltaTop, deltaBottom].map((xx) => {
    return Math.abs(xx) >= 1 ? 0 : Math.abs(xx);
  });*/

  //console.log(JSON.stringify(point)+" -> "+JSON.stringify(spaces));

  let tl = deltaLeft * deltaTop;
  let tr = deltaRight * deltaTop;
  let bl = deltaLeft * deltaBottom;
  let br = deltaRight * deltaBottom;

  let borders = [
    { coord: [flx, fly], area: tl },
    { coord: [flx, cy], area: bl },
    { coord: [cx, fly], area: tr },
    { coord: [cx, cy], area: br }
  ];

  // console.log(borders);
  return borders;
}

