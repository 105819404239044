import ErrorIcon from '@material-ui/icons/Error';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';

export default function ImageFetchFailed({canClickToRetryDownload,imageId, onRetry, message}){

  let dispatch = useDispatch();


  return (
    <div onClick={e => {
      e.stopPropagation();
    }}
    style={{
      fontSize:10,
      cursor:'pointer',
    }}>
      {!canClickToRetryDownload && (
        <div item="image-fetch-status-text" style={{color:'#bbb'}}>
          {message || "Failed to load resource"}
        </div>
        )}
      {canClickToRetryDownload && (
        <div 
          button="retry-image-download"
          onClick={e => {
            e.stopPropagation();
            dispatch(A.fetchImages({
              imageSpecList:[{imageId,version:"raw-png"}]
            }))
          }}
          style={{
         
          fontSize:10,
          zIndex:1000,
          borderRadius:5,
          position:'absolute',
          width:210,
          border:'3px solid #ffbbbb',
          background:'#ffeeee',
          //color:'white',
          top:'50%',
          left:'50%',
          transform:'translate(-50%,-50%)',
          padding:2,
          display:'flex',
          whitespace:'nowrap',
          //top:-10,
        }}>
          <ErrorIcon style={{
            color:'#800000',
            fontSize:14, marginRight:2}}/>
          <span itemFetchStatus={"failed"} item="image-fetch-status-text" style={{textWrap:'nowrap'}}>
          Image download failed. Click to retry.
          </span>
        </div>
      )}
    </div>
  )


}
