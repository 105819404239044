import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ToolbarItem from './ToolbarItem';

import Tooltip from '@material-ui/core/Tooltip';
import Overlay from 'react-bootstrap/Overlay'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: 5,
  },
}));

const oppositeDirectionClass = {
  vertical:"sg-row",
  horizontal:"sg-col",
}

export default function PopoverToolbarItem({item,editorStyle,selectedFigureItems, unifiedGroup,setRetainInputFocus,className,scale,subToolbarDirection,panelGlobalStyle,figurePanelId}){

  let selectedCells = selectedFigureItems.cells;
  let toolbarItemProps = {editorStyle,selectedFigureItems, unifiedGroup,setRetainInputFocus,className,scale,panelGlobalStyle};

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    console.log(event.currentTarget.getBoundingClientRect());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log("setting anchorEl to null!");
    setAnchorEl(null);
  };

  

  //console.log({iconType:typeof(item.icon)});

  const getIcon = () => {
    let icon = item.icon;
    if( typeof(icon) === 'function' ){
      return icon(unifiedGroup.style);
    }else{
      return icon;
    }
    
  }

  const open = Boolean(anchorEl);

  if( open ){
    window.__handleMenuClose = handleClose;
  }



  const id = open ? 'simple-popover' : undefined;



  let currentDirection = item.subToolbarDirection;

  let subtoolbarClass = item.subToolbarDirection!=='vertical'?'sg-row':'sg-vert';

  if( item.styleProperty === "color" ){
    debugger;
  }

  let icon = getIcon();

  return (

    <>
      <div 
        onClick={handleClick}
        class="style-toolbar sg-row hoverable toolbar-button" style={{alignItems:'center', padding:4,borderRadius:5}}>

        {item.hint && icon && <Tooltip title={item.hint}>
          {icon}
          </Tooltip>}
      {!item.hint && item.icon}
        <ArrowDropDownIcon style={{width:15,height:15}}/>
      </div>

      {open && <Popover
        style={{
          zIndex:0
        }}
        className={"style-toolbar"}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>
          <div style={{
            border:'1px solid blue',
          }} class={subtoolbarClass}>
            {item.subToolbar.map( subToolbarItem => {

              if( Array.isArray( subToolbarItem ) ){
                return (
                  <div class={"sg-row"}>
                    {subToolbarItem.map(subsubItem => {
                      return <ToolbarItem {
                        ...{...toolbarItemProps,
                          item:subsubItem}
                        }/>
                    })}
                  </div>
                )
              }else{
                return <ToolbarItem 
                  {...{
                    figurePanelId,
                    item:subToolbarItem,
                    ...toolbarItemProps
                  }}
                  />
              }

            })}
          </div>

          
        </Typography>
      </Popover>}

    </>
  )

}

