import { useDragLayer } from 'react-dnd';
import TitledThumbnail from './TitledThumbnail';

function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none"
    };
  }
  let { x, y } = currentOffset;
  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    //[x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

export default function CustomDragLayer() {
  const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    //background:'rgba(0,0,255,0.5)'
  };
  const {
    itemType,
    dropped,
    isDragging,
    item,
    initialOffset,
    currentOffset,
    monitor
  } = useDragLayer((monitor) => ({
    monitor,
    dropped:!!monitor.didDrop(),
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function renderItem() {
    return (
      <div style={{ display: "flex" }}>
        <TitledThumbnail {...{ item, isDragPreview: true }} />
      </div>
    );
  }


  if (!isDragging) {
    return null;
  }
  if( !item ){
    return null;
  }else if( !item.type ){
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  );
}
