const displayMacroDialog = `
// --- fileLabels and fileKeys should be declared before this line ---

previouslySavedLocations = getImageLocations(fileKeys);
haveValidFilePaths = false;
unsubmitted = newArray(0);
submittedPaths = newArray(fileKeys.length);


while(!haveValidFilePaths){

	Dialog.create("Specify file locations...");


	for(iiFileLabels = 0; iiFileLabels < fileLabels.length; iiFileLabels++){
		if( previouslySavedLocations[iiFileLabels] != 0 ){		
			Dialog.addFile(fileLabels[iiFileLabels],previouslySavedLocations[iiFileLabels]);
		}else{
			Dialog.addFile(fileLabels[iiFileLabels],"")
		}
	}

	if( unsubmitted.length > 0 ){
		Dialog.addMessage(String.join(unsubmitted,", ") + " need valid file paths.",14,"red");
	}

	for(ii=0;ii<unsubmitted.length;ii++){
		Array.deleteIndex(unsubmitted, 0);
	}

	Dialog.show();
	
	unsubmitted = newArray(0);
	
	for(iiPath = 0; iiPath < fileKeys.length; iiPath++){
		path = Dialog.getString();
		submittedPaths[iiPath] = path;
		//print("Got " + path + " from the dialog!");
		if( !(File.exists(path)) ){
			//print("There was a file that didn't exist!");
			unsubmitted = Array.concat(unsubmitted,newArray(fileLabels[iiPath]));
		}
	}

	if( unsubmitted.length == 0){
		appendKeyValuePairsWhichDoNotMatchCurrentKVPairs(fileKeys,previouslySavedLocations,submittedPaths);
		haveValidFilePaths = true;
	}	
}

for(ii = 0; ii < fileLabels.length; ii++){
	open(submittedPaths[ii]); rename(fileLabels[ii]);
}


function getImageLocations(keyList){

	values = newArray(keyList.length);
	

	
	if( File.exists(imageLocationMapPath) ){

		print("MAP EXISTS!");
		
		f = File.openAsString(imageLocationMapPath);
		lines = split(f,"\\n");
		
		pairs = newArray(lines.length);
		for(iiLine = 0; iiLine < lines.length; iiLine++){			
			//pairs[iiLine] = split(lines[iiLine],":");
			pair = split(lines[iiLine],":");

			//we will always go until the end, because
			//if the user changes the file location
			//then it will append a new key:value to the file
			//and the final value will be the one at the very end of the file.
			for(jjKey = 0; jjKey < keyList.length; jjKey++){
				if(pair[0] == keyList[jjKey]){
					
					values[jjKey] = pair[1];
				}
			}
		}

		
    }else{
    	File.open(imageLocationMapPath);
    }

    return values;
    
}

function appendKeyValuePairsWhichDoNotMatchCurrentKVPairs(fileKeys,prevValues,newValues){
	for(iiKey = 0; iiKey < fileKeys.length; iiKey++){
		if( prevValues[iiKey] != newValues[iiKey] && File.exists(newValues[iiKey]) ){
			toAppend = fileKeys[iiKey]+":"+newValues[iiKey];
			File.append(toAppend, imageLocationMapPath);
		}
	}
}
`

export default displayMacroDialog;
