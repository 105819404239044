import G from './Getters';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';

export default function RefreshAccountButton(){

  const [ throttled, setThrottled ] = useState(false);

  let dispatch = useDispatch();

  let {
    refreshPending,
    refreshFailed
  } = useSelector(state => {
    let response = G.getRequestResponse(state,{route:"/getSubscriptionInfo"});
    if( response ){
      return {
        refreshPending:response.pending,
        refreshFailed:response.status === 'failed'
      }

    }else{
      return {
        refreshPending:false,
        refreshFailed:false
      }
    }
  });





  let refreshButton = (<button disabled={throttled || refreshPending} onClick={() => {

    setThrottled(true);
    setTimeout(() => setThrottled(false),2500);

    let onClickAction = A.makeRequest({
      route:'/getSubscriptionInfo',
      body:{ productId:"sciugoMain" },
      onSuccess:(res) => {
        let { data } = res;
        dispatch(A.setSubscriptionInfo({
          productId:"sciugoMain",
          ...data
        }))
        dispatch(A.setWarnings({
          subscriptionRefereshNeeded:false
        }))
      },
      onFailure:() => {
      }
    })

    dispatch(onClickAction);


  }}
    action={"refreshSubscriptionInfo"}
    class="btn btn-warning btn-sm bold">
    {throttled && (
      <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    )}
    {!throttled && (
      <popover>
      <div>
        <div>Refresh Account</div>
        {refreshFailed && <div errorMessage={"refreshSubscriptionInfo"} style={{padding:2,margin:0}} class="alert alert-danger">Refresh failed.</div>}
      </div>
      </popover>
    )}
  </button>)

  return refreshButton;
}
