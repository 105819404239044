import { useState } from 'react';
import CollapsedIcon from '@material-ui/icons/ArrowRight';
import ExpandedIcon from '@material-ui/icons/ArrowDropDown';

export default function CollapsibleProtocol({heading,info, content,defaultOpen}){

  const [ open, setOpen ] = useState(!!defaultOpen);

  const header = (

    <div onClick={() => setOpen(!open)} class="hoverable" style={{
      paddingLeft:5,
      marginLeft:-20,
      }} class="sg-row">
      {open && <div><ExpandedIcon/></div>}
      {!open && <div><CollapsedIcon/></div>}
      <h6 class="clickable-text" style={{marginBottom:20}}>{heading}</h6>
    </div>

  )



  return (
    <div style={{}}>
      {header}
      <div class={`collapsible-protocol-content ${open?'open-content':''}`} style={{
        /*
        paddingLeft:15,
        paddingTop:15,
        paddingBottom:15,
        */
        
        //border:'1px solid #ccc',

        
        }}>
        {open && content}
      </div>
    </div>

  )



}

export function EntityFormItem({ title, entity }){

  return (
    <div class="sg-row">

      <div style={{
        paddingTop:2,
        paddingBottom:2,
        fontWeight:'bold'
      }}>
        {`${title}:`}
        &nbsp;
      </div>
      <div style={{ border:'1px solid #ccc',
        padding:2,
        borderRadius:5,
      }} class="clickable-text hoverable">

        {entity}
      </div>
    </div>
  )

}

export const ElectricityFormItem = ({bufferType}) => (

  <div>
    <div class="sg-row">

      <div class="sg-col">
        <div><b>Electricity</b></div>
        <input value={100} style={{width:50}}/>
      </div>

      <div class="small-margin-left sg-col">
        <div><b>Units</b></div>
        <select style={{fontSize:15}}>
          <option>V</option>
        </select>
      </div>

      <div style={{width:50}}/>

      <div class="sg-col">
        <div><b>Time</b></div>
        <input value={1} style={{width:50}}/>
      </div>

      <div class="small-margin-left sg-col">
        <div><b>Units</b></div>
        <select style={{fontSize:15}}>
          <option>hour(s)</option>
        </select>
      </div>


    </div>

    <div class="sg-row fair-margin-top">

      <EntityFormItem {...{ title:bufferType, entity:"Tris-glycine SDS"} }/>




    </div>


  </div>

)

