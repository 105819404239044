import { scale } from './Geometry';

import { isDeleteObjectKey } from './EventUtils';
import SciugoInput from './SciugoInput';
import A from './ActionCreators';
import DeleteAnnotationButton from './DeleteAnnotationButton';
import { useDispatch } from 'react-redux';
import { useContext, useState, useEffect } from 'react';
import G from './Getters';
import usePresentSelector from './usePresentSelector';
import { AnnotationStyleContext } from './Contexts';




export default function MarkLabel({annotation,imgWidth,
  isFocusedAnnotation, setFocusedAnnotationId,
  onDelete}){



  let annotationStyleContext = useContext(AnnotationStyleContext);



  let { lsToAnnotationBox, isInsideAnnotationBox } = usePresentSelector(state => {
    if( !(annotation?._id) ){
      return {};
    }
     return G.getLsFromMarkToAnnotation(state,{markId:annotation._id}) || {};
  }); 

  let lsToAnnotationComponent = null;
  if( lsToAnnotationBox ){
    let scaledLs = lsToAnnotationBox.map(p => scale(imgWidth,p));
    lsToAnnotationComponent = (
      <line 
        x1={scaledLs[0][0]} y1={scaledLs[0][1]}
        x2={scaledLs[1][0]} y2={scaledLs[1][1]}
        stroke={isInsideAnnotationBox ? "lime" : "orange"}
        strokeWidth={4}
      />
    )
  }




  let dispatch = useDispatch();

  let { _id , mark, displayPosition } = annotation;

  displayPosition = scale(imgWidth,(displayPosition || mark))



  let [cx,cy] = scale(imgWidth, mark);

  let [tempValue,setTempValue] = useState(annotation.label);

  let [dragInfo,setDragInfo] = useState();

  let inputDelta = [0,0]
  if( dragInfo ){
    inputDelta = [dragInfo.mousePos[0] - dragInfo.dragStartPos[0], dragInfo.mousePos[1] - dragInfo.dragStartPos[1] ]
  }

  const onMouseMove = e => {


    

    if( dragInfo ){

      setDragInfo({
        ...dragInfo,
        mousePos:[e.clientX,e.clientY]
      })
    }
  }

  const onMouseUp = e => {


    let newDisplayPosition = scale(1/imgWidth,[displayPosition[0] + inputDelta[0], displayPosition[1] + inputDelta[1]])

    if( dragInfo ){
      setDragInfo(null);
      dispatch(A.setAnnotationProperties({
        _id,
        displayPosition:newDisplayPosition
      }))
    }

    //alert("Mouse came up!");



  }

  function onKeyDown(e){
    if( e.key === 'Escape' ){
      dragInfo && setDragInfo(null);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('mousemove',onMouseMove);
    window.addEventListener('mouseup',onMouseUp);

    return () => {
      window.removeEventListener('mousemove',onMouseMove);
      window.removeEventListener('mouseup',onMouseUp);
      window.removeEventListener('keydown', onKeyDown);

    }
  },[dragInfo])

  const [entered,setEntered] = useState(false);


  let circleArgs = { cx, cy, r:5, fill:'red' };


  let extrasSurroundingLabelForMovability = true;

  

  if( annotationStyleContext ){
    let { mwMarkers } = annotationStyleContext;
    if( mwMarkers ){
      let { point, label } = mwMarkers;
      if( point ){
        Object.entries(point).forEach(([key,value]) => {
          circleArgs[key] = value;
        })
      }

      if( label && label.extrasSurroundingLabelForMovability === false ){
        extrasSurroundingLabelForMovability = false;
      }
    }
  }


  let styleSurroundingLabel = {
    background:(dragInfo && 'lime'),
    border:'1px solid #aaa'
  }

  if( extrasSurroundingLabelForMovability === false ){
    styleSurroundingLabel = {};
  }


  return (
    <>

      {lsToAnnotationComponent}

      <line pointerEvents={"none"} stroke={"red"} strokeOpacity={0.25} strokeWidth={2} fill="black" x1={cx} y1={cy} x2={displayPosition[0]+inputDelta[0]} y2={displayPosition[1]+inputDelta[1]}/>
      <g 
        container={"outer-annotation-mark-container"}

        onMouseEnter={e => setEntered(true)}
        onMouseLeave={e => setEntered(false)}
        style={{
          //border:'1px solid magenta',
        }}

        zIndex={dragInfo ? 2000 : 3}

        onMouseDown={e => {



          setDragInfo({
            mousePos:[e.clientX,e.clientY],
            dragStartPos:[e.clientX,e.clientY]
          })

        }}>

        



        <circle {
          ...circleArgs
          }/>

        <rect x={displayPosition[0] - 15 + inputDelta[0]} 
          y={displayPosition[1] - 12 + inputDelta[1]} 
          width={30} 
          height={22}
          fill={"rgba(220,220,255,0.3)"}
          strokeRadius={20}
        />


        <foreignObject
          item={"annotation-mark-container"}
          onMouseUp={e => onMouseUp()}
          style={styleSurroundingLabel} 
          class="grabbable"
          x={displayPosition[0] - 15 + inputDelta[0]} 
          y={displayPosition[1] - 12 + inputDelta[1]} 
          width={30} 
          height={24}>
          <div>
            <div style={{display:'flex'}}>
              {true && <SciugoInput 
                input={"mark-input"}
                onKeyDown={e => {
                  if( isDeleteObjectKey(e) && tempValue === '' ){
                    dispatch(A.deleteAnnotation({_id:annotation._id}))
                  }
                }}
                onChange={e => setTempValue(e.target.value)}
                inputRef={el => isFocusedAnnotation && el && el.focus()} 
                onClick={e => { 
                  e.stopPropagation() // click on the input box should not propagate to foreignObject
                  setFocusedAnnotationId(annotation._id);
                }}
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => onMouseUp()}
                value={tempValue}
                onBlur={e => {
                  dispatch(A.setAnnotationProperties({
                    _id:annotation._id,
                    label:e.target.value

                  }))
                }}

                placeholder={"kDa"} 
                style={{
                  margin:0,
                  transform:'translate(2px,3px)',
                  opacity:0.9,width:25,fontSize:8,
                  height:15,
                }}/>}
            </div>

          </div>
        </foreignObject>

        {entered && <DeleteAnnotationButton {...{
          onDelete,
          cx:displayPosition[0]+12.5 + inputDelta[0],
          cy:displayPosition[1]-10 + inputDelta[1],
          r:5,
          annotationId:annotation._id,
          fontSize:8
        }}/>}


        {extrasSurroundingLabelForMovability && <rect {...{
          item:"mw-dragging-box",
          onMouseDown:e => {
            setDragInfo({
              mousePos:[e.clientX,e.clientY],
              dragStartPos:[e.clientX,e.clientY]
            })
          },
          style:{ cursor:"grab" },
          x:(displayPosition[0] - 21 + inputDelta[0]),
          y:displayPosition[1] - 12 + inputDelta[1],
          width:8,
          height:24,
          fill:"#ccc",
          borderTopLeftRadius:10,
        }}/>}

      </g>

    </>
  )


}
