import React, { Children, cloneElement, useContext, useState, useEffect, useRef } from 'react';
import ExpansionTextItem from './ExpansionTextItem';

import SubregionOutlineLayer from './SubregionOutlineLayer';

import { GridCellContext, ExpansionTemplateInfo, ExpansionWindowFocusContext } from './Contexts';

import FigureImageExpansionTemplateNode from './FigureImageExpansionTemplateNode';
import FigureImageExpansionLayoutValue from './FigureImageExpansionLayoutValue';

import Scalebar from './Scalebar';
import SubregionWindow from './SubregionWindow';



function u(){
  return {
    value:0,
    units:'px'
  }
}

function resolvePos(pos,parentBox,dragDelta){
    return (pos.units === 'px' ? pos.value : (
    pos.value/100 * parentBox.width
  ))
}
 
function expandBase(location){
  return {
    alignItems:({t:"start",m:"center",b:"flex-end"})[location[0]],
    justifyContent:({l:"start",m:"center",r:"flex-end"})[location[1]],
  }
}

const DRAGGABLE = false;





export default function TemplateNodePositionedItem({
  selectedNodes,
  onEvent,
  evaluate,
  nodeId,
  node,
  parentBox, 
  nodes, 
  regions
}){


  let gridCellContext = useContext(GridCellContext);

  let { cellLocation } = gridCellContext;

  let expansionTemplateInfo = useContext(ExpansionTemplateInfo);

  let expansionWindowFocusContext = useContext(ExpansionWindowFocusContext);
  
  let { onItemClick, focusedExpansionInfo } = expansionWindowFocusContext || {};

  
  
  let selected = selectedNodes?.find((item) => {
    let thatNodeId = item.nodeId;
    let thatNode = item;

    let eqCL = thatNode.cellLocation.every(
      (x,ii) => x === cellLocation[ii]
    );

    
    return thatNodeId === nodeId &&  eqCL;
  })

  let { thumbnail } = expansionTemplateInfo || {};

  let { 
    type, label, 
    position, 
    style={}, 
    setItem, 
  } = node;


  
  let { top=u(),left=u(), bottom=u(), right=u(), width=u(), height=u() } = position || {};

  //if( !position ){ debugger; }
  if( position && position.base ){
    position = expandBase(position.base);
  }


  const [dragStart,setDragStart] = useState();
  const [mousePos,setMousePos] = useState();
  const [box, setBox] = useState({});


  let dragDelta = dragStart ? ({
    x:(mousePos.x - dragStart.x),
    y:(mousePos.y - dragStart.y)
  }) : ({x:0, y:0, w:0, h:0})

  let x = resolvePos(left||right,parentBox) + dragDelta.x;

  let y = resolvePos(top||bottom,parentBox) + dragDelta.y;

  let w = (width.units === 'px' ? width.value : (
    width.value/100 * parentBox.width
  )) + dragDelta.w;

  let h = (height.units === 'px' ? height.value : (
    height.value/100 * parentBox.height
  )) + dragDelta.h;





  /*
  useEffect(() => {

    const mouseMove = (e) => {
      if( dragStart ){
        setMousePos({x:e.clientX, y:e.clientY});
      }
    }


    const mouseUp = () => {
      if( !dragStart ){
        return;
      }

      let toSet = {
        top:{ 
          value:(top.units === 'px' ? y : (
            Number((y/parentBox.height * 100).toFixed(2))
          )), 
          units:top.units 
        }, 
        left:{ 
          value:(left.units === 'px' ? x : (
            Number((x/parentBox.width * 100).toFixed(2))
          )), 
          units:left.units 
        }
      }
      

      setDragStart(null);
      setMousePos(null);

      setItem && setItem(toSet);
    }

    window.addEventListener('mousemove',mouseMove);
    window.addEventListener('mouseup',mouseUp);
    return () => {
      window.removeEventListener('mousemove',mouseMove);
      window.removeEventListener('mouseup',mouseUp);

    }
  })*/

  let corners = {
    tl:[x,y],
    tr:[x+w,y],
    br:[x+w,y+h],
    bl:[x,y+h]
  }


 
  let positionedItem = getPositionedItem({
  type, style, position, nodeId, node, nodes, regions,
  selected, onEvent, onItemClick,
  thumbnail,
  label,
  evaluate,
  parentBox,
  })




  return (
    <>

      {dragStart && <GuidelineLayer corners={corners}/>}

      <div class="sg-row absolute-cover"  style={{
        //pointerEvents: none here
        /*
         I always forget why..
         It's because you don't want to click on the pointer layer
         and have that handle the click...
        */

        //pointerEvents:'none',

        alignItems:(position?.alignItems),//'flex-end',
        justifyContent:(position?.justifyContent),//'flex-end',
      }}>
       
        <div style={{position:'relative'}} class="clickable sg-row outline-on-hover">
          {positionedItem}
          
        </div>
      </div>

      {DRAGGABLE && (<div
        ref={(el) => {}}
        class={"grabbable hoverable"}
        onClick={e => {

          if( !evaluate ){
          }

        }}
        onMouseDown={e => {
          setDragStart({x:e.clientX, y:e.clientY})
          setMousePos({x:e.clientX, y:e.clientY})
        }}
        style={{
          //position:'absolute',
          position:'absolute',
          width:w,
          height:h,
          top:(position?.top && y),
          bottom:(position?.bottom && y),
          left:(position?.left && x),
          right:(position?.right && x),
          transform:`translate(${position?.right ? '-100%' : 0},${position?.bottom ? '-100%' : 0})`,
          fontSize:8,
          overflow:'visible',
          background:'pink',
          ...style,
        }}
      >
      </div>)}
    </>
  )

}

function GuidelineLayer({corners}){

  let guidelineRef = useRef(null);


  return (
    <div 
      ref={guidelineRef}
      style={{
        position:'absolute',
        pointerEvents:'none',
      
      //background:'pink',
      //outline:'1px solid blue',
      top:0,
      left:0,
      width:'100%',
      height:'100%', zIndex:10,
    }}>


    </div>
  )
}


function getPositionedItem({
  type, style, position, nodeId, node, nodes, regions,
  selected, onEvent, onItemClick,
  thumbnail,
  label,
  evaluate,
  parentBox,


}){

  if( type === "regionOutline" ){
    return null;
  }

  let { component, props } = (() => {

  switch( type ){
    case "scalebar":{
      return {
        component:Scalebar,
        props:{
          selected,

          ref:el => {
            if( el ){
              alert("RENDERED SCALEBAR!");
            }else{
              alert("Beh?");
            }
          },

          onEvent:(onEvent||onItemClick),
          parentBox,
          style,
          position,
          nodeId,
          node,
          nodes,
          regions,
        }
      }
    }
    case "text":{


      let textStyle = {...style};
      if( thumbnail ){
        textStyle.fontSize = '1vw';
      }

      let borderProps = ["borderTop","borderBottom","borderLeft","borderRight"];

      let borderStyles = {};



      borderProps.forEach(prop => {
        let borderStyleInfo = textStyle[prop];

        let { borderThickness=1, borderColor="black" } = textStyle || {};
        if( borderStyleInfo ){
          borderStyles[prop] = 
            `${borderThickness}px solid ${borderColor}`;
        }
      })


      return {
        component:ExpansionTextItem,
        props: {
          selected,
          nodeId,
          node,
          textStyle:{
            ...textStyle,
            ...borderStyles
          },
          label:(label||"Label"),//:"100",

          onEvent:(onEvent||onItemClick),
        }
      }
      break;
    }
    case "region":{

      return {
        component:SubregionWindow,
        props:{
          selected,
          onItemClick,
          evaluate,
          nodeId,
          node,
          nodes,
          regions,
          parentBox,
          thumbnail,
          style,
        }
      }
    }
    case "regionOutline":{
      
      return {
        component:SubregionOutlineLayer,

        props:{
          selected,
          nodeId,
          node,
          regions,
          onItemClick,
          position:{},
          onEvent:(onEvent||onItemClick),
        }
      }
    }
    default:
      throw Error(`No registered node type '${type}'.`);

  }
  
  })()

  return React.createElement(component,props);
}
