import replaceWithGreek from './replaceWithGreek';

export default function SciugoInput({...props}){
  let { inputRef } = props;


  return (
    <input ref={inputRef} {...{
      ...props,
      onChange:(e) => {

        e.target.value = replaceWithGreek(e.target.value);

        props.onChange && props.onChange(e);



      }
    }}/>
  )

}
