import { useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import { ChildTypes } from './Filesystem';
import G from './Getters';
import A from './ActionCreators';
import React, { useContext } from 'react';

import FilesystemList from './FilesystemList';
import CollapsedIcon from '@material-ui/icons/ArrowRight';
import ExpandedIcon from '@material-ui/icons/ArrowDropDown';


import TitledThumbnail from './TitledThumbnail';
import SetFigureImageButton from './FavouriteImageSetImageButton';

import QuantificationListToggler from './QuantificationListToggler';

import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from "react-dnd-html5-backend";

import { useDispatch } from 'react-redux';
import { IMAGE_SETS, DIRECTORIES, IMAGE_UPLOADS, } from './RecordTypes';

import { FilesystemHierarchyContext, FilesystemStore } from './Contexts';

import { FilesystemItemHoverContext, FilesystemColumnWidthContext } from './Contexts';

const ICON_DIM = 16;


const expandabilityMap = {
  [IMAGE_SETS]:(children) => children.length > 1,
  [DIRECTORIES]:(children) => children.length > 0
}

function getExpandability({item,children}){
  let itemType = item.type || item.itemType;

  let expFun = expandabilityMap[itemType];

  let result = !!(expFun && expFun(children))
  return result;

}

function ExpandedStateIcon({expandable, expanded,setExpanded,children,item, treePath, filesystemContainer}){

  //let isExpandable = getExpandability({item,children});

  if( expandable ){
    let comp = expanded ? ExpandedIcon : CollapsedIcon;
    let interior = React.createElement(comp,{style:{fontSize:16}});
    return (
      <div treePath={treePath?.join('-')}
        filesystemContainer={filesystemContainer}
        expanded={""+expanded}
        action={"toggle-fileSystem-list-expansion"}
        onClick={()=>setExpanded(!expanded)}>
        {interior}
      </div>
    )
  }else{
    return <div style={{width:ICON_DIM,height:ICON_DIM}}/>
  }
}

function distinctDropTargets(t1, t2){
  return JSON.stringify(t1) !== JSON.stringify(t2);
}

export default function FilesystemItem({

  paddingLeft,

  treePath,
  filesystemContainer,

  item,
  treeLevel,
  contextMenuInfo,

  parentFilesystemId,

  setContextMenuInfo,
  setActivelyRenamingFilesystemItem,
  activelyRenamingFilesystemItem,

  dropTargetId,
  setDropTargetId,

  sortInfo,

}){


  let fsStore = useContext(FilesystemStore);
  if( !fsStore ){
    debugger;
  }

  let { isFilesystemItemSelected, onFilesystemItemEvent } = fsStore;



  let dispatch = useDispatch();

  let record = useSelector(state => G.getRecord(state,item));
  let { data, meta, type } = record;

  let state = useSelector(state => state);

  let isDropTarget = dropTargetId === item._id;


  const [ expanded, setExpanded ] = useState(false);

  const [{ canDrop, hover }, drop] = useDrop(
    () => {
      return ({
        accept: ChildTypes[item.type],
        drop: (draggedItem) => {

          

          //alert("DROPPED (FsItem)!");

          let resolvedDropTargetId = G.getDropTargetId(state,{
            draggedId:draggedItem._id,
            dropTargetId:item._id
          });



          //console.error("Handling drop to ("+resolvedDropTargetId+")!");

          if( resolvedDropTargetId ){

            debugger;

            let moveFsItemArgs = {
              _id:draggedItem._id,
              itemType:draggedItem.type,
              to:item._id,
              //to:resolvedDropTargetId//item._id
            }
            console.error(JSON.stringify(moveFsItemArgs));
            dispatch(A.moveFilesystemItem(
              moveFsItemArgs
            ));
          }
        },
        collect: (monitor) => {

          let isOver = !!monitor.isOver();
          let resolvedDropTargetId;
          if( !isOver ){
            return {
              resolvedDropTargetId
            }
          }

          let draggedItem = monitor.getItem();


          if( draggedItem ){

            resolvedDropTargetId = G.getDropTargetId(state,{
              draggedId:draggedItem._id,
              dropTargetId:item._id
            });


            if( distinctDropTargets(resolvedDropTargetId, dropTargetId) ){
              setDropTargetId(resolvedDropTargetId);
            }
          }
          return { 
            resolvedDropTargetId 
          };
        }
      })
    },
    [state,dropTargetId]
  );

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: (item.type || item.itemType),
    item:{ ...item, ...record },

    end:(item,monitor) => {
      setDropTargetId && setDropTargetId(null);
    },

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  function attachRef(el) {
    drag(el);
    drop(el);
    //preview(el);
  }


  let itemTypeGetterFunctions = useContext(FilesystemHierarchyContext);


  let { children, expandable } = useSelector(state => {

    let { type } = item;
    if( itemTypeGetterFunctions && itemTypeGetterFunctions[type] ){

      let hierarchyConfig = itemTypeGetterFunctions[type](
        state,item._id);

      return hierarchyConfig;


    }else{
      let children = G.getFsChildren(state,item)
      return {
        children,
        expandable:getExpandability({item,children})
      }
    }


  });




  //filesystemName
  //last edited date
  //creationDate
  //icon
  //notification
  //children


  //text of folder will be bold?
  //or we can have the dot
  //like in messenger
  let notification;


  let hasChildren = children.length > 0;
  let expansionToggleIcon = <ExpandedStateIcon {...{ expandable, item, treePath, filesystemContainer, expanded, setExpanded, children}}/>;


  let isActiveDropTarget = isFilesystemItemSelected(item._id);

  let focusClassAddition = isActiveDropTarget ? 'selected' : '';

  let className = "sg-row ";
  if( !isDropTarget ){
    className += "hoverable " + focusClassAddition;
  }else{
    className += "selected";
  }



  let renaming = activelyRenamingFilesystemItem === item._id;
  let setRenaming = setActivelyRenamingFilesystemItem;


  let figureImageSelection;
  if( (item.type||item.itemType) === IMAGE_UPLOADS ){
    figureImageSelection = (
      <SetFigureImageButton {...{
        treePath,
        imageId:item._id,
        imageSet:G.getImageSetByImageId(state,item._id)
      }}/>
    )
  }

  const { filesystemItemHovered, onFilesystemItemHoverChange } = useContext(FilesystemItemHoverContext);

  const { onColumnSizeChange, columnMinWidths } = useContext(FilesystemColumnWidthContext);

  let filesystemNameComponent = (
    <div style={{alignItems:'center',
      //border:'1px solid black',
      paddingLeft:(paddingLeft||0),
      position:'relative',

      overflow:'hidden',
      textOverflow:'ellipsis',

      width:columnMinWidths[0],
      whiteSpace:'nowrap'

    }} class="sg-row">
      {figureImageSelection}

      <div filesystemContainer={filesystemContainer} treePath={treePath.join('-')}>
        {expansionToggleIcon}
      </div>
      <div style={{
        //border:'1px solid blue'
      }} treePath={treePath.join('-')}
        info={"draggable-filesystem-component"}
        ref={setDropTargetId && drag}>
        <TitledThumbnail  key={item._id} {...{ 
          treePath, 
          filesystemContainer, 
          item, 
          renaming, 
          setRenaming 
        }}/>
      </div>
    </div>
  )


  const fsBounds = useRef();


  let thisItemIsHovered = (filesystemItemHovered===item._id);


  return (
    <>
      <div

        onMouseEnter={() => {

          onFilesystemItemHoverChange({enter:item,rect:fsBounds.current})
        }}

        onMouseLeave={() => {
          onFilesystemItemHoverChange({leave:item})
        }}

        filesystemContainer={filesystemContainer}
        treePath={treePath.join('-')}

        expanded={expanded}
        ref={el => {
          drop(el);
          if( el ){
            fsBounds.current = el.getBoundingClientRect();
          }
        }} 
        class={className} 
        onClick={event => {
          if( expandable ){
            setExpanded(!expanded);
          }

          onFilesystemItemEvent && onFilesystemItemEvent({ _id:item._id, event, item });

          
          //setFocusedFsItemId && setFocusedFsItemId(item._id); 
          onFilesystemItemHoverChange({leave:item})
        }}

        dropTargetActivity={isActiveDropTarget ? "active" :"inactive"}



        onContextMenu={e => {

          e.preventDefault();
          e.stopPropagation();
          if( setContextMenuInfo ){
            setContextMenuInfo({x:e.clientX,y:e.clientY,filesystemItem:item})
          }
        }}
        style={{
          display:'flex',
          position:'relative',
          alignItems:'center',

        }}>


        {filesystemNameComponent}
        <div style={{
          color:'#aaa',
          paddingLeft:5,
          width:columnMinWidths[1],
          overflow:'hidden',
          textOverflow:'ellipsis',
          whiteSpace:'nowrap',

          position:'relative',

          //border:'1px solid black'
        }}>
          {item.lastEditedDate}



        </div>

        <div style={{
          paddingLeft:5,
          width:columnMinWidths[2],
          color:'#aaa',

          overflow:'hidden',
          textOverflow:'ellipsis',
          whiteSpace:'nowrap',
          position:'relative',

          //border:'1px solid black'



        }}>
          {item.creationDate}
          {false && <div style={{color:'red'}} class="abs-right">{columnMinWidths[2]}</div>}

        </div>



        {contextMenuInfo && contextMenuInfo.filesystemItem._id === item._id && <div style={{
          position:'absolute',top:0,left:0,
          pointerEvents:'none',
          boxSizing:'border-box',
          border:'2px solid rgb(0,81,230)',
          width:'100%',
          height:'100%'
        }}/>}


        {thisItemIsHovered && (
          <div style={{position:'absolute',
            top:0,
            right:-100,
            width:100,
            height:100,
            background:'blue',
            zIndex:200,
          }}>

          </div>
        )}

        <QuantificationListToggler {...{
          treePath,
          _id:item._id,
          item,
          children
        }}/>
            

      </div>

      


      {expandable && expanded && <div style={{
        /*paddingLeft:(
          item.itemType === IMAGE_UPLOADS ? 0 : ICON_DIM ),*/
        //border:'1px solid blue'
      }}>

        <FilesystemList {...{
          paddingLeft:(paddingLeft||0) + (
            item.itemType === IMAGE_UPLOADS ? 0 : ICON_DIM 
          ),
          treePath,
          filesystemContainer,

          setActivelyRenamingFilesystemItem,
          activelyRenamingFilesystemItem,

          //parentIsBeingDragged:(item._id ===

          setContextMenuInfo,

          treeLevel:treeLevel+1,
          itemType:(item.type||item.itemType),
          _id:item._id,


          sortInfo,

          setDropTargetId,
          dropTargetId,
        }}/>



      </div>}
    </>

  )

}
