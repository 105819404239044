import { bump } from './LaneAdjuster';
import QuantificationLaneBox from './QuantificationLaneBox';





export default function QuantificationLaneBoxContainer({
  imageContainerBounds,
  evaluatedLaneOffsets,
  imagePresent,
  leftBump,
  rightBump,
  fullImageWidth,
  scaledAnnotationHeight,
  currentLeftMostPosition,
  currentLength,
  dist,
  quantifications,
  atn

}){

  return (

    <div>

      <div style={{pointerEvents:'none',width:'100%',height:'100%',top:0,position:'absolute',border:'1px solid #ccc'}}/>
      {imagePresent && imageContainerBounds && <svg 
        key={Math.random()}
        style={{position:'absolute',width:'100%',height:'100%',top:0, overflow:'visible',
          //border:'5px solid orange',
        }}>
        {evaluatedLaneOffsets.map((laneBoundaryPositions,ii) => {

          let prevIndex = ii-1;
          let nextIndex = ii+1;



          let leftDragLimit = ii === 0?0:evaluatedLaneOffsets[prevIndex][1];
          let rightDragLimit = ii === (evaluatedLaneOffsets.length - 1) ? dist : evaluatedLaneOffsets[nextIndex][0] ;

          //console.log(laneBoundaryPositions);
          return (
            <QuantificationLaneBox {...{
              key:(ii+'containerWidth='+fullImageWidth+'-containerStart='+imageContainerBounds.x+"-"+imageContainerBounds.width),
              leftDragLimit,
              rightDragLimit,
              height:scaledAnnotationHeight,
              laneBoundaryPositions:bump({
                laneBoundaryPositions,
                leftBump,
                rightBump,
                currentLeftMostPosition,
                currentLength,
                dist
              }),
              laneIndex:ii,
              lsDistance:dist,
              containerTop:imageContainerBounds.y,
              containerWidth:fullImageWidth,
              containerHeight:imageContainerBounds.height,
              containerStart:imageContainerBounds.x,
              quantification:quantifications && quantifications[ii],
              annotationId:atn._id
            }} />
          )
        })}
      </svg>}



    </div>

  )

}
