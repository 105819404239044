import { useDispatch } from 'react-redux';
import A from './ActionCreators';


const dim = 25;


const locationMap = {
  tl:{ alignItems:'start', justifyContent:'start' },
  tr:{ alignItems:'start', justifyContent:'flex-end' },
  bl:{ alignItems:'flex-end', justifyContent:'flex-end' },
  br:{ alignItems:'flex-end', justifyContent:'start' },
}

function CornerLocker({rotation,onEvent,locationCode,selected}){

  return (

    <div addLocation={locationCode} onClick={(e) => {
      //dispatch(A.setRegionExpansionNodeProperties({ templateId:"t0", nodeIdList:focusedNodeIdList, properties:{ position } }));
      onEvent({
        event:e,
        position:locationCode
      })
      /*
        type:"change",
        event:e,
        properties:{
          "position.base":locationCode
        }
      });*/
    }} class="show-child-on-hover clickable hoverable" style={{
      border:'1px solid #ccc',
      width:dim,height:dim,
      background:(selected?"blue":undefined),
    }}>
    </div>

  )
}


const locationCodes = [
  ["tl","tm","tr"],
  ["ml","mm","mr"],
  ["bl","bm","br"],
];

export default function TemplatePositionLock({selectedFigureItems,data,args,onEvent}){

  let selectedPosition = data?.position?.base;


  return (

    <div style={{width:dim * 3,height:dim * 3, 
      border:'1px solid #ccc',
      position:'relative',
      }}>
      {locationCodes.map(row => {
        return <div class="sg-row">{row.map(code => {
          return <CornerLocker {...{
            locationCode:code,
            onEvent,
            selected:(selectedPosition === code),
          }}/>
        })}</div>


      })}
    </div>

  )
}
