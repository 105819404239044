import Popover from '@material-ui/core/Popover';
import HelpIcon from '@material-ui/icons/Help';
/*
import RotateGif from './gifs/RotateImage.gif';
import AnnotateGif from './gifs/PrecisionLabelling.gif';
import CroppingGif from './gifs/Cropping.gif';
*/
import { useState } from 'react';

const gifSizes = {
    rotate:[300,300],
    'precision labeling':[400,250],
    crop:[400,250]
  }

const gifMap = {
  /*extractMembranes:RotateGif,
    labelBands:AnnotateGif,
    crop:CroppingGif*/
  }

export default function HelpPopover({mode,anchorElement, popover}){

  const [ popoverAnchor, setPopoverAnchor ] = useState(null);
  let helpPopoverOpen = Boolean(popoverAnchor);


  let popoverElement;
  if(popover){
    popoverElement = popover;
  }else{
    popoverElement = <HelpInfo/>;
  }

  return (
    <>
      <Popover 
        open={helpPopoverOpen}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >

        {popoverElement}
        {gifSizes[mode] && <div style={{
          width:gifSizes[mode][0], height:gifSizes[mode][1]
        }}>
          
          {false && <img style={{
            width:'100%',
            objectFit:'contain'}} src={gifMap[mode]}/>}
        </div>}
      </Popover>
      <div 
        id={"help-button"}
        onClick={e => setPopoverAnchor(e.currentTarget)}
        style={{transform:(!anchorElement &&'translate(0,-25%)')}} class="hoverable">
        {anchorElement||<HelpIcon style={{color:'blue',fontSize:40}}/>}
      </div>
    </>
  )
}

let items = [
  {
    title:'To crop:',
    instruction:'Click and drag.',
  },

  {
    title:'To mark molecular weights:',
    instruction:'Click.'
  },

  {
    title:'(While mouse up) To clear line hint (for molecular weights):',
    instruction:'Shift.',
  },

  {
    title:'Use/cancel line hint:',
    instruction:'Shift.',
  },

  {
    title:'To use hint by default:',
    instruction:"In settings, check off 'Use line hint by default'."
  },
  {
    title:'To view membrane while annotating:',
    instruction:"Select the image with signal only in 'My Images' as the 'Figure Image'"
  }


]

const HelpInfo = () => {

  return <div style={{display:'flex',flexDirection:'column',fontSize:14,width:400}}>{items.map( ({title,instruction}) => {
    return (
      <div style={{margin:10}}>
        <div><b>{title}</b></div>
        <div style={{margin:8}}><i>{instruction}</i></div>
      </div>
    )
  })}
  </div>
  
}
