import { useDispatch } from 'react-redux';
import A from './ActionCreators';

export default function TrialEndMessage({dateString}){

  let dispatch = useDispatch();

  let continueButton = (

<div style={{textAlign:'right', paddingTop:20}}>
          <button 
            surveyItem={"submit"}
            onClick={() => {
              dispatch(A.answerSurvey({
                name:"freeTrialEndNotification",
                answer:{}
              }))
            }}
             class="btn btn-primary">Continue</button>
        </div>

  )

  return (
    <div class="sg-col full-height">
      <div>
        <h2 style={{ borderBottom:'1px solid #ccc' }}>Sciugo is adopting a trial & upgrade model</h2>
        <br/>
      </div>
      <div class="scroll-shadows-vertical">

        <br/>
        <h5>Here's what you need to know:</h5>
        <ul>
          <li>{dateString}</li>
          <li>You will always have access to your data.</li>
          <li>Once the trial expires, you can not create or edit.</li>
        </ul>

        <br/>
        <h5>Your options:</h5>
        <ul>
          <li>Pricing info is in your account settings (Subscriptions).</li>
          <li>You get 1 <b>free</b> month for every 3 valid users you refer to Sciugo.<br/></li>
          <ul>
            <li>Make sure referrals use your account email as their sign up referrer.</li>
            <li>We will validate each referral account.</li>
            <li>Validation takes ~1 week.</li>
          </ul>
        </ul>

        <h5>What's coming in the near future:</h5>
        <ul>
          <li>Auto-detection of bands & molecular weights.</li>
          <li>Quick templates to link results, protocols and antibodies.</li>
          <li>Auto-generated <i>supplemental info</i> with each figure</li> 
          <li>
            Expansion to <b>microscopy</b> and <b>PCR</b>
          </li>
          <li>
            Smart search-engine: search images, figures and experiments by sample, condition, target
          </li>

        </ul>

        <h5>We want your feedback & ideas:</h5>
        <ul>

          <li><b>We want to hear from you! What's missing? What needs to change?</b></li>
          <li>
            Please reach out with any questions and concerns to <a href="mailto:support@sciugo.com" target="_blank;">support@sciugo.com</a>.
          </li>
        </ul>

        

      </div>

      {continueButton}

    </div>
  )




}
