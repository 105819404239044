import overviewGif from './gif/overviewGif.gif';
import cropDrawingToolsGif from './gif/cropDrawingToolsGif.gif';
import moveMolecularWeightMarkers from './gif/moveMolecularWeightMarkers.gif';
import rightClickMenu from './gif/rightClickMenu.gif'

import resizeRows from './gif/resizeRows.gif';
import alignBands from'./media/AlignCropBands.mp4';

import setFigureImage from './gif/setFigureImage.gif';

const content = [
  {
    label:'Align Bands In-Figure',
    text:'Click and drag to align bands to adjuacent rows.',
    vid:alignBands,
    date:'February 13, 2022'
  },

  /*{label:"New App Layout",
    text:'There are only two screens now. Annotation and Figure.',
    gif:overviewGif,
    date:'October 17, 2021'
  },*/
  {label:"New Cropping Tool",
    gif:cropDrawingToolsGif,
    text:"With the introduction of 'Rotated Rectangle Crop', there are now two cropping tools.",
    date:'October 17, 2021'
  },
  {label:"New Movable MW Markers", 
    gif:moveMolecularWeightMarkers,
    text:"Move crowded ladder marks aside.",
    date:'October 17, 2021',
  },
  {
    label:"New Right-click menu",
    gif:rightClickMenu,
    text:"More convenient figure building operations.",
    date:'October 17, 2021'

  },
  {
    label:"Set Primary Image", 
    gif:setFigureImage,
    date:'October 17, 2021'
  }
]

export default content;
