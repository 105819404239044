import { useState, useRef } from 'react';
import { getLineAndValuesIntersections, getFirstIntersectionInTheDirectionOfLs, yFromXLineFunction } from './Geometry';

import DeleteButton from './DeleteButton';


let meanFilter = (series, length) => {
  if (length % 2 !== 1 || length < 1) {
    throw Error("Mean filter must be passed an odd length > 1");
  }
  let output = series.map((val, ii) => {
    let startIndex = Math.max(0, ii - (length - 1) / 2);
    let endIndex = Math.min(series.length, ii + (length - 1) / 2);
    let m = mean(series.slice(startIndex, endIndex));
    return m;
  });
  return output;
};

let mean = (list) => {
  return list.reduce((a, b) => a + b) / list.length;
};


function FilledFunctionPath({windowScale,bounds,values,...props}){

  

  let sortedPeakBounds = [Math.min(...bounds),Math.max(...bounds)];

  let scaledSortedBounds = sortedPeakBounds.map(x => x * (values.length-1));
  let integersBetweenPeaks = Math.floor(scaledSortedBounds[1] - scaledSortedBounds[0]);

  let listOfIntegersBetweenPeaks = Array(integersBetweenPeaks).fill(0).map(
    (_,ii) => Math.floor(scaledSortedBounds[0] + ii + 1)
  );


  let xValuesToDraw = [ scaledSortedBounds[0], ...listOfIntegersBetweenPeaks, scaledSortedBounds[1] ];

  let maxY = Math.max(...values);
  let scaler = maxY > 255 ? 256 : 1;
  
  let scaleY = (y) => y / scaler;

  

  let pointsToDraw = xValuesToDraw.map((xVal,ii) => {

    let header = (ii !== 0 ? "L" : "");
    let [rawX,rawY] = getPointAt(xVal,values);
    let scaledX = rawX * windowScale;
    let scaledY = 255 - scaleY(rawY);

    //console.log({scaledX,scaledY});

    return [ header, scaledX, scaledY ].join(" ");
    
  });


  let d = "M "+pointsToDraw[0] +" "+ pointsToDraw.slice(1).join(" ")+" Z";


  return [
    <path {...{d,...props}}/>,
      ]


}

function getPointAt(x,values){


  let floorX = Math.floor(x);
  let ceilX = Math.ceil(x);
  let fractional = x - floorX;
  let yAtFloor = values[floorX];
  let yAtCeil = values[ceilX];

  let deltaY = yAtCeil - yAtFloor;
  let yAtX = values[floorX] + deltaY * fractional;

  let point = [x,yAtX];

  if( point.some(isNaN) ){

  }

  return point;
    
}


const pathFill = 'rgba(45,52,97)';

export default function Plot({values,peaks,dispatch,plotIndex,windowScale,points}){

  let max = Math.max(...[255,...values.map(Number)]);

  const [ regionStart, setRegionStart ] = useState(null);
  const [ mouseY, setMouseY ] = useState(null);
  const [ mouseX, setMouseX ] = useState(null);

  let unscaledRegionStartX;

  let scale = windowScale * (values.length-1);
  let unscaledRegionEndX;
  let mouseXProportion = mouseX / scale;



  let svgRef = useRef();
  let intPoint;
  let peakBounds;
 

  let focusY = getPointAt(mouseX/windowScale,values)[1]
  let drawnFirstPoint;
  let drawnSecondPoint;
  let yAtRegionStart;
  let yAtSecondPoint;
  let myLs;

  if( mouseX/windowScale <= values.length && mouseX!==undefined && mouseX!==null && regionStart && mouseXProportion >= 0){
    let scale = (values.length-1) * windowScale;
    unscaledRegionStartX = regionStart * (values.length-1);
    unscaledRegionEndX = mouseXProportion * (values.length-1);
    yAtRegionStart = getPointAt(unscaledRegionStartX,values)[1];
    yAtSecondPoint = getPointAt(unscaledRegionEndX,values)[1];
    
    drawnFirstPoint = { 
      cx:unscaledRegionStartX * windowScale,
      cy:255-yAtRegionStart
    };

    

    myLs = [
      [unscaledRegionStartX,yAtRegionStart],
      [unscaledRegionEndX,255-mouseY]
    ];
    //console.log(myLs);

    try{

      intPoint = getFirstIntersectionInTheDirectionOfLs(myLs,values);

    }catch(e){
      debugger;

    }

    if( intPoint ){
      peakBounds = intPoint && [regionStart, intPoint.x/(values.length-1)];
      peakBounds && peakBounds.sort();

      drawnSecondPoint = {
        cx: mouseXProportion * windowScale * (values.length-1),
        cy: 255-intPoint.y
      }
    }
   

    //let intersections = getLineAndValuesIntersections(myLs,values);
    
  }

  //console.log(peaks);
  let canClickForRegion = (
    mouseX >= 0
    && mouseX < (values.length-1) * windowScale
    && peaks.every(([bStart,bEnd]) => !(bStart < mouseX/scale && mouseX/scale < bEnd))
  );

  

  let currentFilledPath = peakBounds && <FilledFunctionPath {...{values,bounds:peakBounds, stroke:'black',strokeWidth:0.5,fill:pathFill, windowScale }}/>


  let deleteButtons = peaks.map((bounds,iiPeak) => {

    let boundsXMid = scale * (0.5 * (bounds[0] + bounds[1]));

    return (
      <DeleteButton zIndex={20} pointerEvents={"all"} style={{
        cursor:'pointer'}} 
        cx={boundsXMid} 
        cy={248} width={25} height={20} r={5}
        onClick={e => {
          e.stopPropagation();
          dispatch({
            type:'delete',
            peakIndex:iiPeak,
            plotIndex
          })
        }}
      />
    )


  })

  let peakSummary = (
    <div>
      {peaks.map((bounds,ii) => {
        let bottom = Math.floor(Math.min(...bounds));
        let top = Math.ceil(Math.max(...bounds));

        let toSum = values.slice(bottom,top);
        let sum = toSum.reduce((a,b) => a+b,0);

        return <div>{"Peak " + ii + ": " + sum}</div>

      })}
    </div>
  )

  
  //dome


  let maxY = Math.max(...values);
  let scaleY = 1;
  if( maxY > 255 ){
    scaleY = 256;
  }

  const svgWidth = windowScale * (values.length-1) + 1;
  const svgHeight = 500;

  return (
    <div class="sg-row">
      
            <div>
        <svg 


          onClick={e => {
            if(!canClickForRegion || (regionStart && !intPoint)){return;}

            if( !regionStart ){
              setRegionStart(mouseX/scale)
            }else{
              let boundsToAdd = peakBounds.slice();
              boundsToAdd.sort();
              setRegionStart(null);
              dispatch({
                type:'add',
                plotIndex,
                peakBounds:boundsToAdd,
              })
            }

          }}
          ref={svgRef}

          onMouseMove={e => {
            let newMouseX = e.clientX - svgRef.current.getBoundingClientRect().x;

            let newMouseY = e.clientY - svgRef.current.getBoundingClientRect().y;

            setMouseX(newMouseX);
            setMouseY(newMouseY);

            
          }}

          style={{
            border:'1px solid black',
            background:'white',
          position:'relative',
          width:svgWidth,
          height:svgHeight}}>


          {currentFilledPath}

          {values.map((val,ii) => {
            let nextPoint = 255 - ((values[ii+1]||val)/scaleY);
            if( isNaN(nextPoint) ){
              nextPoint = max;
            }
            return <line style={{cursor:'pointer'}} x1={windowScale*ii} y1={255-(val/scaleY)} x2={(ii+1)*windowScale} y2={nextPoint} stroke={"black"}/>
          }).slice(0,-1)}

          

          {false && <circle cx={mouseX} cy={mouseY} r={1} fill={"red"}/>}

          {!regionStart && <line x1={mouseX} x2={mouseX} y1={255-focusY} y2={255} stroke={canClickForRegion?"lightgreen":"salmon"}/>}


          
          {intPoint && <line {...{
            x1:drawnFirstPoint.x,
            y1:drawnFirstPoint.y,
            x2:drawnSecondPoint.x,
            y2:drawnSecondPoint.y
          }} strokeWidth={1} stroke={"lightgreen"}/>}

    

          {regionStart && !intPoint && <line x1={regionStart * scale} y1={255-yAtRegionStart} x2={mouseX} y2={mouseY} stroke={"salmon"}/>}
          

         
          {peaks.map(bounds => <FilledFunctionPath {...{bounds,values,windowScale, stroke:'black',strokeWidth:0.5,fill:pathFill}}/>)}


          {canClickForRegion && regionStart && <circle {...{...drawnFirstPoint}} fill={"lightgreen"} r={2}/>}

          {intPoint && <circle cx={intPoint.x * windowScale} cy={255-intPoint.y} r={1.5} fill={"lightgreen"}/>}


          {canClickForRegion && !regionStart && <circle cx={mouseX} cy={255-focusY} r={1.5} fill="lightgreen"/>}


          {myLs && intPoint && <line {...{
            x1:myLs[0][0] * windowScale,
            y1:255-myLs[0][1],
            x2:intPoint.x * windowScale,
            y2:255-intPoint.y
          }} strokeWidth={1} stroke={"lightgreen"}/>}
          
          {deleteButtons}

          {false && myLs && <line 
            x1={myLs[0][0]*windowScale}
            y1={255-myLs[0][1]}
            x2={myLs[1][0]*windowScale}
            y2={255-myLs[1][1]}
            stroke="blue"
            strokeWidth={1}
          />}


          {points && points.map(pointX => <circle cx={pointX * windowScale} cy={255-getPointAt(pointX,values)[1]} fill={"red"} r={3}/>)}


          <rect x={0} y={0} width={svgWidth} height={svgHeight} stroke={"blue"} strokeWidth={2} fill={"transparent"}/>


        </svg>

      </div>

      <div>
        <div>{"Peak bounds: " + JSON.stringify(peakBounds)}</div>
        <div>{"My LS: " + JSON.stringify(myLs)}</div>
        <div>{"Mouse X: " + mouseX}</div>
        <div>{"Mouse Y: " + mouseY}</div>
        <div><pre>{"intPoints: " + JSON.stringify(intPoint,null,1)}</pre></div>
        <div>{"unscaledRegionStartX: " + unscaledRegionStartX}</div>

        <div>{"unscaledRegionEndX: " + unscaledRegionEndX}</div>
        <div>{"unscaledRegionEndY: " + getPointAt(unscaledRegionEndX,values)[1]}</div>
      </div>
      <div>
        {/*false && <div><pre>{"mouseIntersections: " + JSON.stringify(mouseLineValueIntersections,null,1)}</pre></div>*/}
      </div>

    </div>
  )
}
