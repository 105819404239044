import { useDispatch } from 'react-redux';
import A from './ActionCreators';
export default function ImageAdjustmentSlider({
  label,property,min,max,step,imageId,
  onChange,value,setTemporaryAdjustments
}){

  let dispatch = useDispatch();

  let valueToDisplay;
  if( value >= 1 ){
    valueToDisplay = (value - 1)/(max*2) + 0.5;
  }else{
    valueToDisplay = value / 2;
  }

  return (
  <div class="sg-row">
    <div style={{width:80}}>
    {label||"Unlabeled property"}
    </div>

    <input class="wide-slider" onMouseUp={() => {
      setTemporaryAdjustments({});
      dispatch(A.setImageProperty({imageId,property,value}));
    }} value={valueToDisplay} onChange={e => {
      let newValue = Number(e.target.value);
        if( newValue < 0.5 ){
          onChange( newValue * 2 );
        }else if( newValue >= 0.5 ){
          onChange((newValue - 0.5)*2*max + 1);
        }
      }}
      type="range" {...{min:1e-10,max:1,step:'any'}}/>

    <div>{false && "(value: " + value+")"}</div>
    <div>{false && "(valueToDisplay: "+valueToDisplay+")"}</div>

  </div>
  )


}
