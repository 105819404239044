import { useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';
import C from './Constants';
import G from './Getters';
export default function ConfirmExitTutorial({onStateChange}){

  let dispatch = useDispatch();
  let requestResponse = useSelector(state => G.getRequestResponse(state,{route:"/setUserInfo"}));


  let guideLayer = document.querySelector(
    '#tutorial-guideline-wrapper'
  );

  let connectingLine = document.querySelector('#connecting-line-svg');


  if( connectingLine ){
    connectingLine.parentNode.removeChild(connectingLine);
  }


  if( guideLayer ){
    guideLayer.parentNode.removeChild(
      guideLayer
    )
  }



  let requestMessage;
  
  if( requestResponse?.status === "failed" ){
    requestMessage = (
    <div itemErrorMessage={"tutorialButton-exit"} style={{fontWeight:"bold",color:"red"}}>
      {requestResponse.reason}
    </div>
    )

  }



  return (
    <div style={{ 
      alignItems:'center',
      background:'#ddd',
      paddingTop:'10%',
    }} 
      class="sg-col full-width full-height">
      <h3>Are you sure you want to exit the tutorial?</h3>
      

        <button 
          tutorialButton={"cancel-exit-tutorial"} style={{margin:10}} 
          onClick={()=>{
            dispatch({
              type:C.cancelExitTutorial
            })
          }} class="btn btn-success">Return to the tutorial</button>

      <div class="flex-expand"/>


      <div>
        {requestMessage}
      <button 
        disabled={requestResponse?.pending}
        tutorialButton={"confirm-exit-tutorial"} style={{margin:10, marginTop:100 }}
          onClick={() => {
            dispatch(A.exitTutorial())
          }} class="btn btn-danger">Exit the tutorial</button>

      <div style={{marginTop:10,marginBottom:100}}>You won't be able to return.</div>
      </div>
    </div>
  )
}
