
export default function TutorialIntro({onStateChange}){

  


  return (
    <div style={{ 

      //border:'1px solid green',
      alignItems:'center',
      background:'#ddd',
      paddingTop:'10%',


    }} 
      class="sg-col full-width full-height">
      <h3>Welcome to the Sciugo tutorial!</h3>
      

      <button tutorialButton={"start"} style={{marginTop:20}} onClick={onStateChange} class="btn btn-primary">Continue to Tutorial</button>
    </div>
  )
}
