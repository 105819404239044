import usePresentSelector from './usePresentSelector';
import { useEffect, useState } from 'react';
import G from './Getters';
import ProteinTargetSearchResult from './ProteinTargetSearchResult';
import SciugoInput from './SciugoInput';
import { IMAGE_UPLOAD_DATE, ANNOTATION_LAST_EDITED_DATE } from './CropSearch';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const dateTextLabelMap = {
  [IMAGE_UPLOAD_DATE]:'Uploaded:',
  [ANNOTATION_LAST_EDITED_DATE]:'Last edited:'
}


const cropsInView = 5;

export default function ProteinTargetSearchContainer({
  setCropIdsSelectedForInsertion,
  setDragInfo,isThisCropSelectedForInsertion, isSomeCropSelectedForInsertion, setMode}){



  let [ sortBy, setSortBy ] = useState(ANNOTATION_LAST_EDITED_DATE);

  const [page,setPage] = useState(0);

  const onQueryChange = newQuery => {
    setQuery(newQuery);
    setPage(0);
  }

  let [query,setQuery] = useState();

  let cropResults = usePresentSelector(state => {
    let results = G.getCropSearchResults(state,query,sortBy)
    return results.map(res => ({
      result:res,
      path:G.getAbsolutePathByAnnotationId(state,res)
    }))
  },[query,sortBy]);


  let [resultOrder,setResultOrder] = useState(cropResults);


  const onSetSortBy = key => {
    let newResultOrder = resultOrder.slice().sort((a,b) => {
      return b[key] - a[key]
    })
 
    setSortBy( key );
    setResultOrder( newResultOrder )
  }

  const numPages = Math.max(1,Math.round(cropResults.length / cropsInView));
  



  let atns = usePresentSelector(state => state.data.annotations);


  const onKeyDown = e => {
    if( isSomeCropSelectedForInsertion && e.key === 'Escape' ){
      setCropIdsSelectedForInsertion([]);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown',onKeyDown);
    return () => {
      window.removeEventListener('keydown',onKeyDown);
    }
  })

  let searchComponent = (

    <div class="light-border-bottom" style={{
        padding:10}}>
        <SciugoInput input={"search-protein-target"} value={query} onChange={e => onQueryChange(e.target.value)}
          style={{width:'100%',marginBottom:10}} placeholder={"Search protein target"}/>

          <div class="sg-row">

        <div>Sort by: </div>
            <div class="flex-expand"/>
            <select value={sortBy} onChange={e => {
              onSetSortBy(e.target.value);
            }}>
          <option value={ANNOTATION_LAST_EDITED_DATE}>Last edited</option>
          <option value={IMAGE_UPLOAD_DATE}>Upload date</option>

        </select>
      </div>

      <div style={{alignItems:'center', justifyContent:'center'}} class="sg-row">
        <div class="dark-hoverable" style={{borderRadius:10, cursor:'pointer'}} onClick={() => {setPage(Math.max(page-1,0))}}><NavigateBeforeIcon/></div>
        {` ${page+1} / ${numPages} `}
        <div class="dark-hoverable" style={{borderRadius:10, cursor:'pointer'}} onClick={() => {setPage(Math.min(page+1,numPages))}}><NavigateNextIcon/></div>
      </div>

      </div>

  )

  let emptyQueryResults = (

    ((!query || query === "") && cropResults.length === 0) && (
      <div style={{flex:1, height:'100%', fontSize:36,
        padding:10,
        color:'#aaa',textAlign:'center',
        background:'white',
        }}>
          <a onClick={() => setMode("ANNOTATION")} href="#" class="link-primary">Crop images</a>
          <br/>
          to use them in your figure.</div>
      )
  )

  let queryWithNoResults = (

    (query && query !== "" && cropResults.length === 0) && (

        <div style={{
          fontSize:36,color:'#aaa',textAlign:'center',
          padding:10,
          height:'100%',
          background:'white',
          }}>
          No crops found for:
          <br/>
          <i>"{query}"</i>
        </div>
      )
  )

  let topCropNumber = cropsInView * page;
  let displayedCrops = cropResults.slice(topCropNumber,topCropNumber+cropsInView);

  let queryResults = (

    <div class="flex-expand" style={{
      display:'flex',
      flexDirection:'column',
      height:'100%',
        flex:1,
        //border:'5px solid blue',
        //height:'100%',
        //overflowY:'scroll',
        }}>
        {displayedCrops.map((x,ii) => {
          let { result, path } = x;
          let label = atns[result.annotationId].label;
          return <ProteinTargetSearchResult key={result.annotationId} {...{
            dateLabelString:(dateTextLabelMap[sortBy]),
            orderInList:ii,
            isSelected:isThisCropSelectedForInsertion(result.annotationId),
            path,
            label, 
            searchResultDisplayedDate:G.getClientDateString(null,result[sortBy]),
            cropResult:result, 

            setDragInfo:_id => {
              setCropIdsSelectedForInsertion([_id])
            },
            annotationId:result.annotationId
          }}/>
        })}
      <div style={{flex:1,minHeight:30}} class="fs-alternating flex-expand"/>
      </div>

  )


  return ( 


    <div id={"protein-target-container"} style={{
      position:'relative',
      display:'flex', 
      flexDirection:'column',
      background:'#fafafa',
      //flex:1,
      height:'100%',

      //border:'3px solid magenta',
      minHeight:0,


      overflowY:'hidden',
      }}>
      {searchComponent}
      <div style={{
        height:'100%',
        overflowY:'scroll',
        //border:'1px solid blue',
        }}>
        {emptyQueryResults}
        {queryWithNoResults}
        {queryResults}
            
      </div>
    </div>

  )


}
