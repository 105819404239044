import { get, post } from './io';
import A from './ActionCreators';
import G from './Getters';
import { isCLI } from './versionConfig';
import SciugoBlob from './SciugoBlob';

const BINARY_OCTET_STREAM = 'binary/octet-stream';
const TEST_SERVER = "test-server";
const S3 = "s3";

async function processMediaFetchSuccess({version,imageId,dispatch,state,response:res}){

  let json = res.json;
  let blobType = res.blobType;
  let blobData = res.blobData
  let blob = res.blob;

  blobType = blobType || 'image/png';


  let { data } = json || {};
  if( !blobData ){
    blobData = await SciugoBlob(data,blobType);
  }

  let url;
  try{
    url = URL.createObjectURL(blobData);
  }catch(e){
    if( isCLI() ){

      let blobBuffer = await blobData.arrayBuffer();
      let testBlob = await SciugoBlob(blobBuffer,blobType);
      url = URL.createObjectURL(testBlob);
      //debugger;

    }else{ 
      throw e;
    }
  }




  return dispatch(A.setMediaURL({
    imageId,
    version,
    url
  }))


}


function processMediaFetchFailure(mediaFetchFailedAction,dispatch){

  dispatch(mediaFetchFailedAction);

}

function isBinaryOctetStreamContentType(response){

  let headers = response.headers;
  let resContentType = headers.get('content-type');
  return resContentType === BINARY_OCTET_STREAM;
}


function fetchMediaAndInjectReferencesIntoState({url,version,imageId,dispatch,state,storageLocation}){


  let mediaFetchFailedAction = A.mediaFetchFailed({
      imageId,
      version,
      stage:"fetchFromStorage",
      error:"storageFetchFailure",
    })

  let onSuccess = async response => {



    let processingArgs = {url,version,imageId,dispatch,state,response}


    let isS3 = storageLocation === S3;

    let success = (
      !isS3 || isBinaryOctetStreamContentType(response)
    )

    if( success ){
      await processMediaFetchSuccess(processingArgs);
    }else{
      processMediaFetchFailure(mediaFetchFailedAction,dispatch);
      //probably want to do something here like retry?
    }
  }


  

  let args = {
    routeOrigin:url,
    route:'',
    onSuccess,
    onServerFailure:(res => dispatch(mediaFetchFailedAction)),
    onInternetFailure:(res => {
      dispatch(mediaFetchFailedAction);
      return dispatch(A.createNoInternetDialog());
    }),
    onFetchError:(res => dispatch(mediaFetchFailedAction)),
    state
  }

  return get(args,dispatch);
}




async function fetchTestServer(state,info,dispatch){
  let { imageId, version } = info;

  let remoteStorageResourceId = G.getRemoteStorageResourceId(state,{imageId,version});

  let route = [
    'http://localhost:9999/fetchResource',
    //'/fetchResource',
    remoteStorageResourceId
  ].join('/');

  let getArgs = { route, state };


  return fetchMediaAndInjectReferencesIntoState({
    url:route,
    state,
    imageId,
    version,
    storageLocation:"test-server",
    dispatch
  })

}



function unsupportedDriveStorage(info){
  throw Error("Storage location ("+info.storageLocation + ") access is not yet implemented.");
}

function fetchS3(state,info,dispatch){


  let onSuccess = res => {
    
    let { json } = res;

    let { version, imageId } = info;
    let { data } = json;


    let fetchPromises = data.map((url,ii) => {
      
      return fetchMediaAndInjectReferencesIntoState({
        url,
        storageLocation:S3,
        version,
        imageId,
        dispatch,
        state
      })
    })

    return Promise.all(fetchPromises);
  }

  let onInternetFailure = res => {
    
    dispatch(A.mediaFetchFailed({ ...info, stage:"getPresignedFetchUrls",error:"noInternet"}));

    return dispatch(A.createNoInternetDialog());
  }
  let onServerFailure = res => {
    dispatch(A.mediaFetchFailed({ ...info, stage:"getPresignedFetchUrls", error:"serverError"}));

  }

  return post({
    route:'/getPresignedFetchUrls',
    body:[info],
    onSuccess,
    onInternetFailure,
    onServerFailure,
    state
  },dispatch).then()
}

const locationFetchMap = {
  "test-server":fetchTestServer,
  //"google-drive":fetchGoogleDrive,
  "one-drive":unsupportedDriveStorage,
  "browserImageMap":fetchTestServer,
  "s3":fetchS3
}

export async function fetchImage({state,imageInfo,dispatch}){


  let { storageLocation } = imageInfo;

  if( process.env.NODE_ENV === 'production' ){
    storageLocation = storageLocation || "s3";
  }

  let fetchFunction = locationFetchMap[storageLocation];
  

  if(!fetchFunction){
    debugger;
    throw Error("No fetch function found for storage location: '" + storageLocation+"'");
  }

  let res = await fetchFunction(state,imageInfo,dispatch);
  return res;

}


export default async function fetchImages({state,imagesToFetchInfoList,dispatch}){

  let fetchPromises = imagesToFetchInfoList.map(imageInfo => {
    return fetchImage({state,imageInfo,dispatch})
  })

  return Promise.all(fetchPromises);

}
