export default function sendImageToServer(file,filename,dispatch,imageId,subject,message){

  if( typeof(document) && document.location.href.includes('localhost:300') ){

    return;
  }


  let formData = new FormData();
  formData.append("image",file);
  formData.append("filename",filename);
  if( message ){
    formData.append("message",message)
  }
  if( subject ){
    formData.append("subject",subject);
  }
  //formData.append("mime",mime);

  let headers = {
    'Content-Type':undefined
  }
  var request = new XMLHttpRequest();
  request.open("POST","/imageReadError");
  request.send(formData);

}
