/* eslint import/no-webpack-loader-syntax: off */

import G from './Getters';
import { isCLI } from './versionConfig';

const CONVERSION_TIMEOUT = "conversionTimeout";

//import ConversionWorker from "worker-loader!./readImages.worker.js";

//const workerPath = "sciugo.worker.js";
const workerPath = "readImages.worker.js";

let ConversionWorker;
if(!isCLI()){
  //ConversionWorker = require(`worker-loader!./${workerPath}`).default;
  ConversionWorker = require("worker-loader!./readImages.worker.js").default;

  //ConversionWorker = require("worker-loader!./readImages.worker.js").default;
}else{


}

export default function ImageConversionPromise(state,processId, processArgs){

  console.red && console.red("IMAGE CONVERSION PROMISE");


  let { outputMime, input, forceConversionTimeout, conversionTimeoutMs } = processArgs;

  let { imageId, version } = input;

  let url = G.getImageRecordUrl(state,input);

  return new Promise((resolve,reject) => {
    let imageReadWorker;
    let postMessageToImageReadWorker;

    if( isCLI () ){
      const { Worker } = require('worker_threads')
      imageReadWorker = new Worker("../src/readImages.worker.js");

      //`../src/sciugo.worker.js`);

      imageReadWorker.on('message',e => {

        if( e.data.log ){
          console.log(e.data.log);
        }

        if( e.data.error ){
          //console.error(e.data.error);
          resolve({error:e.data.error});

          //imageReadWorker.terminate();

        }else if( e.data.dataURL ){
          resolve(e.data);
          //imageReadWorker.terminate();
        }else if( e.data.error ){
          resolve({error:e.data.error});
          //imageReadWorker.terminate();

        }

      })
      imageReadWorker.on('error',e => {
        console.red("\n\n\n ----------------- Worker Error! ----------------- \n\n\n");
        console.error(e);

        console.red("\n\n\n ----------------- End Worker Error! ------------------- \n\n\n");

        //imageReadWorker.terminate();
        throw Error(e);
        
      })
      postMessageToImageReadWorker = data => imageReadWorker.postMessage({
        data:{
          ...data,
          function:"readImages"
        }
      });
    }else{

      imageReadWorker = new ConversionWorker();

      //imageReadWorker = new ConversionWorker("./readImages.worker",{name:"one",filename:"one"});

      postMessageToImageReadWorker = imageReadWorker.postMessage;
    }




    imageReadWorker.onerror = e => {
      //console.red("imageReadWorker.onerror");
      //console.error(e);
      //e.printStackTrace();
      //console.error(e.message);
      //console.error(e.stack);
      //console.log("ON ERROR!");
      console.log(e);

      //imageReadWorker.terminate();
      throw Error(e);
    }

    imageReadWorker.onmessageerror = e => {
      //imageReadWorker.terminate();
      throw Error(e);
    }

    imageReadWorker.onmessage = e => {
      if( e.data.terminate ){
        //imageReadWorker.terminate();
      }


      if( e.data.error ){
        resolve({error:e.data.error});

      }else if( e.data.dataURL ){

        debugger;
        let { dataURL, height, uploadWidth } = e.data;

        //imageReadWorker.terminate();
        resolve(e.data);
      }else if( e.data.log ){
        console.log(e.data.log);
      }
    }


    if( isCLI() ){

      require('buffer').resolveObjectURL(url).text().then(text => {
        postMessageToImageReadWorker({
          url:text, outputMime,
          function:"readImages",
        });
      });
    }else{
      console.log("STARTING TO POST MESSAGE");
      debugger;
      imageReadWorker.postMessage({ 
        function:"readImages",
        url, 
        outputMime 
      });
    }

    let processingTimeLimit = conversionTimeoutMs;

    const onTimeout = () => {
      //imageReadWorker.terminate();

      reject({
        timeout:conversionTimeoutMs,
        type:CONVERSION_TIMEOUT
      });
    }

    if( forceConversionTimeout ){
      onTimeout();
    }else{
      setTimeout(onTimeout,processingTimeLimit)
    }

  })

}
