import { useSelector } from 'react-redux';
import CropWrapper from './CropWrapper';
import ImageThumbnailSyncStatus from './ImageThumbnailSyncStatus';
import ImageIcon from '@material-ui/icons/Image';

export default function ImageThumbnail({
  imageId, dim,
  draggable,
  cropWrapperSetDragInfo,
  isDragPreview,
  treePath
}){


    const DIM = dim;


  let state = useSelector(state => state);

  
  if( !imageId ){ 
    //if we don't return null here,
    //then we'll get a crash in the following case:
    //
    //after we drop an image from a singleton imageSet
    //into another imageset.
    //
    //the image disappears from its imageSet
    //and is transfered to the other imageSet
    //
    //however, for soem reason
    //draglayer still thinks we're dragging
    //and it doesn't stop it before this
    //renders
    //
    //so if you remove this line it will crash...
    //because we have passed in the imageId
    //which was taken listDirector.
    //And after the move operation is done,
    //there is no longer any images in that directory
    //so the imageId is undefined.
    //and then we get undefined when we try
    //to find it in the the imageUplaods data.
    return null; 
  }


  let image = state.data.imageUploads[imageId]

  if( !image ){
    debugger;
    //throw Error("No image found for imageId = " + imageId);
  }




  return (
    <div 

      style={{
        height:DIM,
        width:DIM,
        background:'#eee',
        position:'relative',
        //border:'1px solid blue',
        display:'flex',
        flexDirection:(1 > image.height ? 'column' : 'row'),
        alignItems:'center',
        cursor:(draggable !== false ? 'move' : 'unset'),
      }}>

      {true && <ImageIcon style={{height:DIM,width:DIM}}/>}
      {false && <div 


        style={{
          top:0,
          left:0,
          height: 1 > image.height ? (image.height) * DIM : DIM,
          width: 1 > image.height ? DIM : (1/image.height) * DIM,
          //background:'orange',
        }}>
        
        {true && <CropWrapper {...{
          inlineCrop:{
            top:0,
            left:0,
            height:image.height,
            width:1,
            rotation:0
          },
          width:dim,
          overflow:false,
          invertable:false,
          fromImageManagement:true,
          setDragInfo:cropWrapperSetDragInfo,
          draggable:(draggable !== false),
          imageSelectable:true,
          imageId
        }}/>}

      </div>}
    </div>)

}
