import { useState, useReducer } from 'react';

function createBlankForm(schema){


  let entries = schema.map(field => {

    let { _id, hasValue } = field;

    if( !hasValue ){
      return null;
    }
    return [_id,null]
  }).filter(x => x);

  return Object.fromEntries(entries);
}


export default function useSciugoForm(schema){

  
  const [form, setForm] = useState(createBlankForm(schema));


  function setFormValue({_id,value}){
    setForm({
      ...form,
      [_id]:value
    })
  }

  return [form,setFormValue];


}
