const middleware = store => next => async action => {

  if( window.__actionArgsOverrides ){
    return next({
      ...action,
      ...window.__actionArgsOverrides,
    })
  }

  return next(action);


}

export default middleware;
