import usePresentSelector from './usePresentSelector';
import G from './Getters';
import Barplot from './Barplot';
export default function QuantifcationResults({annotationId}){



  let quantification = usePresentSelector(state => G.getAnnotationQuantification(state,{_id:annotationId}));

  let content;

  /*
  let unsyncedProperties = ["window","imageId"];
  if( unsyncedProperties.length > 0 ){

    content = <QuantificationAnnotationUpdateMenu {...{
      annotationId,
      unsyncedProperties,
      setUpdateMenuActive,
    }}/>

  }else */
  if( !quantification ){

    content = (
      <div 
        annotationId={annotationId}
        info={"quantificationStatus"}
        quantificationStatus={"unquantified"}
        style={{
          width:'100%',
          height:'100%',
          alignText:'center', 
          display:'flex', 
          justifyContent:'center',
          alignItems:'center',
          //background:'yellow'
        }}>
        Quantify to plot results!
      </div>
    )

  }else{

    //console.log("quantification");
    //console.log(quantification);

    let integrations = quantification.map( lane => {
      return Math.round(lane.value);
    });

    /*
      let integrationRange = laneQuant.integrationRanges[0];
      let [ rangeStart, rangeEnd ] = integrationRange;
      let { plotValues } = laneQuant;

      let startIndex = Math.round( rangeStart * plotValues.length);
      let endIndex = Math.round( rangeEnd * plotValues.length );

      let toIntegrate = plotValues.slice(startIndex, endIndex);

      let sum = toIntegrate.reduce((a,b) => a+b,0);

      return sum;
    })
    */



    let barWidth = 15;

    content = (
      <Barplot values={quantification.map(x => x.value)} labels={integrations}
          outerProps={{
            info:"quantificationStatus",
            annotationId,
            quantificationStatus:"quantified"
          }}
        />
    )

  }


  return content;
}
