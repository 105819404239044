import DropCell from './DropCell';
import { useDispatch } from 'react-redux';
import usePresentSelector from './usePresentSelector';
import { useState } from 'react';
import G from './Getters';
import A from './ActionCreators';

const sum = row => row.reduce((a,b) => a+b);

export default function RowMoveDropRow({
  figurePanelId,
  rowDragDispatch,
  fromIndex,
  toIndex,
  rowIndex,
  setCropIdsBeingDragged,
  cropIdsBeingDragged, 
  cellDragEntered, setCellDragEntered,
  dragRowLeftShift,
  totalDragRowWidth
}){

  if( !figurePanelId ){
    throw Error("CropDropRow requries figurePanelId");
  }

  let dispatch = useDispatch();

  //let figurePanel = usePresentSelector(state => G.getFigurePanel(state,{figurePanelId}))

  let dims = usePresentSelector(state => G.getTableDimensions(state,figurePanelId));


  let numColumns = dims[1];
  const gridIsEmpty = dims.includes(0);
  let structure = [numColumns];


  const onDrop = () => {
    dispatch(
      A.moveRow({fromIndex,toIndex,figurePanelId})
    );
    rowDragDispatch({'release':true});

  }

  let thisRowIndex = rowIndex + 0.5;
  let isDragEntered = thisRowIndex === cellDragEntered[0] && 0 === cellDragEntered[1];

  

  return (
    <>
      
      <tr
        style={{
          position:'relative',
          //transform:'translate(-45px,0)'

        }}
      >
          <td>
            {true && <div style={{
              background:'red',
              //position:'absolute',
              //height:100,
              height:20,

              //height:'100%',
              //border:'1px solid black',
              //top:0,
              //left:-40,
              //width:120
            }}/>}
          </td>

          {structure.map((length,index) => {

            let colIndex = structure.slice(0,index).reduce((a,b) => a+Math.abs(b),0);

            return (


              <td style={{
                //border:'1px solid blue',
                position:'relative',
                }}
                colSpan={length}>
                <div
                  class={isDragEntered ? "selected" : ""}
                  onMouseEnter={() => {
                    setCellDragEntered([thisRowIndex,colIndex]);
                  }}
                  onMouseLeave={() => {
                    if( isDragEntered ){
                      setCellDragEntered([-10,-10]);
                    }
                  }}
                  onClick={onDrop}
                  onMouseUp={onDrop}
                  style={{
                  position:'absolute',
                  border:'2px dashed #ddd',
                  
                  top:-2,
                  marginTop:1,
                  left:dragRowLeftShift-3,
                  width:totalDragRowWidth,
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',

                }}>
                  <div style={{
                    color:'#ddd',
                    fontWeight:'bold',

                  }}>
                    Move Here
                  </div>
                </div>
              </td>
            )
          })}


      <td></td></tr>
    </>
  )

}
