import usePresentSelector from './usePresentSelector';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IMAGE_SETS, DIRECTORIES } from './RecordTypes';
import ImageUploadButton from './ImageUploadButton';
import UploadProgressContainer from './UploadProgressContainer';

import { FILESYSTEM_NAME } from './Filesystem';

import { CREATION_DATE, LAST_EDITED_DATE } from './FieldConstants';

import A from './ActionCreators';
import { v4 } from 'uuid';
import G from './Getters';

import FilesystemListContainer from './FilesystemListContainer';

import FilesystemItemContextMenu from './FilesystemItemContextMenu';


import ImageManagementMessageModal from './ImageManagementMessageModal';


import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import FilesystemNav from './FilesystemNav';

const FILESYSTEM_NAV = false;


const eventOnContextMenu = e => e.composedPath().find(comp => comp.className && comp.className.includes && comp.className.includes('sg-context-menu'));

export default function ImageManagement({state,

  focusedFsItemId, 
  setFocusedFsItemId,
}){



  let dispatch = useDispatch();

  const focusedFsItemIdIsArchived = usePresentSelector(state => {
    if( focusedFsItemId ){
      return G.isArchived(state,{_id:focusedFsItemId})
    }
  });

  if( focusedFsItemIdIsArchived ){
    setFocusedFsItemId(null);
  }



  const topLevelDirectoryId = usePresentSelector(state => G.getTopLevelDirectoryId(state,{itemType:"imageSets"}));

  //const annotationCountTable = usePresentSelector(state => G.getAnnotationCountTable(state));

  //const [showUnlabeled,setShowUnlabeled] = useState(true);
  //const [showLabeled,setShowLabeled] = useState(true);
  const [moveOption,setMoveOptions] = useState();


  const [dragInfo,setDragInfo] = useState(null);

  const [contextMenuInfo,setContextMenuInfo] = useState(null);
  const [activelyRenamingFilesystemItem,setActivelyRenamingFilesystemItem] = useState(null);

  const [dropTargetId,setDropTargetId] = useState(null);

  const topLevelDirectoryItems = usePresentSelector(state => G.listDirectory(state,{itemType:"directories",_id:topLevelDirectoryId})||[]);




  useEffect(() => {

    let onClick = e => {
      if( !eventOnContextMenu(e) ){ setContextMenuInfo(null); }
    }

    window.addEventListener('click',onClick);
    window.addEventListener('contextmenu',onClick);
    return () => {
      window.removeEventListener('click',onClick);
      window.removeEventListener('contextmenu',onClick);
    }
  })


  //let ld = G.listDirectory(state,{topLevelDirectory:IMAGE_SETS});
  //console.log({ld});
  //let imageSets = ld.map(info => G.getData(state,{type:IMAGE_SETS,_id:info._id}));

  let imageSets = Object.values(state.data[IMAGE_SETS]);

  const [enteredBig,setEnteredBig] = useState(false);


  let bigBg = (dragInfo && enteredBig) ?'lime':'#eeeeee';


  const cropWrapperSetDragInfo = newDragInfo => {
    if(!dragInfo){
      setDragInfo(newDragInfo);
    }
  }

  const onDropMain = e => {
    if( !dragInfo ){
      return;
    }

    let imageSetIdOfDraggedImage = state.data.imageUploads[ dragInfo.imageId ].imageSetId;
    let imageSetOfDraggedImage = state.data.imageSets[imageSetIdOfDraggedImage];

    if( imageSetOfDraggedImage.images.length > 1 ){

      let destinationImageSetId = v4();
      //console.log(destinationImageSetId);
      let imageIds = [dragInfo.imageId];

      let messageKey = G.getImageMoveMessageKey(state,{
        imageIds,destinationImageSetId
      })

      if( messageKey ){
        setMoveOptions({action:'move',messageKey,imageIds,destinationImageSetId})
      }else{
        dispatch(A.moveImagesToImageSet({
          imageIds,
          destinationImageSetId
        }))
      }
    }

    setDragInfo(null);
  }

  


  //let labeled = [];
  //let unlabeled = [];

  /*
  imageSets.forEach(set => {
    if( annotationCountTable[set._id] > 0 ){
      labeled.push(set);
    }else{
      unlabeled.push(set);
    }
  })*/

  //const labeledUnlabeledFontSize = 12;
 
  


  return (
    <div 
      onContextMenu={e => e.preventDefault()}
      style={{
        minWidth:0,
        //border:'3px solid green',
        position:'relative', 
        overflow:'hidden', 
        display:'flex', 
        flexDirection:'column'
      }} class="full-width full-height">


      {FILESYSTEM_NAV && <FilesystemNav/>}
      
    {contextMenuInfo && <FilesystemItemContextMenu {...{
        ...contextMenuInfo,
      filesystemRootName:"images",
      functions:{
        setActivelyRenamingFilesystemItem,
      },
        
        onClose:() => {
          setContextMenuInfo(null);
        }
      }}/>}


      

      {moveOption && <ImageManagementMessageModal {...{
        action:moveOption,
        closeModal:()=>setMoveOptions() 
      }}/>}

      {true && <div class="full-width v-center sg-row" style={{
        textAlign:'center',
        justifyContent:'center',
        alignItems:'center',
        //background:'pink',
      }}>

        <ImageUploadButton/>
        
        {true && <div button="create-directory" style={{
          background:'#f4f4f4',
          borderRadius:25,
          height:45,
          display:'flex',
          alignItems:'center'
        }}
          onClick={() => {
            dispatch(A.createItem({
              itemType:"directories",
              inTopLevelParent:"imageSets"
            }))
          }}
          class="small-padding full-height flex v-center dark-hoverable round-corners dark-active">
          <CreateNewFolderIcon />
        </div>}

        


      </div>}

      {topLevelDirectoryItems.length > 0 && <FilesystemListContainer {...{

        filesystemContainer:"imageSets",
        contextMenuInfo,
        setContextMenuInfo,

        activelyRenamingFilesystemItem,
        setActivelyRenamingFilesystemItem,

        topLevelDirectoryId,

        dropTargetId,
        setDropTargetId,

        columns:[FILESYSTEM_NAME,LAST_EDITED_DATE,CREATION_DATE]


      }}/>}
      { topLevelDirectoryItems.length === 0 && (

        <div style={{justifyContent:'center', textAlign:'center'}} class="sg-row flex-expand">
          <div style={{fontSize:36, 
            //background:'yellow', 
            width:'100%', 
            height:'100%',marginTop:80}}>

          Drag and Drop

            <div style={{fontSize:16, marginTop:5}}>
            (JPEG, PNG, TIF)
          </div>


        </div>
                  </div>


      )}


      <UploadProgressContainer/>

    </div>
  )
}
