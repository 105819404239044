import { useState, useEffect } from 'react';

export default function ResizableSidebar(args){

  let { children, ...props } = args;
  let { maxWidth, minWidth, defaultWidth } = props;

  const [ resizing, setResizing ] = useState(false);
  const [ width, setWidth ] = useState( defaultWidth || 300 );


  const mouseUp = () => setResizing(false);
  const mouseMove = e => {

    if( !resizing ){
      return;
    }

    let newWidth = e.clientX;
    let conditions = [
      !isNaN(maxWidth) ? newWidth <= maxWidth : true,
      !isNaN(minWidth) ? newWidth >= minWidth : true,
    ]

    let shouldResize = conditions.every(x => x);

    if( shouldResize ){


      setWidth(newWidth);
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup',mouseUp);
    window.addEventListener('mousemove',mouseMove);
    return () => {
      window.removeEventListener('mouseup',mouseUp);
      window.removeEventListener('mousemove',mouseMove);
    }
  })

  return (
    <div 
      ref={el => {
        if( el ){
          //renderedWidthRef.current = el.getBoundingClientRect().width;
        }
      }}
      style={{
        //border:'3px solid black',
        minWidth:width, 
        position:'relative',
        zIndex:100,
        //background:'purple',
        height:'100%',


        borderRadius:4,    

        boxShadow:'0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      }

      }>
      <div onMouseDown={e => {
        setResizing(true);
      }}
        style={{
          cursor:'ew-resize',
          top:0,
          zIndex:100,


          position:"absolute", right:0, height:'100%', width:2, background:'#ccc'}}/>


      {children}
    </div>
  )



}
