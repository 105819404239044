import Id, {Ids} from './IdFactory';
import G from './Getters';
import HardCodedMicroscopyTemplateMap from './HardCodedMicroscopyTemplateMap';

export function CellGroup(_id, {value,style,placeholder} = {}){
  return {
    _id, value:(value||''),style:(style||{})
  }
}

const DefaultColumnWidths = {
  westernBlot:30,
  microscopy:100,
}
const DefaultHeaderColumnWidth = 100;

export function getDefaultColumnWidths({figurePanelType}){ return DefaultColumnWidths[figurePanelType] };

const figurePanelDefaultArgs = {
  westernBlot:{
    left:1,
    gel:12,
    right:1,
    labelLayout:{ 
      left:["crop"], 
      right:["mw"] 
    },
    defaultColumnWidth:DefaultColumnWidths.westernBlot,
  },
  microscopy:{
    left:1,
    middle:1,
    right:1,
    defaultColumnWidth:DefaultHeaderColumnWidth.microscopy,
  },

  sampleLayout:{
    lanes:12,
    headerColumns:{
      left:["Factor"]
    }
  }
}

/*const figurePanelDefaults = {
  westernBlot:{
    grid:[],
    cellGroupsById:{},
    columnWidths:[],
    globalStyle:{
      rowSpacing:7,
      pointerWidth: 10,
      textPointerSpacing: 0,
      labelFontSize: 9
    }
  }
}*/

function applyGuides(cellGroups, { imageCellId, zerothRowLabelId, firstRowLabelId }){

  let sampleHeaderValue = "Sample info";
  let doseTimepointHeaderValue = "Dose/time info";
  


  cellGroups[imageCellId].placeholder = "Image goes here";
  //cellGroups[imageCellId].style = placeholderStyle;
  cellGroups[zerothRowLabelId].placeholder = sampleHeaderValue;
  //cellGroups[zerothRowLabelId].style = placeholderStyle;
  cellGroups[firstRowLabelId].placeholder = doseTimepointHeaderValue;
  //cellGroups[firstRowLabelId].style = placeholderStyle;

}


function getDefaultGridAndCellGroupsAndColumnWidths(args){

  let { left, gel, middle, right, 
    includeGuides, 
    //groupIds, values, 
    figurePanelType, 
  } = args;

  //labelLayout, 

  let middleColCount = gel || middle;


  let imageCellId = Id();
  let zerothRowLabelId = Id();
  let firstRowLabelId = Id();



  let zerothRow = [
    ...Array(left).fill(0).map(_=> zerothRowLabelId),
    ...Array(middleColCount).fill(0).map(_=> Id()),
    ...Array(right).fill(0).map(_=> Id()),

  ]

  let firstRow = [
    ...Array(left).fill(0).map(_=> firstRowLabelId),
    ...Array(middleColCount).fill(0).map(_=> Id()),
    ...Array(right).fill(0).map(_=> Id()),
  ]

  

  let secondRow = [
    ...Array(left).fill(0).map(_=> Id()),
    ...Array(middleColCount).fill(imageCellId),
    ...Array(right).fill(0).map(_=> Id()),
  ]

  let grid = [zerothRow, firstRow,secondRow];

  if( args.numRows ){
    let diff = args.numRows - grid.length;
    if( diff > 0 ){
      for(let ii = 0; ii < diff; ii++){
        grid.push(

          [
            ...Array(left).fill(0).map(_=> Id()),
            ...Array(middleColCount).fill(0).map(_=>Id()),
            ...Array(right).fill(0).map(_=> Id()),
          ]
        )
      }

    }else if( diff < 0 ){
      grid = grid.slice(0,args.numRows);
    }
    
    
  }

  let columnLayout = [
    {type:'header',num:left},
    {type:'default',num:middleColCount},
    {type:'header',num:right}
  ]

  let columnWidths = createColumnWidths({columnLayout, figurePanelType});

  let uniqueIds = Array.from(new Set(grid.flat()));
  let cellGroups = Object.fromEntries(uniqueIds.map(_id => ([_id,CellGroup(_id)])));

  if( includeGuides ){
    applyGuides( cellGroups, { imageCellId, zerothRowLabelId, firstRowLabelId } );
  }

  return { cellGroups, grid, columnWidths }

}

function MicroscopyLayout(state,args){
  let { groupIds, values, compactTemplateId, compactTemplate, templateId, localTemplateId } = args;

  let template;
  if( compactTemplateId ){
    compactTemplate = HardCodedMicroscopyTemplateMap[compactTemplateId];
    if( !compactTemplate ){
      throw Error("No compactTemplateId exists '"+compactTemplateId);
    }
  } 

  if( compactTemplate ){
    template = G.getTemplateExpansion(null,compactTemplate);
  }else if( templateId ){
    template = G.getData(state,{itemType:"templates", _id:templateId});
  }


  // determine column order 
  let templateRoot = template.nodes.root;
  let templateMovesHorizontally = templateRoot.d === "h";
  let numRootChildren = templateRoot.c.length;

  let left = 1;
  let right = 1;
  let middle = templateMovesHorizontally ? numRootChildren : 1;
  args.left = left;
  args.right = right;
  args.middle = middle;



  let tableInfo;

  if( !groupIds ){

    tableInfo = getDefaultGridAndCellGroupsAndColumnWidths(
      args
    )

  }else{

    let grid = Array(groupIds.length).fill(null).map(_=>([]));
    let cellGroups = {};
    groupIds.forEach((row,iiRow) => {
      row.forEach((_id,iiCol) => {
        if( cellGroups[_id] === undefined ){
          cellGroups[_id] = CellGroup(_id,{value:String(values[iiRow][iiCol])})
        }
        grid[iiRow][iiCol] = _id;
      })
    })
    let columnWidths = Array(grid[0].length).fill(30);
    tableInfo = {
      grid, cellGroups, columnWidths
    }

  }

  let { grid, columnWidths, cellGroups } = tableInfo;



  return {
    gelLanesStart:left,
    gelLanesEnd:left + middle,
    grid, 
    cellGroups,
    columnWidths,
    includeGuides:false,
    globalStyle:{
      rowSpacing:7,
      pointerWidth: 10,
      textPointerSpacing: 0,
      labelFontSize: 9
    },
    config:{
      templates:{
        "initTemplate":template
      },
      currentTemplateId:"initTemplate"
    }
  }

  

}




function WesternBlotFigurePanel(state,args){
  let { left, gel, right, labelLayout, includeGuides, groupIds, values } = args;

  

  let tableInfo;

  if( !groupIds ){

    tableInfo = getDefaultGridAndCellGroupsAndColumnWidths(
      args
    )

  }else{

    let grid = Array(groupIds.length).fill(null).map(_=>([]));
    let cellGroups = {};
    groupIds.forEach((row,iiRow) => {
      row.forEach((_id,iiCol) => {
        if( cellGroups[_id] === undefined ){
          cellGroups[_id] = CellGroup(_id,{value:String(values[iiRow][iiCol])})
        }
        grid[iiRow][iiCol] = _id;
      })
    })
    let columnWidths = Array(grid[0].length).fill(30);
    tableInfo = {
      grid, cellGroups, columnWidths
    }

  }

  let { grid, columnWidths, cellGroups } = tableInfo;


  



  

  

  return {
    gelLanesStart:left,
    gelLanesEnd:left + gel,
    grid, 
    cellGroups,
    columnWidths,
    includeGuides,
    globalStyle:{
      rowSpacing:7,
      pointerWidth: 10,
      textPointerSpacing: 0,
      labelFontSize: 9
    },
    config:{
      labelLayout
    }
  }


}

function getWidthByColumnType({cellType,figurePanelType}){
  if( cellType === 'header' ){
    return DefaultHeaderColumnWidth;
  }else if( cellType === 'default' ){
    return DefaultColumnWidths[figurePanelType];
  }else{
    throw Error("Unrecognized columnType for getting width: " + cellType);
  }
}

function createColumnWidths({columnLayout,figurePanelType}){



  let widthsInArrays = columnLayout.map(({type,num}) => {
    let cellType = type;
    return Array(num).fill( getWidthByColumnType({cellType,figurePanelType}) );
  })

  return widthsInArrays.flat();

}

function SampleLayout(state,args){
  let { lanes, headerColumns, numRows } = args;

  if( isNaN(lanes)  ){
    throw Error("Did not find lanes in SampleLayout args: " + JSON.stringify(args));
  }


  let left = 0;
  let right = 0;
  if( headerColumns ){
    let leftHeaders = headerColumns.left || [];
    let rightHeaders = headerColumns.right || [];
    left =  leftHeaders.length
    right = rightHeaders.length;
  }

  let columnLayout = [{type:'header',num:left},{type:'default',num:lanes},{type:'header',num:right}]
  let columnWidths = createColumnWidths(columnLayout);

  let totalNumCols = lanes + left + right;

  let grid = Array(numRows||2).fill(null).map(() => {
    return Array(totalNumCols).fill(0).map(_=>Id())
  });


  let uniqueIds = Array.from(new Set(grid.flat()));
  let cellGroups = Object.fromEntries(uniqueIds.map(_id => ([_id,CellGroup(_id)])));

  let config = {
    headerColumns
  }



  return {
    gelLanesStart:left,
    gelLanesEnd:left + lanes,
    grid,
    cellGroups,
    columnWidths,
    config
  }



}

const VALID_PANEL_TYPES = ['westernBlot','sampleLayout','microscopy'];

export default function FigurePanelFactory(state,item){


  let { figurePanelType, args } = item;

  let templateFigureId = args && (args.fromTemplate || args.fromFigure);

  let panel;

  if( templateFigureId ){
    panel = {...G.getFigurePanelWithoutAnnotations(state,{figurePanelId:templateFigureId})};
    panel._id  = item._id || Id();
  }else{


    let panelFactoryMap = {
    westernBlot:WesternBlotFigurePanel,
    sampleLayout:SampleLayout,
    microscopy:MicroscopyLayout,
  }

  if( !(figurePanelType in panelFactoryMap) ){
    throw Error("There's no figurePanelType '"+figurePanelType+"', you need to include ['fiugrePanelType'] followed by one of "+JSON.stringify(Object.keys(panelFactoryMap)));
  }

  let figurePanelArgs = {
    ...figurePanelDefaultArgs[figurePanelType],
    ...args,
    figurePanelType,
  }


    panel = panelFactoryMap[figurePanelType](state,figurePanelArgs);
    panel.figurePanelType = figurePanelType;



  }


  return panel;
} 
