import { getGlobalObject, isUsingWebdriver } from './versionConfig';

function getCookie(name){

  let cookie = document.cookie;
  let pairs = cookie.split(';').map(x => x.trim().split('='))
  let map = Object.fromEntries(pairs);
  return map[name];

}


export default function injectTestSessionHeaders(args,extraArgs){

  let resolvedHeaders = { ...args };




  let globalObject = getGlobalObject()

  let testMeta = globalObject.__testMeta;

  let sessionInfo;

  if( testMeta ){

    let { state } = extraArgs || {};

    let windowSessionId = state.windowSessionId;

    if( typeof window === 'undefined' ){
      sessionInfo = testMeta.getSession(windowSessionId);
    }else{
      sessionInfo = testMeta
    }



    if( !sessionInfo ){
      throw Error("Could not get session from testMeta with _id = " + state.windowSessionId+"\n\nSessions = " + JSON.stringify(Object.keys(testMeta.sessions)));


    }
  }

  sessionInfo = sessionInfo || {}

  //console.error(JSON.stringify({sessionInfo}));


  
  let testTimeDelta = 0;
  if( Date.defaultNow ){
    testTimeDelta = (Date.now() - Date.defaultNow());
  }

  

  let headers = {
    ...args.headers,
  }

  if( sessionInfo.ipAddress ){
    headers['Test-Remote-Addr'] = sessionInfo.ipAddress;
  }

  if( sessionInfo.agentId ){
    headers['Test-Agent-Id'] = sessionInfo.agentId;
  }else{
    if( process.env.NODE_ENV !== 'production' && !isUsingWebdriver() ){

      headers['Test-Agent-Id'] = 'manualDev'
    }
    // if it's not webdriver
    // and it's in development
    // then, it should be 'dev'

  }

  

  if( testTimeDelta !== undefined ){
    headers['Test-Time-Delta'] = testTimeDelta;
  }

  if( sessionInfo._id ){
    headers['Test-Window-Session-Id'] = sessionInfo._id;
  }


  //headers['Test-Session-Info'] = JSON.stringify(sessionInfo);

  let toReturn = {
    ...resolvedHeaders
  }

  if( extraArgs.route && !extraArgs.route.includes('s3.amazonaws') ){
    toReturn.headers = headers;
  }

  return toReturn;

}
