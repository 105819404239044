import { useState, useEffect } from 'react';
import A from './ActionCreators';
import { useSelector, useDispatch } from 'react-redux';
import { QUANTIFICATION } from './UIConstants';
import G from './Getters';

function activeFocusConsumesUndo(){



  let { activeElement } = document;
  let { tagName } = (activeElement||{});

  //let isBody = tagName === 'BODY'

  //Previously we had undo if it's body
  //I have no idea why we had that
  //So I just changed it to everything 
  //except for input.

  let inputFieldTypes = ["INPUT","TEXTAREA"];


  if( inputFieldTypes.includes(tagName) ){

    if( tagName === "INPUT" && activeElement.type === "range" ){
      //because range inputs are directly
      //hooked in to state,
      //whenever they change, so does state
      //so those fields (range) cannot
      //consume undo
      return false;
    }

    /*if( tagName==="TEXTAREA" ){
      let valueDisplayTextIsNotClicked = activeElement.getAttribute('consumearrowkeys') !== "true";
      if( valueDisplayTextIsNotClicked ){

        return false;
      }

    }*/
    return true;
  }




  return false;

}


function isQuantification(mode){
  return mode === QUANTIFICATION;
}

export default function ShortcutListener({mode}){

  let dispatch = useDispatch();
  let inTutorial = useSelector(G.isInTutorial);

  let onKeyDown;
  if( inTutorial ){
    onKeyDown = e => {};
  }else{
    onKeyDown = e => {

      //console.error(JSON.stringify({meta:e.metaKey, controlKey:e.ctrlKey, shiftKey:e.shiftKey, command:e.commandKey, key:e.key})); 

      let zKey = 'zZ'.includes(e.key);

      let ctrl = e.ctrlKey;
      let cmd = e.metaKey;

      let ctrlOrCmd = ctrl || cmd;

      let isUndo = ctrlOrCmd && !e.shiftKey && zKey;
      let isRedo = ctrlOrCmd && e.shiftKey && zKey;
      let isSave = ctrlOrCmd && e.key === 's';

      if(isUndo || isRedo){



        let undoConsumed = activeFocusConsumesUndo();
        let ignoreUndo = undoConsumed || isQuantification(mode);


        if( ignoreUndo ){
          return;
        }
        //blurValueDisplayInputIfFocused();
        let type = isUndo ? "UNDO" : "REDO";

        //console.error("\n\nTYPE = " + type + "\n\n")

        e.preventDefault();

        if( process.env.NODE_ENV === 'production' ){

        }

        dispatch({type});


      }else if( isSave ){


      }


    }
  }


  const onTouchStart = e => e.preventDefault();
  const onTouchMove = e => e.preventDefault();


  useEffect(() => {
    //window.addEventListener('touchstart',onTouchStart);
    //window.addEventListener('keypress',onKeyPress);
    window.addEventListener('keydown',onKeyDown);
    //window.addEventListener('keyup',onKeyUp); 
    //window.addEventListener('touchmove',onTouchMove); 


    return () => {
      //window.removeEventListener('keypress',onKeyPress);
      window.removeEventListener('keydown',onKeyDown); 
      //window.removeEventListener('keyup',onKeyUp); 
      //window.removeEventListener('touchstart',onTouchStart);
      //window.removeEventListener('touchmove',onTouchMove);


    }
  })

  return <span id={"shortcut-listener"}/>


}
