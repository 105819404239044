


export default function WelcomeAbout({setAuthModal}){


  
  let bottom = (
    <div style={{alignItems:'center'}}>
      <span onClick={() => setAuthModal(true)} class="signin-hover clickable" style={{border:'3px solid darkblue',
        padding:3,
        margin:3,
        borderRadius:10,
        //background:'darkblue',
        //color:'darkblue',
        fontWeight:'bold',
      }}>Join Sciugo</span> and experience fast, secure and traceable scientific research.
    </div>
  );



  let formatted = about.split('\n');
  let mapped = formatted.map(x => (
    <div style={{marginBottom:30}}>{x}</div>
  )).flat();


  return (
    <div style={{
      //border:'3px solid blue',
      height:'100%', overflowY:'scroll'}}>
      
    <div style={{
      //border:'3px solid green',
      padding:'10%',
      fontSize:20,
    }} class="fair-margin">

      <div style={{
        //border:'3px solid yellow'
      }}>
      <h2 style={{
        marginBottom:30
      }}>About Sciugo</h2>
      <div style={{
        //border:'3px solid orange'
      }}>
      {mapped}
        {bottom}
      </div>
  
      </div>
    </div>
    </div>
  );
}



let  about = (
    `At Sciugo, we empower scientists to work efficiently while safeguarding their research and collaborations. 

    All scientists must retrace their steps to achieve scientific rigor and to protect themselves, their groups and their institutions.

That's where Sciugo steps in. 

Our software automatically links figures and processed data to their raw data sources, ensuring seamless traceability. With Sciugo, you can always find the original data behind any figure or processed data, saving time and preventing data loss.



Moreover, Sciugo streamlines information sharing among group leads and lab members, protecting your team from turnover or unexpected events. 

All actions within Sciugo are timestamped, enabling clear recognition of those first to groundbreaking discoveries.`);

