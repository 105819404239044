import A from './ActionCreators';
import G from './Getters';

import { useDispatch } from 'react-redux';
import usePresentSelector from './usePresentSelector';

function validateReqs(reqs){

  let missingKey = Object.keys(reqs).find(keyName => !reqs[keyName]);
  
  if( missingKey ){ 
    throw Error("Missing required prop: '"+missingKey+"'");
  }

}


export default function TableActionToolbar({shouldSplitText, onItemClicked, itemOrder, figurePanelId, selectedFigureItems, setSelectedCells,
  location
}){

  let selectedCells = selectedFigureItems.cells || [];


  let reqs = { selectedCells, setSelectedCells };
  validateReqs(reqs);


  const dispatch = useDispatch();



  let editorStyle = usePresentSelector(state => state.editorStyle);

  let selectedRows = Array.from(new Set(selectedCells.map(cell => cell[0])))
  let selectedCols = Array.from(new Set(selectedCells.map(cell => cell[1])))

 


  let canMergeSelectedCells = usePresentSelector(state => {
    if( !selectedCells ){
      throw Error("The selected cells property was removed, but we're trying to read from it when checking mergeability.");
    }
    return G.canMergeCells(state,{figurePanelId,cells:selectedCells})
  });

  const buttonTextComponent = (text) => {
    if(shouldSplitText===false){
      return <div style={{margin:2.5,textAlign:'left'}}>{text}</div>
    }
    let split = text.split(' ');
    return split.map( (word,iiWord) => {
      return (
        <div style={{fontSize:9}}>
          {word}
          {iiWord < split.length - 1 && <br/>}
        </div>
      )
    })
  }

  const Space = ({}) => (

    <div className="thin-vertical-line" 
      style={{marginLeft:'3px',marginRight:'3px',width:'1px'}}>
    </div>

  )

  


  let components = {
    deleteRow:(<MyDiv 
        onItemClicked={onItemClicked}
        location={location}
        action={"row-delete"}
        disabled={selectedRows.length === 0}
        onClick={
        ()=>{
          setSelectedCells([]);
          dispatch(A.deleteRow(
            {figurePanelId,selectedCells}
        ))}}>
        {buttonTextComponent("Delete row"+(selectedRows.length > 1 ? "(s)" : ""))}
      </MyDiv>),
    deleteColumn:(<MyDiv
      onItemClicked={onItemClicked}
        location={location}
        action={"col-delete"}
        disabled={selectedCols.length === 0}
        onClick={
          ()=>{
            setSelectedCells([]);
            dispatch(A.deleteColumn(
            {figurePanelId,selectedCells}
          ))}}>
        {buttonTextComponent("Delete column"+(selectedCols.length > 1 ? "(s)" : ""))}
      </MyDiv>),
    split:(

      <MyDiv 
        onItemClicked={onItemClicked}
        location={location}
        action={"cell-split"}
        disabled={selectedCells.length === 0}
        onClick={() => {

          dispatch(A.splitCells({
            cells:selectedCells,
            figurePanelId,
            deselectCells:true
          }))
          

        }}>{buttonTextComponent("Split cells")}</MyDiv>

    ),
    merge:(

      <MyDiv 
        location={location}
        action={"cell-merge"}
        onItemClicked={onItemClicked}
        onClick={() => {
          dispatch(A.mergeCells({cells:selectedCells,figurePanelId}))
        }}
        disabled={!canMergeSelectedCells}>
        {buttonTextComponent(`Merge cells`)}
      </MyDiv>

    ),
    line:<div style={{
      height:1,width:'100%',
      background:'#ccc'

    }}/>



  }

  let defaultOrder = ['deleteRow','deleteColumn','split','merge'];

  let resultantOrder = (itemOrder||defaultOrder)

  let result = resultantOrder.map((item,ii) => (
    <>
      
      {components[item]}
      {(ii !== resultantOrder.length - 1) && <Space/>}
    </>
  ))

  return result;

}

function MyDiv({action,disabled,children,className,onClick,location, onItemClicked}){
    let classModification = disabled ? "disabled-button" : "hoverable";

    let resolvedClass = classModification + " toolbar-action-button toolbar-button";

    return (
      <div 
        location={location}

        action={action}

        onClick={() => {
          onClick && onClick();
          onItemClicked && onItemClicked();

        }} className={resolvedClass}>{children}</div>
    )
  }
