import { useSelector } from 'react-redux';

import NPSSurvey from './NPSSurvey';
import FreeTrialEndNotification from './FreeTrialEndNotification';
import ReferralProgramMessage from './ReferralProgramMessage';
import HowToImprove from './HowToImprove';
import EmailValidation from './EmailValidation';

const SURVEY_MAP = {
  npsScore:<NPSSurvey/>,
  freeTrialEndNotification:<ReferralProgramMessage/>,
  howToImprove:<HowToImprove/>,
  emailValidationRequired:<EmailValidation/>
}


export default function SurveyContainer({survey}){



  let surveyComponent = SURVEY_MAP[survey.name];


  //alert(JSON.stringify(survey));



  
  return (
    <div 
      container={"survey"}
      survey={survey.name}
      class="flex-expand full-height"
      style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',

        //background:'pink'
      }}>
      {surveyComponent}
    </div>
  )


}
