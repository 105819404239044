import PanelPreview from './PanelPreview';
import { useState, useEffect } from 'react';
import stomach from './svg/stomach.svg';
import all from './svg/all.svg';
import kidney from './svg/kidney.svg';
import spleen from './svg/spleen.svg';
export default function GelFigure(){

  const [ panelPreviewWidth, setPanelPreviewWidth ] = useState(); 

  const [ charts, setCharts ] = useState("all");


  let figureList = charts !== "all" ? [stomach,kidney,spleen] : [all];

  const onResize = () => {
    setPanelPreviewWidth(null);
  }

  useEffect(() => {
    window.addEventListener('resize',onResize)
    return () => {
      window.removeEventListener('resize',onResize);
    }
  })


  return (
    <div style={{
      //background:'yellow',
      //border:'3px solid lime',
      height:'100%',
      //minHeight:0,
      position:'relative',
    }}
      class="sg-row">
      <div style={{
        width:'50%',
        height:'100%',

      }}
      ref={el => {
        let width = el && el.getBoundingClientRect().width;
        if( width ){
          setPanelPreviewWidth(width);
        }
      }}>
        <h4>Figure</h4>
        {false && <div>{"Panel preview width: " + panelPreviewWidth}</div>}
        <div>
          {panelPreviewWidth && <PanelPreview
            width={panelPreviewWidth}
            figurePanelId={"fp0"}
            showTitle={false}
          />}
        </div>
      </div>






      <div style={{
        //border:'3px solid green',
        height:'100%',
        position:'relative',
        width:'50%',
        
      }} class="sg-col">
        <h4>Quantification</h4>
        <div class="fair-padding" style={{

          //border:'3px solid pink',
        }}>
          Group samples by:
          <select onChange={e => setCharts(e.target.value)} style={{marginLeft:20}}>
            <option id={"all"} value={"all"}>Nothing (all together)</option>
            <option value={"organs"}>Organ</option>
          </select>
        </div>


        <div style={{
          //border:'3px solid red',
          minHeight:0,
          overflowY:'scroll',
        }} class="flex-expand fair-padding">
          {true && figureList.map(src => <div style={{borderRadius:10, border:'1px solid #ccc'}}><img style={{borderRadius:10}} width={"100%"} src={src}/></div>)}
        </div>

      </div>
    </div>
  )
}
