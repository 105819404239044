import { FilesystemHierarchyContext, QuantificationListContext, FilesystemStore } from './Contexts';

import Tooltip from '@material-ui/core/Tooltip';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


import { IMAGE_SETS, ANNOTATIONS } from './RecordTypes';

import { FILESYSTEM_NAME } from './Filesystem';
import { getImageSetAnnotationsAsFilesystemItems } from './FilesystemComponentFunctions';

import { CREATION_DATE, LAST_EDITED_DATE } from './FieldConstants';

import FilesystemListContainer from './FilesystemListContainer';
import G from './Getters';
import usePresentSelector from './usePresentSelector';
import { useState } from 'react';
import MinimizedFilesystemContainer from './MinimizedFilesystemContainer';



const TOGGLE_ON_QUANTIFICATION_LIST = "quantificationListToggle";

export default function QuantificationFilesystemContainer(){

  const topLevelDirectoryId = usePresentSelector(state => G.getTopLevelDirectoryId(state,{itemType:"imageSets"}));

  const [showFilesystem,setShowFilesystem] = useState(true);


  if( !showFilesystem ){
    return <MinimizedFilesystemContainer {...{setShowFilesystem}}/> 
  }

  return (

    <FilesystemHierarchyContext.Provider value={{

      [IMAGE_SETS]:(state,_id) => {
        let children = getImageSetAnnotationsAsFilesystemItems(state,_id);
        let expandable = children.length > 0;
        return { children, expandable };
      },
      [ANNOTATIONS]:(state,_id) => {
        return {children:[],expandable:false}
      }

    }}>
      <div class="sg-col" style={{
        //border:'1px solid blue',
        width:350
        }}>

        <div style={{
          height:45,
          background:'#fafafa',
          position:'relative',
          //border:'3px solid red',

        }}>
          <div 
            onClick={() => setShowFilesystem(false)}
            button="hide-filesystem-drawer" class="small-padding dark-hoverable round-corners" style={{
              position:'absolute',

              // background:'#f4f4f4',


              display:'flex',
              borderRadius:25,
              alignItems:'center',
              justifyContent:'center',
              right:5,
              //width:'100%',
              //height:100,
            }}
          >

            <Tooltip title={"Hide Filesystem Window"}>
              <ArrowBackIosIcon style={{
                //fontSize:18,
                padding:0,
                margin:0,
                paddingLeft:5,
              }}/>

            </Tooltip>
          </div>
        </div>
        <div style={{
          //border:'3px solid yellow'
        }} class="flex-expand">


        <FilesystemStore.Provider value={{isFilesystemItemSelected:() => false, onFilesystemItemEvent:() => {}}}>
          <FilesystemListContainer {...{
            topLevelDirectoryId,
            columns:[
              FILESYSTEM_NAME,
              LAST_EDITED_DATE,
              CREATION_DATE,
              TOGGLE_ON_QUANTIFICATION_LIST
            ]


          }}/>
      </FilesystemStore.Provider>
        </div>
      </div>
    </FilesystemHierarchyContext.Provider>
  )

}
