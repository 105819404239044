export default function pushPatchOnUndoHistoryStack(draft,filteredPatch){

  let thereAreChangesToApplyToHistory = Object.keys(filteredPatch ).length > 0;
    
  if( thereAreChangesToApplyToHistory ){
    draft.historyInfo.activeHistory.splice(
      0, draft.historyInfo.index+1, filteredPatch 
    )
    draft.historyInfo.index = -1;
  }
}
