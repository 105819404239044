


import Modal from 'react-bootstrap/Modal';
import AuthContainer from './AuthContainer';























export default function SigninModal({closeModal}){
  


  const onHide = () => {
    closeModal();
  }

  return (

    <Modal size="m" show={true} onHide={onHide}>
      <Modal.Body style={{
        background:'#f8f8f8',
        margin:0,
        padding:0,
        borderRadius:10,

      }}>

        <AuthContainer maxWidth={'100%'}/>
        
      </Modal.Body>

    </Modal>

  )
}
