import DefaultState from './DefaultState';

export default function stateClearedOfUserData(state){

  let newState = DefaultState();

  let toPersist = ["windowSessionId","agentId"];

  toPersist.forEach(key => {
    newState[key] = state[key]
  })

  return newState;






}
