import { DIRECTORIES, IMAGE_SETS, FIGURE_PANELS, getResolvedItemTypeName } from './RecordTypes';

import { FILESYSTEM_PARENT_DIRECTORY, FILESYSTEM_NAME, FILESYSTEM_NAME_PREFIX } from './Filesystem';

import Dialog from './DialogConstants';
import G from './Getters';

const topLevelDirectories = [FIGURE_PANELS, IMAGE_SETS]

function getParentDirectoryId(state,args){

  let { inTopLevelParent } = args;
  let parent = args[FILESYSTEM_PARENT_DIRECTORY];
  if( parent !== undefined ){ 
    return parent; 
  }else if( inTopLevelParent ){
    return G.getTopLevelDirectoryId(state,{type:inTopLevelParent})
  }else if( topLevelDirectories.includes(args.type) ){
    return G.getTopLevelDirectoryId(state,{type:args.type});
  }

  throw Error("Didn't receive inTopLevelParent OR parent ids for new directory. \n\nInstead, got: \n" + JSON.stringify(args,null,1));
}

function resolveNewFilesystemName(state,{resolvedArgs,filesystemParentDirectoryId}){

  let { type } = resolvedArgs;

  let filesystemName;

  if( FILESYSTEM_NAME_PREFIX in resolvedArgs ){

    let prefix = resolvedArgs[FILESYSTEM_NAME_PREFIX];
    filesystemName = G.getNextFilesystemNameWithPrefix(state,{filesystemParentDirectoryId,prefix});

  }else if( resolvedArgs[FILESYSTEM_NAME] ){
    filesystemName = resolvedArgs[FILESYSTEM_NAME];
  }else{
    filesystemName = G.getDefaultNewFilesystemName(state,{itemType:type,filesystemParentDirectoryId})
  }

  return filesystemName;


}



function getNewItemPrefix(state,args){

  let { template, basedOn } = args;
  let makingTemplate = template;
  let makingPanel = !makingTemplate;
  let basedOnTemplate = G.isItemPanelTemplate(state,{_id:basedOn});
  let basedOnPanel = !basedOnTemplate;

  let prefix;

  let copiedName = G.getFilesystemName(state,{_id:basedOn});

  if( makingPanel && basedOnPanel ){
    prefix = `${copiedName} copy`;

  }else if( makingPanel && basedOnTemplate ){
    prefix = `Panel from ${copiedName}`;

  }else if( makingTemplate && basedOnPanel ){
    prefix = `Template from ${copiedName}`;

  }else if( makingTemplate && basedOnTemplate ){
    prefix = `${copiedName} copy`;
  }
  return prefix;

}

function resolveFilesystemArgs(state,{type,args}){


  let filesystemArgs = {};
  
  if( type === FIGURE_PANELS ){

    let figurePanelArgs = args.args;
    if( !figurePanelArgs ){
      return filesystemArgs;
    }

    let { template, basedOn } = figurePanelArgs;


    let basedOnSomeOtherItem = Boolean(basedOn);

    if( template ){
      filesystemArgs.inTopLevelParent = "figurePanelTemplates";
    }

    if( !args.filesystemName ){
      let prefix;
      if( ! basedOnSomeOtherItem ){
        prefix = template ? "Untitled template" : "Untitled figure panel";
      }else if( basedOnSomeOtherItem ){
        prefix = getNewItemPrefix(state,figurePanelArgs);
      }
      filesystemArgs[FILESYSTEM_NAME_PREFIX] = prefix;
    }
    
  }

  return filesystemArgs;



}





export default function getFilesystemMetadataArgs(
  state,args
){

  let error;

  const typesNeedingFilesystemParentDirectory = [
    DIRECTORIES,
    IMAGE_SETS,
    FIGURE_PANELS
  ]

  let { type,}  = args; 
  let metaArgs = {};


  let resolvedItemType = getResolvedItemTypeName(type);
  let resolvedFilesystemNameArgs = resolveFilesystemArgs(state,{type,args});



  let resolvedArgs = {
    ...args,
    ...resolvedFilesystemNameArgs,
    type:resolvedItemType
  };


  let shouldAddFilesystemParentFieldToMeta = 
    typesNeedingFilesystemParentDirectory.includes( resolvedItemType );

  if( shouldAddFilesystemParentFieldToMeta ){

    let filesystemParentDirectoryId = getParentDirectoryId(state,resolvedArgs);





    let fsNameToSet = resolveNewFilesystemName(state,{resolvedArgs,filesystemParentDirectoryId});
    


    let isCreatingRootDirectory = (
      filesystemParentDirectoryId
      ===
      null
    )

    if( !isCreatingRootDirectory && 
      G.findItemIdWithNameInDir(state,{
      directoryId:filesystemParentDirectoryId,
      name:fsNameToSet

    }) ){

      let parentDirName = G.getFilesystemName(state,{
        itemType:DIRECTORIES,
        _id:filesystemParentDirectoryId
      });

      error = {
        dialogName:Dialog.FILESYSTEM_NAME_COLLISION_ON_SET,
        source:'createItem',
        args:{ 
          newFilesystemName:fsNameToSet,
          parentDirName
        }
      }

        //"The file '"+fsNameToSet+"' already exists in '"+parentDirName+"'.";



    }else{

      metaArgs[FILESYSTEM_PARENT_DIRECTORY] =
        filesystemParentDirectoryId;

      metaArgs[FILESYSTEM_NAME] = fsNameToSet
    }
  }

  return {
    error, 
    metaArgs
  };

  
}


