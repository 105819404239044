import React from 'react';

import LabelOptionsIcon from './LabelOptionsIcon';

import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';

import BorderOuterIcon from '@material-ui/icons/BorderOuter';

import BorderAllIcon from '@material-ui/icons/BorderAll';
import BorderBottomIcon from '@material-ui/icons/BorderBottom';
import BorderLeftIcon from '@material-ui/icons/BorderLeft';
import BorderRightIcon from '@material-ui/icons/BorderRight';
import BorderTopIcon from '@material-ui/icons/BorderTop';

import LineWeightIcon from '@material-ui/icons/LineWeight';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';

import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';

import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';

import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';

import TextRotationAngleupIcon from '@material-ui/icons/TextRotationAngleup';
import TextRotationAngledownIcon from '@material-ui/icons/TextRotationAngledown';

import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';

import HeightIcon from '@material-ui/icons/Height';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import A from './ActionCreators';

const fontSize = 18;
const verticalBorderToolbar = {
  subToolbarDirection:'vertical',
  subToolbar:[
    [{
      menuItem:"bottom-border-toggle",
      icon: <BorderBottomIcon style={{fontSize}}/>,
      styleProperty: 'borderBottom',
      valueToSet: '1px solid black',
      hint:'Bottom Border',
    },
      {
        icon:<HeightIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        menuItem:"bottom-border-length",
        hint:'Border % Length',
        type:'number',
        styleProperty:'bottomBorderWidth',
        activatesProperties:{borderBottom:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderBottom),
        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{fontSize}}/>,
        menuItem:"bottom-border-bracket-length",
        hint:'Bracket Length',
        type:'number',
        styleProperty:'bottomBracketHeight',
        activatesProperties:{borderBottom:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderBottom),
        scope:'local',
        defaultValue:0,
      }],
    [
      {
        icon: <BorderTopIcon style={{fontSize}}/>,
        hint:'Top Border',
        styleProperty: 'borderTop',
        valueToSet: '1px solid black'
      },
      {
        icon:<HeightIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        type:'number',
        styleProperty:'topBorderWidth',

        activatesProperties:{borderTop:'1px solid black'},

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderTop),

        scope:'local',
        defaultValue:100,
        hint:'Border % Length',
        minValue:0
      },

      {
        icon:<SpaceBarIcon style={{transform:'rotate(180deg)', fontSize}}/>,
        hint:'Bracket Length',
        type:'number',
        styleProperty:'topBracketHeight',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderTop),

        activatesProperties:{borderTop:'1px solid black'},
        scope:'local',
        defaultValue:0,
      }
    ],
    [{
      icon: <BorderLeftIcon style={{fontSize}}/>,

      hint:'Left Border',

      styleProperty: 'borderLeft',
      valueToSet: '1px solid black'
    },
      {
        icon:<HeightIcon  style={{fontSize}}/>,

        hint:'Border % Length',

        type:'number',
        styleProperty:'leftBorderHeight',

        activatesProperties:{borderLeft:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderLeft),
        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        type:'number',

        hint:'Bracket Length',

        styleProperty:'leftBracketWidth',
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderLeft),

        activatesProperties:{borderLeft:'1px solid black'},

        scope:'local',
        defaultValue:0,
      }],
    [

      {
        icon: <BorderRightIcon style={{fontSize}}/>,

        hint:'Right Border',

        styleProperty: 'borderRight',
        valueToSet: '1px solid black'
      },
      {
        icon:<HeightIcon  style={{fontSize}}/>,
        hint:'Border % Length',
        type:'number',
        styleProperty:'rightBorderHeight',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderRight),
        activatesProperties:{borderRight:'1px solid black'},

        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{transform:'rotate(-90deg)', fontSize}}/>,
        type:'number',
        hint:'Bracket Length',
        styleProperty:'rightBracketWidth',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderRight),
        activatesProperties:{borderRight:'1px solid black'},

        scope:'local',
        defaultValue:0,
      }
    ]
  ]
}

function controlCommandShortcut(key){
  return function(e){
    let ctrl = e.ctrlKey;
    let cmd = e.metaKey;
    let ctrlOrCmd = ctrl || cmd;
    let isKey = e.key === key;
    return ctrlOrCmd && isKey;
  }
}


const toolbarOrder = [
  /* {
    type:'color',
    styleProperty:'color',
    scrop:'local',
    hint:'Text Color',
    id:'toolbar-item-text-color',
    defaultValue:'black',

  },
  */

  {
    icon:<FormatLineSpacingIcon style={{fontSize}}/>,
    type:'number',
    styleProperty:'rowSpacing',
    hint:'Row Spacing',
    scope:'global',
    defaultValue:0,
    id:'toolbar-item-rowSpacing',
    minValue:0
  },
  'space',

  {
    icon:<HeightIcon style={{transform:'rotate(90deg)', fontSize}}/>,
    type:'number',
    styleProperty:'columnWidth',
    hint:'Cell Width',
    scope:'local',
    defaultValue:30,
    minValue:5
  },
  'space',

  {
    icon:<TextRotationAngleupIcon style={{fontSize}}/>,
    hint:'Text angle',
    type:'angle',
    styleProperty:'angle',
    scope:'local',
    defaultValue:0,
    minValue:-90,
    maxValue:90,
  },
  {
    icon:null,
    type:'number',
    styleProperty:'angle',
    scope:'local',
    defaultValue:0,
    minValue:-90,
    maxValue:90,
  },
  'space',
  {
    icon:<LabelOptionsIcon style={{fontSize}}/>,
    hint:'Molecular Weight Marker Formatting',
    subToolbar:[
      /*{
        text:'Pointer-image spacing',
        type:'number',
        scope:'global',
        styleProperty:'pointerImageSpacing',
        defaultValue:0
      },*/
      {
        text:'Pointer width',
        type:'number',
        scope:'global',
        styleProperty:'pointerWidth',
        defaultValue:10
      },
      {
        text:'Label-pointer spacing',
        type:'number',
        scope:'global',
        styleProperty:'textPointerSpacing',
        defaultValue:0,
      },
      {
        text:'Label font size',
        styleProperty:'labelFontSize',
        type:'number',
        scope:'global',
        defaultValue:9
      }
    ]
  },


  'space',

  /*{
        menuItem:"bottom-border-toggle",
        icon: <BorderBottomIcon style={{fontSize}}/>,
        styleProperty: 'borderBottom',
        valueToSet: '1px solid black',
        hint:'Bottom Border',
  },*/
  /*{
    icon: <BorderAllIcon style={{fontSize}}/>,
    menuItem:"border-menu",
    hint:'Borders',
    subToolbar:[
      {
        menuItem:"bottom-border-toggle",
        icon: <BorderBottomIcon style={{fontSize}}/>,
        styleProperty: 'borderBottom',
        valueToSet: '1px solid black',
        hint:'Bottom Border',
      },
      {
        icon:<HeightIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        menuItem:"bottom-border-length",
        hint:'Border % Length',
        type:'number',
        styleProperty:'bottomBorderWidth',
        activatesProperties:{borderBottom:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderBottom),
        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{fontSize}}/>,
        menuItem:"bottom-border-bracket-length",
        hint:'Bracket Length',
        type:'number',
        styleProperty:'bottomBracketHeight',
        activatesProperties:{borderBottom:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderBottom),
        scope:'local',
        defaultValue:0,
      },

      'space',
      
      {
        icon: <BorderLeftIcon style={{fontSize}}/>,

        hint:'Left Border',

        styleProperty: 'borderLeft',
        valueToSet: '1px solid black'
      },
      {
        icon:<HeightIcon  style={{fontSize}}/>,

        hint:'Border % Length',

        type:'number',
        styleProperty:'leftBorderHeight',

        activatesProperties:{borderLeft:'1px solid black'},
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderLeft),
        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        type:'number',

        hint:'Bracket Length',

        styleProperty:'leftBracketWidth',
        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderLeft),

        activatesProperties:{borderLeft:'1px solid black'},

        scope:'local',
        defaultValue:0,
      },
      'space',


      {
        icon: <BorderRightIcon style={{fontSize}}/>,

        hint:'Right Border',

        styleProperty: 'borderRight',
        valueToSet: '1px solid black'
      },
      {
        icon:<HeightIcon  style={{fontSize}}/>,
        hint:'Border % Length',
        type:'number',
        styleProperty:'rightBorderHeight',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderRight),
        activatesProperties:{borderRight:'1px solid black'},

        scope:'local',
        defaultValue:100,
        minValue:0
      },
      {
        icon:<SpaceBarIcon style={{transform:'rotate(-90deg)', fontSize}}/>,
        type:'number',
        hint:'Bracket Length',
        styleProperty:'rightBracketWidth',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderRight),
        activatesProperties:{borderRight:'1px solid black'},

        scope:'local',
        defaultValue:0,
      },
      'space',
      {
        icon: <BorderTopIcon style={{fontSize}}/>,
        hint:'Top Border',
        styleProperty: 'borderTop',
        valueToSet: '1px solid black'
      },
      {
        icon:<HeightIcon style={{transform:'rotate(90deg)', fontSize}}/>,
        type:'number',
        styleProperty:'topBorderWidth',

        activatesProperties:{borderTop:'1px solid black'},

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderTop),

        scope:'local',
        defaultValue:100,
        hint:'Border % Length',
        minValue:0
      },

      {
        icon:<SpaceBarIcon style={{transform:'rotate(180deg)', fontSize}}/>,
        hint:'Bracket Length',
        type:'number',
        styleProperty:'topBracketHeight',

        disabledEval:({unifiedGroup}) => !(unifiedGroup?.style.borderTop),

        activatesProperties:{borderTop:'1px solid black'},
        scope:'local',
        defaultValue:0,
      }
    ],

    ...verticalBorderToolbar,

  },*/
  {

    icon:<BorderAllIcon style={{fontSize}}/>,
    menuItem:"border-menu",
    hint:'Border Settings',
    type:'border',
    subToolbar:[{
      scope:'local',
      hint:'Border Settings',
      type:'border',
    }]
  },

  /*{
    icon:<BorderOuterIcon style={{fontSize}}/>,
    hint:'All Borders'
  },*/

  /*
  {
    icon:<LineWeightIcon style={{fontSize}}/>,
    type:'number',
    styleProperty:'borderThickness',
    scope:'local',
    defaultValue:1,
    id:'toolbar-item-line-weight',
    minValue:1,

  },

  {
    scope:'local',
    hint:'Border Color',
    icon:({borderColor}) => {
      return <div style={{alignItems:'center', justifyContent:'center',

      }} class="sg-col">

        <div style={{
          paddingTop:2,
          fontSize:16,
          fontWeight:'bold',
          lineHeight:0.7,
          //marginBottom:3,
        }}><BorderColorIcon style={{fontSize}}/></div>

        <div style={{
          marginTop:-3,
          position:'relative',
          width:18,height:5, 
          background:(borderColor||"#000000"),
          //borderRadius:3,
          border:'1px solid #aaa',
        }}/>
      </div>
    },
    subToolbar:[{
      scope:'local',
      valueToSet:'borderColor',
      //icon: <FormatUnderlinedIcon style={{fontSize}}/>,
      styleProperty:'borderColor',
      type:'color'
    }],
  },
  */

  'space',
  {
    styleProperty:'fontSize',
    type:'number',
    defaultValue:12,
    scope:'local',
    minValue:5,
    //hint:(<div><div>Font size</div></div>),
  },
  {
    icon: <FormatBoldIcon style={{fontSize}}/>,
    styleProperty: 'fontWeight',
    valueToSet: 'bold',
    hint:(<div style={{textAlign:'center'}}>Bold<br/>Ctrl/Cmd + b</div>),
    controlCommandShortcut:'b'
  }
  ,
  {
    icon: <FormatItalicIcon style={{fontSize}}/>,
    styleProperty: 'fontStyle',
    valueToSet: 'italic',
    hint:(<div style={{textAlign:'center'}}>Italic<br/>Ctrl/Cmd + i</div>),
    controlCommandShortcut:'i',
  },
  {
    icon: <FormatUnderlinedIcon style={{fontSize}}/>,
    styleProperty: 'textDecoration',
    valueToSet: 'underline',
    //hint:'Underline',

    hint:(<div style={{textAlign:'center'}}>Underline<br/>Ctrl/Cmd + u</div>),

    controlCommandShortcut:'u',
  },
  {
    scope:'local',
    hint:'Text Color',
    icon:({color}) => {
      return <div style={{alignItems:'center', justifyContent:'center',

      }} class="sg-col">

        <div style={{
          paddingTop:2,
          fontSize:14,
          fontWeight:'bold',
          lineHeight:0.7,
          marginLeft:3,
        }}>A</div>

        <div style={{
          marginTop:5,
          position:'relative',
          width:18,height:5, 
          background:(color||"#000000"),
          //borderRadius:3,
          border:'1px solid #aaa',
        }}/>
      </div>
    },
    subToolbar:[{
      scope:'local',
      valueToSet:'color',
      styleProperty:'color',
      type:'color'
    }],
  },
  'space',
  {
    scope:'local',
    hint:'Background Color',
    icon:({background}) => {
      return <div style={{alignItems:'center', justifyContent:'center',

      }} class="sg-col">

        <div style={{
          paddingTop:2,
          fontSize:16,
          fontWeight:'bold',
          lineHeight:0.7,
          marginLeft:3,
        }}><FormatColorFillIcon
          style={{
            fontSize,
          }}
        /></div>

        {true && <div style={{
          border:'1px solid #aaa',
          marginTop:-4,
          position:'relative',
          width:'100%',
          height:5, 
          background:(background||"#000000"),
        }}/>}
      </div>
    },
    subToolbar:[{
      scope:'local',
      valueToSet:'background',
      styleProperty:'background',
      type:'color'
    }],
  },
  'space',
  {

    styleProperty:'textAlign',

    icon:(value) => {
      switch(value){
        case 'left':
          return <FormatAlignLeftIcon style={{fontSize}}/>;
        case 'center':
          return <FormatAlignCenterIcon style={{fontSize}}/>;
        case 'right':
          return <FormatAlignRightIcon style={{fontSize}}/>;
        default:
          return <FormatAlignCenterIcon style={{fontSize}}/>;

      }
    },

    hint:'Horizontal Alignment',

    subToolbar:[

      {
        icon: <FormatAlignLeftIcon style={{fontSize}}/>,
        styleProperty: 'textAlign',
        valueToSet: 'left'
      },
      {
        icon: <FormatAlignCenterIcon style={{fontSize}}/>,
        styleProperty: 'textAlign',
        valueToSet: 'center'
      },
      {
        icon: <FormatAlignRightIcon style={{fontSize}}/>,
        styleProperty: 'textAlign',
        valueToSet: 'right'
      },
    ]
  },
  /*
  'space',
  {
    icon: <FormatAlignLeftIcon style={{fontSize}}/>,
    styleProperty: 'textAlign',
    valueToSet: 'left'
  },
  {
    icon: <FormatAlignCenterIcon style={{fontSize}}/>,
    styleProperty: 'textAlign',
    valueToSet: 'center'
  },
  {
    icon: <FormatAlignRightIcon style={{fontSize}}/>,
    styleProperty: 'textAlign',
    valueToSet: 'right'
  },
  */
  {
    styleProperty:'verticalAlign',
    hint:'Vertical Alignment',
    icon:(value) => {
      switch(value){
        case 'top':
          return <VerticalAlignTopIcon style={{fontSize}}/>;
        case 'middle':
          return <VerticalAlignCenterIcon style={{fontSize}}/>;
        case 'bottom':
          return <VerticalAlignBottomIcon style={{fontSize}}/>;
        default:
          return <VerticalAlignCenterIcon style={{fontSize}}/>;
      }
    },
    subToolbar:[

      {
        icon: <VerticalAlignTopIcon style={{fontSize}}/>,
        styleProperty: 'verticalAlign',
        valueToSet: 'top'
      },

      {
        icon: <VerticalAlignCenterIcon style={{fontSize}}/>,
        styleProperty: 'verticalAlign',
        valueToSet: 'middle'
      },

      {
        icon: <VerticalAlignBottomIcon style={{fontSize}}/>,
        styleProperty: 'verticalAlign',
        valueToSet: 'bottom'
      }
    ]
  },
  'space',
]




export function toggleStyleProperties({dispatch,selectedFigureItems,item,figurePanelId}){

  let style = { [item.styleProperty]:item.valueToSet}

  let somethingIsSelected = Object.values(selectedFigureItems).some(itemTypeList => itemTypeList.length > 0);
  if( somethingIsSelected ){

    let action = A.setCellsValue(
      { 
        style, 
        figurePanelId,
        figureItems:selectedFigureItems,
      }
    )
    dispatch(action)
  }else{
    dispatch(A.setEditorStyle(style))
  }
}

export function keyboardShortcuts(){
  let getAllMenuItems = list => list.map(x => {

    let toReturn = [x];
    if( x.subToolbar ){
      toReturn.push(...getAllMenuItems(x.subToolbar).flat())
    }
    return toReturn;
  }).flat().filter(x => x);


  let menuItems = getAllMenuItems(toolbarOrder);

  let controlCommandKeyboardShortcuts = {};
  menuItems.forEach(item => {
    let shortcut = item.controlCommandShortcut;
    if( shortcut ){
      controlCommandKeyboardShortcuts[ shortcut ] = {
        ...item,
        icon:undefined
      }
    }
  })

  return controlCommandKeyboardShortcuts;
}



export default toolbarOrder;
