import { useState, useEffect, createRef } from 'react';
import SvgBandAnnotation from './SvgBandAnnotation';
import SvgImage from './SvgImage';

import { getTextWidth } from './PositionalLayout';

const getInnerTextDimensions = (textContainerBounds, angle) => {

  let outerLeft = textContainerBounds.left;
  let outerBottom = textContainerBounds.bottom;
  let outerTop = textContainerBounds.top;

  if( Math.abs(angle) === 45 ){

    throw Error("Cannot compute inner dimensions from 45º angle, an additional 'pivot point' is required.");

  }



  let absTheta = 2 * Math.PI * Math.abs(angle) / 360;
  let outerHeight = textContainerBounds.height;
  let outerWidth = textContainerBounds.width;

  let c = Math.cos( absTheta );
  let s = Math.sin( absTheta );

  let det = Math.cos( 2 * absTheta ); 

  let innerHeight = (outerHeight * c - outerWidth * s) / det;
  let innerWidth = (- outerHeight * s + outerWidth * c) / det;

  //assuming pivot is bottom left

  return {
    innerHeight,
    innerWidth,
  }

}

export default function SvgRendering({div,origin,value,state,cell,imageUrlMap, figurePanel, exportId }){

  if( cell[0] === 0 && cell[1] === 0 ){
    //throw Error("FORCED ERROR AT A SPECIFIC CELL!");
  }

  let {x,y,width,height} = div.getBoundingClientRect();
  let relX = x - origin[0];
  let rectRelY = y - origin[1];

  if( !value ){ return null };

  let rawVal = value.value;

  //can't return null here just because 
  // value.value is falsy
  //because there may be borders
  //on the empty cell
  

  let val = typeof(rawVal)==='string' ? rawVal : "OBJ";

  if( val === "OBJ" ){
  }

  

  let divChars = document.querySelectorAll(
    '[id$='+div.id+']'
  )

  let pivotId = 'pivot-point-'+cell.join('-')
  let pivotMarker = document.getElementById(pivotId);
  let pivotBounds = pivotMarker && pivotMarker.getBoundingClientRect() || {};

  let textContainerDiv = document.getElementById('text-container-'+cell.join('-'));
  let textContainerBounds = textContainerDiv && textContainerDiv.getBoundingClientRect() || {};


  let textStyle = { 
    fontFamily:'Helvetica',
    fontSize:12,
    ...value.style, 
    fill:value.style.color || 'black',
    textAlign:'center',
  }

  let {angle,angleDirection} = textStyle;
  let transformAngle = (({up:-1,down:1})[angleDirection] || -1) * (angle||0);
  let lineHeight = transformAngle ? 1 : 1.5;


  let innerTextBounds;
  let innerHeight;
  let innerWidth;
  let pivotTop = pivotBounds.top;
  let pivotLeft = pivotBounds.left;
  if( Math.abs(angle) === 45 ){
    let outerHeight = textContainerBounds.height;

    let outerTop = textContainerBounds.top;

    let sin45 = 1 / Math.sqrt(2);

    if( transformAngle < 0 ){
      innerHeight = pivotLeft - textContainerBounds.left;
    }else{
      innerHeight = (pivotTop - outerTop) / sin45;
    }

    innerWidth = outerHeight/sin45 - innerHeight

  }else{
    innerTextBounds = getInnerTextDimensions(textContainerBounds, transformAngle);
    innerHeight = innerTextBounds.innerHeight;
    innerWidth = innerTextBounds.innerWidth;
  }



  let chars = Array.from(divChars).filter(x => x.id.indexOf('char') === 0)
  let firstCharsOfLines = chars.filter(charDiv => charDiv.id.includes('char-0'));

  let lineStrings = val === "OBJ" ? [] : val.split('\n');



  let lineDivs = firstCharsOfLines.sort((a,b) => a.id.localeCompare(b.id)).map(
    (div,ii) => {
      return { bounds: div.getBoundingClientRect(), value: (lineStrings[ ii ]||"") }
    }
  );

  //Take the id of each char
  //We see where the lines are
  //We take the position of the first character as the starting point
  //We string all the characters together
  //If they are in the same line, then they are a line and we only want to keep that
  //Otherwise, they're different lines and we need to ust keep them separately. 

  let linesStartX = {}
  let textLines = {};

  /*
   *<text textAnchor={"middle"} style={{fill:'black',textAlign:'center'}} width={width} height={height} x={relX+0.5*width} y={relY+0.5*height} dominantBaseline="middle">{val}</text>*/



  let borders = [];
  if( value.style ){
    let borderStyles = Object.keys(value.style).filter(key => key.includes('border'));

    let borderTopXOffset = (width * (100 - (value.style.topBorderWidth||100)) / 200 );
    let borderBottomXOffset = (width * (100 - (value.style.bottomBorderWidth||100)) / 200 );
    let borderLeftYOffset = (height * (100 - (value.style.leftBorderHeight||100)) / 200 )


    let borderRightYOffset = (height * (100 - (value.style.rightBorderHeight||100)) / 200 )

    let borderTopX1 = relX + borderTopXOffset - 0.5;
    let borderTopX2 = relX + width - borderTopXOffset + 1;
    let topBracketHeight = value.style.topBracketHeight || 0;

    let borderBottomX1 = relX + borderBottomXOffset - 0.5;
    let borderBottomX2 = relX + width - borderBottomXOffset + 1;
    let bottomBracketHeight = value.style.bottomBracketHeight || 0;

    let rightBracketWidth = value.style.rightBracketWidth || 0;
    let leftBracketWidth = value.style.leftBracketWidth || 0;

    let missingBorder = (value.style.borderThickness || 1)/2;


    let borderLineMap = {
      borderTop:{
        x1:borderTopX1,
        y1:(rectRelY),
        x2:borderTopX2,
        y2:(rectRelY),

        shapeRendering:"crispEdges",

      },
      borderTopLeftBracket:{
        x1:borderTopX1 + 0.5,
        y1:rectRelY,
        x2:borderTopX1 + 0.5,
        y2:rectRelY + topBracketHeight,

      },

      borderTopRightBracket:{
        x1:borderTopX2 - 0.5,
        y1:rectRelY,
        x2:borderTopX2 - 0.5,
        y2:rectRelY + topBracketHeight
      },


      borderBottom:{
        x1:borderBottomX1,
        y1:rectRelY + height,
        x2:borderBottomX2,
        y2:rectRelY + height,

        shapeRendering:"crispEdges",

        //stroke:'magenta',
      },
      borderBottomLeftBracket:{
        x1:borderBottomX1 + 0.5,
        y1:rectRelY + height,
        x2:borderBottomX1 + 0.5,
        y2:rectRelY + height - bottomBracketHeight,
      },

      borderBottomRightBracket:{
        x1:borderBottomX2 - 0.5,
        y1:rectRelY + height,
        x2:borderBottomX2 - 0.5,
        y2:rectRelY + height - bottomBracketHeight,
        //stroke:'magenta',
      },


      borderLeft:{
        x1:relX,
        y1:rectRelY + borderLeftYOffset - missingBorder,
        x2:relX,
        y2:rectRelY+ height - borderLeftYOffset + missingBorder,
        shapeRendering:"crispEdges",
      },
      borderRight:{
        x1:relX+width + 0.5,
        y1:rectRelY + borderRightYOffset - missingBorder,
        x2:relX+width + 0.5,
        y2:rectRelY+height - borderRightYOffset + missingBorder,
        shapeRendering:"crispEdges",
      },

      borderRightTopBracket:{
        x1:relX+width,
        y1:rectRelY + borderRightYOffset,
        x2:relX+width - rightBracketWidth,
        y2: rectRelY + borderRightYOffset
      },

      borderRightBottomBracket:{

        x1:relX+width,
        y1:rectRelY  + height - borderRightYOffset,
        x2:relX+width - rightBracketWidth,
        y2: rectRelY + height - borderRightYOffset,
      },

      borderLeftTopBracket:{
        x1:relX,
        y1:rectRelY + borderLeftYOffset,
        x2:relX + leftBracketWidth,
        y2: rectRelY + borderLeftYOffset
      },

      borderLeftBottomBracket:{
        x1:relX,
        y1:rectRelY + height - borderLeftYOffset,
        x2:relX + leftBracketWidth,
        y2: rectRelY + height - borderLeftYOffset

      }


    }


    for(let key in borderLineMap){
      let line = borderLineMap[key];
      for(let coordKey in line){
        let num = line[coordKey]
        if( Number(num) === num ){
          //line[coordKey] = Math.round(line[coordKey]);
        }
      }
    }

    console.log(JSON.stringify(borderLineMap,null,1));

    borderStyles.forEach( styleKey => {
      if( styleKey === 'borderThickness' ){
        return; 
      }

      let givenStyle = value.style[styleKey];
      let borderLineIsNotSet = !givenStyle;
      if( borderLineIsNotSet ){
        return;
      }

      let stroke;
      if( value.style ){ 
        stroke = value.style.borderColor || "black";
      }
    
      if( !givenStyle ){
        debugger;
      }

      let spl = givenStyle.split(' ');
      let strokeWidth = spl[0];// || value.style.borderThickness;
      let resolvedStroke = stroke || spl[2];
      let style={
        stroke:resolvedStroke,//'rgb('+Array(3).fill(null).map(x => Math.round(Math.random()*255)).join(',')+')',

        //stroke:'rgb('+Array(3).fill(null).map(x => Math.round(Math.random()*255)).join(',')+')',

        strokeWidth:value.style.borderThickness||1}
      let lineArgs = {
        ...borderLineMap[styleKey],
        style,
        

        //strokeWidth:99,//(value.style.borderThickness||1)
      }

      borders.push(<line cell={cell} {...lineArgs}/>);
    })

    
    //let strokeWidth = value.style.borderThickness || 1;
    if( value.style.borderThickness ){
      debugger;
    }

    const makeLine = pointMap => <line {...{
      cell,
      stroke:'black',
      strokeWidth:1,
      ...pointMap,
      shapeRendering:"crispEdges"
    }}/>

      borders.push(...[
        ...(value.style.borderTop ? [
        makeLine(borderLineMap.borderTopLeftBracket),
        makeLine(borderLineMap.borderTopRightBracket),
        ] : [] ),


        ...(value.style.borderBottom ? [
          makeLine(borderLineMap.borderBottomLeftBracket),
          makeLine(borderLineMap.borderBottomRightBracket),
        ] : [] ),


        ...(value.style.borderRight ? [

        makeLine(borderLineMap.borderRightBottomBracket),
        makeLine(borderLineMap.borderRightTopBracket),
        ] : [] ),


        ...(value.style.borderLeft ? [
        makeLine(borderLineMap.borderLeftBottomBracket),
        makeLine(borderLineMap.borderLeftTopBracket),
        ] : [] ),

      ]);


  }



  let image;
  let bandAnnotation;

  let relBounds = {
    x:relX,
    y:rectRelY,
    width,
    height
  }



  let imageTypes = ['crop','image'];
  if( imageTypes.includes(value.value.valueType) ){
    image = <SvgImage 

      exportId={exportId}
      

    imageUrlMap={imageUrlMap} state={state} value={value} bounds={relBounds} div={div}/>

  }else if( value.value.valueType === 'bandAnnotation' ){
    bandAnnotation = (
      <SvgBandAnnotation 
        figurePanel={figurePanel}
        cell={cell}
        state={state}
        annotation={value.value} 
        bounds={relBounds}
      />
    )

  }



  let fontSize;
  if( value.style && value.style.fontSize ){
    fontSize = value.style.fontSize
  }else{
    fontSize = 12;
  }

  let widths;
  try{ 
    widths = lineDivs.map(x => getTextWidth({text:x.value,fontSize}));
  }catch{ 
    debugger;
    widths = lineDivs.map(x => getTextWidth({text:x.value,fontSize}));
  }

  let maxLength = Math.max(...widths);
  //let maxLength = Math.max(...lineDivs.map(x => x.value.length));





  return (
    <>
      {false && <rect style={{fill:'none',stroke:'red',strokeWidth:1}} width={width} height={height} x={relX} y={rectRelY}/>}

      {false && <rect width={width} style={{fill:'none',stroke:'black',height:3,borderLeft:'1px solid black'}} x={relX} y={rectRelY}/>}

      
      {lineDivs.map( (div,iiLine) => {

        let text = div.value;

        //let textHeight = bounds.height

        let pivotPointX = pivotBounds.left - origin[0];
        let pivotPointY = pivotBounds.y - origin[1];


        let rotateOriginX = pivotBounds.left - origin[0]
        let rotateOriginY = pivotBounds.y - origin[1]

        let boundingTextLeft = pivotBounds.left - origin[0];
        let boundingTextTop = -innerHeight + pivotBounds.y - origin[1];


        let rotateArgs = [transformAngle,rotateOriginX,rotateOriginY].join(',')
        let transform = "rotate("+rotateArgs+")"


        let finalTextY = rectRelY + height/2;

        if( lineStrings.length > 1 ){
          finalTextY = pivotPointY - ((lineDivs.length-(iiLine+1)) * fontSize * lineHeight) + 0.375 * fontSize;
        }else if( transformAngle !== 0 ){
          finalTextY = pivotPointY;
        }          


        let baseXPoint;
        let textWidth = getTextWidth({text:(text||""),fontSize})

        if( transformAngle === 0 ){
          baseXPoint = div.bounds.left - origin[0];
        }else{

          
          baseXPoint = pivotPointX + 0.5 * (maxLength - textWidth)-1;
        }

        
        let textX = baseXPoint + 0.5 * (maxLength - textWidth)-1;


        let textHeight = fontSize * 0.7;;//+ 1.5;//+ 1.5 //*0.77;


        let directionFunction = x => {
          if( x === 0 ){ 
            return 0;
          }

          return x;
        }

        let verticalTextPositioning = ({
          top:{ 
            y:(rectRelY +  iiLine * textHeight),
            dy:"0.95em",
            rline:(rectRelY +  iiLine * textHeight) + textHeight/2
          },
          middle:{
            y:(
              rectRelY  + height/2  + (iiLine * textHeight) + 1 * (textHeight/2)
               - (textHeight * (lineDivs.length-1))/2


              //term for direction dependent padding about the center
              + directionFunction( iiLine - ((lineDivs.length-1)/2) ) * 0.5 * fontSize

              //+ textHeight / 2// - 2
              //-(fontSize/2) - 2
            ),
            dy:0,//"0.385em"
            rline:(iiLine + 1)/(1+lineDivs.length) * height + rectRelY
          },
          bottom:{
            y:(
              (rectRelY + height) - ((lineDivs.length - iiLine) * textHeight)
              + textHeight
            ),
            rline: ((rectRelY + height) - ((lineDivs.length - iiLine) * textHeight)
              + textHeight) - textHeight/2,
             //+ textHeight/2),
            dy:"-0.25em",
          }
        });

        let verticalAlignment = textStyle.verticalAlign || "middle";

        

        let vPos = verticalTextPositioning[ verticalAlignment ];
        let vPosY = vPos.y 
        let vPosDy = vPos.dy;

        let redLineY = vPos.rline || (vPosY - textHeight/2);

        

        let TRACE = false;


        return (
          <>
            <text 

              x={baseXPoint}
              y={vPosY}
              dy={vPosDy}
              transform={transform}
              //dominantBaseline={(transformAngle!==0) ? "auto" : undefined}
              textAnchor={"start"}
              style={{
                ...textStyle,
              }} 
              width={width} 
              
              height={height}
              
              fontSize={fontSize}

            >
              {text}
            </text>
            {TRACE && 
            <line x1={relX} y1={redLineY} x2={relX+width} y2={redLineY} stroke={"red"} fill={"red"}/>
            }
            {TRACE && <rect fill={"none"} stroke={"blue"} x={textX} y={vPosY - textHeight} width={textWidth} height={textHeight}/>}

            {TRACE && <rect fill={"none"} stroke={"green"} x={textX} y={vPosY - textHeight} width={textWidth} height={textHeight/2}/>}

            {TRACE && <rect fill={"none"} stroke={"orange"} x={textX} y={vPosY - textHeight/2} width={textWidth} height={textHeight/2}/>}
            {false && ((text) || null) && (<circle 
              id={"pivot-point-"+cell.join('-')}
              cx={baseXPoint} 
              cy={pivotPointY} r="2" fill="magenta"/>)}

            {false && ((text) || null) && (<circle 
              id={"pivot-point-"+cell.join('-')}
              cx={baseXPoint} 
              cy={0.375 * fontSize + div.bounds.top - origin[1]} 
              r="2" fill="green"/>)}

            {false && <circle 
              id={"pivot-point-"+cell.join('-')}
              cx={boundingTextLeft} 
              cy={rectRelY + height/2} r="2" fill="red"/>
            }

            {false && <rect 
              fill={"none"}
              transform={transform}
              stroke={"green"}
              strokeWidth={2}
              height={height}
              width={width}
              x={relX}
              y={rectRelY}
            />}

            <rect 
              fill={"none"}
              transform={transform}
              stroke={"transparent"}
              strokeWidth={1}
              height={innerHeight}
              width={innerWidth}
              x={boundingTextLeft}
              y={boundingTextTop}
            />


          </>
        )

      })}

      {angle && <rect id={"bounding-box-"+cell.join('-')}
        fill={"none"}
        stroke={"none"}
        strokeWidth={1}
        x={textContainerBounds.left - origin[0]}
        y={textContainerBounds.top - origin[1]}
        width={textContainerBounds.width}
        height={textContainerBounds.height}
      />}
      {image}
      {bandAnnotation}

      {borders}
    </>
  )
}
