import LoadImage from './LoadImage';
import SciugoBlob from './SciugoBlob';
import fetch from 'cross-fetch';
export default function CreateGreyscale({src}){


  return LoadImage(src).then(raw => {
    let grey = raw.grey();
    let greyDataUrl = grey.toDataURL("image/png");
    
    return SciugoBlob(greyDataUrl,"image/png").then(blob => URL.createObjectURL(blob));
  });

  
}
