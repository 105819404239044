import G from './Getters';
//import notifyRequirementsThatTheyAreRequired from './notifyRequirementsThatTheyAreRequired';
import { FIGURE_PANELS, ANNOTATIONS } from './RecordTypes';

import Venn from './Venn';

import addMetadataForRecordIfMissing from './addMetadataForRecordIfMissing';

const requirementEvaluationFunctionsByRecordType = {
  [FIGURE_PANELS]:G.getFigurePanelRequirements,
  [ANNOTATIONS]:G.getAnnotationRequirements,
}

function getRequirementByRoleEvalFunc(type){

  let evalFunc = 
    requirementEvaluationFunctionsByRecordType[type];

  return function(state,{_id}){
    let recordExists = G.doesRecordExistInCache(state,{_id,type});

    if( !recordExists ){
      return {}
    }

    let reqsByRole = evalFunc(state,{_id,type});
    return reqsByRole

  }


}

function getRequirementChanges(oldReqs,newReqs){
  let roleVenn = Venn(oldReqs,newReqs);


  let added = {};
  let removed = {};

  let removedRoles = roleVenn.leftNotInRight;
  let addedRoles = roleVenn.rightNotInLeft;
  
  removedRoles.forEach(role => 
    removed[role] = oldReqs[role]
  );
  addedRoles.forEach(role =>
    added[role] = newReqs[role]
  );
  
  let consistentRoles = roleVenn.inBoth;
  consistentRoles.forEach(role => {
    let roleSpecificVenn = Venn(oldReqs[role],newReqs[role]);
    let roleReqsRemoved = roleSpecificVenn.leftNotInRight;
    let roleReqsAdded = roleSpecificVenn.rightNotInLeft;

    removed[role] = roleReqsRemoved;
    added[role] = roleReqsAdded;

  })

  return { added, removed };

}

function getRequirementByRoleDelta({newState,oldState,_id,type}){

  let requirementByRoleEvalFunc = 
    getRequirementByRoleEvalFunc(type); 

  let oldRequirementsByRole = requirementByRoleEvalFunc(oldState,{_id});
  let newRequirementsByRole = requirementByRoleEvalFunc(newState,{_id})
  



  let { added, removed } = getRequirementChanges(oldRequirementsByRole,newRequirementsByRole);

  return { added, removed };

}

function removeBidirectionalDependancy({
  draft,
  referencesToRemove,
  dependantId
}){

  for(let role in referencesToRemove){
    let requiredRecordIds = referencesToRemove[role];
    requiredRecordIds.forEach(_id => {
      let meta = G.getMeta(draft,{_id});
      meta.requiredAsByRole[role] = (meta.requiredAsByRole[role]||[]).filter(x => x!==dependantId)

    })
  }

}

function addBidirectionalDependancy({
  draft,
  referencesToAdd,
  dependantId
}){


  for(let role in referencesToAdd){
    let requiredRecordIds = referencesToAdd[role];
    requiredRecordIds.forEach(_id => {
      let dependantRecord = G.getRecord(draft,{_id:dependantId});

      let dependantRecordType = dependantRecord.type;

      addMetadataForRecordIfMissing(draft,{
        type:dependantRecordType,
        objectId:dependantId
      })



      let metaOfRequired = G.getMeta(draft,{_id});
      //let meta = G.getMeta(draft,{_id});

      metaOfRequired.requiredAsByRole[role] = [
        ...(metaOfRequired.requiredAsByRole[role]||[]),
        dependantId
      ]

      let dependantMeta = G.getMeta(draft,{_id:dependantId});

      

      dependantMeta.requirementsByRole[role] = [
        ...(dependantMeta.requirementsByRole[role]||[]),
        _id
      ]

    })
  }

}

function adjustDependancyGraphEdges({
  draft, referencesToAdd, referencesToRemove, dependantId
}){

  removeBidirectionalDependancy({
    draft,
    referencesToRemove,
    dependantId
  });

  addBidirectionalDependancy({
    draft,
    referencesToAdd,
    dependantId
  })



}


export default function updateRecordRequirementEdges({
  draft,
  oldState,
  newState,
  type,
  recordId
}){

  if( !(type in requirementEvaluationFunctionsByRecordType) ){
    return [];
  }

  
  let requirementByRoleDelta = getRequirementByRoleDelta({newState,oldState,type,_id:recordId});

  

  let idsOfUpdatedNodes = Object.values(requirementByRoleDelta).map(Object.values).flat().flat();



  let { added, removed } = requirementByRoleDelta;

  adjustDependancyGraphEdges({
    draft,
    referencesToRemove:removed,
    referencesToAdd:added,
    dependantId:recordId
  });

  return idsOfUpdatedNodes;

}
