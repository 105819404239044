import TutorialIntro from './TutorialIntro';
import TutorialIntroTwo from './TutorialIntroTwo';
import TutorialHowToExit from './TutorialHowToExit';
import TutorialOutro from './TutorialOutro';
import AdjustCropClip from './gifs/AdjustCropClip.gif';
import A from './ActionCreators';
import BorderWidthPreview from './images/BorderWidthChangePreview.png';
import VerticalCropResizeTutorial from './gifs/LoopCropVert.gif';
import MWExample from './images/MolecularWeightExample.png';
import MakeCrop from './gifs/_MakeCrop.gif';
import { distance } from './Geometry';

const walkthrough = [
  {
    title:"WELCOME",
    scene:onStateChange => <TutorialIntro onStateChange={onStateChange}/>

  },
  {
    title:"HOW_TO_EXIT",
    scene:onStateChange => <TutorialHowToExit onStateChange={onStateChange}/>
  },
  {
    title:"WELCOME_2",
    scene:onStateChange => <TutorialIntroTwo onStateChange={onStateChange}/>
  },
  { 
    title:'SELECT_IMAGE',
    selector:`div[treepath='0'][filesystemcontainer=imageSets]`,
    //ignoreEventTypes:['mousedown','mouseup'],
    nextStateOnSelectorClick:true,
    message:{
      default:(
        <div>
          To start, <b>click</b> in the red box around 'First Image Set'
        </div>
      ),
      offset:{
        left:'calc(100% + 10px)',
        top:0
      }
    
    }
    /*steps:[
      { action:'click', selector:`div[treepath='0'][filesystemcontainer=imageSets]` }
    ]*/
  },
  {
    title:'CREATE_MW_0',
    selector:'#redbeard',
    clickDragArrow:false,
    message:{
      height:130,
      positionAt:'start',
      default:(
        <div style={{position:'relative'}}>
          <div><b>Click</b> inside the red circle to create a location specific label.</div>
          <div style={{marginTop:10}}>This will show up in your figure like this:</div>
          <div style={{width:'100%',marginTop:10}}>
            <img style={{
              width:'100%',
              border:'1px solid black',
            }} src={MWExample}/>
          </div>
        </div>
      ),
      offset:{
        //top:'calc(100% + 10px)',
        left:'calc(100% + 10px)',
        //transform:'translate(0,calc(-100%)) translate(0,-10px)',
      }

    },

    errorMessage:(
      <div>
        Click <b>don't drag</b> inside the red circle to continue.
      </div>
    ),



    lineStartRequirements:{

      relativeOffset:{
        left:0.247,
        top:0.523,
      },
      dim:15,
    },

    lineEndRequirements:{
      relativeOffset:{
        left:0.247,
        top:0.523 
      },
      dim:15,
    },

    isValidClickPath:(mouseDownEvent, mouseUpEvent) => {
      let downX = mouseDownEvent.clientX;
      let downY = mouseDownEvent.clientY;
      let upX = mouseUpEvent.clientX;
      let upY = mouseUpEvent.clientY;

      let norm = distance([downX,downY],[upX,upY]);

      return norm < 1;



    }


  },

  {
    title:'LABEL_MW_0',
    captureAllMouseEvents:true,
    selector:'input[input=mark-input]',
    message:{
      default:<div>Type '50' and press <code>Enter</code> to continue.</div>,
      offset:{
        left:'calc(100% + 10px)',
      }
    },

    
    onEvent:({e,setErrorPopup,nextStateOnRerender,selector}) => {
      let keyEvents = ['keypress','keydown','keyup'];
      if( keyEvents.includes(e.type) ){

        let input = document.querySelector(selector);
        
        if( input.value.length === 0 ){
          if( e.key === 'Backspace' || e.key === 'Delete' ){
            let boundingRect = input.getBoundingClientRect();
            e.preventDefault();
            e.stopPropagation();
            setErrorPopup({
              position:[ 500, 500 ],
              text:`'${e.key}' would normally delete the crop box, but for now just make a protein name and hit 'Enter'.`
            })
          }
        }else if( input.value.length > 0 ){
          if( e.key === 'Enter' && input.value.trim() === '50' ){
            nextStateOnRerender();
          }
        }

      }else{
        
      }

    },
  },

  {
    title:'MOVE_MW_CONTAINER',
    message:{
      default:(
        <div>Move label by <b>click and dragging</b> the grey box on the left.
          <div>
            Drag the label into the red oval to continue.
          </div>
        </div>),

      positionAt:'start',
      offset:{
        left:'calc(100% + 10px)',
      }
    },
    selector:'[item=mw-dragging-box]',
    lineStartRequirements:{
      startMustIncludeSelector:true,
      selector:'[item=mw-dragging-box]',
      illegalSelectors:['input'],
      offset:{
        width:15
      },
      /*offset:{
        top:-3,
        left:0,
        //width:36,
        //height:27,
      }*/
    },
    lineEndRequirements:{
      selector:'#redbeard',

      relativeOffset:{
        left:0.075,
        top:0.5,
        width:50,
        height:40,
      }
    },

    beforeStateChange:(store) => {
      window.__setFocusedAnnotationId();
    }
  },


  {
    //also, we don't actually want to keep any of the things
    //from the tutorial, so we can actually remove them
    //and ensure that they aren't sync'd


    // in the "captureEvent" function, we'll just
    // need to treat this as a special case
    title:'DRAW_BAND',

    message:{
      positionAt:'start',
      default:(
        <div>
          Capture regions by <b>click and dragging</b> accross.

            <div style={{width:'100%', justifyContent:'center', position:'relative'}} class="sg-row">
            <img style={{border:'1px solid black', width:'100%'}} src={MakeCrop}/>
          </div>

        </div>
      ),
      offset:{
        top:'calc(100% + 10px)',
        //transform:'translate(0,calc(-100%)) translate(0,-10px)',
      }

    },
    lineStartRequirements:{
      relativeOffset:{
        left:0.237,
        top:0.543,
      },
      dim:15,
    },

    lineEndRequirements:{
      relativeOffset:{
        left:0.858,
        top:0.545 
      },
      dim:15,
    },
    selector:'#redbeard',//'#main-crop-preview',
    guidelineItems:[
      {
        type:'circle', x:0.25, y:0.5 
      }
    ],
  },
  {

    title:'LABEL_TARGET_0',
    message:{
      default:(
        <div>
          <div>Type <b><code style={{color:'black'}}>\beta-actin</code></b> and press <code>Enter</code>.</div>
          <div style={{fontSize:10, marginTop:7,opacity:0.5 }}>
            <div><span style={{color:'green'}}><b>Correct:</b></span> &nbsp;&nbsp;\ (backslash, to get Greek) </div>
            <div><b style={{color:'red'}}>Incorrect:</b> / (used for divide or URLs).</div>
          </div>

          {false && <p style={{marginTop:10}}>
            <b>Note</b>: you'll adjust the blue rectangle later.
          </p>}
        </div>
      ),
      offset:{
        transform:'translate(0,-100%) translate(-10px,-20px)',
      }
    },
    captureAllMouseEvents:true,
    selector:'input[input=crop-label-field]',
    onEvent:({e,setErrorPopup,nextStateOnRerender,selector, nextStateOnKeyUp}) => {

      let keyEvents = ['keypress','keydown','keyup'];
      if( keyEvents.includes(e.type) ){

        let input = document.querySelector(selector);
        
        if( input.value.length === 0 ){
          if( e.key === 'Backspace' || e.key === 'Delete' ){
            let boundingRect = input.getBoundingClientRect();
            e.preventDefault();
            e.stopPropagation();
            setErrorPopup({
              position:[ 500, 500 ],
              text:`'${e.key}' would normally delete the crop box, but for now just make a protein name and hit 'Enter'.`
            })
          }
        }else if( input.value.length > 0 ){
          if( e.key === 'Enter' && input.value.trim().length > 0 ){

            nextStateOnKeyUp();
            //nextStateOnRerender();
          }else if( e.key === 'Enter' ){
            
          }
        }

      }else{
        
      }

    }
  },

  {

    message:{
      default:<div>Now, we'll create a figure. Click <b>My Figures</b>.</div>
    },
    captureAllMouseEvents:true,
    title:'GO_TO_FIGURES',
    selector:'div[tab=figurePanels]',
    nextStateOnSelectorClick:true,
    onEnterViaNav:(dispatch) => {
      dispatch(A.setUiMode({mode:"ANNOTATION"}));
    }
  },
  {

    message:{
      default:<div>Click <b>New Panel</b> to create a panel.</div>
    },
    captureAllMouseEvents:true,
    title:'CREATE_FIGURE',
    selector:'[button=mainCreateButton]',
    nextStateOnSelectorClick:true,
    onEnterViaNav:(dispatch) => {
      dispatch(A.setUiMode({mode:"FIGURES_AND_PANELS"}))
    }

  },

  {

    message:{
      default:<div>And here to confirm.</div>,
    },
    title:'ACCEPT_FIGURE',
    selector:'[button=acceptFigurePanelSettings]',
    nextStateOnSelectorClick:true,
  },

  
  {
    message:{ default:<div><b>Click</b> on a crop to place it in the figure.</div>,},
    title:'SELECT_CROP_FOR_FIGURE',
    selector:`[item=protein-target-search-result][annotationsearchresult='0']`,
    nextStateOnSelectorClick:true,
    captureAllMouseEvents:true,
  },
  {
    title:'PLACE_CROP_IN_FIGURE',
    selector:`td[cell='2-1'][objecttype=GridCell]`,
    nextStateOnSelectorClick:true,
    captureAllMouseEvents:true,
    message:{
      default:"Place the crop here.",
    }
  },

  {
    customPointer:true,
    //also, we don't actually want to keep any of the things
    //from the tutorial, so we can actually remove them
    //and ensure that they aren't sync'd


    // in the "captureEvent" function, we'll just
    // need to treat this as a special case
    title:'ADJUST_CROP_1',
    message:{
      height:320,
      default:(
        <div>
          <div>Easily adjust the crop-region by dragging.</div>
          <br/>
          <div>Make sure to end the drag <b>inside</b> the red box.</div>
          <p>
            <b>Note:</b> this does not manipulate the image.
          </p>
          <div style={{width:'100%', justifyContent:'center', position:'relative'}} class="sg-row">
            <img style={{border:'1px solid black', width:'100%'}} src={AdjustCropClip}/>
          </div>
        </div>
      ),
      positionAt:'start',
      offset:{
        top:'calc(100% + 10px)',
      }
    },


    lineStartRequirements:{
      //removeWhenMouseDown:true,
      startMustIncludeSelector:true,
      selector:'[action=adjust-crop][celllocation="2-1"]',
      offset:{
        width:15,
      }
    },

    lineEndRequirements:{
      selector:'[action=adjust-crop][celllocation="2-1"]',
      offset:{
        left:-15,
        width:14,
      },
      type:'point',
      //left:0.8,
      //top:0.6,
      //dim:15,
    },
    selector:'[container=figure-panel]',
    guidelineItems:[
      {
        type:'circle', x:0.25, y:0.5 
      }
    ],
  },

  {
    //also, we don't actually want to keep any of the things
    //from the tutorial, so we can actually remove them
    //and ensure that they aren't sync'd

    customPointer:true,
    message:{
      default:(
          <div>
            <div>Do the same on this side.</div>
            <div>Make sure to end the drag <b>inside</b> the red box.</div>


          </div>
      ),
      positionAt:'start',
      offset:{
        top:'calc(100% + 10px)',
      }
    },

    // in the "captureEvent" function, we'll just
    // need to treat this as a special case
    title:'ADJUST_CROP_2',
    
    lineStartRequirements:{
      //removeWhenMouseDown:true,
      selector:'[action=adjust-crop][celllocation="2-1"][side=right]',
      startMustIncludeSelector:true,
      offset:{
        width:15,
      }
    },

    lineEndRequirements:{
      selector:'[action=adjust-crop][celllocation="2-1"][side=right]',
      offset:{
        left:17,
        width:14
      }
    },
    selector:'[container=figure-panel]',
    guidelineItems:[
      {
        type:'circle', x:0.25, y:0.5 
      }
    ],
    startEventInfo:{
      selector:'',
      relativePosition:[],
    },
  },
  {
    title:'DRAG_FIRST_6',
    onFail:(e) => {
        window.dispatchEvent(new Event('click', {
          clientX:e.clientX,
          clientY:e.clientY
        }));
    },
    lineStartRequirements:{
      offset:{
        left:4
      },
      startMustIncludeSelector:true,
      selector:'td[cell="0-1"]',
    },

    lineEndRequirements:{
      offset:{
        left:4
      },
      selector:'td[cell="0-6"]',
    },
    customRequirement:() => {
      let eleList = Array.from(document.querySelectorAll('.selected'));
      let required = new Set('grid-0-1','grid-0-2','grid-0-3','grid-0-4','grid-0-5','grid-0-6');
      eleList.forEach(ele => required.remove(ele.id));
      return required.size === 0;
    },
    selector:'[container=figure-panel]',
    message:{
      positionAt:'start',
      default:"Now, select the cells shown by dragging across.",
      offset:{
        top:'calc(100% + 10px)',
      }
    }


  },
  {
    title:'RIGHT_CLICK',

    message:{
      default:(
        <div>
          Right click to bring up the menu.
        </div>
      ),
    },
    selector:`td[cell='0-6'][objecttype=GridCell]`,
    nextStateOnSelectorContextMenu:true,
    //message:'Right click (secondary click) on the cell.'
  },

  {
    title:'MERGE_CELLS',

    message:{
      default:(
        <div>
          Click 'Merge cells'.
        </div>
      ),
    },
    selector:`.sg-context-menu .toolbar-action-button[action=cell-merge]`,
    nextStateOnSelectorClick:true,
    //message:'Click to merge cells.',
  },

  // now they need to type something!

  {

    message:{
      default:<div><div>Type 'HeLa' and press <code>Enter</code> to continue.</div><div>Don't worry about a flashing cursor. Type away.</div></div>,
    },

    title:'LABEL_HELA',
    instruction:`Type 'HeLa' and press Enter to continue.`,
    selector:'textarea[name=cellValueInput]',
    //selector:'input[input=crop-label-field]',
    onEvent:({e,setErrorPopup,nextStateOnRerender,selector}) => {

      const targetText = "HeLa";
      let keyEvents = ['keypress','keydown','keyup'];
      if( keyEvents.includes(e.type) ){

        let input = document.querySelector(selector);


        if( e.key === 'Escape' ){
          input.focus();
        }

        let value = input.value;


        //we want to make sure that they stay focused on the current cell
        //to make sure of that, we can only allow key events that won't change the focus
        //is we're focused on the input, they can use arrow keys.
        //otherwise, no arrow keys allowed

        let regex = /a-zA-Z0-9/
        /*let keyWillChangeCellFocus = e => {
          
          if( e.focus.tagName === 'INPUT' ){

          }
          ['Enter','Return','ArrowUp','ArrowDown',
        }*/

        let arrowKeys = ['ArrowLeft','ArrowRight','ArrowUp','ArrowDown'];
        let isBackspace = e.key === 'Backspace' || e.key === 'Delete';
        let isEnter = ['Enter','Return'].includes(e.key);
        let isAlphanumeric = regex.test(e.key);
        let isShift = e.key === 'Shift';

        let usedForTyping = [isBackspace,isShift,isAlphanumeric].some(x => x);

        if( isEnter ){
          if( value?.trim().toLowerCase().length > 0 ){
            nextStateOnRerender();
          }else{
            e.preventDefault();
            e.stopPropagation();
            setErrorPopup({
              position:[500,500],
              text:`Normally, 'Enter' would move to the next cell.` + 
              ` But we want you to type '${targetText}', then press enter.`
            },e)
          }
        }else if( usedForTyping ){

        }else if( arrowKeys.includes(e.key) ){
            let boundingRect = input.getBoundingClientRect();
            e.preventDefault();
            e.stopPropagation();
            setErrorPopup({
              position:[ 500, 500 ],
              text:`'${e.key}' would normally change cells, the crop box, but for now just type 'HeLa' and hit 'Enter'.`
            },e)
        }else if( input.value.length > 0 ){
          if( e.key === 'Enter' && input.value.trim().length > 0 ){
            nextStateOnRerender();
          }
        }

      }else if( e.type !== 'input' ){
        e.preventDefault();
        e.stopPropagation();
        setErrorPopup({
          position:[e.clientX,e.clientY],
          text:<div>You can click around after the tutorial. For now, type <b>{targetText}</b> and hit <code>Enter</code>.</div>,
        },e)
      }

    }
  },

  {
    title:'DRAG_LAST_6',
    onFail:(e) => {
        window.dispatchEvent(new Event('click', {
          clientX:e.clientX,
          clientY:e.clientY
        }));
    },
    lineStartRequirements:{
      offset:{
        left:4
      },
      startMustIncludeSelector:true,
      selector:'td[cell="0-7"]',
    },

    lineEndRequirements:{
      offset:{
        left:4
      },
      selector:'td[cell="0-12"]',
    },
    selector:'[container=figure-panel]',

    message:{
      positionAt:'start',
      offset:{
        top:'calc(100% + 10px)',

      },
      default:(
        <div>
          We'll do the same thing again for HEK293.
          <br/>
          <b>Click and drag</b> <u>all</u> 6 cells to continue.
        </div>
      ),
    },


  },

  {
    title:'RIGHT_CLICK',
    selector:`td[cell='0-12'][objecttype=GridCell]`,
    nextStateOnSelectorContextMenu:true,
    message:{ 
      default:'Right click (secondary click) on the cell.'}
  },
  {
    title:'MERGE_CELLS',

    message:{
      default:(
        <div>
          Click 'Merge cells'.
        </div>
      ),
    },
    selector:`.sg-context-menu .toolbar-action-button[action=cell-merge]`,
    nextStateOnSelectorClick:true,
  },


  {

    title:'LABEL_HEK293',

    message:{
      default:<div>Type 'HEK293' and press <code>Enter</code> to continue.</div>,
    },
    selector:'textarea[name=cellValueInput]',
    //selector:'input[input=crop-label-field]',
    onEvent:({e,setErrorPopup,nextStateOnRerender,selector}) => {

      const targetText = "HEK293";
      let keyEvents = ['keypress','keydown','keyup'];
      if( keyEvents.includes(e.type) ){

        

        let input = document.querySelector(selector);


        if( e.key === 'Escape' ){
          input.focus();
        }

        let value = input.value;


        //we want to make sure that they stay focused on the current cell
        //to make sure of that, we can only allow key events that won't change the focus
        //is we're focused on the input, they can use arrow keys.
        //otherwise, no arrow keys allowed

        let regex = /a-zA-Z0-9/
        /*let keyWillChangeCellFocus = e => {
          
          if( e.focus.tagName === 'INPUT' ){

          }
          ['Enter','Return','ArrowUp','ArrowDown',
        }*/

        let arrowKeys = ['ArrowLeft','ArrowRight','ArrowUp','ArrowDown'];
        let isBackspace = e.key === 'Backspace' || e.key === 'Delete';
        let isEnter = ['Enter','Return'].includes(e.key);
        let isAlphanumeric = regex.test(e.key);
        let isShift = e.key === 'Shift';

        let usedForTyping = [isBackspace,isShift,isAlphanumeric].some(x => x);

        if( isEnter ){
          if( value?.trim().toLowerCase().length > 0 ){
            nextStateOnRerender();
          }else{
            e.preventDefault();
            e.stopPropagation();
            setErrorPopup({
              position:[500,500],
              text:`Normally, 'Enter' would move to the next cell.` + 
              ` But we want you to type '${targetText}', then press enter.`
            },e)
          }
        }else if( usedForTyping ){

        }else if( arrowKeys.includes(e.key) ){
            let boundingRect = input.getBoundingClientRect();
            e.preventDefault();
            e.stopPropagation();
            setErrorPopup({
              position:[ 500, 500 ],
              text:<div>{e.key} would normally change cells, but for now just type <b>HeLa</b> and hit <code>Enter</code></div>,
            },e)
        }else if( input.value.length > 0 ){
          if( e.key === 'Enter' && input.value.trim().length > 0 ){
            nextStateOnRerender();
          }
        }

      }else if( e.type !== 'input' ){
        e.preventDefault();
        e.stopPropagation();
        setErrorPopup({
          position:[e.clientX,e.clientY],
          text:<div>You can click around after the tutorial. For now, type <b>{targetText}</b> and hit <code>Enter</code>.</div>
        },e)
      }

    }
  },

  {
    title:'UNDERLINE_CELL_LINES_FIRST_CLICK',

    selector:`td[cell='0-1'][objecttype=GridCell]`,
    nextStateOnSelectorClick:true,
    message:{ default:`Now, we'll put underlines under the cell lines. Click 'HeLa'.`}
  },

  {
    title:'SHIFT_CLICK_HEK293',
    selector:`td[cell='0-7'][objecttype=GridCell]`,
    requiredModifierKeys:['shiftKey'],
    nextStateOnSelectorClick:true,
    message:{
      default:`Now, Shift + Click to select the other cell line.`
    }
  },

  {
    title:"CLICK_BORDER_MENU",
    selector:"[menuItem=border-menu]",
    nextStateOnSelectorClick:true,
    message:{
      default:'Open the border menu.',
    }
  },
  {

    title:"TOGGLE_BOTTOM_BORDER",
    message:{
      default:"Click here to enable bottom border.",
      offset:{
        top:'calc(100% + 10px)',
      }
    },
    selector:"[menuItem=bottom-border-toggle]",
    nextStateOnSelectorClick:true,
    beforeStateChange:(store) => {

      //we need to do this
      //because it seems
      //if we don't dispatch the
      //action directly
      //then the menu gets closed
      //before the onClick gets
      //dispatched from within the menu

      let dispatch = store.dispatch;
      let state = store.getState();

      let figurePanelId = Object.keys(state.data.figurePanels)[0];



      /*
      dispatch(A.setCellsValue({
        figurePanelId,
        cells:[[0,1],[0,7]],
        style:{
          borderBottom:"1px solid black"
        }
      }));

      window.__handleMenuClose && window.__handleMenuClose();
      */
    }

  },
  {
    title:"ADJUST_BORDER_WIDTH",
    selector:"[menuItem=bottom-border-length]",

    message:{
      default:(
        <div>
          Set border width to <b>95</b>. Then, hit <code>Esc</code>.
          <br/>
          <div>Notice the border's width changing in your figure.</div>
          <div style={{marginTop:3, width:'100%', justifyContent:'center', }} class="sg-row">
            <img style={{border:'1px solid black'}} src={BorderWidthPreview}/>
          </div>
        </div>
      )
      
    },


    onEvent:({e,setErrorPopup,nextStateOnRerender,selector}) => {

      const targetText = "95";
      let targetElement = document.querySelector(selector);

      let targetInputSelector = selector + ' input';
      let input = document.querySelector(targetInputSelector);
      let hitTargetValue = input.value === targetText;

      let keyEvents = ['keypress','keydown','keyup'];
      if( keyEvents.includes(e.type) ){

        if( !targetElement ){
          return;
        }
        //let value = input.targetElement;


        //we want to make sure that they stay focused on the current cell
        //to make sure of that, we can only allow key events that won't change the focus
        //is we're focused on the input, they can use arrow keys.
        //otherwise, no arrow keys allowed

        let regex = /a-zA-Z0-9/
        /*let keyWillChangeCellFocus = e => {
          
          if( e.focus.tagName === 'INPUT' ){

          }
          ['Enter','Return','ArrowUp','ArrowDown',
        }*/

        let arrowKeys = ['ArrowLeft','ArrowRight','ArrowUp','ArrowDown'];
        let isBackspace = e.key === 'Backspace' || e.key === 'Delete';
        let isEscape = ['Escape'].includes(e.key);
        let isEnter = ['Enter','Return'].includes(e.key);
        let isAlphanumeric = regex.test(e.key);
        let isShift = e.key === 'Shift';

        let usedForTyping = [isBackspace,isShift,isAlphanumeric].some(x => x);

        if( isEscape && !hitTargetValue ){
            e.preventDefault();
            e.stopPropagation();
            setErrorPopup({
              position:[ 500, 500 ],
              text:`'${e.key}' will close the menu after border-width is ${targetText}.`
            },e)
        }else if( isEscape && hitTargetValue ){
            nextStateOnRerender();
        }

      }else if( e.composedPath().includes(targetElement) ){
      }else{
        e.preventDefault();
        e.stopPropagation();
        setErrorPopup({
          position:[e.clientX,e.clientY],
          text:<div>You can click around after the tutorial.<br/>Set the border-width to {targetText}<br/>Then hit <code>Escape</code>.</div>
        },e)
      }

    }
  },
  {
    title:'INSERT_ROW_AT_1',
    message:{
      default:"You can click here to add a row."
    },
    selector:'[action=add-row-1-left]',
    nextStateOnSelectorClick:true,
  },

  {
    title:'SELECT_CELL_IN_FIRST_ROW_TO_DELETE',
    selector:`[cell='1-0'][objecttype=GridCell]`,
    nextStateOnSelectorClick:true,
    message:{
      default:(<div>Now we'll delete that (and another) row.<br/> Click a cell in a row to be deleted.</div>)
    }
  },

  {
    title:'SELECT_CELL_IN_SECOND_ROW_TO_DELETE',
    selector:`[cell='2-0'][objecttype=GridCell]`,
    nextStateOnSelectorClick:true,
    requiredModifierKeys:['shiftKey'],
    message:{
      default:`Shift + Click on the cell of other row we'll delete.`
    }
  },

  {
    title:'RIGHT_CLICK_FOR_DELETE',
    selector:`td[cell='2-0'][objecttype=GridCell]`,
    nextStateOnSelectorContextMenu:true,
    message:{default:'Right click to bring up the menu.'},
  },

  {
    title:'DELETE_ROWS',
    selector:`.sg-context-menu .toolbar-action-button[action=row-delete]`,
    nextStateOnSelectorClick:true,
    message:{
      default:'Click to merge cells.',
    }
  },

  {
    title:'RESIZE_CROP',
    selector:'[container=figure-panel]',

    onFail:(e) => {
        window.dispatchEvent(new Event('click', {
          clientX:e.clientX,
          clientY:e.clientY
        }));
    },
    message:{
      height:260,
      default:(
        <div style={{position:'relative'}}>
          You can expand crop height from within the figure.
          <br/>
          <b>Click and drag</b> the <span style={{
            height:1,
            textDecoration:'line-through',
            textDecorationThickness:3,
            color:'red',
          }}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>.
          <br/>
          When dragging, it turns into <span style={{
            height:1,
            textDecoration:'line-through',
            textDecorationThickness:3,
            color:'magenta',
          }}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <br/>
          Drop this inside the
          &nbsp;


      <span style={{
            border:'2px solid red', 
            height:1,
            //textDecoration:'line-through',
            //textDecorationThickness:3,
            //color:'magenta',
          }}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span> 
          <div style={{width:'100%'}}>
            <img alt="Video demonstrating the click drag."
            style={{
              marginTop:20,
              border:'1px solid black',
              objectFit:'contain',
              width:'100%',
            }} src={VerticalCropResizeTutorial}/>
          </div>
        </div>
      ),

      positionAt:'end',
      offset:{
        transform:'translate(-100%,0) translate(-10px,0)'
        //left:'calc(-100% + )',
      }
    },



    lineStartRequirements:{
      selector:'[cell="1-1"] .blue-hoverable[side=top]',
      removeWhenMouseDown:true,
      removeArrowWhenMouseDown:true,
      ignoreOffsetForMouseDownValidation:true,
      offset:{
        left:3,
        top:-2,
        height:5,
      },
      style:{
        border:'2px solid red',
      }
      //dim:1,
    },

    lineEndRequirements:{
      selector:'[cell="1-1"] .blue-hoverable[side=bottom]',
      offset:{
        top:-60,
        left:3,
        height:15,

      },
      
    }
    
  },
  {
    title:"EXPORT_FIGURE",
    nextStateOnSelectorClick:true,
    selector:"[button=top-export-button]",
    message:{
      default:"Export the figure.",
    }
  },

  {
    title:"TUTORIAL_OUTRO",
    scene:onStateChange => <TutorialOutro onStateChange={onStateChange}/>,
    selector:'[p=h_h_h_h]'

  }

  /*


  {
    title:'DRAG_ROW_1_TO_0',
    selector:'[action=rowDragButton][side=left][rowIndex="1"]',

  },

  

  {
    title:'CLICK_1-1',
    selector:`td[cell='1-1'][objecttype=GridCell]`,
    //requiredModifierKeys:['shiftKey'],
    nextStateOnSelectorClick:true,
    message:`Click the cell and label doses.`
  },


  {
    title:'CLICK_1-1',
    selector:`td[cell='1-1'][objecttype=GridCell]`,
    //requiredModifierKeys:['shiftKey'],
    nextStateOnSelectorClick:true,
    message:`Click the cell and label doses.`
  },

*/

]

export default walkthrough;
