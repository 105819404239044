import A from './ActionCreators';
import C from './Constants';
import { get, post } from './io';
import Dialog from './DialogConstants';



export default function fetchPresignedPostUrls(args){
  return (dispatch,getState) => {



    const onSuccess = function(dispatch){
      return function(res){

        let postData = res.json.map((data,ii) => {
          return {
            mediaInfo:data.imagesToSync,
            postInfo:(data)
          }
        })

        return dispatch(A.receivedPresignedPosts({postList:postData}))
        //just store the presigned post info

      }
    }

    let onGetPresignedPostsServerFailure = (toSync,dispatch) => res => {


      toSync.forEach(spec => {
        //if( spec.version === 'raw' ){
        dispatch(A.notifyMediaPersistenceError({
          imageSpec:spec,
          errorType:"getPresignedPostsServerError"
        }))
        //}
      })

    }

    let onInternetFailure = (toSync,dispatch) => res => {


      toSync.forEach(spec => {
        dispatch(A.notifyMediaPersistenceError({
          imageSpec:spec,
          errorType:C.internetConnectionError
        }))
      })
    }




    let {imagesToSync, storageLocation, state, remoteStorageSpecification} = args || {};

    dispatch({
      type:C.fetchPresignedPostUrls,
      imagesToSync
    })

    let route = ['/'+['getPresignedPosts',storageLocation,imagesToSync.length].join('/')];

    let body = {
      imagesToSync
    }


    return post({
      route,
      body,
      state: state || getState(),
      onSuccess:onSuccess(dispatch),
      onServerFailure:onGetPresignedPostsServerFailure(imagesToSync,dispatch),
      onInternetFailure:onInternetFailure(imagesToSync,dispatch),
    },dispatch);

  }
}
