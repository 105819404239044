import { post } from './io';
import { getGlobalObject, isCLI } from './versionConfig';
import C from './Constants';
const dontTrack = [
  'SET_IMAGE_PROPERTY',
  C.showFetch,
]

function getInAConsoleAnalyticsEnabled(inConsole,store){
  if( inConsole ){

    let state = store.getState();
    let { windowSessionId } = state;


    let testMeta = getGlobalObject().__testMeta;
    if( testMeta ){

      let currentSession = testMeta.getSession(windowSessionId);
      if( !currentSession ){
        debugger;
      }
      try{
        let { analyticsEnabled } = currentSession;
        return analyticsEnabled;
      }catch(e){
        //console.error(e);
        console.error("\n\nThis is a weird error caused by a server error.\n\n");
        throw e;
      };
    }else{
      return false;
    }


  }else{
    return false;
  }
}

let isProductionBuild = false;
if( process.env.NODE_ENV === 'production' ){
  isProductionBuild = true;
}

const actionLogger = store => next => async action => {


  if( action === undefined ){
    debugger;
  }


  let actionIsFunction = typeof(action) === typeof(()=>{}); 


  if( process.env.NODE_ENV !== 'production' ){
    if( !actionIsFunction && !isCLI() ){
      let toLog = {...action};
      delete toLog.type;
      console.log(action.type+'\t'+JSON.stringify(toLog)); 
    }
  }

  let shouldIgnoreAction = actionIsFunction || dontTrack.includes(action.type);

  let inAConsole = typeof(window) === typeof(undefined);
  let inAWindow = !inAConsole
  
  let inAConsoleAndAnalyticsOn = getInAConsoleAnalyticsEnabled(inAConsole,store);



  
    let shouldNotStreamEvent = {
      shouldIgnoreAction,
      actionIsFunction,
      inConsoleWithoutAnalytics:(inAConsole && !inAConsoleAndAnalyticsOn),
      inWindowWithoutAnalytics:(inAWindow && (!isProductionBuild) && (!getGlobalObject().__analyticsEnabled))

    }



  if( shouldIgnoreAction || actionIsFunction || (inAConsole && !inAConsoleAndAnalyticsOn) || (inAWindow && (!isProductionBuild) && (!getGlobalObject().__analyticsEnabled)) 
  /*&& !getGlobalObject().__testMeta)*/ ){
    let res = await next(action);
    return res;
  }else{


    debugger;
    let state = store.getState();

    if( !action ){
      throw Error("NO ACTION, I.E, NULL!");
    }

    let global = getGlobalObject();
    let { __sciugoAnalyticsPostOrderIndex } = global;
    if( !__sciugoAnalyticsPostOrderIndex ){
      global.__sciugoAnalyticsPostOrderIndex = 0;
    }

    let postOrderIndex = Number(global.__sciugoAnalyticsPostOrderIndex);



    let body = { 
      event:action, 
      clientInfo:{
        clientTime:Number(Date.now()),
        postOrderIndex,
      },
      route:state.route,
      windowSessionId:store.getState().windowSessionId
    }

    global.__sciugoAnalyticsPostOrderIndex++;


    let headers = {
      'Content-Type':'application/json;charset=utf-8',
    }


    let postArgs = {
      route:'/sg',
      body,
      headers,
      state,
      onSuccess:(() => {}),
      onServerFailure:(() => {}),
      onInternetFailure:(() => {}),
      onFetchError:(() => {}),
      skipGlobalResponseHandler:true

    }

    if( !inAWindow ){

      return post( postArgs, store.dispatch ).then(r => {
        return next(action);
      })            

    }else{
      post( postArgs, store.dispatch ).catch(err => {
        debugger;
        console.log({
          err,
          postArgs
        })
        debugger;
      })
      return next(action);
    }


  }
}

export default actionLogger;
