import G from './Getters';
import C from './Constants';
import A from './ActionCreators';
import D from './DialogConstants';

let bannedActionSet = new Set([
  C.startImageUploads,
  C.startQuantification,
])

const actionVerbStrings = {
  [C.startImageUploads]:"upload images",
  [C.startQuantification]:"quantify",
}



const paywallMiddleware = (store) => next => action => {

  let state = store.getState();
  if( G.shouldEnforcePaywall(state) ){

    let a = action;
    let registeredName = a.__registeredName;
    //debugger;

    let { type } = action;
    if( bannedActionSet.has( registeredName ) ){
      // instead, return a NEW action
      // where we create a dialog?
      // and we say NO NO NO NO!
      let createDialogAction = A.createDialog({
        dialogName:D.SUBSCRIBE,
        source:"paywallMiddleware",
        args:{
          action:(actionVerbStrings[registeredName]),
        }
      })

      return next(createDialogAction);


    }

  }

  return next(action);

  

}

export default paywallMiddleware;
