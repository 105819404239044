import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import G from './Getters';

const deliveryReadStatus = (state,threadId,messageId) => {

  let delivered = G.isMessageReceived(state,{threadId,messageId,by:"admin"});
    let read = G.isMessageRead(state,{threadId,messageId,by:"admin"});

    if( read ){
      return "Read";
    }else if( delivered ){
      return "Delivered";
    }
}

export default function MessageFeed({threadId,typing}){

  const state = useSelector(state => state); 
  const {thread, messages} = useSelector(state => ({
      thread:state.threads[threadId],
      messages:G.getThreadMessageOrder(state,{threadId}).map(messageId => {
        return G.getMessage(state,{threadId,messageId})
      })
    }));

  const messagesContainerRef = useRef();

  useEffect(() => {
    messagesContainerRef.current && messagesContainerRef.current.scrollIntoView({behavior:'smooth'});
    let obj = messagesContainerRef.current;
    if( obj ){
      obj.scrollTop = obj.scrollHeight;
    }
  })


  if( !thread ){
    return null;
  }

   const admin = "admin";
  const userOtherThanAdmin = thread.participants.find(usr => usr!==admin);

  let reversed = messages.slice().reverse()

  let lastMessageThatWasReadOrDeliveredByCounterpart = reversed.find(msg => {

    if(!msg){
      debugger;
    }
    if( msg.from !== "admin" ){
      return deliveryReadStatus(state,threadId,msg._id);
    }
  }) || {}


  //let lastMessageFromUser = reversed.find(msg => msg.from !== admin);



  return (
    <div 
      ref={messagesContainerRef}
  style={{
      paddingTop:3,
      height:'100%',
      overflowY:'scroll',
      overflowX:'hidden',
      }}>
      {messages.map(msg => {
        return (
          <div style={{
            display:'flex',
            }}>
            {msg.from!=='admin' && <div class="flex-expand"/>}
            <div>
            <div
              class="text-selectable"
              messageType={"message"}
              objectType={"chatMessage"}
              sender={msg.from!=='admin'?"you":"admin"}
            style={{
              marginLeft:10,
              marginRight:10,
              marginTop:3,
              marginBottom:3,
              padding:7,
              borderRadius:10,
              display:'flex',
              justifyContent:(msg.from !== 'admin' ? 'end' : 'start'),
              background:msg.from !== 'admin' ? 'lightblue' : 'lightgrey',
              wordBreak:'break-all',
            }}>
              {msg.text}
            </div>
            {msg._id === lastMessageThatWasReadOrDeliveredByCounterpart._id && <div style={{
              justifyContent:'end',
              color:'#ccc',
              fontSize:12,
              //background:'blue',
              display:'flex',
            marginRight:10,
            }}>{deliveryReadStatus(state,threadId,msg._id)}</div>}
          </div>
          </div>
        )
      })}
      {typing && (
        <div objectType={"chatMessage"} sender={"admin"} messageType={"typing"} style={{display:'flex'}}>
            <div style={{
              marginLeft:10,
              marginRight:10,
              marginTop:3,
              marginBottom:3,
              padding:7,
              borderRadius:10,
              display:'flex',
              alignItems:'center',
              justifyContent:('start'),
              background:'lightgrey',
              wordBreak:'break-all',
            }}>
              <div style={{width:10, height:10, borderRadius:10, background:'#333',marginRight:5}}/>
              <div style={{width:10, height:10, borderRadius:10, background:'#333',marginRight:5}}/>
              <div style={{width:10, height:10, borderRadius:10, background:'#333'}}/>
            </div>
        </div>
      )}
      <div style={{height:1, width:'100%'}}/>
    </div>
  )

}
