import { useSelector } from 'react-redux';
import { useState } from 'react';
import PanelPreview from './PanelPreview';
import ManualAnnotationGridTable from './ManualAnnotationGridTable';
import CollapsibleProtocol, { EntityFormItem, ElectricityFormItem } from './CollapsibleProtocol';

export default function GelLayoutScene({layoutSelected,setLayoutSelected}){

  //const [ lanes, setLanes ] = useState(

  const [ selectedIndex, setSelectedIndex ] = useState();

  let { figurePanels } = useSelector(state => state.data);

  let content;
  if( !layoutSelected ){
    content = (
      <div class="full-width">
        <div class="sg-row full-width">

          <div>

            <div style={{width:'100%', border:'1px solid #ccc', borderRadius:10, height:50, 
              background:'white',
              alignItems:'center',display:'flex',justifyContent:'center'}}>

              <div>New Layout</div>

            </div>

            <div style={{width:'100%',background:'#ccc',height:1, marginTop:20}}/>

            <div style={{
              //border:'1px solid blue',
              height:60,
              alignItems:'center',

            }} class="sg-row"> 
              <h6 style={{
                //border:'1px solid green',
                marginTop:15, marginBottom:15}}>Recents</h6>
              <div class="flex-expand"/>
              <div style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center', 
                height:'100%',
                //background:'blue',
                //border:'1px solid red'
              }}>
                <input placeholder={"Search"}/>
              </div>
            </div>

            {Object.values(figurePanels).map(({_id},ii) => _id !== 'fp0' && (
              <div  onClick={() => setSelectedIndex(ii)} style={{borderRadius:10, margin:10, border:'1px solid #ccc', position:'relative',
                background:'white',
              }}>
                <div style={{pointerEvents:'none',padding:3,}}>
                  <ManualAnnotationGridTable {...{
                    editable:false,
                    tableOutlineShowing:false,
                    figurePanelId:_id
                  }}/>
                </div>
                <div 
                  class={ii===selectedIndex?"selected":""}
                  style={{position:'absolute',top:0,left:0, width:'100%', height:'100%',borderRadius:10,
                    opacity:0.5,
                  }}/>
              </div>
            ))}

            <div class="sg-row small-margin">
              <div class="flex-expand"/>
              <div style={{marginTop:20}}
                onClick={() => setLayoutSelected(true)} class="btn btn-primary">
                Select
              </div>
            </div>

          </div>
        </div>

        {false && <div>
          Lanes:
          <input type="number" min={1}/>
        </div>}

      </div>
    )
  }else{
    content = (
      <>

        <div  style={{
          borderRadius:10, 
          margin:10, 
          border:'1px solid #ccc', 
          position:'relative',
          background:'white',
        }}>

          <div style={{pointerEvents:'none',padding:3,}}>
            <ManualAnnotationGridTable {...{
              editable:false,
              tableOutlineShowing:false,
              figurePanelId:Object.values(figurePanels)[selectedIndex]._id
            }}/>
          </div>

        </div>

        <div class="fair-margin-top small-margin-left">
          <CollapsibleProtocol {...{
            heading:"Details",
            content: (
              <div>

                <div class="sg-row fair-margin-bottom">

                  <div style={{padding:2, fontWeight:'bold'}}>Gel:&nbsp;</div>
                  <div style={{ border:'1px solid #ccc',
                    padding:2,
                    borderRadius:5,
                  }} class="clickable-text hoverable">

                    Bis-Tris Gel
                  </div>




                </div>


                <CollapsibleProtocol {...{
                  heading:"Electrophoresis",
                  content:ElectricityFormItem({bufferType:"Running Buffer"})
                }}/>
              </div>
            )
          }}/>

        </div>

      </>

    )
  }

  return content;

}
