import { useState } from 'react';
export default function DropTargetBox({onDrop, children, style, className,mouseEnteredColor}){

  const [mouseEntered,setMouseEntered] = useState(false);

  const enter = () => setMouseEntered(true);
  const leave = () => setMouseEntered(false);

  
  let background = mouseEntered ? 
    (mouseEnteredColor || '#f8f8f8')
    : undefined;
  return (
    <div style={{background,...style}} class={"drop-image-box "+className} onMouseEnter={enter}
      onMouseLeave={leave}
      onDragOver={e=>{e.preventDefault();enter()}}
      onDragEnter={e=>{e.preventDefault();enter()}}
      onDragLeave={leave}
      onDrop={e => {
        e.preventDefault();
        onDrop && onDrop(e);
      }}>
      {children}
    </div>
  )
}
