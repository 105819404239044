import G from './Getters';
import A from './ActionCreators';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';

function Close({onClick}){
  const r = 20;
  return (
    <div onClick={onClick} style={{width:r,height:r,borderRadius:r,
      background:'#111',
      color:'white',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      cursor:'pointer',
      zIndex:1003
      }}>
      <div style={{fontWeight:'bold',
        //background:'green',
        lineHeight:0.7
      }}>×</div>
    </div>
    
  )
}

function NotificationBox({ notification, text }){
  let dispatch = useDispatch();

  const [entered,setEntered] = useState(false);

  return (
    <div 
      objectType={"notification"}
      notificationText={text}
      onMouseEnter={e => {
        setEntered(true);
      }}
      onMouseLeave={() => setEntered(false)}
  style={{
      width:300,
      background:'pink',
      border:'3px solid red',
      borderRadius:5,
      padding:10,
      marginTop:10,
      fontSize:16,
      position:'relative',
      overflow:'visible',
    }}>
      {text}
      {entered && (
        <div style={{position:'absolute',
          top:0,
          right:0,
          zIndex:1002,
        }}>
        <Close 
        onClick={() => {
          dispatch(A.popNotification({_id:notification._id}))
        }}
        style={{position:'absolute',top:0,right:0}}/>
        </div>
      )}
    </div>
  )

}

export default function Notifications(){
  let notifications = useSelector(state => {
    let { notifications } = state;
    return notifications.map(nn => ({
      notification:nn,
      text:G.getNotificationText(state,nn)
    }))
  })

  let dispatch = useDispatch();

  if( notifications.length === 0 ){
    return null;
  }


  return (
    <div style={{
     
      paddingTop:20,
      position:'fixed',
      bottom:0,
      left:0,
      zIndex:1000,
      background:'#efefef',
      border:'1px solid #ccc',
      maxHeight:500,
      overflowY:'scroll',
      borderRadius:10,
    }}>

    
      <div style={{
        position:'absolute',
        top:0,
        right:0,
      }}>
        <Close onClick={() => {
          dispatch(A.clearAllNotifications())
        }}/>
      </div>

      <div style={{
        margin:20,
        height:'100%',
        overflowY:'scroll',
        zIndex:1001,

        overflowX:'visible',
        }}>

      {notifications.map(({notification,text}) => {
        return <NotificationBox
          key={notification._id}
        {...{
          notification,
          text
        }}/>
      })}
      </div>

    </div>
  )
}
