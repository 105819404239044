import { useSelector } from 'react-redux';
import ManualAnnotationGridTable from './ManualAnnotationGridTable';
import { useState } from 'react';
import ScaledContent from './ScaledContent';

function CanvasElement(args){
  let { children, ...props } = args;
  let { x, y } = props;
  return (
    <div style={{
      top:y,
      left:x,
      position:'absolute',
      display:'flex',
      //border:'1px solid blue',
      cursor:'grab',
    }}>
      <div style={{pointEvents:'none',
        //background:'pink'
      }}>
        {children}
      </div>
    </div>
  )
}

const UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const LOWERCASE = 'abcdefghijklmnopqrstuvwxyz';

export default function FigureBuilder(){

  let figures = useSelector(state => Object.keys(state.data.figurePanels));

  let [ letterPosition, setLetterPosition ] = useState({ corner:0, outSquare:1 })
  let [ letterSubPos, setLetterSubPos ] = useState(4);

  const [ aspectRatio, setAspectRatio ] = useState({ width: 8.5, height: 11 })


  let outX = [-1,0]
  let outXY = [-1,-1]
  let outY = [0,-1]

  let outSquares = [
    outX,
    outXY,
    outY
  ]

  let corners = [
    { left:0, top:0, flip:[1, 1] },
    { right:0, top:0, flip:[-1, 1] },
    { right:0, bottom:0, flip:[-1, -1] },
    { left:0, bottom:0, flip:[1, -1] },
  ]

  const outSquareDim = 20;

  const alignItems = ['start','start','start','center','center','center','end','end','end'];
  const justifyContent = ['start','center','end','start','center','end','start','center','end'];

  const letterPositioning = {
    alignItems:alignItems[letterSubPos],
    justifyContent:justifyContent[letterSubPos]
  }



  let floatingCorners = ({style,text}) => (
    <>
      {corners.map((corner,iiCorner) => {
        let { flip, ...position } = corner;
        return outSquares.map((offset,iiOutSquare) => {
          let trX = offset[0] * flip[0] * outSquareDim;
          let trY = offset[1] * flip[1] * outSquareDim;

          return (
            <div 
              onClick={() => {
                setLetterPosition({corner:iiCorner,outSquare:iiOutSquare})
              }}
              style={{
                position:'absolute',
                width:outSquareDim,
                height:outSquareDim,
                ...position,
                transform:'translate('+trX+'px,'+trY+'px)',
                display:'flex',
                lineHeight:0.75,

                ...letterPositioning,

                ...style,


              }}>
              {iiOutSquare === letterPosition.outSquare && iiCorner === letterPosition.corner && text}

              <div style={{position:'absolute',top:0, left:0, width:outSquareDim,height:outSquareDim,display:'flex',flexWrap:'wrap'}}>
                {Array(9).fill(null).map((x,iiSubSquare) => <div 
                  class="hoverable"
                  onClick={() => setLetterSubPos(iiSubSquare)}
                  style={{
                    //background:'lime',
                    //border:'1px solid black',
                    width:outSquareDim/3, height:outSquareDim/3}}/>)}

              </div>

            </div>
          )
        })
      })}
    </>
  )




  let rect = (
    <div style={{margin:30,width:100, height:50, border:'1px solid red', position:'relative'}}>
      {floatingCorners({style:{border:'1px solid black'},text:"A"})}
    </div>
  )

  const [ letterCase, setLetterCase ] = useState("UPPERCASE");

  const letters = {
    UPPERCASE,
    LOWERCASE,
  }

  const sqW = 40;

  const sd = outSquareDim;
  const figMarginsByCorner = {
    0:[ { marginLeft:sd }, { marginLeft:sd, marginTop:sd }, { marginTop:sd } ],

    1:[ { marginRight:sd }, { marginRight:sd, marginTop:sd }, { marginTop:sd } ],

    2:[ { marginRight:sd }, { marginRight:sd, marginBottom:sd }, { marginBottom:sd } ],

    3:[ { marginLeft:sd }, { marginLeft:sd, marginBottom:sd }, { marginBottom:sd } ]

  }

  const positions = {
    fp0:[0.06, 0.06],
    fp1:[0.5, 0.06]
  }



  let pageWidth = 1000;

  let fullCanvas = (

    <div style={{ 
        aspectRatio:'8.5 / 11',
        width:pageWidth,
        border:'1px solid black',
        position:'relative',
      }}>
        {figures.map((_id,iiPanel) => {
          let elPos = positions[_id];
          return (

            <CanvasElement 
              x={elPos[0]*pageWidth} 
              y={elPos[1]*pageWidth}>
              <div style={{
                background:'white',
               //border:'3px solid cyan',
                position:'relative',
                ...figMarginsByCorner[letterPosition.corner][letterPosition.outSquare],
                pointerEvents:'none'
                //margin:outSquareDim
              }}>

                {floatingCorners({text:(letters[letterCase][iiPanel])})}
                <ManualAnnotationGridTable {...{
                  tableOutlineShowing:false,
                  editable:false,
                  figurePanelId:_id
                }}/>
              </div>
            </CanvasElement>
          )
        })}
      </div>
  )

  const pageMarginSettingsContainer = (
    <div class="small-margin">
      <div style={{alignItems:'center'}} class="sg-row">
        <div style={{width:50}}>Width</div> 
        <input type="number" value={aspectRatio.width} onChange={e => setAspectRatio({...aspectRatio,width:Number(e.target.value)})} min={1}/>
      </div>

      <div style={{alignItems:'center'}} class="sg-row">
        <div style={{width:50}}>Height</div>
        <input type="number" value={aspectRatio.height} onChange={e => setAspectRatio({...aspectRatio,height:Number(e.target.value)})} min={1}/>
      </div>

    </div>
  )


  


  return (
    <div style={{width:'100%',height:'100%',
      background:'#eee',
      display:'flex',
      position:'relative'}}>

      {false && JSON.stringify({letterSubPos, letterPositioning})}

      <div style={{background:'white',
        display:'flex', 
        flexDirection:'column',
        borderRight:'1px solid #ccc',
        }} class="fair-padding">
        {pageMarginSettingsContainer}

        {rect}
      </div>

      <ScaledContent style={{
        position:'absolute',
        background:'white',

        aspectRatio:`${aspectRatio.width } / ${aspectRatio.height}`,
        maxHeight:'100%',
        top:0,
        left:'25%',
        zIndex:100,
        display:'flex',
        //opacity:0.4,
        overflow:'visible',
      }}
        >
        {fullCanvas}
      </ScaledContent>
    </div>
  )




}
