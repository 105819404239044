import { isCLI } from './versionConfig';

let FormDataConstructor;
if( isCLI() ){
  FormDataConstructor = require("form-data");
}else{
  FormDataConstructor = FormData;
}

export default FormDataConstructor;
