const GreekLetterMap = {
  '\\\\alpha': 'α',
  '\\\\beta': 'β',
  '\\\\gamma': 'γ',
  '\\\\delta': 'δ',
  '\\\\epsilon': 'ε',
  '\\\\zeta': 'ζ',
  '\\\\eta': 'η',
  '\\\\theta': 'θ',
  '\\\\iota': 'ι',
  '\\\\kappa': 'κ',
  '\\\\lambda': 'λ',
  '\\\\mu': 'μ',
  '\\\\nu': 'ν',
  '\\\\xi': 'ξ',
  '\\\\omicron': 'ο',
  '\\\\pi': 'π',
  '\\\\rho': 'ρ',
  '\\\\sigma': 'σ',
  '\\\\tau': 'τ',
  '\\\\upsilon': 'υ',
  '\\\\phi': 'φ',
  '\\\\chi': 'χ',
  '\\\\psi': 'ψ',
  '\\\\omega': 'ω',
  '\\\\Alpha': 'Α',
  '\\\\Beta': 'Β',
  '\\\\Gamma': 'Γ',
  '\\\\Delta': 'Δ',
  '\\\\Epsilon': 'Ε',
  '\\\\Zeta': 'Ζ',
  '\\\\Eta': 'Η',
  '\\\\Theta': 'Θ',
  '\\\\Iota': 'Ι',
  '\\\\Kappa': 'Κ',
  '\\\\Lambda': 'Λ',
  '\\\\Mu': 'Μ',
  '\\\\Nu': 'Ν',
  '\\\\Xi': 'Ξ',
  '\\\\Omicron': 'Ο',
  '\\\\Pi': 'Π',
  '\\\\Rho': 'Ρ',
  '\\\\Sigma': 'Σ',
  '\\\\Tau': 'Τ',
  '\\\\Upsilon': 'Υ',
  '\\\\Phi': 'Φ',
  '\\\\Chi': 'Χ',
  '\\\\Psi': 'Ψ',
  '\\\\Omega': 'Ω',
}

const GREEK_REGEX = new RegExp(Object.keys(GreekLetterMap).join('|'), 'g');


export default function replaceWithGreek(text) {
  return text.replace(GREEK_REGEX, function (match) {
    let replacement = GreekLetterMap['\\'+match];
    return replacement;
  });
}

