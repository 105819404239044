import ManualAnnotationGridTable from './ManualAnnotationGridTable';
import { useState, useEffect, useRef } from 'react';
import G from './Getters';
import C from './Constants';
import A from './ActionCreators';
import EditIcon from '@material-ui/icons/Edit';
import TransformedPreviewPanel from './TransformedPreviewPanel';

import EditableContent from './EditableContent';
import { FIGURE_PANELS } from './RecordTypes';

import { useDispatch, useSelector } from 'react-redux';

export default function PanelPreview({
  parentRef, 
  figurePanelId, 
  setMode, 
  showTitle,
  title,
  editable,
  width, 
  setSelectedFigurePanelId}){

  let dispatch = useDispatch();

  let filesystemName = useSelector(state => G.getFilesystemName(state,{_id:figurePanelId}));

  let squareDim = width || 200;
  let [ renderedDim, setRenderedDim ] = useState();

  let maxDim = 0;
  let scale = 1;
  if( renderedDim ){
    maxDim = Math.max(renderedDim.width, renderedDim.height);
    scale = squareDim / maxDim;
  }

  let translateX = renderedDim?(
    (squareDim - maxDim)/2
  ):0

  const tableRef = useRef();



  useEffect(() => {
    if(!renderedDim && tableRef.current){
      //debugger;
      setRenderedDim(tableRef.current.getBoundingClientRect());
    }
  })

  if( renderedDim ){
    /*console.log({
    width,
    renderedDim:JSON.parse(JSON.stringify(renderedDim)),
    maxDim,
    scale
    });*/
  }

  return (

    <div class="hoverable round-corners small-padding" style={{position:'relative',
      border:'1px solid #ccc',
      background:'#fdfdfd',
      opacity: (renderedDim ? 1 : 0),
      
    }}>

      {showTitle!==false && editable!==false && <div style={{
        marginBottom:5,
        display:'flex',
        alignItems:'center',
      }}>
        <EditableContent 
          info={"filesystemName"}
          maxLength={25}
          trimContent={true}
          content={filesystemName}
          ignoreEmptyContent={true}
          onChange={value => {
            if( value.trim().length === 0 ){
              return;
            }
            dispatch(A.setFilesystemName({_id:figurePanelId,itemType:FIGURE_PANELS,filesystemName:value}));
          }}
        />
      </div>}

      {editable !== false && <div onClick={() => { 
          setMode("FIGURE");
          setSelectedFigurePanelId(figurePanelId);
        }} 
        class="dark-hoverable"
        action={"edit-figure-panel"}
        filesystemName={filesystemName}
        style={{
          position:'absolute',
          borderRadius:15,
          top:0,
          right:0,
          height:30,
          width:30, 
          zIndex:5,
          cursor:'pointer',
          

          //background:'#ccc'
        }}>
        <EditIcon style={{
          fontSize:20,
          transform:'translate(25%,20%)'
        }}/>
      </div>}
      <div ref={tableRef}
        class="hoverable"
        style={{
          width:'100%',
          height:'100%',
          pointerEvents:'none',
        }}>
        {renderedDim ? (
          <div style={{
            position:'relative',
            width:squareDim,
            height:width?'unset':squareDim,
            //border:'1px solid magenta', 
          }}>

            <div style={{
              width:renderedDim.width,
              height:renderedDim.height,
              //border:'3px solid red',
              display:'flex',flexGrow:1,

              transform:'scale('+1+') translate(-'+0+'px,0)',
            }}>
              <div
                style={{
                  //border:'1px solid lime'
                }}>

                <TransformedPreviewPanel {...{
                  scale,
                  translateX,
                  renderedDim,
                  figurePanelId
                }}/>

              </div>
            </div>


          </div> 
        ) : (
          <div style={{opacity:0,background:'magenta', border:'5px solid blue'}}>
            <ManualAnnotationGridTable editable={false} tableOutlineShowing={true} figurePanelId={figurePanelId}/>
          </div>
        )}
      </div>
      {false && <div style={{position:'absolute',bottom:-20}}>
        <pre>
          {JSON.stringify(renderedDim,null,1)}
        </pre>
      </div>}
    </div>

  )
}
