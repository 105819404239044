import { useState } from 'react';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';

export default function CancelSubscription(){

  let dispatch = useDispatch();

  const [ confirming, setConfirming ] = useState(false);

  if( !confirming ){
    return (
      <div button={"cancel-subscription"} onClick={() => {
        setConfirming(true);
      }} style={{color:'red'}} class="fair-margin clickable-text">Cancel subscription renewal</div>
    )
  }else{
    return (
      <div class="fair-margin">
        <div info={"cancel-subscription-confirmation"}>
          Are you sure you want to cancel renewal?
          <br/>
          You will have limited access to Sciugo after your current period ends.
        </div>


        <button onClick={() => {
          dispatch(A.cancelSubscription({productId:"sciugoMain"}))
        }}
          button={"confirm-cancel-subscription"}
        style={{marginTop:20,
          //color:'red'
        }} class="fair-margin button btn-danger">Confirm cancel subscription renewal</button>


      </div>
    )
  }
}
