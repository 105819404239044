import G from './Getters';

export default function getEvaluatedSyncObject(state,syncArgs){
  let { syncStatus } = state;

  let syncStatusEntries = Object.entries(syncStatus.records)
  ////console.log(JSON.stringify({syncStatusEntries},null,1));
  let syncRecordsObjectWithData = syncStatusEntries.map( ([objectType,objectList]) => {
    let objectTypeSyncObjects = Object.fromEntries(Object.values(objectList).map(syncInfo => {
      let { _id, syncKey } = syncInfo;

      let meta;
      let data;
      if( syncInfo.meta ){
        meta = state.meta[objectType][_id];
      }
      if( syncInfo.data ){
        data = state.data[objectType][_id];
      }

      return [_id, {
        _id, syncKey, meta, data
      }]
    }))

    return [objectType,objectTypeSyncObjects]
  })

  ////console.log(JSON.stringify({syncObjectWithData}));


  let syncObjectData = { };

  let records = Object.fromEntries(syncRecordsObjectWithData);
  let userConfig = G.getSyncableUserConfig(state);

  if( userConfig && Object.keys(userConfig).length > 0 ){
    syncObjectData.userConfig = userConfig;
  }

  if( Object.keys(records).length > 0 ){
    syncObjectData.records = records;
  }



  let finalSyncObject = { 
    ...syncArgs,
    data:syncObjectData
  }

  return finalSyncObject;
}
