import MwLeft from './svg/MwLeft.svg';
import MwRight from './svg/MwRight.svg';

export default function MolecularWeightSideOption({
  side,
  selectedMwSide,
  setSelectedMwSide
}){

  const selected = (selectedMwSide === side);

  const src = side === "right" ? MwRight : MwLeft;

  const extraMarginInfo = side === "left" ? { marginTop:5, marginLeft:10 } : {};

  return (
    <div style={extraMarginInfo}>
    <img
      alt={""}
      onClick={()=>setSelectedMwSide(side)}
      src={src}
      class="small-padding"
      style={{
        cursor:'pointer',
        borderRadius:5,
        background:(selected?'#ccc':'unset')
      }}/>
    </div>
  )



}
