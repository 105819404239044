export default function deleteItemFromObject(obj,path){
  let key = path[0];
  let keyInChild = key in obj;


  if( !keyInChild ){ return; }
  if( path.length === 1 ){
    delete obj[key];
  }else if( path.length > 1 ){
    deleteItemFromObject(obj[key],path.slice(1))
    if( Object.keys(obj[key]).length === 0 ){
      delete obj[key];
    }
  }

}
