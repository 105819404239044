import { useState } from 'react';
import TemplatePositionLock from './TemplatePositionLock';

const defaultItemOrder = [
  "text",
  "scalebar",
  "subregion-window"
]

export default function NodeExpansionItemAdder({
  data,
  onEvent,
  selectedFigureItems,
  figurePanelId,
  args,
}){

  let [ isAdding, setIsAdding ] = useState()

  let { extras=[] } = args || {};
  let canAddRegion = extras.includes("regionOutline");


  let addableItems = [
    ...defaultItemOrder,
    ...extras
  ]





  return (
    <div>

      {addableItems.map(itemType => {
        return (
          <div style={{fontSize:10}}>
            <button onClick={() => {
              setIsAdding(itemType);
            }} button={`add-${itemType}`}>
              {itemType[0].toUpperCase() + itemType.slice(1)}
            </button>

          </div>
        )
      })}
      
      {isAdding && (
        <TemplatePositionLock {...{
          onEvent:({event,position}) => {

            let nodeArgs = { position };
            if( isAdding === "text" ){
              nodeArgs.type = "text";
            }else if( isAdding === "subregion" ){
              nodeArgs.value = {
                regionId:"region-0"
              }
            }

            onEvent({
              type:"add",
              args:{
                type:isAdding,
                position:{base:position}
              }
            })

            setIsAdding(false);
          },


        }}/>
      )}
    </div>
  )




}
