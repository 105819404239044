import DefaultMetadata from './DefaultMetadata';
import { DIRECTORIES, IMAGE_UPLOADS } from './RecordTypes';
import { FILESYSTEM_PARENT_DIRECTORY, FILESYSTEM_NAME } from './Filesystem';
import G from './Getters';

function addObjectSpecificMeta(draft,type,objectMeta){
  if( type === IMAGE_UPLOADS ){
    objectMeta[FILESYSTEM_NAME] = G.getImageUploadRawFilename(draft,{_id:objectMeta._id});
  }

}

export default function addMetadataForRecordIfMissing(draft,{
  type,objectId,directoryIdOfNewRoot,directoryDestination
}){

    let metaTypeContainer = draft.meta[type];

    if( !metaTypeContainer[objectId] ){
      let metadataArgs = {
        type,
        _id:objectId,
      }
      if( directoryDestination ){
        metadataArgs[FILESYSTEM_PARENT_DIRECTORY] = directoryDestination
      }else{
        metadataArgs.inTopLevelParent = type;
      }


      let objMetaResult = DefaultMetadata(draft, metadataArgs);
      let meta = objMetaResult.meta;
      let error = objMetaResult.error;
      draft.meta[type][objectId] = meta;

      let objectMeta = draft.meta[type][objectId];
      addObjectSpecificMeta(draft,type,objectMeta);

      let resultingParentDirectoryId = meta[FILESYSTEM_PARENT_DIRECTORY]; 
      if( resultingParentDirectoryId ){
        let parentDirectory = G.getData(draft,{
          type:DIRECTORIES,
          _id:resultingParentDirectoryId
        });
        parentDirectory.children.push({
          type,
          _id:objectId
        })

      }

    }

  }
