import { useState } from 'react';

export default function useRenderedBoundingBox(){

  const [ curBoundingRect, setBoundingBox ] = useState({});

  function renderResponse(el){

    if( !el ){
      return;
    }

    let newBoundingRect = el.getBoundingClientRect();

    let { width, height, top, left } = newBoundingRect;

    

    let allEqual = [
      width === curBoundingRect.width,
      height === curBoundingRect.height,
      top === curBoundingRect.top,
      left === curBoundingRect.left
    ].every(x => x);

    if( !allEqual ){
      setBoundingBox({
        width,
        height,
        top,
        left,
      })
    }
  }

  return [curBoundingRect, renderResponse];


}
