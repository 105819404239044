import { useState } from 'react';

import CollapsedIcon from '@material-ui/icons/ArrowRight';
import ExpandedIcon from '@material-ui/icons/ArrowDropDown';

const titleMap = {
  addItems:"Insert..."
}


export default function CollapsibleSection({sectionTitle,defaultOpen,children, titleStyle}){

  const [open,setOpen] = useState(defaultOpen);


  let displayTitle = titleMap[sectionTitle] || sectionTitle;

  return (
    <>

      <div 
        sectionTitle={sectionTitle}
        item={"toggle-section-accessibility"}
        accessibility={open?"open":"closed"}
      style={{
        marginTop:10,
        fontSize:24,
        borderBottom:'2px solid #ccc',
        //borderTop:'2px solid black',
        paddingLeft:10,
        paddingRight:10,
        ...(titleStyle||{}),

      }} onClick={() => setOpen(!open)} class="tiny-padding sg-row">
        <div style={{
          marginTop:-5,
          marginLeft:10,
        }}>{open ? <ExpandedIcon/> : <CollapsedIcon/>}</div>
        <div style={{
          //fontSize:18
          marginTop:-2,
        }} class="clickable-text">{displayTitle}</div>
      </div>

      {open &&
      <div 
        style={{
          position:'relative',
          borderBottom:'1px solid #ccc',
          paddingLeft:10,
          paddingRight:10,
        }}>

        {children}
        
      </div>}

    </>


    
  )


}
