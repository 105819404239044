import G from './Getters';
export function getImageSetAnnotationsAsFilesystemItems(state,_id){

  let atnIds = G.getAnnotationIdsByImageSetId(state,_id);

  let atns = atnIds.map(_id => G.getRecord(state,{itemType:"annotations",_id})).filter(x => x.data.annotationType === 'ls');


  let items = atns.map(item => ({
    _id:item._id,
    creationDate:item.meta.creationDate,
    lastEditedDate:item.meta.lastEditedDate,
    filesystemName:item.data.label || <i>{"Unlabled target"}</i>,
    fsName:item.data.label,
    type:"annotations",
  }))

  return items;





}
