import Tooltip from '@material-ui/core/Tooltip';

function TooltipWrapper({tooltip, children, label}){

  console.log("LABEL: " + label);

  if( tooltip ){
    return (
      <Tooltip title={tooltip}>
        <div>
          {children}
        </div>
      </Tooltip>
    )
  }else{
    return children;
  }

}


export default function Navbar({
  name,
  options,selected,onSelect,fontSize,spread,
  hoverClass,
  selectedClass,
  style

}){

  return (
    <div style={{ }} 
      class="sg-row">
      {options.map( (opt,ii) => {

        let finalHoverClass = opt.hoverClass || hoverClass || "hoverable";
        let finalSelectedClass = (opt._id===selected?(opt.selectedClass || selectedClass || "selected"):"");

        return (
          <TooltipWrapper tooltip={opt.tooltip} label={opt.label}>
          <div 

            item={opt._id}
            navbar={name}
            style={{
              whiteSpace:'nowrap',
              fontSize:(fontSize||13),
              ...(style || {}),
              ...(opt.style || {}),
            }}
            onClick={() => {
              if(!onSelect){
                alert("no select function!");
              }

              onSelect && onSelect(opt._id)
            }}
            class={
              finalHoverClass+" selectable round-corners small-padding "+finalSelectedClass
            }>{opt.label}</div>

          {spread !== false && ii!==options.length-1 && 
              <div class="flex-expand"/>
          }
          </TooltipWrapper>
        )
      })}
    </div>
  ) 
}
