import { produce } from 'immer';

import G from './Getters';
import addMetadataForRecordIfMissing from './addMetadataForRecordIfMissing';
import getObjectsToSyncWithServer from './getObjectsToSyncWithServer';
import updateRecordRequirementEdges from './updateRecordRequirementEdges';

import { FILESYSTEM_PARENT_DIRECTORY } from './Filesystem';

import getTypeChanges from './getTypeChanges';

import { 
  SYNC_DELETE,
  SYNC_KEY,
  SYNC_UPDATE,
  IDLE,
  PENDING
} from './SyncConstants';

import { IMAGE_UPLOADS, IMAGE_SETS } from './RecordTypes';




export default function ReduceStateBasedOnChanges(state,newState){

  let noSyncableChangesMade = ['data','meta'].every(infoKey => state[infoKey] === newState[infoKey] )

  if( noSyncableChangesMade ){
    return newState;
  }
  let newObjectsToSync = getObjectsToSyncWithServer(state,newState);


  

  let changeReducedState = produce(newState, draft => {

    let keyOrderWhichEnsuresContainerObjectsAreGivenMetadataAfterTheirChildren = getKeyOrderWhichEnsuresContainerObjectsAreGivenMetadataAfterTheirChildren(Object.keys(newObjectsToSync));


    keyOrderWhichEnsuresContainerObjectsAreGivenMetadataAfterTheirChildren.forEach( type => {
      let syncStatusOfType = draft.syncStatus.records[type];

      if( !syncStatusOfType ){
        draft.syncStatus.records[type] = {};
      }

      let valuesToInsert = Object.values(newObjectsToSync[type])

      for(let obj of valuesToInsert){

        /*
        let metaOfObject = draft.meta[type][obj._id];
        if( metaOfObject ){
          metaOfObject.lastEditedDate=Number(Date.now());
        }*/

        let { syncKey } = obj;


        let idsOfConnectedNodesThatWereUpdated = 
            updateRecordRequirementEdges({
              draft,
              oldState:state,
              newState,
              type,
              recordId:obj._id
            });

        if( syncKey !== SYNC_DELETE ){
          
          addMetadataForRecordIfMissing(draft,{
            type,
            objectId:obj._id
          });
        }

        /*draft.syncStatus.records[type][obj._id] = {
          ...(draft.syncStatus.records[type][obj._id]||{}),
          ...obj,
          meta:true
        }

        for(let idToUpdate of idsOfConnectedNodesThatWereUpdated){
          let recordType = G.getItemType(draft,idToUpdate);

 
        }*/

      }

    })
  })


  return changeReducedState;


}

function getKeyOrderWhichEnsuresContainerObjectsAreGivenMetadataAfterTheirChildren(keys){

  //for example, imageUploads processed
  //before imageSets
  let containsImageUploads = keys.indexOf(IMAGE_UPLOADS)!==-1;
  let containsImageSets = keys.indexOf(IMAGE_SETS)!==-1;

  if( containsImageUploads && containsImageSets ){
    return [...keys.filter(x => ![IMAGE_UPLOADS,IMAGE_SETS].includes(x)),IMAGE_UPLOADS,IMAGE_SETS]
  }

  return keys;

}
