import getEntityRelationshipMap from './getEntityRelationshipMap';


export const EXPERIMENTS = "experiments";
export const ASSAYS = "assays";

export const PROCESS_EXECUTIONS = "processExecutions";
export const PROCESSES = "processes";

export const ANNOTATIONS = 'annotations';
export const CROPS = 'crops';
export const FIGURES = 'figures';
export const FIGURE_PANELS = 'figurePanels';
export const IMAGE_SETS = 'imageSets';
export const IMAGE_UPLOADS = 'imageUploads';
export const WESTERN_BLOTS = 'westernBlots';
export const ANTIBODY_VALIDATIONS = 'antibodyValidations';
export const SAMPLE_LAYOUTS = 'sampleLayouts';
export const SAMPLE_LAYOUT_SINGULAR = 'sampleLayout';
export const WESTERN_BLOT_SINGULAR = 'westernBlot';
export const TEMPLATES = "templates";
export const DIRECTORIES = 'directories';

const PARENT = 'parent';
const CHILDREN = 'children';


//Relationship Structures:
const CONTAINS_ONE_OF_OTHER = 'CONTAINS_ONE_OF_OTHER';
const CONTAINS_MANY_OF_OTHER = 'CONTAINS_MANY_OF_OTHER';






const recordTypes = [
  ASSAYS,
  EXPERIMENTS,
  ANNOTATIONS,
  CROPS,
  FIGURES,
  FIGURE_PANELS,
  IMAGE_SETS,
  IMAGE_UPLOADS,
  WESTERN_BLOTS,
  ANTIBODY_VALIDATIONS,
  SAMPLE_LAYOUTS,
  DIRECTORIES,
  TEMPLATES,
]
recordTypes.sort();



const recordTypeMap = () => Object.fromEntries(recordTypes.map(key => [key,key]))

export function getResolvedItemTypeName(itemType){
 
  let typeMap = recordTypeMap();

  if( itemType in typeMap ){
    return itemType;
  }else if( (itemType+'s') in typeMap ){
    return itemType+'s';
  }else{
    throw Error("Cannot resolve item type '"+itemType+"' in the record types: \n" + JSON.stringify(recordTypes));
  }

}

export default recordTypes;

const EntityRelationshipList = [
  {
    structure:[[ANNOTATIONS,CONTAINS_ONE_OF_OTHER],[SAMPLE_LAYOUTS,CONTAINS_MANY_OF_OTHER]],
    defaultProperties:{
      [ANNOTATIONS]:SAMPLE_LAYOUT_SINGULAR,
      [SAMPLE_LAYOUTS]:ANNOTATIONS
    }
  },
  {
    structure:[[ANNOTATIONS,CONTAINS_ONE_OF_OTHER],[WESTERN_BLOTS,CONTAINS_MANY_OF_OTHER]],
    defaultProperties:{
      [ANNOTATIONS]:WESTERN_BLOT_SINGULAR,
      [WESTERN_BLOTS]:ANNOTATIONS
    }
  },
  {
    structure:[[WESTERN_BLOTS,CONTAINS_ONE_OF_OTHER],[SAMPLE_LAYOUTS,CONTAINS_MANY_OF_OTHER]],
    defaultProperties:{
      [WESTERN_BLOTS]:SAMPLE_LAYOUT_SINGULAR,
      [SAMPLE_LAYOUTS]:WESTERN_BLOTS,
    }
  }
]

//map gives you, for each type, an object with 
//all of the entity types it connects to
//and the information associated with that connection

const EntityRelationshipMap = getEntityRelationshipMap(EntityRelationshipList);

function getEntityRelationshipSchema(type1,type2){

  let allPossibleTypesBasedOnTypesGiven = [
    [type1.slice(0,-1),type1,type1+'s'],
    [type2.slice(0,-1),type2,type2+'s']
  ];

  let fixedTypes = allPossibleTypesBasedOnTypesGiven.map(possibleTypes => {
    return possibleTypes.find(type => (type in EntityRelationshipMap))
  })

  let indexOfTypeNotFound = fixedTypes.findIndex(x => !x);

  if( indexOfTypeNotFound !== -1){
    throw Error("Tried to create a relationship between ("+type1+","+type2+"), but " + [type1,type2][indexOfTypeNotFound] + " was not found in the EntityRelationshipMap. Possible entities are: " + Object.keys(EntityRelationshipMap));
  }

  return EntityRelationshipMap[fixedTypes[0]][fixedTypes[1]];

}

export { getEntityRelationshipSchema, CONTAINS_ONE_OF_OTHER, CONTAINS_MANY_OF_OTHER };



