import A from './ActionCreators';
import G from './Getters';
import { FIGURE_PANELS } from './RecordTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import Navbar from './Navbar';
import QuickMicroscopyTemplateForm from './QuickMicroscopyTemplateForm';
import SuggestedMicroscopyTemplates from './SuggestedMicroscopyTemplates';

import Id from './IdFactory';


const options = [
  { _id:"suggested", label:"Suggested Templates" },
  { _id:"builder", label:"Quick Template Builder" }
]

export default function MicroscopyPanelSpecification({
  setSelectedFigurePanelContext, setTargetDirectoryOfNewFigurePanel, targetDirectoryOfNewFigurePanel
}){

  let dispatch = useDispatch();

  const [ templatePicker, setTemplatePicker ] = useState("suggested");
  const [selectedTemplate, setSelectedTemplate ] = useState();

  let suggested = templatePicker === "suggested";


  let createFigureButton = (


    <div>
      <button disabled={!selectedTemplate} onClick={() => {
        

        let _id = Id();


        dispatch(A.createItem({
          type:FIGURE_PANELS,
          figurePanelType:'microscopy',
          _id,
          args:{
            compactTemplate:selectedTemplate.data,
          },
          onSuccess:[
            A.setSelectedFigurePanelContext({
              context:{
                _id,
                figurePanelId:_id
              }
            })
          ]
        }))
      }} class="btn btn-primary">
        Create Figure
      </button>
    </div>

  )






  return (

    <div class="sg-row full-width full-height">
      <div class="sg-col flex-expand" style={{
        //border:'1px solid magenta',
        minHeight:0,

      }}>
        <div class="fair-margins sg-row">
          <div><Navbar options={options} onSelect={setTemplatePicker} selected={templatePicker} spread={false}/>
          </div>
          <div class="flex-expand"/>
          {createFigureButton}


        </div>


        {suggested && (
          <SuggestedMicroscopyTemplates {...{
            selectedTemplate, setSelectedTemplate
          }}/>
        )}

        {!suggested && (
          <QuickMicroscopyTemplateForm/>
        )}




      </div>

    </div>




  )









}
