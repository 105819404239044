import FinalImage from './images/TutorialFinalProduct.png';

export default function TutorialIntro({onStateChange}){

return (
    <div style={{ 

      //border:'1px solid green',
      alignItems:'center',
      background:'#ddd',
      paddingTop:'10%',


    }} 
      class="sg-col full-width full-height">

      <h3>
        Here, you'll create this figure:
      </h3>

      <br/>

      <div>
        <img style={{border:'1px solid black',
          borderRadius:5,
        }}
          src={FinalImage}/>
      </div>


      <button tutorialButton={"start"} style={{marginTop:20}} onClick={onStateChange} class="btn btn-primary">Continue to Tutorial</button>
    </div>
  )
}
