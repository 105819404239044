import QuantificationScene from './QuantificationScene';
import { useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';
import { ErrorBoundary } from 'react-error-boundary';

const allowedUsers = [
  'jonahlibrach@gmail.com',
  'caita8@vt.edu',
  'cem452@drexel.edu',
  'kat388@drexel.edu',
  'ns3345@drexel.edu',
]



export default function QuantificationSceneWrapper(){

  //loggedOut
  let { username } = useSelector(state => state.loginInfo);
  let { quantificationAccessRequested } = useSelector(state=>state);

  let dispatch = useDispatch();

  let scene;

  let allowedHrefs = ['localhost:300','10.0.0.222','ngrok'];

  let userAllowed = true; 
  //userAllowed = allowedUsers.includes(username) || allowedHrefs.some(href => document.location.href.includes(href));

  if( userAllowed ){
    scene = <QuantificationScene/>
  }else{

    scene = (
      <div class="full-width full-height" style={{
        background:'#f0f0f0',
        textAlign:'center',
        paddingTop:200,
      }}>
        {!username && (<h2>
          Login to access this feature.
        </h2>)}
        {username && (
          <>
            <h2>
              You currently do not have access to this feature.
            </h2>
            {!quantificationAccessRequested && 
            <button
              onClick={() => { dispatch(A.requestQuantificationAccess()) }}

              style={{fontSize:18, fontWeight:'bold', margin:20}} class="btn btn-primary">
              Request Access
            </button>}
            {quantificationAccessRequested && (

              <>
                <button
                  disabled={true}
                  style={{fontSize:18, fontWeight:'bold', margin:20}} class="btn btn-primary">
                  Request Received
                </button>
                <div style={{fontSize:18}}>We will be in touch.</div>
              </>
            )}
          </>
        )}

      </div>
    )
  }

  return (
    <ErrorBoundary
      fallbackRender={({error,resetErrorBoundary,componentStack})=>{

        if( window.__errorHandler ){
          window.__errorHandler(error);
        }

        return (<div class="full-width full-height" style={{
          background:'#f0f0f0',
          textAlign:'center',
          paddingTop:200,
        }}>
          <h2>An unexpected error occurred. You can try again by re-navigating to this tab.</h2>
        </div>
        )


      }}>
      {scene}

    </ErrorBoundary>
  );



}
