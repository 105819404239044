import helvetica from './helveticaGlyphWidths.json';

export default function measuredTextWidth(text,fontSize){

  let widths = Array.from(text).map(letter => Number(helvetica[letter]));
  let sum = widths.reduce((a,b) => a+b);
  /*let sum = Array.from(text).reduce((width,letter) => {
    return width + Number(helvetica[letter])
  },0)*/
  let scaledSum = sum * fontSize;

  return scaledSum;

}
