import { useDispatch } from 'react-redux';
import A from './ActionCreators';




export default function ColumnAdder({rows, columnIndex,figurePanelId}){

  let dispatch = useDispatch();


  return (
    <div 
      style={{textAlign:'center',
        width:50,
      }}
      onClick={() => {
        dispatch(A.insertColumn({
          columnIndex,figurePanelId}))
      }}
      class={(rows === 0 ? "disabled-button" : "hoverable clickable")}>Add<br/> Column</div>
  )


}
