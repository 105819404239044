import LaneAdjuster from './LaneAdjuster';
import useLocalReducer from './useLocalReducer';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import G from './Getters';

import QuantificationCropInfoHeader from './QuantificationCropInfoHeader';

import usePresentSelector from './usePresentSelector';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import QuantificationCropButtons from './QuantificationCropButtons';


export default function QuantificationCropInfoContainer({atn, maxLaneCount, tempProperties, activeUpdatePreviewProperty, imageVersions, setImageVersions }){

  let dispatch = useDispatch();

  let {quantificationGridStart,laneCount} = usePresentSelector(state => {
    return {
      laneCount:G.getAnnotationLaneCount(state,{_id:atn._id}),
      quantificationGridStart:G.getData(state,{itemType:"annotations",_id:atn._id}).quantificationGridStart || 0
    }
  })

  let [ entered, setEntered ] = useState(false);
  //let [ localState, localDispatch ] = useLocalReducer({

  let laneWidth = (100/maxLaneCount)+'%';


  let spacesAfterCrop = (maxLaneCount - (quantificationGridStart + laneCount));
  let rightPaddingCount = Math.max(0,spacesAfterCrop - 1);

  let spacesBeforeCrop = quantificationGridStart;
  let leftPaddingCount = Math.max(0,spacesBeforeCrop - 1);

  let leftShiftDiv = spacesBeforeCrop > 0 && (
    <div onClick={() => {
      dispatch(A.setAnnotationProperties({_id:atn._id,quantificationGridStart:quantificationGridStart-1}))
    }} style={{width:(1/maxLaneCount*100)+'%',

      cursor:'pointer',

      display:'flex',
      alignItems:'center',justifyContent:'center',
    }}>
      <div><ArrowLeftIcon style={{fontSize:38}}/><span class="grey">{"1"}</span></div>
    </div>
  )

  let rightShiftDiv = spacesAfterCrop > 0 && (
    <div 
      onClick={() => {
        dispatch(A.setAnnotationProperties({_id:atn._id,quantificationGridStart:quantificationGridStart+1}))
      }}
      style={{
        cursor:'pointer',
        display:'flex',alignItems:'center',justifyContent:'center',width:(1/maxLaneCount*100)+'%'}}>
      <div><span class="grey">{maxLaneCount}</span><ArrowRightIcon style={{fontSize:38}}/></div>
    </div>
  )

  
  let leftPaddingLabels = Array(leftPaddingCount).fill(null).map((_,ii) => {
    return (ii+2);
  })
  let rightPaddingLabels = Array(rightPaddingCount).fill(null).map((_,ii) => {
    return (maxLaneCount - (rightPaddingCount-ii));
  })



  let rightPaddingDiv = <ExtraLanes {...{width:laneWidth, labels:rightPaddingLabels}}/>

    let leftPaddingDiv = <ExtraLanes {...{width:laneWidth, labels:leftPaddingLabels}}/>


    let versions = imageVersions.map(imageVersion => (

      <div style={{
        position:'relative',
        marginBottom:20,
      }} class="sg-row">

        {leftShiftDiv}
        {leftPaddingDiv}
        <div class="sg-row" style={{position:'absolute',
          height:'100%',
          //background:'pink',
          left:-40,
          alignItems:'center',
          justifyContent:'center',

        }}>
          <QuantificationCropButtons {...{_id:atn._id, imageVersion,
            onRemove:(
              imageVersions.length > 1 ? (() => setImageVersions([...imageVersions.filter(x => x!==imageVersion)])) : undefined
            )
          }}/>
        </div>

        <div style={{width:(laneCount / maxLaneCount)*100+'%'}}>
          <LaneAdjuster key={atn._id+"-lanes="+laneCount+"-maxLaneCount="+maxLaneCount} {...{
            annotationId:atn._id, 
            tempProperties,
            activeUpdatePreviewProperty,
            imageVersion
          }}/>
        </div>
        {rightPaddingDiv}
        {rightShiftDiv}
      </div>

    )
    );





    return (
      <div 
        onMouseEnter={e => { setEntered(true); }} 
        onMouseLeave={e => { setEntered(false); }}
        style={{ 
          //border:'3px solid red';
        }}
      class="full-width">

        <QuantificationCropInfoHeader {...{
          atn,entered,laneCount
        }}/>

        {versions}

      </div>
    )
}

function ExtraLanes({ width, labels }){
  let laneDivs = [];
  for(let ii = 0; ii < labels.length; ii++){

    laneDivs.push(
      <div style={{
        justifyContent:'center',
        alignItems:'center',
        color:'grey',
        //alignText:'center',
        width,
        display:'flex',
      }}><div style={{
        //background:'yellow'
      }}>{String(labels[ii])}</div></div>
    )

  }

  return laneDivs;


}
