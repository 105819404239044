import {record} from 'rrweb';

export default function initRrweb(){

  window.__curBinKey = Date.now();
  window.__eventCache = {
    [window.__curBinKey]:[]
  };
  window.__streamProgressCache = {};
  window.__EVENT_STREAM_PENDING = 12;



  function logEventLocally(event){
    let curBinKey = window.__curBinKey;
    let eventCache = window.__eventCache;
    let curCache = eventCache[curBinKey];
    if( !curCache ){
      eventCache[curBinKey] = [];
      curCache = eventCache[curBinKey];
    }
    curCache.push(event);
  }


  function getStreamEventsAndUpdateStreamProgress(){

    let curBinKey = window.__curBinKey;
    let eventCache = window.__eventCache;
    let streamProgressCache = window.__streamProgressCache;

    let eventsToStream = {};


    for( let binKey in eventCache ){
      let notCurrentlyStreamingBin = streamProgressCache[binKey] !== window.__EVENT_STREAM_PENDING;
      let thisBinIsNotTheCurrentBin = binKey !== curBinKey;
      let binIsEmpty = eventCache[binKey].length === 0;

      if( thisBinIsNotTheCurrentBin && notCurrentlyStreamingBin && !binIsEmpty ){


        streamProgressCache[binKey] = window.__EVENT_STREAM_PENDING;
        eventsToStream[binKey] = eventCache[binKey]
      }

      if( binIsEmpty && thisBinIsNotTheCurrentBin ){
        delete eventCache[binKey];
      }
    }

    return eventsToStream;

  }

  function streamEvents(){
    window.__curBinKey = Date.now();
    let eventsToStream = getStreamEventsAndUpdateStreamProgress();

    if( Object.keys(eventsToStream).length === 0 ){
      return;
    }

    const body = JSON.stringify({ data: (eventsToStream) })

    //return;

    fetch('/sendDomEvents',{
      method:'POST',
      credentials:'include',
      headers:{
        'Content-Type':'application/json;charset=utf-8'

      },
      body
    })
      .then(resp => {
        if( resp.status === 204 ){
          onStreamSuccess(eventsToStream);
        }else{
          //alert("Stream failed!");
          onStreamFailed(eventsToStream);
        }
      })
      .catch(err => {
        console.log("SEND DOM EVENTS ERROR");
        console.log(err);
        onStreamFailed(eventsToStream)

      })

  }

  window.__streamEvents = streamEvents;

  function onStreamSuccess(eventsToStream){
    let streamProgressCache = window.__streamProgressCache;
    let eventCache = window.__eventCache;

    for(let streamedBin in eventsToStream){
      delete streamProgressCache[streamedBin];
      delete eventCache[streamedBin];
    }

  }

  function onStreamFailed(eventsToStream){

    let streamProgressCache = window.__streamProgressCache;

    Object.keys(eventsToStream).forEach(binKey => {
      delete streamProgressCache[binKey]
    })

  }




  record({
    emit(event){
      logEventLocally(event);
    }
  }, { maskInputOptions:{ password:true }})


  window.__eventStreamInterval = setInterval(
    window.__streamEvents,10000
  );


}
