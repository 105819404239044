import { produce } from 'immer';
import { useState, useRef } from 'react';
import ManualAnnotationGrid from './ManualAnnotationGrid';
import ValueDisplay from './ValueDisplay';
import GoogleDriveIcon from './GoogleDriveIcon';
import CroppableImageContainer from './CroppableImageContainer'
import { isDeleteObjectKey } from './EventUtils';

let mwMap = {
  'β-actin':42,
  CDK1:34,
  p53:53
}

function getTargetMW(target){
  return mwMap[target] || -1;
}

const googleDriveLink = "https://docs.google.com/spreadsheets/d/1Wm1KdUCfYDuVOqsNWq8aUAEJ59xgyp2NMXTLda6QpKw/edit#gid=0"; 

const SidebarHeaders = () => {
  return (
    <tr>
      
      <td style={{width:'33%'}}>Protein target</td>
     <td style={{width:'33%'}}> MW (kDa)</td>
      <td style={{width:'33%'}}>Loading control</td>
    </tr>
  )
}

export default function MembraneGroupContainer({cuts}){

  let ref1 = useRef();
  let ref2 = useRef();
  let ref3 = useRef();

  const [ selectionJustMade, setSelectionJustMade ] = useState(false);
  const [temporaryCellValue,setTemporaryCellValue] = useState(null);


  let cropRefs = [[ref1],[ref2,ref3]];

  cuts = [
    [
      {imageId:"img1",imageSetId:"set0",height:0.4438}
    ],
    [
      {imageId:"img2",imageSetId:"set1",height:0.4181},
      {imageId:"img3",imageSetId:"set2",height:0.4057},
    ],
  ]

  let [targets,setTargets] = useState([""]);
  let [isLoadingControl, setLoadingControl] = useState({});

  /*
   * Cuts contains grouping of cuts, and:
   *  image urls
   *  annotation list
   *    marks
   *    line segments (with labels that get mapped, and some line segments that are hidden unless the name of the protein is typed)
   */

  let targetIndexToFocus = useRef(0);

  let width = 400;

  let cutComponents = cuts.map( (cut,iiCut) => {
    return <div 
      style={{position:'relative',height:200,
        //background:'green',border:'1px solid blue'
      }} class="sg-row">{cut.map( (stain,iiStain) => {

      let { imageId, imageSetId, crop } = stain;
      return (
        <CroppableImageContainer {...{width,height:stain.height,highlight:targets,imageId,imageSetId}}/> 
      )

    })}</div>
  })

  let cutComponentContainer = <div>{cutComponents}</div>;

  let defaultProteinLadder = "SeeBlue Plus2 Pre-stained Standard";

  let targetsContainer = targets.map((target,iiTarget) => (
    <tr>

      <td>
      <input 
        ref={el => {
          if( iiTarget === targetIndexToFocus.current ){
            el && el.focus();
            targetIndexToFocus.current = -1;
          }
        }}
        onChange={e => {
          setTargets(targets.map((val,iiVal) => iiTarget === iiVal ? e.target.value : val));
        }}
        onKeyUp={e => {
          if( iiTarget > 0 && targets[iiTarget] === '' && isDeleteObjectKey(e) ){
            targetIndexToFocus.current = iiTarget - 1;
            setTargets(produce(targets,draft => {draft.splice(iiTarget,1)}));
          }
          else if( e.key === 'Enter' ){
            targetIndexToFocus.current = iiTarget + 1;
            setTargets(produce(targets,draft => {draft.splice(iiTarget+1,0,"")}));
          }
        }}
        style={{width:'80%'}}
      />
      </td>
      <td>{getTargetMW(target)}</td>
      <td>
      <input 
        onChange={e => {
          /*let checked = e.target.checked;
          setLoadingControl(produce(isLoadingControl,draft => {
            isLoadingControl[iiTarget] = checked;
          }))*/
        }}
        type="checkbox"/>
      </td>
    </tr>
  ));

  let sidebar = (
    <div style={{marginLeft:20,borderRadius:10,border:'1px solid #ccc', display:'flex',flexDirection:'column',width:400,background:'white'}} class="small-padding">
      <div class="sg-row small-margin">
        <div>Protein ladder:</div>
        <div class="flex-expand"/>
        <div><select><option>{defaultProteinLadder}</option></select></div>
      </div>

      <div class="small-margin">
        <br/>
        <div style={{textAlign:'center',borderBottom:'1px solid #eee'}}>Protein Targets</div>
        <br/>
        <table>
          <SidebarHeaders/>
          {targetsContainer}
        </table>
      </div>
      <div class="flex-expand"/>
     
      
      <button 
        style={{position:'relative'}} class="sg-row btn btn-outline-primary">

        <a style={{textDecoration:'none'}} target={"_blank"} href={googleDriveLink}>
        <div>Export Quantifications</div>
        <div style={{
          position:'absolute',
          right:10,
          top:5,
          width:25}}>
          <GoogleDriveIcon/>
        </div>
        </a>
      </button>
      
    </div>
  );

  let cellValueInputRef = useRef();


  return (
    <div style={{position:'relative',width:'100%',background:'#eee'}}>
      <div style={{position:'absolute',height:0,top:-100}}>
      <ValueDisplay {...{
        selectionJustMade,
        setSelectionJustMade,
        tableOutlineShowing:true,
        setTemporaryCellValue,
        temporaryCellValue,
        cellValueInputRef
      }}/>
      </div>
      <div style={{ 
        background:'white',
        position:'relative',
        left:0,
        border:'1px solid #ccc',
        borderRadius:5,
        marginLeft:150,
        marginRight:150,
        marginBottom:30,
        marginTop:30,
        }}>
        <ManualAnnotationGrid {...{
          cellValueInputRef,
          tableOutlineShowing:true,
          selectionJustMade,
          setSelectionJustMade,
          temporaryCellValue,
          setTemporaryCellValue,

        }}/>
      </div>
      <div style={{marginLeft:20}} class="sg-row">
        {cutComponentContainer}
        {sidebar}
      </div>
    </div>
  )





}
