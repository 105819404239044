export default function activeElementConsumesArrowKeys(){

  

  let { activeElement } = document;
  let { tagName } = (activeElement||{});

  let inputFieldTypes = ["INPUT","TEXTAREA"];

  if( inputFieldTypes.includes(tagName) ){

    let consumesArrowKeys = activeElement.getAttribute('consumearrowkeys');

    if( consumesArrowKeys === "true" ){ 
      console.log("CONSUMES ARROW KEYS!");
      return true;
    }else{
      return false;
    }
  }

  return false;


}
