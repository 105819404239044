import { useState, useEffect, useRef } from 'react';

import TutorialMessage from './TutorialMessage';

export default function TutorialBoundingBox({
  guideOutline, tutorialState,isDrawLineEvent,
  errorPopup,draggingMouseRef,

}){

  let tutorialMessageTest = <TutorialMessage {...{
      errorPopup:{
        position:(tutorialState.message?.offset) || {
          left:'calc(100% + 10px)',

        },
        text:tutorialState.message?.default,
        debugLocation:guideOutline,
        
      },

    message:tutorialState.message,
    }}/>


  let guideElements =[
    <div id="tutorial-box" style={{
      position:'absolute',
      //position:'fixed',
      ...guideOutline,
      //border:'3px solid #aaa',
      //borderRadius:10,
      pointerEvents:'none',
      zIndex:19999,


    }}/>,


    


  ]



  useEffect(() => {

  },[window.__mouseDown, draggingMouseRef.current])

  let { lineStartRequirements } = tutorialState;
  let noStartGuideWhenMouseDown = lineStartRequirements?.removeWhenMouseDown;
  
  let shouldNotShowLineStartGuide = noStartGuideWhenMouseDown && window.__mouseDown //&& window.__downInCorrectDragRect

  let shouldShowLineStart = !shouldNotShowLineStartGuide;


  if( guideOutline ){

    
    if( isDrawLineEvent ){
      guideElements.push( ...[
        (shouldShowLineStart && <DrawLineGuideline {...{
          tutorialState,
          endpoint:'start',
          guideOutline,
          errorPopup,
          endpointRequirement:tutorialState.lineStartRequirements
        }}/>),

        <DrawLineGuideline {...{
          tutorialState,
          endpoint:'end',
          guideOutline,
          errorPopup,
          endpointRequirement:tutorialState.lineEndRequirements
        }}/>

      ])

    }else{

      let amendedGuideOutline = {...guideOutline};
      if( tutorialState.title === 'LABEL_CELL_LINES' ){
        amendedGuideOutline.width = amendedGuideOutline.width * 6; // because we want to merge six lanes
      }

      guideElements = [<div id="tutorial-box" style={{
        position:'fixed',
        ...amendedGuideOutline,
        border:'3px solid red',
        pointerEvents:'none',
        zIndex:30000
      }}>
        {errorPopup === null && tutorialMessageTest}
      </div>
      ]
    }
  }



  return (
    <div 
      id={"tutorial-guideline-wrapper"}
      ref={el => {

        //looking at this now, I have no Idea why this code
        //is the way it is.

        if( el ){
          let currentGuidelines = document.querySelector('#tutorial-guideline-wrapper');
          if( currentGuidelines ){
            currentGuidelines.parentNode.removeChild(currentGuidelines);
          }
          document.body.appendChild(el)

          let noClickDragArrow = tutorialState.clickDragArrow !== false;
         


          if( !window.__mouseDown && noClickDragArrow ){
            updateSvgPointer({tutorialState});
          }

        }
      }}
      style={{pointerEvents:'none'}}>
      {guideElements}



    </div>
  );

}


function getSvgDefs(){

  const markerHeight = 2;

  let defs = document.createElementNS("http://www.w3.org/2000/svg", 'defs');
  let marker = document.createElementNS("http://www.w3.org/2000/svg", 'marker');

  let markerProps = {
    id:"triangle",
    viewBox:"0 0 10 10",
    refX:"1",
    refY:"2",
    markerUnits:"strokeWidth",
    markerWidth:"10",
    markerHeight:"10",
    orient:"auto"
  }

  let path = document.createElementNS("http://www.w3.org/2000/svg", 'path');
  path.setAttribute('d',"M 0 0 L 4 2 L 0 4 z");
  path.setAttribute('fill','red');

  Object.entries(markerProps).forEach(([key,val]) => {
    marker.setAttribute(key,val)
  })

  marker.appendChild(path);
  defs.appendChild(marker);

  return defs;


}


function customPointerBehaviour({SVG,start,end}){

  let startRect = start.getBoundingClientRect();
  let endRect = end.getBoundingClientRect();

  let a_x = startRect.left + 0.5 * startRect.width;
  let b_x = endRect.left + 0.5  * endRect.width;
  let a_y = startRect.top + 0.5 * startRect.height;
  let b_y = endRect.top + 0.5 * endRect.height;


  let lambda = 0.01;


  let x1 = (1-lambda) * a_x + lambda * b_x;
  let y1 = (1-lambda) * a_y + lambda * b_y;
  let x2 = lambda * a_x + (1-lambda) * b_x;
  let y2 = lambda * a_y + (1-lambda) * b_y;


  const straightLine = (x1,y1,x2,y2) => {
    return `M${x1},${y1} L${x1},${y1}; M${x1},${y1} L${x2},${y2};`//450,250;`
  }


  const curvedLine = (x1,y1,x2,y2) => {
    //this works for the line
    //doesn't work for the arrowhead
    //

    let vShift = -8

    return `M${x1} ${y1+vShift} C${x1} ${y1+vShift} ${0.5*x1 + 0.5*x2} ${y1-50} ${x2},${y2+vShift*2}`
    //; M${x1},${y1} C${x1},${y1} ${0.5*x1 + 0.5*x2},${y1-100} ${x2},${y2};`
  }


  let lineData = {
    //x1,x2,y1,y2,
    stroke:"red",
    fill:'transparent',
    'stroke-width':3,
    'marker-end':'url(#triangle)',
    d:curvedLine(x1,y2,x2,y2),
    //class:'draw-line-path',
  };

  let path = document.createElementNS('http://www.w3.org/2000/svg', 'path')

  
  for(let lineKey in lineData){
    path.setAttribute(lineKey,lineData[lineKey]);
  }

  let defs = getSvgDefs();

  
  SVG.appendChild(defs);
  SVG.appendChild(path);
  document.body.appendChild(SVG);

}

function defaultPointerBehaviour({SVG,start,end}){

  let startRect = start.getBoundingClientRect();
  let endRect = end.getBoundingClientRect();

  let a_x = startRect.left + 0.5 * startRect.width;
  let b_x = endRect.left + 0.5  * endRect.width;
  let a_y = startRect.top + 0.5 * startRect.height;
  let b_y = endRect.top + 0.5 * endRect.height;


  let lambda = 0.01;


  let x1 = (1-lambda) * a_x + lambda * b_x;
  let y1 = (1-lambda) * a_y + lambda * b_y;
  let x2 = lambda * a_x + (1-lambda) * b_x;
  let y2 = lambda * a_y + (1-lambda) * b_y;


  const straightLine = (x1,y1,x2,y2) => {
    return `M${x1},${y1} L${x1},${y1}; M${x1},${y1} L${x2},${y2};`//450,250;`
  }

  let lineData = {
    //x1,x2,y1,y2,
    stroke:"red",
    fill:'transparent',
    'stroke-width':3,
    'marker-end':'url(#triangle)',
    class:'draw-line-path',
  };

  let line = document.createElementNS('http://www.w3.org/2000/svg', 'path')

  let animate = document.createElementNS('http://www.w3.org/2000/svg', 'animate');
  let animateProps = {
    dur:'2s',
    repeatCount:'indefinite',
    'attributeName':'d',
    values:straightLine(x1,y1,x2,y2)
    //values:'M100,100 L100,100; M100,100 L450,250'
    //values:`m${x1},${y1} l${x2},${y2}; m20,20 l20,20`
  }

  Object.entries(animateProps).forEach(([key,val]) => {
    animate.setAttribute(key,val);
  })


  //let line = document.createElement("LINE");

  for(let lineKey in lineData){
    line.setAttribute(lineKey,lineData[lineKey]);
  }

  let defs = getSvgDefs();

  line.appendChild(animate);
  
  SVG.appendChild(defs);
  SVG.appendChild(line);
  document.body.appendChild(SVG);

}

function updateSvgPointer({tutorialState}){

  

  let connectingLineSvg = document.querySelector('#connecting-line-svg');
  if( connectingLineSvg ){

    connectingLineSvg.parentNode.removeChild(connectingLineSvg);
    //return;
    //
  }

  let start = document.querySelector('[tutorialrole=endpoint][endpoint=start]')

  let end = document.querySelector('[tutorialrole=endpoint][endpoint=end]')

  if( !(start && end ) ){
    return;
  }else{
  }

  let SVG = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
  //document.createElement("SVG");

  SVG.id = 'connecting-line-svg';

  let svgStyle = {
    position:'absolute',
    top:0,
    left:0,
    width:'100%',
    height:'100%',
    'pointer-events':'none',
    'z-index':100000
  }
  for(let svgKey in svgStyle){
    SVG.style[svgKey] = svgStyle[svgKey];
  }
  //SVG.setAttribute('width','10000');
  //SVG.setAttribute('height','10000');
  //

  if( tutorialState.customPointer ){
    customPointerBehaviour({SVG,start,end});
  }else{
    defaultPointerBehaviour({SVG,start,end});
  }

  
}

function DrawLineGuideline({ endpointRequirement, guideOutline, endpoint, tutorialState, errorPopup, }){
  

  useEffect(() => {

    function mouseMove(e){
      let { clientX, clientY } = e;

      let endEndpoint = document.querySelector('[endpoint=end]');
      if( endEndpoint ){

        let boundingRect = endEndpoint.getBoundingClientRect();
        let x0 = boundingRect.x;
        let x1 = boundingRect.x + boundingRect.width;
        let y0 = boundingRect.y;
        let y1 = boundingRect.y + boundingRect.height;

        let inX = x0 < clientX && clientX < x1;
        let inY = y0 < clientY && clientY < y1;

        if( inX && inY && window.__mouseDown ){
          endEndpoint.style.border = '3px solid lime';
        }else{
          endEndpoint.style.border = '3px solid red';
        }



      }
    }
    window.addEventListener('mousemove',mouseMove)
    return () => {
      window.removeEventListener('mousemove',mouseMove);
    }
  })


  let { selector } = endpointRequirement;

  
  if( endpointRequirement.relativeOffset && endpointRequirement.offset ){
    throw Error("Cannot appeal to both 'relativeOffset' and 'offset' for drag endpoint.");
  }

  let element;

  let targetSelectorBounds;
  if( endpointRequirement.selector ){
    element = document.querySelector(selector);
    if( !element ){
      return null;
    }
    targetSelectorBounds = element.getBoundingClientRect();
  }

  let isCorrectEndpointForMessage = (
    tutorialState.message?.positionAt === endpoint
  );

  let tutorialMessageTest = true && isCorrectEndpointForMessage && ( 
    <TutorialMessage {...{
      errorPopup:{
        position:(tutorialState.message?.offset) || (
          {
            transform:'translate(100%,0) translate(10px,0)',
          }
        ),
        text:tutorialState.message.default,
        debugLocation:guideOutline,
        
      },
      message:tutorialState.message,
    }}/>
  )
  

  if( targetSelectorBounds && endpointRequirement.offset ){

    if( !element ){
      return null;
    }
    
    let { left, top, width, height } = targetSelectorBounds;

    



    let offset = endpointRequirement.offset || endpointRequirement.relativeOffset || {};
    let leftOffset = offset.left || 0;
    let topOffset = offset.top || 0;

    let finalLeft = left + leftOffset;
    let finalTop = top + topOffset;

    height = offset.height || height;
    width = offset.width || width;

    return (
      <div
        endpoint={endpoint}
        ref={null}
        //class={"no-pointer-hover-green"}
        tutorialrole={"endpoint"}
        style={{
          border:'3px solid red',
          
          pointerEvents:'none',//({start:'none',end:'unset'})[endpoint],

          position:'fixed',
          

          left:finalLeft-4,
          top:finalTop,
          width,
          height,
          zIndex:20000,
          ...(endpointRequirement.style || {})

        }}>

        {tutorialMessageTest}
      </div>
        
    )
  }

  let relativeOffset = endpointRequirement.relativeOffset;
  let { dim } = endpointRequirement;
  dim = dim || 15;
  let halfDim = dim/2;

  let anchor = targetSelectorBounds || guideOutline;

  


  return (

    <div 
      tutorialrole={"endpoint"}
      endpoint={endpoint}
      //class={"no-pointer-hover-green"}
      style={{
        width:relativeOffset.width || dim,
        height:relativeOffset.height || dim,
        border:'3px solid red',
        position:'absolute',
        borderRadius:40,
        //pointerEvents:'none',


        top:(anchor.top + relativeOffset.top * anchor.height - halfDim),
        left:(anchor.left + relativeOffset.left * anchor.width - halfDim),
        zIndex:20000
      }}>
      {tutorialMessageTest}
    </div>
  )


}
