import { distance, getIntermediaryPoint } from './Geometry';
import ProcessImage from './ProcessImage';
import { useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';
import { QuantificationListContext } from './Contexts';
import { useContext } from 'react';
import G from './Getters';


function integrate(laneQuant){

  let integrationRange = laneQuant.integrationRanges[0];
  let [ rangeStart, rangeEnd ] = integrationRange;
  let { plotValues } = laneQuant;

  let startIndex = Math.round( rangeStart * plotValues.length);
  let endIndex = Math.round( rangeEnd * plotValues.length );

  let toIntegrate = plotValues.slice(startIndex, endIndex);

  let sum = toIntegrate.reduce((a,b) => a+b,0);

  return sum;

}

function makeRow(atn){
  let { label, quantifications } = atn;
  let row = [label, ...quantifications.map(integrate)];
  return row;
  //let commaSeparated = row.join(',');
  //return commaSeparated;
}

function getTableValues(atnInfoList){

  let annotations = atnInfoList.map(x => x.annotation);
  let rows = annotations.map(makeRow);
  return rows;


}

function getClipboardValues(atnInfoList){
  let rows = getTableValues(atnInfoList);
  let tabbedRows = rows.map( row => row.join('\t') );
  let spacedRows = tabbedRows.join('\n')
  return spacedRows;
}

function download(atnInfoList){

  
  let csvContent = "data:text/csv;charset=utf-8,";
  let rows = getTableValues(atnInfoList);

  rows.forEach(function(rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  var encodedUri = encodeURI(csvContent);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "my_data.csv");
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".

  document.body.removeChild(link);

}
function copyToClipboard(data){
  let ta = document.createElement("TEXTAREA");
  ta.value = data;
  ta.style.display = "hidden";

  document.body.appendChild(ta);
  ta.focus();
  ta.select();
  document.execCommand('copy');
  document.body.removeChild(ta);
}


export default function QuantificationActionButtons({
  atnQuantInfoList,
}){

  let dispatch = useDispatch();

  let state = useSelector(state => state);

  const quantificationListContext = useContext(QuantificationListContext);

  let { quantificationListDispatch } = quantificationListContext || {};

  //let { quantificationListDispatch } = useContext(QuantificationListContext);




  return (

    <div class="flex-expand" style={{display:'flex',alignItems:'center',
      //justifyContent:'center',
      //border:'1px solid blue',
    }}>


      <div id="quantify-all" style={{background:'#f0f0f0',cursor:'pointer'}} onClick={() => {
        if( quantificationListDispatch ){
          quantificationListDispatch({type:"removeAll"});
        }
      }} 
        class={" small-padding hoverable round-corners small-margin"}>Clear</div>

      <div class="flex-expand"/>


      <div id="quantify-all" button={"quantify"} style={{background:'#f0f0f0',cursor:'pointer'}} onClick={() => {
        dispatch(A.startQuantification({annotationIds:atnQuantInfoList.map(x => x._id)}) )
        /*quantifyAll(atnQuantInfoList,true,dispatch)*/
      }} class={" small-padding hoverable round-corners small-margin"}>Quantify</div>

      <div id="quantify-all" button={"download-csv"} style={{background:'#f0f0f0',cursor:'pointer'}} onClick={() => download(atnQuantInfoList)} class={" small-padding hoverable round-corners small-margin"}>Download CSV</div>

      {true && atnQuantInfoList.every(atn => atn.annotation.quantifications) && <div id="quantify-all" style={{background:'#f0f0f0',cursor:'pointer'}} 
        onClick={
          () => downloadMacro(state,{atns:atnQuantInfoList})

        } 
        class={" small-padding hoverable round-corners small-margin"}>Quantify with ImageJ Macro</div>}

        {true && atnQuantInfoList.every(atn => atn.annotation.quantifications) && <div id="quantify-all" style={{background:'#f0f0f0',cursor:'pointer'}} 
          onClick={() => {
            dispatch(A.downloadQuantificationValues({
              via:"clipboard",
              idList:atnQuantInfoList.map(x => x._id)
            }));

          let toClipboard = getClipboardValues(atnQuantInfoList);
          copyToClipboard(toClipboard);


        }} 
        class={" small-padding hoverable round-corners small-margin"}>Copy to Clipboard</div>}

      <div class="flex-expand"/>

    </div>

  )
}

function downloadMacro(state,{atns}){
  let macro = G.getQuantificationMacro(state,{atns});

  var encodedUri = encodeURI(macro);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "QuantificationMacro.ijm");
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".

  document.body.removeChild(link);



}
