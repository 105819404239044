import Id, {Ids} from './IdFactory';


export default function AssayFactory(state,args){
  let { experimentId } = args;

  return {
    _id:Id(),
    ...args,
  }
}
