import Tooltip from '@material-ui/core/Tooltip';
import A from './ActionCreators';

export default function ImageExpansionTemplateInsertionLayer({dispatch,templateId,nodeId}){

  return ( 
      <>

    <div 
      onClick={() => {
        dispatch(A.insertExpansionTemplateNode({
          templateId,
          d:"v",
          before:nodeId
        }))
      }}
      class="hidden-child" style={{
        position:'absolute', top:0, 
        left:'50%', transform:'translate(-50%,-25%)',
        cursor:'pointer',
      }}>

      <Tooltip title="Add Above">
        <span class="hover-bold">+</span>
      </Tooltip>
    </div>


    <div class="hidden-child" style={{position:'absolute', bottom:0, left:'50%', transform:'translate(-50%,25%)', cursor:'pointer',
    }}


      onClick={() => {
        dispatch(A.insertExpansionTemplateNode({
          templateId,
          d:"v",
          after:nodeId
        }))
      }}

    >


      <Tooltip title="Add Below">
        <span>+</span>
      </Tooltip>

    </div>

    <div class="hidden-child" style={{position:'absolute', left:0, top:'50%', transform:'translate(0,-50%)',
      cursor:'pointer',
    }}

      onClick={() => {
        dispatch(A.insertExpansionTemplateNode({
          templateId,
          d:"h",
          before:nodeId
        }))
      }}

    >

      <Tooltip title="Add Left">
        <span>+</span>
      </Tooltip>
    </div>

    <div class="hidden-child" style={{position:'absolute', right:0, top:'50%', transform:'translate(0,-50%)',
      cursor:'pointer',

    }}

      onClick={() => {
        dispatch(A.insertExpansionTemplateNode({
          templateId,
          d:"h",
          after:nodeId
        }))
      }}

    >

      <Tooltip title="Add Right">
        <span>+</span>
      </Tooltip>
    </div>
    </>
      )
}
