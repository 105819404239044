import React from 'react';
import G from './Getters';
import C from './Constants';

import computeFilters from './computeFilters';

import imageMap from './imageMap';

const proportions = (rectangle, imageWidth, scale) => {
  let props = {};
  Object.keys(rectangle).forEach((key) => {
    props[key] = (rectangle[key] / imageWidth) * (scale ? scale : 1);
  });


  return props;
};

const toRad = degree => {
  return Math.PI * 2 * degree / 360;
}



export default function CropPreview({ stack, onClick, draggable, parentWidth, entered, gridCellId, temporaryAdjustments, 
  imageId,
  htmlImageId, 
  htmlImageClipDivId,
  overflow,
  imgRef,
  crop,
  setDragInfo,
  imageRecord,
  src
}){


 
  /*
  const onMouseUp= e => {

    let div = document.getElementById('drag-ghost')
    if( div) {
      document.body.removeChild(div);
    }
  }

  React.useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mouseup',onMouseUp);
    }
  })*/

  const [computedWidth,setTargetWidth] = React.useState(null);
  const containerRef = React.useRef(null);

  if( !crop ){

  }
  let rad = toRad( crop.rotation || 0 );
   
  let cropRotation = crop.rotation

  let imageIdQuery = {
    imageId:imageId || crop.imageId,
    version:C.DEFAULT_PROCESSED_IMAGE_VERSION
  };

  /*
  let {imageRecord,src} = usePresentSelector(state => {
    let imageRecord = state.data.imageUploads[imageIdQuery.imageId];
    let src = G.getImageRecordUrl(state,imageIdQuery);


    let queryNoResults = !src;

    
    if( queryNoResults ){


      let imageIdMediaContainer = state.media[ imageIdQuery.imageId ];
      let imageInImageMap = Object.values(imageIdMediaContainer).filter(({localBlobUrl}) => (localBlobUrl in imageMap))[0];


      console.error("queryNoResults");


      let { localBlobUrl } = imageInImageMap || {}; 
      return { imageRecord, src:imageMap[ localBlobUrl ] }
    }


    return { imageRecord, src }
  });
  */

  
  let targetWidth = (parentWidth || computedWidth) // * imageScaledByRotation;

  ////console.log("%c Target width: " + targetWidth,'color: blue'); 

  if( crop === undefined ){

  }
  
  let transform = "rotate(" + -cropRotation + "deg)"// scale("+imageScaledByRotation+")";

  let cropWidth = crop.width;// / imageScaledByRotation;

  //let scaleForVerticalImage = Math.max(1, imageRecord.height??0);

  let imgStyle = {
    position:'absolute',
    top: -crop.top * targetWidth / cropWidth,
    left: -crop.left * targetWidth / cropWidth,
    width: targetWidth / cropWidth,
    //border:'1px solid red',
    transform, 
    ...computeFilters(temporaryAdjustments||imageRecord.adjustments)
  }


  return (
    <div
      draggable={false}
      class={"crop-preview-outer-div"}

      

      ref={containerRef} 

      style={{
        position:'relative',
        width:'100%',
      }}>


      {targetWidth && 
      <>
        <div id={htmlImageClipDivId}
          draggable={draggable}
          
          style={{
            position: "relative",
            overflow: "hidden",/*(overflow?"visible":(isEditingPlacement ? "visible" : "hidden"))*/
            width: targetWidth,
            height: targetWidth / crop.width * crop.height,
            //overflow:'visible',
          }}
        >
          {targetWidth !== null && (
            <>

              <img 
                imageId={imageId}
                type="image-resource"
                ref={imgRef}
                id={htmlImageId}
              class={"no-drag"} 
              src={imageMap[src]||src} 
              style={imgStyle} />
            </>
          )}
        </div>
      </>
      }
    </div>
  );
}
