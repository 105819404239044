import usePresentSelector from './usePresentSelector';
import G from './Getters';
import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import UploadItemInfo from './UploadItemInfo';

export default function UploadProgressContainer(){

  const [open, setOpen] = useState(true);

  let mediaProcessingInfo = usePresentSelector(state => {
    let idList = Object.keys(state.pendingRecords.imageUploads)
    return idList.map(_id => ({
      _id,
      message: G.getMediaProcessingStatusMessage(state,{_id}),
      canRestart: G.canRestartMediaProcessing(state,{_id}),
      image: G.getImage(state,{_id,pendingRecords:true}),//.filename,
      
    }))
  });

  if( mediaProcessingInfo.length === 0 ){
    return null;
  }

  return (
    <div style={{
      width:'100%',
      //border:'2px solid blue',
      position:'relative',
      minWidth:0,
      borderBottom:'1px solid #ccc',

      
    }}>
      <div style={{ }}
        class="flex-expand">

        <div style={{
          border:'1px solid #ccc',
          background:'#eee',
          fontSize:14,
          fontWeight:'bold',
          textAlign:'center',
          position:'relative',
          minWidth:0,
          width:'100%',
          
        }}>
          <div style={{
            //border:'4px solid red',
            width:'100%',
            minWidth:0,
            }}>
          {"Pending Uploads ("+mediaProcessingInfo.length+")"}
          </div>

          <div 
            class="hoverable"
          style={{
            right:3,
            borderRadius:20,
            height:20,
            width:20,
            position:'absolute',
            top:0,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            cursor:'pointer',
          }} onClick={()=>setOpen(!open)}>

            {open?<ExpandMoreIcon/>:<ExpandLessIcon/>}
          </div>


        </div>

        {open && <div style={{
          maxHeight:300,
          overflowY:'scroll',
        }}>

          {mediaProcessingInfo.map(info => <UploadItemInfo key={info._id} {...{info}}/>)}
        </div>}

      </div>
    </div>
  )


}
