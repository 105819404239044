export default function StyledSubstring({ text, substring, style }){
  const parts = text.split(new RegExp(`(${substring})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === substring.toLowerCase() ? (
          <span style={style} key={index}>{part}</span>
        ) : (
          part
        )
      )}
    </span>
  );
};

