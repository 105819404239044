import { produce } from 'immer';

export default function applyChangesToHistoryInfo(state,patchFunction,delta,index){
  if(typeof(patchFunction)!==typeof(function(){})){
    debugger;
  }
  //let patchedState = patchFunction(state,delta);
  let patchedStateWithHistoryInfoUpdated = produce(state,draft => {

    patchFunction(draft,delta);


    draft.historyInfo.index = index;
  })
  return patchedStateWithHistoryInfoUpdated;
}

