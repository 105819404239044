import { isCLI } from './versionConfig';

export default async function getBlobFromUrl(url){

  return new Promise(async (res,rej) => {

    if( isCLI() ){

      const { resolveObjectURL } = require("buffer");


      let resolvedUrl = await resolveObjectURL(url);

      if( !resolvedUrl ){
        console.log("Could not resolve " + url);
        //throw Error("Chould not resolve resource '"+url+"'");
      }


      try{

        let resolved = await resolvedUrl.text();

        res( resolved );
      }catch(e){
        debugger;
        //throw(e);
        
      }
    }else{
      let fetched = await fetch(url);
      let blob = await fetched.blob();
      res(blob);
    }
  })
}

