import FinalImage from './images/TutorialFinalProduct.png';
import ExitTutorialButton from './ExitTutorialButton';
import NavbarScreenshot from './images/NavbarScreenshot.png';
export default function TutorialIntro({onStateChange}){

return (
    <div style={{ 

      //border:'1px solid green',
      alignItems:'center',
      background:'#ddd',
      paddingTop:'10%',


    }} 
      class="sg-col full-width full-height">

      <h5>
        You can exit the tutorial by clicking the button on the right

      </h5>

      <br/>

      <div style={{width:'60%', position:'relative'}}>
        <img style={{border:'1px solid black', width:'100%'}} src={NavbarScreenshot}/>
      </div>



      <br/>

      <h5>once the tutorial begins.</h5>



      <button tutorialButton={"start"} style={{marginTop:20}} onClick={onStateChange} class="btn btn-primary">Continue</button>
    </div>
  )
}
