import { HexColorPicker } from "react-colorful";
import CheckIcon from '@material-ui/icons/Check';


const PRESET_BORDER = {
  "#ffffff": '1px soild #ccc',
  "#f3f3f3": '1px solid #ccc',
  "#efefef": '1px solid #ccc',
};

export default function ColorPicker({ color, onChange, selectedFigureItems, size, containerWidth }){


  let outerStyle = {};
  let outerClassName = "picker";
  if( containerWidth ){
    outerStyle.width = containerWidth;
    outerClassName = "";
  }

  return (
    <div style={{ 
      ...outerStyle,
      height:'100%',
    }} >
      {presetColors.map(presetRow => (
        <div style={{
          height:'12.5%', 
          position:'relative', 
          width:'100%',
          //border:'1px solid #ccc',
          }} class="sg-row">
          {presetRow.map((presetColor) => (
            <div
              className="clickable"
              style={{
                margin:2,
                width:'10%',
                height:20,
                //aspectRatio:'1 / 1',
                border:PRESET_BORDER[presetColor],
                background: presetColor,
                borderRadius:20,
                position:'relative',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
              }}
              onClick={() => onChange(presetColor)}
            >
              <div className={"thick-border-on-hover"} style={{
                borderRadius:20,
                position:'absolute',
                top:'-5%',
                left:'-5%',
                width:'110%',
                height:'110%',
              }}>
              </div>
              {presetColor === color && <div style={{
                position:'absolute',
                top:'50%',
                left:'50%',
                padding:0,
                margin:0,
                transform:'translate(-50%,-55%)',
              }}>
                <CheckIcon style={{
                  fontSize:14,
                  //border:'1px solid black',
                  //lineHeight:0,
                  color:selectedCheckColor[presetColor]||'black',
                }}/>
              </div>}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};


const selectedCheckColor = {
  "#000000":'white',
  "#434343":'white',
  "#666666":'white',

  "#980000":'white',
  "#ff0000":'white',
  "#ff9900":'white',

  "#0000ff":'white',
  "#9900ff":'white',
  "#ff00ff":'white',


"#a61c00":"white",
"#cc0000":"white",
"#e69138":"white",
"#f1c232":"white",
"#6aa84f":"white",
"#45818e":"white",
"#3c78d8":"white",
"#3d85c6":"white",
"#674ea7":"white",
"#a64d79":"white",
"#85200c":"white",
"#990000":"white",
"#b45f06":"white",
"#bf9000":"white",
"#38761d":"white",
"#134f5c":"white",
"#1155cc":"white",
"#0b5394":"white",
"#351c75":"white",
"#741b47":"white",
"#5b0f00":"white",
"#660000":"white",
"#783f04":"white",
"#7f6000":"white",
"#274e13":"white",
"#0c343d":"white",
"#1c4587":"white",
"#073763":"white",
"#20124d":"white",
"#4c1130":"white",
}

const presetColors = [["#000000","#434343","#666666","#999999","#b7b7b7","#cccccc","#d9d9d9","#efefef","#f3f3f3","#ffffff"],
["#980000","#ff0000","#ff9900","#ffff00","#00ff00","#00ffff","#4a86e8","#0000ff","#9900ff","#ff00ff"],
["#e6b8af","#f4cccc","#fce5cd","#fff2cc","#d9ead3","#d0e0e3","#c9daf8","#cfe2f3","#d9d2e9","#ead1dc"],
["#dd7e6b","#ea9999","#f9cb9c","#ffe599","#b6d7a8","#a2c4c9","#a4c2f4","#9fc5e8","#b4a7d6","#d5a6bd"],
["#cc4125","#e06666","#f6b26b","#ffd966","#93c47d","#76a5af","#6d9eeb","#6fa8dc","#8e7cc3","#c27ba0"],
["#a61c00","#cc0000","#e69138","#f1c232","#6aa84f","#45818e","#3c78d8","#3d85c6","#674ea7","#a64d79"],
["#85200c","#990000","#b45f06","#bf9000","#38761d","#134f5c","#1155cc","#0b5394","#351c75","#741b47"],
["#5b0f00","#660000","#783f04","#7f6000","#274e13","#0c343d","#1c4587","#073763","#20124d","#4c1130"]];
/*
const presetColors = [
    "#ff0000",
    "#ffa500",
    "#ffff00",
    "#00ff00",
    "#00ffff",
    "#0000ff",
    "#800080",
    "#ff00ff",

    "#8b0000",
    "#ff4500",
    "#ffd700",
    "#32cd32",
    "#40e0d0",
    "#000080",
    "#663399",
    "#ee82ee",

    "#000000",
    "#444444",
    "#666666",
    "#888888",
    "#aaaaaa",
    "#cccccc",
    "#eeeeee",
    "#ffffff"
  ];
  */
