import MoveIcon from './svg/Move.svg';
import Tooltip from '@material-ui/core/Tooltip';
export default function DragRowButton({rowDragDispatch,rowIndex,rowDragState, left, right,
  dragRowMargin,
  manualAnnotationGridDispatch,
  tooltipPlacement
}){

  //let hoveringOverRow = rowDragState && rowDragState.hovering === rowIndex;

  return (
    <Tooltip title={"Drag to Move Row"} placement={tooltipPlacement}>
    <div
      className={"invisible-without-grid-row-hover"}
      action={"start-drag-"+rowIndex}
      side={left?"left":"right"}
        onMouseEnter={() => {

      /*manualAnnotationGridDispatch({
        verticalImageResizeTranslation:{
          untilRowIndex:rowIndex-1,
          y:-dragRowMargin
        }
      })*/

      //rowDragDispatch({hover:rowIndex})
    }
    }

    onMouseLeave={() => {
      //rowDragDispatch({releasePreDrag:rowIndex})

      /*manualAnnotationGridDispatch({
        verticalImageResizeTranslation:{
          untilRowIndex:-1,
          y:0,
        }
      })*/


    }}
    style={{
      position:'absolute', 
      height:'100%',
      //border:'1px solid blue',
      //background:'orange',
      //marginLeft:left,
      //marginRight:right,
      left,
      right:-40,
      //top:0,
      padding:0,
      margin:0,
      width:Math.max(40, Math.abs(left||right)),
      top:'50%',
      transform:'translate(0,-50%)',
      fontWeight:(rowDragState.preDrag ? 'bold' : 'unset'),

      //border:'1px solid red',
      

    }}>
      {true && <div 
      class="no-drag"

      action={"rowDragButton"}
      rowIndex={rowIndex}
      side={left ? "left":"right"}
      onMouseDown={()=>{

      rowDragDispatch({dragging:rowIndex})
    }}
      style={{
        //pointerEvents:(hoveringOverRow?'unset':'none'),
        //background:'pink',
        position:'absolute',
        top:'50%',
        transform:'translate(0,-50%)',
        zIndex:4000,
        //height:'100%',
        //border:'1px solid red',
        cursor:'pointer'}}>
        <div style={{
          //border:'1px solid green',
          //opacity:(hoveringOverRow ? 1 : 0)
        }}>{true && 
          <img class="no-drag" alt="Drag row" style={{maxWidth:15,
          pointerEvents:'none',

    }} src={MoveIcon}/>}
        </div>
        </div>}



  </div>
    </Tooltip>
  )
}
