import helvetica from './helveticaGlyphWidths.json';
import times from './timesGlyphWidths.json';

export function getRotatedRectHeight(bounds, angle){

  let { height, width } = bounds;
  let abstheta = Math.abs((Math.PI * 2 * angle) / 360);

  let totalHeight = width * Math.sin(abstheta) + height * Math.cos(abstheta);
  return totalHeight;

}

export default function computeCellTextBounds(cell){

  let { style, value } = cell.data;

  if( typeof(value) !== 'string' ){
    return {height:0, width:0}
  }

  let fontFamily = style.fontFamily || 'Helvetica';
  let fontSize = style.fontSize || 12;

  let widthDict = ({Helvetica:helvetica,Times:times})[fontFamily];

  let getLineWidth = function(line){
    return Array.from(line).reduce((width,letter) => {
      return width + Number(widthDict[letter]) * fontSize;
    },0)
  }


  let lines = value.split('\n');
  let lineWidths = lines.map(getLineWidth);
  let width = Math.max(...lineWidths); 

  let height = lines.length * fontSize;

  return { width, height }

}
