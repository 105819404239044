import React from "react";
import { useState, useEffect } from 'react';
import usePresentSelector from './usePresentSelector';
import Navbar from './Navbar';
import { useDispatch, useSelector } from 'react-redux';

import CropPreviewContainer from './CropPreviewContainer';
import G from './Getters';
import A from './ActionCreators';
import Tooltip from '@material-ui/core/Tooltip';

import ImageAdjustmentContainer from './ImageAdjustmentContainer';

import DrawOnBandsIcon from './DrawOnBandsIconLarge';
import DrawRotatedRectIcon from './DrawRotatedRectIconLarge';

import CroppableImage from './CroppableImage';
import { DrawingContext, AnnotationStyleContext } from './Contexts';

const bounds = (ref) => ref.current.getBoundingClientRect();

const p = (arg) => {
  if (arg instanceof Object) {
    let newObj = {};
    Object.keys(arg).forEach((key) => (newObj[key] = p(arg[key])));
    return newObj;
  } else {
    return arg + "px";
  }
};


;

const toRad = (deg) => (deg / 360) * 2 * Math.PI;


function provideSetFocusedAnnotationIdHookGloballyForTutorial(hookFunction){
  window.__setFocusedAnnotationId = hookFunction;
}

export default function ImageCroppingContainer({
  selectedImageId,
  referenceAnnotationId,
  src,
  showSidebar,
  imageManagementWidth,
  mode,
  onEvent,
}) {

  let dispatch = useDispatch();


  const [temporaryAdjustments, setTemporaryAdjustments] = useState({});


  const [ focusedAnnotationId, _setFocusedAnnotationId ] = useState();


  provideSetFocusedAnnotationIdHookGloballyForTutorial(_setFocusedAnnotationId);


  let [temporaryAnnotationProperties,setTemporaryAnnotationProperties] = useState({});


  const setFocusedAnnotationId = arg => {
    let t = temporaryAnnotationProperties;

    _setFocusedAnnotationId(arg)
    if( Object.keys(t).length > 0 ){

      dispatch(A.setAnnotationProperties({
        _id:focusedAnnotationId,
        ...temporaryAnnotationProperties
      }))

    }
    
    
  }


  let { crop, imageSetId, imageId, image, atns } = useSelector(state => {
    let crop;
    let imageSetId;
    let imageId;
    let image;
    let atns;

    if( referenceAnnotationId ){
      crop = G.getCropFromAnnotation(state,referenceAnnotationId)
      imageSetId = G.getImageSetIdByAnnotationId(state,{_id:referenceAnnotationId});
      imageId = G.getImageSetFigureImageIdByAnnotationId(state,{annotationId:referenceAnnotationId});
      image = G.getData(state,{itemType:'imageUploads',_id:imageId});
      atns = G.getRelativeLsAnnotationsBoundedByAnnotationId(state,{annotationId:referenceAnnotationId});
    
    }else{
     
      imageId = selectedImageId;
      image = G.getData(state,{itemType:'imageUploads',_id:imageId});
      let { height } = image;

      //alert("height = " + height);

      crop = { 
        top:0, 
        left: 0, 
        width:1, 
        height, 
        rotation:0 
      }
      imageSetId = G.getImageSetIdByImageId(state,imageId);
      atns = G.getAnnotationsByImageSetId(state,imageSetId);

    }

    return { 
      crop, 
      imageSetId,
      imageId,
      image,
      atns
    }
  });

  

  useEffect(() => {
    setFocusedAnnotationId(undefined);
  },[imageSetId])


  


  

  useEffect(() => {
    setTemporaryAnnotationProperties({});
    
  },[focusedAnnotationId])

  useEffect(() => {
    if( !focusedAnnotationId ){
      setFocusedAnnotationId(null);
    }
  },[imageSetId])

  const notifyAnnotationPropertyChange = change => {
    setTemporaryAnnotationProperties({
      ...temporaryAnnotationProperties,
      ...change
    })
  }

  

  const imgContainerRef = React.useRef(null);
  const imgRef = React.useRef(null);

  
  const [windowResizeMarker,setWindowResized] = React.useState(0);

  useEffect(() => {
    setWindowResized(!windowResizeMarker);
  },[imageManagementWidth])

  const onResize = () => {
    setWindowResized(!windowResizeMarker);
  }

  React.useEffect(() => {
    window.addEventListener('resize',onResize);

    return () => {
      window.removeEventListener('resize',onResize);
    };
  });

  const [ selectedItemIds, setSelectedItemIds ] = React.useState([]);


  const onClick = (e) => {
    let path = e.composedPath();
    let tagsToIgnoreForUnselection = ["INPUT", "SELECT", "BUTTON"];


    if (!tagsToIgnoreForUnselection.includes(path[0].tagName)) {
      setSelectedItemIds([]);
    }
  };

  React.useEffect(() => {
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  });

  const HtmlTooltip = ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )

  const [ selectedDrawingToolId, setSelectedDrawingToolId ] = useState(0);

  const [ guidelineSpec, setGuidelineSpec ] = useState({w:1,h:1});

  let drawingToolMap = ['band','box','fixedRatio'];

 
  let defaultSidebar = <CropPreviewContainer {...{
      atns,
      focusedAnnotationId,
      setFocusedAnnotationId,
      temporaryAnnotationProperties,
      notifyAnnotationPropertyChange,
      imgRef,
      imageId,
      imageSetId, 
      labelEditable:false,
      crop
    }}/>;


  let regionChoiceMode = mode === "regionChoice";


  let sidebar = showSidebar !== false && (<div style={{position:'relative',right:0,width:'30%',
    //border:'3px solid lime',
    maxWidth:280, minWidth:100}}>
    {regionChoiceMode && (
      <div>
        {defaultSidebar}
        <button onClick={() => {
          if( onEvent ){
            onEvent({
              type:"specifiedExpansionNodeRegion",
              annotationId:focusedAnnotationId,
            })
          }
        }} class="full-width btn btn-success" disabled={!focusedAnnotationId}>
            Select Region
          </button>
      </div>
    )}
    {!regionChoiceMode && defaultSidebar}
  </div>);




  return (
    <DrawingContext.Provider value={{
      guidelineType:drawingToolMap[selectedDrawingToolId],
      guidelineSpec,
    }}>
    <div style={{
      display:'flex',flexDirection:'column', width:'100%',
      height:'100%',
      //border:'3px solid red',
    }}>

      <div style={{alignItems:'center'}} class="sg-row">
      {true && <Navbar {...{onSelect:(_id) => setSelectedDrawingToolId(_id),selected:selectedDrawingToolId,spread:false,fontSize:10,options:[
        {
          _id:0,
          tooltip:<DrawOnBandsIcon/>,
          label:"Line Crop",
        },
        {
          _id:1,
          tooltip:<DrawRotatedRectIcon/>,
          label:"Rotated Rectangle Crop",
        },
        /*
        {
          _id:2,
          label:(
            <div style={{alignItems:'center'}} class="sg-row">
              <div>Fixed Ratio Crop </div>
              &nbsp;
              W:
              &nbsp;
              <input onChange={e => setGuidelineSpec({...guidelineSpec,w:Number(e.target.value)})} style={{width:30, fontSize:9, lineHeight:1}} type="number" min={1} defaultValue={1} disabled={selectedDrawingToolId!==2} value={guidelineSpec.w} />
              &nbsp;
              H:
              &nbsp;
              <input onChange={e => setGuidelineSpec({...guidelineSpec,h:Number(e.target.value)})} style={{width:30, fontSize:9, lineHeight:1}} type="number" min={1} defaultValue={1} disabled={selectedDrawingToolId!==2} value={guidelineSpec.h} />
            </div>
          )
        }*/
      ]}}/>}
        <div class="flex-expand"/>

        <ImageAdjustmentContainer {...{imageId, imageAdjustments:{
          ...image.adjustments,
          ...temporaryAdjustments
        },setTemporaryAdjustments}}/>

        
       

        


      </div>
      <div id="annotate-images-container" class="sg-row" style={{borderTop:'1px solid #ccc',position:'relative', flex:1,
        //border:'3px solid orange',
        }}>

        

        <div class="flex-expand" style={{
          position:'relative',background:'rgba(238,238,238)',
          borderRight:'1px solid #ccc',
          //border:'4px solid magenta',
          width:'70%',

        }}>
          <CroppableImage {
            ...{
              atns,
              temporaryAdjustments:{
                ...image.adjustments,
                ...temporaryAdjustments,
              },
              guidelineType:drawingToolMap[selectedDrawingToolId],
              focusedAnnotationId,
              setFocusedAnnotationId,
              notifyAnnotationPropertyChange,
              temporaryAnnotationProperties,
              imgContainerRef, imgRef, src,
              selectedItemIds,
              imageSetId,
              imageId,
              crop
            }}/>

        </div>

        {sidebar}


        

      </div>

      {false && <div style={{position:"absolute",
        top:70,
        left:'40%',
        color:'lime',
        zIndex:100
      }}>{JSON.stringify(temporaryAdjustments)}</div>}



    </div>
    </DrawingContext.Provider>
  );
}


