import { useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import useSciugoForm from './useSciugoForm';
import A from './ActionCreators';

const FIELD_COMPONENT_MAP = {
  textarea:({value,_id,setFormValue}) => (
    <textarea value={value} onChange={e => setFormValue({_id,value:e.target.value})}>
    </textarea>
  ),
  message:({item}) => (
    <div>
      <h4>{item.message}</h4>
    </div>
  ),
}




export default function SciugoForm({name,schema}){

  let dispatch = useDispatch();
  let [form, setFormValue] = useSciugoForm(schema);

  let components = schema.map(item => {
    
    return (
      <div class="fair-margin">
        <div>{item.prompt}</div>
        {FIELD_COMPONENT_MAP[item.type]({
          value:form[item._id],
          _id:item._id,
          setFormValue,
          item
        })}
      </div>
    )
  })

  let continueEnabled = Object.values(form).every(value => value !== '' && value);

  return (
    <div>
      {components}

      <div style={{textAlign:'right', paddingTop:20}}>
          <button 
            surveyItem={"submit"}
            onClick={() => {
              dispatch(A.answerSurvey({
                name:"npsScore",
                answer:form
              }))
            }}
            disabled={!continueEnabled} class="btn btn-primary">Continue</button>
        </div>

      
    </div>
  )





}
