import { unitPerp, add, minus, vectorAngleDegrees } from './Geometry';

const cursorMap = {
  0:'ew-resize',
  1:'nwse-resize',
  2:'ns-resize',
  3:'nesw-resize',
  4:'ew-resize',
  5:'nwse-resize',
  6:'ns-resize',
  7:'nesw-resize'
}

export function getCursorPerpendicularToLs(ls){

  let lsVec = unitPerp(minus(ls[1],ls[0]))
  let perpLs = [ls[0],add(ls[0],lsVec)];
  return getCursorParallelToLs(perpLs); 

}

export function getCursorParallelToLs(ls){

  let lsVec = minus(ls[1],ls[0])
  let lsAngle = vectorAngleDegrees(lsVec) + 360;
  let angleBin = Math.floor(((lsAngle+22.5)%360)/45) 
  let resizeCursor = cursorMap[angleBin];

  return resizeCursor;


}
