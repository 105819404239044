import PanelPreview from './PanelPreview';
import { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { useSelector } from 'react-redux';
import GelAnnotation from './GelAnnotation';
import GelQuantification from './GelQuantification';
import GelFigure from './GelFigure';
import LinearProgress from '@material-ui/core/LinearProgress';
import Spinner from 'react-bootstrap/Spinner'

import GetAppIcon from '@material-ui/icons/GetApp';


const resultOptions = [
  {label:'Results',_id:'results'},
  {label:'Quantification',_id:'quantification'},
  {label:'Annotation',_id:'annotation'}
]

const menuButtonStyle = { fontSize:12,
  background:'green',
  color:'white',
  display:'flex',
  alignItems:'center',
  cursor:'pointer',
  
  position:'absolute',
  top:0,
  left:'50%',
  transform:'translate(-50%,0)'
  //marginLeft:500,
}

function ExportReport(){


  let idle = ( <>
    <GetAppIcon style={{padding:0,marginTop:-5,marginBottom:-5}}/>

    &nbsp; Export Report

  </>)

  


  return (

    <h6>
      <a


      target={"_blank"} 
      href={"http://10.0.0.222:9999/demoPdf"}
      style={menuButtonStyle} 
      class={"small-padding hoverable-green round-corners"}>
      {idle}
      </a>
      
    </h6>

  )

}

export default function GelResults({width, imagesUploaded}){


  const [ selected, setSelected ] =useState("results");
  const [ loadStatus, setLoadStatus ] = useState(null);//"loaded")//null);

  let { gelDemoDataIn } = useSelector(state => state);


  useEffect(() => {
    if( gelDemoDataIn && loadStatus === null ){
      setLoadStatus("loading");
    }
  },[gelDemoDataIn])

  useEffect(() => {
    if( loadStatus === "loading" ){
      setTimeout(
        () => setLoadStatus("done"),
        2000
      )
    }
  },[loadStatus])


  const scene = ({
    results:<GelFigure/>,
    quantification:<GelQuantification/>,
    annotation:<GelAnnotation/>
  })[selected];


  let noData = (
    <div style={{
      background:'#eee',
      alignItems:'center', justifyContent:'center'}} class="sg-row full-height">
      <h3 style={{textAlign:'center'}}>
        Upload images for <br/>
        analysis & figures.
      </h3>




    </div>
  )

  let content;

  if( loadStatus === null ){
    content = noData;
  }
  else if( loadStatus === "loading" ){

    let loading = (
      <div style={{
        background:'#eee',
        alignItems:'center', justifyContent:'center'}} class="sg-col full-height">
        <div>
          <h3>
            Loading...
          </h3>
        </div>

        <div style={{width:500}}>
          <LinearProgress />
        </div>
      </div>
    )
    content = loading;
  }else{

    content = (

      <div style={{
        height:'100%',
        position:'relative',

      }} class="sg-col">
        <div style={{
          position:'relative',
          

          }} class="fair-margin sg-row">
          <h4>Data</h4>

          <ExportReport/>
          

        </div>
        <Navbar selected={selected} onSelect={setSelected} spread={false} options={resultOptions}/>

        <div 
          class="flex-expand"
          style={{
          padding:20,
          //border:'3px solid purple',
          minHeight:0,
          }}>
          {scene}
        </div>
      </div>
    )
  }



  return (
    <div style={{
      //border:'3px solid red',

    }} class="full-width full-height">
      {content}
    </div>
  )









}
