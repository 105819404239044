import { useDispatch } from 'react-redux';
import usePresentSelector from './usePresentSelector';
import useLocalReducer from './useLocalReducer';
import WesternBlotExplorerTable from './WesternBlotExplorerTable';
import PanelPreview from './PanelPreview';
import G from './Getters';



let blotLayoutMap = {
  blot0:null,
  blot1:'sampleLayout0',
}

let tableMockData  = [
  {
    _id:'blot0',
    title:'Random western blot',
    dateModified:(new Date()),
    targetInfo:['Actin','ERK 1/2', 'EGF','EGFR','FGF','Cyclin','CDK3','BART3','PAP10'].map(x => ({_id:x, label:x})),
    loadControl:null,
    samplePreview:null,
  },

  {
    _id:'blot1',
    title:'Another one',
    dateModified:(new Date()),
    targetInfo:[{_id:'t0',label:'Target 1'},{_id:'t1',label:'Target 2'}],
    loadControl:'GAPDH',
    samplePreivewId:'sampleLayout0',
  }

]

const localDefaultState = {
  selectedWesternBlotId:null,
  tableData:tableMockData,
}

export default function WesternBlotExplorerSidebar({width}){
  const [ localState, localDispatch ] = useLocalReducer(localDefaultState);

  let sampleLayoutPreview;
  let sampleLayoutId;

  let selectedWesternBlotId = localState.selectedWesternBlotId;
  if( selectedWesternBlotId ){
    sampleLayoutId = blotLayoutMap[ selectedWesternBlotId ];
  }

  let sampleLayoutFigurePanelId = usePresentSelector(state => {
    if( sampleLayoutId ){
      let dat = G.getData(state,{itemType:"sampleLayouts",_id:sampleLayoutId})
      return dat.figurePanelId
    }else{
      return null;
    }
  })


  if( sampleLayoutId ){
    sampleLayoutPreview = <PanelPreview {...{
      figurePanelId:sampleLayoutFigurePanelId,
      width:0.8 * width
    }}/>
  }else{
    sampleLayoutPreview = (
      <div class="full-height full-width" style={{background:'#ccc',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
      }}>
        {"Western blot has no sample layout. Create one."}
      </div>
    )
  }

  


  return (

    <div class="full-width">
      <div>{width}</div>
      <div>{localState.selectedWesternBlotId}</div>
      


      <div class="thin-horizontal-line"/>

      <div style={{alignItems:'center'}} class="sg-row">
        <div style={{fontSize:24}}>Western blots</div>
        <div class="flex-expand"/>
        <div>
          <button onClick={() => {
            localDispatch({
              tableData:[
                {_id:Math.random(),
                  title:'New western blot',
                  dateModified:Date.now(),
                  targetInfo:[],
                  loadControl:null,
                  samplePreivewId:null
                },...localState.tableData
              ]
            })
          }}>New</button>
        </div>
      </div>


      <div class="thin-horizontal-line"/>

      <div style={{
        //background:'yellow'
      }} class="full-width">
        <WesternBlotExplorerTable {...{
          selectedWesternBlotId
        }} onSelectWesternBlot={(_id) => localDispatch({selectedWesternBlotId:_id})} 
        tableData={localState.tableData}/>

        {localState.selectedWesternBlotId && sampleLayoutPreview}

      </div>
    </div>

  )
}
