import { useReducer } from 'react';

export default function useLocalReducerWithGlobalDispatch(reducer,defaultState,globalDispatch){

  let [ state,localDispatch ] = useReducer(reducer,defaultState);

  const localDispatchWithGlobalDispatch = (args) => {
    //this means all `args` objects 
    //need to be an {} object.
    args.globalDispatch = globalDispatch;
    localDispatch(args);
  }

  return [
    state,
    localDispatchWithGlobalDispatch
  ];


}
