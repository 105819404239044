import Factory from './Factory';
import DefaultMetadata from './DefaultMetadata';

//import metadataArgsAdaptor from './metadataArgsAdaptor';

export default function ItemCreator(state,args){
  //let {dataArgs,metaArgs} = resolveArgsIntoMetaAndDataArgs(state,args);


  let data = Factory(state,args);
  //let metadataArgs = metadataArgsAdaptor(state,args);

  let defaultMetadata = DefaultMetadata(state,args);

  




  let meta = defaultMetadata.meta;
  let error = defaultMetadata.error;

  return {
    data,
    meta,
    error
  }
}
