import DeleteButton from './DeleteButton';

export default function ScalebarTextPositionSetter({ position, textPosition, height, onEvent }){

  const outlineColor = '#ddd';

  if( position !== textPosition ){

    return (
      <div onClick={(e) => {
        onEvent({
          event:e,
          type:"change",
          properties:{
            textPosition:position,
          }
        });
      }} class="clickable dark-hoverable" style={{
        color:outlineColor, 
        border:`2px dashed ${outlineColor}`,
        height,
        width:'60%',
        textAlign:'center',
        fontSize: 8,
      }}>
        {false && <>
          <div>Text</div>
          <div>Here</div>
        </>}
      </div>
    )
  }

  let bDim = 20;

  return (
    <div style={{
      border:'1px solid #aaa',
      
      borderRadius:5,
    }}>
      <div style={{
        position:'relative',
        margin:3,
      }}>
        <div>Text</div>

        <div 
          onClick={(e) => {
            onEvent({
              event:e,
              type:"change",
              properties:{
                textPosition:null
              }
            })
          }}
          class="sg-row clickable" style={{
          alignItems:'center',
          justifyContent:'center',
          position:'absolute',
          top:-7,
          right:-7,
          width:10,
          height:10,
          //border:'1px solid blue',
          background:'#ccc',
          borderRadius:10,
          color:'black',
          lineHeight:0.7,
          textAlign:'center',
          fontSize:10,
        }}>
          ×
        </div>

      </div>
    </div>
  )
}
