import usePresentSelector from './usePresentSelector';
import useLocalReducer from './useLocalReducer';
import { useEffect, useState, useContext } from 'react';
import G from './Getters';


import { useDispatch } from 'react-redux';
import QuantificationActionHeader from './QuantificationActionHeader';

//import createQuantificationMacro from './createQuantificationMacro';

import QuantificationAnnotationContainer from './QuantificationAnnotationContainer';


const loadingControlFeature = false;

//const temporaryLaneSpacingPropertyName=

//export { temporaryLaneSpacingPropertyName }



export default function CropListLaneAdjustmentContainer({selectionInformation, quantificationListDispatch }){

  let dispatch = useDispatch();

  let [ loadingControls, setLoadingControls ] = useState({});



  let [ localState, localDispatch ] = useLocalReducer({
    temporaryLaneSpacing:null
  })

  let {atnQuantInfoList, atns }  = usePresentSelector(state => {

    let ids = Object.keys(selectionInformation);
    let atnQuantInfoList = ids.map(_id => G.getAnnotationQuantificationArgs(state,{_id}));
    let atns = atnQuantInfoList.map(x => x.annotation);
    return { atnQuantInfoList, atns }
      
  });



  let ids = atns.map(x => x._id);
  let maxLaneCount = Math.max(...atns.map(atn => atn.laneBoundaryPositions.length));

  return (
    <div 
      style={{
        overflowY:'scroll',
        
      }}
      
      class="full-height full-width">
      
      {false && <div>{JSON.stringify(ids)}</div>}
      <QuantificationActionHeader {...{
        atnQuantInfoList,
        localState,
        localDispatch,
        ids,
        atns,
        loadingControlFeature
      }}/>
      {atnQuantInfoList.map((atnQuantInfo,ii) => {
        return (
          <div style={{
            width:'100%',
            //border:'3px solid red',
            }} class="sg-row">
          <QuantificationAnnotationContainer 
          key={atnQuantInfo.annotation._id} {...{
          atn:atnQuantInfo.annotation,
          loadingControls,
          setLoadingControls,
          localState,
          maxLaneCount,
          atnQuantInfo,
          loadingControlFeature
        }}/>
          </div>
        )
      })}
    </div>
  )


}
