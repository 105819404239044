import { useRef, useState, useEffect } from 'react';
const dim = 20;


const angleBins = [
  [-90,-67.5],
  [-67.5,-22.5],
  [-22.5,22.5],
  [22.5, 67.5],
  [67.5,90]
]

const binToAngle = [ -90 , -45, 0, 45, 90 ];

function getAngle(dy,dx){

  if( dx === 0 ){
    if( dy > 0 ){ return 90; }
    else if( dy < 0 ){ return -90; }
    else if( dy === 0 ){
      return 0;
    }
    return;
  }else{
    return 180 * Math.atan(dy/dx) / Math.PI;
  }
}

export default function AngleInput({disabled,value, onChange}){

  let angle = value;
  //const [angle, setAngle] = useState(0);

  const [ mouseAngle, setMouseAngle ] = useState(0);
  const [ newishBin, setNewBin ] = useState(-1);

  const [ anglePreview,setAnglePreview] = useState(0);
  const [ currentBin, setCurrentBin ] = useState(binToAngle.findIndex(angle => angle === 0));

  const [ d, setD ] = useState()

  const centerPoint = useRef();



  const mouseMove = (e) => {
    if( centerPoint.current ){
      let x = e.clientX;
      let y = e.clientY;
      let centerPointBounds = centerPoint.current.getBoundingClientRect();
      let dx = -Math.min(0, centerPointBounds.x - x);
      let dy = -(centerPointBounds.y - y);

      let mouseAngle = getAngle(dy,dx);
      setMouseAngle(mouseAngle);

      let newBin = angleBins.findIndex(bin => ((bin[0] <= mouseAngle) && (mouseAngle <= bin[1])));

      //setNewBin(newBin);

      setAnglePreview(binToAngle[ newBin ])


    }
  }

  let enabledStyle = {
    cursor:'pointer',
    position:'relative',
  }

  let disabledStyle = {
    //cursor:'not-allowed',
    position:'relative',
    filter:'grayscale(1)',
    pointerEvents:'none'
  }


  return (

    <div style={disabled?disabledStyle:enabledStyle}>
      {false &&  
        <div style={{position:'absolute',top:0,
          width:'130%',
          height:'150%',left:0,
          //pointerEvents:'none',
          border:'1px solid red',
          zIndex:10,
          transform:'translate(0,-12.5%)',
      }}/>
      }

      {false && <div>{JSON.stringify(d)}</div>}
      {false && <div>{JSON.stringify({anglePreview, mouseAngle, currentBin, newishBin})}</div>}
      {false && <input onChange={e => setAnglePreview(Number(e.target.value))} type="range" min={-90} max={90} value={anglePreview}/>}
      <div 
        onMouseMove={mouseMove}
        onClick={() => onChange(-anglePreview)}
        style={{
          borderRadius:dim,
          width:dim,
          height:dim,
          border:'1px solid black',
          //borderLeft:'1px solid black',
          //background:(active?'pink':'white'),
          position:'relative'
        }}>
        <div style={{
          width:dim/2,height:dim,
          background:'white',
          position:'absolute',
          top:-1,
          left:-1,
          borderRight:'1px solid black',
          borderTop:'1px solid white',
          borderLeft:'1px solid white',
          borderBottom:'1px solid white',


          
          }}
        />




        <div 
          ref={centerPoint}
          onMouseDown={() => {
            //setActive(true);
          }}
          style={{
            cursor:'pointer',
            position:'absolute',
            width:3,
            height:3,
            top:'50%',
            left:'50%',
            transform:'translate(-50%,-50%)',
            background:'blue',
            borderRadius:10
          }}>

          <Pointer {...{color:'#aaa',angle:anglePreview, bodyText:false, pointerHead:7}}/>
          {true && <Pointer {...{color:'blue',angle,zIndex:10, pointerHead:3}}/>}
          {binToAngle.map(binAngle => {
            return <Pointer {...{color:'transparent', showText:false,angle:binAngle }}/>})}

        </div>



      </div>

    </div>
  )
}

function Pointer({angle,color,zIndex,
  showText,bodyText,
  pointerHead,
}){

  const pointerHeight = 1;
  return <div style={{
    zIndex,
    position:'absolute',
    left:1.5,
    top:pointerHeight,
    width:dim/2, 
    height:pointerHeight,
    background:color,
    borderTop:'1px solid '+color,
    transformOrigin:'0 0.5px',
    transform:'rotate('+angle+'deg) ',

  }}>
    {bodyText && <div style={{left:'10%',fontSize:6,
      position:'absolute',
      top:0,
      transform:'translate(0,-70%)',
      lineHeight:0.6,
      //border:'1px solid red',
    }}>
      ABC
    </div>}
    {showText && <div style={{position:'absolute', 
      right:0, 
      top:0,
      fontSize:6,
      //border:'1px solid red',
      transform:'translate(100%,-50%)'
    }}>
      {angle}
    </div>}

    <div style={{
      position:'absolute',
      right:0,
      top:'50%',
      width:pointerHead,
      height:pointerHead,
      background:color,
      transform:'translate(50%,-60%)',
      //border:'1px solid red',
      borderRadius:10,
    }}/>
    


  </div>
}
