import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { executeActionList } from './CustomModal';



export default function CustomModalFooter({buttons,setModal}){

  let dispatch = useDispatch();

  let buttonList = buttons.map(buttonSpec => {
    let { variant, actions, text } = buttonSpec;
    return (
      <Button buttonType={"dialogbutton"} buttonText={text} variant={variant} onClick={() => {
        executeActionList(actions,{dispatch,setModal});
      }}>
        {text} 
      </Button>
    )
  });

  return (
    <Modal.Footer>
      {buttonList}
    </Modal.Footer>
  )

}
