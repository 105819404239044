export default function ExpansionTemplatePlacementCover({
  onClick, readyToPlaceWindow
}){

  return (<div 

        onClick={onClick}

        style={{
          position:'absolute',
          top:0,
          left:0,
          padding:5,
          border:'1px solid red',
          background:'rgba(255,255,255,0.4)',

          cursor:(readyToPlaceWindow?'pointer':'unset'),

        }} class="full-width full-height">

        <div style={{
          position:'absolute',
          top:5,
          left:5,
          width:'calc(100% - 10px)',
          height:'calc(100% - 10px)',
          border:'2px dashed #ccc',
          background:'rgba(255,255,255,0.4)',

        }} class="sg-row h-center v-center">
        </div>
      </div>
      )
}
