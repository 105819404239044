import RawImagesFigureView from './RawImagesFigureView';

export default function GelAnnotation({}){


  return (
    <div style={{
      width:'100%',
      height:'100%',
      minHeight:0,
      overflowY:'scroll',
    }}>
      <RawImagesFigureView {...{
        figurePanelId:"fp0",

      }}/>
    </div>
  )
}
