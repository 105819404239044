import usePresentSelector from './usePresentSelector';
import GetAppIcon from '@material-ui/icons/GetApp';
import A from './ActionCreators';
import SvgGrid from './SvgGrid';
import { useDispatch, useStore } from 'react-redux';
import { useState } from 'react';

import getImageUrlMap from './getImageUrlMap';

import Spinner from 'react-bootstrap/Spinner'

import { ErrorBoundary } from 'react-error-boundary';

export default function ExportButton({setSvg,color, figurePanelId, button}){

  if( !figurePanelId ){
    throw Error("ExportButton must be passed 'figurePanelId'.");
  }


  let dispatch = useDispatch();

  const [downloadingFigure,setDownloadingFigure] = useState(false);

  const [imageUrlMap, setImageUrlMap] = useState(null);

  const notifyFinishedDownload = () => {
    setImageUrlMap(null);
    setDownloadingFigure(false);
  }

  const store = useStore();


  let menuButtonStyle = { fontSize:12,
    background:color,
    color:(color?'white':'unset'),
    display:'flex',
    alignItems:'center'
  }


  return (

    <h6 
      style={menuButtonStyle} 
      class={"small-padding hoverable-green round-corners tiny-margin"}
      button={button}



      onClick={
        ()=>{
          /*htmlToImage.toSvg(document.getElementById('figure'))
  .then(function (dataUrl) {
      var link = document.createElement('a');
    link.download = 'my-image-name.svg';
    link.href = dataUrl;
    link.click();
  });*/

          try{
            setDownloadingFigure(true)
            getImageUrlMap(store.getState(),{figurePanelId}).then(setImageUrlMap);
            dispatch(A.exportFigure({format:'svg',figurePanelId}))
          }catch(error){
            setDownloadingFigure(false);

            dispatch(A.exportFigureFailed({
              message:JSON.stringify([
                error.message, error.stack
              ])
            }))

          }

        }}>
      {downloadingFigure && imageUrlMap && (
        <div style={{display:'none'}}>
          <ErrorBoundary onError={(error,info)=>{
            setDownloadingFigure(false);
            dispatch(A.exportFigureFailed({
              message:JSON.stringify([ error, info ])
            }))
            

          }}
            FallbackComponent={() => <div/>}
        fallbackRender={({error,resetErrorBoundary,componentStack}) => {

            debugger;
            return (
              <div/>
            )
          }}>

            {downloadingFigure && <SvgGrid 
              figurePanelId={figurePanelId}
              download={true}
              setSvg={setSvg}
              state={store.getState()}
              imageUrlMap={imageUrlMap}
              notifyFinishedDownload={notifyFinishedDownload}
            />}
          </ErrorBoundary>
        </div>
      )}

      {!downloadingFigure && (
        <>
          <GetAppIcon style={{padding:0,marginTop:-5,marginBottom:-5}}/>
          <span>&nbsp;Export Figure</span>
        </>
      )}
      {downloadingFigure && (
        <Spinner
          style={{height:20,width:20}}
          animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
    </h6>
  )

}
