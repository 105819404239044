import usePresentSelector from './usePresentSelector';
import { useContext, useState,useEffect, useLayoutEffect, useRef, createRef } from 'react';

import ImpactedColumnsOnGridResizeHint from './ImpactedColumnsOnGridResizeHint';

import CellWidthResizer from './CellWidthResizer';
import RenderedValue from './RenderedValue';
import G from './Getters';
import useLocalReducer from './useLocalReducer';
import GridCellBorder from './GridCellBorder';
import { FigureInteractionContext } from './Contexts';

import { 
	GridBoundaryAdjustmentContext, 
	ColumnResizeContext, 
	CropAdjustmentContext,
	GridCellContext,
} from './Contexts';




export default function GridCell({
	displayPlaceholder,
	editable,
	verticalImageResizeTranslation,
	manualAnnotationGridDispatch,
	figurePanelId,
	textBounds,
	rotatedRectHeight,
	angle,
	isDraggingCrop,
	setCropIdsBeingDragged,
	contents,selected,
	temporaryCellValue,expandImage,_id, pxWidth,
	cellLocation,
	makeCellSelection,
	isCellSelected,
	computedStyle,
	cell,
	tableOutlineShowing,
	setDontUnselectCells,
	setMouseIsDown,
	cellDragEntered,
	selectedCells,
	setCellDragEntered,
	onCropDrop,
	setCellResizeInfo,
	setCurrentWidths,
	cellResizeInfo,
	currentWidths,
	widths,
}){




	if( !figurePanelId ){
		throw Error("GridCell was not given a figurePanelId.");
	}

	let selectedCell = usePresentSelector(state => {
		return G.getCellsValue(state,{cells:[[cell.y,cell.x]], figurePanelId});
	})

	////console.log("GridCell " + JSON.stringify(cell));

	const tdRef = useRef(null);


	const {onFigureInteractionEvent} = useContext(FigureInteractionContext); 

	
	

	const gridBoundaryAdjustmentContext = useContext(GridBoundaryAdjustmentContext);

	const cropAdjustmentContext = useContext(CropAdjustmentContext);

	let { cropResizeInfo } = cropAdjustmentContext || {};

	const { adjustmentSide } = gridBoundaryAdjustmentContext || {};

	let noCellIsBeingResized = !cellResizeInfo;
	let thisCellIsBeingResized = cellResizeInfo && (
		cellResizeInfo.cell[0] === cell.y
		&&
		cellResizeInfo.cell[1] === cell.x
	);
	let anotherCellIsntBeingResized = noCellIsBeingResized || thisCellIsBeingResized;

	const canResizeCellWidths = (
		!cropResizeInfo &&
		anotherCellIsntBeingResized &&
		gridBoundaryAdjustmentContext && 
		tableOutlineShowing && !(['top','bottom'].includes(adjustmentSide))
	)

	if(cellResizeInfo && (cellResizeInfo.cellX === cell.x) ){
		//debugger;
	}


	const isAdjustingCropHorizontally = ['left','right'].includes(adjustmentSide); 


	const [tdBounds,setTdBounds] = useState({});

	let thisCellIsDragEntered = editable!==false && cell.y === cellDragEntered[0] && cell.x === cellDragEntered[1];


	let [ localState, localDispatch ] = useLocalReducer();

	useEffect(() => { 
		//let newBounds = ref.current.getBoundingClientRect();
		if( tdRef && tdRef.current ){
			let newTdBounds = tdRef.current.getBoundingClientRect();
			setTdBounds(newTdBounds);
		}
		//setBounds(newBounds);
	}, [tdRef,angle]);


	let { value  } = contents;

	if( selected && temporaryCellValue !== null && temporaryCellValue !== undefined ){
		value = temporaryCellValue;
	}



	let { bottomBorderWidth, topBorderWidth, leftBorderHeight, rightBorderHeight, borderBottom, borderLeft, borderRight, borderTop, bottomBracketHeight, leftBracketWidth, rightBracketWidth, topBracketHeight, ...borderlessStyle } = computedStyle;



	let valueToRender = value;
	if( displayPlaceholder ){
		valueToRender = contents.placeholder;
	}
	/*
	if( !valueToRender && contents.placeholder  ){
		const isPlaceholder = Object.values(userDefinedStyle).length === 0 && !value;
		if( isPlaceholder ){
			valueToRender = contents.placeholder;
			borderlessStyle = placeholderStyle();
		}
	}

	debugger;

*/

	if( value === undefined ){
		debugger;
	}
	if( value.valueType === 'bandAnnotation' ){
		valueToRender = contents;
	}

	

	


	const canAdjustImagesInGrid = false;

	let renderedValue = (
		<RenderedValue {...{
			onCellInteractionEvent:(eventObject) => {
				let bubbledObject = {
					...eventObject,
					cell,
					cellLocation
				}

				if( onFigureInteractionEvent ){
					onFigureInteractionEvent(bubbledObject)
				}

			},
			editable,
			isAdjustingCropVertically:['top','bottom'].includes(cropResizeInfo?.side),
			verticalImageResizeTranslation,
			tableOutlineShowing,
			manualAnnotationGridDispatch,
			figurePanelId,
			textBounds,
			rotatedRectHeight,
			value:valueToRender,
			width:pxWidth,
			expandImage,
			gridCellId:_id,
			cellLocation,
			tdBounds, 
			tdRef,
			cell,
		}}/>
	)


	
	//row spacer above (from rotation), row spacer below (from rotation) and general row spacing
	let numberOfSpacerRowsBetweenEachFigureRow = 3 + 2 * isDraggingCrop;

	let rowSpan = cell.h;
	if( cell.h > 1 ){
		rowSpan = cell.h * (1 + numberOfSpacerRowsBetweenEachFigureRow) - 2 ; // minus the row spacer at the end.
	}


	let tdStyle = {
		...borderlessStyle,
		minWidth:(currentWidths?currentWidths[cell.x]:widths[cell.x]),
		width:pxWidth,
		

		//width:currentWidths?currentWidths[cell.x]:widths[cell.x]
		background:'white'
	}

	if( cropResizeInfo ){
		tdStyle.pointerEvents = 'none';
	}

	return (

		<GridCellContext.Provider value={{cellLocation}}>

			<td 
				valueType={value.valueType}
				objecttype="GridCell"
				imageCrop={value.valueType === "crop"}
				cell={cell.y+'-'+cell.x}
				ref={tdRef}
				id={"grid-"+cell.y+"-"+cell.x}

				onMouseEnter={e => {
					////console.log("GridCell ("+cell.y+"-"+cell.x+") - on mouse enter.");
					if( isDraggingCrop ){
						setCellDragEntered([
							cell.y,cell.x
						])
					}
				}}
				onMouseUp={e => {
					if( isDraggingCrop ){
						onCropDrop([cell.y,cell.x])
						setCellDragEntered([-1,-1])
						setCropIdsBeingDragged([]);
						return;
					}

					if( cropResizeInfo ){
						console.log("CROP RESIZE INFO IS ON!");
						//e.preventDefault();
						//e.stopPropagation();
					}

				}}


				onClick={e=>{


					if( isDraggingCrop ){

						////console.log("Clicked cell, but dragging a crop.");
						onCropDrop([cell.y,cell.x])
						setCellDragEntered([-1,-1])
						setCropIdsBeingDragged([]);
						return;

					}

					e.stopPropagation();
					if( !cropResizeInfo ){
						makeCellSelection([[cell.y,cell.x]],e.shiftKey)
					}
				}}

				class={ editable!==false &&	(( isCellSelected([cell.y,cell.x],selectedCells) && !isDraggingCrop)
					|| 
					(cell.y===cellDragEntered[0] && cell.x === cellDragEntered[1]))
						&&
						"selected" /*|| "hoverable"*/
				}
				style={tdStyle}
				rowSpan={rowSpan}
				colSpan={cell.w}>

				{false && <div class="selected-item-cover"/>}

				{tableOutlineShowing && <div 
					sciugorole="table-outline"
					style={{
						//pointerEvents:'none',
						position:'absolute',top:0,left:0,width:'100%',height:'100%',
						borderTop:'0.5px solid #ccc',
						borderBottom:'0.5px solid #ccc',
						borderLeft:'0.25px solid #ccc',
						borderRight:'0.25px solid #ccc',
						pointerEvents:'none',
						//zIndex:10000
					}}/>}


				{renderedValue}

				{selected && ['crop','image'].includes(value.valueType) && 
					<>
						<div class="selected-item-cover"

							//style={{width:'100%',height:'100%',background:'#33aaff', opacity:0.5,position:'absolute',top:0,}}

							//pointerEvents:'none',
						>
						</div>


						{canAdjustImagesInGrid && <div 
							onClick={e => {
								////console.log("Can adjust images in grid -");
								e.stopPropagation();

							}}
							style={{cursor:'pointer',borderRadius:5,border:'2px solid white', background:'black',opacity:0.6,position:'absolute',right:0,bottom:0,color:'white', fontSize:10, padding:2}}>

						</div>
						}
					</>}


				


				<GridCellBorder {...{ computedStyle , cell:cellLocation }}/>




				{canResizeCellWidths && <CellWidthResizer {...{
					figurePanelId,
					localDispatch,

					onMouseEnter:() => {
						//localDispatch({enteredAdjustmentBar:true})
					},
					onMouseLeave:() => {
						//localDispatch({enteredAdjustmentBar:false});
					},
					cell,
					cellResizeInfo,
					setCellResizeInfo,
					setDontUnselectCells,
					setMouseIsDown,
					setCurrentWidths,
					widths
				}}/>}

				{thisCellIsDragEntered && <div 
					sciugorole="drag-entered-cell"
					style={{
						position:'absolute',top:0,left:0,
						width:'100%',
						height:'100%',
						background:'#a6d3f7',
						pointerEvents:'none',
						opacity:0.6,
					}}
				/>}


				{editable!==false && 
					<ImpactedColumnsOnGridResizeHint {...{
						cell,
						width:pxWidth,
						value
					}}/>}


				{(value.valueType === 'crop' && (cropResizeInfo || isAdjustingCropHorizontally)) && (
					<div class="sg-row" style={{position:'absolute',top:0,left:0,width:'100%',height:'100%',pointerEvents:'none', zIndex:50}}>
						{(currentWidths||widths).slice(cell.x,cell.x+cell.w).map(w => <div style={{height:'100%',borderRight:'1px solid black', width:w}}/>)}
					</div>
				)}

						
			</td>

		</GridCellContext.Provider>

	)

}

