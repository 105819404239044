import FolderIcon from '@material-ui/icons/Folder';
export default function MinimizedFilesystemContainer({setShowFilesystem}){

  return (
      <div style={{
        width:50, 
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        zIndex:100,
        position:'relative',
        height:'100%', 
        borderRight:'1px solid #ccc',
        boxShadow:'0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',


        }}>

        <div onClick={() => setShowFilesystem(true)}
      style={{paddingTop:10}}>
        <FolderIcon style={{
          cursor:'pointer',
          //padding:10,
          color:'#ddd',
        }}/>
        </div>


        

        <div 
          onClick={() => setShowFilesystem(true)}

          class="small-padding hoverable"
    style={{
      cursor:'pointer',
          position:'absolute',
          top:'50%',
          left:'50%',
          transform:'translate(-50%,-50%) rotate(-90deg)',
          display:'flex',
          //background:'green',
          whiteSpace:'nowrap',
          textOverflow:'nowrap',



        }}>
        Show Filesystem
      </div>
      </div>
    )
}
