import { useState, useEffect } from 'react';


const Countdown = ({ targetDate, completionMessage }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => time.toString().padStart(2, '0');


  const { days, hours, minutes, seconds } = timeLeft;

  let h = 0;

  return (
    <div class="sg-row">
      {days > 0 && <div>{days} days&nbsp;</div>}
      {hours >= 0 && <div>{formatTime(hours)}:</div>}
      {minutes >= 0 && <div>{formatTime(minutes)}:</div>}
      {seconds >= 0 && <div>{formatTime(seconds)}</div>}
      {Object.keys(timeLeft).length === 0 && <div>Countdown finished!</div>}
    </div>
  );
};

export default Countdown;
