export default function ReferralPromotionInfo(){
  return (
    <div class="text-body">
      <h3 style={{fontWeight:'bold'}}>Invite 3 researchers, get a month free!</h3>

      <br/>
      <div>
        <ol>
          <li>
            Have them use your account email in the signup referral field.
          </li>
          <li>
            We will validate their email and their account. 
          </li>
          <li>

            The validation process can take 1-2 weeks.
          </li>
        </ol>
    </div>
    </div>
  )
}
