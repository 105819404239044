import { diff } from 'jsondiffpatch';
import ActionCreators from './ActionCreators';
import G from './Getters';

const middleware = api => next => action => {

  let { getState, dispatch } = api;

  let prevState = getState();

  let processedNext = next(action);

  let nextState = getState();

  let stateDiff = diff(prevState,nextState);


  /*
  let oldAtns = prevState.data.annotations;
  let newAtns = nextState.data.annotations;
  if( oldAtns !== newAtns ){
    debugger;
  }
  */

  if( stateDiff ){

    let { data } = stateDiff;
    if( data ){
      let { annotations } = data;
      if( annotations ){

        for(let _id in annotations ){
          let atn = annotations[_id];
          let boundaryChange = atn.laneBoundaryPositions;

          let quantChanges = atn.quantifications;
          let integrationRangesChange = false;

         
          let changeToSingleIndex = !Array.isArray(quantChanges)
          if( quantChanges && changeToSingleIndex ){ 
            let quantChangeValues = Object.values(quantChanges);
            //change to quant that we care about 
            //is only the integration range

            
            integrationRangesChange = quantChangeValues.some(change => change.integrationRanges);
          }
          //let quantDiffKeys = Object.keys(atn.quantifications);
          //debugger;
          //let quantKeyLength = quantDiffKeys.length;
          //let onlyIntegrationRanges =  !!integrationRanges && !boundaryChange;

          //let onlyIntegrationRangesChanged = integrationRanges && onlyIntegrationRanges;

          if( boundaryChange || !!integrationRangesChange ){
            let wasPreviouslyQuantified = G.getAnnotation(prevState,{_id}).quantifications;

            if( wasPreviouslyQuantified ){

              let startQuant = ActionCreators.startQuantification({
                source:"onQuantificationParametersChange",
                annotationIds:[_id],
                config:{force:true}
              })


              let dispatchPromise = dispatch(startQuant);
              return dispatchPromise;
            }

            //return dispatch(startQuant);

          }
        }


      }
    }
  }

  return processedNext;


}

export default middleware;
