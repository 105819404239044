import { useContext } from 'react';
import { ColumnResizeContext } from './Contexts';

export default function ImpactedColumnsOnGridResizeHint({cell,width,value}){

  const { columnResizeInfo } = useContext(ColumnResizeContext);

  let { impactedRanges, conservedWidthRange } = columnResizeInfo || {};
  let {x,y,w} = cell;


  

  if( conservedWidthRange ){

    let conservedWidthRangeLength = conservedWidthRange[1] - conservedWidthRange[0];

    if( conservedWidthRange && conservedWidthRange[0] === x && w === conservedWidthRangeLength){
      return null;
    }

    if( conservedWidthRangeLength < w ){
      return null;
    }
  }

  if( impactedRanges?.length > 0 ){
    


    
    let indexOfColumnInRange = impactedRanges.findIndex(range => range[0] <= x && x < range[1]);

    let hintColorMap = [
      "rgba(0,0,255,0.1)",
      "rgba(0,255,0,0.1)",
    ]

    let background = hintColorMap[indexOfColumnInRange];

    if( background ){
      return (<div style={{
        pointerEvents:'none',
          top:0,
          left:0,
          width,
          height:'100%',
          background,
          position:'absolute',
      }}>
        </div>)
    }


  }

  return null;


}
