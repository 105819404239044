import usePresentSelector from './usePresentSelector';

import { argmin } from './utils';

import { scale, distance,
  add,
  unitPerp,
  vectorAngleRad,
  minus,
  rotatePointsAboutCentroid, 
  getPointsAsWidthProportions, 
  getPointsFromProportions,
  vectorAngleDegrees
} from './Geometry';
import { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import G from './Getters';
import { v4 } from 'uuid';
import LineSegment from './LineSegment';

import { DrawingContext } from './Contexts';

const __custom = "__custom";

const MIN_PIXEL_LENGTH_FOR_LS = 30;

export default function GuidelineDrawingLayer({
  imageViewTransformation,
  imageSetHeight,

  interactive,
  defaultCropHeight, 
  heightOptionHovered, 
  hoveredHeight, 
  imageSetId,
  imgContainerBounds, 
  imgRefBounds, 
  selectedHeight,
  setFocusedAnnotationId,
  focusedAnnotation,
  focusedAnnotationId,
  imgRef, setPopoverLocation,
  heightGroupList,
  defaultCropHeightGroupId,
  setDrawing,
  //guidelineType
}){

  if( !imageSetId ){
    throw Error("imageSetId undefined!");
  }

  //guidelineType = 'box';
  


  let drawingContext = useContext(DrawingContext);
  let { guidelineType, guidelineSpec } = drawingContext || {}
  let dispatch = useDispatch();

  let useLineHintByDefault = usePresentSelector(state => state.useLineHintByDefault);


  let [points,setPoints] = useState({start:null,end:null,settingEndpoint:false});
  let [mousePosition,setMousePosition] = useState([200,50]); 

  //const [shiftDown, setShiftDown] = useState(false);
  const shiftDown = false;

  const onKeyDown = e => { 
    //setShiftDown(e.key==='Shift'); 
    if( e.key === 'Escape' ){
      setPoints({start:null,end:null,settingEndpoint:false})
    }
  }
  const onKeyUp = e => {
    if( e.key === 'Shift' ){ 
      //setShiftDown(false) 
    }


  }


  useEffect(() => {
    window.addEventListener('keydown',onKeyDown);
    window.addEventListener('keyup',onKeyUp);
    return () => {
      window.removeEventListener('keydown',onKeyDown);
      window.removeEventListener('keyup',onKeyUp);

    }
  })


  let w = imgRefBounds.width;
  let invW = 1/w;

  let defaultCustomHeight = 30 * invW;

  let guidelineIsBox = guidelineType === 'box';
  let guidelineIsFixedRatio = guidelineType === 'fixedRatio';

  let ls;
  if( guidelineIsBox || points.settingEndpoint ){
    ls = points.start && [points.start,points.end].map(scale(invW))
  }


  let guidelines = usePresentSelector(state => {

    let modifier = shiftDown;
    
    let lsDistance = ls && distance(minus(...ls));
    if( lsDistance > 0.001 && useLineHintByDefault ){
      modifier = !shiftDown;
    }
      return G.getGuidelines(state,
        {imageSetId,
          mousePosition,
          ls,
          modifier,
          guidelineType
          
        })
    
  });

  //let onlyDrawingGuidelines = guidelines.filter(x => x.choice);

  ////console.log("--- guidelines --- :: " + JSON.stringify(guidelines));

  let guidelineComponents;

  

  if( imgRefBounds.width ){



    let strokeColor;

    if( points.start && points.end ){

    
      let lsPixelDistance = distance(points.start,points.end);
    
      strokeColor = lsPixelDistance >= MIN_PIXEL_LENGTH_FOR_LS ? "blue" : "red";
    }



      guidelineComponents = guidelines.map(atn => (
        <g style={{pointerEvents:'none'}}>
          <LineSegment {...{
            ...(drawingContext||{})
          }} strokeColor={strokeColor} defaultCropHeight={defaultCropHeight} key={atn._id} annotation={atn} imgWidth={imgRefBounds.width}/>
        </g>
      )
      )
  }

  

  let shiftPointer = 'pointer';
  let cursor = shiftDown ? shiftPointer : 'crosshair';





  return (
    <g style={{cursor:'crosshair'}}>

      {false && <text fill={"lime"} x={0} y={0}>{guidelineType}</text>}

      {false && <text fill={"red"} x={0} y={50}>{JSON.stringify(points,null,1)}</text>}

      {/*rawMousePosition && <circle style={{position:'fixed'}} {...{cx:rawMousePosition[0],cy:rawMousePosition[1],r:2,fill:"lime",stroke:"blue"}}/>*/}

      <rect x={0} y={0}  stroke={"lime"} strokeWidth={3} 
        width={imgRefBounds.width} 
        height={imgRefBounds.height} 
        style={{
          fill:'white',
          opacity:0,
          cursor:(focusedAnnotationId ? 'auto' : 'crosshair')
        /*border:'1px solid lime', fill:'red', fillOpacity:0.1*/
        }}


      onMouseDown={e => {



        if(window.someComponentIsDragging){return;}

        if( focusedAnnotationId ){

          setFocusedAnnotationId();
          return;

        }


        //console.log("Mouse is down :)");

        

        let point = [
          e.clientX - imgContainerBounds.left,
          e.clientY - imgContainerBounds.top
        ];
        let startRelToImage = [
          e.clientX - imgRefBounds.left,
          e.clientY - imgRefBounds.top
        ]
        if( !points.start && points.settingEndpoint !== true ){
          setPoints({
            start:point,
            startRelToImage,
            endRelToImage:startRelToImage,
            end:point,
            settingEndpoint:true
          })
        }

        setDrawing(true)

      }}

      onMouseUp={e => {

        if( 
          //window.__mouseDown || 
          window.someComponentIsDragging 
        ){ 
          return; 
        }

        setPoints({
          ...points,
          settingEndpoint:false
        })


        let choice = guidelines.find(g => g.choice);

        let isCrop = choice && 'ls' in choice;
        let annotationType = (isCrop?'ls':'mark');

        if( !points.settingEndpoint && guidelineIsBox ){
          let rectGuide = guidelines.find(guide => guide.rect);
          if(rectGuide){
            let atnId = v4();
            let rect = rectGuide.rect;


            let ls0 = [
              scale(0.5,add(rect[0],rect[3])),
              scale(0.5,add(rect[2],rect[1]))
            ]


            let ls1 = [
              scale(0.5,add(rect[0],rect[1])),
              scale(0.5,add(rect[2],rect[3]))
            ]

            let lsPossibilities = [ls0,ls1]
            let angles = lsPossibilities.map(ls => Math.abs(vectorAngleDegrees(minus(...ls))))

            let chosenLsIndex = argmin(angles)

            let ls = lsPossibilities[chosenLsIndex];



            let height = distance(lsPossibilities[(chosenLsIndex+1)%2]);

            let rectHasArea = lsPossibilities.map(ls => distance(...ls)).every(dist => dist > 0)
            if( rectHasArea ){

              //make sure there's something goin on here.

          
            

              let transformedArgs = {
              _id:atnId,
              imageSetId,
              label:"",
              annotationType:"ls",
              rect,
              height,
              heightGroupId:defaultCropHeightGroupId,
              ls,
            }

              let absoluteArgs = imageViewTransformation ? G.getAbsoluteLsGeometry(null,{transformedGeometry:{ls, height}, transformation:imageViewTransformation,imageSetHeight}) : {}

              let resolvedArgs = {
                ...transformedArgs,
                ...absoluteArgs
              }


              
            dispatch(A.addAnnotation(resolvedArgs))
              setPoints({start:null,end:null,settingEndpoint:null});
            }else{
              //debugger;
            }
          }
        }

       
        

        if( choice ){

          let canAdd = false;
          if( annotationType === 'ls' ){
            let pixelLength = distance(points.start,points.end)
            canAdd = pixelLength >= MIN_PIXEL_LENGTH_FOR_LS;

          }else{
            canAdd = true;
          }


          
          let atnId = v4();
          if( canAdd ){


            debugger;

            let absoluteLsGeometry = {};

            let height = (defaultCropHeightGroupId === __custom ? defaultCustomHeight : undefined);


            if( annotationType === "ls" ){
              absoluteLsGeometry = {
                ...(imageViewTransformation ? G.getAbsoluteLsGeometry(null,{transformedGeometry:{ls, height}, transformation:imageViewTransformation,imageSetHeight}) : {})
              }
            }

            
            dispatch(A.addAnnotation({
              _id:atnId,
              imageSetId,
              label:"",
              annotationType,
              [annotationType]:choice[annotationType],
              heightGroupId:defaultCropHeightGroupId,
              height,
              ...absoluteLsGeometry
            }))
            setFocusedAnnotationId(atnId);
          }
          setPoints({start:null,end:null,settingEndpoint:null});

          
        }

        if( isCrop ){
          setPopoverLocation({top:e.clientY,left:e.clientX});
        }

        setDrawing(false)


      }}

      onMouseMove={e => {
        /*
         * Be careful if the pointer doesn't line up properly,
         * it's probably because there's some border above it
         * which is pushing it offline.
         **/

        //console.log([e.clientX,e.clientY]);
        if(window.someComponentIsDragging){
          return;
        }
        

        let newMousePosition = [
          (e.clientX - imgContainerBounds.left) / imgContainerBounds.width,
          (e.clientY - imgContainerBounds.top) / imgContainerBounds.width
        ]

        let point, endRelToImage, rotation, degRad;

        let end = [
          e.clientX - imgContainerBounds.left,
          e.clientY - imgContainerBounds.top 
        ]

        if( points.start !== null && points.settingEndpoint === true ){


          endRelToImage = [
            e.clientX - imgRefBounds.left,
            e.clientY - imgRefBounds.top
          ];

          let deltaY = points.start[1] - points.end[1];
          let deltaX = points.start[0] - points.end[0];
          degRad = Math.atan(deltaY/deltaX);
          let newRotation = (rotation||0) + degRad * 180 / Math.PI

          setPoints({
            ...points,
            end,
            endRelToImage,
            rotation:degRad,
          })

        }

        setMousePosition(newMousePosition);

      }}
    />



      {false && <text fill={"magenta"} style={{color:'magenta'}} x={0} y={0} dominantBaseline={"hanging"} height={20}>{JSON.stringify(selectedHeight)}</text>}

    
      {guidelineComponents}

      
    
        </g>

  )
}
