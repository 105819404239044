import { useState, useEffect } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import usePresentSelector from './usePresentSelector';

import G from './Getters';
import A from './ActionCreators';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';

import SupportSigninButton from './SupportSigninButton';
import FETCH from 'cross-fetch';


const ErrorMessage = ({msg,scene,messageType}) => (

  <div messagetype={messageType} form={scene} formComponentType={"error-message"} style={{color:'red', fontSize:18}} class="bold">{msg}</div>

)

function notifyServerOfSignup(form,scene,route,setResponses){

  let headers = {
    'Content-Type':'application/json;charset=utf-8',
  }


  let body = JSON.stringify(
    {
      notifyMeInfo:{
        address:form.email,
        name:form.name,
        firstName:form.firstName,
        lastName:form.lastName,
        figureBuilder:true,
        scene
      },
      route,
      scene
    }
  )

  let shouldSendNotifyMeInfo = true;
  if( process.env.NODE_ENV !== 'production'){
    shouldSendNotifyMeInfo = false;
    if( !!navigator.webdriver && window.__testMeta && window.__testMeta.shouldSendNotifyMeInfo ){
      shouldSendNotifyMeInfo = true;
    }
  }

  if( shouldSendNotifyMeInfo ){

    //this was slowing down tests too much...
    //so we needed to make this conditional on actually wanting the email to be sent for some test..
    return FETCH('/sendNotifyMeInfo', {
      mode:'no-cors',
      method:'POST',
      headers,
      body,
    }).then(resp => {
      if( scene === 'forgot' ){
        setResponses({
          client:'We will contact you shortly.'
        })
      }
    }).catch(console.error);
  }

}

const placeholders = {
  code:'Code',
  email:'Email',
  firstName:'First name',
  lastName:'Last name',
  password:'Password',
  newPassword:'New password',
  confirmPassword:'Confirm password',
  signupReference:'Who referred you? (email/login)',
}

const typeMap = {
  firstName:'text',
  lastName:'text',
  newPassword:'password',
  confirmPassword:'password',
  signupReference:'email',
}

function Field({field,form,setForm,scene}){


  let autoComplete = ({
    code:"off",
    password:(scene === "changePassword" ? 'new-password' : 'current-password'),
    confirmPassword:"new-password",
  })[field]

  return (<div class="form-field small-margin flex-expand">
    <input 
      key={field}
      form={scene}
      formComponentType={"field"}
      field={field}
      onChange={e=>setForm({
      ...form,
      [field]:e.target.value
    })}
      required={true}
      autoComplete={autoComplete}
      class="form-control" 
      type={typeMap[field]||field} 
      placeholder={form.placeholders?.[field] || placeholders[field]} 
      value={form[field]}
    />
  </div>)

}

const FormLine = ({scene,fieldNameData,form,setForm}) => {

  return (
    <div class="sg-row">
      {[fieldNameData].flat().map(field => <Field {...{
        key:field,
        scene,
        field,form,setForm
      }}/>)}
    </div>
  )

}

const findFormInvalidity = (form,scene) => {


  

  let formLines = forms[scene].formLines.flat();
  let { optional } = forms[scene];
  let requiredFormLines = formLines;
  if( optional ){

    requiredFormLines = formLines.filter(line => !optional.has(line));
  }



  let emptyField = (requiredFormLines.find(field => {
    return !form[field] || form[field].length === 0
  }))





  if( emptyField ){



    let formattedFieldName = emptyField.replace(/[A-Z]/g,(m) => ' ' + m.toLowerCase()).replace(/^./,(m) => m.toUpperCase()) 



    return (formattedFieldName + " required.");
  }

  if( 'password' in form && 'confirmPassword' in form ){
    let pw = form.password;
    let cpw = form.confirmPassword;
    if( pw !== cpw ){
      return "Passwords do not match.";
    }
  }


}


const createEmptyForm = (scene) => {
  return Object.fromEntries(
    forms[scene].formLines.flat().map(key => ([key,'']))
  )
  
}


function Form({scene,setScene}){


  const dispatch = useDispatch();

  let [form,setForm] = useState(createEmptyForm(scene));
  let [ responses, setResponses ] = useState({});

  let [ succesfulSignup, setSuccessfulSignup ] = useState(false);
  let isProcessing = usePresentSelector(state => !!state.loginInfo.authPending)
  let [loginAttempt, setLoginAttempt] = useState();


  let route = usePresentSelector(state => state.route || 'guest');

  

  let { lastAuthAttemptId, serverResponseMessage } = usePresentSelector(state => {
    let lastAuthAttemptId = G.getAuthAttemptId(state)
    let serverResponseMessage = G.getAuthResponseMessage(state)
    return { lastAuthAttemptId, serverResponseMessage };
  });

  useEffect(() => {

    if( loginAttempt !== lastAuthAttemptId ){
      setLoginAttempt(lastAuthAttemptId);
      setResponses({server:serverResponseMessage});
    }
  })

  if( succesfulSignup ){
    return null;//signupText;
  }

  if( responses.server ){
    debugger;
  }




  let errorMessage = (loginAttempt && responses.server) || responses.client;

  let messageType = Object.keys(responses).find(x => x);


  return (

    <div class="tiny-padding" style={{
      textAlign:'center',
      width:'100%',
      }}>


      {errorMessage && <ErrorMessage {...{messageType, msg:errorMessage,scene}}/>}

      {forms[scene].formLines.map( fieldNameData => (
        <FormLine {...{scene,fieldNameData,form,setForm}}/>
      ))}

      <div style={{color:'red',fontWeight:'bold'}}>

      </div>

      <div class="small-margin-left small-margin-right small-margin-top">
        <button 
          buttonDisabled={Boolean(isProcessing)+""}
          disabled={isProcessing}
          form={scene}
          formComponentType={"submit"}
          style={{
          }}

          onClick={ () => {

            let formInvalidity = findFormInvalidity(form,scene);
            if( formInvalidity ){
              setResponses({
                client:formInvalidity
              });
            }else{

              setResponses({});

              let actionMap = {
                signin:'tryLogin',
                signup:'trySignup',
                forgot:'requestPasswordRecoveryCode',
                changePassword:'changePassword',
              }

              let nextSceneMap = {
                forgot:'changePassword',
              }

              let actionName = actionMap[scene];

              //setIsProcessing(true);


              if( true || process.env.NODE_ENV === 'production'){
                if( scene === 'signup' || scene === 'forgot' ){
                  notifyServerOfSignup(form,scene,route,setResponses)
                }
              }


              dispatch(A[actionName]({ ...form }))

              let nextScene = nextSceneMap[ scene ];
              if( nextScene ){
                setScene(nextScene);
              }
            }


          
        }}
          class="form-control btn btn-primary bold">{forms[scene].action}</button>
        {forms[scene].alternative && (<div style={{
          //background:'pink'
        }}>
          <button 
            goto={forms[scene].alternative.scene}
            onClick={() => setScene(forms[scene].alternative.scene) } class="small-margin alternative btn btn-link">
            {forms[scene].alternative.text}
          </button>
        </div>)}
      </div>

      {
        (scene === 'signin') && (
          <>
            <div class="fair-margin-bottom">
              <div class="full-width med-border-bottom"/>
            </div>

              <button style={{
                width:'90%',
                }} button={"create-account"} onClick={() => setScene('signup')} class="btn btn-success bold fair-margin-bottom">
                Create account
              </button>
                
            <SupportSigninButton/>
          </>
        )
      }
    </div>

  )

}

const forms = {
  signin:{
    formLines:['email','password'],
    action:"Login",
    alternative:{
      scene:'forgot',
      text:'Forgot password?'
    }
  },
  signup:{
    formLines:[['firstName','lastName'],'email','password','confirmPassword','signupReference'],
    optional:(new Set(['signupReference'])),
    action:'Sign up!',
  },
  forgot:{
    formLines:['email'],
    action:'Send Code to Email',
  },
  changePassword:{
    placeholders:{
      password:'New password',
    },
    action:'Reset Password',
    formLines:['code','password','confirmPassword'],

  }
}

export default function AuthContainer({maxWidth}){

  let [scene,setScene] = useState('signin');
  return (
    <div style={{
      position:'relative',
      display:'flex',
      justifyContent:'center',
      maxWidth:'100%',
      //border:'3px solid red',

    }}>
      <div class="flex-expand" style={{
        display:'flex',
        justifyContent:'center',
        maxWidth:(maxWidth || '80%'),
        //border:'3px solid blue',
      }}>


        <div style={{ 
          position:'relative', 
          //border:'3px solid red',
        }} class="auth-container white-shaddow-container flex-expand">

          <div style={{position:'relative', justifyContent:'center'}} class="sg-row">
            {scene !== 'signin' && (<div onClick={() => setScene('signin')}
              class="hoverable round-corners"

              style={{
                position:'absolute',
                width:30,
                height:30,
                left:0,
                top:0,
                //transform:'translate(0,-50%)',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
              }}>
              <ArrowBackIcon/>
            </div>
            )}
            {scene !== 'signin' && <div>
              {forms[scene].action}
            </div>}
          </div>
          <div 
            style={{
              position:'relative',
              //border:'3px solid red',
              display:'flex', justifyContent:'center',
            }}>

            <Form key={scene} scene={scene} setScene={setScene}/> 
          </div>
        </div>
      </div>
    </div>
  )
}
