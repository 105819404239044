import usePresentSelector from './usePresentSelector';

import SciugoFolderIcon from './SciugoFolderIcon';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import CropPreview from './CropPreview';
import CropWrapper from './CropWrapper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import G from './Getters';
import LineSegmentBox from './LineSegmentBox';

export default function ProteinTargetSearchResult({
  path,
  dateLabelString,
  searchResultDisplayedDate,
  label, 
  cropResult,
  orderInList,
  isSelected,
  setDragInfo, 
  annotationId,
}){



  let [ expanded, setExpanded ] = useState(false);

  let fullImageHeight = usePresentSelector(state => state.data.imageUploads[cropResult.imageId].height);
  let focusedAnnotation = usePresentSelector(state => state.data.annotations[annotationId]);

  let inlineCrop={left:0,top:0,height:fullImageHeight,width:1};

  let cropHeight = usePresentSelector(state => G.getAnnotationHeight(state,annotationId));

  let imgRef = useRef();
  let bounds = imgRef && imgRef.current && imgRef.current.getBoundingClientRect();

  let w = bounds && bounds.width;

  const onClick = () => {
    setDragInfo({
      valueType:'crop',
      annotationId
    })
  }


  let oldStyleClasses = 'round-corners small-padding small-margin';

  let nonRootPath = path.slice(1,path.length-1);
  let finalContainer = path.slice(-1)[0];

  const pathColor = '#aaa';


  let innerPathContent;
  if( nonRootPath.length > 0 ){
    innerPathContent = nonRootPath.map((item,ii) => {
      return (
        <div class="sg-row" style={{alignItems:'center'}}>
          <SciugoFolderIcon/>
          <div class="target-search-result-text" style={{
          //fontSize:11,
          color:pathColor}}>{item.filesystemName}</div>
          <ChevronRightIcon style={{fontSize:16}}/>
        </div>
      )
    })
  }else{

    innerPathContent = (
      <div class="sg-row" style={{alignItems:'center'}}>
        <SciugoFolderIcon/>
        <div class="target-search-result-text" style={{
        //fontSize:11,
        color:pathColor}}>{"Home Folder"}</div>
        <ChevronRightIcon style={{fontSize:16}}/>
      </div>
    )
  }

  let pathDiv = (
    <div class="sg-row target-search-result-text" style={{width:'100%', 
      //fontSize:11, 
      color:pathColor, alignItems:'center',flexWrap:'wrap', marginTop:5,}}>
      {innerPathContent}
      {finalContainer.filesystemName}
    </div>
  )
  
  return (

    <div 
      item={"protein-target-search-result"}
      annotationSearchResult={orderInList}
      onClick={() => {
        setDragInfo({valueType:'crop',annotationId})
      }}

      onMouseDown={
        () => {
          setDragInfo({valueType:'crop',annotationId})
        }
      }

      class={"small-padding fs-alternating"}
      style={{cursor:'pointer',
        background:'white',
        //border:'1px solid #ccc'
      }}>
        <div style={{
          width:'100%',
          alignItems:'center',
          //background:'magenta'
        }}>

          <DateComponent {...{dateLabelString,searchResultDisplayedDate}}/>

          <CropAndLabel {...{
            cropResult,
            inlineCrop:cropResult,
            annotationId,
            label,
            selected:isSelected,
          }}/>
        </div>


      {pathDiv}
    </div>

  )

}
function DateComponent({dateLabelString,searchResultDisplayedDate}){
  return (

    <div>
      <div 
        class="target-search-result-text"
        style={{
          //fontSize:11,
        fontStyle:'italic',
        //fontWeight:'bold'
        }}>
        {dateLabelString}
      </div>
      <div class="target-search-result-text" style={{
        //fontSize:11,
        marginBottom:3}}>
        {searchResultDisplayedDate}
      </div>
    </div>

  )
}

function CropAndLabel({label,cropResult,inlineCrop,annotationId,selected}){
  let outerClass = (
    "round-corners " + (selected?"selected-blue":"dark-hoverable")
  );

  return (
    <div class={outerClass} style={{
      fontSize:11,
      position:'relative',
      border:'1px solid #ccc',
      padding:5,
      paddingBottom:7,
      background:'white',
      //width:'60%'

    }}>

      <div 
      style={{
        fontStyle:!label && 'italic',
        fontWeight:label && 'bold',
      }}>{label||"Unlabled target"}</div>
      <div style={{
        //border:'1px solid #ccc'
      }}>
        <CropWrapper grabbable={false} draggable={false} imageId={cropResult.imageId} inlineCrop={cropResult} annotationId={annotationId}/>
      </div>
    </div>

  )
}

