import ChangeReducer from './ChangeReducer';
import C from './Constants';
import { produce } from 'immer';
import {patch,unpatch} from 'jsondiffpatch';
import DefaultHistoryInfo from './DefaultHistoryInfo';
import applyChangesToHistoryInfo from './applyChangesToHistoryInfo';
import getStateWithUpdatedChangeHistory from './getStateWithUpdatedChangeHistory';
import G from './Getters';

function removeReduxDevtoolsKeysFromRawAction(rawAction){
  let action = Object.fromEntries(Object.entries(rawAction).filter(([key,val]) => {
    return ['__loginInfo','__dialogsCount'].every(badKey => badKey !== key)
  }))
  return action;
}

export default function UndoRedoReducer(state,rawAction){

  let action = removeReduxDevtoolsKeysFromRawAction(rawAction);

  if( !state ){
    return ChangeReducer(state,action);
  }


  let { historyInfo } = state;
  let { activeHistory, index } = historyInfo;

  switch(action.type){

    case C.setState:{
      return action.state;
    }

    case C.UNDO:
    case C.REDO:{



      let changeArgMap = { 
        [C.UNDO]:{ patchFunction:unpatch, opIndexDelta:1, newIndexDelta: 1 },
        [C.REDO]:{ patchFunction:patch, opIndexDelta:0, newIndexDelta:-1 }
      }

      let {opIndexDelta, newIndexDelta} = changeArgMap[action.type];

      let newIndex = index+newIndexDelta;
      let changesAtEpoch = activeHistory[ index+opIndexDelta ];
      if( changesAtEpoch ){

        let {patchFunction} = changeArgMap[ action.type ];
        

        return applyChangesToHistoryInfo(
          state,
          patchFunction,
          changesAtEpoch,
          newIndex
        );
      }else{
        return state;
      }
      
      
    }
    case C.CLEAR_HISTORY:{
      return produce(state,draft => {
        draft.historyInfo = DefaultHistoryInfo();
      })
    }
    default:
      return getStateWithUpdatedChangeHistory(state,action);
  }
}
