import ImageAdjustmentSlider from './ImageAdjustmentSlider';
import A from './ActionCreators';
import { useDispatch } from 'react-redux';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff';


export default function ImageAdjustmentContainer({
  imageAdjustments,
  setTemporaryAdjustments, 
  imageId
}){

  let dispatch = useDispatch();

  let content = [
    {property:'contrast',min:0.00001,max:50},
    {property:'brightness',min:0.00001,max:3}
  ]


  let sliders =  content.map(sliderData => {
    let { property } = sliderData;
    let label = property[0].toUpperCase() + property.slice(1);
    return (
      <div class="small-margin"><ImageAdjustmentSlider {...{
        ...sliderData,
        label,
        imageId,
        value:imageAdjustments[property]||1,
        setTemporaryAdjustments,
        onChange:value => setTemporaryAdjustments({...imageAdjustments,[property]:value})
      }}/></div>
    )
  })

  let resetButton = (<button 
    onClick={() => {
      dispatch(A.setImageProperties({
        imageId,
        properties:{adjustments:Object.fromEntries(content.map(item => ([item.property,1])))}
      }))
      setTemporaryAdjustments({});
    }}
    class="image-adjustment-button large-font">Reset</button>
  );

  let { inverted } = imageAdjustments;

  return (
    <div style={{borderLeft:'1px solid #ccc',paddingLeft:22, 
      alignItems:'center'}} class="sg-row">

      
      
      <div>
        {sliders}
      </div>

      <div onClick={() => {

        dispatch(A.setImageProperty({
              property:'inverted',
              value:!inverted,
              imageId
            }))
      }}
        style={{height:'100%', cursor:'pointer'}} class="dark-hoverable round-corners">
        {!imageAdjustments.inverted ? <InvertColorsIcon style={{fontSize:36}}/> : <InvertColorsOffIcon style={{fontSize:36}}/> }
      </div>



      <div class="small-margin" style={{fontSize:16,marginRight:30}}>
        {resetButton}
      </div>


    </div>
  )

}
