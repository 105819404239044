import usePresentSelector from './usePresentSelector';
import ResizeElement from './ResizeElement';
import G from './Getters';
import { useContext } from 'react';
import { ColumnResizeContext } from './Contexts';

export default function CellWidthResizer({
  figurePanelId, 


  cell,
  cellResizeInfo,
  setCellResizeInfo,
  setDontUnselectCells,
  onMouseEnter,
  onMouseLeave,
  setMouseIsDown,setCurrentWidths,widths}){

  if( !figurePanelId ){
    throw Error("CellWidthResizer requires `figurePanelId`");
  }

  let cellX = cell.x + cell.w - 1;
  let w = cell.w;

  let resizeOptions = usePresentSelector(state => {
    try{
      let resizeOptions = G.getCellResizeOptions(state,{figurePanelId,cell:[cell.y,cell.x]})
      return resizeOptions;

    }catch(e){

      debugger;
    }
  });

  //let { left, right } = resizeOptions;

  const columnResizeContext = useContext(ColumnResizeContext);
  const { setColumnResizeInfo } = (columnResizeContext||{});

  return (
    <>
      {["left","right"].map(side => {

        if( !resizeOptions ){
          debugger;
        }

        let resizeFrom = resizeOptions[side];

      return <ResizeElement {...{
        side,
        resizeFrom,
        figurePanelId,
        cell,
        cellResizeInfo,
        onMouseEnter,
        onMouseLeave,
        setDontUnselectCells,
        setMouseIsDown,
        setCurrentWidths,
        setCellResizeInfo,
        cellX,
        w,
        widths
      }}/>
      })}
    </>
  )
  
}
