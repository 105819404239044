import { useState, useContext } from 'react';
import TemplateNodePositionedItem from './TemplateNodePositionedItem';
import { ExpansionWindowFocusContext } from './Contexts';

export default function FigureImageExpansionTemplateNodeValue({
  computedDimensions,
  value, 
  dispatch,
  templateId,
  nodeId,
  regionId,
  readyToPlaceWindow,
  entered,
  node,
  nodes,
}){


  let experimentWindowFocusContext = useContext(ExpansionWindowFocusContext);


  let { focusedTemplateNodeIds=[] } = experimentWindowFocusContext || {};

  let isSelected = focusedTemplateNodeIds.includes(nodeId);


  const [ parentBox, setParentBox ] = useState();
  let children = node.c || [];

  const padding = node?.style?.padding || 0;
  const borderWidth = node?.style?.borderWidth || 0;
  const borderColor = node?.style?.borderColor || 'pink';

  const resolvedOuterPadding = Math.max(0,padding - borderWidth);
  const resolvedBorderPadding = Math.min(padding, borderWidth)

  return (

    <div style={{
      position:'absolute',
      padding,
      //top:padding,
      //left:padding,

      background:borderColor,
      //border:'1px solid #ccc',
      width:`calc(100% - ${padding*2}px)`,
      height:`calc(100% - ${padding*2}px)`,
    }}>

      <div style={{
        position:'absolute',
        top:borderWidth,
        left:borderWidth,
        //border:'1px solid lime',
        background:'white',
        width:`calc(100% - ${borderWidth*2}px)`,
        height:`calc(100% - ${borderWidth*2}px)`,
      }}>

        <div 
          ref={el => {

            if( el ){
              let parentRect = el.getBoundingClientRect();
              let parentBoxToSet = {
                width:parentRect.width,
                left:parentRect.left,
                height:parentRect.height,
                top:parentRect.top
              }

              if( !parentBox ){
                setParentBox(parentBoxToSet)
              }
            }
          }}

          style={{
            top:0,
            left:0,
            background:'white',
            position:'relative',
            maxHeight:'100%',
            maxWidth:'100%',
            minHeight:0,
            minWidth:0,

            overflow:'hidden',
          }} class="flex-expand full-height full-width expansion-item-container">

          {isSelected && <div class="selected-item-cover"/>}

          {parentBox && (children).map(cId => {

            return <TemplateNodePositionedItem {...{
              nodeId:cId,
              node:nodes[cId],
              parentBox,
              nodes,
              computedDimensions,

            }}/>



          })}

          {false && parentBox && <div style={{
            position:'absolute',
            top:0,
            left:0,
            fontSize:8}}>{JSON.stringify(parentBox,null,1)}</div>}

        </div>
      </div>
      
    </div>
  )

}

