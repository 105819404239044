//import DefaultDatatypeSetupFactory from './DefaultTypeContainerFactory';
//import DefaultMetadataSetupFactory from './DefaultMetadataSetupFactory';

import Id from './IdFactory';

import createDefaultStateItems from './createDefaultStateItems';
import { getGlobalObject } from './versionConfig';

import getDefaultUiStateSlice from './getDefaultUiStateSlice';

import CellCultureCreator from './CellCultureCreator';

function setGlobalSessionId(id){
  getGlobalObject().__windowSessionId = id;
}

function getGlobalSessionId(){
  return getGlobalObject().__windowSessionId;
}






let getDefaultState = (args) => {

  let testMeta = getGlobalObject().__testMeta;
  if( testMeta){
    let { officialTestName } = testMeta;
    if( officialTestName && officialTestName.includes('sendDelivery') ){
      //debugger;
    }
  }


  let {providedDirectoryRoots,windowSessionId,personId,agentId} = args || {};
  providedDirectoryRoots = providedDirectoryRoots || {}

  //setGlobalSessionId(getGlobalSessionId()||windowSessionId||Id());

  const datatypeSpecificDirectoryRoots = {
    imageSets:(providedDirectoryRoots.imageSets) || Id(),
    figurePanels:(providedDirectoryRoots.figurePanels) || Id(),
    figurePanelTemplates:(providedDirectoryRoots.figurePanelTemplates) || Id()
  }

  let defaultStateItemArgs = {
    datatypeSpecificDirectoryRoots
  }
  
  let { data, meta } = 
    createDefaultStateItems(defaultStateItemArgs);

  
  return {

    cellCulture:CellCultureCreator.template(),
    loginInfo:{
      authPending:false,
      status:'loggedOut',
      loginResponse:undefined,
      loginAttempts:0,
      receivedCookie:'empty-session'
    },


    historyInfo:{
      activeHistory:[],
      index:-1
    },

    dialogs:[],
    discounts:[],
    surveys:[],
    notifications:[],

    media:{},
    mediaProcessing:{},

    data,
    meta,

    pendingRecords:{ imageUploads:{} },

    archived:{},

    userConfig:{
      userRootIds:{
        datatypeSpecificDirectoryRoots,
      },
    },

    fetchStatus:{},
    syncStatus:{
      records:{},
      userConfig:{},
    },

    processes:{},

    messages:[],
    threads:{},
    adminIsTyping:false,
    requests:{ 
      sync:{}
    },
    warnings:{},

    ui:getDefaultUiStateSlice(),

    sessionConfig:{},

    windowSessionId:(windowSessionId || getGlobalSessionId()),
    personId:(personId||agentId),


    shouldShowImageLabels:{ left: true, right: true }, // this should be in figurePanelSettings
    selectedFigurePanelId:null, //this should probably be in a provider... I'm not sure when it's necessary or who will pass around
    archivedImages:[], //don't think this will be necessary


    editorStyle:{}, // this could go either way
    //if editor style is local, then we need to pass the style
    //to every cell we set
    //if its global, we set it once and that's it

    // @@variables that are probably unused
    heightGroups:{},

    tutorialState:null,

    passwordResetInfo:{},


  }}



export default getDefaultState;
