import Croppable from './Croppable';


const p = (arg) => {
  if (arg instanceof Object) {
    let newObj = {};
    Object.keys(arg).forEach((key) => (newObj[key] = p(arg[key])));
    return newObj;
  } else {
    return arg + "px";
  }
};


export default function CropItems({ items, imageRef, addCrop, rotation }){
  return (
    <>
      {items.map((item) => (
        <CroppableItem rotation={rotation} item={item} addCrop={addCrop} imageRef={imageRef} />
      ))}
    </>
  );
}

const Crop = ({ top, height, left, width }) => {
  return (
    <div
      style={{
        userSelect: "none",
        top: p(top),
        width: "100%",
        height: p(height),
        pointerEvents: "none",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        position: "absolute"
      }}
    >

      <div style={{ background: "rgba(0,0,0,0.5)", width: p(left) }}></div>
      <div style={{ width: p(width) }}></div>
      <div style={{ background: "rgba(0,0,0,0.5)", flex: 1 }}></div>
    </div>
  );
};

;

const CroppableItem = ({ item, imageRef, addCrop, rotation }) => {
  if (item.type === "delta") {
    return (

      <Croppable
        top={item.top}
        addCrop={addCrop}
        height={item.height}
        imageRef={imageRef}
        rotation={rotation}
      />
    );
  } else if (item.type === "crop") {
    return <Crop {...{ ...item }} />;
  } else {
    alert("Unrecognized item type: " + item.type);
  }
}
