export default function getTypeChanges(reducedTypeContainer,oldTypeContainer){

  let reducedRecIds = new Set(Object.keys(reducedTypeContainer));
  let origRecIds = new Set(Object.keys(oldTypeContainer));


  let newIds = [...reducedRecIds].filter(_id => !origRecIds.has(_id) ); 
  let missingIds = [...origRecIds].filter(_id => !reducedRecIds.has(_id) ); 

  let persistingIds = [...reducedRecIds].filter(_id => origRecIds.has(_id) );

  let changed = persistingIds.filter(id => {
    return reducedTypeContainer[id] !== oldTypeContainer[id]
  })

  let changes = {
    newItems:newIds,
    missingItems:missingIds,
    changed
  }

  return changes;

}
