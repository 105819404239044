import Modal from 'react-bootstrap/Modal';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

export default function CustomModalHeader({header}){
  let { text, type } = header;
  return(

    <Modal.Header style={{background:'#fff'}}>
      <Modal.Title style={{display:'flex', alignItems:'center'}}>



        <span style={{marginRight:10}}>
          {type === 'error' && <ErrorIcon style={{
            height:40,
            width:40,
            margin:7,color:'rgba(200,0,0)'}}/>
          }

          {type === 'warning' && <WarningIcon style={{
            height:40,
            width:40,
            margin:7,color:'gold'}}/>
          }
        </span>

        <span info={"dialog-header-text"}>
        {text}
        </span>

      </Modal.Title>
    </Modal.Header>
  )

}
