import Navbar from './Navbar';
import HomeIcon from '@material-ui/icons/Home';

const navbarOptions = [
  {
    _id:'features',
    label:'Features',
  },

  {
    _id:'about',
    label:'About',
  },
  {

    _id:'overview',
    label:'Overview',
  },

  /*
  {
    _id:'signin',
    label:'Sign in',
    style:{
      fontWeight:'bold',
      border:'3px solid white',
    },
    hoverClass:"signin-hover"
  }
  */


]

export default function WelcomeSceneNavbar({
  onNavbarClick,
  setSubscene,
  subscene,
}){

  return (
    <div style={{
      position:'sticky',
      top:0, 
      left:0,
      minHeight:50,
      height:50,
      width:'100%',
      background:'rgb(1, 72, 140)',
      borderBottom:'1px solid #ccc',
      display:'flex',
      alignItems:'center',
      overflowX:'auto',
      zIndex:20,

    }}>
      <div style={{
        marginLeft:20,
      }}
        onClick={() => setSubscene("home")}
        class="underline-hoverable selectable clickable">
        <HomeIcon style={{
          color:'white',
          fontSize:28,

        }}/>
      </div>
      <div class="flex-expand"/>
      <Navbar 
        onSelect={onNavbarClick}
        selected={subscene}
        style={{ 
          color:'white', 
          boxSizing:'border-box',
          marginLeft:25, 
          //fontSize:'150%',
        }}
        hoverClass={"underline-hoverable clickable"}
        selectedClass={"white-underline"}
        spread={false} options={navbarOptions}/>
      <div style={{width:100}}/>
    </div>
  )
}
