import CropWrapper from './CropWrapper';
import usePresentSelector from './usePresentSelector';
import G from './Getters';
//import { unit, minus, add, getPointsFromLs, scale } from './Geometry';

import { ANNOTATIONS } from './RecordTypes';

import { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import A from './ActionCreators';

import useLocalReducer from './useLocalReducer';

import { MINIMUM_ROW_HEIGHT } from './ManualAnnotationRow';

import { CropAdjustmentContext } from './Contexts';
import HorizontalCropAdjuster from './HorizontalCropAdjuster';

const DEFAULT_STATE = {
  enteredAdjustmentBar:false
}



const defaultLineColor = ''

export default function CropGridValue({
  tableOutlineShowing,
  manualAnnotationGridDispatch,
  width,
  gridCellId,
  expandImage,
  value,
  figurePanelId,
  cellLocation,
  editable
}){

  const cropAdjustmentContext = useContext(
    CropAdjustmentContext
  );

  let { cropResizeInfo, setCropResizeInfo } = cropAdjustmentContext||{};



  if(editable!==false && !manualAnnotationGridDispatch){
    throw Error("CropGridValue doesn't have a defined MAGD");
  }

  let dispatch = useDispatch();


  let {annotationId} = value;

  let isAnnotationArchived = usePresentSelector(state => G.getMeta(state,{type:ANNOTATIONS,_id:value.annotationId}).archived)

  let inlineCrop = usePresentSelector(state => G.getCropFromAnnotation(state,value.annotationId));

  let {annotation} = usePresentSelector(state => {
    return {
      annotation:G.getData(state,{itemType:'annotation',_id:annotationId}),
    }
  },[annotationId]);

  let [ shiftDown, setShiftDown ] = useState(false);


  let [_,setTempInlineCrop] = useState(inlineCrop);

  let { tempInlineCrop } = (cropResizeInfo || {});

  let state = usePresentSelector(state => state);



  let [ localState, localDispatch ] = useLocalReducer();

  if( isAnnotationArchived || !inlineCrop ){
    return <div style={{fontWeight:'bold',color:'red'}}>Image/crop not found.</div>;
  }


  let thisCropBeingAdjusted = (
    cropResizeInfo && 
    cellLocation[0] === cropResizeInfo.cellLocation[0] && 
    cellLocation[1] === cropResizeInfo.cellLocation[1]
  )

  let currentCrop;
  if( thisCropBeingAdjusted ){
    currentCrop = (tempInlineCrop||inlineCrop);
  }else{
    currentCrop = inlineCrop;
  }
  let { imageId } = currentCrop;
  let currentCropWidth = currentCrop.width;
  let currentCropHeight = currentCrop.height;

  //let { imageId, top, height} = (tempInlineCrop || inlineCrop);

  let idWithDescriptionFollowedByDashesToSeparateDescriptionAndEachCellLocationCoordinatesAsUsedByTheOnClickCellSelectionFramework = 'grid_image-'+cellLocation[0]+"-"+cellLocation[1]



  ////console.log("CROP CELL WIDTH: " + width);

  let outerDivHeight = (width/currentCropWidth)*currentCropHeight;
  //console.log("%c Outer div height: " + outerDivHeight,'background: #222; color: #bada55' );


  let { side } = cropResizeInfo || {};
  let adjustmentSide = side;



  let adjustingHorizontally = ['left','right'].includes(adjustmentSide) && thisCropBeingAdjusted;
  let adjustingVertically = ['top','bottom'].includes(adjustmentSide) && thisCropBeingAdjusted;


  let canResizeCropVertically = editable !== false && (!adjustingHorizontally && tableOutlineShowing);
  let canResizeCropHorizontally = editable !== false && (!adjustingVertically && tableOutlineShowing);

  //const ROTATION_DEV = 


  let cropResizeStarterPackage = (e,side) => ({
    side:(e.shiftKey ? "rotationFrom_" : "")+side,
    cellLocation,
    startY:e.clientY,
    startX:e.clientX,
    inlineCrop,
    tempInlineCrop:inlineCrop,
    annotation,
    cellWidthOfCropBeingAdjusted:width,
    globalState:state,
  })




  return (
    <>
      <div 
        objectType={"CropGridValue"}
        cell={cellLocation?.join('-')}
        valuetype={"crop"}
        imageId={imageId}

        class={"crop-grid-value-container"}
        onMouseEnter={() => {
          ////////console.log("Outer Div: CropGridValueEnter");

          ////////console.log("ENTERED CROP GRID VALUE RESIZE BAR");

        }} style={{
          zIndex:cropResizeInfo?1000:'unset',
          height:outerDivHeight
        }}>
        <CropWrapper
          canClickToRetryDownload={true}

          key={gridCellId+'_'+annotationId}

          htmlImageClipDivId={idWithDescriptionFollowedByDashesToSeparateDescriptionAndEachCellLocationCoordinatesAsUsedByTheOnClickCellSelectionFramework}
          inlineCrop={currentCrop}
          placementEditable={true}
          imageSelectable={false} 
          annotationId={annotationId} 
          imageId={imageId}
          width={width} 
          pxHeight={outerDivHeight}
          expandImage={expandImage}
          gridCellId={gridCellId}
        />

        {canResizeCropVertically  && <div 
          item={"crop-resizer"}
          side={"top"}
          class="crop-resizer blue-hoverable hoverable-sibling"

          onMouseEnter={() => {
            ////////console.log("ENTERED CROP GRID VALUE RESIZE BAR");
            //localDispatch({enteredAdjustmentBar:true})
          }}
          onMouseLeave={() => {
            //localDispatch({enteredAdjustmentBar:false})
          }}
          onClick={e => e.stopPropagation()}
          onMouseDown={e => {
            //////console.log("Mouse down on vertical!");
            e.stopPropagation();

            setCropResizeInfo(cropResizeStarterPackage(e,'top'))
            setTempInlineCrop(inlineCrop);
          }}

          style={{position:'absolute',width:'100%',
            height:3,
            background:(thisCropBeingAdjusted && cropResizeInfo?.side === 'top' ? 'magenta' : defaultLineColor),
            top:0,left:0,zIndex:10,cursor:'ns-resize'}}/>}

        {canResizeCropVertically &&  <div class="crop-resizer blue-hoverable hoverable-sibling" 
          side={"bottom"}
          item={"crop-resizer"}

          onMouseEnter={() => {
            //localDispatch({enteredAdjustmentBar:true})
          }}
          onMouseLeave={() => {
            //localDispatch({enteredAdjustmentBar:false})
          }}
          onMouseDown={e => {
            //alert("Starting to adjust bottom!");
            e.stopPropagation();
            setCropResizeInfo(cropResizeStarterPackage(e,'bottom'))
            setTempInlineCrop(inlineCrop);
          }}
          onClick={e => e.stopPropagation()}
          style={{
            position:'absolute',width:'100%',height:3,
            //background:(thisCropBeingAdjusted && cropResizeInfo?.side === 'bottom' ? 'magenta' : defaultLineColor),
            bottom:0,left:0,zIndex:10,cursor:'ns-resize'}}/>}

        <div style={{background:'rgb(200,200,200,0.5)'}} class="absolute-cover visible-on-sibling-hover"/>

      </div>

      {false && (thisCropBeingAdjusted || localState.enteredAdjustmentBar) && <div style={{
        pointerEvents:'none',
        top:0,
        left:0,
        width,
        height:'100%',
        background:'rgba(255,255,255,0.5)',
        position:'absolute',
      }}>
      </div>}

      {canResizeCropHorizontally && (<>
        {['left','right'].map(
          side => (
            <HorizontalCropAdjuster
              {...{
                cellLocation,
                cropResizeInfo,
                height:15,
                side,
                onStart:((e) => {
                  setCropResizeInfo(cropResizeStarterPackage(e,side))})

              }}
            />
          )
        )}
      </>)}


      {false && cellLocation[0] === 2 && <div style={{position:'fixed',top:300,left:200,width:500,height:100}}><pre>{JSON.stringify(inlineCrop,null,1)}</pre></div>}




    </>
  )


}
