import { useState } from 'react';
import GelBlot from './GelBlot';
import GelLayoutScene from './GelLayoutScene';
import GelResults from './GelResults';




export default function GelsScene({
}){

  const [ layoutSelected, setLayoutSelected ] = useState(false);
  const [ imagesUploaded, setImagesUploaded ] = useState(false);


  //let leftWidth = layoutSelected ? 30;




  return (
    <div style={{
      //background:'pink'
      background:'#eee'
    }} class="sg-row full-width">
      <div style={{
        //width:`${leftWidth}%`
      }} class="sg-col">
        <div style={{
          width:'100%',
          //height:'50%',
          padding:20
        }}>
          <h3>Gel</h3>
          {true && <GelLayoutScene {...{
            layoutSelected,
            setLayoutSelected
          }}/>}
        </div>

        {layoutSelected && <div style={{
          position:'relative',
          width:'100%',
          height:'50%',
          //padding:20
        }}>
          <div style={{
            padding:20,
            //background:'pink',
            height:'100%',
            borderTop:'1px solid #ccc',
          }}>
            <h3>
              Blot
            </h3>
            <div class="small-padding">
              <GelBlot/>
            </div>
          </div>
        </div>}



      </div>

      <div 
        class="flex-expand"
        style={{
          //background:'pink'
          borderLeft:'1px solid #ccc',
          //width:`${100-leftWidth}%`
        }}>

        <GelResults imagesUploaded={imagesUploaded}/>
        {/* width={100-leftWidth}/>*/}

      </div>


    </div>
  )
}
