import { useState } from 'react';
import {  useDispatch } from 'react-redux';
import A from './ActionCreators';

function Rating({value,onClick,rating}){

  let style = rating !== value ? { background:'#ccc' } : {
    background:'#007bff',
    color:'white',
  };




  return (
    <div 
      surveyItemId={"npsRating"}
      value={value}
      onClick={()=>onClick(value)} style={{
        borderRadius:30,
        margin:10,
        width:30,
        height:30,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        ...style,
        //fontWeight:'bold',
        cursor:'pointer',
      }}>
      <div style={{position:'relative'}}>
        <div style={{fontWeight:'bold'}}>{value} </div>

        {value === 1 && <div style={{position:'absolute',
          top:-30,

          left:'50%',
          transform:'translate(-50%,0)',
        }}>Low</div>}

        {value === 10 && <div style={{position:'absolute',
          fontWeight:'none',
          color:'black',
          top:-30,
          left:'50%',
          transform:'translate(-50%,0)',
        }}>High</div>}
      </div>

    </div>
  )
}



export default function NPSSurvey(){

  const [rating,setRating] = useState();
  const [explained,setExplained] = useState("");
  const [valid,setValid] = useState(false);

  const dispatch = useDispatch();

  let continueEnabled = (rating && explained.length >= 100);


  return (

    <div survey={"npsScore"} style={{position:'relative'}}>
        <h3 style={{textAlign:'center',
          paddingBottom:20,
        }}>How likely are you to recommend <br/>Sciugo to a co-worker / colleague?</h3>
        <div class="sg-row">
          {Array(10).fill(null).map((_,ii) => <Rating {...{value:(ii+1), onClick:setRating, rating }}/>)}
        </div>
        <h3 style={{paddingTop:20}}>Explain your answer above:</h3>
        <textarea surveyItemId={"npsExplanation"} onChange={e=>setExplained(e.target.value)}
          style={{width:'100%',
            minHeight:100
          }}>
        </textarea>
      <div style={{textAlign:'right', paddingTop:20}}>
        <div>{explained.length+" / 100 (min)"}</div>
      </div>
  
        <div style={{textAlign:'right', paddingTop:20}}>
          <button 
            surveyItem={"submit"}
            onClick={() => {
              dispatch(A.answerSurvey({
                name:"npsScore",
                answer:{
                  score:rating,
                  reason:explained
                }
              }))
            }}
            disabled={!continueEnabled} class="btn btn-primary">Continue</button>
        </div>
      </div>

  )
}
