import G from './Getters';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import A from './ActionCreators';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const ERROR_COLOR = '#CC0000';
//"#A31700";

export default function RefreshAccountMessage(){

  

  return (
    <div message={
      "refreshSubscriptionInfoRecommendation"
    } class="sg-row v-center bold">
      <ErrorOutlineIcon style={{
        color:ERROR_COLOR,
      }}/>
      &nbsp;

      <div style={{
        color:ERROR_COLOR,
      }}>
          Refresh account to activate checkout changes.
      </div>
    </div>
  )

}
