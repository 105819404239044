import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ColorPicker from './ColorPicker';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CollapsibleSection from './CollapsibleSection';

/*

  1. parent regions 
    ==> we're showing options to show/hide all of the regions inside main
    Hence, we need to just get the data for the nodes and display them.

    What's needed is:
      The cell location to get info about the subregions selected...

  2. zoom/inset region
    ==> same thing, we're selecting multiple of SINGLE
      ==> only displaying options for a unified group

  3. regionOutline
    ==> we may have many region outlines from many different cells
      ==> we're only displaying edit options for the one.

*/


const styleOptions = ["0 0","2 1", "2 2", "3 1","3 2","4 1", "4 2"]




function RegionSpecificMenu({ regionId, targetLocations, onEvent }){

  let [ colorPopoverOpen, setColorPopoverOpen ] = useState(false);
  let regionOutline  = {};

  let { width=1, color="white", strokeDasharray="0 0" } = regionOutline;


  return (
    <>
      <tr>

        <td>
          <input type={"checkbox"} checked={true}/>
        </td>

        <td>
          {regionId+""}
        </td>

        <td></td>

        <td>
          <input onEvent={e => {
            onEvent && onEvent({
              targetLocations,
              key:"width",
              value:Number(e.target.value)
            })
          }} value={width} style={{width:40, fontSize:10}} min={1} type="number"/>
        </td>



        <td>
          <Select
            //onClick={e => e.preventDefault()}
            labelId="demo-simple-select-outlined-label"
            id="subregion-outline-style"
            value={strokeDasharray}
            style={{
              paddingLeft:3,
              border:'1px solid #ccc',
              fontSize:10,
              height:22,
              borderRadius:1,
              background:'white',
            }}
            onEvent={e => onEvent && onEvent({
              targetLocations,
              key:"strokeDasharray",
              value:e.target.value,
            })}
          >
            {styleOptions.map(value => {
              return (
                <MenuItem value={value}>
                  <svg width={40} height={6}>
                    <line x1={0} y1={"50%"} x2={"100%"} y2={"50%"} {...{
                      stroke:"black",
                      strokeDasharray:value||"0 0"
                    }}/>
                  </svg>
                </MenuItem>
              )
            })}
          </Select>



        </td>
        <td>

          <div onClick={() => {
            setColorPopoverOpen(!colorPopoverOpen);
          }} style={{
            position:'relative',
            width:20,
            height:20,
            borderRadius:2,
            background:(color||"white"),
            border:'1px solid #aaa',
          }}>

          </div>

        </td>


        {/*false && <td><input onEvent={e => onEvent({borderRadius:Number(e.target.value)})} defaultValue={0} style={{width:40, fontSize:10}} value={borderRadius} min={0} type="number"/></td>*/}
      </tr>
      <tr>
      </tr>
    </>


  )


}


export default function SubregionOutlineMenu({
  data,args,onEvent,selectedFigureItems,figurePanelId
}){

  /*

   for MAIN regions,
   targetLocations:
    [{cellLocation:[...], nodeId:[]}]
    //REGION_ID
  

    for each item in the menu, 
    we associate that with a list of targetLocations (nodeId,cellLocation)

    and that's that.

  */

  let { targetLists=[] } = args || {};

  



  



  return (


    <div class="full-width small-padding">
      <div><b>Subregion Outline</b></div>


      <select>
        {styleOptions.map(outline => {
          return (
            <option>
              {outline}
            </option>
          )
        })}
      </select>


      <table>
        <tr>
          <td></td>
          <td>Region</td>
          <td style={{width:'100%'}}></td>

          
          <td>Width</td>
          <td>Style</td>
          <td>Color</td>
          {false && <td>Radius</td>}
        </tr>


        {targetLists.map((targetList,ii) => {
          return (
            <RegionSpecificMenu {...{
              regionId:ii,
              targetLocations:targetList,
              onEvent,
            }}/>
          )
        })}

      </table>

      
    </div>

  )

}
