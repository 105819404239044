export default function computeSpecificScript(args){
  let { 
    rawImageWindowName,
    cropWindowName, 
    plotWindowName,

    uploadedImageWidth,
    ls,
    annotationHeight,
    boundaries,
    quantifications
  } = args;

  const boxHeight = annotationHeight * uploadedImageWidth;
  let allValues = quantifications.map(x => x.plotValues).flat();
  let totalMax = Math.max(...allValues); 


  let boxSetupCommands = boundaries.map((bounds,ii) => {
    let mapped = bounds.map(x => x*uploadedImageWidth);
    let boxWidth = Math.abs(mapped[1] - mapped[0]);
    let x = mapped[0];
    let y = 0;
    let laneOrder = ii === 0 ? "First" : "Next";
    return [
      cmd("makeRectangle",[x,y,boxWidth,Math.floor(boxHeight)]),
      cmd('run',"Select "+laneOrder+" Lane")
    ] 
  }).flat();

  let linesToDraw = quantifications.map((q,iiBox) => {
    let { plotValues, integrationRanges } = q;


    let xValuesAtDomainScale = integrationRanges.map(range => range.map(x => x * plotValues.length) )

    //console.log(xValuesAtDomainScale);

    let yValues = plotValues.map((val) => {
      return (16 + iiBox + 250 * ( 1 - (val/totalMax) ) + 249 * iiBox);
    })

    //console.log(yValues);


    //val[x]: 
    // 16 + ii + (255 - val[x]) / 255 * (250 * ii)

    let linePoints = xValuesAtDomainScale.map( (range,ii) => range.map(xVal => {

      let xValTransform = [Math.floor,Math.ceil][ii%2];
      let floor = xValTransform(xVal);
      //console.log(floor);
      let remainderToCeil = xVal - floor;

      let valAtFloor = yValues[floor];

      /*
        //console.log(chalk.green(xVal));
        //console.log(chalk.blue(valAtFloor));
        */

      return [ floor * 4 , "findNearestVerticalPoint("+floor*4+","+valAtFloor+",'"+plotWindowName+"')" ];
      //since we don't know how imageJ interpolates
      //we'll just take the value at floor for now

    }))

    let regularPoints = xValuesAtDomainScale.map( (range,ii) => range.map(xVal => {

      let xValTransform = [Math.floor,Math.ceil][ii%2];
      let floor = xValTransform(xVal);
      //console.log(floor);

      let valAtFloor = yValues[floor];

      /*
        //console.log(chalk.green(xVal));
        //console.log(chalk.blue(valAtFloor));
        */

      return [ floor * 4 , valAtFloor ];
      //since we don't know how imageJ interpolates
      //we'll just take the value at floor for now

    }))


    let processPointsCmds = regularPoints.map(pt => cmd("processEndpoints",[pt.flat(),"'"+plotWindowName+"'"].flat()));

    let firstSetOfCmds = linePoints.map(pt => {
      return cmd("drawLine",pt.flat()).replace('"','')
      //cmd("makeLine",pt.flat()).replace('"',''),
      //cmd("setTool","wand"),

    });

    return [/*firstSetOfCmds,*/ processPointsCmds]


  }).flat()



  let macro = [
    cmd("selectWindow",rawImageWindowName),
    ...makeRotatedRectangleCommands(ls,uploadedImageWidth,annotationHeight),
    cmd("rename",cropWindowName),
    ...boxSetupCommands,
    cmd("run","Plot Lanes"),
    cmd("rename",plotWindowName),
    cmd("selectWindow",plotWindowName),
    ...linesToDraw,
  ]

  //console.log(macro.join('\n'));
  return macro.join('\n');

}

const makeRotatedRectangleCommands = (ls,imageWidth,annotationHeight) => {

  let scaledRotatedRectCoords = [...ls,annotationHeight].flat().map(x => x*imageWidth);

  let commands = [
    'makeRotatedRectangle('+scaledRotatedRectCoords.join(',')+');',
    'run("Straighten...");'
  ]
  return commands;
}


const cmd = (title,args) => {

  let dressed = Array.isArray(args) ? args.join(',') : '"'+args+'"';


  //need to ensure that if it's not a list, we still put //brackets on either side of the argument
  let finalArgString = '(' + dressed + ');';

  let command = title + finalArgString;
  return command;

}








/*

 Tested on a file called:
 "testForImageJ.ti.t2.t3",
 ImageJ created a new window called:
 "testForImageJ.t1.t2-1.t3"


*/


/*
if( indexOfLastPeriod === -1 ){
  straightenedWindowName = filename + '-1';
}else{
  straightenedWindowName = filename.slice(0,indexOfLastPeriod) + '-1' + filename.slice(indexOfLastPeriod);
}
*/

/*
let pathSplit;

if( typeof(navigator) !== typeof(undefined) ){
  function isMacintosh() {
    return navigator.platform.indexOf('Mac') > -1
  }

  function isWindows() {
    return navigator.platform.indexOf('Win') > -1
  }

  let platform = navigator.userAgentData.platform;

}else{
  pathSplit = '/';
}
*/


//



//let boundaries = 
/*
let xStarts = boundaries.map((bounds,ii) => {
  let mapped = bounds.map(x => x*imageWidth);
  return mapped[0];
})

//let xStartsCmd = 'xStarts = newArray('+xStarts.join(',') + ')';
//let captureLanesCmd = 'captureLanes(xStarts, '+imageWidth+',
*/




//console.log({linesToDraw});






