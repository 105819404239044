import onUploadFilesSelected from './onUploadFilesSelected';
import { getFilesFromEvent } from './AppImageUploader';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
export default function ImageUploadButton(){

  let dispatch = useDispatch();


  const fileUploader = useRef(null);

  return(
    <>

      <input onChange={e => onUploadFilesSelected(getFilesFromEvent(e),dispatch)} ref={fileUploader} style={{display:'none'}} type="file" class="hidden" multiple={"multiple"}/>

      <button onClick={() => fileUploader.current.click()}
        style={{ 
          border:'1px solid #ccc',
          fontWeight:'bold',
          fontSize:12,
        }} class="small-margin btn btn-primary"><div>Upload Images</div>
        <div style={{fontSize:8, margin:0}}>
          (JPEG,PNG,TIF)
        </div>
      </button>

    </>
  )

}
