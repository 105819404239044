import { useState, useRef, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import { useDispatch } from 'react-redux';
import C from './Constants';
export default function ExitTutorialButton(){

  let dispatch = useDispatch();

  

  return (
    <div 
      style={{position:'relative'}}
      id={"exit-tutorial"}>
      <button 
        onClick={(e) => {
          dispatch({
            type:C.confirmExitTutorial
          })
        }}

        tutorialButton={"exit-tutorial"}
        style={{
          marginRight:10,
          fontWeight:500
        }}
        class={"btn btn-danger btn-sm"}>
        <b>Exit Tutorial</b>
      </button>
    </div>
  )
}
