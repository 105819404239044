import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
import { useContext } from 'react';
import { QuantificationListContext } from './Contexts';

export default function RemoveCropFromQuantificationListButton({_id,onRemove}){

  const quantificationListContext = useContext(QuantificationListContext);

  let { quantificationListDispatch } = quantificationListContext || {};
  return (
    <div 

      onClick={() => {

        if( onRemove ){
          onRemove();

        }

        else if( quantificationListDispatch ){
          quantificationListDispatch({
            type:"remove",
            ids:[_id]
          })
        }

      }}
      class="sg-row dark-hoverable"
      style={{
        // background:'pink', 

        borderRadius:20,
        alignItems:'end', 
        position:'relative',
        cursor:'pointer',
        //height:'100%',
        marginRight:10,

        //width:50,
      }}>

      <Tooltip enterDelay={0} placement="top" title={"Remove"}>

        <HighlightOffIcon/>
      </Tooltip>
    </div>
  )

}
