import usePresentSelector from './usePresentSelector';
import G from './Getters';
import A from './ActionCreators';
import { ANNOTATION } from './UIConstants';
import { IMAGE_SETS, IMAGE_UPLOADS } from './RecordTypes';
import ImageManagement from './ImageManagement';
import ImageCroppingContainer from './ImageCroppingContainer';
import { FilesystemStore } from './Contexts';
//import HelpPopover from './HelpPopover';
//import ImageSetSelectionSidebar from './ImageSetSelectionSidebar';

import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function ImageAnnotation({showSidebar}){


  let state = usePresentSelector(state => state);
  let selectedFilesystemItemId = state.ui[ANNOTATION].selectedAnnotationsFilesystemId;

  



  let dispatch = useDispatch();


  if( selectedFilesystemItemId && G.isArchived(state,{_id:selectedFilesystemItemId}) ){
    dispatch(A.setSelectedAnnotationsItemContext({_id:null}));
  }

  const imageSets = usePresentSelector(state => Object.values(state.data.imageSets)); 

  let [ focusedFsItemId, setFocusedFsItemId ] = useState(selectedFilesystemItemId||null);

  if( focusedFsItemId && G.isArchived(state,{_id:focusedFsItemId}) ){
    setFocusedFsItemId(null);
  }

  

  let selectedImageId;
  if( focusedFsItemId ){
    selectedImageId = G.getSelectedImageIdFromFsFocus(state,{focusedFsItemId});
  }


  const [resizingImageManagement, setResizingImageManagement] = useState(false);
  let [imageManagementWidth,setImageManagementWidth] = useState(360);

  let croppingContainerContent;
  if( selectedImageId ){
    croppingContainerContent = (
            <ImageCroppingContainer key={focusedFsItemId} selectedImageId={selectedImageId} showSidebar={showSidebar} imageManagementWidth={imageManagementWidth}/>
        )
  }else{
    croppingContainerContent = (
        <div style={{fontSize:28, textAlign:'center',marginTop:200}} class="full-width">
          <div>
          Click an image on the
          <br/>
          left to annotate (crop).
          </div>

        </div>
      )
  }

  
  
  const mouseUp = () => setResizingImageManagement(false);
  const mouseMove = e => {
    if( resizingImageManagement === true ){
      setImageManagementWidth(e.clientX);
    }else{
    }
  }
  useEffect(() => {
    window.addEventListener('mouseup',mouseUp);
    window.addEventListener('mousemove',mouseMove);
    return () => {
      window.removeEventListener('mouseup',mouseUp);
      window.removeEventListener('mousemove',mouseMove);
    }
  })
 
  
  

  return (



    <div id="image-annotation-container" style={{position:'relative',width:'100%',height:'100%'}} class="sg-row">
      
        
      <div style={{width:imageManagementWidth,background:'white',height:'100%',
        //borderRight:'1px solid #ccc',
        position:'relative'}}>

        <FilesystemStore.Provider value={{ 
          isFilesystemItemSelected:(_id) => {
            return _id === focusedFsItemId;
          },
          onFilesystemItemEvent:({
            _id,
            event
          }) => {
            //alert("event: " + JSON.stringify(event));
              let itemType = G.getItemType(state,_id);
              if( [IMAGE_SETS,IMAGE_UPLOADS].includes(itemType) ){
                dispatch(A.setSelectedAnnotationsItemContext({_id}));
                setFocusedFsItemId(_id);
              }
          }
        }}>

          <ImageManagement {...{ state }}/>
        </FilesystemStore.Provider>
          

        <div onMouseDown={e => {
          setResizingImageManagement(true);
        }}
          style={{
          cursor:'ew-resize',
          top:0,
        position:"absolute", right:0, height:'100%', width:4, background:'#ccc'}}/>
        
      </div>

      

      <div style={{height:'100%',
        //background:'blue'
        }} class="sg-row flex-expand">
      {croppingContainerContent}
      </div>

    </div>
  )

}
