//REQUIRE STATEMENTS

import sendMessageToAWS from './sendMessageToAWS';


import CreateGreyscale from './CreateGreyscale';
import ProcessMap from './ProcessMap';
import { batchActions } from 'redux-batched-actions';

import ProcessImage from './ProcessImage';


import G from './Getters';
import C from './Constants';
import ItemCreator from './ItemCreator';
import Id, { Ids } from './IdFactory';

import fetchImagesFromStorage from './fetchImagesFromStorage';
import persistMediaWithPresignedUrls from './persistMediaWithPresignedUrls';

import { getGlobalObject, isUsingWebdriver } from './versionConfig';
import fetch from 'cross-fetch';

import fetchPresignedPostUrls from './fetchPresignedPostUrls';
import { getResolvedItemTypeName, ANNOTATIONS, DIRECTORIES, IMAGE_SETS, IMAGE_UPLOADS } from './RecordTypes';

import readFilesIntoMemory from './readFilesIntoMemory';

import { post, get } from './io';

import { isCLI, getSessionInfo } from './versionConfig';
import SciugoBlob from './SciugoBlob';
import { DEFAULT_IMAGE_VERSION, 
  FILES_NOT_PERSISTED, 
  FILES_NOT_PERSISTED_TO_GIVEN_LOCATION 
} from './SciugoDefaults';

import { FILESYSTEM_NAME } from './Filesystem';

import Dialog from './DialogConstants';

import sendImageToServer from './sendImageToServer';


import getEvaluatedSyncObject from './getEvaluatedSyncObject';

import { WELCOME } from './UIConstants';

let ActionCreators = {} 


const PRECISE_MARKERS_ON_BOTH_SIDES_ENABLED = true;
//FOR A TEST, refer to test/futureTests/specificBandLabels.in


function getRequestsFromImageSpecList(presentState,imageSpecList,preferredImageVersion=DEFAULT_IMAGE_VERSION){

  let locationGroups = {};

  imageSpecList.forEach(spec => {
    let { imageId, version } = spec;
    version = version || preferredImageVersion;
    let location = G.getImageStorageLocation(presentState,{
      imageId,version
    })

    let storageId = [imageId,version].join('-');
    let locationQueryItem = {storageId,imageId,version};

    if( locationGroups[location] ){
      locationGroups.push(locationQueryItem)
    }else{
      locationGroups[location] = [locationQueryItem];
    }
  })

}
/*
register(C.fetchAndUpdate,function(args){
  return (dispatch,getState) => {
    let { overwri
  }
})*/
/*
register(C.fetchDiscounts,function(args){
  return (dispatch,getState) => {
    return dispatch(C.makeRequest,{
      route:'/getDiscounts',
    })
  }
})
*/

register(C.modifyRegionExpansionNodeItems);

register(C.moveExpansionTemplateNode);

register(C.setCellsValueProperties)

register(C.removeExpansionTemplateNode);

register(C.insertExpansionTemplateNodes, function(args){
  return (dispatch,getState) => {

    let { before, inside, after, nodeIdSuffix, nodeArgs } = args;

    let parentToNodeIdMap = {};
    let newParentNodeIds = {};
    
    let insertionPositions = {before,inside,after};

    let insertionLocation;
    let parentList;


    for(let pos in insertionPositions){

      parentList = insertionPositions[pos];
      if( parentList ){
        insertionLocation = pos;
        parentList.forEach(parent => {
          if( nodeIdSuffix ){
            parentToNodeIdMap[parent] = parent + '-' + nodeIdSuffix;
          }else{
            parentToNodeIdMap[parent] = Id()
          }

          newParentNodeIds[ parent ] = Id()
        })
        break;
      }
    }

    return dispatch({
      ...args,
      type:C.insertExpansionTemplateNodes,
      parentToNodeIdMap,
      newParentNodeIds,
      insertionLocation,
      parentList,
      nodeArgs
    })



  }

});

register(C.insertExpansionTemplateNode, function(args){
  return (dispatch) => {
    let { before, after, inside } = args;
    if( !before && !after && !inside ){
      throw Error("You must specify where to insert the new node with 'before', 'after' or 'inside'.");
    }
    dispatch({
      type:C.insertExpansionTemplateNode,
      nodeId:Id(),
      newParentId:Id(),
      ...args
    })
  }

})

register(C.setRegionExpansionNodeProperties)

register(C.splitRegionFormatCell,function(args){
  return (dispatch) => {
    dispatch({
      type:C.splitRegionFormatCell,
      newIds:Ids(3),
      ...args,
    })
  }
})

register(C.setCellCultureItem,function(args){
  return {
    type:C.setCellCultureItem,
    args
  }
});

register(C.addCellCultureItem,function(args){
  return {
    type:C.addCellCultureItem,
    args
  }
});

register(C.removeCellCultureItem,function(args){
  return {
    type:C.removeCellCultureItem,
    args
  }
})

register(C.removeCellCultureListItem,function(args){
  return {
    type:C.removeCellCultureListItem,
    args
  }
})

register(C.setCellCultureDate,function(args){
  return {
    type:C.setCellCultureDate,
    args
  }
})

register(C.setSubscriptionInfo);


register(C.fetchSubscriptionInfo,function(args){
  return (dispatch,getState) => {

    let { onSuccess, onFailure, ...dataArgs } = args || {};

    let { productId } = dataArgs;

    return dispatch(ActionCreators.makeRequest({
      route:'/getSubscriptionInfo',
      body:dataArgs,
      onSuccess:(res) => {
        let { data } = res;
        dispatch(ActionCreators.setSubscriptionInfo({...dataArgs,...data}))
        onSuccess && onSuccess(res);
      },
      onFailure:(res) => {
        onFailure && onFailure(res)
      }
    }))
  }
})

register(C.retryPayment,function(args){
  return (dispatch,getState) => {

    //server requests from stripe...
    //then we wait for webhooks to come through

    return new Promise((resolve,reject) => {


      let onResponseAction = ActionCreators.fetchSubscriptionInfo({
        ...args,
        onSuccess:() => resolve(),
        onFailure:() => resolve(),
      })

      dispatch(ActionCreators.makeRequest({
        route:'/retryPayment',
        body:args,
        onSuccess:() => {
          setTimeout(() => {
            dispatch(onResponseAction)
          },3000)
            //in practice, 
            //3 seconds doesn't work
            //but we'll leave this for now
            

          //the real solution is having it either 
          //1) poll for the answer
          //2) use a weboscket


        },
        onFailure:() => {
          dispatch(onResponseAction)
        }
      }))
    }).catch(e => {
      throw Error(e);
    })
  }
})


register(C.setWarnings);
register(C.registerReferrer,function(args){
  return (dispatch,getState) => {
    let { username } = args;
    return dispatch(ActionCreators.makeRequest({
      route:'/registerReferrer',
      body:{ username },
      onSuccess:() => { 
        dispatch(ActionCreators.setUserInfo({
          referrer:{
            referrerUsername:username
          }
        }))
      },
      onFailure:() => { }
    }))
  }

})

register(C.sendMessageToAWS,function(args){
  return (dispatch,getState) => {

    return sendMessageToAWS();


  }
})

register(C.cancelSubscription,function(args){
  return (dispatch,getState) => {
    let { productId } = args;



    return dispatch(ActionCreators.makeRequest({
      route:'/manageSubscription',
      body:{ productId, action:"cancel" },
      onSuccess:(res) => { 
        let { data } = res;
        dispatch(ActionCreators.setSubscriptionInfo(data))
      },
      onFailure:() => { }
    }))
  }
})

register(C.tryArchiveItems)

register(C.sendToCloudwatch,function(args){
  return (dispatch,getState) => {


  }
})


register(C.setSelectedAnnotationsItemContext)
register(C.setTutorialState);
register(C.confirmExitTutorial);
register(C.cancelExitTutorial);

register(C.exitTutorial,function(args){
  return (dispatch,getState) => {
   
  let userInfo = {
    onboardingTutorial:"exited"
  }

  return dispatch(ActionCreators.makeRequest({
    route:'/setUserInfo',
    body:userInfo,
    onSuccess:() => {
      dispatch(ActionCreators.setUserInfo({userInfo}))
      dispatch({type:C.exitTutorial, args});
    },
    onFailure:() => {

    }
  }))
 

  }
})

register(C.completeTutorial,function(args){
  return (dispatch,getState) => {

    let userInfo = {
      onboardingTutorial:"exited"
    }

    return dispatch(ActionCreators.makeRequest({
      route:'/setUserInfo',
      body:userInfo,
      onSuccess:() => {
        dispatch(ActionCreators.setUserInfo({userInfo}))
        dispatch({type:C.completeTutorial, args});
      }
    }))


  }
})

register(C.requestCheckoutLink);

register(C.downloadQuantificationValues);

register(C.clearAuthStatusReason);

register(C.requestPasswordRecoveryCode,function(args){
  return function(dispatch,getState){

    let rawUsername = args.user || args.email;

    let sendEmail;
    if( args.sendEmail === false ){
      sendEmail = false;
    }


    let resolvedArgs = {
      user:rawUsername.toLowerCase().trim(),
      sendEmail
    }
    dispatch({
      type:C.requestPasswordRecoveryCode,
      ...resolvedArgs
    })

    return post({
      route:'/initSendPasswordResetCode',
      state:getState(),
      body:{
        ...resolvedArgs,
        
      },
      onSuccess:(data) => {
        return dispatch({
          type:C.receiveSendPasswordRecoveryResponse,
          data:data.json
        })


        
        if( data.response === 'failed' ){
          dispatch({
            type:C.setPasswordRequestFailReason,
            reason:data.reason
          })
        }

        
        //going to get some 'response' (success/fail).

        //on success, we change the UI scene to enter-code-and-password.
        //on fail, display the message/reason
        
        //dispatch({type:C.answerSurveyPostSuccess});
      },
      onServerFailure:() => {

        

        return dispatch({
          type:C.receiveSendPasswordRecoveryResponse,
          data:{
            response:"failed",
            reason:"Server failed."
          }
          
        })
      },

      onInternetFailure:res => {},
    },dispatch)


  }
})

register(C.fetchEmailVerificationStatus,function(args){
  return (dispatch,getState) => {

    return new Promise((resolve,reject) => {
      dispatch(ActionCreators.makeRequest({
      route:'/getEmailVerificationStatus',
      onSuccess:({data}) => {
        dispatch(ActionCreators.setUserInfo({
          userInfo:{
            emailVerificationStatus:data
          }
        }))
        resolve();
      },
      onFailure:({data}) => {
        resolve();
      }
      }))
    })



  }
})

register(C.verifyEmailValidation,function(args){
  return (dispatch,getState) => {

    return new Promise((res,rej) => {
      return dispatch(ActionCreators.makeRequest({
        route:'/verifyValidation',
        body:{},
        onSuccess:() => {
          console.log("VERIFY VALIDATION SUCCESS!");
          dispatch(ActionCreators.answerSurvey({
            name:"emailValidationRequired",
            answer:{}
          })).then(() => {
            res();
          }).catch(e => {
            console.error(e);
          })

        },
        onFailure:() => {
          res();
        }
      }))
    })
  }

})

register(C.makeRequest,function(args){

  return (dispatch,getState) => {
    let { route,body,onSuccess, onFailure } = args;
    dispatch({
      type:C.makeRequest,
      route
    })

    function onReceiveResponse(res){
      let json = res.json;
      dispatch({
        type:C.receiveRequestResponse,
        route,
        json
      })
    }

    return post({
      route,
      body,
      state:getState(),
      onSuccess:(res) => {

        let { json } = res;
        onReceiveResponse(res);

        if( json.status === "success" ){
          onSuccess && onSuccess(json);
        }else if( json.status === "failed" ){
          onFailure && onFailure(json);
        }else{
          if( !json.status ){
            console.red && console.red("Received response without a status. All responses to `makeReqest` should have { status:(success|failed) }. With reason if failed and data (option) if success.")
          }
        }

      },
      onInternetFailure:() => {
        onReceiveResponse({
          json:{
            status:'failed',
            reason:'You are not connected to the internet. Re-connect and retry.'
          }
        })

        onFailure && onFailure();

      },
      onServerFailure:() => {
        onReceiveResponse({
          json:{
            status:'failed',
            reason:'Server failure.',
          }
        })
        onFailure && onFailure();
      },
      onFetchError:() => {
        onReceiveResponse({
          json:{
            status:'failed',
            reason:'Unexpected fetch failure. Try again.',
          }
        })

        onFailure && onFailure();

      }


    },dispatch)




  }






})

register(C.changePassword,function(args){
  return function(dispatch,getState){


    let resolvedArgs = { ...args } 
    
    if( !args.user ){
      let state = getState();
      resolvedArgs.user = state.passwordResetInfo.user;
    }



    dispatch({
      type:C.changePassword,
      code:args.code,
      user:args.user
    })



    return dispatch(ActionCreators.fetchUserAuthResponse({
      authType:'changePassword',
      authArgs:resolvedArgs,
      authAttemptId:Id()
    }))
  }
})

register(C.sendRecovery);

register(C.createGreyscale,function(args){
  return (dispatch,getState) => {
    let { imageId } = args;

    let state = getState();


    let imageUrl = G.getImageRecordUrl(state,{imageId,version:'raw-png',fallBackOnRaw:true})

    return CreateGreyscale({src:imageUrl}).then(greyscaleUrl => {


      dispatch({
        type:C.setMediaURL,
        imageId,
        version:'greyscale',
        url:greyscaleUrl
      })

    });
    


  }
})
register(C.moveRow,function(args){
  return (dispatch,getState) => {

    let state = getState();

    let { figurePanelId } = args;
    let { grid } = G.getFigurePanel(state,{figurePanelId});
    let cols = (grid[0] || []).length;
    let dim = grid.length * grid[0].length;

    args.newGroupIds = args.newGroupIds || (
      Array(cols).fill(dim).map(x => Id())
    );

    return dispatch({
      type:C.moveRow,
      ...args
    });

  }
});

register(C.answerSurveyPostSuccess);
register(C.answerSurveyPostFail);

register(C.answerSurvey,function(args){
  return (dispatch,getState) => {

    
    dispatch({
      type:C.answerSurvey,
      ...args
    })


    return post({
      route:'/answerSurvey',
      state:getState(),
      body:args,
      onSuccess:() => {
        dispatch({type:C.answerSurveyPostSuccess});
      },
      onServerFailure:() => {
        dispatch({type:C.answerSurveyPostFail});
      }
    },dispatch)

  }
})

register(C.markPossibleQuantificationAnnotationUpdatesSeen);

register(C.updateQuantificationParameters);
//register(C.updateProcessStatus)
register(C.openAuthModal);
register(C.updateQuantificationAnnotationToFigureAnnotation);
register(C.modifyQuantificationList);

register(C.setIntegrationRange)
register(C.registerProcesses);
register(C.updateProcessStatus);

register(C.startQuantification,function(args){
  return (dispatch,getState) => {
    let { annotationIds, config, source } = args;

    let state = getState();

    let processArgList = G.getQuantificationProcessArgsFromAnnotationIds(state,{annotationIds});

    let annotationIdsToQuantify;
    if( config && config.force ){
      annotationIdsToQuantify = annotationIds;
    }else{
      let unquantifiedAnnotationIds = annotationIds.filter(_id => {
        let atn = G.getData(state,{_id,itemType:ANNOTATIONS});
        return !atn.quantifications;
      })
      annotationIdsToQuantify = unquantifiedAnnotationIds;
    }

    let processes = annotationIdsToQuantify.map((annotationId,ii) => ({
      _id:Id(),
      annotationId,
      ...processArgList[ii]
    }))

    

    dispatch(ActionCreators.registerProcesses({
      processes
    }))


    return Promise.all(processArgList.map( (quantificationArgs,ii) => {
      return new Promise((resolve,rej) => {

        let { _id } = quantificationArgs;
        let thisConfig = (config||[])[ii];


        return ProcessImage({
          config:thisConfig,
          ...quantificationArgs,
        }).then(q => {



          if( isCLI() && thisConfig ){
            if( thisConfig.queueResults ){
            getGlobalObject().__resultQueue = [ 
              ...(getGlobalObject().__resultQueue||[]),
              {_id,quantifications:q}
            ]
            }else if( thisConfig.fail ){
              throw Error("Forced error.");
            }

          }else{
            dispatch(ActionCreators.setQuantifications({
              _id,
              quantifications:q,
              quantificationArgs,
              source
            }))
          }
          
          resolve();

        }).catch(e => {

          console.log("HELO catch");

          ActionCreators.setQuantifications({
            _id,
            error:true
          })

          //throw Error("QUANTIFICATION ERROR");
          rej("QUANTIFICATION ERROR");


          resolve(
            dispatch(ActionCreators.updateProcessStatus({
            }))
          )

        })
      })
    }))









  }
})

register(C.resetLanesAndClearQuantification);
register(C.requestQuantificationAccess);
register(C.mediaFetchFailed)

register(C.sendDomEvents,function(){
  return (dispatch,getState) => {
    return post({
      route:'/sendDomEvents',
      body:window.events,
      onSuccess:() => {},
      onServerFailure:() => {}
    },dispatch)
  }
})

register(C.discardPendingRecords);
register(C.clearPostData);
register(C.showFetch);
register(C.persistMediaWithPresignedUrls,persistMediaWithPresignedUrls);

register(C.fetchPresignedPostUrls,fetchPresignedPostUrls);
register(C.receivedPresignedPosts);

register(C.pushNotification,function(args){
  return (dispatch,getState) => {
  dispatch({ type:C.pushNotification, ...args, _id:(args._id || Id())})
  }
})
register(C.popNotification);

register(C.fetchThreads,function(args){
  return (dispatch,getState) => {
    return dispatch(ActionCreators.receivedThreadUpdateNotification(args||{}));
  }
})

register(C.clearAllNotifications);

register(C.threadUpdateRequested);
register(C.threadUpdateReceived);
register(C.updateThread);

register(C.receiveTypingNotification);

register(C.sendTypingNotification,function(args){
  return (dispatch,getState) => {

    let postArgs = {
      route:'/sendTypingNotification',
      body:{
        to:"admin",
        ...args
      },
      state:getState(),
      onSuccess:(()=>{}),
      onServerFailure:(() => {}),
      onInternetFailure:(() => {}),
      dispatch
    }
    return post(postArgs,dispatch);


  }
});

register(C.receivedThreadUpdateNotification,function(args){
  return (dispatch,getState) => {



    dispatch(ActionCreators.threadUpdateRequested(args));
    let postArgs = {
      route:'/getThread',
      body:args,
      state:getState(),
      onSuccess:(data => {
        let { json } = data;

        dispatch(ActionCreators.threadUpdateReceived({thread:json}))

        if( json ){
          let state = getState()

          let currentThread = state.threads[json._id];

          let thisUpdatesCurrentThread = (
            !currentThread || (!currentThread.lastUpdate) || (json.lastUpdate > currentThread.lastUpdate)
          );

          if( thisUpdatesCurrentThread ){
            dispatch(ActionCreators.updateThread({thread:json}))

            let threadId = json._id;
            return dispatch(ActionCreators.updateThreadMessagesStatus({status:"delivered",threadId}));

          }

          


        }

      }),
      onServerFailure:(() => {}),
      onInternetFailure:(() => {}),
      dispatch
    }


    return post(postArgs,dispatch)

  }
})

register(C.updateThreadMessagesStatus,function(args){
  return (dispatch,getState) => {
    let { status } = args;

    let state = getState();
    let personId = state.personId;

    let thread = G.getThread(state,args);

    if( !thread ){
    }

    let messagesNeedingToUpdateStatus = Object.values(thread.messages).filter(msg => {
      let messageNotSentByThisClient = msg.from === "admin";
      let personId = Object.keys(msg[status]).find(x => x!=="admin");

      let thisPersonWouldNeedToUpdateThatMessageStatus = !msg[status][personId];

      return (
        thisPersonWouldNeedToUpdateThatMessageStatus
        && 
        messageNotSentByThisClient
      );
      
    }).map(msg => msg._id);

    if( messagesNeedingToUpdateStatus.length === 0 ){
      return;
    }


    let route = ({delivered:'/receiveMessages',read:'/readMessages'})[
      status
    ];

    if( status === "read" ){

      let state = getState();
      dispatch(ActionCreators.readMessages({
          threadId:args.threadId,
          messageIds:args.unreadMessages
        }))
    }

    let body = ({
      ...args,
      messageIds:messagesNeedingToUpdateStatus
    });


    let postArgs = {
      route,
      body,
      state,
      onSuccess:(() => {}),
      onServerFailure:(() => {}),
      onInternetFailure:(() => {}),
      dispatch
    }

    return post(postArgs,dispatch);


  }
})

register(C.readMessages,function(args){

  return (dispatch,getState) => {
    //we update our thread
    dispatch({
      type:C.readMessages,
      ...args
    })

    let postArgs = {
      route:'/readMessages',
      body:args,
      state:getState(),
      onSuccess:(() => {}),
      onServerFailure:(() => {}),
      onInternetFailure:(() => {}),
      dispatch
    }

    return post(postArgs,dispatch);

    //and notify the server
  }

})

register(C.resendMessage,function(args){
  return (dispatch,getState) => {
    let state = getState();
    let message = G.getMessage(state,args);


    let sendMessageArgs = {
      ...args,
      message:{...message}
    }

    delete sendMessageArgs.message.sendFailure

    dispatch({
      type:C.resendMessage,
      ...sendMessageArgs
    })

    return dispatch(ActionCreators.sendMessage(
      sendMessageArgs
    ));
  }
})

register(C.receiveMessage)
register(C.messageSendFailure);

register(C.sendMessage, function(args){
  return (dispatch,getState) => {

    //  basically we create a message (it gets tacked on to our message object.)
 
    let state = getState();

    let threadId = args.threadId || Id();

    args.threadId = threadId;

    let thread = state.threads[ threadId ];
    if(thread === undefined){
      dispatch(ActionCreators.createThread(args))
    }

    let messageArgs = args.message;
    if( !messageArgs ){
      debugger;
    }
    args.message._id = messageArgs.messageId || messageArgs._id || Id();
    args.message.sentTimestamp = Number(Date.now());


    dispatch({
      type:C.sendMessage,
      ...args
    })

    //  then we notifiy the server that we've send that message out



    let postArgs = {
      route:'/sendMessage',
      body:args,
      state:getState(),
      onSuccess:(r => {
        let { json } = r;
        //dispatch(ActionCreators.messageDelivered(args))
      }),
      onServerFailure:(() => {
        dispatch(ActionCreators.messageSendFailure(args));
      }),
      onInternetFailure:(() => {
        dispatch(ActionCreators.messageSendFailure(args));

      }),
      dispatch
    }


    return post(postArgs,dispatch);

  }
})

//register(C.messageDelivered)
register(C.receiveReadReceipt)



register(C.enterApp);

register(C.rejectExpiredRequestResponse);

register(C.dispatchGetter);
register(C.deleteItem);

function getMoveImagesToImageSetArgsIfNecessary(state,args){

  //3 cases where we need to call moveImagesToImageSet:
  //1. moving imageSet(singleton) to another imageSet
  //2. moving imageUpload (from multi-imageSet) to dir
  //3. moving imageUpload to imageSet
  let { _id, to } = args;

  let receivingType = G.getItemType(state,to);
  let movingType = G.getItemType(state,_id);

  let imageSetIsReceiving = receivingType === IMAGE_SETS;
  let dirIsReceiving = receivingType === DIRECTORIES;
  let movingImageUpload = movingType === IMAGE_UPLOADS;
  let movingImageSet = movingType === IMAGE_SETS;

  if( movingImageSet && imageSetIsReceiving ){
    let imageSetIdsOfMover = G.getImageSetImageIds(state,{imageSetId:_id});
    let isSingletonImageSet = imageSetIdsOfMover.length === 1;

    if( isSingletonImageSet ){

      return {
        imageIds:[imageSetIdsOfMover[0]],
        destinationImageSetId:to
      }

    }else{
      return { 
        dialogName:Dialog.DRAGGING_IMAGE_SETS_WITH_MULTIPLE_IMAGES_UNSUPPORTED,
        args:{}
      }
    }

  }else if( movingImageUpload ){
    let isInSingletonImageSet = G.isImageIdAnOnlyChildInItsImageSet(state,{imageId:_id});

    if( dirIsReceiving && !isInSingletonImageSet ){
      ///return stuff here

      return {
        imageIds:[_id],
        destinationImageSetId:(args.destinationImageSetId||Id()),
        imageSetFilesystemDestination:to
      }


    }else if( imageSetIsReceiving ){


      return {
        imageIds:[_id],
        destinationImageSetId:to
      }


    }
  }

  return null;

}

register(C.moveFilesystemItem,function(args){
  return (dispatch,getState) => {

    let state = getState();

    //let record = G.getRecord(state,{_id:args._id});

    let itemType = getResolvedItemTypeName(args.itemType);

    let { from, to, fromTopLevelDirectory, toTopLevelDirectory, destinationImageSetId } = args;


    if( to ){ 
      let toDestination = G.getRecord(state,{_id:to});
      if( toDestination.itemType === IMAGE_UPLOADS ){
        to = G.getImageSetIdByImageId(state,to);
      }
    }


    let finalArgs = {...args,itemType};

    if( args.itemType === IMAGE_UPLOADS && G.isImageIdAnOnlyChildInItsImageSet(state, {imageId:args._id}) ){

      args.itemType = IMAGE_SETS;
      args._id = G.getImageSetIdByImageId(state,args._id);

      finalArgs.itemType = IMAGE_SETS;
      finalArgs._id = args._id; 

      
    }/*else if( args.itemType === IMAGE_SETS ){
      let record = G.getRecord(state,{_id:args._id});
      let images = record.data.images;
      if( images.length > 1 ){

      }

    }*/


    let thisId = args._id;

    to = to||G.getTopLevelDirectoryId(state,{type:toTopLevelDirectory});

    let fsNameOfItemBeingMoved = 
      G.getFilesystemName(state,finalArgs);

    if( thisId === to ){
      throw Error("You can't move a directory to itself!");
    }


    let clashingId = G.findItemIdWithNameInDir(state,{
      name:fsNameOfItemBeingMoved,
      directoryId:to
    });


    if( clashingId && clashingId !== thisId ){

      let newDirName = G.getFilesystemName(state,{
        _id:to,
        itemType:G.getItemType(state,to)
      });

      dispatch(ActionCreators.createDialog({
        source:C.moveFilesystemItem,
        dialogName:Dialog.FILESYSTEM_NAME_COLLISION_ON_MOVE,
        args:{
          newDirName,
          fsNameOfItemBeingMoved
        }
      }))

    }else{

      let moveImagesToImageSetArgs = getMoveImagesToImageSetArgsIfNecessary(state,{_id:thisId,to, destinationImageSetId });

      if( moveImagesToImageSetArgs ){
        if( moveImagesToImageSetArgs.dialogName ){
          return dispatch(ActionCreators.createDialog(
            moveImagesToImageSetArgs
          ))
        }else{
          dispatch({
            type:C.moveImagesToImageSet,
            ...moveImagesToImageSetArgs
          })
        }
      }else{

        dispatch({
          type:C.moveFilesystemItem,
          ...finalArgs,
          //itemType,
        })
      }
    }
  }
});

register(C.setFilesystemName,function(args){

  return (dispatch,getState) => {
    let state = getState();


    let {itemType,_id,parentDirectoryId} = args;
    let newFilesystemName = args[FILESYSTEM_NAME];

    parentDirectoryId = parentDirectoryId || G.getParentDirectoryId(state,{
      _id,
      itemType
    })

    if(parentDirectoryId === null){

      throw Error("Can't set fileystem name of item with no parent directory. This implies were trying to change a root directory which is pointless because the filesystemName of a root directory is not displayed.");

    }

    let clashingItemIdDirectory = G.findItemIdWithNameInDir(state,{name:newFilesystemName,directoryId:parentDirectoryId});

    let thereIsANameClash = Boolean(clashingItemIdDirectory);

    let clashingItemIsDifferentItem = thereIsANameClash && (
      clashingItemIdDirectory !== _id
    )

    if( clashingItemIsDifferentItem ){

      dispatch(ActionCreators.createDialog({
        source:'setFilesystemName',
        dialogName:Dialog.FILESYSTEM_NAME_COLLISION_ON_SET,
        args:{ newFilesystemName }
      }))

    }else{
      dispatch({
        type:C.setFilesystemName,
        ...args
      })
    }



  }


});


register(C.overwriteAllOccurencesOfValueAtPath);

register(C.finishProcess,function(actionArgs){
  return (dispatch,getState) => {

    let state = getState();

    let {processId, output, error} = actionArgs;


    dispatch({type:C.finishProcess, ...actionArgs});
  }
})

function handleImageReadError({
  args,
  dispatch
}){


  let { filename } = args;

  let alertArgs = {
    alert:'imageReadError',
    data:{ filename }
  }

  /*
  dispatch(ActionCreators.createDialog({
    source:'handleImageReadError',
    dialogName:Dialog.IMAGE_READ_FAILURE
  }));
  */

  return dispatch(ActionCreators.alertServer(alertArgs))

}

register(C.startProcess,function(actionArgs){
  return (dispatch,getState) => {
    let { processId, processName, args } = actionArgs;

    if( processName !== 'convertImageType' ){
      throw Error("Unrecognized process `"+processName+"`");
    }

    processId = processId || Id();
    let state = getState();

    dispatch({
      type:C.startProcess,
      processName,
      processId,
      args,
    })






    return ProcessMap[processName](state,processId,args).then( async (processOutputArgs) => {



      if( processName === "convertImageType" ){

        let { error, dataURL } = processOutputArgs;
        if( !error ){
          let localBlobUrl = await getBlobUrlFromProcessOutputArgs({processOutputArgs,...actionArgs});
          processOutputArgs.dataURL = localBlobUrl;

          dispatch({
          type:C.finishImageProcessing,
          error,
          processId,
          outputArgs:processOutputArgs,
          inputArgs:args
        })
        }else{

          let image = G.getImage(state,{_id:args.input.imageId, pendingRecords:true});
          let filename = image.filename;


        

          return dispatch(ActionCreators.updateMediaProcessing({
            imageId:args.input.imageId,
            errors:[error],
            status:"failed"
          }))


          /*return handleImageReadError({
            args:{
              filename,
            },
            dispatch
          });*/
        }
      }
        
    }).catch(error => {


      dispatch(ActionCreators.updateMediaProcessing({
        imageId:args.input.imageId,
        errors:[error],
        status:"failed"
      }))
    })


      
      //promise caught from the ProcessMap...
      //throw(error);
  }
})

async function getBlobUrlFromProcessOutputArgs({
  processOutputArgs,dispatch,args,
  processId
}){

  let { dataURL }  = processOutputArgs;
 

  let { outputMime } = args;
  if( !outputMime.includes("image") ){
    outputMime = "image/"+outputMime;
  }
  let blob = await SciugoBlob(dataURL,outputMime)
  let localBlobUrl = URL.createObjectURL(blob);
  return localBlobUrl;
}

register(C.updateMediaProcessing);
register(C.setMediaURL);
register(C.startSyncImageCloudStorage);
register(C.finishSyncImageCloudStorage);

register(C.fetchImages,function(args={}){
  return (dispatch,getState) => {

    let state = getState();

    let { imageSpecList, imageIdVersionSpecificationList } = args;


    //get everything that hasn't yet been fetched
    //regardless of version

    let corruptImages = [];
    let imagesToFetchInfoList = [];
    if( imageSpecList ){
      imageSpecList.forEach(spec => {
        let imageId = spec._id || spec.imageId;
        let _id = spec._id || spec.imageId;
        let resolvedSpec = {
          imageId,
          _id,
          ...spec
        };




        let storedRersourceId = G.getRemoteStorageResourceId(state,{imageId,version:spec.version});

        if( storedRersourceId ){
          imagesToFetchInfoList.push( {
            ...spec,
            imageId,
            _id,
            storageLocation:G.getImageStorageLocation(state,resolvedSpec)
          } )
        }else if( spec.version === "greyscale" ){

          dispatch({
            type:C.fetchImages,
            imagesToFetchInfoList:[{
              imageId,
              version:"greyscale",
            }]
          });

          dispatch(ActionCreators.createGreyscale({imageId}));

        }else{

          corruptImages.push({
            imageId,...spec
          })
        }
      })
    }else{
      let unfetchedImageVersionSpecificationList = 
        G.getStorageLocationsOfMissingCachedImages(state,{imageSpecList});
        imagesToFetchInfoList = unfetchedImageVersionSpecificationList;
    }


    if( corruptImages.length > 0 ){
      corruptImages.forEach(corruptSpec => {
        dispatch(ActionCreators.mediaFetchFailed({
          ...corruptSpec,
          corrupt:true
        }))
      })
    }

    if( imagesToFetchInfoList.length > 0 ){
      dispatch({
        type:C.fetchImages,
        imagesToFetchInfoList
      });

      return fetchImagesFromStorage({state,imagesToFetchInfoList,dispatch});
    }else{
      return;
    }
    


  }
})

register(C.mergePersistedUserDataWithCurrentSessionData)

register(C.setFigurePanelStyle);

register(C.createSampleLayout, function(args){
  //ideally, we'd use createItem
  //  but we can't do that straight up because
  //
  //this is a patch on some horrible technical debt
  //which basically assumes the location of any table in the app
  //we want to be able to create a sampleLayout (which would use the same + enhanced table logic as figurePanel)
  //and then only way to access it is through figurePanels 

  //probably, the way to overcome this will be to separate tables, styles etc.
  //damn it.

  return (dispatch,getState) => {
    let state = getState();

    let figurePanelId = args.figurePanelId;
    let figurePanel = G.getData(state,{itemType:'figurePanels', _id:figurePanelId});
    if(!figurePanel){


      let itemCreatorArgs = { 
        _id:args.figurePanelId,
        type:'figurePanel',
        figurePanelType:'sampleLayout',
        lanes:12,
        headerColumns:{left:["Factor"]},
      }

      let { data, meta } = ItemCreator(state,itemCreatorArgs);

      dispatch({
        type:C.createItem,
        itemType:'figurePanel',
        figurePanelType:'sampleLayout',
        data, meta
      })
    }

    let sampleLayoutArgs = {
      type:'sampleLayout',
      figurePanelId,
      ...args
    }

    dispatch({
      type:C.createItem,
      itemType:'sampleLayout',
      ...ItemCreator(state,sampleLayoutArgs)
    })
  }

})

register(C.connectItems);
register(C.disconnectItems);


register(C.transferItemLink)
register(C.setItemTitle);
register(C.setLaneOffsets);
register(C.setQuantifications);
register(C.addItemLinks);
register(C.removeItemLinks);
register(C.selectFigurePanel);
register(C.syncFailure);
register(C.syncSuccess);
register(C.receiveSyncResponse);
register(C.processSyncResponse);
register(C.setUserSettings);

register('syncChanges',function(syncArgs){
  return (dispatch,getState) => {

    return syncChangesIfNecessary(dispatch,getState,syncArgs);
  }
})

const isImageType = type => ['image','crop'].includes(type);


function assertRequiredKeysPresent(funcName,args,requiredKeys){

  let missingKey = requiredKeys.find(
    key => !(key in args)
  )
  if( missingKey ){
    let type = args.type;
    throw Error("Action '"+funcName+"' requires '"+missingKey+"', but it's missing.");
  }

}



function register( funcName, func ){

  if( funcName === undefined ){
    throw Error("Received undefined funcName.");
  }
  if( funcName in ActionCreators ){
    throw "Error (in ActionCreators):" + 
      "\n\tFunction '"+funcName+"' is already defined.";
  }

  if( func === undefined ){
    ActionCreators[funcName] = function(args){
      return {
        type:funcName,
        ...(args||{})
      }
    }
  }else if( typeof(func) === 'object' ){
    let keysNeedingIds = func.createIfMissing || [];
    let requiredKeys = func.require;


    ActionCreators[funcName] = function(args){

      if( requiredKeys && !args.__IGNORE_MISSING_KEYS__ ){
        assertRequiredKeysPresent(funcName, args,requiredKeys);
      }

      let idsToFill = {};
      keysNeedingIds.forEach(key => {
        idsToFill[key] = Id()
      })
      return {
        type:funcName,
        ...idsToFill,
        ...(args||{}),
        __registeredName:funcName,
      }
    }
  }
  else if( typeof(func) === 'string' ){
    ActionCreators[funcName] = () => ({
      type:func,
      __registeredName:funcName
    });
  }else{ 

    ActionCreators[ funcName ] = function(args){
      
      if( getGlobalObject().__testMeta ){
          /*testDashboard({
            title:funcName
          })*/
      }

      let toReturn = func(args);
      toReturn.__registeredName = funcName;

      return toReturn;
    }
  }

}



register(C.createItem,function(args){
  return function(dispatch,getState){

    let state = getState();
    let resolvedItemTypeVariable = args.itemType || args.type;
    args.type = getResolvedItemTypeName(resolvedItemTypeVariable);

    let createdItem = ItemCreator(state,args);
    let { data, meta, error } = createdItem;



    if( error ){
      return dispatch(ActionCreators.createDialog(error))
    }else{
      let { type, ...remainingArgs } = args;
      let createItemDispatch = dispatch({
        type:C.createItem,
        itemType:getResolvedItemTypeName(args.type),
        data, 
        meta,
        //...remainingArgs
      })

      if( args.onSuccess ){

        let { _id } =  meta;
        let state = getState();
        let recordExists = G.doesRecordExistInCache(state,{_id, itemType:resolvedItemTypeVariable}); 
        let success = recordExists;
        if( success && Array.isArray(args.onSuccess) ){


          args.onSuccess.forEach(action => {
            dispatch(action);
          })

        }else{
        }

      }

      
    }
  }

})

register(C.setAnnotationLaneCount);

register('receiveResponse',function(json){
  return {
    type:C.RECEIVE_RESPONSE,
    json
  }
})

register('receiveLoginResponseFailure',function(err){
  return {
    type:C.FAILED_TO_INJECT_LOGIN_RESPONSE,
    err:err
  }
})


register('testPromise',function(){
  return (dispatch,getState) => 123;
})

register(C.createUnexpectedServerErrorDialog,function(args){
  return (dispatch,getState) => {
    dispatch(ActionCreators.createDialog({
      dialogName:Dialog.UNEXPECTED_SERVER_ERROR,
      args
    }));
  }

})

register(C.createNoInternetDialog,function(args){
  return (dispatch,getState) => {
    dispatch(ActionCreators.createDialog({
      dialogName:Dialog.NO_INTERNET_CONNECTION,
      args,
      addIfNotYetQueued:true
    }));
  }
})



register(C.setSessionConfig)

function syncChangesIfNecessary(dispatch,getState,syncArgs){

  //let state = getState();
  /*if( G.isInTutorial(state) ){
    return { }
  }*/

  syncArgs = syncArgs || {};
  syncArgs.syncId = syncArgs.syncId || Id();
  syncArgs.timestamp = syncArgs.timestamp || Number(Date.now())

  let timeout = syncArgs.timeout || 5000;

  let syncObject = getEvaluatedSyncObject(getState(),syncArgs);

  if( Object.keys(syncObject.data).length > 0 ){
    dispatch({type:C.sendSyncRequest,syncObject,timestamp:Number(Date.now())});

    let { syncId } = syncObject;

    let timeoutConfig = {
      timeout,
      onTimeout:() => {
        dispatch(ActionCreators.createDialog({dialogName:Dialog.REQUEST_TIMEOUT}))
        dispatch(ActionCreators.updateRequestStatus({
          requestId:syncId,
          status:C.TIMEOUT
        }))
      }
    }


    let onSuccess = res => {
      let { json } = res;

      let status = res.status;

      let state = getState();
      if( G.getRequestStatus(state,{requestId:syncArgs.syncId}) !== 'PENDING' ){
        return dispatch({type:C.rejectExpiredRequestResponse,
          requestId:syncArgs.syncId,
          json
        })
        
      }

      try{
        dispatch({type:C.receiveSyncResponse,json})

        dispatch({type:C.processSyncResponse,json})
        
      }catch(error){
        
        dispatch(ActionCreators.createDialog({
          source:'syncChangesIfNecessary',
          dialogName:Dialog.ERROR_PROCESSING_SERVER_RESPONSE,
          args:{
            error:error.message,
            errorStack:error.stack
          }
        }))

        if( isCLI() ){
          throw error;
        }
      }
    }

    let onServerFailure = res => {

      dispatch(ActionCreators.updateRequestStatus({
        requestId:syncId,
        status:C.FAILURE
      }))

      return dispatch(ActionCreators.createDialog({
        dialogName:Dialog.SAVE_FAILED_ON_SERVER,
        source:'syncChangesIfNecessary'
      }))
    }



    let postArgs = {
      route:'/sync',
      body:syncObject,
      state:getState(),
      onSuccess,
      onServerFailure,
      onInternetFailure:() => {

        dispatch(ActionCreators.createNoInternetDialog());

        dispatch(ActionCreators.updateRequestStatus({
          requestId:syncId,
          status:C.FAILURE
        }))


      },
      timeoutConfig,
      dispatch
    }

    return post(postArgs,dispatch)

  }else{
    return dispatch({type:C.noSyncNecessary})
    //return new Promise((res,rej) => res())
  }
}

register(C.unexpectedCliServerError);
register(C.createDialog,["dialogName"]);

register(C.removeCorruptImageUploads);

register('tryLogout',function(args){
  return (dispatch,getState) => {



    let force = args && args.force;


    let logoutMessageContent = G.getLogoutMessageContent(getState())
    if( !force && logoutMessageContent ){

      dispatch(
        ActionCreators.createDialog({
          source:C.tryLogout,
          dialogName:Dialog.LOGOUT_WITH_UNSAVED_DATA
        })
      )

      return false;

    }else{

      dispatch(
        ActionCreators.fetchLogoutResponse()
      )


      let onSuccess = () => {

        dispatch(
            ActionCreators.receiveLogoutResponse()
          )
        dispatch(ActionCreators.setUiMode(
          {mode: WELCOME }
        ));
          dispatch({
            type:C.CLEAR_HISTORY
          });

      }

      let onServerFailure = () => {
        dispatch(ActionCreators.createUnexpectedServerErrorDialog());
      }

      let onInternetFailure = () => {

        dispatch(ActionCreators.createNoInternetDialog())

      }

      let getArgs = {
        route:'/logout',
        state:getState(),
        onSuccess,
        onServerFailure,
        onInternetFailure
      };

      return get(getArgs,dispatch)
      
    }
  }
})

register('fetchLogoutResponse',function(){
  return{type:C.FETCH_LOGOUT_RESPONSE}
})

register('receiveLogoutResponse',function(){
  return{type:C.LOGOUT_USER}
})

register(C.injectRecordsFromServer);
register(C.queueAnonymousSessionDataForSync);

register(C.processAuthResponse,function(args){
  return (dispatch,getState) => {
    let { authResponse, authType } = args;
    return processAuthResponse({dispatch,authResponse,authType});
  }
});

register(C.processAuthSuccess,function(args){
  return (dispatch,getState) => {

    let { authResponse, authType } = args;
    let { json, headers } = authResponse;
    let { 
      username, 
      status,
      data,
      userConfig,
      userInfo,
      firstLogin,
      outstandingSurveys
    } = json;



    /*dispatch({
      type:C.processAuthSuccess,
      records:data,
      userConfig,
      firstLogin
    })*/


    /*
     * These calls need to be different
     * because of how Reducer calls are processed
     * after the data is inserted.
     * If all the data is inserted
    */
  

    //https://www.npmjs.com/package/redux-batched-actions
    //Need to batch actions 
    //Because if you didn't, it was
    //crashing when you were creating a figure
    //and you signed in
    //I think because its looking for root paths
    //after first state change
    //but it can't find them because the real root
    //hadn't been injected yet

    let actionBatch = [

      ActionCreators.queueAnonymousSessionDataForSync(),

      ActionCreators.injectRecordsFromServer({
        records:data,
      }),

      ActionCreators.mergePersistedUserDataWithCurrentSessionData({
        userConfig,
        firstLogin,
        surveys:outstandingSurveys,
      })

    ]

    dispatch(batchActions(actionBatch));

    //we add this here so that we can just log in 
    //without our account validity checker harassing us
    dispatch({type:C.loginCompleted})

    let state = getState();

    let shouldLaunchTutorial = G.shouldLaunchTutorial(state);
    if( G.shouldLaunchTutorial(state) ){
      dispatch(ActionCreators.launchTutorial());
    }
    
    
  }
})

register(C.launchTutorial);

register(C.processAuthFailure,function(args){

  return (dispatch,getState) => {
    //throw Error("Haven't yet implemented auth failure handling... although I'm not sure how much there is to do here!");
  }
});

function isSuccessfulAuth(authResponse){
  return authResponse && authResponse.status === 'loggedIn';
}


function processAuthResponse({dispatch,authResponse,authType}){
  let { json, headers } = authResponse;

  if( isSuccessfulAuth(json) ){
    return dispatch(ActionCreators.processAuthSuccess(
      {authResponse,authType}
    ));
  }else{

    return dispatch(ActionCreators.processAuthFailure(
      {authResponse,authType}
    ));
  }
} 


register(C.createThread,function(args){
  return (dispatch,getState) => {
    let state = getState();
    let thread = {
      _id:(args.threadId || args._id || Id()),
      participants:[
        "admin",
        state.personId
      ],
      read:{},
      delivered:{},
      messages:{}
    }

    dispatch({
      type:C.createThread,
      thread
    })


  }

})

register(C.setUserInfo);

register('receiveAuthResponse',function(args){
  return (dispatch,getState) => {

    let { json, headers, authAttemptId, isOffline } = args || {};
    let { status, username, response, userId, userInfo, reason } = json || {};
    let receivedCookie;


    if( headers && isCLI() ){
      receivedCookie = headers.get('set-cookie');
    }

    if( isOffline ){
      if( reason ){
        throw Error("Reason is defined, but it probably shouldn't be.");
      }
      reason = "noInternet";
    }

    dispatch({
      type:C.receiveAuthResponse, 
      authType:args.authType,
      status,
      username,
      isOffline,
      response,
      userId,
      reason,
      receivedCookie,
      authAttemptId,
      userInfo,
      json
    })
  }
})

register('fetchUserAuthResponse',function(args){
  return (dispatch,getState) => {

    let state = getState();
    if( G.isLoggedIn(state) ){
      return;
    }

    let { authArgs, authType, authAttemptId } = args;

    dispatch({
      type:C.fetchUserAuthResponse,
      authArgs,
      authType,
      authAttemptId,
    })

    

    

    let onInternetFailure = res => {

      return dispatch(ActionCreators.receiveAuthResponse({
        ...res,
        authType,
        authAttemptId,
        isOffline:true
      }));

    }

    let onServerFailure = res => {

      debugger;
      let json = {
        status:'loggedOut',
        reason:"Server failed. Try again shortly. Contact support@sciugo.com if required.",
        response:'failed',
        authAttemptId,
      }
      return dispatch(ActionCreators.receiveAuthResponse({
        ...json,
        json,
        authType
      }))
    }


    let onSuccess = res => {

      dispatch(ActionCreators.receiveAuthResponse({
        headers:res.headers,
        ...res,
        authType,
        authAttemptId,
      }));

      return dispatch(ActionCreators.processAuthResponse({
        authResponse:res,
        authType
      }))

    }
    //onSuccess = onServerFailure;

    let processUsername = (
      authArgs.email || 
      authArgs.username || 
      authArgs.user
    ).toLowerCase().trim()


    let postArgs = {
      route:'/'+authType,
      body:{
        username:processUsername,
        password:authArgs.password,
        ...authArgs
      },
      state:getState(),
      onSuccess,
      onInternetFailure,
      onServerFailure
    }

    return post(postArgs,dispatch)
  }
})



register('trySignup',function(args){
  return (dispatch,getState) => {

    args.email = args.email.toLowerCase();
    
    let state = getState();
    return dispatch(ActionCreators.fetchUserAuthResponse({
      authType:'signup',
      authArgs:args,
      authAttemptId:Id()
    }))
  }
})

register('tryLogin',function(args){
  return (dispatch,getState) => {


    args.email = args.email.toLowerCase();

    return dispatch(ActionCreators.fetchUserAuthResponse({
      authType:'login',
      authArgs:args,
      authAttemptId:Id()
    }))
  }
})

register(C.newFigure, function(args){
  return (dispatch,getState) => {
    let {structures} = args;
    let grid = structures.map(rowStructure => {
      return rowStructure.map( groupLength => {
        return Array(groupLength).fill(Id())
      }).flat()
    })

    dispatch({
      type:C.newFigure,
      grid
    })


  }
});

register(C.selectAll,function(){
  return (dispatch,getState) => {

    let state = getState();
    let {grid} = state;
    let rowCount = grid.length;
    let colCount = grid[0].length;
    let cellsToSelect = [];
    for(let ii = 0; ii < rowCount; ii++){
      for(let jj = 0; jj < colCount; jj++){
        cellsToSelect.push([ii,jj]);
      }
    }

    dispatch({
      type:C.SELECT_CELLS,
      cells:cellsToSelect
    })


  }
})


register('receiveLoginResponse', function(status,username,response,receivedCookie,json){
  return {
    type:C.receiveLoginResponse,
    status,
    username,
    response,
    receivedCookie,
    json
  }
})


register('receiveSignupResponse', function(status,username,response,receivedCookie,json){
  return {
    type:C.receiveSignupResponse,
    status,
    username,
    response,
    receivedCookie,
    json
  }
})



register(C.fetchLoginResponse)
register(C.fetchSignupResponse)


register(C.UNDO,function(){
  return (dispatch,getState) => {
    let state = getState();
    let { historyInfo } = state;
    let { activeHistory, index } = historyInfo;
    let change = activeHistory[index+1];
    return dispatch( {
      type:C.UNDO,
      ops:change && change.undo
    } )
  }
});
register(C.REDO,function(){
  return (dispatch,getState) => {
    let state = getState();
    let { historyInfo } = state;
    let { activeHistory, index } = historyInfo;
    let change = activeHistory[index-1];
    return dispatch( {
      type:C.REDO,
      ops:change && change.redo
    } )
  }
});

register(C.CLEAR_HISTORY,function(){
  return (dispatch,getState) => {
    let state = getState();
    if( state.historyInfo.activeHistory.length > 0 ){
      return dispatch({ type:C.CLEAR_HISTORY });
    }
  }
});

register(C.sendImagesToServer,function(args){
  return (dispatch,getState) => {
    dispatch({type:C.sendImagesToServer,...args});

    let { urls, filenames, imageIds } = args;



    urls.map((url,ii) => fetch(url).then(res => res.blob()).then(file => {

      let filename = filenames[ii];
      let imageId = imageIds[ii];

      sendImageToServer(file,"AUTOMATED_UPLOAD - " + filename, dispatch, imageId);

    }))

  }
})

function resolveImageUploadUrls(args){

  let urls;

  let { filenames, imageIds, imageSetIds } = args;

  if( args.readIntoMemory && args.urls ){
    throw Error("If you're trying to read something into memory, you shouldn't be passing a urls ("+JSON.stringify(args.urls)+") – the URL will be created automatically. Pass either readIntoMemory OR urls, but not both.");
  }

  if( isCLI() && !args.readIntoMemory && !args.urls ){
    console.warn("On CLI, if you don't pass in `urls`, then the image won't show up when you load the state into GUI.");
  }
  if( args.urls ){
    if( args.urls.length !== imageIds.length ){
      throw Error("If you pass a `urls` args, there should be as many `urls` as there are `imageIds`.");

    }else{
      urls = args.urls;
    }
  }else if( args.readIntoMemory ){
    urls = readFilesIntoMemory({filenames});
  }else{
    throw Error("Image uploads need resource URLs. None were provided and you aren't reading any files for which a URL can be created.");
  }

  return urls;
}

function automatedImageProcessingPromises(dispatch,args){
  let { imageIds } = args;
  let processActionSpecifications = [];

  if( args.convertTo && (!isCLI() || args.readIntoMemory) ){
    /*
      if( args.convertTo !== 'png' ){
        throw Error("why are you trying to convert image to a format OTHER than png?");
      }
      */

    processActionSpecifications = imageIds.map(imageId => ActionCreators.startProcess({
      processName:'convertImageType',
      args:{ 
        outputMime:args.convertTo,
        input:{
          imageId, version:'raw'
        }
      }
    })) 
  }

  //return Promise.all so that we don't move on to the next
  //action until all these promises are resolved.
  //another strategy could be to override promise
  //and register every promise created and just
  //wait until they are all resolved to finish 
  //the command.


  let promisePipelineForEachUpload = 
    processActionSpecifications.map(spec => {
      return dispatch(spec).then(processingResults => {
      })
    })

  return Promise.all(processActionSpecifications.map(dispatch));

}

function convertImageFormat({imageId,args,dispatch}){


  let shouldConvert = args.convertTo //&& (!isCLI() || args.readIntoMemory);

  if( !shouldConvert ){
    return Promise.resolve();
  }


  let action = ActionCreators.startProcess({
      processName:'convertImageType',
      
      args:{ 
        forceConversionTimeout:args.conversionTimeout,
        conversionTimeoutMs:args.conversionTimeoutMs,
        outputMime:args.convertTo,
        input:{
          imageId, version:'raw'
        }
      }
    })

  return dispatch(action);

}

async function persistUnpersistedImageVersionsToCloud({imageId,delayStorage,args,dispatch,getState}){




  let fakeStorage = args.storageLocation === "fake";


  let isInTestEnvironment = false;
  if( process.env.NODE_ENV !== 'production' ){
    isInTestEnvironment = (
      isCLI() || isUsingWebdriver()
    )
  }



  let shouldNotReadOrStoreData = isInTestEnvironment && fakeStorage && !args.readIntoMemory;
  let realDataToProcess = !shouldNotReadOrStoreData;

  if( !realDataToProcess && !delayStorage ){

    return dispatch({
      type:C.setRemoteStorageResourceId,
      _id:imageId,
      version:"raw", 
      storageLocation:args.storageLocation,
      pendingRecords:false,
      remoteStorageResourceId:Id(),
    })
  }else{


    let state = getState();



    let toSync = G.getUnpersistedImageVersionsNotCurrentlyBeingSynced(state,{imageId});


    console.error("SYNCING TO CLOUD STORAGE");


    return dispatch(
      ActionCreators.syncImagesToCloudStorage({
        toSync,
        storageLocation:args.storageLocation
      })
    )

  }



}

function syncImageRecordsToAccount({imageId,args,dispatch,getState}){

  //sync this to user's data

  let state = getState();
  

  if( !G.isLoggedIn(state) ){
    return 
  }

}

register(C.startMediaProcessing, function(args){
  return (dispatch,getState) => {


    let processArgs = args.processArgs;

    

    
      
  let { imageIds, delayStorage } = args;


  let pipelinePromises = imageIds.map(async imageId => { 

      let state = getState();
      let startArgs;


      if( state.mediaProcessing[imageId] ){
        startArgs = G.getMediaProcessingStartArgs(state,{_id:imageId});
      }else{
        startArgs = G.getDefaultMediaProcessingArgs(state,{
          //convertTo:"png",
          convertTo:args.convertTo
        });
        
      }


      dispatch({
        type:C.startMediaProcessing,
        imageId,
        ...args,
        ...startArgs,
        processArgs
      });

      

      let subProcessArgs = {imageId,
        args:{
          ...startArgs,
          ...args, 
          ...(args.args||{}),
          ...processArgs,
          //...startArgs
      },dispatch,getState};



    /*
     We only want to convert the items we WANT to convert.
     Most times, we don't want to convert.
     But currently, every 'startImageUploads' 
     call is going to the thread.

     So, by default, we are converting.
     However, when we are in the console,
     we don't want this.
    */
      if( subProcessArgs.args.convertTo ){

        await convertImageFormat(subProcessArgs);

      }


      if( !subProcessArgs.args.delayStorage ){
        
        await persistUnpersistedImageVersionsToCloud(subProcessArgs);
      }

      //await syncImageRecordsToAccount(subProcessArgs);
      
  });


    return Promise.all(pipelinePromises).then(() => {
    });
  }
})

register(C.insertNodesInEvaluatedTemplateNodes);

register(C.startImageUploads,function(args){

  return (dispatch,getState) => {




    let { readIntoMemory, filenames, imageIds, imageSetIds, storageLocation, urls, delayStorage } = args;

    let noStorage = false;
    if( !storageLocation  && !delayStorage ){
      storageLocation = "fake";
      noStorage = true;
    }

   
    urls = resolveImageUploadUrls(args);




    if( args.imageSetId ){
      throw Error("You passed imageSetId, but if you want to pass imageSetId, you should do so in an array, `imageSetIds:[...]`.");
    }

    imageSetIds = args.imageSetIds.map( id => id || Id() );
    imageIds = args.imageIds.map( id => id || Id() );

    /*
    if( !isCLI() ){
      dispatch(ActionCreators.sendImagesToServer({
        urls,imageIds,filenames
      }));
    }*/

   
    let uploadArgs = {
      ...args,
      filenames,
      imageSetIds, 
      imageIds,
      urls,
      storageLocation,
      delayStorage
    }





    let shouldNotReadOrStoreData = noStorage && !readIntoMemory;
    let realDataToProcess = !shouldNotReadOrStoreData;
    
    if( isCLI() && realDataToProcess ){
      let uniqueImageSetIds = new Set((imageSetIds||[]));
      if( uniqueImageSetIds.size < (imageSetIds||[]).length ){
        throw Error("If you're processing real data, imageSetIds must be unique! You can move images into image sets after.");
      }
    }

    dispatch({
      type:C.startImageUploads,
      ...uploadArgs,
      pendingRecords:(delayStorage || !shouldNotReadOrStoreData)
    })

    
      let startMediaProcessing = ActionCreators.startMediaProcessing(uploadArgs)


      return dispatch(startMediaProcessing).catch(error => {
        debugger;
        throw(error);
      });

  }

});




register(C.restoreImages,{createIfMissing:["destinationImageSetId"]})
register(C.archiveImages);

function validateImageSetTransferOperation(args){

  let validTransferOptions = [
    C.KEEP_BOTH_ANNOTATIONS,
    C.KEEP_MOVER_ANNOTATIONS_ONLY,
    C.KEEP_DESTINATION_ANNOTATIONS,
    undefined,
  ];

  if( !validTransferOptions.includes(args.annotationTransferOption) ){
    throw Error("Illegal annotationTransferOption: '"+args.annotationTransferOption+"'");
  }


}

function resolveNewAnnotationIds(state,args){

  let { imageIds, annotationTransferOption } = args;

  let newIdsRequired = [C.KEEP_MOVER_ANNOTATIONS_ONLY,C.KEEP_BOTH_ANNOTATIONS].includes(annotationTransferOption);
  let newAnnotationIds = args.idsOfDuplicatedAnnotations || [];
  if( newIdsRequired ){
    let imageId = imageIds[0];
    let moverAtnIds = G.getAnnotationsByImageId(state,imageId);
    if( isNaN(moverAtnIds.length) ){
      debugger;
    }


    let numIdsNeeded = (
      moverAtnIds.length - newAnnotationIds.length
    );

    if( numIdsNeeded < 0 ){
      throw Error(newAnnotationIds.length + " were provided for duplicated annotation ids, but only " + moverAtnIds.length + " were required.");
    }

    let idsToPush = Ids( numIdsNeeded )

    newAnnotationIds.push(...idsToPush);

  } 

  return newAnnotationIds;
}

register(C.moveImagesToImageSet,function(args){
  return (dispatch,getState) => {

    if( args.newAnnotationIds ){
      throw Error("Action arg `newAnotationIds` has been renamed to `idsOfDuplicatedAnnotations` in 'moveImagesToImageSet'");
    }

    validateImageSetTransferOperation(args);
    let newAnnotationIds = resolveNewAnnotationIds(
      getState(),
      args
    );

    dispatch({
      type:C.moveImagesToImageSet,
      ...args,
      newAnnotationIds
    })
  }
});

register(C.setFocusedAnnotationId);
register(C.deleteAnnotation)
register(C.setGlobalConfig)


register(C.tryArchiveImages, function(args){
  return (dispatch,getState) => {

    let {imageIds} = args;

    let imageId = imageIds[0];

    let state = getState();

    let imageDeletionConditions = 
      G.getImageDeletionMessageKey(state,imageId);

    if( imageDeletionConditions ){
      dispatch(ActionCreators.createDialog({
        dialogName:Dialog.IMAGE_ARCHIVE_WARNING,
        source:'tryArchiveImages',
        args:{
          imageDeletionConditions,
          imageId
        }
      }))
    }else{
      dispatch(ActionCreators.archiveImages({
        imageIds:[imageId]
      }))

    }
  }
})

register(C.addAnnotation,
  {createIfMissing:["_id"],require:["annotationType"]}
)
register(C.setAnnotationProperties,function(args){
  return (dispatch,getState) => {

    let { _id, properties } = args;
    let state = getState();
    let atn = G.getData(state,{_id, itemType:ANNOTATIONS});

    let previouslyQuantified = atn.quantifications;

      dispatch({
        type:C.setAnnotationProperties,
        ...args
      });


    /*
    if( previouslyQuantified ){
      let startQuant = ActionCreators.startQuantification({
        annotationIds:[_id],
        config:{force:true}
      })
      return dispatch(startQuant);
    }*/

  }
})



register(C.POP_MESSAGE);

register('popMessage',function(args){
  return (dispatch,getState) => {
    dispatch({ type:C.POP_MESSAGE })
    if( args ){
      let { actionName, actionArgs } = args;
      return dispatch(ActionCreators[actionName](actionArgs));
    }
  }
})

register('reportMessage',function(messageData){
  return (dispatch,getState) => {
    dispatch({
      type:C.REPORT_MESSAGE,
      messageData
    })
  }
})

register('setHeightGroupProperties',function(args){

  return {
    type:C.SET_HEIGHT_GROUP_PROPERTIES,
    ...args
  };
});

register('addHeightGroup',function(args){
  return {
    type:C.ADD_HEIGHT_GROUP,
    _id:args._id||Id(),
    ...args
  }
});

register('exportFigure',function(args){
  return {
    type:C.EXPORT_FIGURE,
    ...args
  }
})

register('setCropProperty', function(args){
  return {
    type:C.SET_CROP_PROPERTY,
    ...args
  }
})

register(C.setRemoteStorageResourceId);

register('setImageProperty',function(args){
  return (dispatch,getState) => {
    dispatch({
      type:C.SET_IMAGE_PROPERTY,
      ...args
    })
  }
})

register(C.exportFigureFailed,function(args){
  return (dispatch,getState) => {

    dispatch(ActionCreators.createDialog({
      dialogName:Dialog.CUSTOM,
      header:{ type:'', text:"Export Failed" },
      body:"We will investigate this unexpected error on your behalf.",
    }))

    return dispatch(ActionCreators.alertServer(
      {
        alert:'exportFigureError',
        data:{
          ...args
        }
      }
    ))

  }
})

register(C.removeNodeFromEvaluatedTemplateNode);
register(C.insertNodeInEvaluatedTemplateNode, function(args){
  return (dispatch,getState) => {


    let { before, after, inside } = args;
    if( !before && !after && !inside ){
      throw Error("You must specify where to insert the new node with 'before', 'after' or 'inside'.");
    }
    dispatch({
      type:C.insertNodeInEvaluatedTemplateNode,
      nodeId:(args.nodeId||Id()),
      newParentId:(args.newParent||Id()),
      ...args
    })

    // 

  }
});

register(C.setImageProperties)

register(C.alertServer,function(args){
  return (dispatch,getState) => {

    let body = args;


    dispatch({
      type:C.alertingServer,
      ...args
    })

    debugger;


    return post({
      route:'/alertServer',
      body,


      //this will eventually need to be
      //replaced with something that handles
      //and queues up notifications and things
      //when the client is offline

      onSuccess:() => {},
      onInternetFailure:() => {},
      onServerFailure:() => {},
      onFetchError:() => {},
      

      
      state:getState()
    }, dispatch).then(() => {
      dispatch({
        type:C.serverAlerted,
        body
      })
    })
  }

},["alert","data"])

register(C.logError,function(args){
  return (dispatch,getState) => {

    let body = args;
    let crashId = Id();
    //put this up top, easier to view?
    body = { crashId, ...body }

    let sgBody = {...body,state:undefined};

    let errMsg = "An unexpected error occurred.";

    if( getGlobalObject().__showErrorDialog ){ 
      dispatch({
        type:C.createDialog,
        dialogName:Dialog.WITH_NOTIFICATION_MESSAGE,
        args:{
          message:errMsg,
          body:errMsg,
        }
      })
    }

    dispatch({
      type:C.alertingServer,
      body:sgBody
    })

    return post({
      route:'/alertServer',
      body,
      onSuccess:() => {},
      onInternetFailure:() => {},
      onServerFailure:() => {},
      onFetchError:() => {},
      state:getState()
    }, dispatch)  

  }
});

register(C.sendBugReport,function(args){
  return (dispatch,getState) => {
    let state = getState();
    let body = {
      ...args,
      ...getSessionInfo(),
      loginStatus:state.loginInfo.status,
      username:state.loginInfo.username
    }
    dispatch({
      type:C.alertingServer,
      alertType:C.sendBugReport,
      body
    })

    let onSuccess = () => {
      return dispatch({
        type:C.postServerAlert
      })
    }

    let onInternetFailure = () => {};
    let onServerFailure = () => {
      return dispatch(ActionCreators.createDialog({
        dialogName:Dialog.PLEASE_REPORT_BUG
      }))
    };

    return post({
      route:'/reportUserBug',
      body,
      state:getState(),
      onSuccess,
      onInternetFailure,
      onServerFailure,
    }, dispatch).then(() => {
          });
  }

})

register(C.setFigureImage)

register('deleteAnnotationLine',function(args){
  return {
    type:C.DELETE_ANNOTATION_LINE,
    ...(args||{})
  }
})

register('endSession',() => ({ type:C.END_SESSION }))

register('initSession', route => ({type:C.INJECT_ROUTE,route}))

register('splitSelectedCells',function(args){
  return (dispatch,getState) => {

    throw Error("Deprecated. Use `splitCells` instead.");

  }
})

register('splitCells', function(args){
  return (dispatch,getState) =>  {

    let { cells, figurePanelId } = args;
    if( !figurePanelId ){
      throw Error("figurePanelId must be defined (splitCells)!")
    }

    let state = getState();

    let splitInfoForEachCell = cells.map( cell => {
      let cellsMerged = G.getCellsMergedWith(state,{position:cell, figurePanelId});
      let lenCellsMerged = cellsMerged.length;
      return { cellsMerged, ids: Ids(lenCellsMerged) };
    })

    dispatch({
      type:C.SPLIT_CELLS,
      cells,
      figurePanelId:args.figurePanelId,
      splitInfoForEachCell
    })
  }
})

register('setWidthsOfMergedCells',function(listOfCellsMergedToOtherCells,widths){

  return (dispatch,getState) => {
    let state = getState();
    let figurePanelId = state.selectedFigurePanelId;

    //for each in above list

    let mergedCells = listOfCellsMergedToOtherCells.map(position =>{
      G.getCellsInGroup(state,position,figurePanelId).flat();
    })

  }



})

register(C.setCellWidths)

function uniqueIdsInGrid(figurePanel){
  let flatGrid = figurePanel.grid.flat();
  let uniqueIds = new Set(flatGrid);
  return uniqueIds.size
}

function uniqueIdSelectionCount(state,figurePanelId){
  let figurePanel = state.data.figurePanels[figurePanelId];
  let {grid,selectedCells} = figurePanel;
  let idsSelected = selectedCells.map(
    ([row,col]) => {
      return grid[row][col];
    }
  )
  let uniqueIds = new Set(idsSelected)

  return uniqueIds.size;



}


register('deleteRow', function(args){
  return (dispatch,getState) => {


    let { selectedCells, figurePanelId } = args;
    let state = getState();
   
    let rowIndices = selectedCells.filter(cell => {

      let [row,col] = cell;

      let mergedCells = G.getCellsMergedWith(state,{figurePanelId,position:cell});

      let cellMergedOnlyWithOneRow = mergedCells.every(
        mergedCell => mergedCell[0] === row
      )

      return cellMergedOnlyWithOneRow
    }).map(cell => cell[0]);

    let uniqueRowIndices = Array.from(new Set(rowIndices));


    if( !figurePanelId ){
      args.figurePanelId = getState().selectedFigurePanelId;
      if( !args.figurePanelId ){
        throw Error("You must pass figurePanelId to get a figure's table grouping display!");
      }
    }

    return dispatch({
      type:C.DELETE_ROW,
      figurePanelId,
      rowIndices:uniqueRowIndices
    })
  }
})

register('deleteColumn', function(args){


  /*
    
    Just ignore the cells that span multiple columns

    Reason is because we're not sure what the person is
    intending to delete.

    Only real issue there is if they've merged a bunch of
    cells and can't figure out why they can't delete


  */


  return (dispatch,getState) => {
    let { selectedCells, figurePanelId } = args;
    let state = getState();
   
    let columnIndices = selectedCells.filter(cell => {

      let [row,col] = cell;

      let mergedCells = G.getCellsMergedWith(state,{figurePanelId,position:cell});

      let cellMergedOnlyWithOneColumn = mergedCells.every(
        mergedCell => mergedCell[1] === col
      )

      return cellMergedOnlyWithOneColumn
    }).map(cell => cell[1]);

    let uniqueColumnIndices = Array.from(new Set(columnIndices));

    console.log({uniqueColumnIndices});


    if( !figurePanelId ){
      throw Error("You must pass figurePanelId to get a figure's table grouping display!");
    }
    return dispatch({
      type:C.DELETE_COLUMN,
      figurePanelId,
      columnIndices:uniqueColumnIndices
    })
  }
})


register('setEditorStyle',function(args){
  return {
    type:C.SET_EDITOR_STYLE,
    args
  }
})

/*register('selectAllCells',function(args){
  return (dispatch,getState) => {
    let cells = [];
    let state = getState();
    let figurePanelId = args.figurePanelId;
    if( !figurePanelId ){
      args.figurePanelId = getState().selectedFigurePanelId;
      if( !args.figurePanelId ){
        throw Error("You must pass figurePanelId to get a figure's table grouping display!");
      }
    }

    let figurePanel = state.data.figurePanels[figurePanelId];


    figurePanel.grid.forEach( (row,ii) => {

      row.forEach( (value,jj) => {
        cells.push([ii,jj])
      })

    })

    dispatch(ActionCreators.selectCells({cells}))
  }
})*/

register('selectCells',function(args){
  return {
    type:C.SELECT_CELLS,
    ...args
  }
})


register('mergeSelectedCells',function({figurePanelId}){
  return (dispatch,getState) => {
    let cells = getState().selectedCells;
    dispatch(ActionCreators.mergeCells({cells,figurePanelId}))
  }
})

register(C.mergeCells,function(args){
  return (dispatch,getState) => {

    let figurePanelId = args.figurePanelId;
    if( !figurePanelId ){
      args.figurePanelId = getState().selectedFigurePanelId;
      if( !args.figurePanelId ){
        throw Error("You must pass figurePanelId to get a figure's table grouping display!");
      }
    }
    dispatch({
      type:C.mergeCells,
      ...args
    })


  }
})

register('setTableDimensions',function(args){
  return (dispatch,getState) => {
    let {rows,cols,figurePanelId} = args;
    let state = getState();
    let curDims = G.getTableDimensions(state,figurePanelId);
    /*if( !(curDims[0] === 0 && curDims[1] === 0) ){
      throw Error("Can't use this function unless the table is completely empty... [0,0]");
    }*/

    let cellsNeeded = rows * cols;
    let _ids = Array(cellsNeeded).fill("").map(Id);

    dispatch({
      type:C.SET_TABLE_DIMENSIONS,
      _ids,
      ...args
    })

  }
})

register('insertColumn',function(args){
  return (dispatch,getState) => {
    let { columnIndex,figurePanelId } = args;
    if(!figurePanelId){ 
      let state = getState();
      if(state.selectedFigurePanelId){figurePanelId=state.selectedFigurePanelId}else{throw Error("figurePanelId cannot be undefined."); }}

    let [rows,cols] = G.getTableDimensions(getState(),figurePanelId);


    if( columnIndex === undefined ){
      columnIndex = cols;
    }
    let state = getState();
    let figurePanel = state.data.figurePanels[figurePanelId];

    let gelLanesCount = figurePanel.columnWidths.length;

    let insertionInfo = getGelLaneChangeIdInfo(
      state,gelLanesCount+1,columnIndex,figurePanel
    )

    dispatch({
      type:C.INSERT_COLUMN,
      columnIndex,
      figurePanelId,
      ...insertionInfo
    })
  }
})

function mergeMultiSpanningIds(newRow, originalRow, idsToKeep){
  return newRow.map((id,ii) => {
    if( idsToKeep && idsToKeep[ii] ){
      return originalRow[ii];
    }else{
      return id;
    }
  })
}

function createMatchingIdStructure(originalIdRow){

  let idMap = {}
  let newRow = [];
  originalIdRow.forEach((id,iiCell) => {


    if( !(id in idMap) ){
      idMap[ id ] = Id();
    }
    newRow.push(idMap[id]);
  })
  return newRow;
}

function createIdStructureToFitBetween(rowOne,rowTwo,structureToMatch){

  let rowStructureToMatch = {
    rowAbove:rowOne,
    rowBelow:rowTwo,
  }[structureToMatch];

  let newRow;
  if( structureToMatch === undefined ){
    newRow = Ids(rowOne.length);
  }else{
    newRow = createMatchingIdStructure(rowStructureToMatch);
  }

  //now merge the required columns

  for(let ii = 0; ii < rowOne.length; ii++){
    let rOneId = rowOne[ii];
    let rTwoId = rowTwo[ii];

    if( rOneId === rTwoId ){
      newRow[ii] = rOneId
    }
  }

  return newRow;

}

register('rotateImage',function(args){
  return { 
    type:C.ROTATE_IMAGE,
    ...args,
  }
})

register('transformImage',function(args){
  return (dispatch,getState) => { 
    let { crops, imageId } = args;
    let cropsWithIds = crops.map(
      crop => ({ ...crop, _id: crop._id || Id() })
    )
    dispatch({
      type:C.TRANSFORM_IMAGE,
      ...args,
      imageId,
      crops:cropsWithIds
    })
  }
})



register('insertRow',function(args){
  return (dispatch,getState) => {


    

    //batchGroupBy.start();

    //if row is at top or bottom
    //then all are new.

    //else we need to check if any 
    //row cells are already merged 
    //and we need to add a new 
    //cell to this merged group


    let state = getState();

    let { figurePanelId, rowIndex, structure, columnIndices, groupValues } = args;

    if( rowIndex === 7 ){
      debugger;
    }

    if(!figurePanelId){ throw Error("figurePanelId cannot be undefined."); 
    }

    let [rows,cols] = G.getTableDimensions(state,figurePanelId);

    let figurePanel = state.data.figurePanels[figurePanelId];


    let numberOfLanes;


    if( rows === 0 && rowIndex > 0 ){
      rowIndex = -0.5;
    }




    if( structure ){
      numberOfLanes = structure.reduce((a,b) => a+b);
    }else if( cols === 0 && figurePanel.grid.length > 0 ){
      numberOfLanes = figurePanel.grid[0].length;
    }else{
      numberOfLanes = state.numberOfLanes;
    }
    let newIdRow;


    if( rows === 0 && structure === undefined ){
      newIdRow = Ids(numberOfLanes);
    }else if( structure !== undefined ){

      //they can merge with a row
      //and still pass in a structure

      if( args.mergeWith !== undefined ){
        let rowIndexToMatch;
        if( args.mergeWith === 'top' ){
          rowIndexToMatch = rowIndex - 1;
        }else if( args.mergeWith === 'bottom' ){
          rowIndexToMatch = rowIndex;
        }else{
          throw Error("Unexpected value for `mergeWith`: " + args.mergeWith);
        }

        let idsToKeep = G.doCellsSpanMultipleRows(state,{rowIndex:rowIndexToMatch, figurePanelId});
        if(!figurePanelId){
          debugger;
        }
        let rowToMatch = G.getRow(state,{figurePanelId,rowIndex:rowIndexToMatch});

        let matchingIdStructure = 
          createMatchingIdStructure(rowToMatch);

        newIdRow = mergeMultiSpanningIds(
          matchingIdStructure,
          rowToMatch,
          idsToKeep
        )

      }else{
        let rowToMatch = structure.map( length => Array(Math.abs(length)).fill(Id()) ).flat();
        newIdRow = createMatchingIdStructure(rowToMatch);


        let idsToKeepAbove = rowIndex > 0 ? G.doCellsSpanMultipleRows(state,{rowIndex:rowIndex-1,figurePanelId}) : [];
        let idsToKeepBelow = rowIndex < rows ? G.doCellsSpanMultipleRows(state,{rowIndex,figurePanelId}) : [];

        if( !idsToKeepAbove.some ){

        }

        if( idsToKeepAbove.some( x => x ) ){

          newIdRow = mergeMultiSpanningIds(
            newIdRow,
            G.getRow(state,{rowIndex:rowIndex-1, figurePanelId}),
            idsToKeepAbove
          )

        }/*else if( idsToKeepBelow.some(x => x)){

          newIdRow = mergeMultiSpanningIds(
            newIdRow,
            G.getRow(state,rowIndex),
            idsToKeepBelow
          )
        }*/



      }
    } else if( rowIndex === rows || rowIndex === 0 ){
      if( args.matchAdjacentRowStructure ){

        let currentIdRowIndex;
        if( rowIndex === 0 ){
          currentIdRowIndex = 1;
        }else{
          currentIdRowIndex = rows-1;
        }

        let originalRow = G.getRow(state,{rowIndex:currentIdRowIndex,figurePanelId}); 


        let idsAtCellsToKeep;

        let newRowWithMatchingStructure = 
          createMatchingIdStructure(originalRow);

        if( args.mergeWith !== undefined ){

          idsAtCellsToKeep = G.doCellsSpanMultipleRows(state,{rowIndex:currentIdRowIndex,figurePanelId});

          newIdRow = 
            mergeMultiSpanningIds(
              newRowWithMatchingStructure,
              originalRow,
              idsAtCellsToKeep
            );

        }else{
          newIdRow = newRowWithMatchingStructure
        } 
      }else{
        newIdRow = Ids(cols);
      }
    }else {

      //this is when there is not structure
      //and it's just a regular insert row.

      let rowOne = G.getRow(state,{rowIndex:rowIndex-1,figurePanelId});
      let rowTwo = G.getRow(state,{rowIndex,figurePanelId});

      //0 for the first row, 1 for the second row
      let structureToMatch = args.structureToMatch;

      if( args.mergeWith !== undefined ){
        let rowIndexToMatch = args.mergeWith === 'top' ? rowIndex - 1 : rowIndex;
        let idsAtCellsToKeep = G.doCellsSpanMultipleRows(state,{rowIndex:rowIndexToMatch,figurePanelId});
        let rowToMatch = G.getRow(state,rowIndexToMatch,figurePanelId);
        newIdRow = createMatchingIdStructure( rowToMatch )


      }else{

        newIdRow = createIdStructureToFitBetween(
          rowOne,
          rowTwo,
          structureToMatch
        )
      }

    }

    let entriesOfValuesToSet = (columnIndices||[]).map( (index, ii) => {
      return [ newIdRow[ index ], groupValues[ ii ] ]
    })


    dispatch({
      type:C.insertRow,
      figurePanelId,
      rowIndex,
      newIdRow,

      structure
    })


    /*if( rowIndex === 2 && groupValues.length > 0 ){

    }*/


    if( entriesOfValuesToSet ){
      entriesOfValuesToSet.forEach(entry => {
        let groupId = entry[0];

        let state = getState();

        let cellLocationList = G.findCellLocations(state,{ids:[groupId],figurePanelId});
        let cellLocation = cellLocationList[groupId][0]; 

        dispatch(ActionCreators.setCellsValue({ 
          figurePanelId, 
          cells:[cellLocation],
          ...entry[1] 
        }))
      })
    }

    if( args.addExtraRowAbove ){

      let numCols = structure.reduce((a,b)=>a+b);
      let idArray = Array(numCols).fill(0).map(_=>Id());
      dispatch({
        type:C.insertRow,
        figurePanelId,
        rowIndex,
        newIdRow:idArray,
        structure:Array(numCols).fill(1)
      })
    }

    //batchGroupBy.end();

  }
})


register('insertRows',function(args){

  return (dispatch,getState) => {


    

    let { rowValues } = args;
    let rows = Object.keys(rowValues)

    let actionBatch = rows.forEach(row => {
      let rowIndex = Number(row);

      let thisSubAction = {
        ...args,
        rowIndex,
        ...rowValues[row]
      }


      return dispatch(ActionCreators.insertRow(thisSubAction))


    })


    //return dispatch(batchActions(actionBatch));

  }

})

function getGelLaneChangeIdInfo(state,nol,columnIndex,figurePanel){

  let newGrid = [];
  let newIds = [];
  let idRemovalCount = {};
  //need to determine where we need a new id.
  let curNol = figurePanel.columnWidths.length; //currentNumberOfLanes
  let delta = nol - curNol;


  let grid = figurePanel.grid;

  let [start,end] = [0,curNol]//G.getGelLaneSpan(state, figurePanel._id);



  grid.forEach(row => {


    if( columnIndex === undefined ){
      columnIndex = end+1;
    }

    let idsWhichDetermineTheIdOfTheNewCell;
    if( columnIndex === end+1 ){
      idsWhichDetermineTheIdOfTheNewCell = row.slice(end-2,end)
    }else if( columnIndex === 0 ){
      idsWhichDetermineTheIdOfTheNewCell = row.slice(0,2);
    }else{
      idsWhichDetermineTheIdOfTheNewCell = [row[columnIndex-1],row[columnIndex]]
    }

    let setLen = (new Set(idsWhichDetermineTheIdOfTheNewCell)).size;

    let newRow = row.slice();

    if( delta > 0 ){
      let addedIds;
      if( columnIndex === 0 || setLen !== 1 ){

        addedIds = Ids(delta);
        newIds.push(...addedIds);
      }
      else if( setLen === 1 ){
        addedIds = Array(delta).fill(
          idsWhichDetermineTheIdOfTheNewCell[0]
        )
      }

      newRow.splice(columnIndex, 0, ...addedIds);
    }else {

      let spliceRange = [end + delta + 1, Math.abs(delta)]

      let columnToStartRemoveAt = end + delta + 1;
      let nColumnsToRemove = Math.abs(delta);

      let idsAtCellsBeingCutoff = newRow.splice(columnToStartRemoveAt,nColumnsToRemove);

      idsAtCellsBeingCutoff.forEach(id => {

        if( id in idRemovalCount ){
          idRemovalCount[id]++;
        }else{
          idRemovalCount[id] = 1;
        }
      })
    }

    newGrid.push(newRow);
  })

  let idsToRemove = [];
  let ids = Object.keys(idRemovalCount);
  let figurePanelId = figurePanel._id;
  let locationsOfIdsToRemove = G.findCellLocations(state,{ids,figurePanelId});

  Object.keys(idRemovalCount).forEach( id => {
    let nCellsWithId = locationsOfIdsToRemove[id].length;
    if( nCellsWithId === idRemovalCount[id] ){
      idsToRemove.push(id);
    }
  })

  return {
    newGrid,
    newIds,
    idsToRemove
  }

}



register('setGelLanes',function(numberOfLanes){
  return (dispatch,getState) => {
    throw Error("`setGelLanes` is banned as an action.");
  }
})

register('setSelectedPanel');


register('setSelectedCellsValue',function(args){
  return (dispatch,getState) => {
    throw Error("Action 'setSelectedCellsValue' is deprecated.");
    let cells = getState().selectedCells;
    dispatch(ActionCreators.setCellsValue({...args,cells}))
  }
})

function shouldWeShowAnyImageLabels(shouldShowImageLabels){
  if( !shouldShowImageLabels ){
    return {show:{left:false,right:false}};
  }

  return Object.values(shouldShowImageLabels.show).some(x=>x);
}

function shouldAddLabelsToFigure(state,cell,value,figure){

  if( value.valueType === 'image' ){
    return {
      show:{left:false,right:false},
      overwrite:{left:false,right:false}
    };
  }

  let [row,col] = cell;
  let grid = figure.grid;

  let columnIndexToImageRight = col;
  for(let ii = col; ii < grid[row].length; ii++ ){
    columnIndexToImageRight++;
    if( grid[row][columnIndexToImageRight] !== grid[row][col] ){
      break;
    }
  }

  let gridIdToImageRight = grid[ row ][ columnIndexToImageRight ];

  let cellGroupToImageRight = figure.cellGroups[ gridIdToImageRight ];
  let cellGroupToImageLeft = figure.cellGroups[ grid[ row ][ col - 1 ] ];


  let leftValueType = cellGroupToImageLeft && cellGroupToImageLeft.value.valueType;

  let rightValueType = cellGroupToImageRight && cellGroupToImageRight.value.valueType;

  let leftIsNotImage = !isImageType(leftValueType)
  let rightIsNotImage = !isImageType(rightValueType);

  let annotationId = value.annotationId;

  let atnLabels = G.getMarkLabels(state,{annotationId});

  let cropHasLabels = atnLabels.length > 0

  /*if( cell[0] === 1 && cell[1] === 1 ){

  }*/

  return {
    show:{ left:cropHasLabels, right:cropHasLabels },
    overwrite:{ left:leftIsNotImage, right:rightIsNotImage }
  }
}

function shouldAddNewColumnsForBandAnnotations(state,cellOfSetValue,value,figurePanel){

  /*

  We only add a new row if adjacent cells are undefined
  or if the cell of the same row in the adjacent column are part of a merge.
  */

  let [ row, col ] = cellOfSetValue;

  let grid = figurePanel.grid;

  let columnIndexToImageRight = col;
  for(let ii = col; ii < grid[row].length; ii++ ){
    columnIndexToImageRight++;
    if( grid[row][columnIndexToImageRight] !== grid[row][col] ){
      break;
    }
  }


  let gridIdToImageRight = grid[ row ][ columnIndexToImageRight ];

  let cellGroupToImageRight = figurePanel.cellGroups[ gridIdToImageRight ];
  let cellGroupToImageLeft = figurePanel.cellGroups[ grid[ row ][ col - 1 ] ];

  let shouldAddColumnLeftOfImage = 
    state.shouldShowImageLabels.left && cellGroupToImageLeft === undefined;

  let shouldAddColumnRightOfImage = 
    state.shouldShowImageLabels.right && cellGroupToImageRight === undefined;

  if( !shouldAddColumnRightOfImage ){
    let gridIdsTouchingTheAdjacentCell = [ 
      grid[row][columnIndexToImageRight],
      (grid[row-1] && grid[row-1][columnIndexToImageRight]),
      (grid[row+1] && grid[row+1][columnIndexToImageRight])
    ]


    shouldAddColumnRightOfImage =
      (new Set(gridIdsTouchingTheAdjacentCell)).size < 3;
  }


  if( !shouldAddColumnLeftOfImage ){



    let gridIdsTouchingTheAdjacentCell = [ 
      grid[row][col-1],
      (grid[row-1] && grid[row-1][col-1]),
      (grid[row+1] && grid[row+1][col-1])
    ]
    shouldAddColumnLeftOfImage =
      (new Set(gridIdsTouchingTheAdjacentCell)).size < 3;
  }

  return { shouldAddColumnLeftOfImage, shouldAddColumnRightOfImage }

}


const bandAnnotation = ({annotationId, sideRelativeToImage}) => ({
  annotationId,
  valueType:'bandAnnotation',
  sideRelativeToImage
})

const notInGrid = (grid, cell) => {
  let [row, col] = cell;
  if( grid[row] !== undefined ){
    if( grid[row][col] !== undefined ){
      return false;
    }
  }
  return true;
}



function getAllCellCoords(rows, cols){
  let coords = [];
  for(let ii = 0; ii < rows; ii++){
    for(let jj =0 ; jj < cols; jj++){
      coords.push([ii,jj]);
    }
  }
  return coords;
}



register('setCellsValue', function(args){
  return (dispatch,getState) => { 


    let { figurePanelId, value, figureItems, columnWidth,cellGroupIds } = args;

    let cells;
    let expansionNodes;
    if( figureItems ){
      expansionNodes = figureItems.expansionNodes;
      if( expansionNodes && expansionNodes.length > 0  && args.style ){

        let item= expansionNodes[0];
        let { node, nodeId } = item;

        debugger;

        let properties = Object.fromEntries( Object.keys(args.style).map(styleKey => {
          let propKey = [
            ["nodeOverrides",nodeId,"style"].join('.'),
            styleKey
        ].join('~');

          let value = args.style[styleKey];
          return [propKey,value];
        }))

        return dispatch(ActionCreators.setCellsValueProperties({
          cells:expansionNodes.map(x => x.cellLocation),
          figurePanelId,
          properties
        }))
      }else{
        args.cells = figureItems.cells || [];
      }
    }else{
      cells = args.cells;
    }



    

    let state = getState();
    let figurePanel = G.getFigurePanel(state,{figurePanelId});

    if( value === '' || value === null ){
      debugger;
    }

    if(!figurePanelId){ 
      throw Error("figurePanelId cannot be undefined.");
    }


    if(!cells){
      cells = [];
    }else if( cells === 'all' ){
      let state = getState();
      
      let rows = figurePanel.grid.length;
      let cols = figurePanel.grid[0].length;

      cells = getAllCellCoords(rows,cols);
      args.cells = cells;
    }

    if( cellGroupIds ){
      let map =  G.findCellLocations(getState(),{ids:cellGroupIds,figurePanelId})
      let locations = cellGroupIds.map(id => map[id][0]);
      cells.push(...locations);
    }


    let getCurPanel = (getState) => getState().data.figurePanels[figurePanelId];
    let getCurGrid = (getState) => getCurPanel(getState).grid;

    let curGrid = getCurGrid(getState);

    let cellThatIsNotInGrid = 
      cells.find(cell => notInGrid(curGrid,cell));

    if( cellThatIsNotInGrid ){
      throw Error("Cannot set properties of cell, " + JSON.stringify(cellThatIsNotInGrid) + " that isn't in grid! Cells received: " + JSON.stringify(cells));
    }

    if( columnWidth !== undefined ){
      dispatch({
        figurePanelId,
        type:C.setCellWidths,
        widths:Array(cells.length).fill(columnWidth),
        cellsToSet:cells
      })
    }

    state = getState();

    let isWesternBlotPanel = figurePanel.figurePanelType === "westernBlot";
    let isMicroscopyPanel = figurePanel.figurePanelType === "microscopy";

    let isAddingImageToWesternBlotPanel = value !== undefined && isImageType(value.valueType) && isWesternBlotPanel;

    let isAddingImageToMicroscopyPanel = value !== undefined && isImageType(value.valueType) && isMicroscopyPanel;
    
    if( isAddingImageToWesternBlotPanel ){

      if(value.valueType === 'image' && !value.imageSetId){
        throw Error("Setting values to an 'image' required an 'imageSetId'");
      }



      args.imageAdjustments = null;

      let grid = getCurGrid(getState);

      let cell = cells[0];
      let [row,col] = cell;

      let columnIndexToImageRight = col;
      for(let ii = col; ii < grid[row].length; ii++ ){
        columnIndexToImageRight++;
        if( grid[row][columnIndexToImageRight] !== grid[row][col] ){
          break;
        }
      }


      let shouldShowImageLabels = shouldAddLabelsToFigure(state,cell,value,getCurPanel(getState))
      let shouldShowAnyImageLabels = shouldWeShowAnyImageLabels(shouldShowImageLabels);

      let showLeft = true;
      let showRight = true;

      let columnAdditionActions = shouldShowAnyImageLabels && shouldAddNewColumnsForBandAnnotations(state,cell, value,getCurPanel(getState));


      if( showRight && columnAdditionActions.shouldAddColumnRightOfImage ){
        dispatch(ActionCreators.insertColumn({figurePanelId,columnIndex:columnIndexToImageRight}))
      }

      //let figurePanel = G.getFigurePanel(state,{figurePanelId});

      const adjacentValueMap = {
        mw:(side) => ({
          valueType:'bandAnnotation',
          version:2,
          annotationId:value.annotationId,
          sideRelativeToImage:side
        }),
        crop:side => {
          let markLabels;
          if( PRECISE_MARKERS_ON_BOTH_SIDES_ENABLED ){

            //FOR A TEST, refer to test/futureTests/specificBandLabels.in
            
            markLabels = G.getMarkLabels(state,{annotationId:value.annotationId, labelType:"crop"});
          }
          if( markLabels && markLabels.length > 0 ){
            return {
              valueType:'bandAnnotation',
              version:2,
              labelType:"crop",
              annotationId:value.annotationId,
              sideRelativeToImage:side
            }
          }
          return G.getValueLabel(state,{value})
        }
      }

      let labelLayout = G.getLabelLayout(state,{figurePanelId});
      
      let adjacentValues = Object.fromEntries(
        Object.keys(labelLayout).map(side => {
          //this assumes we only have ONE label for each
          //Idk if this will ever change.
          
          let labelType = labelLayout[ side ][0];
          return [side,adjacentValueMap[ labelType ](side)]
        })
      )

      let rightValueToSet = adjacentValues.right;
      let leftValueToSet = adjacentValues.left;

      

      let cellToRight = [cell[0],columnIndexToImageRight]
      if( shouldShowImageLabels.overwrite.right && !notInGrid(curGrid,cellToRight) ){
        dispatch(ActionCreators.setCellsValue({
          figurePanelId,
          cells:[cellToRight],
          value:rightValueToSet
        }))
      }

      dispatch({
        type:C.setCellsValue,
        figurePanelId,
        ...args,

      })

      if( showLeft && columnAdditionActions.shouldAddColumnLeftOfImage ){
        dispatch(ActionCreators.insertColumn({columnIndex:cell[1]}))
      }

      let columnIndexOffset = columnAdditionActions.shouldAddColumnLeftOfImage ? 0 : -1;


      //let leftValueToSet = G.getValueLabel(state,{value});


      let cellToLeft = [cell[0],cell[1]+columnIndexOffset];
      if( shouldShowImageLabels.overwrite.left && !notInGrid(curGrid,cellToLeft)){
        dispatch(ActionCreators.setCellsValue({
          cells:[cellToLeft],
          value:leftValueToSet,
          figurePanelId
        }))
      }
    } else if( isAddingImageToMicroscopyPanel ){

      let regions = {
          main:args.value.annotationId
      }

      let panel = getCurPanel(getState);
      let { config } = panel;

      let { value } = args;
      let resolvedValue = {
        ...value,
        localTemplateId:config.currentTemplateId,
        expandable:true,
        regions,
      }

      return dispatch({
        type:C.setCellsValue,
        figurePanelId,
        ...args,
        value:resolvedValue,
      })


    }else{


      return dispatch({
        type:C.setCellsValue,
        figurePanelId,
        ...args
      })


    }
  }
})

register('mergeBandLabels',function(args){
  return (dispatch,getState) => {
    let state = getState();

    let newLabelId = args.newLabelId || Id();
    dispatch({
      type:C.MERGE_BAND_LABELS,
      ...args,
      newLabelId
    })
  }
})

register('swapSidesOfBandAnnotation',function(args){
  return {
    type:C.SWAP_SIDES_OF_BAND_ANNOTATION,
    ...args
  }
})

register('setLabelProperty',function(args){
  return {
    type:C.SET_LABEL_PROPERTY,
    ...args
  }
})


const getImagesToSync = (state,imageUploads,syncCond) => {
  let needsToBeSynced = []

  imageUploads.forEach(({_id,versions}) => {

    for( let key in versions ){
      let { storageLocation } = versions[key];
      let imagePresent = G.isImagePresent(state,{imageId:_id,version:key});

      let unsynced = syncCond(versions[key]);

      if( unsynced && imagePresent ){
        needsToBeSynced.push({_id,version:key});
      }
    }
  })

  return needsToBeSynced;
}


register('syncImagesToCloudStorage',function(args){
  return (dispatch,getState) => {



    let state = getState();
    let { storageLocation, toSync, remoteStorageSpecification } = args;

    if( !toSync ){
      throw Error("'syncImagesToCloudStorage' requires a 'toSync' arg. Received: " + toSync);
    }
    
    //toSync = toSync || FILES_NOT_PERSISTED;

    
   
    let imagesToSync = toSync;

    dispatch(ActionCreators.startSyncImageCloudStorage({
      imagesToSync,storageLocation
    }))

    let syncArgs = () =>  ({
      imagesToSync,
      storageLocation,
      state:getState(),
      remoteStorageSpecification
    })

    if( process.env.NODE_ENV !== 'production' ){
      if( storageLocation === "fake" ){

        return dispatch(ActionCreators.finishSyncImageCloudStorage({
          imagesToSync,
          syncResults:Array(imagesToSync.length).fill("fake-storage-id"),
          storageLocation
        }))

      }
    }


    return new Promise(async (resolve,reject) => {


      //let mediaPreFetch = getState().media;

      await dispatch(ActionCreators.fetchPresignedPostUrls(
        syncArgs()
      ))

      //let mediaPostFetch = getState().media;

      

      let uploadPromises = await dispatch(
          ActionCreators.persistMediaWithPresignedUrls(
            syncArgs()
          )
        )



      let syncResults = await uploadPromises;


      await dispatch(ActionCreators.finishSyncImageCloudStorage({imagesToSync,syncResults,storageLocation}))

      resolve();

    })

   

  }

})

register(C.notifyMediaPersistenceError,function(args){
  return (dispatch,getState) => {

    dispatch({
      type:C.notifyMediaPersistenceError,
      ...args
    })

    dispatch({
      type:C.updateMediaProcessing,
      status:"failed",
      imageId:args.imageSpec._id,
      errors:[
        {type:args.errorType}
      ]

    })

//    let { imageSpec } = args;

    /*dispatch(ActionCreators.pushNotification({
      notificationType:C.imageSyncFailure,
      args:{ ...imageSpec }
    }))*/
  }
})

register('addBandLabel',function(args){
  return (dispatch,getState) => {
    dispatch({
      type:C.ADD_BAND_LABEL,
      lineId:(args.lineId || Id()),
      leftLabelId:(args.leftLabelId || Id()),
      rightLabelId:(args.rightLabelId || Id()),
      ...args
    })
  }
})

register('addPanelToFigure');
register('removePanelFromFigure');


register('addValidationTemplate',function(args){
  return (dispatch,getState) => {

    if(!args.validationId){
      throw Error("Require validationId!");
    }

    let state = getState();

    let panelTemplateGridInfo = ItemCreator(state,{
      _id:args.panelTemplateGridId,
      type:'figurePanel',
      figurePanelType:'westernBlot',
      args:{left:1,gel:2,right:1}
    })

    let antibodyLayoutGridInfo = ItemCreator(state,{
      _id:args.antibodyLayoutGridId,
      type:'figurePanel',
      figurePanelType:'westernBlot',
      args:{left:1,gel:1,right:1}
    })

    dispatch({
      type:C.addValidationTemplate,
      panels:{
        panelTemplateId:panelTemplateGridInfo.data._id,
        figureLayout:antibodyLayoutGridInfo.data._id
      },
      panelDataToAdd:[panelTemplateGridInfo,antibodyLayoutGridInfo],
      validationId:args.validationId,
      index:args.index
    })



  }
})



register(C.updateRequestStatus, function(args){
  return (dispatch,getState) => {
    dispatch({
      type:C.updateRequestStatus,
      ...args,
      timestamp:Number(Date.now())
    })
  }
});

register(C.setState);

register(C.setUiMode);
register(C.setSelectedFigurePanelContext);
register(C.shiftLaneOffsets);
register(C.incrementLaneWidths);


//ensure that no constant is used more than once

let usedConstants = {};

Object.values(ActionCreators).forEach( (x,ii)=> {
  let action = x({__IGNORE_MISSING_KEYS__:true});
  if( typeof(action) === 'object' ){
    let type = action.type
    if( type === undefined ){
      throw Object.keys(ActionCreators)[ii]+' constant is undefined.';
    }

    if( usedConstants[ type ] === true ){
      throw type+' is used ILLEGALLY used twice.';
    }
    usedConstants[ type ] = true;
  }else{
  }
})

//REQUIRE_STATEMENTS
//IMPORT_STATEMENTS
export default ActionCreators;
