import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import A from './ActionCreators';
import G from './Getters';

export default function EmailValidation(){

  let dispatch = useDispatch();

  let username = useSelector(state => state.loginInfo.username);
  let { 
    sendCodeResponse,
    validationResponse
  } = useSelector(state => {
    let sendCodeResponse = G.getRequestResponse(state,{route:'/sendValidationEmail'})
    let validationResponse = G.getRequestResponse(state,{route:'/verifyValidation'})
    return {
      sendCodeResponse,
      validationResponse
    }
  })


  console.log({
    validationResponse
  })


  let validationFailureReason;
  if( validationResponse?.status === "failed" ){
    validationFailureReason = validationResponse.reason || "Request failed.";
  }



  const [ validEmail, setValidEmail ] = useState("");
  const [ emailSendSuccess, setEmailSendSuccess ] = useState();
  const [ validationCode, setValidationCode ] = useState("");
  const [ emailFailureReason, setEmailFailureReason ] = useState();

  return (
    <div class="text-body">
      <h4>Institutional/Company Email Verification</h4>
      <div style={{fontSize:16}}>
      <div>
        Sciugo accounts must be linked to institutional or company emails.
      </div>
            <div>
        Your login username will remain as:&nbsp;
        <b>
          {username}
        </b>
      </div>

      <br/>

      <div>
        Enter your institutional/company email below to receive your validation link.
      </div>

      <div class="fair-margin-top sg-row">
        <input style={{width:'100%'}} placeholder={"Institution/company email"} value={validEmail} onChange={({target}) => setValidEmail(target.value)} />
      </div>


        {emailFailureReason && <div style={{color:"red"}}>
          <b>{emailFailureReason}</b>
        </div>}
        {emailSendSuccess && <div style={{color:"green"}}>
          <b>Email sent.</b>
        </div>}

      <div class="sg-row fair-margin-top">
        <button disabled={sendCodeResponse?.pending} onClick={() => {

          setEmailSendSuccess();
          setEmailFailureReason();
          dispatch(A.makeRequest({
            route:'/sendValidationEmail',
            body:{email:validEmail},
            onSuccess:(json) => {
              setEmailSendSuccess(true);
            },
            onFailure:(json) => {
              let { reason } = json || {reason:"Request failed."};
              setEmailFailureReason(reason);
            }
          }))
         }}
        style={{width:150}} class="btn btn-primary">Send Code</button>
      </div>

      <div style={{marginTop:50}}>
        Follow the emailed link (check spam). Then, click <b>validate</b>.
      </div>

        
        <div style={{marginTop:50}}>

          <div style={{color:"red"}}>
          <b>{validationFailureReason}</b>
        </div>

          <button disabled={validationResponse?.pending} onClick={() => {
            dispatch(A.verifyEmailValidation())
          }} style={{width:150}} class="btn btn-success">Validate</button>
        </div>

      </div>
    </div>
  )
}
