import React from 'react';
import HierarchyList from './HierarchyList';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Modal from 'react-bootstrap/Modal';
import NewFeatureContent from './NewFeatureContent';
const newestDate = NewFeatureContent.map(x => ([new Date(x.date),x])).sort((a,b) => b[0]-a[0])[0][1].date;


const documentationSection = {
  overview:(
    <div>
      <h5>Overview</h5>
      <p>
        This tutorial shows you how to create figures and quantify bands in Sciugo.
      </p>

      
      <h5>Image Annotation</h5>
      <p>
        Here, you will:
        <ul>
          <li>Capture band regions</li>
          <li>Label regions so they are findable when creating figures and quantifying</li>
          <li>Label MW markers (they show up automatically in your figure)</li>
        </ul>
      </p>
      <p>

      </p>

      


    </div>
  ),




}

const documentationHierarchy = [
  {
    item:"overview",
    children:[

    ]
  },
  {

  }
]


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{background:'#eee',width:'100%',flex:1}}
      {...other}
    >
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight:500,
    //height: 400,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs({closeModal}) {
  const classes = useStyles();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };



  let tab = NewFeatureContent[selectedTabIndex];
  let { label, vid, gif, text } = tab;

  let videoRef = useRef();


  const setPlayBack = () => {
    videoRef.current.playbackRate = 4;
  };


  let media;
  if( gif ){
    media = (

      <img style={{
        width:'100%',
        objectFit:'contain',


      }} src={gif}/>
    )

  }else if( vid ){
    media = (

      <video ref={videoRef} style={{width:'100%'}} 

        onCanPlay={() => setPlayBack()}

        controls={false} loop autoPlay={true} playsInline={true} muted>
        <source type="video/mp4" src={vid}/>
      </video>

    )
  }





  return (
    <Modal size="lg" show={true} onHide={closeModal}>

      <Modal.Header style={{background:'#fff'}}>
        <div style={{width:'100%',fontSize:24}} class="sg-row">
          <div>
            New Features
          </div>
          <div class="flex-expand"/>
          <div>
            {tab.date}
          </div>
        </div>
      </Modal.Header>

      <div className={classes.root}>

        <div style={{
          padding:20,
          borderRight:'1px solid #ccc'
        }}>
          <HierarchyList/>
        </div>




        {false && <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTabIndex}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          style={{flexShrink:1,alignItems:'left',textAlign:'left'}}
        >
          {NewFeatureContent.map((item,iiItem) => (
            <Tab 
              style={{
                fontWeight:item.date === newestDate ? 'bold' : 'auto'
              }}
              label={item.label} />
          ))}
        </Tabs>}
        <TabPanel style={{background:'#f8f8f8',flex:1}}>
          <div style={{
            width:'100%',
            //border:'1px solid black',
            height:'100%',

          }}>
            {documentationSection.overview}
            {false && <>
              <h3>{label}</h3>
              <div style={{marginTop:15,marginBottom:15}}>{text}</div>
              <div style={{

                boxShadow:'0 2px 4px rgba(0 0 0 / 10%), 0 8px 16px rgba(0 0 0 / 10%)',
                boxSizing:'border-box',
                borderRadius:5,

              }}>
                {media}
              </div>
            </>}
          </div>
        </TabPanel>
      </div>
    </Modal>
  );
}
