import replaceWithGreek from './replaceWithGreek';
export default function SciugoTextArea({children,...props}){


  let { textareaRef } = props;

  return (
    <textarea ref={textareaRef} {...{
      ...props,
      onChange:(e) => {

        e.target.value = replaceWithGreek(e.target.value);

        props.onChange && props.onChange(e);



      }
    }}/>
  )


}
