import ManualAnnotationGridTable from './ManualAnnotationGridTable';
import { useRef, useEffect, useState } from 'react';

//import QueryResult from './QueryResult';


export default function TransformedPreviewPanel({translateX, scale, renderedDim, figurePanelId}){


  const panelToTransformAfterRender = useRef();
  const [ alreadyTransformed, setAlreadyTransformed ] = useState(false);

  console.log({
    renderedDim:JSON.parse(JSON.stringify(renderedDim)),
    scale
  })

  useEffect(() => {

    if( !alreadyTransformed && panelToTransformAfterRender.current ){
      translateX = 0;
      let transform = 'translate('+translateX+'px,0) scale('+scale+')';
      panelToTransformAfterRender.current.style['transform-origin'] = '0px 0px';
      panelToTransformAfterRender.current.style.transform = transform; 
      panelToTransformAfterRender.current.style.opacity = 1;
      setAlreadyTransformed(true);
    }
  })

  return (

    <div 
      ref={panelToTransformAfterRender}
      class={"scaled-panel-preview"}
      style={{
        opacity:0,
        //border:'3px solid blue', 
        width:renderedDim.width,
        height:renderedDim.height,
        //background:'white',
      }}>
      <ManualAnnotationGridTable tableOutlineShowing={true} editable={false} figurePanelId={figurePanelId}/>
    </div>

  )



}
