import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ButtonGroup from 'react-bootstrap/ButtonGroup';


function MenuItemWithTooltip({text,tooltipText}){
  return (

    <OverlayTrigger {...{placement:"right",overlay:( 
      <Tooltip>{tooltipText}</Tooltip>
    )}}>

      <Dropdown.Item>
        <div>{text}</div>

      </Dropdown.Item>
    </OverlayTrigger>

  )
}

const newItemOptions = {
  figurePanelTemplates:[
  ],
  figurePanels:[
    { text:"Based on previous figure", tooltipText:"Right click on previous figure and select 'Duplicate'" },
    { text:"From template", tooltipText:"Navigate to templates, right click on the desired template and select 'Use for new panel'" }
  ]
}

export default function NewPanelButton({setNewPanelFilesystemRootTarget,itemType, activeFilesystemRoot}){


  const title = ({
    figurePanelTemplates:"New Template",
    figurePanels:"New Panel"
  })[ activeFilesystemRoot ];


  /*
   * Wasted ~30-40 minutes trying to figure
   * out if I can pass HTML props through to
   * the react-bootstrap components
   * so I can test the split dropdown.
   *
   * Result is I will just use classic bootstrap
   * with a react-boostrap dropdown
   * grouped together with a dropdown button.
  */

  return (
    <>
      <Dropdown as={ButtonGroup}>
        <Button button={"mainCreateButton"} onClick={()=>{
        setNewPanelFilesystemRootTarget(activeFilesystemRoot)
      }}
          style={{
            fontSize:12
          }}

        variant="primary">{title}</Button>

        <Dropdown.Toggle action={"mainCreateButton-dropdown"} split variant="primary"/>
      
      <Dropdown.Menu>
        <Dropdown.Item action={"mainCreateButton-dropdown-blank"} onClick={() => setNewPanelFilesystemRootTarget(activeFilesystemRoot)}>Blank</Dropdown.Item>

        {newItemOptions[activeFilesystemRoot].map(optionInfo => <MenuItemWithTooltip {...optionInfo}/>)}
      </Dropdown.Menu>

    </Dropdown>
    </>

  )
}
