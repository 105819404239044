//REQUIRE STATEMENTS

import C from './Constants';
import { DEFAULT_CONVERTED_VERSION } from './SciugoDefaults';
import Letters, { isMeasuredString } from './Letters';

import GetLines from './GetLine';
import { getIntermediaryPoint, dot, unit, add, scale, createLineSegmentWithDirection, distance, distanceFromPointToLineSegment,isPointProjectionOntoLineSegmentInLineSegment, lineSegmentShiftedPerpendicularlyToPoint, pointsAreEqual, minus, vectorAngleRad, rotatePointsAboutCentroid, rotatePointsAboutPoint, vectorAngleDegrees, unitPerp, pointProjectionOntoLine } from './Geometry';

import { unflattenObject, flattenObject, argmin, table, mode, setData, overrideNodes,round } from './utils';

import createQuantificationMacro from './createQuantificationMacro';

import getVenn from './Venn';



import { 
  TEMPLATES, ANNOTATIONS, DIRECTORIES, 
  FIGURE_PANELS, IMAGE_SETS, IMAGE_UPLOADS, 
  getResolvedItemTypeName 
} from './RecordTypes';

import { CREATION_DATE, LAST_EDITED_DATE } from './FieldConstants';

import { IMAGE_SET_WITHOUT_ANNOTATIONS, IS_FILESYSTEM_ITEM_EXPANDABLE, FILESYSTEM_PARENT_DIRECTORY, FILESYSTEM_NAME, NOTIFICATIONS, CHILDREN } from './Filesystem';

import getEvaluatedSyncObject from './getEvaluatedSyncObject';

import Dialog from './DialogConstants';

import { QUANTIFICATION } from './UIConstants';

import { IMAGE_UPLOAD_DATE, ANNOTATION_LAST_EDITED_DATE } from './CropSearch';

import HTML from './HTML';


const REGION_OUTLINE = "regionOutline";

function isNullish(obj){
  return obj === undefined || obj === null;
}

const convertedInfoContainerName = "raw-png";

function unique(array){
  return Array.from( new Set( array ) );
}

function groupBy(list, func){

  let map = {};
  list.forEach(item => {
    let key = func(item);
    if( !(key in map) ){
      map[key] = []
    }
    map[key].push(item);
  })

  return map;

}



let G;
//window.calls = {};
if( process.env.NODE_ENV === 'development' ){
  G = new Proxy({},{
    get:(obj,prop,receiver) => {

      //window.calls[prop] = (window.calls[prop]||0) + 1;

      
      return obj[prop];
    },

    set:(obj,prop,value) => {
      if( obj[prop] ){
        throw Error("Illegal attempt to re-define (as a Getter) '"+prop+"'");
      }else{
        obj[prop] = value;
        return obj;
        //obj[prop] = value;
      }
    }
  })
}else{
  G = {};
}

const CONVERSION_TIMEOUT = "conversionTimeout";
const IMAGE_PROCESSING_ERROR = "imageProcessingError";
const GET_PRESIGNED_POSTS_SERVER_ERROR = "getPresignedPostsServerError";



const ProcessingStatusMessageErrorPriority = [
  CONVERSION_TIMEOUT,
  IMAGE_PROCESSING_ERROR,
  GET_PRESIGNED_POSTS_SERVER_ERROR,

]

const processingErrorMessageMap = {
  [CONVERSION_TIMEOUT]:(error) => {
    let { timeout } = error;
    return `Image processing timeout. Retry with ${2*timeout/1000} second timeout or re-upload a compressed image.`
  },
  [IMAGE_PROCESSING_ERROR]:() => {
    return 'Unable to process image. Tip: import this image into ImageJ, then export from ImageJ and re-upload.'
  },
  [GET_PRESIGNED_POSTS_SERVER_ERROR]:() => {
    return `Unexpected processing error. Try again in 10 seconds.`; 
  },
  "postResourceError":() => {
    return `Unexpected processing error. Try again in 10 seconds.`; 
  }
  
}


const ALWAYS_DELETABLE_ITEM_TYPES = new Set([FIGURE_PANELS,IMAGE_UPLOADS,IMAGE_SETS]);


G.getSelectedEvaluatedNodeInfo = function(state,{figurePanelId, selectedNodes}){

  let selectedNodeInfo = selectedNodes.map(({nodeId,cellLocation}) => {

    let cell = G.getCellsValue(state,{figurePanelId,cells:[cellLocation]})[0];

    let node = G.getEvaluatedFigurePanelCellNodeValue(state,{figurePanelId, cellLocation, nodeId});


    return {
      nodeId,
      node,
      cell,
      cellLocation,
    }

  })


  return selectedNodeInfo;

}


G.getRegionOutlineMenuTargetLists = function(state,{selectedItems,figurePanelId}){
  let possibleActions = G.getPossibleFigureEditorActions(state,{selectedItems,figurePanelId});
  return possibleActions.regionOutlines;
}


G.getRegionAncestorNode = function(state,{nodeId,cellLocation,figurePanelId}){

  let args = { cellLocation, figurePanelId };

  let curNode = G.getEvaluatedFigurePanelCellNodeValue(state,{ nodeId,...args })

  while( true ){

    let parentId = curNode.p;
    if( !parentId ){
      return; 
    }
      
    curNode =  G.getEvaluatedFigurePanelCellNodeValue(state,{ 
      nodeId:parentId,...args 
    })

    
    if( curNode.type === "region" ){
      if( nodeId === "012-0" ){
      }
      return {...curNode, nodeId:parentId };
    }
  }

}

function getPossibleScalebarFocusActions(state,{figurePanelId,expansionNodes,evaluatedNodes}){
  // need to check the status of the scalebar...
  // is the corresponding image resolution set?
  // does the thing even have an image?
  // It turns out, there are steps to be taken
  // until the scalebar is effective.
  // 1. there must be an image/region under the scalebar
  // 2. the image must have a resolution

  let focusedNode = evaluatedNodes[0];
  let focusedNodeQuery = expansionNodes[0]

  let setImageResolution;
  let setRegionVariable;
  let editRegion;


  let imageRegionNode = G.getRegionAncestorNode(state,{figurePanelId,...focusedNodeQuery});

  let { nodeId } = imageRegionNode;

  let { regionId, annotationId } =
    G.getEvaluatedRegionNodeInfo(state,{figurePanelId,...focusedNodeQuery,nodeId});

  if( !regionId ){
    setRegionVariable = { urgent:true };
  }else if( !annotationId ){
    editRegion = { urgent:true }
    setRegionVariable = { urgent:true, regionId }
  }else{

    setImageResolution = {};

    //ensure we have the image resolution!

    let imageId = G.getImageSetFigureImageIdByAnnotationId(state,{annotationId});
    let resolution = G.getImageResolution(state,{imageId});

    if(! resolution ) {
      setImageResolution.urgent = true;
      setImageResolution.imageId = imageId;
    }
  }

  // need to check the status of the scalebar...
            // is the corresponding image resolution set?
            // does the thing even have an image?
            // It turns out, there are steps to be taken
            // until the scalebar is effective.
            // 1. there must be an image/region under the scalebar
            // 2. the image must have a resolution

  return {
    editRegion,
    setImageResolution,
    setRegionVariable,
    scalebar:{},
    spacing:{},
    position:{}
  };
}

function getPossibleRegionFocusActions(state,{
  figurePanelId,
  expansionNodes,
  evaluatedNodes
}){

  //position, only if the node as some "region" ancestor.
  let addItems = {};



  let regionOutlinesOfSelectedNodes = [];
  let subregionOutlinesByRegionId = {};

  let regionOutlines = { 
    applyToAll:regionOutlinesOfSelectedNodes,
    byChildSubregions:subregionOutlinesByRegionId 
  }

  let position = {};
  let setRegionVariable = {};
  let editRegion;

  


  // certain conditions will render it nothing;


  let regionIdSet = new Set();
  let rowSet = new Set();
  let colSet = new Set()


  
  
  let allNodesHaveRegionOutlineChildren = true;
  let allNodesHaveRegionAncestor = true;
  let regionOutlineCanBeFoundForAllSelectedNodes = true;
  let someRegionNodeHasNoRegionId = false;

  evaluatedNodes.forEach(({c,value},ii) => {

    let { cellLocation } = expansionNodes[ii];

    rowSet.add(cellLocation[0]);
    colSet.add(cellLocation[1]);

    let { regionId } = value;

    regionIdSet.add( regionId );


    let regionNodeInfo = G.getEvaluatedRegionNodeInfo(state, {
      figurePanelId,
      ...expansionNodes[ii]
    });


    let cellsValue = G.getCellsValue(state,{figurePanelId,cells:[cellLocation]})[0];
    let { regions } = cellsValue;

    if( !regionNodeInfo.regionId || !regionNodeInfo.annotationId ){
      if( evaluatedNodes.length === 1 ){

        setRegionVariable = { urgent:true, regionIds:Object.keys(regions) }

        
      }
      someRegionNodeHasNoRegionId = true;
    }

    if( regionNodeInfo.regionId && !regionNodeInfo.annotationId ){
      setRegionVariable.editable = true;
      editRegion = { urgent:true, referenceAnnotationId:regions.main }
    }


    if( allNodesHaveRegionOutlineChildren ){



      let thisSubregionOutlineList = (c||[]).filter(child => child.includes('v_'))

      

      if( thisSubregionOutlineList.length === 0 ){
        allNodesHaveRegionOutlineChildren = false;
      }else{

        thisSubregionOutlineList.forEach(subregionNodeId => {
          let { regionId } = G.getEvaluatedFigurePanelCellNodeValue(state,{figurePanelId,nodeId:subregionNodeId,cellLocation});
          subregionOutlinesByRegionId[ regionId ] = [
            ...(subregionOutlinesByRegionId[regionId]||[]), 
            {nodeId:subregionNodeId,cellLocation}
          ]
        })
      }
    }


    if( regionOutlineCanBeFoundForAllSelectedNodes ){

      let regionAncestorNode = G.getRegionAncestorNode(state,{...expansionNodes[ii],figurePanelId});
     
      if( regionAncestorNode ){
        let thisRegionOutlineNode =  regionAncestorNode.c.find(childId => childId.includes(`r=${regionId}`));

        if( thisRegionOutlineNode ){
          regionOutlinesOfSelectedNodes.push(({nodeId:thisRegionOutlineNode,cellLocation}))
        }else{
          regionOutlineCanBeFoundForAllSelectedNodes = false;
        }
      }else{
        allNodesHaveRegionAncestor = false;
        regionOutlineCanBeFoundForAllSelectedNodes = false;
      }


      
    }

  });

  let hasMain = regionIdSet.has("main"); 
  let hasMainAmongstOthers = hasMain && regionIdSet.size > 1;
  let singleRegionSelected = regionIdSet.size === 1;
  let hasOnlyMain = hasMain && singleRegionSelected;
  let noMain = !hasMain;
  let hasSingleRegionId = regionIdSet.size === 1;
  let singleCellLocation = rowSet.size === 1 && colSet.size === 1;





  /* Computed Aggregated Selected Region List */
  if( !regionOutlineCanBeFoundForAllSelectedNodes ){
    delete regionOutlines.applyToAll;
  }

  /* Children Region Outline List */
  if( !(singleCellLocation && singleRegionSelected) || Object.keys(regionOutlines.byChildSubregions).length === 0 ){
    delete regionOutlines.byChildSubregions;
  }

  if( hasMainAmongstOthers ){
    regionOutlines = undefined;
  }


  if( addItems && hasOnlyMain ){

    addItems = { }
    if( singleCellLocation ){
      addItems.extras = ["regionOutline"]
    }
  }

  if( someRegionNodeHasNoRegionId ){
    addItems = addItems ? {} : addItems;
    if( evaluatedNodes.length === 1 ){
      regionOutlines = undefined;
    }
  }

  if( !singleCellLocation ){
    regionOutlines = undefined;
  }

  if( allNodesHaveRegionAncestor ){
    position = {};
  }else{
    position = undefined;
  }

  if( !singleCellLocation ){
    setRegionVariable = undefined;
  }

  let toReturn = {
    editRegion,
    position,
    setRegionVariable,
    spacing:{},
    imageFilters:{},
    addItems,
    regionOutlines,
  }


  return toReturn;
}



G.getPossibleFigureEditorActions = function(state,{figurePanelId,selectedItems}){

  let { cells, expansionNodes, templateNodes } = selectedItems;



  if( cells && cells.length > 0 ){


  }else if( expansionNodes && expansionNodes.length > 0 ){

    // assume templateNodes and expansionNodes 
    // can't be selected simultaneously...
    // somethings can't be done in the template
    //  also, not everything is going to be perfect the first time around
    // i need to remember:
    //  write the requirements
    //  be quick, don't stop moving, if possible.
    //


    let typeSet = new Set();

    

    if( expansionNodes ){

      let evaluatedNodes = expansionNodes.map(info => {
        let getterArgs = {
          figurePanelId, ...info
        }
        let value = G.getEvaluatedFigurePanelCellNodeValue(state,getterArgs)

        if( Object.keys(value).length === 0 ){
          throw Error("\n\nYo, couldn't get nuffin' for dis one: " + info.nodeId+"\n\n\n");

        }


        return value;

      });

      

      evaluatedNodes.forEach(node => {
        let { type } = node;
        typeSet.add(type);
      })

      let nTypes = typeSet.size;
      if( nTypes > 1 ){
        return { spacing:{} }
      }else if( nTypes === 1 ){
        let type = Array.from(typeSet)[0];
        switch(type){
          case "region":{

            let toReturn = getPossibleRegionFocusActions(state,{figurePanelId,expansionNodes,evaluatedNodes});
            debugger;
            return toReturn;
          }
          case "scalebar":{
            return getPossibleScalebarFocusActions(state,{figurePanelId,expansionNodes,evaluatedNodes})
          }
          case "text":{
            return {
              spacing:{},
              position:{},

            }
          }
          case "regionOutline":{
            return {
              regionOutlines:{ 
                targetLists:expansionNodes
              }
            }
          }
          default:{
            throw Error(`Unregistered type '${type}', cannot return available actions.`);
          }


        }
      }else{
        return { NOTHING_HERE:"NEED_TO_SET_TYPES" };
      }


    }else if( templateNodes ){
    }


  }




  //track locations with location.join('-');
  let cellLocations = new Set();

  let possibleActions = {

  }

  let cellTypeSummary = {
    cellLocations:(new Set()),
    nodeTypes:(new Set()),

  }


  let add = {};

  if( true ){
  }



  //

  // for each node
  // we check it's type: 
  //  current types:
  //    (scalebar, text, regionContainer, region)
  
  /*
   position is the only ~ complicated one.
   position:
    1) some ancestor is a region
    2) we shouldn't allow users to overlap items in the same corner (by sending 2 at once). Hence, only one item can be selected per closest regionContainer ancestor


    3) 

   scalebar editor:
    - if the items are only scalebar

   adder:
    - if the items are only regions

   spacing-editor:
    - this actually works for everything
    
   image-region choice:
    - region only

   channel selector
    - region only

   subregion-outline editing:
    also ~complicated because
    it's not directly the node we're editing...
    and also, if they have selected multiple
    subregions, it's weird to know which one is which...
    we can display "region previews" if region from different cells are selected
    so for now, I'll say it's only if a single cell is selected.






   
  
  */

}

G.getImageResolution = function(state,{imageId}){
  let { versions } = G.getData(state,{itemType:IMAGE_UPLOADS,_id:imageId});
  return versions.raw.resolution;
}

G.getNodeItems = function(state,{templateId, nodeId}){
 
  let template = G.getData(state,{itemType:TEMPLATES,_id:templateId});
  let { nodes } = template;
  let { items=[] }  = nodes[nodeId].value;

  return items;

}


G.getTemplateExpansionInterior = function(_,template){
  return null;
}


function mergeConsecutiveItemsOfDirection(lst,direction) {
  let mergedList = [];
  let currentItem = { d:direction, c:[] };
  let directionless = [];
  let curDirection;

  for (let item of lst) {
    if( typeof(item) === typeof('') ){
      currentItem.c.push(item);
    }else if( item.d === direction ){
      currentItem.c.push(...item.c)
    }else if( item.d !== direction ){
      if( currentItem.c.length > 0 ){
        mergedList.push(currentItem);
        currentItem = { d:direction, c:[] }
      }
      mergedList.push(item);
    }
  }

  if( currentItem.c.length > 0 ){
    mergedList.push(currentItem);
    currentItem = { d:direction, c:[] }
  }
    
  return mergedList;
}





function expandTemplateChild(nodes,childId){

  //console.log("expanding " + childId);
  let node = {...nodes[childId]};
  delete node.p;

  let c;
  if( !node.c ){
    c = [childId];
  }else{
    c = node.c && node.c.map(thisChildId => {
      //console.log(`expanding ${childId}.${thisChildId}`);
      let expanded = expandTemplateChild(nodes,thisChildId);
      //console.log(expanded);
      if( expanded.c.length === 1 ){
        return expanded.c[0];
      }else{
        return expanded;
      }
    });
    let direction = node.d;

    let merged = mergeConsecutiveItemsOfDirection(c,direction)
    if( merged.length === 1 ){
      merged = merged[0].c;
    }
    c = merged;
  }

  return {
    ...node,
    c
  }

}

function updateNestedObject(object,updates){
  let updated = JSON.parse(JSON.stringify(object));
  for(let updateKey in updates){
    let updateKeyPath = updateKey.split('.');
    let currentObjectContainer = updated;
    let updatedValue = updates[updateKey];

    updateKeyPath.forEach((pathItem,iiPath) => {
      if( iiPath === (updateKeyPath.length - 1) ){
        currentObjectContainer[pathItem] = updatedValue;
      }else{

        if( !currentObjectContainer[pathItem] ){
          currentObjectContainer[pathItem] = {}
        }
      }

      currentObjectContainer = currentObjectContainer[pathItem]

    })
  }
  return updated;
}


G.getTemplateExpansionNode = function(_,template,nodeId){
  let expansion = G.getTemplateExpansion(_,template);
  let { nodes } = expansion;
  return nodes[nodeId];
}

G.getEvaluatedCellExpansionPathItem = function(state,args){
  let { figurePanelId, cell, path } = args;
  let value = G.getCellsValue(state,{...args,cells:[cell]})[0];
  return value;

}


G.getFigurePanelTemplate = function(state,{templateId,figurePanelId,cellLocation,localTemplateId}){

  if( templateId ){
    return G.getData(state,{_id:templateId,itemType:TEMPLATES});
  }

  let figurePanel = G.getData(state,{_id:figurePanelId,itemType:FIGURE_PANELS});


  localTemplateId || (localTemplateId = G.getCellsValue(state,
    {figurePanelId,cells:[cellLocation]}
  )[0].localTemplateId);


  let { config } = figurePanel;
  let { templates } = config;
  let template = templates[localTemplateId];
  return template;
  
}


G.getTemplateExpansion = function(_,template){

  
  const INSIDE = "inside";

  
  let { channelStructure, regions=[], text=[], scalebar=[], } = template;

  let channelList = channelStructure.split(',').map(x => x.split(' ')).flat();

  let rows = channelStructure.split(',').map(row => row.trim().split(' '));
  let numChannels = rows.flat().length;
  let nodes = {};

  let regionIdList = [];

  nodes.root = {
    d:(rows.length > 1 ? "v" : "h"),
    p:null,
    c:[],
  };


  let regionsByChannel = {};
  regions.forEach((region,ii) => {

    let regionVariableId = "region-"+ii;
    regionIdList.push(regionVariableId);

    for(let channelsToSpecify in region){
      let channelSpecificRegionArgs = region[channelsToSpecify];
      let regionChannelList = channelsToSpecify.split(',');
      
      regionChannelList.forEach(channel => {
        
        let channelNotYetRegistered = !(channel in regionsByChannel);
        if( channelNotYetRegistered ){ regionsByChannel[channel] = {} }

        regionsByChannel[channel][regionVariableId] = channelSpecificRegionArgs

        /* need to introduce the regionOutline nodes */
      })

    }
  })

  channelList.forEach(channel => {
    //add the region outline nodes to each channel region container




  })

  //** create region outlines for each channel...
  



  rows.forEach((row,iiRow) => {

    let rowContainerId = (rows.length === 1 ? 'root' : 'row-'+iiRow);
    if( !nodes[rowContainerId] ){
      nodes[rowContainerId] = {
        c:[],
        p:'root',
        d:"h" //the row containers are going to be horizontal
      }
    }

    if( rows.length > 1 ){
      nodes.root.c.push(rowContainerId);
    }

    let rowContainer = nodes[rowContainerId];


    row.forEach( channel => { 
      let before = []
      let after = [];
      let inside = [];

      let parentId = channel+'-p';
      nodes[parentId] = {
        d:"h",
        p:rowContainerId,
      }

      /*regionIdList.forEach((regionId,ii) =>{

        let regionOutlineId = channel+"-outline-"+ii;
        inside.push(regionOutlineId);

        nodes[regionOutlineId] = {
          p:channel,
          type:"regionOutline",
          regionId,
          style:{
            stroke:"white",
            strokeWidth:1
          },
        }

      })*/





      let channelRegions = regionsByChannel[ channel ] || {};



      Object.entries(channelRegions).forEach((regionsEntries,iiRegion) => {

        let [regionVariableId,regionSpec] = regionsEntries;

        let { location, ...regionNodeArgs } = regionSpec;

        let regionNodeId = channel+'-'+iiRegion;

        let regionId = regionVariableId;



        let locationList = ({
          before,
          after,
          below:after,
          above:before,
          inside,
        })[location];

       
        let d = ({
          below:"v",
          above:"v",
          left:"h",
          right:"h",
        })[location] || "h";

        nodes[parentId].d = d;

        locationList.push( regionNodeId );
       
        let parentToSet = location === INSIDE ? channel : parentId;



        nodes[ regionNodeId ] = {

          type:"region",
          p:parentToSet,
          style:{},
          value:{ regionId, channels:channel },
          ...regionNodeArgs
        }
      })

      
      let c = [...before,channel,...after]

      nodes[ channel ] = {
        type:"region",
        p:parentId,
        style:{},
        value:{ regionId:"main", channels:channel }
      }

      nodes[channel].c = inside
     


      nodes[parentId].c = c;


      if( !rowContainer.c ){
        rowContainer.c = [];
      }
      rowContainer.c.push(parentId);




    })
  })

  text.forEach((textItem,iiTextItem) => {
    let { applyAllChannels={}, ...channelSpecificTestArgs } = textItem;

    channelList.forEach(channel => {

      let textBase = textItem.applyAllChannels || {}

      let textNodeId = channel+'-text-'+iiTextItem;

      let channelTextProperties = channelSpecificTestArgs[channel];
      if( channelTextProperties !== null ){

        let textNode = channelTextProperties ? 
          updateNestedObject(textBase,channelTextProperties)
          : textBase;

        if( !textNode ){
          //console.log({channel, textNodeId});
        }

        nodes[textNodeId] = textNode
        textNode.type="text";
        textNode.p = channel;
        let channelNode = nodes[channel];
        if( !channelNode.c ){
          channelNode.c = [];
        }
        
        channelNode.c.push(textNodeId);
      }

    })
  })


  scalebar.forEach((textItem,iiTextItem) => {
    let { applyAllChannels={}, ...channelSpecificTestArgs } = textItem;

    channelList.forEach(channel => {

      let textBase = textItem.applyAllChannels || {}

      let textNodeId = channel+'-scalebar-'+iiTextItem;

      let channelTextProperties = channelSpecificTestArgs[channel];
      if( channelTextProperties !== null ){

        let textNode = channelTextProperties ? 
          updateNestedObject(textBase,channelTextProperties)
          : textBase;

        if( !textNode ){
          console.log({channel, textNodeId});
        }

        nodes[textNodeId] = textNode
        textNode.type="scalebar";
        textNode.p = channel;
        let channelNode = nodes[channel];
        if( !channelNode.c ){
          channelNode.c = [];
        }
        
        channelNode.c.push(textNodeId);
      }

    })
  })

  return { nodes, regions:regionIdList };

}

G.getRefinedTemplateExpansion = function(_,template){
  let expansion = G.getTemplateExpansion(_,template);

  let { nodes } = expansion;
  let expandedRoot = expandTemplateChild(nodes,"root");
  return expandedRoot;
}

G.getExpansionTemplateItem = function(state,{templateId, itemId}){

  let template = G.getData(state,{itemType:TEMPLATES,_id:templateId});
  let item = template.items[ itemId ];
  debugger;
  return item;

}

G.getFigureRegionExpansionLayout = function(state,{template,templateId,_id,nodeId="root"}){
  _id = _id || templateId;
  let data = G.getData(state,{itemType:TEMPLATES,_id});
  let { nodes } = data;
  let targetNode = nodes[nodeId];
  return targetNode;
}

G.getParentProcedureExecution = function(state,itemSpec){
  let item = G.getRecord(state,itemSpec);
  let { meta } = item;
  let { requiredAsByRole } = meta;
}

G.getSelectedExperimentCellCulture = function(state){
  return state.cellCulture;
}

G.getDiscounts = function(state){
  let response = G.getRequestResponse(state,{route:'/getDiscounts'})

  if( response && response.status === 'success' ){
    let data = response.data;
    if( data ){
      let dateStringified = data.map(dd => ({
        ...dd,
        start:stripeDateToString(dd.start),
        end:stripeDateToString(dd.end)
      }))
      return dateStringified;
    }
    return response;
  }

  return response;
  //return state.discounts;
}


G.getSubregionAnnotations = function(state,{annotationId}){

  let atn = G.getAnnotation(state,annotationId);
  let { imageSetId } = atn;

  let parentAnnotations = G.getAnnotationsByImageSetId(state,imageSetId);





}


G.getDefaultFigureExpansionTemplateId = function(state,args){
  let { figurePanelId  } = args || {};
  let templateIds = Object.keys(state.data.templates);
  //let templateIds = Object.keys(templates);
  return templateIds[0];
}


G.getAnnotationDimensions = function(state,{_id}){
  let atn = G.getAnnotation(state,{_id});
  let { ls, height } = atn;

  let w = round(distance(...ls),4);
  let h = round(height,4) / w;

  return { w:1,h }
}

function getTemplateExpansionDimensions(state, { targetNodeId, nodeMap, dimensionCache={}, nodeDimensions, regions }){

  let { c, d, value, dimension } = nodeMap[targetNodeId];

  /*if( value && c ){
    throw Error(`${targetNodeId} has both children and a value, which shouldn't happen.`);
  }*/

  if( value ){
    let { regionId } = value;



    let annotationId = regions[regionId];
    if( !annotationId ){
      nodeDimensions[targetNodeId] = { w:1, h:1 }
      return;
    }

    if( !regionId && !annotationId  ){
      throw Error(targetNodeId + " has no 'annotationId', received: " + JSON.stringify(value))
    }

    let atnDim = dimensionCache[annotationId];
    if( !atnDim ){
      dimensionCache[annotationId] = G.getAnnotationDimensions(
        state,{_id:annotationId}
      );

      atnDim = dimensionCache[annotationId];
      
    }





    nodeDimensions[targetNodeId] = atnDim;
    return;

  }else if( !value && c ){
    //console.log(targetNodeId+ " has children! so...")
    
    c.forEach(childId => 
      getTemplateExpansionDimensions(
        state,
        {
          targetNodeId:childId,
          nodeMap,
          dimensionCache,
          nodeDimensions,
          regions
        }
      )
    )

    let numeratorKey = ({ v:"h", h:"w" })[d];
    let denomKey = ({ v:"w", h:"h" })[d];

    let childDims = c.map(childId => nodeDimensions[childId]);

    //console.log({childDims});
    
    let denominator = childDims.reduce((sum,nd) => {
      return sum + (nd[numeratorKey]/nd[denomKey])
    },0);

    //console.log({denominator, targetNodeId});
   
    /*
    console.log({
      targetNodeId,
      childDims
    })
    */




    let dimensions = childDims.map( cDim => {

      let percentage = (cDim[numeratorKey]/cDim[denomKey]) / denominator; 
      let adjustedDim = percentage

      return {
        [numeratorKey]:(adjustedDim),
        [denomKey]:1
      }
    })


    //why must we update the children?

    c.forEach((childId,ii) => {
      //console.log("setting " + childId + " to: "+ JSON.stringify(dimensions[ii]));
      nodeDimensions[childId] = dimensions[ii]
    })



    /* now compute the dimensions of the parent
     * as a result of the children! */

    nodeDimensions[ targetNodeId ] = { 
      [ numeratorKey ]: 1,
      [ denomKey ]: (1 / denominator)
    }


    return;
  }else if( dimension ){
      nodeDimensions[targetNodeId] = dimension;
  }else{
    nodeDimensions[targetNodeId] = { w:1, h:1 }
  }

  return;


    //compute the remaining percentage available?
    //or... just take the max...

}


function resolveTemplateArgs(state,{expandableTemplate,template,templateId}){


  if( expandableTemplate ){
    template = G.getTemplateExpansion(null, expandableTemplate);
  }else if( templateId ){
    template = G.getData(state,{itemType:TEMPLATES,_id:templateId});
  }

  return template;

}

G.getEvaluatedExpansionNodeDimensions = function(state,{figurePanelId,cellLocation, localTemplateId, templateId, nodeId}){
  //compute so that the dimension opposite to each root
  //are flush
  //i.e, if root = { ..., d:"h" }, 
  //then we want all it's children height to be 1
  
  //returning a DICT with each node mapping to its dimensions



  let { regions } = G.getCellsValue(state,{figurePanelId,cells:[cellLocation]})[0];
  let template = G.getFigurePanelTemplate(state,{figurePanelId,localTemplateId,templateId,cellLocation});

  let { nodes } = template;

  // we just traverse along the root.
  let { root } = nodes;

  let nodeDimensions = {}


  if( !regions ){
    throw Error("Cannot compute template dimensions without region mapping.");
  }

  getTemplateExpansionDimensions(state,{targetNodeId:"root",nodeMap:nodes,dimensionCache:{},nodeDimensions,regions});

  for(let nodeId in nodes){
    if( !nodeDimensions[nodeId] && nodes[nodeId].type === "region" ){
      getTemplateExpansionDimensions(state,{targetNodeId:nodeId,nodeMap:nodes,dimensionCache:{},nodeDimensions,regions});
    }
  }
  //console.log({nodeDimensions});

  if( nodeId ){
    return Object.fromEntries(Object.entries(nodeDimensions[nodeId]).map(([key,val]) => ([key,Number(val.toFixed(3))])));
  }

  return nodeDimensions;
  
}


G.getAnnotationAssociatedRegions = function(state,{annotationId}){
  return {
    main:annotationId
  }
}

G.canDeleteFilesystemItem = function(state,filesystemItem){

  let { itemType, type, _id } = filesystemItem;


  let resolvedType = itemType || type || G.getItemType(state,_id);


  let alwaysDeletable = ALWAYS_DELETABLE_ITEM_TYPES.has( resolvedType );

 
  if( alwaysDeletable ){
    return true;
  }

  if( resolvedType === DIRECTORIES ){
    let directoryChildren = G.getFsChildren(state,{_id,type});

    return directoryChildren.length === 0;
  }

  return false;


}

G.isSubscriptionCancelled = function(state,{productId}){
  let { subscriptions } = state.userInfo;
  let product = subscriptions[productId];
  return Boolean(product.cancelled);
}


G.isEmailVerified = function(state){

  let { userInfo } = state;
  if( !userInfo ){
    return false;
  }
  let { emailVerificationStatus } = userInfo;
  return emailVerificationStatus;


}

G.parseLatex = function(state,latex){

  // the easiest thing I can do
  // is only allow one level of superscript and subscript.
  // so no nested...

  //and then there's also handling of '\n' characters

  // I really don't want to be programming this in

  const isWhitespace = char => {
    return 
  }

  

 
  let current = { text:"" }
  let parsed = [current]
  let stack = [current];
  let bracketLevel = 0;


  for(let char in latex){

    if( char === '_' ){


    }else if( char === '^' ){
    }else if( char === '{' ){

    }else if( char === '}' ){
    }else if( isWhitespace(char) ){


    }else{
    }

  }




}

G.getReferrer = function(state){

  let { userInfo } = state;
  let { referrer } = userInfo;
  if( referrer ){
    return referrer.referrerUsername;
  }
}

G.canRegisterReferrer = function(state){
  let { userInfo } = state;
  let { referrer, subscriptions } = userInfo;


  if( subscriptions.sciugoMain.stripeSubscriptionId ){
    return false;
  }


  if( !referrer ){
    return true;
  }
}


G.getUserReferrals = function(state){
  return [];
}


G.shouldLaunchTutorial = function(state){
  let { userInfo } = state;

  let isLoggedIn = G.isLoggedIn(state);
  if( isLoggedIn && 
    !userInfo.onboardingTutorial && !Boolean(state.tutorialState)
  ){
    return true;
  }

  return false;

}

G.isInTutorial = function(state){
  let { tutorialState } = state;

  return Boolean(tutorialState);
}

G.shouldEnforcePaywall = function(state){

  let isLoggedIn = G.isLoggedIn(state);
  if( isLoggedIn ){

    let subscriptionExpired = G.isProductSubscriptionExpired(state,{productId:"sciugoMain"});

    return subscriptionExpired;
  }

  return isLoggedIn;

  

}

G.getRequestResponse = function(state,args){
  let { route, projection } = args;
  let response =  state.requests[route];
  return  project(response,projection)
}

G.isProductSubscriptionExpired = function(state,{productId}){
  let expiredProducts = G.getProductsWithExpiredSubscriptions(state);
  return expiredProducts.includes(productId);
}

G.getSubscriptions = function(state){
  return state.userInfo.subscriptions;
}

function stripeDateToString(number){

  let dateObj = new Date(number*1000);

  let timeString = dateObj.toLocaleTimeString([], {hour:'numeric', minute:'2-digit'}).replaceAll('.','').toUpperCase(); 
  let dateString = (dateObj.toDateString() + ' ' + timeString).replaceAll(' 0',' ');

  return dateString;

}

G.getSubscriptionExpiry = function(state,args){
  let productId = "sciugoMain";
  let subInfo = G.getSubscriptions(state)[productId];
  if( !subInfo ){
    return;
  }

  let { expiry } = subInfo;
  return expiry;
}

G.getSubscriptionExpiryDateString = function(state){
  let expiry = G.getSubscriptionExpiry(state);
  
  if( expiry === "never" ){
    return "You have full access to Sciugo."
  }

  let expiryDateString = stripeDateToString(expiry);

  return expiryDateString;



}

G.getSubscriptionActivityMessage = function(state,{productId}){

  let subs = G.getSubscriptions(state);
  let subInfo = subs[productId];
  if(!subInfo){
    return;
  }

  let { expiry, plan } = subInfo;
  if( expiry === "never" ){
    return "You have full access to Sciugo."
  }

  let curDate = Math.round((Date.now()/1000))


  let expiryDateString = stripeDateToString(expiry);

  /*
  let expiryDateObj = new Date(expiry*1000);

  let timeString = expiryDateObj.toLocaleTimeString([], {hour:'numeric', minute:'2-digit'}).replaceAll('.','').toUpperCase(); 
  let expiryDateString = expiryDateObj.toDateString() + ' ' + timeString;*/

  let planStringMap = {
    "30day-academic-individual":"30 day academic individual",
    "annual-academic-individual":"annual academic individual",
    "30day-industry-individual":"30 day industry individual",
    "annual-industry-individual":"annual industry individual",
    "30day-academic-lab":"30 day academic lab",
    "annual-academic-lab":"annual academic lab",
    "30day-industry-lab":"30 day industry lab",
    "annual-industry-lab":"annual industry lab"

  }

  


  let planString = planStringMap[plan];

  if( !planString && plan ){
    console.error("Could not find a plan string for '"+plan+"'");
  }else if( planString ){
    planString += " subscription";
  }



  let expired = curDate > expiry;

  let accessType = expired ? "limited" : "full";
  let endVerbConjugation = expired ? "ed" : "s";


  let inFreeTrial = !planString;

  let planAction = (inFreeTrial || expired) ? "end" : "renew";

  let { cancelled } = subInfo;
  
  if( cancelled && !expired ){
    endVerbConjugation = '';
    planAction = 'ends without renewal';
  }


  return `You have ${accessType} access to Sciugo. Your ${planString || "trial period"} ${planAction}${endVerbConjugation} ${expiryDateString}.`
}

G.getProductsWithExpiredSubscriptions = function(state,args){

  let { date } = args || {};

  date = date || Math.round((Date.now()/1000))


  if( !state.userInfo ){
  }

  let { subscriptions } = state.userInfo;
  if( !subscriptions ){
    return [];
  }


  if( process.__debugger ){
    debugger;
  }

  

  let expired = Object.values(subscriptions).filter(x => {
    let expiry = x.expiry;
    /*//console.log({
      expiry,date,
      expiryStr:(new Date(expiry*1000)).toString(),
      dateStr:(new Date(date*1000)).toString()

    });*/

    if( expiry === 'never' ){
      return false;
    }
    return date > expiry;
  }).map(x => x.productId);

  return expired;


}




function getRepeatLengthMap(row){
  let map = {};
  let lastGroup;
  row.forEach((group,ii) => {
    if( group !== lastGroup ){
      map[ii] = 1;
      lastGroup = group;
    }else{
      map[ii] = (map[ii]||0)+1;
    }

  })

  return map;
}


G.getVerticallyMergedMap = function(state,{figurePanelId,rowIndices}){
  let sortedRowIndices = [...rowIndices];
  sortedRowIndices.sort();

  let topRow = G.getTableRowGroups(state,{figurePanelId,rowIndex:rowIndices[0]});
  let bottomRow = G.getTableRowGroups(state,{figurePanelId,rowIndex:rowIndices[1]});


  let bothNotNullish = !(isNullish(topRow) || isNullish(bottomRow));

  let mergedMapLengths = {};

  if( bothNotNullish ){
    let tlen = topRow.length;
    let blen = bottomRow.length;
    if( tlen !== blen ){
      throw Error(`Top/bottom rows have different lengths... ${tlen},${blen}`);
    }

    let inVMerge = false;
    let curMergeStart = -1;
    let lastMergeGroup;


    for(let ii = 0; ii < topRow.length; ii++){

      let tGroup = topRow[ii];
      let bGroup = bottomRow[ii];

      if( tGroup === bGroup ){
        if( tGroup !== lastMergeGroup ){
          inVMerge = false;
        }

        if( inVMerge ){
          mergedMapLengths[curMergeStart]++;
        }else{
          lastMergeGroup = tGroup;
          curMergeStart = ii;
          mergedMapLengths[curMergeStart] = 1;
        }
      }else{
        inVMerge = false;
        lastMergeGroup = undefined;
      }
    }
  }

  return mergedMapLengths;

}


function getCompliantMerges({
  surroundingRowMap,
  rowToMoveMap
}){

  let compliantMerges = [];
  let uncompliantMerges = [];
  for( let surroundingMergeStart in surroundingRowMap ){
    let mergeLength = surroundingRowMap[surroundingMergeStart];
    let incomingLength = rowToMoveMap[surroundingMergeStart];

    let mergeList = mergeLength === incomingLength ? compliantMerges : uncompliantMerges;
    mergeList.push(surroundingMergeStart);
  }

  return {
    compliantMerges,
    uncompliantMerges
  }

}


function getMovingRowTemplate(rowStructure){
  const templateChar = '_';

  return rowStructure.map((len,ii) => {
    return Array(len).fill(templateChar.repeat((ii+1)))
  }).flat();

}


G.getNewRowGroupsAfterMove = function(state,args){
  //this might need to return the entire table...
  //because a row move can actually cause 
  //the creation of entire new groups...

  let { figurePanelId, fromIndex, toIndex, mergeWith, newGroupIds } = args;

  let figurePanel = G.getFigurePanel(state,{figurePanelId});

  let nRows = figurePanel.grid.length;
  toIndex = Math.min(toIndex,nRows);


  
  let cellGroups = figurePanel.cellGroups;



  //this line here is inspired by the case when we want to move a row to
  //itself, but move OUT of it's current merge the the row above it.
  //in that case, we defer to the merge in common with the row above
  //and the row below its current position, assuming its essentially removed.
  
  let bottomRowToCheckIndex = fromIndex !== toIndex ? toIndex : (toIndex + 1);





  let verticallyMergedMapAroundInsertion = G.getVerticallyMergedMap(state,{figurePanelId,rowIndices:[(toIndex-1),bottomRowToCheckIndex]});

  let rowToMoveGroups = G.getTableRowGroups(state,{figurePanelId,rowIndex:fromIndex});
  let toMoveHorizMergeLengthMap = getRepeatLengthMap(rowToMoveGroups);


  //all filled with underscores...
  let rowToMoveStructure = G.getRowStructure(state,{figurePanelId,rowIndex:fromIndex});
  
  let rowToMove = getMovingRowTemplate(rowToMoveStructure);

  let { uncompliantMerges, compliantMerges } = getCompliantMerges({
    surroundingRowMap:verticallyMergedMapAroundInsertion,
    rowToMoveMap:toMoveHorizMergeLengthMap
  })


  let newCellGroups = JSON.parse(JSON.stringify(figurePanel.cellGroups));
  let newCellGrid = JSON.parse(JSON.stringify(figurePanel.grid));

  //for the uncompliantMerges we need to BREAK_UP the group at that merge...
  
  //loop through each uncompliant merge, and, for however long it is horizontally, 
  //create the new groups, copy them and add them in


  let bottomSurroundingRowGroups = G.getTableRowGroups(state,{figurePanelId,
    rowIndex:bottomRowToCheckIndex
  });

  /*
  if( !bottomSurroundingRowGroups && mergeWith === 2 ){
  }
  */

  

  for(let uncompliantGroupStart of uncompliantMerges){
    let horizGroupLen = verticallyMergedMapAroundInsertion[uncompliantGroupStart];

    let newGroupId = newGroupIds.splice(0,1)[0];

    if( !newGroupId ){
      throw Error("UNDEFINED NEW ID, newGroupIds is empty!");
    }


    if( !bottomSurroundingRowGroups ){
      debugger;
    }

    let groupToRename = bottomSurroundingRowGroups[ uncompliantGroupStart ]; 

    //register new group
      newCellGroups[ newGroupId ] = {
        ...groupToRename,
        _id:newGroupId
      }
    

    let indexOfRowToResetGroups = toIndex;
    
    let groupAtMarker = newCellGrid[ toIndex ][ uncompliantGroupStart ];

    let numGroupStart = Number(uncompliantGroupStart);
    let horizGroupEnd = numGroupStart + horizGroupLen;

    while( groupAtMarker === groupToRename ){
    
      for(let iiCol = uncompliantGroupStart; iiCol < horizGroupEnd; iiCol++ ){
        newCellGrid[ indexOfRowToResetGroups ][ iiCol ] = newGroupId
      }

      indexOfRowToResetGroups++;
      groupAtMarker = newCellGrid[indexOfRowToResetGroups][uncompliantGroupStart]

    }
  }


  //setting things on new row

  for(let compliantGroupStart of compliantMerges){

    let numGroupStart = Number(compliantGroupStart);
    let horizGroupLen = verticallyMergedMapAroundInsertion[compliantGroupStart];

    if( !bottomSurroundingRowGroups ){
      debugger;
    }


    let groupToAssign = bottomSurroundingRowGroups[compliantGroupStart];
    let horizGroupEnd = numGroupStart + horizGroupLen;
    
    for(let iiCol = numGroupStart; iiCol < horizGroupEnd; iiCol++ ){
      rowToMove[ iiCol ] = groupToAssign
    }
  }



  // now go for merges with TARGET ROW...
 
  //do the same kind of thing as above, look for 
  //get a vertically merged map, but for which rows?
  //depends where we're inserting
  //and what were mering with



  if( !isNullish(mergeWith) ){


    
  
    let toMergeRowGroups = G.getTableRowGroups(state,{figurePanelId,rowIndex:mergeWith});


    if( !isNullish(toMergeRowGroups) ){

      let secondRowIndex = mergeWith + (mergeWith < toIndex ? (-1) : 1);

      let targetMergeRowsToCheck = [ mergeWith, secondRowIndex ];

      let targetVMergeMap = G.getVerticallyMergedMap(state,{figurePanelId,rowIndices:targetMergeRowsToCheck});

      if( mergeWith === 2 && toIndex === 2 ){ 
        debugger;
      }

      //now check compliance...

      let targetCompliancesInfo = getCompliantMerges({
        surroundingRowMap:targetVMergeMap,
        rowToMoveMap:toMoveHorizMergeLengthMap
      })
      let targetCompliances = targetCompliancesInfo.compliantMerges;

      

      for(let compliantGroupStart of targetCompliances){

        let horizGroupLen = targetVMergeMap[compliantGroupStart];
        let groupToAssign = toMergeRowGroups[compliantGroupStart];

        let numGroupStart = Number(compliantGroupStart);
        let horizGroupEnd = numGroupStart + horizGroupLen;

        for(let iiCol = numGroupStart; iiCol < horizGroupEnd; iiCol++){

          rowToMove[ iiCol ] = groupToAssign

        }
      }
    }

  }






  
  let originalToMove = figurePanel.grid[fromIndex];

  let assignedGroups = {}

  rowToMove.forEach((group,ii) => {

    //I need to take all the unassignedIds
    //and create new cell groups from them...
    //this is by default as the unassigned cell group
    //may have previously been in a merge

    if( (""+group).includes('_') ){
      if( !assignedGroups[group] ){
        let originalGroupId = originalToMove[ii];
        let originalGroupObj = cellGroups[originalGroupId];
        let newId = newGroupIds.splice(0,1)[0]

        if( !newId ){
          throw Error("UNDEFINED NEW ID, newGroupIds is empty!");
        }


        assignedGroups[group] = {...originalGroupObj,_id:newId};
        newCellGroups[newId] = assignedGroups[group];
      }

      let toAssign = assignedGroups[group]._id
      rowToMove[ii] = toAssign;

    }

  })




  newCellGrid.splice(fromIndex,1);
  let resolvedToIndex = fromIndex < toIndex ? (toIndex-1) : toIndex;
  newCellGrid.splice(toIndex,0,rowToMove)

 

  

  return {
    cellGroups:newCellGroups,
    grid:newCellGrid
  }



}


G.peekSurveys = function(state){
  let { surveys } = state;


  if( !surveys ){ return null; }

  let survey = surveys.find(sv => sv.name === 'emailValidationRequired') || surveys[0] || null;
  return survey;

}

G.arePossibleQuantificationAnnotationUpdatesSeen = function(state,{_id}){

  let atn = G.getAnnotation(state,{_id});

  let { quantificationAnnotation } = atn;
  if( !quantificationAnnotation ){
    return true;
  }else{
    return !!quantificationAnnotation.seen;
  }




}

function defaultLabelLayout(){
  return {
    left:["crop"],
    right:["mw"],
  }
}


G.getBreak = function(){
  return '------';
}

G.getTableRowValues = function(state,{_id,figurePanelId,rowIndex}){

  let figurePanel = G.getFigurePanel(state,{figurePanelId:(_id||figurePanelId)});
  let { grid, cellGroups } = figurePanel;
  let row = grid[rowIndex].map(groupId => cellGroups[groupId].value);

  

  return row;

}

G.getTableRowGroups = function(state,{_id,figurePanelId,rowIndex}){

  if( isNullish(rowIndex) ){
    throw Error("Need a non-nullish rowIndex!");
  }

  let figurePanel = G.getFigurePanel(state,{figurePanelId:(_id||figurePanelId)});
  let { grid } = figurePanel;

  let row = grid[rowIndex];

  return row;
}

G.getLabelLayout = function(state,{figurePanelId}){

  let figure = G.getData(state,{_id:figurePanelId,type:FIGURE_PANELS});
  let figureLabelLayout = (figure.config || {}).labelLayout;
  let resolvedLayout = figureLabelLayout || defaultLabelLayout();

  return resolvedLayout;

}

G.getImagePixelDimensionsByAnnotationId = function(state,{annotationId}){
  let imageRecord = G.getImageSetFigureImageDataByAnnotationId(state,{annotationId});

  let { height } = imageRecord;
  let width;


  let versionWithWidth = Object.values(imageRecord.versions).find(v => v.uploadWidth);
  width = versionWithWidth.uploadWidth;

  let scaledHeight = height * width;

  return { height:Math.ceil(scaledHeight), width };
}


G.getLinesOfPixelPositions = function(state,{annotationId, ls, height, imagePixelWidth }){

  if( !ls || !height ){
    let atn =G.getAnnotationProperties(state,{forQuantification:true, properties:["ls","height"], annotationId, _id:annotationId}); 

    ls = atn.ls;
    height = atn.height;
  }


  if( !imagePixelWidth ){
    let imagePixelDimensions = G.getImagePixelDimensionsByAnnotationId(state,{annotationId});
    imagePixelWidth = imagePixelDimensions.width;
  }


  let atnPixelHeight = Math.ceil( imagePixelWidth * height );

  if( atnPixelHeight < 2 ){
    throw Error("Cannot quantify image with height < 2");
  }
  let evenHeight = atnPixelHeight + (atnPixelHeight % 2);

  

  let rawScaledPoints = ls.map(point => point.map(x => x * imagePixelWidth));

  //floor all the decimal points?
  let [p1, p2] = rawScaledPoints.map(pp => pp.map(Math.floor))

  

  let lines = GetLines(p1, p2, evenHeight);


  return lines;



  //let atn = G.getAnnotation(state,{annotationId});
  


}

G.getQuantificationMacro = function(state,{atns}){


  let quantParams = atns.map(atn => {

    let geoData = G.getAnnotationProperties(state,{_id:atn._id,properties:["imageId","ls","height"],forQuantification:true});
    return geoData;
  })

  let quantifiedImages = quantParams.map(({imageId})=>{
    return G.getData(state,{type:IMAGE_UPLOADS,_id:imageId});
  })

  let macroItems = atns.map((atn,iiAtn) => ({
    imageId:quantParams[iiAtn].imageId,
    uploadedImageWidth:quantifiedImages[iiAtn].versions[convertedInfoContainerName].uploadWidth,
    label:atn.annotation.label,
    ls:quantParams[iiAtn].ls,
    imageFilename:quantifiedImages[iiAtn].filename,
    annotationHeight:quantParams[iiAtn].height,
    boundaries:atn.evaluatedLaneOffsets,
    quantifications:atn.annotation.quantifications,

  }))

  let macroScript = createQuantificationMacro(macroItems);

  let content = "data:text/ijm;charset=utf-8,";
  content += macroScript;

  return content;


  

}

G.getAnnotationProperties = function(state,args){
  let { _id, forQuantification, properties } = args;
  let atn = G.getData(state,{_id, type:ANNOTATIONS});


  let toReturnFrom = atn;
  if( forQuantification && atn.quantificationAnnotation ){
    toReturnFrom = atn.quantificationAnnotation;
  }
  
  let toReturn = {};
  properties.forEach(prop => {
    if( prop === "imageId" ){
      let notYetQuantified = !atn.quantificationAnnotation;
      let notSearchingForQuantifiedInfo = !forQuantification;
      if( notYetQuantified || notSearchingForQuantifiedInfo ){
      toReturn[prop] = G.getImageSetFigureImageIdByAnnotationId(state,{
        annotationId:_id
      });
      }else{
        toReturn[prop] = toReturnFrom[prop]
      }

    }else{
      toReturn[prop] = toReturnFrom[prop]
    }
  });

  return toReturn;

}

G.didQuantificationFail = function(state,{annotationId}){
  let atn = G.getAnnotation(state,{_id:annotationId});
  if( atn.quantifications ){
    return atn.quantifications.error;
  }
  return false;
}

G.isQuantified = function(state,{_id}){
  let atn = G.getAnnotation(state,{_id});
  return !!atn.quantifications;
}

G.getAnnotationQuantificationIntegrationRanges = function(state,{annotationId}){
  let atn = G.getAnnotation(state,{_id:annotationId});
  let integrationRanges = atn.quantifications.map(lane => lane.integrationRanges);
  return integrationRanges;
}

G.getQuantificationProcessInfo = function(state,{_id,annotationId}){
  _id = _id || annotationId;

  let quantifications = G.getAnnotationQuantification(state,{_id});

  //obviously assumes that there's only one quantification process per annotation
  let quantificationProcess = Object.values(state.processes).find(process => process.annotationId === _id && process.name === "densitometry");

  if( !quantifications && !quantificationProcess ){
    return { status:"idle" };
  }else if( ! quantifications && quantificationProcess ){
    return { status:"pending" }
  }


}

G.getPossibleQuantificationAnnotationUpdates = function(state,{_id}){


  let atn = G.getData(state,{_id});
  let { ls, height, imageId } = atn;
  let qAtn = atn.quantificationAnnotation; 
  let currentFigureImageId = G.getImageSetFigureImageIdByAnnotationId(state,{annotationId:_id});
  
  if( !qAtn ) {
    return [];
  }

  let qls = qAtn.ls;
  let qheight = qAtn.height;
  let qImageId = qAtn.imageId;

  let upToDateProperties = { 
    imageId:(qAtn.imageId === currentFigureImageId),
    window:(JSON.stringify(qls) === JSON.stringify(ls) && qheight === height),
  }

  let possibleUpdates = Object.keys(upToDateProperties).filter(key => {
    return !upToDateProperties[key]
  });

  return possibleUpdates;


}


G.getAnnotationIdsGroupedByImageSetId = function(state,{annotationIds}){


  let imageSetGrouping = {};

  annotationIds.forEach(atnId => {
    let imageSetId = G.getImageSetIdByAnnotationId(state,{_id:atnId});


    imageSetGrouping[imageSetId] = [
      ...(imageSetGrouping[imageSetId]||[]), atnId
    ]
  })

  return imageSetGrouping;


}


G.getLaneIntegrationBoundsFound = function(state,{annotationId}){

  let atn = G.getAnnotation(state,{_id:annotationId});

  let { quantifications } = atn;
  if( quantifications ){

    //let { integrationRanges } = quantifications;

    return quantifications.map(laneQuants => {
      let allRanges = laneQuants.integrationRanges;
      return allRanges.length === 1 && allRanges[0].length === 2;
    });



  }else{
    let { laneBoundaryPositions } = atn;
    let laneCount = laneBoundaryPositions.length;
    return Array(laneCount).fill(null);
  }


}




G.getFigurePanelWithoutAnnotations = function(state,{figurePanelId}){
  let data = {...G.getData(state,{_id:figurePanelId})}
  let { cellGroups } = data;
  let entries = Object.entries(cellGroups);
  let newCellGroups = Object.fromEntries(entries.map(([key,val]) => {
    if( typeof(val.value) !== 'string' ){
      return [key,{...val,value:""}]
    }
    return [key,val];
  }));

  data.cellGroups = newCellGroups;

  return data;
    

}

G.getMediaProcessingStartArgs = function(state,{_id}){
  let mediaProcessingInfo = state.mediaProcessing[_id];
  let args = {
    storageLocation:"s3",
  };


  debugger;
  mediaProcessingInfo.errors.forEach(error => {
    if( error.type === CONVERSION_TIMEOUT ){
      
      args.convertTo = "png";
      args.conversionTimeoutMs = error.timeout * 2;
    }
  })

  return args;
}

G.getMediaProcessingStatusMessage = function(state,{_id,imageId}){

  _id = (_id || imageId);

  let mediaProcessingInfo = state.mediaProcessing[ _id ];
  let { status, errors } = mediaProcessingInfo;

  if( status === "pending" ){
    return status;
  }

  if( status === "failed" ){

    if( errors.length === 0 ){
      return 
    }

    let priorityIndices = errors.map(x => 
      ProcessingStatusMessageErrorPriority.indexOf(
        x.type
      )
    );

    let highestPriorityIndex = null;
   
    priorityIndices.forEach((idx,ii) => {
      let curPriority = highestPriorityIndex !== null ? priorityIndices[highestPriorityIndex] :  Number.POSITIVE_INFINITY;
      if( priorityIndices[ii] < curPriority ){
        highestPriorityIndex = ii;
      }
    })

    let highestPriorityError = errors[highestPriorityIndex];



    let highestPriorityErrorType = highestPriorityError.type;


    let messageFunction = processingErrorMessageMap[highestPriorityErrorType];

    if( !messageFunction ){
      messageFunction = () => {
        return "Highly unexpected processing error. Try again shortly.";
      }
      /*throw Error("Undefined message function for '"+highestPriorityErrorType+"'");*/
    }

    debugger;


    let message = messageFunction(highestPriorityError);


    return message;
    
  }




  



}

G.getUnpersistedImageVersionsNotCurrentlyBeingSynced = function(state,{imageId}){

  debugger;

  //unpersisted means what?
  //means it doesn't have a 
  //remote resource 
  //storageId

  let image = G.getImage(state,{imageId,pendingRecords:true});


  let imageRecordVersions = image.versions;

  let targetVersions = Object.entries(imageRecordVersions).filter(([version,versionInfo]) => {

    let persistedToCloud = versionInfo.remoteStorageResourceId;

    let mediaVersionInfo = state.media[imageId][version]


    let postInfo = mediaVersionInfo.postInfo;

    return !(postInfo || persistedToCloud)
  })


  return targetVersions.map(version => ({version:version[0],_id:imageId}));


}

G.getDefaultMediaProcessingArgs = function(state,args){
  
  let { convertTo } = args || {};
  return {
    storageLocation:"s3",
    convertTo,

    //convertTo:"png",
    conversionTimeoutMs:15000
  }
}



G.listProcesses = function(state,args){
  let { processType } = args;

  let processes = Object.values(state.processes);
  let filteredProcesses;

  if( processType ){
    filteredProcesses = processes.filter(pr => pr.processType === processType);
  }else{
    filteredProcesses = processes;
  }

  return filteredProcesses;
 
}

G.getSelectedAnnatationsForQuantification = function(state){
  return state.ui[QUANTIFICATION].selectedAnnotations;
}


G.getUiSelectedFigurePanelContext = function(state){
  let { figurePanelContext } = state.ui.modeArgs;
  return figurePanelContext;
}

G.getImageSetMembranes = function(state,{_id}){
  //we need to decide how were going to store/compute data 
  //on linking membranes to crops.
  
  //if there are membranes, there are membranes.
  //now, when we get an atn that's in a membrane
  
  //two options:
  //  1) compute the crops from membrane every time
  //  2) save the crops ids in the membrane

  // What's happening downstream of this? 
  // When we're making figures, we want to know what membrane it came from...
  //  That's mapping annotation to membrane

  // What's the application of the membrane?
  // 1) Helping people find the right target
  
  // If we don't keep track of 
  // membraneId links to targetIds
  // We need to recompute every time
  
  // However, this means if we lose a reference
  // Then it could fuck up all the downstream stats
  // Which are taken...
  
  // And so we'd NEED to implement locks 
  // Or warnings which prevent changes
  // Because downstream stats that are used
  // When using membranes as replicates
  // Those membranes would map to targets
  // And those targets map to quantifications.
  //
  // So if those things got "changed" AND there
  // were downstream reliances,
  // things downstrea would be completely fucked.
  //
  // What does this mean?
  // It could mean that datasets need to be 
  // recompiled and hypotheses need to be retested.
  //
  // It definitely doesn't make sense
  // to recompute target membranes for every single
  // crop.
  //
  // Plus, eventually people may start putting 
  // membranes around many of their crops...


  // Membranes will exist by default...
  // Crops will be automatically associated with a membrane
  // However, sometimes there are MULTIPLE
  // membranes in a single image.
  
  // 
  


}


G.getMediaItem = function(state,args){
  let { _id, imageId, version } = args;
  _id = _id || imageId;
  let { media } = state;
  let imageContainer = media[_id];
  let versionInfo = imageContainer[version];
  return versionInfo;
}

G.getMediaItemsWithPresignedUrls = function(state){
  let imageSpecsWithPresignedUrls = [];
  for( let _id in state.media ){
    for( let version in state.media[_id] ){
      if( version.postInfo.fields ){
        imageSpecsWithPresignedUrls.push({_id,version});
      }
    }
  }

  return imageSpecsWithPresignedUrls;

}


G.getMediaPersistanceStatus = function(state,mediaSpec){
  let { _id, imageId, version } = mediaSpec;

  _id = _id || imageId;

  let mediaItem = state.media[_id];

  if( !mediaItem ){
    
    return undefined;

  }else{

    let itemVersion = mediaItem[version];

    if( !itemVersion ){
      return undefined;
    }


    if( itemVersion.persistError ){
      return { error: itemVersion.persistError };
    }

    if( state.pendingRecords[IMAGE_UPLOADS][_id] ){

      let storageId = G.getRemoteStorageResourceId(state,{...mediaSpec, pendingRecords:true});

      if( storageId ){
        return "persisted";
      }
    }else if( state.data[IMAGE_UPLOADS][_id] ){
      return "persisted";
    }


    if( itemVersion.postInfo === undefined ){
      return "idle";
    }

    return "pending";

    //return "pending";


  }
}

G.getNotificationText = function(state,notification){
  let { notificationType } = notification;
  switch(notificationType){
    case C.imageSyncFailure:{
      let { imageId, _id } = notification.args;
      imageId = imageId || _id;
      let fsName = G.getFilesystemName(state,{type:IMAGE_UPLOADS,_id:imageId})
      return fsName + ' cloud-sync failed.';
    }
    default:{
      return JSON.stringify(notification);
    }
  }

}

G.getNotificationCount = function(state,args){
  return state.notifications.length;
}

G.getNotificationsText = function(state,args){
  let { notifications } = state;


  return notifications.map(nn => {
    let text;
    try{
      text = G.getNotificationText(state,nn)
    }catch(e){
      text = "There was a problem reading the notification: "+JSON.stringify(nn);
    }

    return text;
  })

}

G.getNotificationIdOrder = function(state,args){
  return state.notifications.map(x => x._id);
}

G.isMessageReceived = function(state,args){
  let { by } = args;
  let message = G.getMessage(state,args);
  let { delivered } = message;

  return !!delivered[by]
}

G.isMessageSent = function(state,args){
  let message = G.getMessage(state,args);
  if( ! message ){
  }
  return !!message.sentTimestamp && !message.sendFailure;
}

G.canRestartMediaProcessing = function(state,{_id}){
  let mediaProcessing = state.mediaProcessing[_id];
  if( mediaProcessing ){
    let errors = mediaProcessing.errors;
    let conversionFailure = errors.find(err => err.type === 'imageProcessingError');
    if( conversionFailure ){
      return false;
    }
    return true;
  }
  return false;
}


G.getPanelOrderInFigure = function(state,args){
  let figure = G.getData(state,args);
  let { panelOrder } = figure;
  return panelOrder;
}


G.getMessageText = function(state,args){
  let { threadId, messageId } = args;

  let thread = state.threads[threadId];
  let message = thread.messages[messageId];
  let { text } = message;

  return text;

}

G.getThread = function(state,args){
  let thread = state.threads[args.threadId || args._id];
  return thread;
}

G.getMessage = function(state,args){
  let thread = G.getThread(state,args);
  
  let message = thread.messages[args.messageId];
  return message;
}

G.isMessageRead = function(state,args){
  
  let message = G.getMessage(state,args);
  if( !message ){
  }
  let {read} = message;

  let { by } = args;
  if( by ){
    return !!read[by];
  }else{
    let hasBeenReadBySomeone = Object.keys(read).length > 0;
    return hasBeenReadBySomeone;
  }

}

G.getNewMessages = function(state){

  let threads = state.threads;
  let admin = "admin";

  let newMessages =
    Object.fromEntries(Object.entries(threads).map(([key,val]) => {

      return [
        key,
        Object.values(val.messages).filter(
          msg => {
            let messageNotRead = !Object.keys(msg.read).find(x => x!==admin);
            let messageNotFromThisUser = msg.from === admin;
            return messageNotRead && messageNotFromThisUser;
          }
        ).map(x => x._id)
      ]

    }).filter(x => x[1].length > 0))



  return newMessages;

}



G.getMessagesText = function(state,args){
  let text = state.messages.map(x => x.text);
  return text;
}


G.getSelectedImageIdFromFsFocus = function(state,{focusedFsItemId}){

  let record = G.getRecord(state,{_id:focusedFsItemId});
  let { itemType } = record;
  if( itemType === IMAGE_UPLOADS ){
    return focusedFsItemId;
  }else{
    let images = record.data.images;
    let toReturn = images[0];
    return toReturn;
  }

}

function getUserConfigSaveStatus(state){
  return state.syncStatus.userConfig.status;
}


function getRecordsSaveStatus(state){
  let recordsSyncStatus = state.syncStatus.records;
  let syncStatus;

  for(let recSyncData of Object.values(recordsSyncStatus) ){
    for( let rec of Object.values(recSyncData) ){
      if( rec.status === C.IDLE ){
        return rec.status;
      }else{
        syncStatus = rec.status;
      }
    }
  }
  return syncStatus;
}


function getImageReadFailureDialog(state,args){

  let corruptFilenames = G.getCorruptImageUploadFilenames(state);

  let header = "Image files could not be read (bad format).";

  let listItems = corruptFilenames.map(name => {
    return HTML.li(name);
  })
  return {
    afterHide:[
      {
        name:C.removeCorruptImageUploads
      }
    ],
    header:{
      type:"error",
      text:header
    },
    body:[
      HTML.p("Supported formats include: JPEG, PNG, TIF."),
      HTML.ul(listItems),

      HTML.br([]),
      HTML.p(HTML.b("Recommended solution:")),
      HTML.i([
      HTML.ol([
        HTML.li("Open the image with ImageJ."),
        HTML.li("With ImageJ, save the image as TIF, PNG, or JPEG."),
        HTML.li("Upload the image you saved with ImageJ to Sciugo.")
      ])
      ])
    ],

    buttons:[
      {
        variant:'primary',
        text:'Okay',
        actions:[
          {
            name:C.removeCorruptImageUploads
          },
          {
            name:C.POP_MESSAGE
          }
        ]
      }
    ]

  }
}

function getFilesystemNameCollisionOnMoveDialog(state,args){
  let { fsNameOfItemBeingMoved, newDirName } = args;
  let header = {
    type:'error',
    text:'Cannot move item.'
  }
  let body = `The folder '${newDirName}' already has a file named '${fsNameOfItemBeingMoved}'.`

  return {
    header,
    body,
    buttons:[Dialog.OK_BUTTON]
  }




}
function getFilesystemNameCollisionOnSetDialog(state,args){
  let { newFilesystemName, parentDirName } = args;
  let header = {
    text:"Cannot rename item.",
    type:"error"
  }

  let body = `The name '${newFilesystemName}' already exists in ${parentDirName ? "'"+parentDirName+"'" : "the directory" }.`

  return {
    header,
    body,
    buttons:[
      Dialog.OK_BUTTON
    ]
  }

}

function getIllegalCharacterDialog(state,args){

  let { string } = args;

  return {
    header:{
      type:"error",
      text:"Illegal character used."
    },
    body:"`"+string+"` contains an illegal character.",
    buttons:[
      Dialog.OK_BUTTON
    ]
  }

}

function getImageArchiveWarningDialog(state,args){
  let { imageDeletionConditions, imageId } = args;

  return {
    header:{
      type:"warning",
      text:"Archive image?"
    },
    body:G.getImageDeletionMessage(state,imageId),
    buttons:[

      {
        text:'Archive Image',
        variant:'danger',
        actions:[
          {
            name:C.archiveImages,
            args:{
              imageIds:[imageId]
            }
          },
          {name:C.popMessage}


        ]
      },
      {
        variant:'primary',
        text:'Cancel',
        actions:[
          {name:C.popMessage}
        ]
      },
      
    ]
  }

}

function getUnexpectedServerErrorDialog(state,args){
  return {
    header:{ 
      text:'Unexpected server error occurred.',
      type:'error'
    },
    body:HTML.div(
      HTML.p('An unexpected server error occurred.'),
      HTML.p('Please report this error so it can be will resolved ASAP.')
    ),
    buttons:[
      Dialog.OK_BUTTON
    ]
  }
}

function getSaveFailedOnServerDialog(state,args){
  return {
    header:{ text:"Save failed.", type:"error" },
    body:"An error occurred on the server and we cannot confirm whether your data was saved. Try again in a few minutes.",
    buttons:[
      Dialog.OK_BUTTON
    ]
  }
}

function getNoInternetConnectionDialog(state,args){
  return {
    header:{ text:"Cannot connect to the internet.", type:"error",
    },
    body:"You are not connected to the internet.",
    buttons:[
      Dialog.OK_BUTTON
    ]
  }
}

function getSubscribeDialog(state,args){
  let { action } = args;
  action = action || 'Continue';
  return {
    header:{
      text:'Subscribe to ' + action,
    },
    body:('Your subscription period has ended. Subscribe to continue to upload images, create figures and quantify in Sciugo.'),
    buttons:[
      Dialog.OK_BUTTON,
      Dialog.SUBSCRIBE_BUTTON,
    ]
  }
}

function getCustomDialog(state,args){
  return {
    buttons:[Dialog.OK_BUTTON],
    body:'<EMPTY BODY>',
    header:{ type:'', text:'' },
    ...args
  }
}

function getPleaseReportBugDialog(state,args){
  return {
    header:{ text:'A serious error occurred.', type:'error' },
    body:'We detected an serious error and tried to automatically report it, but the automated report failed. If this error disrupted your work-session, please report it manually. Otherwise, ignore this message.',
    buttons:[
      Dialog.OK_BUTTON
    ]
  }
}


G.getDialog = function(state,dialog){
  let content = G.getDialogContent(state,dialog);
  let { args, dialogName } = dialog;
  return {
    ...content,
    dialogName,
    args
  }
}

function getMultiImageDragDialog(state,args){
  return {
    ...args,
    header:{
      text:"Cannot merge image sets.",
      type:"error"
    },
    body:[
      HTML.p("Currently, simultaneously dragging multiple images to another image-set is not supported."),
      HTML.p("You must transfer each image individually."),
    ],
    buttons:[
      Dialog.OK_BUTTON
    ]
  }


}

function getNotificationMessageDialog(state,args){

  return {
    header:{
      text:"Message",
      type:"warning"
    },
    body:args.message,

    buttons:[
      Dialog.OK_BUTTON
    ]
  }
}

function getConfirmDeleteImageItemDialog(state,args){

  let acceptArgs = {...args, force:true}

  return {
    header:{
      text:"Delete image?",
      type:"warning"
    },
    body:(
      G.getArchiveItemWarning(state,args.items[0])
    ),
    buttons:[
      {
        text:'Delete',
        variant:'danger',
        actions:[
          {
            name:C.tryArchiveItems,
            args
          },
          {
            name:C.popMessage
          }
        ]
      },
      {
        text:'Cancel',
        variant:'secondary',
        actions:[
          { name:C.popMessage }
        ]
      }
    ]
  }

}

function getConfirmDeleteItemDialog(state,args){

  let fsName = G.getFilesystemName(state,args);
  return {
    header:{
      text:"Delete item?",
      type:"warning"
    },
    body:(
      `Permanently delete ${fsName}? This cannot be undone.`
    ),
    buttons:[
      {
        text:'Delete',
        variant:'danger',
        actions:[
          {
            name:C.deleteItem,
            args
          },
          {
            name:C.popMessage
          }
        ]
      },
      {
        text:'Cancel',
        variant:'secondary',
        actions:[
          { name:C.popMessage }
        ]
      }
    ]
  }
}

G.getDialogContent = function(state,dialog){

  let { dialogName, args } = dialog;
  
  switch(dialogName){

    case Dialog.SETTINGS:{
      //these can all be blank
      //because no dialog should
      //show up...
      //it should just be the
      //account settings.
      return {
        dialogName:"SETTINGS",
        args,
        body:[],
        header:{
          text:"Settings"
        },
        buttons:[],
      }
    }

    case Dialog.SUBSCRIBE_ACTION:{
      return {body:[],header:{text:"Subscribe"},buttons:[]};
    }
    case Dialog.SUBSCRIBE:{
      return getSubscribeDialog(state,args);
    }

    case Dialog.CONFIRM_DELETE_IMAGE_ITEM:{
      return getConfirmDeleteImageItemDialog(state,args);
    }

    case Dialog.CONFIRM_DELETE_ITEM:{
      return getConfirmDeleteItemDialog(state,args);
    }

    case Dialog.WITH_NOTIFICATION_MESSAGE:{
      return getNotificationMessageDialog(state,args);
    }
    case Dialog.DRAGGING_IMAGE_SETS_WITH_MULTIPLE_IMAGES_UNSUPPORTED:{
      return getMultiImageDragDialog(state,args);
    }
    case Dialog.CUSTOM:
      return getCustomDialog(state,args);
    case Dialog.IMAGE_READ_FAILURE:
      return getImageReadFailureDialog(state,args)
    case Dialog.FILESYSTEM_NAME_COLLISION_ON_MOVE:
      return getFilesystemNameCollisionOnMoveDialog(state,args);
    case Dialog.FILESYSTEM_NAME_COLLISION_ON_SET:
      return getFilesystemNameCollisionOnSetDialog(state,args);
    case Dialog.LOGOUT_WITH_UNSAVED_DATA:
      return G.getLogoutMessage(state,args);
    case Dialog.ILLEGAL_CHARACTER_IN_CELL:
      return getIllegalCharacterDialog(state,args); 
    case Dialog.IMAGE_ARCHIVE_WARNING:
      return getImageArchiveWarningDialog(state,args);
    case Dialog.PRESIGNED_POST_GENERATION_FAILURE:
    case Dialog.SAVE_FAILED_ON_SERVER:
      return getSaveFailedOnServerDialog(state,args);
    case Dialog.NO_INTERNET_CONNECTION:
      return getNoInternetConnectionDialog(state,args);
    case Dialog.PLEASE_REPORT_BUG:
      return getPleaseReportBugDialog(state,args);
    case Dialog.UNEXPECTED_SERVER_ERROR:
      return getUnexpectedServerErrorDialog(state,args);
    default:
      throw Error("Dialog '"+dialogName+"', has not yet been assign a response function (args = " + JSON.stringify(args)+")");
  }

}

G.getAbsoluteFsPath = function(state,fsItem){
  let path = [];

  let currentFsItem = G.getRecord(state,fsItem);
  while( currentFsItem._id ){
    path.splice(0,0,currentFsItem._id);
    currentFsItem = G.getFilesystemParent(state,currentFsItem,{includeHiddenParents:true});
    if( currentFsItem && currentFsItem._id === undefined ){
      throw Error("Didn't expect to get an undefined _id: " + JSON.stringify(currentFsItem));
    }
  }
  return path;
  
}

G.isFilesystemDescendant = function(state,{parent,descendant}){
  let pRec = G.getRecord(state,parent);
  let dRec = G.getRecord(state,descendant);

  let dRecAbsoluteFsPath = G.getAbsoluteFsPath(state,{ record:dRec });

  let parentId = pRec._id;

  let isFilesystemDescendant = dRecAbsoluteFsPath.includes(parentId);
  return isFilesystemDescendant;

}

G.getDropTargetId = function(state,targetArgs){

  let {draggedId,dropTargetId} = targetArgs;

  if( !draggedId || !dropTargetId ){
    throw Error("Required all args, but received: " + JSON.stringify(targetArgs));
  }

 
  let draggedRecord = G.getRecord(state,{_id:draggedId});

  


  let targetRecord = G.getRecord(state,{_id:dropTargetId});

  let targetRecordType = targetRecord.itemType || targetRecord.type;
  if( targetRecordType === IMAGE_UPLOADS ){
    targetRecord = G.getFilesystemParent(state,{record:targetRecord},{includeHiddenParents:true});
  }
  targetRecordType = targetRecord.itemType || targetRecord.type;


  let descendantQuery;
  if( targetRecord.data && targetRecord.meta ){
    descendantQuery = { record:targetRecord }
  }else{
    descendantQuery = targetRecord;
  }

  let targetIsADescendantOfDragged = G.isFilesystemDescendant(state,{
    parent:{ record:draggedRecord }, descendant:descendantQuery
  })

  if( targetIsADescendantOfDragged ){
    return null;
  }

  let draggingOntoSelf = draggedRecord._id === targetRecord._id;
  let draggingOntoOwnParent = targetRecord._id === G.getFilesystemParent(state,{record:draggedRecord},{ includeHiddenParents:true })._id;

  if( draggingOntoOwnParent || draggingOntoSelf ){
    return null;
  }

  let dragRecordType = draggedRecord.type || draggedRecord.itemType;

  


  if( dragRecordType === IMAGE_SETS && targetRecordType === IMAGE_SETS ){
    if( draggedRecord.data.images.length > 1 ){
      //this time without imageSets
      return { 
        dialog:Dialog.DRAGGING_IMAGE_SETS_WITH_MULTIPLE_IMAGES_UNSUPPORTED
      }

          }else{
    }
  }

  if( targetRecordType === IMAGE_SETS && dragRecordType === DIRECTORIES ){

    let finalTargetRecord = G.getFilesystemParent(state,{ _id:targetRecord._id});
    return finalTargetRecord._id;

    return null;
  }



  return targetRecord._id;




}

G.getIdsOfCorruptImageUploads = function(state){

  let mediaProcessing = state.mediaProcessing;
  return Object.keys(mediaProcessing).filter(k => mediaProcessing[k].errors.find(er => er.type === "imageProcessingError"));

  /*

  let activeImageIds = G.getActiveItemIds(state,{type:IMAGE_UPLOADS});
  let activeImageData = activeImageIds.map(_id => G.getData(state,{type:IMAGE_UPLOADS,_id}));
  let imagesWithError = activeImageData.filter(x => x.error);
  let ids = imagesWithError.map(x => x._id);

  return ids;
  */
  

}

G.getMostRecentSyncId = function(state){

  let { sync } = state.requests;
  let syncRequests = Object.values(sync).sort((a,b) => {
    return b.updates[0].timestamp - a.updates[0].timestamp;
  })



  return (syncRequests[0]||{})._id;

}

G.getSaveStatus = function(state){

  let saveStatuses = [
    getUserConfigSaveStatus(state),
    getRecordsSaveStatus(state)
  ].flat();

  let mostRecentSyncId = G.getMostRecentSyncId(state);

  let request;
  let updates;
  let status;
  if( mostRecentSyncId ){
    request = state.requests.sync[mostRecentSyncId];
    updates = request.updates;
    status = updates.slice(-1)[0].status;
  }

  if( saveStatuses.includes(C.IDLE) ){
    return C.UNSAVED
  }else if( saveStatuses.includes(C.PENDING) ){
    if( status !== C.PENDING ){
      return C.UNSAVED;
    }
    return C.SAVING
  }else{
    return C.SAVED;
  }
}



G.getAllImageSpecificaions = function(state,args){
  let { pendingRecords } = args || {};

  let imageContainer = pendingRecords ? "pendingRecords" : "data";

  let images = Object.values(state[imageContainer][IMAGE_UPLOADS]);
  if( !images ){
    return [];
  }

  let imageSpecList = images.map(img => {

    let versionList = Object.keys(img.versions).map(version => {
      return { _id:img._id, version }
    })

    return versionList;
  }).flat();
  return imageSpecList;

}

G.getUnpersistedImageSpecifications = function(state){
  
  let unpersistedImages = G.getAllImageSpecificaions(state,{pendingRecords:true}).filter(spec => 
    !G.isImagePersisted(state,spec)
  );

  return unpersistedImages;

}

G.getUnprocessedImageIds = function(state){
  let ids = Object.keys(state.pendingRecords.imageUploads);
  return ids;
}

G.getUnpersistedMediaInfo = function(state){
  //all images that are around, but not persisted.
  //so we get all imageUploads and there versions
  //map images to version info

  let processingUploads = Object.values(state.pendingRecords.imageUploads);
  let uploadsWithUnpersistedVersion = processingUploads.filter(x => Object.values(x.versions).some(version => !version.remoteStorageResourceId));



}

G.getUnprocessedMediaInfo = function(state){
  

  let unpersistedImageIds = G.getUnprocessedImageIds(state);
  let info = unpersistedImageIds.map(imageId => {

    let image = G.getImage(state, {imageId, pendingRecords:true})

    let filename = image.filename;

    let status = G.getMediaPersistanceStatus(state,{imageId,version:convertedInfoContainerName});



    let obj = {
      _id:imageId,
      filename,
    }

    let errors = state.mediaProcessing[imageId].errors;

    if( errors ){
      obj.errors = errors;
    }

    return obj;


  })
  return info;

}

function formatUnsavedDataDestinedForThirdPartyStorage(state){

  //so just the image resources
  // and for this, we just need to check them to see if they are persisted or not!

  let unprocessedImageIds = G.getUnprocessedImageIds(state);

  if( unprocessedImageIds.length > 0 ){
    return {
      [IMAGE_UPLOADS]:unprocessedImageIds
    }
  }else{
    return {};
  }

}

function formatUnsavedDataDestinedForSciugoServer(syncObject){

  let unsavedIdsByRecordType = {};


  let result = {};

  let { records, userConfig } = syncObject.data;
  if( userConfig && Object.keys(userConfig).length > 0 ){
    result.userConfig = true;
  }


  const unsavedRecordBooleanResolution = {
    [DIRECTORIES]:'filesystem',
    [IMAGE_SETS]:'filesystem',
  }


  Object.entries(records||{}).forEach(([recordType,recData]) => {
    let idList = Object.keys(recData);
    if( idList.length > 0 ){
      if( recordType in unsavedRecordBooleanResolution ){
        let resolvedKey = unsavedRecordBooleanResolution[recordType];
        result[resolvedKey] = true;
      }else{
        unsavedIdsByRecordType[ recordType ] = idList;
      }
    }
  })


  return {
    ...result,
    ...unsavedIdsByRecordType
  }

}

function unifyFormattedUnsavedDataMessage(unsavedDataDestinedForThirdPartyStorage,unsavedDataDestinedForSciugoServer){


  let allKeys = [
    ...Object.keys(unsavedDataDestinedForThirdPartyStorage),
    ...Object.keys(unsavedDataDestinedForSciugoServer)
  ];


  let uniqueKeys = Array.from( new Set( allKeys ) );

  let combinedObject = Object.fromEntries(uniqueKeys.map(key => {
    let optionList = [unsavedDataDestinedForSciugoServer,unsavedDataDestinedForThirdPartyStorage]
    if( optionList.find(obj => {
      return Array.isArray(obj[key])
    }) ){

      /*
      try{
        let x = [...(unsavedDataDestinedForThirdPartyStorage[key]||[])]
      }catch(e){
      }*/

    let list = Array.from( 
      new Set( 
        [
          ...(unsavedDataDestinedForSciugoServer[key]||[]),
          ...(unsavedDataDestinedForThirdPartyStorage[key]||[])
        ]
      )
    )

    return [key, list]
  }else{
    //just return the first value that isn't undefined
    return [key, optionList.find(item => item[key])[key]]
  }
  }));


  return combinedObject;

}


G.getEvaluatedSyncObject = getEvaluatedSyncObject;

function formatUnsavedDataMessage(state){

  let evaluatedSyncObject = getEvaluatedSyncObject(state);

  let unsavedDataDestinedForThirdPartyStorage = formatUnsavedDataDestinedForThirdPartyStorage(state);
  let unsavedDataDestinedForSciugoServer = formatUnsavedDataDestinedForSciugoServer(evaluatedSyncObject);

  let unifiedFormattedUnsavedDataMessage = unifyFormattedUnsavedDataMessage(unsavedDataDestinedForThirdPartyStorage,unsavedDataDestinedForSciugoServer);



  return { unsavedItems:unifiedFormattedUnsavedDataMessage };


}

G.getCorruptImageUploadFilenames = function(state){
  let corruptIds = G.getIdsOfCorruptImageUploads(state);
  let filenames = corruptIds.map(_id => {

    let image = G.getImage(state,{_id});
    let filename = image.filename;
    return filename;

  })

  return filenames;
}

G.isDataUnsaved = function(state){

  let logoutMessageUnsavedDataArgs = formatUnsavedDataMessage(state);
  
  let isUnsaved = Object.keys(logoutMessageUnsavedDataArgs.unsavedItems).length > 0;
  return isUnsaved;
}

//const err = obj => //console.error(JSON.stringify(obj));

G.getLogoutMessage = function(state){

  let content = G.getLogoutMessageContent(state);


  /*
  ////console.error(JSON.stringify(content))
  ////console.error(JSON.stringify({requests:state.requests}));

  if( window.__testMeta && window.__testMeta.log ){
    ////console.error(JSON.stringify({ content }));
  }
  */

  let filesystemNamesByTypeEntries = Object.entries(content.unsavedItems).map(([itemType,itemIds]) => {
    if( itemIds.map ){
      let filesystemNames = itemIds.map(_id => G.getFilesystemName(state,{_id,itemType}));
      return [itemType,filesystemNames]
    }else{
      return;
    }
  }).filter(x => x);

  let filesystemNamesByType = Object.fromEntries(filesystemNamesByTypeEntries);

  let unsavedItemBulletsPoints = Object.keys(content.unsavedItems)


  let header = { type:'warning',text:"Logout with unsaved changes?" }




  let ul = unsavedItemBulletsPoints.map(point => {
    if( point in filesystemNamesByType && filesystemNamesByType[point] ){
      return HTML.li(
    [
      HTML.b(point),
      " ",
      filesystemNamesByType[point].join(', ')
    ]
  )
    }else{
      return HTML.li(HTML.b(point))
    }
    });

  let body = [
    HTML.p("The following are unsaved:"),
    ...ul,
    //HTML.p(JSON.stringify(filesystemNamesByType))
  ]

  let buttons = [
    {
      text:'Logout without Saving',
      variant:'light',
      actions:[
        {name:C.popMessage},
        {
        name:C.tryLogout,
        args:{ force:true }
      }]
    },
    {
      text:'Cancel',
      variant:'light',
      actions:[Dialog.DISMISS_ACTION]
    },
    /*
    {
      text:'Save',
      variant:'primary',
      actions:[
        { name:C.syncChanges,
          then:[{
            name:C.tryLogout
          }]
        },
      ]
    }
    */
  ]

  return { header, body, buttons };

  //throw Error("Logout message not yet implemented!");
}


G.getLogoutMessageContent = function(state){


  let logoutMessageUnsavedDataArgs = formatUnsavedDataMessage(state);

  if( Object.keys(logoutMessageUnsavedDataArgs.unsavedItems).length === 0 ){
    return undefined;
  }


  return logoutMessageUnsavedDataArgs;

}

G.isImagePersisted = function(state,imageSpec){

  imageSpec.imageId = imageSpec.imageId || imageSpec._id;

  

  //check if it has a remoteStorageResourceId

  let recordExists = G.doesRecordExistInCache(state,{type:IMAGE_UPLOADS,_id:imageSpec._id});

  if( !recordExists ){
    imageSpec.pendingRecords = true;
    //return false;
  }


  let remoteStorageResourceId = G.getRemoteStorageResourceId(state,imageSpec);
  return Boolean(remoteStorageResourceId);
  
}

G.getRemoteStorageSpecification = function(state,imagesToSync){
  return imagesToSync.map(imageSpec => {
    let remoteStorageResourceId = G.getRemoteStorageResourceId(state,imageSpec);

    return {
      ...imageSpec,
      remoteStorageResourceId
    }
  })
}

G.getAuthAttemptId = function(state){
  return state.loginInfo.authAttemptId;
}


G.isLoggedIn = function(state){
  let { loginInfo } = state;
  let { status, completed } = loginInfo;
  return status === 'loggedIn' && completed === true;
}

G.getAuthResponseMessage = function(state){

  if( state.loginInfo.status === "loggedOut" ){
    const messageMap = {
      unNotFound:'User does not exist.',
      incorrectPassword:'Invalid password.',
      noInternet:'You are not connected to the internet.',
      'Username already taken.':'Username already taken.',


    }

    let reasonCode = state.loginInfo.reason;
    let mappedMessage = messageMap[ reasonCode ];


    return mappedMessage || reasonCode;
  }


}

G.getPasswordResetResponseMessage = function(state){
  let { passwordResetInfo } = state;
  let { response } = passwordResetInfo;

  if( response === "failed" ){
    return passwordResetInfo.reason || "Request failed. Please try again.";
  }else if( response === "success" ){
    return "A reset code was sent to your email."
  }else if( response === "pending" ){
    return "Sending request..."
  }
}

G.getNewSelectedCellList = function(state,{currentlySelectedCells,newlySelectedCells,multiselect}){

  if( !multiselect ){
    return newlySelectedCells;
  }


  let curSelAsString = currentlySelectedCells.map(JSON.stringify);
  let newSelAsString = newlySelectedCells.map(JSON.stringify);

  let venn = getVenn(curSelAsString,newSelAsString);

  let toAdd = venn.rightNotInLeft;
  let toRemove = venn.inBoth;
  let toKeep = venn.leftNotInRight;

  let finalList = [
    toAdd,
    toKeep
  ].flat().map(JSON.parse);

  return finalList;

}

G.getFigurePixelDimensions = function(state,{ figurePanelId }){


}

G.getListDirectoryItem = function(state,locationArgs){
  let { dir, item } = locationArgs;
  let list = G.listDirectory(state,dir);
  let theItem = list.find(it => it._id === item._id);
  return theItem;
}







G.getClientDateString = function(_,dateArgNumber,args){

  



  const DAY_LENGTH = 86400000;
  //const YESTERDAY_BOUNDS = TODAY_BOUNDS * 2;


 
  
  let refTimeArg = dateArgNumber || Date.now();

  let refTimeNumber = Number(refTimeArg);
  //let refTime = new Date(refTimeNumber);



  let curTimeNumber = Number(Date.now());
  let curDateStr = (new Date(curTimeNumber)).toString();
  let curCalDayBeginStr = curDateStr.split(' ').slice(1,4).join(' ');

  let year = curCalDayBeginStr.split(' ').slice(-1)[0];


  let curDayBeginNumber = Number(new Date(curCalDayBeginStr));


  let refTimeDate = new Date(refTimeNumber);

  let rawDateStringPrefix = refTimeDate.toDateString().split(' ').slice(1);

  rawDateStringPrefix[1] += ',';
  let finalDateStringPrefix = rawDateStringPrefix.join(' ');


  if( refTimeNumber > curDayBeginNumber ){
    finalDateStringPrefix = null;//"Today";
  }else if( curDayBeginNumber - refTimeNumber < DAY_LENGTH ){
    finalDateStringPrefix = "Yesterday";
  }

  let rawTime = refTimeDate.toLocaleTimeString();
  let numberAMPMsplit = rawTime.split(' ');
  let ampm = ({AM:'AM',PM:'PM','a.m.':'AM','p.m.':'PM' })[numberAMPMsplit[1]];

  

  

  let timeInMinutes = numberAMPMsplit[0].split(':').slice(0,2).join(':');
  let time = timeInMinutes + (ampm? (' ' + ampm):'');



  let dateComponents = [];


  let finalDateString = [finalDateStringPrefix,time].filter(x => x).join(', ');



  return finalDateString


}

function isArrayEmpty(arr){
  return arr && arr.length === 0;
}

function isArrayEmptyOrUndef(arr){
  return !arr || isArrayEmpty(arr);
}

G.getFilesystemChildren = function(state,locationArgs){
  let record = G.getRecord(state,locationArgs);
  let { meta, data, type } = record;
  switch(type){
    case DIRECTORIES:
      return data.children;
    case FIGURE_PANELS:
    case IMAGE_UPLOADS:
      return [];
    case IMAGE_SETS:
      if( data.images.length > 1 ){
        return data.images.map(id => ({type:IMAGE_UPLOADS,_id:id}));
      }else{
        return [];
      }
    default:{
      throw Error("Filesystem children not implemented for type '"+type+"'");
    }
  }
}



G.getLastEditedDate = function(state,args){
  return G.getMeta(state,args).lastEditedDate;
}

G.getCreationDate = function(state,args){
  return G.getMeta(state,args).creationDate;
}



G.getFilesystemDirectorySpecByAnnotationId  = function(state, args){

  let rec = G.getFilesystemDirectoryByAnnotationId(state,args);

  let toReturn = {
    _id:rec._id,
    type:(rec.type || rec.itemType)
  }
  
  return toReturn;

}

G.getImageSetIdByAnnotationId = function(state,{_id}){

  let atn = G.getData(state,{type:ANNOTATIONS,_id});
  let { imageSetId } = atn;
  return imageSetId;

}

G.getImageSetByAnnotationId = function(state,args){

  let figureImageId = G.getImageSetFigureImageIdByAnnotationId(state,args);
  let imageSet = G.getImageSetByImageId(state, figureImageId) ;
  return imageSet;

  
}



G.getFilesystemDirectoryByAnnotationId = function(state,args){
  //let _id = args.annotationId || args._id;

  let imageSet = G.getImageSetByAnnotationId(state,args);

  let parent = G.getFilesystemParent(state,{_id:imageSet._id});
  let parentId = parent._id;

  let record = G.getRecord(state,{_id:parentId});

  return record;




}

G.getFilesystemDirectoryNameByAnnotationId = function(state,args){
  let { annotationId } = args;

  let dirRecord = G.getFilesystemDirectoryByAnnotationId(state,{annotationId});

  let fsName = G.getFilesystemName(state,{record:dirRecord});

  return fsName;
}

G.getFilesystemItemNotifications = function(state,locationArgs){
  let record = G.getRecord(state,locationArgs);
  if( record.type === DIRECTORIES ){
    let dirLs = G.listDirectory(state,{...locationArgs,show:{[NOTIFICATIONS]:1}})
    let notifications = dirLs.map(res => res.notifications).flat();
    let uniqueNotifications = Array.from(new Set(notifications));
    const notificationMap = {
      imageSetWithoutAnnotations:"imageSetsInFolderWithoutAnnotations"
    }
    let mappedNotifications = uniqueNotifications.map(not => notificationMap[not] || not);
    return mappedNotifications;
  }else if( record.type === IMAGE_SETS ){

    let { meta } = record;
    let childAnnotations = meta.requiredAsByRole.parentImageSet;
    if( isArrayEmptyOrUndef(childAnnotations)  ){
      return [IMAGE_SET_WITHOUT_ANNOTATIONS];
    }else{
      return [];
    }
  }else{
    return [];
  }
}


G.getImageUploadFilesystemParent = function(state,record){
  let imageSetId = record.data.imageSetId;
  let imageSet = G.getRecord(state,{type:IMAGE_SETS,_id:imageSetId});
  let imageSetData = imageSet.data;
  let imageCount = imageSetData.images.length;


  if( imageCount > 1 ){
    return {_id:imageSetId, itemType:IMAGE_SETS };
  }else{
    let { meta } = imageSet;
    return { _id:meta[FILESYSTEM_PARENT_DIRECTORY], itemType:DIRECTORIES };

  }



}

G.getFilesystemParent = function(state,itemSpec,getArgs){

  let { record } = itemSpec;
  record = record || G.getRecord(state,itemSpec);

  let hiddenParentsRequested = getArgs && getArgs.includeHiddenParents;
  if(record.type === IMAGE_UPLOADS && !hiddenParentsRequested){
    return G.getImageUploadFilesystemParent(state,record);
  }else{

    if( record.type === IMAGE_UPLOADS ){
      return {
        itemType:IMAGE_SETS,
        _id:record.data.imageSetId
      }
    }

    return {
      itemType:DIRECTORIES,
      _id:record.meta[FILESYSTEM_PARENT_DIRECTORY]
    };
  }
  

  
  
}


G.getDependants = function(state,args){

  let meta = G.getMeta(state,args);
  let { requiredAsByRole } = meta;


  let fixedEntries = Object.entries(requiredAsByRole).map(([role,items]) => ([role,items.filter(_id => !G.isArchived(state,{_id}))]))

  let activeRequiredAsByRole = Object.fromEntries(fixedEntries);

  return activeRequiredAsByRole;



}

G.hasDependants = function(state,{_id,itemType}){
  let dependants = G.getDependants(state,{_id,itemType});
    let numDependants= Object.keys(dependants).length;
  let hasDependants = numDependants > 0;
  return hasDependants;
}


function deleteActionDialog(state,actionArgs){
  let { _id } = actionArgs;
  let meta = G.getMeta(state,actionArgs);
  let { requiredAsByRole } = meta;

  let dialog = {};
  if( Object.keys(requiredAsByRole).length > 0 ){
    dialog.warnings = {requiredAsByRole};
  }
  
  return dialog;

}


G.getActionDialog = function(state,{ action, actionArgs }){

  let dialogProcessingMap = {
    delete:(state,actionArgs) => deleteActionDialog(state,actionArgs)
  }

  if( !(action in dialogProcessingMap ) ){
    throw Error(action + " is not registered for any pre-dialog.");
  }

  let dialog =
    dialogProcessingMap[action](state,actionArgs);

  return dialog;

}

G.listDirectoryNames = function(state,lsArgs){
  let dirList = G.listDirectory(state,{
    ...lsArgs,
    show:{ [FILESYSTEM_NAME]: 1 }
  })
  let names = dirList.map(child => child[FILESYSTEM_NAME]);
  return names;
}

function extractPrefixNumberFromName(name,prefix){

  let prefixFoundAtBeginning = name.indexOf(prefix) === 0;
  let prefixLength = prefix.length;
  let remainder = name.slice(prefixLength)

  let afterPrefixIsSpace = remainder[0] === ' ';
  let numberAfterPrefix = name.slice(prefixLength+1);
  let afterPrefixSpaceIsNumber = !isNaN(numberAfterPrefix);

  let shouldCountThisNameWithPrefixesUsed = [
    prefixFoundAtBeginning,
    afterPrefixIsSpace,
    afterPrefixSpaceIsNumber
  ].every(x=>x);

  if( shouldCountThisNameWithPrefixesUsed ){
    return numberAfterPrefix;
  }

}

function getFilesystemNameNumberAssocatedWithPrefix(name,prefix){

  if( name === prefix ){
    return 0;
  }
  return Number(extractPrefixNumberFromName(name,prefix));

}

G.getNumbersAssociatedWithFilenamesHavingPrefixInDirectory = function(state,lsArgs,prefix){
  let nameList = G.listDirectoryNames(state,lsArgs);
  let associatedNumbers = nameList.map(name => getFilesystemNameNumberAssocatedWithPrefix(name,prefix));
  let associatedNumbersWithoutUndefined = associatedNumbers.filter(x => !isNaN(x));
  return associatedNumbersWithoutUndefined;
}

function getLowestNumberMissingFromSequenceStartingAtZero(sequence){

  let asNumbers = Object.keys(sequence).map(Number);
  let lowestNumberNotInUsedNumbers;
  if( asNumbers.length === 0 ){
    lowestNumberNotInUsedNumbers = 0;
  }else{

    lowestNumberNotInUsedNumbers = Math.max(...asNumbers);
  }

  if( lowestNumberNotInUsedNumbers + 1 === asNumbers.length ){
    return lowestNumberNotInUsedNumbers + 1;
  }
  

  for(let ii = 0; ii < asNumbers.length; ii++){
    if(ii !== asNumbers[ii]){
      lowestNumberNotInUsedNumbers = ii;
      break;
    }
  }

  return lowestNumberNotInUsedNumbers;

}

G.getImageUploadRawFilename = function(state,{_id}){
  let imageUpload = G.getData(state,{itemType:IMAGE_UPLOADS,_id});
  let filename = imageUpload.filename;
  return filename;
}

function getFilenameFromPrefixAndNumber(prefix,number){

  if( number === 0 ){
    return prefix;
  }

  let nextFilename = prefix + ' ' + number;
  return nextFilename;

}

G.getNextAvailableFilesystemNameWithPrefixInDirectory = function(state,lsArgs,prefix){

  let numbersWithPrefixInDir = G.getNumbersAssociatedWithFilenamesHavingPrefixInDirectory(state,lsArgs,prefix);
  let lowestNumberMissing = getLowestNumberMissingFromSequenceStartingAtZero(numbersWithPrefixInDir);
  let nextAvailableName = getFilenameFromPrefixAndNumber(prefix,lowestNumberMissing);

  return nextAvailableName;

}


G.getMediaFetchStatus = function(state,imageSpec){

  let { imageId, version } = imageSpec;


  let imageMediaContainer = state.media[imageId];
  if( imageMediaContainer ){
    let versionContainer = imageMediaContainer[version];
    if( !versionContainer ){
      return "none";
    }
    if( versionContainer.localBlobUrl ){
      return "success";
    }else if( versionContainer.pending ){
      return "pending";
    }else if( versionContainer.corrupt ){
      return "corrupt";
    }else if( versionContainer.status === "failed" ){
      return "failed";
    }

  }else{
    return "none";
  }



}





G.getSyncableUserConfig = function(state){
  let userConfigNeedsSync = 
    state.syncStatus.userConfig.syncKey;

  if( userConfigNeedsSync ){
    return state.userConfig;
  }else{
    return undefined;
  }
}

G.findItemIdWithNameInDir = function(state,{name,directoryId}){

  let ls = G.listDirectory(state,{
    _id:directoryId,
    //itemType:DIRECTORIES,
    show:{[FILESYSTEM_NAME]:1}
  })

  let itemWithTargetedName = ls.find(item => item[FILESYSTEM_NAME] === name );

  let _id  = itemWithTargetedName && itemWithTargetedName._id;

  return _id;

}

function forceIntoNumber(multiple){

  return (typeof(multiple) === typeof(1) ? multiple : 1)
}

function compare(a,b,multiple){
  let types = [a,b].map(x => typeof(x));
  if( (new Set(types)).size !== 1 ){
    throw Error("Can't compare objects of different types: (a = " + JSON.stringify(a) + ", b = " + JSON.stringify(b));
  }

  //ensure multiple is a number

  let numberMultiple = forceIntoNumber(multiple); 

  let isNumber = types[0] === typeof(1);
  let isString = types[0] === typeof('');

  let cmpResult;
  if( isNumber ){
    cmpResult = a-b;
  }else if( isString ){
    cmpResult = a.localeCompare(b)
  }else{
    throw Error("Cannot compare non-[integer|string] objects: " + JSON.stringify({a,b}));
  }

  return cmpResult * numberMultiple;

}

function throwNoProperty(obj,prop){
  throw Error("Could not find property '"+prop+"' in " + JSON.stringify(obj));
}

G.getDefaultNewFilesystemName = function(state,{itemType,filesystemParentDirectoryId}){

  let prefix = G.getDefaultNewFilesystemNamePrefix(state,{itemType,filesystemParentDirectoryId}); 
  let newFilesystemName = G.getNextFilesystemNameWithPrefix(state,{filesystemParentDirectoryId,prefix})

  return newFilesystemName;

  
}

G.getDefaultNewFilesystemNamePrefix = function(state,{itemType,filesystemParentDirectoryId}){

  const defaultNameMap = { 
    [DIRECTORIES]:"folder",
    [FIGURE_PANELS]:"figure panel",
    [IMAGE_SETS]:"image set",
    [IMAGE_UPLOADS]:"image"
  }

  let resolvedItemType = getResolvedItemTypeName(itemType);

  if( !(resolvedItemType in defaultNameMap) ){

    throw Error("No default filesystem name registered for itemType: '" + itemType+"'.");
  }


  //we know resolvedItemTypeName is in defaultNameMap
  //from check above

  let defaultName;

  let isTemplateFigurePanel = itemType === FIGURE_PANELS && G.getDatatypeSpecificDirectoryRoots(state).figurePanelTemplates === filesystemParentDirectoryId;

  if( isTemplateFigurePanel ){
    defaultName = "template";
  }else{
    defaultName = defaultNameMap[resolvedItemType]
  }

  let prefix = 'Untitled ' + defaultName;

  return prefix;


}



G.getNextFilesystemNameWithPrefix = function(state,{filesystemParentDirectoryId,prefix}){


  let directoryChildren = G.listDirectory(state,{
    _id:filesystemParentDirectoryId,
    itemType:DIRECTORIES, 
    show:{
      [FILESYSTEM_NAME]:1
    }
  });



  let numbersAlreadyThere = [];
  
  directoryChildren.forEach(item => {
    let name = item[FILESYSTEM_NAME]
    let splitUp = name.split(prefix);
    if( splitUp.length === 2 && splitUp[0] === '' ){
      if(splitUp[1] === ''){
        numbersAlreadyThere[0] = true;
      }else{
        let noSpacesAfterFirstSpace = splitUp[1][0] === ' ' && splitUp[1].slice(1).indexOf(' ') === -1;
        if(noSpacesAfterFirstSpace){
          let numberToMark = Number(splitUp[1])
          numbersAlreadyThere[numberToMark] = true;
        }

      }
    }
  })

  let untitledNumber = 0;
  while(untitledNumber in numbersAlreadyThere){
    untitledNumber++;
  }

  let suffix = (
    untitledNumber === 0 ? '' : ' ' + untitledNumber
  )

  let finalDefaultName = prefix + suffix;

  return finalDefaultName;


}

G.getFilesystemInfo = function(state,args){

  
  if( !args.itemType ){
    throw Error("This function requires 'itemType' in args.");
  }

  let dataFound = G.getData(state,args);

  let argItemTypeIsDirectory = args.itemType === DIRECTORIES;
  
  let isDirectory = argItemTypeIsDirectory && dataFound;
  let isEmpty = isDirectory && dataFound.children.length === 0;

  return { 
    isDirectory,
    isEmpty
  }

}

function getFilesystemDeleteOperation(state,args){

  let filesystemInfo = G.getFilesystemInfo( state, args );
  let { isDirectory, isEmpty } = filesystemInfo;

  let isAnEmptyDirectory = isDirectory && isEmpty;

  if( isAnEmptyDirectory ){ 
    return { operation:'delete', allowed:true, confirmation: null };
  }else if( isDirectory ){
    return { operation: 'delete', allowed:false };
  }else{
    let confirmation = (
      "Are you sure you want to delete '" + 
      G.getFilesystemName(state, args) + 
      "'?"
    );

    return {
      operation:'delete',
      allowed:true,
      confirmation
    }
  }


}

G.getFilesystemRightClickMenu = function(state,args){
  let { itemType, _id, rightClickMenuItem } = args;

  let menu = [];
  menu.push(getFilesystemDeleteOperation(state,args));

  if( rightClickMenuItem ){
    return menu.find(menuItem => menuItem.operation === rightClickMenuItem);
  }
  return menu;

}


G.getParentDirectoryId = function(state,{_id,itemType}){
  let filesystemParentDirectory = G.getFilesystemParent(state,{_id,itemType});

  let parentDirectoryId = filesystemParentDirectory._id;

  return parentDirectoryId;
}

G.isFilesystemItemExpandable = function(state,location){
  let record = G.getRecord(state,location);
  let { itemType, data } = record;
  if( itemType === IMAGE_SETS ){
    return data.images.length > 1;
  }else if( itemType === DIRECTORIES ){
    return data.children.length > 0;
  }else{
    return false;
  }
}

const FILESYSTEM_FUNCTIONS = {
  [FILESYSTEM_NAME]:() => G.getFilesystemName,

  [FILESYSTEM_PARENT_DIRECTORY]:() => G.getFilesystemParent,
  [IS_FILESYSTEM_ITEM_EXPANDABLE]:() => G.isFilesystemItemExpandable,

  [LAST_EDITED_DATE]:() => ((state,{record}) => record.meta[LAST_EDITED_DATE]),

  [CREATION_DATE]:() => ((state,{record}) => record.meta[CREATION_DATE]),

  [NOTIFICATIONS]:() => G.getFilesystemItemNotifications,
  [CHILDREN]:(args) => G.getFsChildren
}



function expandChildrenLs(state,itemLocations,show){
 
  if( !show || Object.keys(show) === 0 ){
    return itemLocations;
  }else{

    return itemLocations.map(location => {
      let itemData = {...location};
      let record = G.getRecord(state,location);
      
      
      Object.keys(show).forEach(key => {

        if( !(key in FILESYSTEM_FUNCTIONS) ){
          throw Error("No fsFunc with key '"+key+"'")
        }

        let fsFunc = FILESYSTEM_FUNCTIONS[key]();
        if( !fsFunc ){
          throw Error("Function at key '"+key+"' is " + fsFunc);
        }

        
        itemData[key] = fsFunc(state,{record});
          
      })


      return itemData;

    })
  }

}

function resolveRawFilesystemDataIntoDisplayData(raw){

  const typeMap = { 
    [CREATION_DATE]:"date",
    [LAST_EDITED_DATE]:"date",
  }

  const typeResolver = {
    date:(val => G.getClientDateString(null,val)),
  }

  let displayObjEntries = Object.entries(raw).map(([key,val]) => {
    if( key in typeMap ){

      let type = typeMap[key];
      let resolvedVal = typeResolver[type](val);

      return [key,resolvedVal];
      //throw Error("I don't know the function to use to format the date properly!");

    }else{
      return [key,val];
    }
  })

  let displayObject = Object.fromEntries(displayObjEntries);

  return displayObject;

}



G.getFsChildren = function(state,args){
  let {showArchived,...location} = args;

  let record = G.getRecord(state,location);
  let children;
  if( record.type === IMAGE_SETS ){
    children = record.data.images.map(_id => ({_id,type:IMAGE_UPLOADS}));
  }else if( record.type === DIRECTORIES ){
    children = record.data.children;
  }else{
    children = [];
    //throw Error("Fs children not registered for type '"+record.type+"'.");
  }

  let filteredChildren = children.filter(child => {
    if( showArchived ){
      return true;
    }else{
      let shouldShow = !G.isArchived(state,child) 
      return shouldShow
    }
  });

  return filteredChildren;


}


G.listDirectoryRecursively = function(state,args){
  let dirRecord = G.getRecord(state,args);
  let fsChildren = G.getFsChildren(state,{record:dirRecord});
  return {
    ...dirRecord,
    children:fsChildren.map(childArgs => G.listDirectoryRecursively(state,childArgs))
  }
  
}

G.listDirectory = function(state,args){

  let { _id, topLevelDirectory, show, sortBy, descending, showArchived, record } = args;

  if( !_id && ! topLevelDirectory && !record ){
    throw Error("Error trying to fetch directory without any of [_id,record,topLevelDirecotry]. Received:" + JSON.stringify(args));
  }

  let showAllRequested = false;

  if( show && show.all ){
    showAllRequested = true;
    delete show.all;
    Object.keys(FILESYSTEM_FUNCTIONS).forEach(key => {
      if( key !== FILESYSTEM_PARENT_DIRECTORY ){
        show[key] = 1
      }
    })
  }

  sortBy = sortBy || FILESYSTEM_NAME;


  let dirRecord = G.getRecord(state,args);


  if( !_id && !topLevelDirectory && !record ){
    throw Error("\nYou specify which directory you want info on! Neither '_id' or 'topLevelDirectory' were provided.\n");
  }
  


  let { data, meta, type } = dirRecord;

 
  
  let fsChildren = G.getFsChildren(state,{record:dirRecord,showArchived});


  

  

  let showNeededForSorting = {...show, [sortBy]:1 };


  let expandedChildren = expandChildrenLs(state,
    fsChildren,
    showNeededForSorting
  );


  let listOfMapsToRawAndResolvedData = expandedChildren.map(raw => ({
    raw,
    displayResolved:resolveRawFilesystemDataIntoDisplayData(raw)
  }));

  const keysToSortOnDisplayData = []
  

  let dataToSortOn = keysToSortOnDisplayData.includes(sortBy) ? "displayResolved" : "raw";

  //try{
    listOfMapsToRawAndResolvedData.sort((a,b) => { 
    let aData = a[dataToSortOn][sortBy];
    if(!aData){
      throwNoProperty(a[dataToSortOn],sortBy);
    }
    let bData = b[dataToSortOn][sortBy];
      return compare(aData,bData, (descending?-1:1));
  }) 
  /*}catch{
    throw Error("Error in sorting method!");
  }*/
  let displayData = listOfMapsToRawAndResolvedData.map(item => item.displayResolved);

  let cleanedUpDataAccordingToOriginalShowArg = getCleanedUpDataAccordingToOriginalShowArg({ displayData, show, showNeededForSorting, sortBy });

  if( cleanedUpDataAccordingToOriginalShowArg.find(x => x._id === "logged-out-figurePanels-root-directory")){
  }

  return cleanedUpDataAccordingToOriginalShowArg;

}

function getCleanedUpDataAccordingToOriginalShowArg({displayData, show, showNeededForSorting, sortBy}){

  if( sortBy && !(sortBy in (show||{})) ){
    return displayData.map(data => {
      let fixed = { ...data };
      delete fixed[sortBy];

      return fixed;
    })

  }else{
    return displayData;
  }

}



G.getItemTypesWithNonEmptyRootDirectories = function(state){

  let datatypeSpecificDirectoryRoots = G.getDatatypeSpecificDirectoryRoots(state);

  let rootDirectoriesToRepackage = Object.keys(datatypeSpecificDirectoryRoots).filter(type => {
    let rootId = datatypeSpecificDirectoryRoots[type]
    let rootChildCount = G.getContainerChildCount(state,{itemType:DIRECTORIES,_id:rootId})
    return rootChildCount > 0;
  })

  return rootDirectoriesToRepackage;

}

G.getDirectoryChildren = function(state,{_id}){
  let data = G.getData(state,{itemType:DIRECTORIES,_id});//.children;
  let { children } = data;
  return children;
}

G.getContainerChildCount = function(state,locationArgs){
  
  if( locationArgs.itemType !== DIRECTORIES ){
    throw Error("I don't know how to count children on any container other than directories.");
  }
  let children = G.getDirectoryChildren(state,locationArgs);
  let count = children.length;
  return count;
}

G.isDataContainer = function(state,type){
  return !!state.data[type];
}

function isGridValueACrop(group){
  return group.value.valueType === 'crop';
}

G.getUserSetupInfo = function(state){
  return state.userSetup;
}

G.getDatatypeSpecificDirectoryRoots = function(state){
  return state.userConfig.userRootIds.datatypeSpecificDirectoryRoots;
}


G.getTopLevelDirectoryId = function(state,{type,itemType}){

  let resolvedTypeVarName = type || itemType;

  let resolvedType;
  if( resolvedTypeVarName !== "figurePanelTemplates" ){
    resolvedType = getResolvedItemTypeName(resolvedTypeVarName);
  }

  let directoryRootLabel = ( resolvedType || resolvedTypeVarName );
  return G.getDatatypeSpecificDirectoryRoots(state)[directoryRootLabel];
}

G.getCellWidth = function(state,{figurePanelId,cell}){

  let mergedCells = G.getCellsMergedWith(state,{position:cell,figurePanelId});
  mergedCells.push(cell);

  let colSpan = (new Set( mergedCells.map(x => x[1]) ));

  let figurePanel = G.getFigurePanel(state,{figurePanelId});
  let { columnWidths } = figurePanel;

  let totalWidth = Array.from(colSpan).reduce((sum,colIndex) => sum+columnWidths[colIndex],0)


  return totalWidth;

}

G.getCellGroupIdsThatAreCropsInColumn = function(state,{figurePanelId, columnIndex}){
  let figure = G.getFigurePanel(state,{figurePanelId});
  let { grid, cellGroups } = figure;

  if( !( grid[0] && grid[0][columnIndex] ) ){
    return [];
  }

  let idsAtColumnIndex = grid.map(row => row[columnIndex]);
  let cropIds = idsAtColumnIndex.filter(id => {
    let group = cellGroups[id];
    if(!group){
      debugger;
    }
    
    let isCrop = isGridValueACrop(group);
    return isCrop;
  });

  return cropIds;

}


function intersect(s1, s2){
  return Array.from(s1).filter(ele => s2.has(ele));
}

G.getCellResizeOptions = function(state,{figurePanelId, cell}){

  let inImageSpan = G.isCellInImageSpan(state,{figurePanelId,cell});

  if( inImageSpan ){

    let gridLayoutObject = G.getGridLayoutObject(state,{figurePanelId,cell});
    let { x, w } = gridLayoutObject;


    let groupColumnStartIndex = x;
    let nextGroupColumnIndex = x + w;


    let cropCellGroupIds = G.getCellGroupIdsThatAreCropsInColumn(state,{figurePanelId, columnIndex:x });

    let prevColumnCropGroupIds = G.getCellGroupIdsThatAreCropsInColumn(state,{figurePanelId, columnIndex:groupColumnStartIndex-1 });

    let nextColumnCropGroupIds = G.getCellGroupIdsThatAreCropsInColumn(state,{figurePanelId, columnIndex:nextGroupColumnIndex });


    let thisColCropIdSet = new Set(cropCellGroupIds);
    let prevColCropIdSet = new Set(prevColumnCropGroupIds);
    let nextColCropIdSet = new Set(nextColumnCropGroupIds);
    

    let numCropsSpanningToPrevColumn = intersect(thisColCropIdSet,prevColCropIdSet);

    let numCropsSpaningToNextColumn = intersect(thisColCropIdSet,nextColCropIdSet);

    const insideIfEmptyElseBoundary = list => (
      list.length === 0 ? "inside" : "boundary"
    );

    if( cell[0] == 4 && cell[1] == 2 ){

    }


    return {
      left: insideIfEmptyElseBoundary(numCropsSpanningToPrevColumn),
      right: insideIfEmptyElseBoundary(numCropsSpaningToNextColumn)
    }


  }else{
    return { left:"inside", right:"inside" };
  }



}



G.getFigurePanelGridCoordByTravelingDirectionFromCell = function(state,{cell,direction,rowArrangedGridData,figurePanelId,rows,cols}){

  if( state ){
    let panel = G.getFigurePanel(state,{figurePanelId});
    let { grid } = panel;
    rows = grid.length;
    cols = grid[0].length;
    rowArrangedGridData = G.getCompleteGridLayoutData(state,{figurePanelId});

  }

  let [dy,dx] = direction;

  let [refRow,refCol] = G.getGridItemIndexFromLocation(null,{cell,rowArrangedGridData});

  let curGridItem = rowArrangedGridData[refRow][refCol];
  let curX = curGridItem.x;
  let curY = curGridItem.y;

  let minPossibleRowIndex = rows - 1;

  let nextRowFollowingDirection = Math.max(curY + dy * ( dy > 0 ? curGridItem.h : 1),0)


  let nextSelectedCellIndex = [
    Math.min(nextRowFollowingDirection,rows-1),
    Math.min(Math.max(curX + dx * ( dx > 0 ? curGridItem.w : 1),0),cols-1)
  ]

  if( isNaN(nextSelectedCellIndex[0]) ){

  }

  let nextGridItemIndex = G.getGridItemIndexFromLocation(null,{cell:nextSelectedCellIndex,rowArrangedGridData});


  let nextGridItem = rowArrangedGridData[nextGridItemIndex[0]][nextGridItemIndex[1]];

  let newSelectedPosition = [ nextGridItem.y, nextGridItem.x ];


  return newSelectedPosition;

}

G.getImageSpansStartingAtColumnAndMovingInDireciton = function(state,{figurePanelId, gridColumn, direction}){


  if( !isNaN(direction) ){
    
    if( direction > 0 ){ direction = 1; }
    else if( direction < 0 ){ direction = -1; }

  }else if( direction === 'left' ){ direction = - 1; }
  else if( direction === 'right' ){ direction = 1; }
  else{ throw Error("Illegal direction provided, '" + direction + "'") };


  let figure = G.getFigurePanel(state,{figurePanelId});

  




}

G.getPanelCropCellSpans = function(state,{figurePanelId}){

  let panel = G.getFigurePanel(state,figurePanelId);
  let { cellGroups } = panel;
  let groupIdsOfCrops = Object.entries(cellGroups).filter(([id,val]) => {
    return val.value.valueType === 'crop'
  }).map(x => x[0]);

  let locationsOfCrops = G.findCellLocations(state,{
    ids:groupIdsOfCrops,
    figurePanelId
  });

  let imageSpanGridLayoutObjects = Object.values(locationsOfCrops).map(location => G.getGridLayoutObject(state,{figurePanelId, cell:location}));






  let largestImageSpanStartingAtEachColumn = Array(gridWidth).fill(null).map(0);
  
  imageSpanGridLayoutObjects.forEach(({x,w}) => {
    if( largestImageSpanStartingAtEachColumn[x] < w ){
      largestImageSpanStartingAtEachColumn[x] = w;
    }
  })

  let gridWidth = (panel.grid[0]||[]).length;

  let longestSpanByLeftmostIndex = {};
  let longestSpanByRightmostIndex = {};

}

G.isCellInImageSpan = function(state,{figurePanelId,cell}){
  let figure = G.getFigurePanel(state,{figurePanelId});
  if( !figure ){

  }
  let { grid, cellGroups } = figure;


  return grid.some(row => {
    let cellGroupId = row[cell[1]]
    let groupValue = cellGroups[ cellGroupId ];
    return groupValue.value.valueType === 'crop';
  })


}

G.getCellCropSpanInfo = function(state,{figurePanelId,cell}){

  let cropRangeList = G.getPanelCropCellSpans(state,{figurePanelId});

  return cropRangeList;



}

G.getImageSpansTargetedByResizeArgs = function(state,args){


  let {figurePanelId,cell,resizeFrom,cellSide} = args;


  //let cropRangeList = G.getCellCropSpanInfo(state,{figurePanelId, cell});

  let figure = G.getFigurePanel(state,{figurePanelId});
  let { grid } = figure;

  let { x, w } = G.getGridLayoutObject(state,args);

  let imageSpanSearchMap = {
    boundary:{
        //left include this cell in right spanning search
      left:[ 
        { x:cell[1], dir:1 }, 
        { x:cell[1]-1, dir:-1 }
      ],
       //right of cell includes everything past it and (it and what's before it)
      right:[ 
        { x:(cell[1]+w), dir:1 }, //we include the whole width because we're going OUTSIDE the merged width
        //otherwise, we'd have taken off 1.
        
        { x:(cell[1]+w-1), dir:-1, } 
      ]
    },
    inside:{
      left:[{ x:cell[1], dir:1 }],
      right:[{ x:cell[1]+w-1, dir:-1 }]
    }
  }

  let travelSpecifications = imageSpanSearchMap[resizeFrom][cellSide];

  
  
  let imageSpans = travelSpecifications.map(spec => {

    let xToTravelFrom = spec.x;
    let directionToTravelIn = spec.dir;
    let imageSpanAlreadyFound = false;
    
    let largestImageSpan = -1;
    let largestNonImageSpan = -1;

    const cellIsPartOfACrop = groupId => ( figure.cellGroups[ groupId ].value.valueType === 'crop' )

    const spanStartsHere = (row, col,dir) => {
      let previousGroup = row[col-dir];
      return (previousGroup === undefined) || previousGroup !== (row[col])
    }

    grid.forEach(row => {

      let iiCol = xToTravelFrom;

      if( !spanStartsHere(row, xToTravelFrom, directionToTravelIn) ){
        return;
      }



      let startingGroupId = row[ xToTravelFrom ];
      let partOfImageSpan = cellIsPartOfACrop(startingGroupId);

      if( imageSpanAlreadyFound && !partOfImageSpan ){
        return;
      }

      //reasons to stop scanning:
      //1. different cell
      //2. end of grid

      const stillLookingAtSameCellGroup = iiCol => row[iiCol] && row[iiCol] === startingGroupId;

      while(stillLookingAtSameCellGroup(iiCol)){
        iiCol += directionToTravelIn
      }
        
      let spanLength = Math.abs( xToTravelFrom - iiCol );
      if( partOfImageSpan ){
        if( spanLength > largestImageSpan ){
          largestImageSpan = spanLength;
        }
      }else if( spanLength > largestNonImageSpan ){
        largestNonImageSpan = spanLength;
      }
    })


    let spanToUse;
    let imageSpan;
    if( largestImageSpan !== -1 ){
      spanToUse = largestImageSpan;
      imageSpan = true;
    }else{
      spanToUse = largestNonImageSpan;
      imageSpan = false;
    }

    let rangeBoundaries = [ xToTravelFrom, xToTravelFrom + (directionToTravelIn * spanToUse ) ];

    let startedOnRightSoNeedToAddOne = rangeBoundaries[0] > rangeBoundaries[1];
    //need to add one because if we start on right as x=4 and x=4 is included in the span,
    //then the rightmost item in the range item should be 5, because the range is UNTIL (not including) 5.
    if( startedOnRightSoNeedToAddOne ){
      rangeBoundaries = rangeBoundaries.map(x => x+1);
    }

    rangeBoundaries.sort((a,b) => a - b);

    return {span:rangeBoundaries, imageSpan};

  })

  if( cell[0] == 4 && cell[1] == 1 && resizeFrom == 'inside' && cellSide == 'right' ){

  }

  
  
 
  if( resizeFrom === 'boundary' || imageSpans.some(span => span.imageSpan) ){

    let sortedImageSpans = imageSpans.map(s => s.span).sort((a,b) => {
      return a[0] - b[0];
    })

    return sortedImageSpans;

  }

  return [];

}

G.getCellHorizontalMergeRange = function(state,{figurePanelId,cell}){
  let {grid} = G.getFigurePanel(state,{figurePanelId});

  let rangeStart;
  let rangeEnd;

  let [row,col] = cell;

  let gridRow = grid[row];
  let targetCellGroupId = grid[row][col];



  for(let iiCol = 0; iiCol < gridRow.length && !rangeEnd; iiCol++){
    let curValue = gridRow[iiCol];
    let nextValue = gridRow[iiCol+1];

    if( !rangeStart && curValue === targetCellGroupId ){
      rangeStart = iiCol;
    }

    let nextValueIsNotCurValue = (
      curValue === targetCellGroupId 
      && 
      nextValue !== targetCellGroupId
    )
    
    if( rangeStart !== undefined && nextValueIsNotCurValue  ){
      rangeEnd = iiCol+1;
    }
    
  }

  rangeEnd = rangeEnd || gridRow.length;

  return [ rangeStart, rangeEnd ];



}


G.getGridCellResizeFromOptions = function(state,{figurePanelId, cell }){



}

G.getCellResizeInfo = function(state,args){
  let { figurePanelId, cell, resizeFrom } = args;

  // init return variables
  
  let impactedRows = [];
  let conservedWidthRange = null;
  let conservedWidthRows = [];
  // end init return variables

  let impactedRanges = G.getImageSpansTargetedByResizeArgs(state,args);
  let noImageSpansThisCell = impactedRanges.length === 0;
  
  if( noImageSpansThisCell ){
    let rangeSpanedByMerge = G.getCellHorizontalMergeRange(state,{figurePanelId,cell});
    impactedRanges.push(rangeSpanedByMerge);
  }else if( resizeFrom === 'boundary' ){
    conservedWidthRange = [impactedRanges[0][0],impactedRanges[impactedRanges.length-1][1]];
  }

  return { impactedRanges, conservedWidthRange, } //impactedRows, conservedWidthRows } ;




  //we want to know whether it's an image border
  //and the ranges associated with that image

  



}

G.getPostInfo = function(state,imageSpec){
  let { _id, imageId, version } = imageSpec || {};
  _id = _id || imageId;
  let container = state.media[_id];
  if( container ){
    let versionObj = container[version];
    if( versionObj ){
      return versionObj.postInfo;
    }
  }
}

G.getHistoryInfoSummary = function(state){
  let { historyInfo } = state;
  let { activeHistory, index } = historyInfo;
  return { index, length: activeHistory.length } 
}

G.getRemoteStorageResourceId = function(state,{_id, imageId, version, pendingRecords}){
  imageId = imageId || _id;

  let image = G.getImage(state,{imageId,pendingRecords});


  let versionContainer = image.versions[version];

  let resourceId;

  if( versionContainer ){
    resourceId = versionContainer.remoteStorageResourceId;
  }

  return resourceId;

}

G.getCachedImageStorageLocations = function(state){
  let images = Object.entries(state.data.imageUploads);


  let storageLocations = images.map( ([imageId,img]) => {
    let { versions } = img;

    return Object.entries(versions).map(([version,versionInfo]) => {
      return ({
        imageId,
        version,
        storageLocation:versionInfo.storageLocation
      })
    })
  }).flat();

  return storageLocations;

}

G.getStorageLocationsOfMissingCachedImages = function(state,args){

  let { imageSpecList } = args || {};

  let cachedImageStorageLocations = G.getCachedImageStorageLocations(state);


  let imagesWithoutLoadedResource = cachedImageStorageLocations.filter(imageInfo => {
    let present = G.isImagePresent(state,{...imageInfo});

    return !present;
  })

  return imagesWithoutLoadedResource;

}

G.getImage = function(state,{imageId,_id,pendingRecords}){

  if( pendingRecords ){
    return state.pendingRecords[IMAGE_UPLOADS][imageId||_id];
  }


  return G.getData(state,{itemType:IMAGE_UPLOADS,_id:(imageId||_id)});
}



G.getImageUploadVersions = function(state,{imageId}){
  let {versions} = G.getImage(state,{imageId});
  return Object.keys(versions); 
}

G.getImageCloudStorageInfo = function(state,{imageId}){

  let image =G.getImage(state,{imageId});
  let { versions } = image;
  let storageInfo = Object.entries(versions).map(([versionName,vInfo]) => {
    return [versionName,{location:vInfo.location}]
  })

  return Object.fromEntries(storageInfo);
}

G.getImageVersionStorageInfo = function(state,{imageId,version}){

  let image =G.getImage(state,{imageId});
  let { versions } = image;
  let storageInfo = Object.entries(versions).map(([versionName,versionInfo]) => {
    let storageInfo = {
      storageLocation:versionInfo.storageLocation,
      remoteStorageResourceId:versionInfo.remoteStorageResourceId
    }
    return [versionName,storageInfo]
  })

  

  let obj = Object.fromEntries(storageInfo);
  if( version ){
    return obj[version];
  }else{
    return obj;
  }
}

G.getImageStorageLocation = function(state,{imageId,version}){
  let image = G.getImage(state,{imageId});
  let { storageLocation } = image.versions[version];
  return storageLocation;
}

G.getUnsavedItemIds = function(state){
  let { syncStatus } = state;
  let unsavedItemIdsByType = Object.fromEntries(Object.entries(syncStatus.records).map(
    ([itemType,typeSpecificMap]) => {
      return [itemType,
        Object.keys(typeSpecificMap).sort((a,b) => a.localeCompare(b))
      ]
    }
  ).filter(entry => entry[1].length > 0))

  return unsavedItemIdsByType;
}

G.getImageRecordUrl = function(state,args){
  let {imageId,version,fallBackOnRaw,returnVersion} = args;

  if( !imageId ){
    throw Error("imageId cannot be a falsey value; args: " + JSON.stringify(args));
  }

  let targetVersion = version || DEFAULT_CONVERTED_VERSION;


  let mediaInfo = state.media[ imageId ]
  let imageVersion = mediaInfo && mediaInfo[targetVersion];
  if( !imageVersion ){


    if( process.env.NODE_ENV !== 'production' ){
      fallBackOnRaw = true;
    }
    
    if( fallBackOnRaw ){
      targetVersion = "raw";
      imageVersion = mediaInfo && mediaInfo[targetVersion];
      if( returnVersion ){
        return imageVersion ? ({
          url:imageVersion.localBlobUrl,
          version:targetVersion
        }) : null;
      }
      return imageVersion ? imageVersion.localBlobUrl : null;
    }else{
      return null;
      //throw Error("Could the requested image resource.");
    }
  }else{
    if( returnVersion ){
      return {
        url:imageVersion.localBlobUrl,
        version:targetVersion,
      }
    }
    return imageVersion.localBlobUrl;
  }
}

G.getWesternBlotTableData = function(state){

  //let tableDataShape = { _id, title, dateModified, targetInfo }

  let westernBlots = G.getDataItems(state,"westernBlots");

  let tableData = westernBlots.map(wb => {
    let wbMeta = state.meta.westernBlots[wb._id];
    let dateModified = wbMeta.lastEditedData
    let linkedAtnIds = G.getLinkedItemIds(state,{type:'westernBlots',link:'annotations',_id:wb._id});
    let atnLabels = linkedAtnIds.map( id => {
      let atn = G.getData(state,{itemType:ANNOTATIONS,_id:id});
      return { _id:atn._id, label:atn.label };
    })
    return {
      _id:wb._id,
      title:wb.title,
      dateModified,
      targetInfo:atnLabels
    }
  })

  return tableData;


}

G.getAnnotationQuantifications = function(state,{_id}){
  let atn = G.getData(state,{_id, itemType:ANNOTATIONS});
  let { quantifications } = atn;
  let values = quantifications.map(q => q.value);
  return values;
}

G.getAnnotationsWithoutWesternBlots = function(state){

  let atns = Object.values(state.data.annotations).filter(atn => atn.annotationType === 'ls');
  let unlinkedAtns = atns.filter(atn => !(atn.links.westernBlot && atn.links.westernBlot.length > 0));
  return unlinkedAtns;
  
}

G.getSampleLayoutTableId = function(state,args){
  let { sampleLayoutId } = args;
  let layout = G.getData(state,{itemType:'sampleLayouts',_id:sampleLayoutId});
  return layout.figurePanelId;
}

G.getImageSetSampleLayoutArrangement = function(state,{imageSetId}){
  let imageSet = G.getData(state,{itemType:'imageSets',_id:imageSetId});

  let annotations = G.getAnnotationsByImageSetId(state,imageSetId);

  let sampleLayoutMap = {};
  annotations.forEach(atn => {
    let atnSampleLayout = atn.links.sampleLayout;
    let sampleLayoutId = atnSampleLayout && atnSampleLayout[0];
    sampleLayoutMap[ sampleLayoutId ] = [...(sampleLayoutMap[ sampleLayoutId ]||[]),atn._id];
  })
  return sampleLayoutMap;
}

G.getDataCreators = function(state){
  let { meta } = state;
  let allRecords = Object.values(meta).map(recMap => Object.values(recMap)).flat();
  let allCreatorIds = allRecords.map(rec => rec.creatorId);
  let creatorIds = Array.from(new Set(Object.values(allCreatorIds)));

  return creatorIds;


}

G.getLinkedItemIds = function(state,args){
  let {type,_id,link} = args;
  let item = G.getData(state,{itemType:type,_id});
  let links = item.links;
  
  if( !links ){
    //////console.warn("Item ("+type+", "+_id+") does not have `links` property.");
    return [];
  }

  let targetLink = links[link]||[];
  return targetLink;

  
}

G.getPanelIds = function(state,{figurePanelType}){
  let figurePanelValues = Object.values(state.data.figurePanels);
  let filteredValues;
  if( figurePanelType ){
    filteredValues = figurePanelValues.filter(x => x.figurePanelType === figurePanelType) 
  }else{
    filteredValues = figurePanelValues;
  }

  let ids = filteredValues.map(x => x._id);

  return ids;

  

}

G.getLinkedAssay = function(state,itemSpec){

}

G.getItemWarnings = function(state,itemSpec){

  let linkedAssay = G.getLinkedAssay(state,itemSpec);


}

G.doesBidirectionalDependencyExist = function(state,{requiringRecord,recordRecordRequired}){
  let requirerRecord = G.getRecord(state,{_id:requiringRecord});
  let recordRequired = G.getRecord(state,{_id:recordRequired});




}

G.getAnnotationRequirements = function(state,{_id}){
  let record = G.getRecord(state,{itemType:ANNOTATIONS,_id});
  let { data } = record;

  return {
    parentImageSet:[data.imageSetId]
  }

}

G.getFigurePanelRequirements = function(state,{_id}){
  let usedInFigure = G.getAnnotationsUsedInFigure(state,{figurePanelId:_id});
  return {
    cropBoxComponents:Array.from(new Set(usedInFigure))
  };
}

G.getSelectedFigurePanel = function(state){
  throw Error("getSelectedFigurePanel is EXTREMELY deprecated.");
}

G.getWesternBlotBandIds = function(state,{_id}){
  let westernBlot = state.data.westernBlots[_id];
  if(!westernBlot){
    throw Error("No western blot defined with _id: " + _id);
  }else{

  }

}

G.getFigurePanelGlobalStyle = function(state,{figurePanelId}){

  let panel = G.getData(state,{itemType:'figurePanel',_id:figurePanelId});
  return panel.globalStyle;

}

G.getFigurePanelGlobalStyleProperty = function(state,{figurePanelId,property}){
  let panelGlobalStyle = G.getFigurePanelGlobalStyleProperty(state,{figurePanelId});
  let value = panelGlobalStyle[property];
  return value;
}

G.getFigurePanelRowSpacing = function (state,{figurePanelId,rowIndex}){

  return G.getFigurePanelGlobalStyleProperty(state,{figurePanelId,property:'rowSpacing'});


}




G.getAnnotationQuantification = function(state,{_id}){

  let atn = G.getData(state,{itemType:'annotation',_id});
  let { quantifications } = atn;
  return quantifications;
}

G.getItemLinks = function(state,{itemType,_id,linkTypes}){
  if(!itemType){
    throw Error("itemType must be defined.");
  }
  if(!_id){
    throw Error("_id must be defined.");
  }
  let item = G.getData(state,{itemType,_id});
  let links = item.links;
  if( linkTypes && linkTypes.length > 0 ){
    links = links.filter(link => linkTypes.includes(link.type)) 
  }
  return links;
}

function isTopLevelDirectoryMeta(meta){
  let filesystemParentDirectory = meta[FILESYSTEM_PARENT_DIRECTORY];

  return filesystemParentDirectory === null;
}



function getTopLevelDirectoryFileystemName(state,meta){


  let datatypeSpecificDirectoryRoots = G.getDatatypeSpecificDirectoryRoots(state);



  let matchingFilesystemEntry = Object.entries(datatypeSpecificDirectoryRoots).find(entry => entry[1] === meta._id);

  let itemTypeContainedByTopLevelDirectory;
  if( !matchingFilesystemEntry ){

    let { filesystemName } = meta;
    itemTypeContainedByTopLevelDirectory = filesystemName.split('-')[0];



  }else{
    itemTypeContainedByTopLevelDirectory = matchingFilesystemEntry[0];
  }

  let capitilizedRoot = ({
    figurePanels:"Figure Panels",
    imageSets:"Image Sets",
    figurePanelTemplates:"Figure Panels Templates",
  })[itemTypeContainedByTopLevelDirectory];

  if(!capitilizedRoot){
    throw Error("Could not get top level directory name because '" + itemTypeContainedByTopLevelDirectory + "' was not registerd to a piece of a directory name");
  }

  return capitilizedRoot + ' Root Directory';
}


G.isImageSetWithASingleChild = function(state,location){
  let record = G.getRecord(state,location);
  if( (record.itemType||record.type) === IMAGE_SETS ){

  
    let images = record.data.images;
    return images.length === 1;
  }
  return false;

}

G.getFilesystemName = function(state,recordSpec){
 
  if( recordSpec.itemType === IMAGE_UPLOADS ){
    let { _id } = recordSpec;
    let pendingRecord = state.pendingRecords.imageUploads[_id]
    if( pendingRecord ){
      return pendingRecord.filesystemName;
    }
  }

  let record = G.getRecord(state,recordSpec);
  let { meta } = record;
  if( isTopLevelDirectoryMeta(meta) ){

    return getTopLevelDirectoryFileystemName(state,meta);

  }else if( G.isImageSetWithASingleChild(state,{record}) ){
    let imageChildArgs = {_id:record.data.images[0],itemType:IMAGE_UPLOADS}
    record = G.getRecord(state,imageChildArgs);
    meta = record.meta;
  }


  return meta[FILESYSTEM_NAME];
}


G.getItemType = function(state,_id,handleErrorArg){
  let { silenceError } = handleErrorArg || {};
  let { data } = state;

  for(let type in data){
    if( _id in data[type] ){
      return type;
    }
  }

  if( !silenceError ){
    //console.error(JSON.stringify(_id));
    throw Error("Record (_id = " + _id + ") was not found.");
  }

}

G.isArchived = function(state,searchArgs){

  //let {itemType,_id} = searchArgs
  let meta = G.getMeta(state,searchArgs);
  let archived = meta.archived;
  let boolArchived = Boolean(archived);
  return boolArchived;
}


G.getMeta = function(state,searchArgs){
  return G.getRecord(state,searchArgs).meta;
}



G.doesRecordExistInCache = function(state,searchArgs){

  let { itemType, type, _id } = searchArgs;

  let itemTypeGiven = (itemType||type)
  let resolvedItemType = (
    itemTypeGiven ? getResolvedItemTypeName(itemTypeGiven)
    :
    G.getItemType(state,_id,{silenceError:true})
  )

  
  
  let record = state.data[resolvedItemType][_id];
  let recordExists = Boolean(record);
  return recordExists;

}

function validRecord(record){
  if( !record ){
    return false;
  }
  let fields = ['data','meta','type','itemType'];
  let missingField = fields.find(field => !record[field]);
  if( missingField ){
    return false;
  }
  return true;
}

G.getRecord = function(state,searchArgs){

  let { itemType, type, _id, record, topLevelDirectory } = searchArgs;

  if( record && validRecord(record) ){
    return record;
  }

  let itemTypeGiven = (itemType||type)


  let resolvedItemType;
  if( topLevelDirectory ){

    resolvedItemType = DIRECTORIES;
    _id = G.getTopLevelDirectoryId(state,{type:topLevelDirectory});

  }else{
    resolvedItemType = (
    itemTypeGiven ? getResolvedItemTypeName(itemTypeGiven)
    :
    G.getItemType(state,_id)
  )
  }

  let data = state.data[resolvedItemType][_id]; 

  if( !data ){
    if( 'ifDoesNotExist' in searchArgs ){
      return searchArgs.ifDoesNotExist;
    }
    throw Error("Cannot find data for args: " + JSON.stringify(searchArgs)+" resolved _id = " + _id);
  }

  return {
    _id,
    data,
    meta:state.meta[resolvedItemType][_id],
    itemType:resolvedItemType,
    type:resolvedItemType
  }
}



G.getData = function(state,searchArgs){
  let rec = G.getRecord(state,searchArgs);
  if( 'ifDoesNotExist' in searchArgs && !rec ){
    return searchArgs.ifDoesNotExist;
  }
  return rec.data;
}


G.getEvaluatedFigurePanelRow = function(state,{figureTemplateId,antibodyId,rowIndex}){


}



G.getActiveItemIds = function(state,{type}){

  let activeIds = [];
  let meta = state.meta[type];
  for(let _id in meta){
    if( ! meta[_id].archived ){
      activeIds.push(_id);
    }
  }

  return activeIds;

}

G.isItemPanelTemplate = function(state,{_id,figurePanelId}){
  let resolvedId = _id || figurePanelId;
  if( !resolvedId ){
    throw Error("No _id/figurePanelId was given, so there is no record to check.");
  }

  let pathToFile = G.getAbsoluteFsPath(state,{_id:resolvedId});
  if( pathToFile[0] === G.getDatatypeSpecificDirectoryRoots(state).figurePanelTemplates){
    return true;
  }
  return false;


  
}


G.getItemCount = function(state,{type}){
  let activeItemIds = G.getActiveItemIds(state,{type});
  return activeItemIds.length;
  //return Object.keys(state.data[type]).length;
}

G.getItemIds = function(state,{type}){
  let plural = type+'s';
  const isType = k => state.data[k];
  let finalType = isType(type) ? type : (isType(plural) ? plural : undefined);
  if( finalType ){
    return Object.keys(state.data[finalType]);
  }else{
    throw Error(type + " is not in data.");
  }
}  
//G.getIds = G.getItemIds



G.getRecordsSyncStatus = function(state,{_id}){

  let allSyncObjects = Object.values(state.syncStatus.records).flat();

  let targetObjectType = allSyncObjects.find(syncObjContainer => (_id in syncObjContainer))

  let syncStatus = targetObjectType ? targetObjectType[ _id ] : undefined;

  return syncStatus

}


G.getLaneLine = function(state,{annotationId,offsetFromLeft}){
  let atn = G.getAnnotation(state,{annotationId});
  let atnHeight = G.getAnnotationHeight(state,annotationId);

  let counterclockwiseHeight = atnHeight/2;
  let clockwiseHeight = atnHeight/2;

  let { ls } = atn;




  let lsVec = minus(ls[1],ls[0]);
  let uperpLs = unitPerp(lsVec);

  
  let counterclockwiseVec = scale(counterclockwiseHeight,unitPerp(lsVec));
  let clockwiseVec = scale(clockwiseHeight,scale(-1,uperpLs));

  let initialDragPoint = add(ls[0],scale(offsetFromLeft,unit(lsVec)));

  let [x1,y1] = add(initialDragPoint,counterclockwiseVec);
  let [x2,y2] = add(initialDragPoint,clockwiseVec);

  return {x1,y1,x2,y2};

}


G.getAnnotationQuantificationArgs = function(state,{
  _id,annotationId, temporarySpacingFactorOverride
}){

  _id = annotationId || _id;

  let { height, ls, imageId } = G.getAnnotationProperties(state,{
    _id, properties:["height","ls","imageId"],forQuantification:true
  })

 
  let imageData = G.getData(state,{itemType:IMAGE_UPLOADS,_id:imageId});

  let imageSrc = G.getImageRecordUrl(state,{imageId}) 

  let evaluatedLaneOffsets = G.getLaneLineOffsetEvaluations(state,{_id,temporarySpacingFactorOverride})
 


  let annotationLsDistance = distance(...ls);
  

  let laneLsList = evaluatedLaneOffsets.map( laneOffsets => {
        let intermediaryPoints = laneOffsets.map(offset => {
          let scaledOffset = offset / annotationLsDistance;
          return getIntermediaryPoint(ls,scaledOffset)
        })
        return intermediaryPoints;
      })

  let previousLanePeakBoundaries;
  
  let atn = G.getAnnotation(state,{_id});
  let prevQuants = atn.quantifications;

  if( prevQuants ){
    previousLanePeakBoundaries = 
      prevQuants.map(lane => lane.integrationRanges);
  }



  return {
    _id,
    imageData,
    annotationId,
    annotation:atn,
    imageSrc,
    evaluatedLaneOffsets,
    height,
    ls,
    imageId,
    laneLsList,
    previousLanePeakBoundaries,
  }


}

G.getIntegrationRange = function(state,{annotationId,laneIndex,rangeIndex}){
  let atn = G.getAnnotation(state,{_id:annotationId});
  let { quantifications } = atn;
  let lane = quantifications[laneIndex];
  let { integrationRanges } = lane;
  let range = integrationRanges[rangeIndex];
  return range;


}

G.getHighestQuantificationLaneIndex = function(state,{ annotationId, laneIndices }){

  let atn = G.getAnnotation(state,{_id:annotationId});
  let { quantifications } = atn;

  let maxLane = -1;
  let maxValue = -1;
  for(let ii = 0; ii < laneIndices.length; ii++){
    let targetIndex = laneIndices[ii];
    let { value } = quantifications[ targetIndex ];
    if( value > maxValue ){
      maxLane = targetIndex;
      maxValue = value;
    }
  }

  return maxLane;


}


G.getQuantificationProcessArgsFromAnnotationIds = function(state,{annotationIds}){

  return annotationIds.map(_id => 
    G.getAnnotationQuantificationArgs(
      state,{_id}
  ));


  /*

  let atnIdsGroupedByImageSetId = G.getAnnotationIdsGroupedByImageSetId(state,{annotationIds});

  let processArgList = Object.entries(atnIdsGroupedByImageSetId).map(([imageSetId,atnIdList]) => {

    let imageSrc = G.getFigure

  })
  */



  //need list of: 
  // { imageSrc, annotations:{ 
  //  [atnId]:{
  //    height,
  //    laneLsList
  //  }
  // }



}

G.getDefaultLaneLineOffsetEvaluations = function(state,{_id, laneCount, averageWidth }){

  const defaultLeftMargin = 0.001;
  const defaultLaneSpacing = 0.05;

  const DEFAULT_SPACING_FACTOR = averageWidth * defaultLaneSpacing;
  //let annot = G.get(state,{type:ANNOTATIONS,_id});
  //let { ls } = annot;
  //let lineDistance = distance(...ls);
  //let averageWidth = lineDistance / laneCount;

  return Array(laneCount).fill(null).map((_,ii) => {
    let left = (averageWidth * ii) + DEFAULT_SPACING_FACTOR + defaultLeftMargin;
    let right = (averageWidth * (ii+1)) - DEFAULT_SPACING_FACTOR + defaultLeftMargin;
    return [left,right];
  })

}

function quantificationLs(annotation){
  let { quantificationAnnotation } = annotation;
  if( quantificationAnnotation ){
    return quantificationAnnotation;
  }
  return annotation;
}

G.getLaneLineOffsetEvaluations = function(state,{_id, temporarySpacingFactorOverride}){
  
  let annot = G.get(state,{type:ANNOTATIONS,_id});

  let laneBoundaryPositions = annot.laneBoundaryPositions;
  let laneCount = laneBoundaryPositions.length;

  
  let qLs = quantificationLs(annot);


  let { ls } = qLs;
  let lineDistance = distance(...ls);

  let averageWidth = lineDistance / laneCount;

  let laneSpacingFactor = temporarySpacingFactorOverride || annot.defaultLaneSpacing;

  let DEFAULT_SPACING_FACTOR = laneSpacingFactor * averageWidth;

  let LEFT_OFFSET = annot.defaultLeftMargin;


  let evaluations = laneBoundaryPositions.map((lane,ii) => {

    let left = lane[0] !== 'DEFAULT' ? lane[0] : (averageWidth * ii) + DEFAULT_SPACING_FACTOR + LEFT_OFFSET;
    let right = lane[1] !== 'DEFAULT' ? lane[1] : (averageWidth * (ii+1)) - DEFAULT_SPACING_FACTOR + LEFT_OFFSET;

    return [ left, right ];

  })

  return evaluations;


}

G.get = function(state,args){
  let { type, _id, properties } = args;

  let records = state.data[type];
  let idList = Array.isArray(_id) ? _id : [_id];

  let results = idList.map(id => {
    let object = records[id];
    if( !properties || (Array.isArray(properties) && properties.length === 0) ){
      return object;
    }

    if( !object ){
      throw Error(id+" is not in '"+type+"' records.");
    }
    return properties.map( propertyPath => {
      let curValue = object;
      let listPropertyPath = Array.isArray(propertyPath) ? propertyPath : [propertyPath];

      listPropertyPath.forEach( (nextKey,ii) => {
        if( nextKey in curValue ){
          curValue = curValue[nextKey];
        }else{
          throw Error("Couldn't evaluate " + JSON.stringify(propertyPath) + " in " + _id+". Got stuck on '"+nextKey+"' ("+ii+"), getting from " + JSON.stringify(curValue));
        }
        
      })
      return curValue;

    })
  })

  if( Array.isArray(_id) ){
    return results;
  }else{
    //if its a single string
    return results[0];
  }

}

//REQUIRE_STATEMENTS

G.getAnnotationLaneCount = function(state,{_id}){
  let getResult = G.get(state,{type:ANNOTATIONS,_id,properties:[["laneBoundaryPositions","length"]]})
  let count = getResult[0];
  return count;
}


G.getExpectedLaneCount = function(state,imageSetId){
  return 12;
}

G.getExpectedLaneProperties = function(state,{imageSetId,ls}){

  let laneCount = G.getExpectedLaneCount(state,imageSetId);

  let lineDistance = distance(...ls);


  let defaultLaneSpacing = 0.05;
  let defaultLeftMargin = 0.001;
  


  let averageWidth = lineDistance / laneCount;

  let laneBoundaryPositions = G.getDefaultLaneLineOffsetEvaluations(state,{ laneCount, averageWidth });

  
  return {
    laneCount,
    laneBoundaryPositions,
    defaultLaneSpacing,
    defaultLeftMargin
  };

}


G.getLoginInfo = function(state,field){
  

  return state.loginInfo[ field ];
}


function intervalAround(x, size){
  return [x - size/2, x + size/2];
}

G.getSessionCookie = function(state){

  if( !state ){
    debugger;
  }
  return state.loginInfo.cookie;
}


G.getCellGroupCount = function(state,{figurePanelId}){
  
  let figurePanel = state.data.figurePanels[figurePanelId];

  return Object.values(figurePanel.cellGroups).length;
}

G.getAnnotationsByImageId = function(state,imageId){

  let imageSetId = G.getImageSetIdByImageId(state,imageId);
  let annotations = G.getAnnotationsByImageSetId(state,imageSetId);
  return annotations;
}

G.isImageIdAnOnlyChildInItsImageSet = function(state,{imageId}){

  let imageSet = G.getImageSetByImageId(state, imageId) ;
  if( !imageSet.images ){
  }
  let moverImageSetHasOnlyOneImage = imageSet.images.length === 1;

  return moverImageSetHasOnlyOneImage;

}

G.getImageMoveMessageKey = function(state,{imageIds,destinationImageSetId}){

  //for now, we just account for one at a time
  if( imageIds.length > 1 ){
    throw Error("Multi-image-move is not implemented.");
  }else if( imageIds.length === 0 ){
    throw Error("Can't move 0 images, no imageIds were given.");
  }

  let moverId = imageIds[0];

  
  if( G.isImageIdAnOnlyChildInItsImageSet(state,{imageId:moverId}) ){
    return undefined;
  }

  let moverAnnotations = G.getAnnotationsByImageId(state,moverId);
  let destinationExists = destinationImageSetId && destinationImageSetId in state.data.imageSets;

  if( !destinationExists ){
    return undefined;
  }

  let moverHasAtns = moverAnnotations.length > 0;

  let messageKey;
  if(destinationExists && moverHasAtns ){
    messageKey = "allAnnotationTransferOptions";
  }

  return messageKey;

}

G.getDownloadedImageVersions = function(state){
  let media = state.media;
  let mediaEntries = Object.entries(media);
  let versionInfo = mediaEntries.map(([imageId,imageVersions]) => {
    return ({imageId, versions:Object.keys(imageVersions)})
  })
  return versionInfo;
}

G.isImagePresent = function(state,{imageId,version}){
  let imageMediaUrlContainer = state.media[imageId];
  if( imageMediaUrlContainer ){
    let versionInfo = imageMediaUrlContainer[version];
    if( !versionInfo ){
      return false;
    }
    return Boolean(!(versionInfo.pending) && versionInfo.localBlobUrl);
  }
  
  return false;

}

G.isImageLastImageInImageSet = function(state,imageId){
  let image = state.data.imageUploads[imageId];
  let imageSetId = image.imageSetId;
  let imageSet = state.data.imageSets[ imageSetId ];
  if( imageSet === undefined ){
    throw Error("Image (id = " + imageId+") is linked to a non-existent imageSet (id = " + imageSetId+")");
  }

  return imageSet.images.length === 1;
}



G.getImageDeletionMessage = function(state,imageId){
  let messageKey = G.getImageDeletionMessageKey(state,imageId);

  let messageMap = {
    imageHasAnnotation:[
      HTML.p("You've already annotated this image and if you delete, your work will be lost."),
      HTML.p("Delete anyway?"),
    ],

    imageUsedInFigure:[
      HTML.p("This image is used in your figure, if you delete, it will be lost from your figure."),
      HTML.p("Delete anyway?")
    ]
  }

  return messageMap[messageKey];
}

function uniqueCellGroupIds(state,figurePanelId){ 


  let figurePanel = state.data.figurePanels[figurePanelId];

  return Array.from(new Set( figurePanel.grid.flat() )); 
}

G.getAnnotationsUsedInFigure = function(state,{figurePanelId}){

  
  let figurePanel = state.data.figurePanels[figurePanelId];

  let cellGroupsIds = uniqueCellGroupIds(state,figurePanelId);

  let annotationIdsInGrid = cellGroupsIds.map(
    groupId => {

      let group = figurePanel.cellGroups[groupId];
      let value = group.value;
      if( value === undefined ){
        debugger;
      }
      if( value.valueType === "crop" ){
        let annotationId = value.annotationId
        return annotationId;
      }

          }
  ).filter(x => x!==undefined);

  return annotationIdsInGrid;

}

G.getImageSetIdsInFigure = function(state,args){
  let figurePanelId = args.figurePanelId;

  let figurePanel = state.data.figurePanels[figurePanelId];

  let figureAnnotationIds = G.getAnnotationsUsedInFigure(state,{figurePanelId});

  let imageIdsUsedInFigure = figureAnnotationIds.map(
    atnId => state.data.annotations[atnId].imageSetId
  );
  return imageIdsUsedInFigure;
}

G.isAnnotationUsedInFigure = function(state,{figurePanelId,annotationId}){

  return G.getAnnotationsUsedInFigure(state,{figurePanelId}).includes(annotationId);

}


const DEPENDANT_TYPE_BY_DEPENDEE_ROLE = {
  //If A is DEPENDANT on B
  //B is the DEPENDEE
  //In our setup, B's type depends only on its role
  //I.e, we can compute what B's type is 
  //(imageUpload, imageSet, annotation),
  //hence, we have this map!
  parentImageSet:ANNOTATIONS,
  cropBoxComponents:FIGURE_PANELS,
}



G.searchDependantTree = function(state,args){
  let { root, query } = args;

  //query will be something like:
  

  let dependants = G.getDependants(state,root);

  let itemsToQuery = [];

  for(let role in dependants){
    let dependantsPlayingThisRole=dependants[role];
    itemsToQuery.push(
      ...dependantsPlayingThisRole.map(_id => ({
        itemType:DEPENDANT_TYPE_BY_DEPENDEE_ROLE[role],
        _id,
        role
      }))
    )
  }

  let queriedChildren = [root,...itemsToQuery.map(item => {
    return G.searchDependantTree(state,{root:item})
  }).flat()];


  if( !query ){
    return queriedChildren;
  }

  let roleQuery = query.requiredAsByRole;
  if( !roleQuery ){
    throw Error("You made a query with no property 'requiredAsByRole'. Pass that in to the query field.");
  }

  let results = queriedChildren.filter(item => item.role === roleQuery);

  return results;
  
}

G.getImageSetByImageId = function(state,imageId){
  let image = state.data.imageUploads[ imageId ];
  let imageSetId = image.imageSetId;
  let imageSet = getImageSet(state,imageSetId);
  if( !imageSet ){ throw Error("Image set doesn't exist for imageId  = " + imageId); }

  return imageSet;
}

G.getImageSetIdByImageId = function(state,imageId){
  let imageSet = G.getImageSetByImageId(state,imageId);
  return imageSet._id;
}

G.isImageUsedInFigures = function(state,imageId){

  
  let image = state.data.imageUploads[imageId];

  let {imageSetId} = image;
  
 
  return Object.keys(state.data.figurePanels).some( figurePanelId => {

    let imageSetIdsUsedInFigure = G.getImageSetIdsInFigure(state,{figurePanelId});
    return imageSetIdsUsedInFigure.includes(imageSetId);

  })

  
}

G.getImageDeletionMessageKey = function(state,imageId){

  let messageKey;
  if( G.isImageLastImageInImageSet(state,imageId) ){
    if( G.isImageUsedInFigures( state,imageId ) ){
      messageKey = "imageUsedInFigure"
    }else if( G.doesImageHaveAnnotation( state, imageId ) ){
      messageKey = "imageHasAnnotation"
    }
  }

  return messageKey;

}

G.doesImageHaveAnnotation = function(state,imageId){

  let annotationList = G.getAnnotationsByImageId(state,imageId)

  return annotationList.length > 0;

}

G.getAnnotation = function(state,{annotationId,_id}){
  _id = _id || annotationId;
  return G.getData(state,{itemType:ANNOTATIONS,_id});
}

G.getLsFromMarkToAnnotation = function(state,{markId,annotationId}){


  let atn;
  if( !annotationId ){
    annotationId = G.getMarkAssociatedBandId(state,{_id:markId});
    if( !annotationId ){
      return null;
    }
    atn = G.getAnnotation(state,{annotationId});
    if(!atn){
      return null
    }
  }
  

  let mark = G.getData(state,{itemType:ANNOTATIONS,_id:markId});

  let { ls } = atn;
  if( atn && !ls ){
    debugger;
  }

  let markLsEndPointDistances = ls.map(point => distance(point,mark.mark))
  let closerEndpointIndex = argmin(markLsEndPointDistances);

  let closerEndpoint = ls[closerEndpointIndex];

  let uPerp = unitPerp(minus(ls[1],ls[0]))

  let closestAnnotationLine = [
    closerEndpoint,add(closerEndpoint,uPerp)
  ];

  let pointProj = pointProjectionOntoLine(
    mark.mark, closestAnnotationLine
  )

  let lineFromMarkToAnnotation = [mark.mark,pointProj]

  let distanceToLs = Math.abs(distanceFromPointToLineSegment(mark.mark,ls));

  let atnHeight = G.getAnnotationHeight(state,annotationId);
  let isInsideAnnotationBox = (atnHeight/2) >= distanceToLs;

  let payload = {
    lsToAnnotationBox:lineFromMarkToAnnotation,
    isInsideAnnotationBox,
    halfOfMatchedAtnHeight:atnHeight/2,
    distanceToLs
  }

  
  return payload;

}


G.getRelativeImageHeight = function(state,{_id}){
  let rec = G.getRecord(state,{_id});
  let { data } = rec;
  let { height } = data;
  return height;
}

function getLabelType(label){

  
  let str = label + '';

  if( !isNaN(Number(str)) ){
    return "mw"
  }else{
    if( str.match(/[kK][dD][aA]?/) ){
      return "mw";
    }
    return "crop";
  }

  // its mw if: only numbers, only numbers with [kK][dD][aA]?




}

G.getMarkLabels = function(state,{annotationId,temporaryAnnotationAdjustments,side, labelType }){

  let atnRec = G.getRecord(state,{type:ANNOTATIONS,_id:annotationId});
  if( !atnRec ){
    return [];
  }
  let atn = atnRec.data;

  let homeImageSetId = atn.imageSetId;

  let allMarksOnThisImageSet = G.getAnnotationsByImageSetId(state,atn.imageSetId).filter(imageSetAtn => imageSetAtn.mark).filter(atn => {
    if( labelType ){
      return getLabelType(atn.label) === labelType;
    }else{
      return true;
    }
  });

  let marksCorrespondingToThisAnnotation = allMarksOnThisImageSet.filter(mark=>{
    let associatedAtn = G.getMarkAssociatedBandId(state,{_id:mark._id});
    return associatedAtn;
  })

  let ls = atn.ls
  let angle = -vectorAngleRad(minus(...ls));
  let allMarks = 
    marksCorrespondingToThisAnnotation.map( mark => mark.mark );


  let imageSetHeight = G.getImageSetHeight(state,homeImageSetId)
  let pivotPoint = [ 0.5, imageSetHeight / 2 ];

  let [lsPoint,...rotatedMarks] = rotatePointsAboutPoint({
    pointsList:[ls[0],...allMarks],
    pivotPoint,
    angle
  })

  let intervalCenter = lsPoint[1];
  let atnHeight = G.getAnnotationHeight(state,annotationId);
  let interval = intervalAround(intervalCenter, atnHeight);

  let heightProportions = rotatedMarks.map(mark => {

    return (mark[1]-interval[0])/atnHeight;

  })

  const inUnitRange = num => 0 <= num && num <= 1;

  let figureMarkerObjects = heightProportions.map( (prop,ii) => ({
    positions:[Number(Number(prop).toFixed(3))].filter(inUnitRange),
    label:marksCorrespondingToThisAnnotation[ii].label
  })
  ).filter(obj => obj.positions.length > 0);

  return figureMarkerObjects;


}

G.getAnnotationHeight = function(state,annotationId){
  
  let {heightGroupId,height} = state.data.annotations[annotationId];

  if( heightGroupId === "__custom" ){
    return height;
  }else{
    let {height} = state.heightGroups[heightGroupId]
    return height;
  }
}

G.getImageSetHeight = function(state,imageSetId){
  let imageSet = state.data.imageSets[imageSetId];
  let imageId = imageSet.images[0];
  let image = state.data.imageUploads[imageId];
  let height = image.height;
  return height;
}

G.getNonFigureImageIdIfPossible = function(state,imageSetId){
  let imageSet = state.data.imageSets[ imageSetId ];
  let figureImageId = imageSet.figureImageId;

  let imageId = imageSet.images.find(id => id !== figureImageId) || figureImageId;

  return imageId;

}

G.getAbsoluteFilesystemNamePathString = function(state,item,args){
 
  let sliceStart = args && args.excludeFirst ? 1 : 0;
  let path = G.getAbsoluteFsPath(state,item).slice(
    sliceStart
  );

  let filenames = path.map(_id => G.getFilesystemName(state,{_id}));

  let str = filenames.map(x => x.replaceAll('/','\\/')).join('/')
  return str;


}

function getImageSet(state,imageSetId,allowNullReturn){

  let imageSet = state.data.imageSets[imageSetId];
  if( !allowNullReturn && !imageSet ){
    throw Error("imageSet (id="+imageSetId+") was not found.");
  }
  return imageSet;
}


function getItemDeletionString({
  targetFsName,
  hasQuantifications,
  depFigurePanelPathStrings,
  targetType
}){

  let hasDependantFigures = depFigurePanelPathStrings.length > 0;
  let noConsequences = !hasQuantifications && !hasDependantFigures;

  let consequenceSuffix = '';

  let firstWord = noConsequences ? 'Delete' : 'Deleting';

  let typeString = targetType === IMAGE_SETS ? 'image set' : 'image';

  if( noConsequences && targetType === IMAGE_SETS ){
    consequenceSuffix = ' and all its images'
  }

  if( !noConsequences ){

    
    let noDepFigures = depFigurePanelPathStrings.length === 0;
    let figureDependanceConsequence = '';
    let quantificationConsequence = '';
    if( hasDependantFigures ){

      let multipleDepFigures = depFigurePanelPathStrings.length > 1;
      let figurePlural = multipleDepFigures ? 's' : '';

      let whatIsBeingRemoved = targetType===IMAGE_SETS ? 'its images' : 'it';

      let quotesSurrounded = depFigurePanelPathStrings.map(x => "'"+x+"'");

      let joinedByCommas = quotesSurrounded.slice(0,depFigurePanelPathStrings.length - 1).join(', ');

      let lastFigure = quotesSurrounded.slice(-1)[0];
      let pathsString;

      if( depFigurePanelPathStrings.length === 1 ){
        pathsString = lastFigure;
      }else if( depFigurePanelPathStrings.length > 1 ){
        pathsString = joinedByCommas + ' and ' + lastFigure;
      }


      figureDependanceConsequence = `remove ${whatIsBeingRemoved} from the figure${figurePlural} ${pathsString}`
    }

    if( hasQuantifications ){
      quantificationConsequence = 'discard its quantifications';
    }

    let consList = [];
    if( figureDependanceConsequence.length > 0 ){
      consList.push(figureDependanceConsequence)
    }
    if( quantificationConsequence.length > 0 ){
      consList.push(quantificationConsequence);
    }


    let joinedConsequences = consList.join(' and ');

    consequenceSuffix = ` will ${joinedConsequences}. Delete anyway`
  }


  return `${firstWord} the ${typeString} '${targetFsName}'${consequenceSuffix}?`;

}

G.getArchiveItemWarning = function(state,item){

  let { type, itemType, _id } = item;

  let resolvedType = itemType || type;

  let targetItem;
  if(resolvedType===IMAGE_SETS){
    targetItem = item;
  }else if( resolvedType === IMAGE_UPLOADS ){
    targetItem = {
      itemType:IMAGE_SETS,
      _id:G.getImageSetIdByImageId(state,_id)
    }
  }else{
    throw Error("Unrecognized resolved type: '"+itemType+"'");
  }

  let targetDependants = G.searchDependantTree(state,{
    root:targetItem,
    query:{requiredAsByRole:"cropBoxComponents"},
  })

  let dependantFigurePanels = targetDependants;

  let depFigurePanelPathStrings = dependantFigurePanels.map(
  item => {
    return G.getAbsoluteFilesystemNamePathString(state,item,{excludeFirst:true})
  })

  let atns = G.getAnnotationsByImageSetId(state,targetItem._id);


  let hasQuantifications = atns.some(atn => atn.quantifications);

  let targetFsName = G.getFilesystemName(state,{_id,resolvedType});



  let strOut = getItemDeletionString({
    targetFsName,
    hasQuantifications,
    depFigurePanelPathStrings,
    targetType:resolvedType
  })
  return strOut;


  
  //return targetDependants;
   
}

G.getImageSetImageIds = function(state,{imageSetId,archived}){
  let imageSet = getImageSet(state,imageSetId); 
  if( archived ){
    return imageSet.images;
  }else{
    return imageSet.images.filter(_id => !G.isArchived(state,{_id,type:IMAGE_UPLOADS}))
  }
}

G.getImageSetCount = function(state,args){
  let { archived } = args || {};

  let imageSetList = Object.values(state.meta[IMAGE_SETS]);
  let activeList = imageSetList.filter(x => !x.archived);
  return activeList.length;
}


function assertAnnotationsHaveHeightGroups(atns){
  let badAtn = atns.find(atn => atn.ls && !atn.heightGroupId);
  if(badAtn){

    throw Error("All ls annotations need a heightGroupId. Did not find one for "+JSON.stringify(badAtn))
  }
}

G.getOrderedHeightGroups = function(state){
  let atns = G.getActiveItemIds(state,{type:ANNOTATIONS}).filter(atn => atn.ls);

  assertAnnotationsHaveHeightGroups(atns);


  let heightGroupIds = atns.map(atn => atn.heightGroupId).filter(x => x!=="__custom");
  let allowedKeys = Object.values(state.heightGroups).map(gr => gr._id);




  let allIds = [...heightGroupIds,...allowedKeys]
  let counts = table(allIds, allowedKeys);

  let sortedByCount = Object.entries(counts).sort(
    (a,b) => b[1] - a[1]
  );

  let sortedHeightGroups = sortedByCount.map(
    entry => {
      let heightGroupId = entry[0];
      let heightGroup = state.heightGroups[heightGroupId];
      if( !heightGroup ){

      }
      return heightGroup;
    }
  )

  return sortedHeightGroups;

}


G.groupItemsBy = function(state,items,groupFunction){}


G.getActiveLsAnnotations = function(state,projection){


  let allActiveLsAnnotations = G.getActiveItemIds(state,{type:ANNOTATIONS}).map(_id => G.getData(state,{_id,type:ANNOTATIONS})).filter(atn=> {
    let isLs = atn.ls;

    let imageStillActive = !G.getRecord(state,{type:IMAGE_SETS,_id:atn.imageSetId}).meta.archived;

    return isLs && imageStillActive;
  });


  let result = allActiveLsAnnotations.map(atn => project(atn,projection));

  return result;

}


G.getCropSearchResultIds = function(state,{query,sortBy}){

  let searchResults = G.getCropSearchResults(state,query,sortBy);
  let ids = searchResults.map(res => res._id || res.annotationId);

  return ids;

}

G.getCropSearchResults = function(state,query,sortBy){

  let allActiveLsAnnotations = G.getActiveLsAnnotations(state);

  let trimmedQuery = (query||"").trim();

  let atnMatches;
  if( trimmedQuery.length === 0 ){
    atnMatches = allActiveLsAnnotations;
  } else {
    atnMatches = allActiveLsAnnotations.filter(xx => {
      return xx.label && xx.label.toLowerCase().trim().includes(query.toLowerCase())});
  }

  atnMatches.sort((a,b) => {
    let _a = (a && a.label) || '';
    let _b = (b && b.label) || '';
    return _a.localeCompare(_b);
  })

  let cropMatches = atnMatches.map(match => {
    return G.getCropFromAnnotation(state,match._id)
  }).filter(x=>x);

  let matchesWithSortableInfo = cropMatches.map(match => {
    let { annotationId } = match;
    let annotationLastEdited = G.getLastEditedDate(state,{_id:annotationId})
    let imageUploadDate = G.getImageUploadDateByAnnotationId(state,{annotationId});
    return {
      ...match,

      [ANNOTATION_LAST_EDITED_DATE]:annotationLastEdited,
      [IMAGE_UPLOAD_DATE]:imageUploadDate,
    }
  });


  matchesWithSortableInfo.sort((a,b) => {
    return b[sortBy] - a[sortBy]
  })



  return matchesWithSortableInfo;



}


G.getSearchResultsSimpleAnnotationLabels = function(state,query){
  let results = G.getCropSearchResults(state,query);

  return results.map(rr => state.data.annotations[rr.annotationId].label)//rr.label);
}


G.getAnnotationCountTable = function(state){

  let imageSetIds = G.getActiveItemIds(state,{type:IMAGE_SETS})

  let countById = {};
  for( let _id of imageSetIds ){
    let meta = G.getMeta(state,{type:IMAGE_SETS,_id});
    let atnIds = meta.requiredAsByRole.parentImageSet||[];
    let activeAtnIds = atnIds.filter(atnId => !G.getMeta(state,{_id:atnId,type:ANNOTATIONS}).archived)
    countById[_id] = activeAtnIds.length;
  }

  
  return countById;

}

G.getAnnotationCount = function(state,{imageSetId}){
  return G.getAnnotationsByImageSetId(state,imageSetId).length;
}


G.getAnnotationsByImageSetId = function(state,imageSetId){

  let imageSet = G.getRecord(state,{type:IMAGE_SETS,_id:imageSetId});

  
  let { meta } = imageSet;


  if( ! meta ){
    debugger;
  }



  let { requiredAsByRole } = meta;




  let childAnnotations = requiredAsByRole.parentImageSet || [];

 
  let activeAtns = [];
  for(let _id of childAnnotations){
    let rec = G.getRecord(state,{_id, type:ANNOTATIONS});
    if( !rec.meta.archived ){
      activeAtns.push(rec.data);
    }
  }

  return activeAtns;

}

G.getAnnotationIdsByImageSetId = function(state,imageSetId){
  let atns = G.getAnnotationsByImageSetId(state,imageSetId);
  return atns.map(atn => atn._id)
}

G.getMarkAssociatedBandId = function(state,{_id}){

  //get closest point (from annotation crops) to this point


  //throw Error("THROWING!");
  let markAtn = state.data.annotations[_id];

  if( !markAtn ){
    debugger;
  }

  let {mark,imageSetId} = markAtn;

  let {annotationId,distance} = getClosestLineAnnotationIdByDistanceToExtremePoints(state,mark,imageSetId) || {};
  return annotationId;

}

/*function getAnnotationLineSegment({p1,p2}){

  let p1Point = [p1.left,p1.top];
  let p2Point = [p2.left,p2.top];

    return [p1Point,p2Point];

}*/


function getClosestLineAnnotationIdByDistanceToExtremePoints(state, point, imageSetId){
  
  let atns = G.getAnnotationsByImageSetId(state,imageSetId).filter(atn => atn.ls);
  if( atns.length === 0 ){
    return null;
  }

  let lineSegments = atns.map(getAnnotationLineSegment);

  let distances = lineSegments.map(ls => {
    let distancesToExtremes = ls.map(extPoint => distance(extPoint,point));
    let minDist = Math.min(...distancesToExtremes);
    return minDist;
  })



  let minDistanceIndex = argmin(distances);
  let closestAnnotation = atns[minDistanceIndex];

  return {annotationId:closestAnnotation._id, distance:distances[minDistanceIndex]}

}


function getLineSegmentParallelToClosestLineSegmentForWhichPointProjectionIsInThatSegment(point,atns){

  if( atns.length === 0 ){
    return null;
  }

  let atnLineSegments = atns.map(getAnnotationLineSegment)


  let distanceToPointsInSegments = atnLineSegments.map(
    (ls,ii) =>{ 
      if( isPointProjectionOntoLineSegmentInLineSegment(point,ls)){ 

        return distanceFromPointToLineSegment(point,ls);

      }
      return Number.POSITIVE_INFINITY;
    })



  let indexOfClosestAnnotation = argmin(distanceToPointsInSegments);

  let closestDistance = distanceToPointsInSegments[indexOfClosestAnnotation];



  if( closestDistance !== Number.POSITIVE_INFINITY ){


    let ls = atnLineSegments[indexOfClosestAnnotation]
    if( ls === undefined ){

    }

    let shiftedLineSegment = lineSegmentShiftedPerpendicularlyToPoint(point,ls);


    return {ls:shiftedLineSegment}

  }

  return null;


}

function toPoint(pointObject){
  let {top,left} = pointObject;
  return [left,top];
}

function getAnnotationLineSegment(atn){

  return atn.ls;
}

function snapPointsToAngle(state,p1,p2,imageSetId){

  let closestToP1 = getClosestLineAnnotationIdByDistanceToExtremePoints(state,p1,imageSetId);
  let closestToP2 = getClosestLineAnnotationIdByDistanceToExtremePoints(state,p2,imageSetId);

  let closest = [closestToP1,closestToP2];
  if( closest.some(x => [null,undefined].includes(x)) ){
    return [p1,p2];
  }
  let dists = closest.map(x => x.distance);

  if( dists.length === 0 ){
    return [p1,p2];
  }

  let closestIndex = dists[0] < dists[1] ? 0 : 1;

  let closestAnnotationId = closest[closestIndex].annotationId;

  let referenceLs = state.data.annotations[closestAnnotationId].ls;


  let referenceVec = unit(minus(referenceLs[1],referenceLs[0]))
  let curVec = minus(p2,p1);

  let refAngle = vectorAngleDegrees(referenceVec);
  let lsAngle = vectorAngleDegrees(curVec)

  let angleDelta = Math.abs(refAngle - lsAngle);

  if( angleDelta < 1.5 ){

    let lsProjLen = dot(curVec,referenceVec)




    return [p1,add(p1, scale(lsProjLen,referenceVec))]


  }

  return [p1,p2];

}

function getAnnotationLineLength(annotation){

  //let segment = getAnnotationLineSegment(annotation);
  return distance(...annotation.ls);
}


G.getBoxGuidelines = function(state,guidelineArgs){

  let { mousePosition, ls } = guidelineArgs;
  if(ls){
    return [
      {rect:[ls[0],...lineSegmentShiftedPerpendicularlyToPoint(mousePosition,ls),ls[1]]}
    ]
  }else{
    return []
  }
}

G.getGuidelines = function(state,guidelineArgs){



  



  let { debug,imageSetId, ls, mousePosition, modifier } = guidelineArgs;

  if( !ls && !mousePosition ){
    throw Error("Can't get guidelines without a lineSegment (ls) or mousePosition!");
  }

  let isMark;
  if( ls && distance(minus(...ls)) < 0.001 ){
    isMark = true;
  }

  if( guidelineArgs.guidelineType === 'box' && !isMark ){
    return G.getBoxGuidelines(state,guidelineArgs);
  }else if( guidelineArgs.guidelineType === 'fixedRatio' ){
  }

  if( debug ){

  }


  let atnsInThisImage = G.getAnnotationsByImageSetId(state,imageSetId);
  /*if( atnsInThisImage.length === 0 ){
    return [];
  }*/


  let cropAnnotationsInThisImage = atnsInThisImage.filter(atn => atn.annotationType === 'ls'); 




  let lines = [];

  let imageHasAtns = atnsInThisImage.length > 0;

  const mark = {mark:(mousePosition||ls[0]),choice:true};

  if( !ls && !imageHasAtns ){
    return [];

  }else if( !ls && imageHasAtns ){
    //go by mouse position
    if(modifier === true){
      return [mark]
    }

    let lineSegment = getLineSegmentParallelToClosestLineSegmentForWhichPointProjectionIsInThatSegment(mousePosition,cropAnnotationsInThisImage);

    //lineSegment && lines.push(lineSegment);


  }else{

    let [p1,p2] = ls;

    let lineSegment = getLineSegmentParallelToClosestLineSegmentForWhichPointProjectionIsInThatSegment(p1,cropAnnotationsInThisImage); 

    //case where mouse is down, but not moved
    if(pointsAreEqual(p1,p2)){

      if(modifier!==true){
        return [mark];
      }



      if(false && lineSegment){ // don't give them this
        lineSegment.choice = true;
        lines.push(lineSegment);
      }else{
        lines.push(mark)
      }

    }else{
      //get lengths of lines


      let allLineLengths;
      if( cropAnnotationsInThisImage.length === 0 ){

        let linesInAllImages = G.getActiveItemIds(state,{type:ANNOTATIONS}).filter(atn => atn.ls);
        if( linesInAllImages.length > 0 ){
          let distances = linesInAllImages.map(line => Number(Number(distance(...line.ls)).toFixed(3))) 
          let lengthMode = mode(distances);
          allLineLengths=[lengthMode];
        }

      }else{
        allLineLengths=cropAnnotationsInThisImage.map(getAnnotationLineLength);
      }

      let lineLengthSet = Array.from(new Set(allLineLengths));
      let snappedLs = snapPointsToAngle(state,p1,p2,imageSetId);

      let guidelines = lineLengthSet.map(len => ({
        ls:toGuideline(...snappedLs,len),
      }))


      //lines.push(...guidelines);

      if( modifier && lines.length > 0 ){
        lines[0].choice = true;
      }

      //let majorGuide = {ls:toGuideline(...snappedLs)};
      let majorGuide = {ls:toGuideline(p1,p2)}
      majorGuide.choice = (lines.length === 0 ? true : (!modifier||undefined));

      lines.push(majorGuide);


    }


    //case where mouse is down, but moved


  }

  let roundedLineSegments = lines.map( line => {
    if( line.mark ){
      return line;
    }
    return {
      ...line,
      ls:(line.ls.map(point => point.map(val => Number(val.toFixed(3)))))
    }
  })

  assertSingleChoice(roundedLineSegments);


  return roundedLineSegments


};



function assertSingleChoice(guides){
  let choiceCount = guides.filter(x => x.choice).length;
  if( choiceCount > 1 ){
    throw Error("Received multiple choice lines.");
  }
}

function toGuideline(p1, p2, length){
  let segmentEnd = 
    length!==undefined?createLineSegmentWithDirection(p1,p2,length)[1]
    : p2;

  return [p1,segmentEnd]
}

G.getThreadIds = function(state){
  return Object.keys(state.threads);
}

G.getThreads = function(state,args){
  let { participants, projection } = args;

  let { threads } = state;
  let allThreads = Object.values(threads);

  let str = JSON.stringify;
  let queryParticipants = [...(participants||[])];
  let strQueryParticipants = str(queryParticipants);

  let filteredThreads = !participants ? allThreads : allThreads.filter(thread => {

    let thisParticipants = [...thread.participants].sort((a,b) => a.localeCompare(b));
    return str(thisParticipants) === strQueryParticipants;
    
  })

  let projectedThreads;
  if( projection ){
    projectedThreads = filteredThreads.map(thread => project(thread,projection))
  }else{
    projectedThreads = filteredThreads;
  }

  return projectedThreads;


}


G.getObjectCount = function(state,{objectType}){
  let { data } = state;
  let object = Object.keys(data[objectType]);
  let count = object.length;
  return count;
}











G.getHeightGroups = function(state){
  return Object.values(state.heightGroups)
};

G.getMessageCount = function(state){
  return state.dialogs.length;
}


function project(resultObject,projection){
  if( !resultObject ){
    return null;
  }

  if( !projection ){
    return resultObject;
  }

  let finalPaths = [];

  const getPaths = (object,prefix=[],paths) => {
    return Object.keys(object).map(key => {
      let val = object[key];
      if( typeof(val) === typeof({}) ){
        return getPaths(object[key],[...prefix,key],paths);
      }else{
        paths.push( {path:[...prefix,key],val} );
      }
    });
  }

  getPaths(projection,[],finalPaths);

  let projectedObject = {};
  
  finalPaths.forEach(({path}) => {

    let subProjObject = projectedObject;
    let subResultObject = resultObject;
    path.forEach((key,ii) => {

      if( ii === path.length - 1 ){
        subProjObject[key] = subResultObject[key]
      }else if( !(key in subProjObject) ){
        subProjObject[key] = {}
      }

      subProjObject = subProjObject[key];
      subResultObject = subResultObject[key];
      
    })
  })

  return projectedObject;
}

function validateDialog(dialog){
  let problems = [];

  let onHide = dialog.beforeHide || dialog.afterHide;

  const push = t => problems.push(`Missing ${t}.`);

  /*if( !onHide){
    push("beforeHide/afterHide");
  }*/

  ['buttons','body'].forEach(key => {
    if( !dialog[key] ){
      push(key);
    }
  })

  let header = dialog.header;
  if( !header ){ push("header") }
  else{
    (!header.text) && push("header.text");
  }

  if( problems.length > 0 ){
    let probString = problems.join('\n\t*');
    let errString = "Invalid dialog:\n"+probString;
    let dialogStr = JSON.stringify(dialog);
    let finalString = errString+'\n\n You passed: '+dialogStr;

    throw Error(finalString);

  }

}

G.getTopMessage = function(state,projection){

  let dialogSpec = state.dialogs[0] || null;

  //to return:
  //* beforeHide
  //* afterHide
  //* buttons
  //* body
  //* header: { type, text }

  let dialog;
  if( dialogSpec ){
  
    dialog = G.getDialog(state,dialogSpec);
    validateDialog(dialog);

  }

  return project(dialog,projection);
}


G.isMeasuredString = function(state,string){
  return isMeasuredString(string);
}

G.amendStructureFromMultiRowSpanCells = function(state,structure,doesSpanMultipleRows){


  let sweepIndex = 0;
  let newStructure = [];
  for(let ii in structure){

    let sublen = structure[ii];
    let subCellSpans = doesSpanMultipleRows.splice(
      sweepIndex, sweepIndex + sublen
    )

    let counter = 0;
    for(let jj of subCellSpans){
      if( jj === false ){
        counter++;
      }else{

        let toPush = [];
        if( counter ){ toPush.push(counter) }
        toPush.push(-1);
        newStructure.push(...toPush);
        counter = 0;
      }
    }
    if( counter !== 0 ){
      newStructure.push(counter);
    }
  }

  return newStructure;

}


G.doCellsSpanMultipleRows = function(state,{figurePanelId,rowIndex}){
  

  if( rowIndex === undefined ){ throw Error("Row index cannot be undefined!") }
  
  let figurePanel = state.data.figurePanels[figurePanelId];


  if( figurePanel.grid.length === 0 ){
    return false;
  }

  if(rowIndex === -1){
    return false;
  }

  let grid = figurePanel.grid;

  let row = grid[rowIndex];
  if( row === undefined ){

  }
  return row.map( (cellId,colIndex) => {
    if( grid[rowIndex-1] && grid[rowIndex-1][colIndex] === cellId ){
      return true;
    }else if( grid[rowIndex+1] && grid[rowIndex+1][colIndex] === cellId ){
      return true;
    }
    return false;
  })


}


function collapseRepeatedIds(rowIds){
  let toReturn = [];
  let lastId = undefined;
  rowIds.forEach( id => {
    if( id !== lastId ){
      lastId = id;
      toReturn.push(id);
    }
  })

  return toReturn;
}


G.getRowStructure = function(state,{figurePanelId,rowIndex}){
  
  let figurePanel = state.data.figurePanels[figurePanelId];

  let row = figurePanel.grid[rowIndex];
  if( row === undefined ){
    return [];
  }

  let structure = [];
  row.forEach( (group, ii) => {
    if( group !== row[ii-1] ){
      structure.push(1);
    }else{
      structure[ structure.length - 1 ]++;
    }
  })
  return structure;
}

G.getAdjacentRowStructuresForAdding = function(state,{figurePanelId,rowIndex}){
  
  let figurePanel = state.data.figurePanels[figurePanelId];


  //let doCellsSpanMultipleRows = G.doCellsSpanMultipleRows(state,rowIndex);
  //

  if( figurePanel.grid.length === 0 ){

    return [[1,15,1]]

  }


  let structures = [...new Set([rowIndex, rowIndex+1].map(
    ii => G.getRowStructure(state,{figurePanelId,rowIndex:ii}).join('.')
  ).filter(x => x.length > 0))].map(x => x.split('.').map(Number))

  let filteredForAllLanes = structures.filter(
    struct => (new Set(struct)).size > 1
  )

  let noStructuresHaveMerges = filteredForAllLanes.length === 0;
  if( noStructuresHaveMerges ){
    if( structures[0].length >= 3 ){

      //need to add 1s on either side because of the rowAdders on both sides of the row
      return [[1,structures[0].length-2,1]]

    }
  }else return filteredForAllLanes;

  return structures;



}

G.getPaymentStatus = function(state,args){

  let { productId } = args;

  let subscription = G.getSubscriptions(state)[productId];
  let { lastInvoice } = subscription;

  let toReturn = {...lastInvoice};
  if( toReturn.status === "failed" ){
    toReturn.date = stripeDateToString(toReturn.date)
  }

  return toReturn;

}


G.getCropRotation = function(state,cropId){
  let thisCrop = state.data.crops[ cropId ];
  let cropRotation = thisCrop.rotation || 0;

  let imageSet = state.data.imageSets[ thisCrop.imageSetId ];
  let imageSetRotation = imageSet.rotation || 0;

  let rotation = cropRotation //+ imageSetRotation;

  return rotation;

}


G.getAdjacentGroupIds = function(state,{figurePanelId,groupId}){
  
  let figurePanel = state.data.figurePanels[figurePanelId];


  let idToCellMap = G.getIdToCellMap(state,{figurePanelId}); 

  let cell = idToCellMap[ groupId ];
  let grid = figurePanel.grid;
  let left;
  let right;
  for(let ii = cell[1]; ii >= 0; ii--){
    let groupIdAtCell = grid[cell[0]][ii];
    if( groupIdAtCell !== groupId ){
      left = groupIdAtCell;
    }
  }

  for(let ii = cell[1]; ii < grid[0].length; ii++){
    let groupIdAtCell = grid[cell[0]][ii];
    if( groupIdAtCell !== groupId ){
      right = groupIdAtCell;
    }
  }

  return { left, right }

}

G.getImageAdjustmentsLabeledByBandAnnotationAt = function(state,{figurePanelId,cell}){
  

  let figurePanel = G.getData(state,{itemType:'figurePanels',_id:figurePanelId});


  let id = figurePanel.grid[ cell[0] ][ cell[1] ];
  let value = figurePanel.cellGroups[ id ].value;
  let side = value.sideRelativeToImage;

  let imageCol = cell[1] + (side==='left'?1:-1);

  let imageCellGroupId = figurePanel.grid[ cell[0] ][ imageCol ];
  let imageCellGroup= figurePanel.cellGroups[ imageCellGroupId ];

  let defaultAdjustments = { zoom: 1, positionDelta: [0,0] }
  let imageAdjustments = imageCellGroup.imageAdjustments || defaultAdjustments;

  return imageAdjustments;

}

G.getImageSet = function(state,imageSetId){
  return G.getData(state,{_id:imageSetId, itemType:IMAGE_SETS});
}

G.getArchivedImageIds = function(state){
  return state.archivedImages;
}



G.filterActiveIds = function(state,{idList,itemType}){

  return idList.filter(id => !G.isArchived(state,{_id:id,itemType}));

}

G.isEmptyImageSet = function(state,{_id,imageSet}){
  if( !imageSet ){
    imageSet = G.getImageSet(state,_id);
  }
  let activeIds = G.filterActiveIds(state,{idList:imageSet.images,itemType:IMAGE_UPLOADS});

  return activeIds.length === 0;


}

G.getImageSetFigureImage = function(state,{imageSetId}){
  return state.data.imageSets[imageSetId].figureImageId;
}


G.getImageSetFigureImageIdByAnnotationId = function(state,{annotationId}){
  let atn = G.getData(state,{itemType:'annotation',_id:annotationId});
  let { imageSetId } = atn;
  let figureImageId = G.getImageSetFigureImage(state,{imageSetId});
  return figureImageId;

}

G.getAbsolutePathByAnnotationId = function(state,args){
  let imageSet = G.getImageSetByAnnotationId(state,args);
  let absolutePath = G.getAbsoluteFsPath(state,imageSet);
  let roots = G.getDatatypeSpecificDirectoryRoots(state);
  let pathWithInfo = absolutePath.map(_id => {
    let record = G.getRecord(state,{_id});
    let type = (record.itemType || record.type);
    return {
      _id,
      itemType:type,
      type,
      filesystemName:G.getFilesystemName(state,{_id}),
      isRoot:(_id in roots)
    }
  })
  return pathWithInfo;
}

G.getImageUploadDateByAnnotationId = function(state,{annotationId,toClientDateString}){
  //let atn = G.getRecord(state,{_id:annotationId});

  let figureImageId = G.getImageSetFigureImageIdByAnnotationId(state, {annotationId});

  let { meta } = G.getRecord(state,{_id:figureImageId});

  let { creationDate } = meta;

  if( toClientDateString ){
    return G.getClientDateString(null,creationDate);
  }
  
  return creationDate;

}




G.getImageSetFigureImageDataByAnnotationId = function(state,{annotationId}){
  let figureImageId = G.getImageSetFigureImageIdByAnnotationId(state,{annotationId});
  let imageData = G.getData(state,{itemType:'imageUploads',_id:figureImageId});

  return imageData;
}


G.getFigureImageUrlByAnnotationId = function(state,{annotationId}){
  let figureImageId = G.getImageSetFigureImageIdByAnnotationId(state,{annotationId})
  let url = G.getImageRecordUrl(state,{
    imageId:figureImageId,
  })

  return url;
  //let image = G.getData(state,{itemType:'imageUploads',_id:figureImageId});
  //return image.url;
}

G.getImageDataFromCropId = function(state,cropId){

  let crop = state.data.crops[ cropId ] ;
  let imageId = crop.imageId || state.data.imageSets[ crop.imageSetId ].figureImageId;
  let imageData = state.data.imageUploads[ imageId ];
  return imageData;

}

G.getOriginalImageCropFromCropId = function(state,cropId){
  let imageData = G.getImageDataFromCropId(state,cropId);
  let defaultCropId = imageData.defaultCropId;
  let defaultCrop = state.data.crops[defaultCropId];
  return defaultCrop;
}

G.getLinesByIdMap = function(state){

  let lines = {};
  let labelEntries = Object.values(state.data.crops).forEach( set => {
    lines = {...lines, ...set.lines}
  })
  return lines;
}

G.getLabelsById = function(state,labelIds){

  let labelEntries = Object.values(state.data.crops).map( set => Object.entries(set.labels) ).flat();

  let labels = labelIds.map( id => {
    return labelEntries.find( entry => entry[0] === id )[1]
  })

  return labels;


}

G.getIdToCellMap = function(state,{figurePanelId}){
  let map = {};
  
  let figurePanel = state.data.figurePanels[figurePanelId];


  let grid = figurePanel.grid;
  for(let ii = 0; ii < grid.length; ii++){
    let row = grid[ii];
    for(let jj = 0; jj < row.length; jj++){
      let id = grid[ii][jj];
      if( !(id in map) ){
        map[id] = [ii,jj];
      }
    }
  }
  return map;
}

G.getCropVerticalRange = function(state,cropId){


  let imageSetImageIds = G.getContainingImageSet(state,cropId).images;
  let defaultCropHeights = 
    imageSetImageIds.map(id => {
      let defaultCropId = state.data.imageUploads[id].defaultCropId 
      return state.data.crops[defaultCropId].height;
    })

  let allHeights = new Set(defaultCropHeights);

  /*
  if( allHeights.size > 1 ){
    throw Error("You have an image set with images of different proportions. Each image in an image set must have the same dimensions.");
  }
  */

  let heightProportion = Array.from(allHeights)[0];


  let crop = state.data.crops[ cropId ];
  let scaledHeightProportions = [ crop.top, crop.top + crop.height ];
  let unitProportions = scaledHeightProportions.map( x => x / heightProportion );

  return unitProportions;
}


G.getContainingImageSetId = function(state,cropId){

  return state.data.crops[cropId].imageSetId;
}

G.getContainingImageSet = function(state,cropId){

  let imageSetId = G.getContainingImageSetId(state,cropId);
  return state.data.imageSets[ imageSetId ];

}

G.getBandAnnotationGroupValues = function(state,args){

  let { cropId } = args;


  if( cropId === undefined ){
    throw Error("getBandAnnotationGroupValues args must contain one of cropId or imageSetId");
  }

  //imageSetId = imageSetId || G.getContainingImageSetId(state,cropId);


  let cropRange = [0,1]//cropId ? G.getCropVerticalRange(state,cropId) : [0,1];



  let leftAnnotationValues = G.getBandAnnotationLabels(
    state,{cropId,range:cropRange,labelGroup:"leftLabelGroupId",
      labelIdsOnly:true}
  )

  let rightAnnotationValues = G.getBandAnnotationLabels(
    state,{cropId,range:cropRange,labelGroup:"rightLabelGroupId",
      labelIdsOnly:true}
  )


  return {
    left: {sideRelativeToImage:'left',valueType:'bandAnnotation',labelIds:leftAnnotationValues},
    right:{sideRelativeToImage:'right',valueType:'bandAnnotation',labelIds:rightAnnotationValues}
  }


}

G.getBandAnnotationLabels = function(state, {cropId,range,labelGroup,labelIdsOnly}){

  /*if( labelIdsOnly ){

  }*/

  let crop = state.data.crops[cropId];

  const inRange = line => ( range[0] <= line.position && line.position <= range[1] );

  let labelGroupId = crop[ labelGroup ];




  let labelPositions = {};

  //let thisLines = crop.lines;
  //let parentLines = [];

  Object.values(crop.lines).forEach(line => {
    if( inRange(line) ){
      let labelId = line.labels[ labelGroupId ];
      if( labelId in labelPositions ){
        labelPositions[labelId].push( line.position );
      }else{
        labelPositions[labelId] = [ line.position ]
      }
    }
  })


  let sortedLabelKeys = Object.keys(labelPositions).sort((a,b) => {
    return labelPositions[a].sort()[0] - labelPositions[b].sort()[0]
  });

  if( labelIdsOnly ){
    return sortedLabelKeys;
  }

  let sortedLabels = sortedLabelKeys.map( key => ({
    ...crop.labels[key],
    positions:labelPositions[key],
    _id:undefined,
    lines:undefined
  })
  );

  return sortedLabels;



}


G.getColumnsWidths = function(state,{figurePanelId}){

  
  let figurePanel = G.getFigurePanel(state,{figurePanelId});
  return figurePanel.columnWidths;

}


G.findCellLocations = function(state,{ids,figurePanelId}){
  
  let figurePanel = state.data.figurePanels[figurePanelId];

  let map = {};
  ids.forEach(id => map[id] = []);

  figurePanel.grid.forEach((row,iiRow) => {
    row.forEach((id,iiCol) => {
      if( id in map ){
        map[id].push([iiRow,iiCol])
      }
    })
  })

  return map;

}

G.getCellsMergedWith = function(state,{position,figurePanelId}){
  let [row,col] = position;
  if(!figurePanelId || !position){ throw Error("position AND figurePanelId must both be defined.")}
  let figurePanel = state.data.figurePanels[figurePanelId];

  let grid = figurePanel.grid;
  let id = grid[row][col];

  let mergedCells = [];
  grid.forEach((gridRow,rowIndex) => {
    gridRow.forEach((cellId,colIndex) => {
      if( cellId === id ){
        let sameCell = rowIndex === row && colIndex === col;
        if( !sameCell ){ 
          mergedCells.push( [rowIndex,colIndex] );
        }
      }

    })
  })

  return mergedCells;
}

G.groupAtCell = function(state,cell){
  return G.getCellGroup(state,G.idAtCell(state,cell));
}

G.idAtCell = function(state,cell,figurePanel){
  return figurePanel.grid[cell[0]][cell[1]];
}

G.getCellGroup = function(state,id,figurePanel){
  return figurePanel.cellGroups[ id ];
}

G.getGelLaneCount = function(state,{figurePanelId}){ 
  if(!figurePanelId){throw Error("figurePanelId cannot be undefined.")}

  let figurePanel = G.getData(state,{itemType:'figurePanel',_id:figurePanelId})


  return (
    figurePanel.gelLanesEnd - figurePanel.gelLanesStart + 1 
  )
}

G.canMergeCells = function(state,{figurePanelId,cells}){

  let cellsAreContiguousAndInSingleRowOrColumn = areCellsContiguousAndInSingleRowOrColumn(cells);

  let gelColAndAnnotColBothSelected = areGelColAndAnnotColBothSelected(state,figurePanelId,cells);

  let debugObj = {
    cellsAreContiguousAndInSingleRowOrColumn,
    gelColAndAnnotColBothSelected
  }

  let verdict = (
    cellsAreContiguousAndInSingleRowOrColumn
    //&& 
    //!gelColAndAnnotColBothSelected
  )


  return verdict;

}

function areGelColAndAnnotColBothSelected(state,figurePanelId,cells){

  let [gs,ge] = G.getGelLaneSpan(state,figurePanelId);

  let columnSet = new Set();
  cells.forEach(([row,col]) => columnSet.add(col));
  let selectedCols = Array.from(columnSet);

  const isGelCol = col => (gs <= col && col <= ge)
  let gelColSelected = selectedCols.some(isGelCol);
  let annotationColSelected = cells.some(col => !isGelCol(col));

 return gelColSelected && annotationColSelected;

}


function areCellsContiguousAndInSingleRowOrColumn(cells){

  if( cells.length === 0 ){
    return false;
  }
  let rowIndexesSelected = new Set();
  let colIndexesSelected = new Set();
  cells.forEach(cell => {
    rowIndexesSelected.add(cell[0]);
    colIndexesSelected.add(cell[1]);
  })

  let numRowsSelected = rowIndexesSelected.size;
  let numColsSelected = colIndexesSelected.size;

  let multipleRowsSelected = numRowsSelected > 1;
  let multipleColsSelected = numColsSelected > 1;

  if( multipleRowsSelected && multipleColsSelected ){
    return false;
  }

  //exclusive or!

  if( multipleColsSelected || multipleRowsSelected ){
    let toComp = multipleRowsSelected ? rowIndexesSelected : colIndexesSelected;

    let min = Math.min(...toComp);
    let max = Math.max(...toComp);

    let delta = max - min;

    //only if the difference between max and min
    //are the number of elements in the list 
    //we assume each is distinct, 
    //so if there are (delta+1) elements in the 
    return (delta + 1) === toComp.size;
  }

  return false;

}


G.getTotalWidthOfCellsMergedWith = function(state,{cell,figurePanelId}){ 
  let cellsInGroup = G.getCellsInGroup(state,cell,figurePanelId);
  let columns = Array.from(new Set(cellsInGroup.map(x => x[1])));

  let figurePanel = G.getItem(state,{itemType:"figurePanel",_id:figurePanelId});

  let columnWidths = columns.map(iiCol => figurePanel.columnWidths[iiCol]);

  let columnWidthSum = columnWidths.reduce((a,b) => a+b);

  return columnWidthSum;

}

G.getUnifiedColumnWidths = function(state,selectedCells,figurePanelId){

  let figurePanel = state.data.figurePanels[figurePanelId];
  let columns = selectedCells.map(cell => cell[1])
  let colSet = Array.from(new Set(columns));
  let widths = colSet.map( columnIndex => {

    //let 
    //let summedWidths = G.ge

    return figurePanel.columnWidths[ columnIndex ] 
  });
  let uniqueWidths = Array.from(new Set(widths));

  return Math.min(...uniqueWidths);
  /*
  if( uniqueWidths.length > 1 ){
    return undefined;
  }else if( uniqueWidths.length === 1 ){
    return uniqueWidths[0];
  }*/

}





G.getEvaluatedRegionNodeInfo = function(state,{figurePanelId,cellLocation,nodeId}){

  let cell = G.getCellsValue(state,{figurePanelId,cells:[cellLocation]})[0];

  let node = G.getEvaluatedFigurePanelCellNodeValue(state,{figurePanelId,cellLocation,nodeId});

  let regionId = node.value.regionId;

  let { regions } = cell;

  let annotationId = regions[regionId];

  return { annotationId, regionId };

}


G.isItemPresent = function(state, args){

  return G.doesRecordExistInCache(state,args);

}



function computeEvalutedScalebarProperties(state,{figurePanelId,cellLocation,node,nodeId}){


  const UNRESOLVED_DISTANCE = { label:"? μm", widthAsRegionProportion:0.4 }


  let cellValue = G.getCellsValue(state,{figurePanelId,cells:[cellLocation]})[0];

  let parentNodeId = node.p;

  let { annotationId } = G.getEvaluatedRegionNodeInfo(state,{figurePanelId,cellLocation,nodeId:parentNodeId});

  let atnId = annotationId;

  if( !atnId ){
    return UNRESOLVED_DISTANCE; 
  }else{

    let imageId = G.getImageSetFigureImageIdByAnnotationId(state,{annotationId:atnId});

    let resolution = G.getImageResolution(state,{imageId});

    if( !resolution ){
      return UNRESOLVED_DISTANCE;
    }else{
      let { isotropic, x, y } = resolution;
      if( !isotropic ){
        throw Error("Can't handle anisotropic resolution yet.");
      }else{
        //either we're trying to evaluate width
        //from a set distance
        //or evaluated distance from a set width
        let nodeWidth = node.width;

        let annotation = G.getAnnotation(state,{_id:atnId});
        let { width } = G.getImagePixelDimensionsByAnnotationId(state,{annotationId:atnId});

        let { ls } = annotation;

        let atnLength = distance(...ls);

        let lsLength = (
          atnLength * width / isotropic
        );

        let { value, units } = nodeWidth;

        //if target is percentage, 
        //we need to compute the 
        //displayedWidth
        //else, we know exactly what it is
        //we just need the 

        if( units === "%" ){

          //return the label corresponding
          //to the width

          let lengthAtDefaultWidth = 
            Math.round( 100 * (lsLength * ( value / 100 )) ) / 100;

          //need to compute unit.
          

          return { 
            label:(lengthAtDefaultWidth + " μm"), 
            widthAsRegionProportion: value / 100 
          }

        }else{

          let widthProportion = ( value / lsLength );



          return { 
            widthAsRegionProportion:widthProportion,
            label:`${value} ${units}`
          }


          // length * PIXEL_WIDTH * um / pixel

          //get width corresponding to desired length
          //to do that, we need
          //


        }


      }
    }


  }



  // just compute the 
  // 1) width
  // 2) 

}

function computeEvalutedNodeProperties(state,{figurePanelId,cellLocation,node,nodeId }){

  
  let { type } = node;
  if( type === "scalebar" ){
    return computeEvalutedScalebarProperties(state,{figurePanelId,cellLocation,node,nodeId});
  }
}


function includeVirtualNodes({nodes,regions}){
  // get regions

  let nodesWithVirtualNodes = {
    ...nodes,
  }
  let nonNullRegionsThatAreNotMain = Object.entries(regions).filter(x => x[0]!=="main" && x[1]).map(x => x[0]);

  let regionNodes = Object.entries(nodes).forEach(([nodeId,node]) => {
    if( node.type === "region" ){

      let  { value } = node;

      /*
       When we introduce outlines for
       non main nodes, we need to make sure
       that outlines in subregions are
       hidden by default
      */

      
      if( value.regionId === "main" ){

        nonNullRegionsThatAreNotMain.forEach(regionId => {
          
          let id = `v_p=${nodeId};t=ro;r=${regionId}`;
          let vNode = {
            type:"regionOutline",
            p:nodeId,
            regionId,
          }
          nodesWithVirtualNodes[id] = vNode;

          nodesWithVirtualNodes[nodeId].c = [
            ...nodesWithVirtualNodes[nodeId].c, id
          ]
          


        })
      }
    }
  })

  return nodesWithVirtualNodes;;


}

G.getMicroscopyExpansionNodes = function(state,{figurePanelId,cellLocation}){

  let figurePanel = G.getFigurePanel(state,{figurePanelId});
  let { config } = figurePanel;
  let value = G.getCellsValue(state,{figurePanelId, cells:[cellLocation]})[0];

  let { annotationId, regions, localTemplateId } = value;

  let template = config.templates[localTemplateId];

  let nodeOverrides;
  if( value ){ 
    nodeOverrides = value.nodeOverrides;
    if( !nodeOverrides ){
      nodeOverrides = {};
    }
  }

  let allNodeIds = Array.from(new Set(
    [
      ...Object.keys(template.nodes),
      ...Object.keys(nodeOverrides),
    ]
  ));


  let nodes = Object.fromEntries(allNodeIds.map(nodeId => {
    return [
      nodeId,
      G.getEvaluatedFigurePanelCellNodeValue(state,{
        figurePanelId,
        cellLocation,
        nodeId
      })
    ]
  }))


  let nodesWithVirtualNodes = includeVirtualNodes({nodes,regions});


  return nodesWithVirtualNodes;
}; 


G.getVirtualNodeIds = function(state,{figurePanelId,cellLocation}){

  let nodes = G.getMicroscopyExpansionNodes(state,{figurePanelId,cellLocation});

  let virtualIds = Object.keys(nodes).filter(_id => _id.slice(0,2) === 'v_');

  return virtualIds;

}

function computeVirtualNode(state,{nodeArgs}){
  let { t } = nodeArgs;

  switch(t){
    case "ro":
      return {
        p:nodeArgs.p,
        type:"regionOutline",
        regionId:nodeArgs.r,
        style:{}
      }
    default:
      throw Error("Unrecognized virtual node type "+t);
  }
  

}

function parseVirtualIdParameters(_id){

  let paramsOnly = _id.replace('v_','');
  let keyPairs = paramsOnly.split(';').map(kp => kp.split('='));
  let idData = Object.fromEntries(keyPairs);
  return idData;
}

G.getEvaluatedFigurePanelCellNodeValue = function(state,{figurePanel, figurePanelId,cellLocation,nodeId, computed, excludeVirtualNodes }){

  let resolvedFigurePanel = figurePanel || (
    G.getData(state,{_id:figurePanelId,itemType:FIGURE_PANELS})
  );


  let group = G.getCellGroupByCellLocation(state,{figurePanelId,cell:cellLocation});

  let { localTemplateId, nodeOverrides={} } = group.value;

  let groupTemplate = resolvedFigurePanel.config.templates[localTemplateId];

  let templateNode;
  let node;

  let isVirtualNodeId  = nodeId.slice(0,2) === 'v_';

  if( isVirtualNodeId ){

    let paramString = nodeId.slice(2)
    let nodeIdArgs = parseVirtualIdParameters(paramString)
    node = computeVirtualNode(state,{nodeArgs:nodeIdArgs});

  }else{

    templateNode = groupTemplate.nodes[nodeId];
    node = templateNode || {};

  }


  
  let overrides = nodeOverrides[nodeId];

  
  let nodeOverride = overrideNodes(node,overrides);

  let overridenNode = {
    ...nodeOverride,
    //localTemplateId
  }

  if( !overridenNode ){
  }

  let computedProperties = computeEvalutedNodeProperties(state,{node:overridenNode, figurePanelId,cellLocation, nodeId});

  let { regions } = group.value;

  let nodeWithVirtualAdjustments;

  if( excludeVirtualNodes === true ){
    nodeWithVirtualAdjustments = overridenNode;
  }else{

    nodeWithVirtualAdjustments = includeVirtualNodes({
    nodes:{ [nodeId]:overridenNode },
    regions,
    })[nodeId];
    
  }

  if( computedProperties ){
    nodeWithVirtualAdjustments.computed = computedProperties;
  }

  if( computed ){
    return nodeWithVirtualAdjustments.computed;
  }


  return nodeWithVirtualAdjustments;

}

G.getUnifiedGroupFromNodeValues = function(state,{figurePanelId, nodeDataList}){

  let figurePanel = G.getData(state,{_id:figurePanelId,itemType:FIGURE_PANELS});

  let selectedGroups = nodeDataList.map(nodeData => {
    let { nodeId, cellLocation, localTemplateId } = nodeData;
    if( localTemplateId ){
    }else{
      return G.getEvaluatedFigurePanelCellNodeValue(state,{figurePanel,figurePanelId,nodeId,cellLocation});
    }
  })

  let unifiedGroup =  G.getUnifiedGroupFromGroups(state,selectedGroups);
  return unifiedGroup;


}





G.getUnifiedGroupFromGroups = function(state,selectedGroups){


  let unifiedGroup;

  if( !selectedGroups || selectedGroups.length === 0 ){
    return { style:{}, value:"" };
  }else if( selectedGroups.length === 1 ){
    return selectedGroups[0];
  }

  let valueShouldBeString = false;

  selectedGroups.forEach(group => {
    let flattened = flattenObject(group);
    if( !unifiedGroup ){
      //set it the first time
      unifiedGroup = flattened;
    }else{
      for(let key in unifiedGroup){

        let keyMissing = !(key in flattened);
        let valuesNotEqual = (flattened[key] !== unifiedGroup[key]);
        let shouldDeleteFromUnified = (
          keyMissing || valuesNotEqual
        )

        if( shouldDeleteFromUnified ){
          delete unifiedGroup[key];
          if( key==="value.valueType" ){

            valueShouldBeString = true;
            //this is really a 
            //bad/hacky patch for westernBlot figure data
            //probably the "new" nodes would have types
            //outside the value...
            //or maybe not...
          }
        } else{
          debugger;
        }
      }
    }
  })

  if( !unifiedGroup ){
    debugger;
  }

  let unifiedUnflattenedGroup = unflattenObject(
    unifiedGroup
  );

  delete unifiedUnflattenedGroup._id;
  unifiedUnflattenedGroup._id = undefined;

  if( valueShouldBeString ){
    unifiedUnflattenedGroup.value = "";
  }


  return unifiedUnflattenedGroup;

}


G.getUnifiedGroupFromSelectedCells = function(state,args={}){
  let { figurePanelId, selectedCells } = args;
  
  let figurePanel = state.data.figurePanels[figurePanelId];

  let selectedGroups = selectedCells.map(
    cell => G.getCellGroupByCellLocation(state,{cell,figurePanelId})
  )


  let unifiedGroup = {...G.getUnifiedGroupFromGroups(state,selectedGroups) };
  delete unifiedGroup._id;

  let toReturn = {
    style:{},
    value:"",
    ...unifiedGroup,
    columnWidth: G.getUnifiedColumnWidths(state,selectedCells,figurePanelId)
  };


  return toReturn;



}

G.getSelectedCellsProperty = function(state,{property,figurePanelId}){
  let selectedCells = state.selectedCells;

  let selectedGroups = selectedCells.map(
    cell => G.getCellGroupByCellLocation(state,{cell,figurePanelId})
  )

  let requestedValues = selectedGroups.map(
    group => {
      return group[property] || group.style[property];
    }
  )

  return requestedValues;

}

G.getCellGroupByCellLocation = function(state,{cell,figurePanelId}){
  if(!cell){
    throw Error("G.getCellGroupByCellLocation: cell is undefined.");
  }

  if( !figurePanelId ){
        throw Error("You must pass figurePanelId.");
  }
  let figurePanel = state.data.figurePanels[figurePanelId];

  let row = figurePanel.grid[ cell[0] ];
  if( !row ){

  }
  let _id = figurePanel.grid[ cell[0] ][ cell[1] ];
  let cellGroup = figurePanel.cellGroups[ _id ];

  return cellGroup;
}

G.getGridLayoutObject = function(state,{cell,figurePanelId}){
  /* gives us {x,y,width,height} which tells us
   * where the merged cells starts (x,y) and its dimensions (w,h)
   */
  if( !figurePanelId ){
        throw Error("You must pass figurePanelId.");
  }
  let figurePanel = state.data.figurePanels[figurePanelId];


  let [row,col] = cell;
  let grid = figurePanel.grid;

  let cellId  = grid[row][col];

  let startX = col;
  let startY = row;
  let height = 1;
  let width = 1;

  let isPartOfHorizontalMerge = (
    grid[row][col-1] === cellId || grid[row][col+1] === cellId
  );

  let isPartOfVerticalMerge = (
    (grid[row-1] && grid[row-1][col]) === cellId || 
    (grid[row+1] && grid[row+1][col]) === cellId
  )


  if( isPartOfHorizontalMerge ){

    while(grid[row][startX-1] === cellId){
      startX--; width++;
    }

    while(grid[row][startX+width] === cellId){
      width++;
    }

  }else if( isPartOfVerticalMerge ){

    while((grid[startY-1] && grid[startY-1][col]) === cellId){
      startY--; height++;
    }

    while((grid[startY+height] && grid[startY+height][col]) === cellId){
      height++;
    }

  }

  return { x:startX, y:startY, h:height, w:width }

}

function getGridLayoutDataArrangedIntoRows(layoutData){

  
  let currentY = 0;
    let allRows = [];
    let currentRow = [];
    layoutData.forEach( gridItem => {
      if( gridItem.y === currentY ){
        currentRow.push( gridItem )
      }else{
        allRows.push(currentRow);
        currentRow = [ gridItem ];
        currentY = gridItem.y;
      }
    })
    if( layoutData.length > 0 ){
      allRows.push(currentRow);
    }
    return allRows;

}

G.getGridItemIndexFromLocation = function(state,args){
  let { figurePanelId, rowArrangedGridData, cell } = args;
  if( !rowArrangedGridData ){
    rowArrangedGridData = G.getCompleteGridLayoutData(state,{figurePanelId});
  }
  let location = cell;
  let rowArranged = rowArrangedGridData;

  let [y,x] = location;
  if(isNaN(y)){

  }
  let testGridRow = rowArranged[y];
  if( testGridRow ){
    let testGridItem = testGridRow[x];
    if( testGridItem && testGridItem.x === x && testGridItem.y === y ){
      return [y,x];
    }else{
      for(let ii = 0; ii < rowArranged.length; ii++){
        for(let jj = 0; jj < rowArranged[ii].length; jj++){
          let gridItem = rowArranged[ii][jj]
          let inXBounds = gridItem.x <= x && x <= gridItem.x + gridItem.w - 1;
          let inYBounds = gridItem.y <= y && y <= gridItem.y + gridItem.h - 1;

          if( inXBounds && inYBounds ){
            return [ii,jj]
          }
        }
      }
    }
  }else{
    throw Error("No grid row geometries computed for row, "+y+".");
  }

  //else?


}

G.getCompleteGridLayoutData = function(state,args={}){
  let idsSeen = new Set();
  let figurePanelId = args.figurePanelId;
  if( !figurePanelId ){
        throw Error("You must pass figurePanelId.");
  }
  let figurePanel = state.data.figurePanels[figurePanelId];

  let grid = figurePanel.grid;
  let gridItems = []; 

  grid.forEach((row,rowIndex) => {
    row.forEach((id,colIndex) => {
      if( !idsSeen.has(id) ){
        idsSeen.add(id);
        let cell = [rowIndex,colIndex];
        let gridLayoutObject = G.getGridLayoutObject(state,{cell,figurePanelId});
        //now we need the object data
        let cellGroupData = figurePanel.cellGroups[id];

        let allData = {
          i:id,
          ...gridLayoutObject,
          data:cellGroupData
        }

        gridItems.push( allData );
      }
    })
  })

  return getGridLayoutDataArrangedIntoRows(gridItems);

}




G.getCropsFromImageSetAnnotations = function(state,{imageSetId}){
  let atns = G.getAnnotationsByImageSetId(state,imageSetId);
  let crops = atns.map(atn => G.getCropFromAnnotation(state,atn._id));

  return crops;
}

G.getRelativeLsAnnotationsBoundedByAnnotationId = function(state,{annotationId}){
  
  //trying to get crops and LS relative to the specified region
  //we also want to filter by what's actually inside that region

  let imageSet = G.getImageSetByAnnotationId(state,{annotationId});
  let imageSetHeight = G.getImageSetHeight(state,imageSet._id);

  let atn = G.getData(state,{type:ANNOTATIONS,_id:annotationId});

  let siblingAnnotations = G.getAnnotationsByImageSetId(state,imageSet._id);
  
  let lsVec = minus(atn.ls[1],atn.ls[0])
  let lsLength = distance(...atn.ls); 
  let angle = vectorAngleRad(lsVec);

  //let internalRelativeLs = {};

  let defaultImageBoundsCrop = { width:1, height:imageSetHeight, top:0, left:0 }

  let rotatedParentLs = rotatePointsAboutCentroid({
      bounds:defaultImageBoundsCrop,
      pointsList:atn.ls,
      angle:-angle 
  });

  let parentCrop = G.getCropFromAnnotation(state,annotationId,{
    //ls:rotatedParentLs
  })


  let internalRelativeLs = siblingAnnotations.map(siblingAtn => {
    if( siblingAtn._id === annotationId ){
      return;
    }

    let rotatedLs = rotatePointsAboutCentroid({
      bounds:defaultImageBoundsCrop,
      pointsList:siblingAtn.ls,
      angle:-angle
    })

    let translatedLs = rotatedLs.map(point => {
      return [(
        point[0] - parentCrop.left
      ), 
      (
        point[1] - parentCrop.top
      )]
    })


    let lsInsideRotatedParent = (
      translatedLs.every(point => {
        let inXBounds = parentCrop.width >= point[0] && 0 <= point[0];
        let inYBounds = parentCrop.height >= point[1] && 0 <= point[1];
        return inXBounds && inYBounds;
      })
    )

   

    if( lsInsideRotatedParent ){

      let translatedAndScaled = translatedLs.map(y => y.map(x => x/lsLength));
      return {
        _id:siblingAtn._id,
        ls:translatedAndScaled,
        height:siblingAtn.height / lsLength,
        heightGroupId:"__custom",

      }
    }
  }).filter(x => x);
  
  return internalRelativeLs;

}


G.getAbsoluteLsGeometry = function(_,{transformedGeometry, transformation, imageSetHeight }){


  console.log({
    transformedGeometry,
    transformation,
  })
  /* 
   * take transformed coordinates and resolve the
   * the coordinates to be relative to the 
   * imageSet crop
   */

  let { ls, height } = transformedGeometry;

  //let lsLength = distance(...ls);


  let tfWidth = transformation.width;
  let tfRotation = transformation.rotation;
  let tfTop = transformation.top;
  let tfLeft = transformation.left;

  let absoluteHeight = tfWidth * height;

  //console.log({transformation});

  let scaledAndTranslatedLs = ls.map(point => {
    return [(point[0]* tfWidth +tfLeft), (point[1]*tfWidth + tfTop)]
  })

  //console.log({scaledAndTranslatedLs});

  let absoluteLs = rotatePointsAboutCentroid({
    bounds:{
      top:0,
      left:0,
      width:1,
      height:imageSetHeight
    },
    pointsList:scaledAndTranslatedLs,
    angleDegrees:tfRotation,
  })

  //console.log({absoluteLs});


  return {
    ls:absoluteLs.map(p => p.map(cc => Number(Number(cc).toFixed(5)))),
    height:Number(Number(absoluteHeight).toFixed(5))
  }



}

G.getCropFromAnnotation = function(state,annotationId,annotationOverride){
  if( typeof(annotationId) === typeof({})){
    annotationId = annotationId.annotationId;
  }
  annotationOverride = annotationOverride||{};

  let atn = state.data.annotations[annotationId] || {};

  atn = { ...atn, ...annotationOverride }


  let imageSetId = atn.imageSetId;
  let imageSet = state.data.imageSets[imageSetId];
  if(!imageSet){ return null; }

  let figureImageId = imageSet.figureImageId;
  let imageUpload = G.getImage(state,{imageId:figureImageId});

  if( !imageUpload ){ 
    debugger;
  }

  let rawCrop = {
    width:1,
    height:imageUpload.height,
    left:0,
    top:0
  }

  //let ls = annotationOverride.ls || atn.ls;


  let lsVec = minus(atn.ls[1],atn.ls[0])
  let angle = vectorAngleRad(lsVec)

  if( lsVec.every(x => x < 0) ){
    angle -= Math.PI;

  }

  let angleDeg = 180 * angle / Math.PI;


  let rotatedPoints = rotatePointsAboutCentroid({
    pointsList:atn.ls,
    bounds:rawCrop,
    angle:-angle
  })

  let ys = rotatedPoints.map(pts => pts[1])
  let xs = rotatedPoints.map(pts => pts[0])

  let heightGroupId = atn.heightGroupId;
  let height;

  if( heightGroupId === "__custom" ){
    height = atn.height;
  }else{
    height = state.heightGroups[heightGroupId].height;
  }

  let top = Math.min(...ys) - height/2;
  let left = Math.min(...xs);

  let width = distance(...atn.ls);


  let inlineCrop = {
    rotation:angleDeg,
    width,
    height,
    top,
    left,
    imageId:figureImageId,
    imageSetId,
    annotationId
  }

  return inlineCrop;




}

G.getImageSetChildCrops = function(state,imageSetId){
  let crops = state.data.crops;
  let imageSetCropChildren = Object.values(crops)
    .filter(crop => crop.imageSetId === imageSetId);

  return imageSetCropChildren;
}


G.getImageSetCropInfoByImageSetId = function(state,imageSetId){
  let cropInfoList = G.getImageSetCropInfo(state);
  return cropInfoList.find(info => info.imageSetId === imageSetId)
}

G.getImageSetCropInfo = function(state,arg){






  let imageSetIdMap = {};

  Object.values(state.data.crops).forEach(crop => {

    if( crop.fullImage ){
      return;
    }
    else if( crop.imageSetId in imageSetIdMap ){
      imageSetIdMap[crop.imageSetId].push(crop._id);
    }else{
      imageSetIdMap[crop.imageSetId] = [ crop._id ];
    }
  })




  let data =  Object.entries(state.data.imageSets).map(entry => {

    let imageSetId = entry[0];
    let rawUploadCropIds = entry[1].images.map(imageId => {
      return state.data.imageUploads[imageId].defaultCropId
    })
    let cropIds = imageSetIdMap[ imageSetId ] || [];

    return ({
      imageSetId, rawUploadCropIds, cropIds
    })
  })

  if( arg === undefined ){
    return data;
  }else if( !isNaN(arg) ){
    return data[arg]
  }else if( typeof(arg) === 'string' ){
    return data.find(dat => dat.imageSetId === arg);
  }else{
    throw Error("Unexpected arg: " + JSON.stringify(arg));
  }

}

G.getImageUploadByIdDisplayOrder = function(state, index){
  let imageUploads = Object.values(state.data.imageUploads);

  let content = imageUploads.map( uploadObject => {
    let imageId = uploadObject._id;
    let defaultCropId = uploadObject.defaultCropId;

    let childCropIds = Object.values(state.data.crops).filter( crop => {
      return crop.parentImageId === imageId && crop.fullImage !== true 
    }).map( crop => crop._id );

    return {
      defaultCropId,
      filename:uploadObject.filename,
      cropIds:childCropIds
    }

  })


  return index !== undefined ? content[index] : content;


}

G.getValueLabel = function(state,{value}){

  let objectIdReferencedInValue = (
    value.imageSetId || value.annotationId
  ) 

  let objectData = G.getData(state,{_id:objectIdReferencedInValue});

  let label = objectData.label;
  return label;


}

G.getRowProperty = function(state,{rowIndex,property,figurePanelId}){
  
  if(!figurePanelId){ throw Error("figurePanelId cannot be undefined.");
  } 

  let row = G.getRow(state,{rowIndex,figurePanelId});


  let collapsed = collapseRepeatedIds(row);

  return collapsed.map( cellId => {
    let cell = state.data.figurePanels[figurePanelId].cellGroups[cellId];
    if( Array.isArray(property) ){
      return property.reduce((object,pathElement)=>object[pathElement],cell);
    }else if( typeof(property) === 'string' ){
      return cell[ property ] 
    }
  })
}


G.getNumberOfNewCellsBySettingLaneCount = function(state,newLaneCount){
  let [rows,cols] = G.getTableDimensions(state);
  if( rows === 0 ){
    return newLaneCount + 5;
    // we'll make 2 rows:
    //  row 1: 2 cells on either side of the newLaneCount number of cells.
    //  row 2: 2 cells on either side of an imageTemplate row
  }else{

    throw Error("Can't do this on a non-empty grid.");

    //we keep track of span of which column the gel lanes start.
    //this allows us to easily determine whether
    //the row should get a new cell Id or if it would just merge with
    //the current row (like if it's an image.

    //this is different than merely adding a column.
    //currently though, we won't support this
    //because it's not absolutely essential
    //to getting those dopamine levels
    //skyrocketing and validating that I'm moving forward
    //and getting close to this ultimate goal.


  }
}


G.getThreadMessageOrder = function(state,args){
  let { threadId } = args;
  let thread = state.threads[threadId];
  
  if( !thread ){
    return [];
  }

  let messages = Object.values(
    thread.messages
  )

  let personId = state.personId || args.fromPerspectiveOf;

  const messageSorter = (a,b) => {
    let personId = state.personId;

    let aIsFromThisUser = a.from === personId;
    let bIsFromThisUser = b.from === personId;

       
    let aSent = a.sentTimestamp;
    let aDeliveredToThisUser = a.delivered[personId];

    let bSent = b.sentTimestamp;
    let bDeliveredToThisUser = b.delivered[personId];

    if( aIsFromThisUser && bIsFromThisUser ){
      return aSent - bSent;

    }else if(aIsFromThisUser && !bIsFromThisUser){
      return aSent - bDeliveredToThisUser;

    }else if(!aIsFromThisUser && bIsFromThisUser){
      return aDeliveredToThisUser - bSent;

    }else if(!aIsFromThisUser && !bIsFromThisUser){
      let cmp = (aDeliveredToThisUser - bDeliveredToThisUser);
      if( cmp ){
        return cmp;
      }else{
        return aSent - bSent;
      }
    }
  }

  let sortedMessages = messages.sort(messageSorter); 
  
  return sortedMessages.map(x => x._id);

}

G.getTableGroupingDisplay = function(state,{figurePanelId,rowIndex}){
  if( !figurePanelId ){
        throw Error("You must pass figurePanelId.");
  }


  if( !figurePanelId ){
    throw Error("You must pass figurePanelId to get a figure's table grouping display!");
  }

  if( isNaN(rowIndex) ){
    throw Error("You must pass a rowIndex arg.");
  }


  

  let outputOrder = [0,1,2,3,4,5,6,7,8,9,
    ...Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
  ]

  let map = {};
  let output = [];


  let table = state.data.figurePanels[figurePanelId].grid;
  
  for( let iiRow = 0; iiRow < table.length; iiRow++ ){
    let outputRow = [];
    for(let iiCol = 0; iiCol < table[iiRow].length; iiCol++){

      let id = table[iiRow][iiCol];
      if( !( id in map )){
        map[id] = outputOrder[0];
        outputOrder.splice(0,1);
      }

      outputRow.push(map[id]);

    }
    output.push(outputRow);
  }

  return output[rowIndex];

}

G.getGelLaneSpan = function(state,figurePanelId){

  if( !figurePanelId ){
        throw Error("You must pass figurePanelId.");
  }
  
  let figurePanel = state.data.figurePanels[figurePanelId];
  return [ figurePanel.gelLanesStart, figurePanel.gelLanesEnd ];
}

G.getCellWarnings = function(state,value){
  if( value.annotationId ){
    let isAtnArchived = G.isArchived(state,{_id:value.annotationId,itemType:ANNOTATIONS})
    if( isAtnArchived ){
      return [
        "cropWindowArchived"
      ]
    }

  }

  return undefined;
}

G.getCellsValue = function(state,args){
  let formatForUi = args.format === 'ui';
  let figurePanelId = args.figurePanelId;
  if( !figurePanelId ){
      figurePanelId = state.selectedFigurePanelId;
      if( !figurePanelId ){
        throw Error("You must pass figurePanelId.");
      }
  }
  let figurePanel = state.data.figurePanels[figurePanelId];
  let grid = figurePanel.grid;
  let cells = args.cells;
  let values = cells.map(cell => {
    let cellId = grid[cell[0]][cell[1]]
    let cellGroupInfo = figurePanel.cellGroups[cellId];
    let value = cellGroupInfo.value;
    if( args.placeholder ){
      value = cellGroupInfo.placeholder;
    }

    let warnings;
    if( formatForUi ){
      warnings = G.getCellWarnings(state,value);
    }
    if( typeof(value) === typeof("") ){
      return value;
    }else if( typeof(value) === typeof({}) ){
      let toReturn = {...value,warnings};
      if( toReturn.warnings === undefined ){
        delete toReturn.warnings;
      }
      return toReturn;
    }

    
  });

  return values;

}

G.getUniqueCellGroupCount = function(state,{figurePanelId}){
  if(!figurePanelId){ if(state.selectedFigurePanelId){figurePanelId=state.selectedFigurePanelId}else{throw Error("figurePanelId cannot be undefined."); }}
  let figurePanel = state.data.figurePanels[figurePanelId];
  let cellGroups = figurePanel.cellGroups;
  let groupCount = Object.keys(cellGroups).length;
  return groupCount;

}


G.getTableDimensions = (state,figurePanelId) => {

  if(!figurePanelId){ if(state.selectedFigurePanelId){figurePanelId=state.selectedFigurePanelId}else{throw Error("figurePanelId cannot be undefined.");} }

  let figurePanel = state.data.figurePanels[figurePanelId];

  let grid = figurePanel.grid;
  let rowCount = grid.length;
  if( rowCount === 0 ){
    return [0,0]
  }

  return [rowCount,grid[0].length]

}

G.getCellsInGroup = function(state,cell,figurePanelId){

  let figurePanel = state.data.figurePanels[figurePanelId];

  let [ row, col ] = cell;

  let idOfCell = figurePanel.grid[row][col];

  let adjacentCells = [];
  figurePanel.grid.forEach( (row,rowIndex) => {
    row.forEach( (id, colIndex) => {
      if( id === idOfCell ){
        adjacentCells.push( [ rowIndex, colIndex ] );
      }
    })
  })

  return adjacentCells;

}

G.getFigurePanel = function(state,args){
  
  if(!args){

  }
  let { figurePanelId } = args;
  figurePanelId = figurePanelId || args;
  if( !figurePanelId ){
    throw Error("figurePanelId required.");
  }


  let item = G.getData(state,{itemType:'figurePanels', _id:figurePanelId});
  return item;
}

G.getFigurePanelGrid = function(state,figurePanelId){
 
  let { grid } = G.getFigurePanel(state,figurePanelId);
  return grid;

}



G.getGridValues = function(state,figurePanelId){
  if(!figurePanelId){ if(state.selectedFigurePanelId){figurePanelId=state.selectedFigurePanelId}else{throw Error("figurePanelId cannot be undefined.");} }
  let figurePanel = state.data.figurePanels[figurePanelId];


  return figurePanel.grid.map( row => {
    return row.map( id => {
      return figurePanel.cellGroups[ id ]
    })
  })
}

G.getRow = function(state,{rowIndex,figurePanelId}){
  if(!figurePanelId){ if(state.selectedFigurePanelId){figurePanelId=state.selectedFigurePanelId}else{throw Error("figurePanelId cannot be undefined.");} }

  let figurePanel = state.data.figurePanels[figurePanelId];
  let row = figurePanel.grid[ rowIndex ];
  return row;

}

G.getColumnsWithCellsMergedBetweenRows = function(state,rowIndexOne,rowIndexTwo,figurePanelId){
  if(!figurePanelId){ if(state.selectedFigurePanelId){figurePanelId=state.selectedFigurePanelId}else{throw Error("figurePanelId cannot be undefined.");} }



  let rowOne = G.getRow(state,{rowIndex:rowIndexOne,figurePanelId});
  let rowTwo = G.getRow(state,{rowIndex:rowIndexTwo,figurePanelId});

  let commonCols = [];
  let commonIds = [];

  for(let col = 0; col < rowOne.length; col++){
    let r1c = rowOne[col];
    let r2c = rowTwo[col];
    if( r1c === r2c ){
      commonCols.push(col);
      commonIds.push(r1c);
    }
  }

  return { columnIndices:commonCols, groupIds:commonIds }




}

G.getGlobalConfig = function(state,keys){
  return keys.map(k => state[k]);
}

G.getValidationTemplateCount = function(state,args){
  let validation = state.data['antibodyValidations'][args.validationId];
  return validation.templates.length;
}

G.getDataItems = function(state,type){
  return Object.values(state.data[type]);
}

G.getRequestStatus = function(state,args){
  let { requestId } = args;
  return state.requests.sync[requestId].updates.slice(-1)[0].status;

}

G.getByPath = function(state,path){
  let curObj = state;
  for(let key of path){
    let nextObj = curObj[key];
    if( nextObj ){
      curObj = nextObj;
    }else{
      throw Error("Could not retrieve value from path, got stuck on `"+key+"`");
    }
  }
  return curObj;
}

G.getClientDate = function(state,format){
  
  return Date.now()
}

export default G;
