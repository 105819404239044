import { 
  midpoint,
  scale,
  unit,
  add,
  unitPerp,
  minus,
  distance,
  angle,
  vectorAngleDegrees,
  rotatePointsAboutCentroid, 
  getPointsAsWidthProportions, 
  getPointsFromProportions } from './Geometry';

import DeleteAnnotationButton from './DeleteAnnotationButton';
import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import MarkLabel from './MarkLabel';

export default function LineSegment({setFocusedAnnotationId, defaultCropHeight,annotation,imgWidth,onDelete, isFocusedAnnotation, lsStyle, highlight, strokeColor, 
  guidelineType,
  guidelineSpec
}){


  let { rect, mark, ls, _id } = annotation;

  let x1,y1,x2,y2,stroke;
  let whiskers = [];
  let deleteButton;
  let outlineBox;
  let scaledLs;

  if(rect){

    console.log("Rect: " + JSON.stringify(rect));

    let scaledPointPath = rect.map(scale(imgWidth));

    let d = "M " + scaledPointPath.map(point => point.join(" ")).join(" L") + " Z";

    //alert("I did find a rect: " + JSON.stringify(rect));

    return <path id={"crop-box-preview"} d={d} class="lane-box" fill={"lime"} stroke={"blue"} strokeWidth={1}/>




  }else if( mark ){

    return <MarkLabel setFocusedAnnotationId={setFocusedAnnotationId} onDelete={onDelete} annotation={annotation} imgWidth={imgWidth} isFocusedAnnotation={isFocusedAnnotation}/>

  }
  else if( annotation._id !== undefined && annotation.ls ){


    //if(!annotation.p2){ debugger; }


    stroke= isFocusedAnnotation ? 'blue' : 'orange';
    scaledLs = annotation.ls.map(scale(imgWidth));

    [x1,y1] = scaledLs[0];
    [x2,y2] = scaledLs[1];

    let propOfLine = 1;

    let cx = (1-propOfLine) * x1 + propOfLine * x2;
    let cy = (1-propOfLine) * y1 + propOfLine * y2;


    deleteButton = <DeleteAnnotationButton {...{
      cx,cy, r:10, annotationId:annotation._id,
      onDelete
    }}/>






  }else{

    if(!annotation.ls){
      throw(JSON.stringify(annotation));
    }

    scaledLs = annotation.ls.map(scale(imgWidth));

    [x1,y1] = scaledLs[0];
    [x2,y2] = scaledLs[1];


    stroke=strokeColor || 'blue';

    let lineVector = (minus([x2,y2],[x1,y1]))
    let whiskerDirection = unitPerp(lineVector);
    let whiskerLen = 10;

    let halfWhiskerVector = scale(0.5*whiskerLen,whiskerDirection);
    let startWhiskerStart = add([x1,y1],halfWhiskerVector); 
    let startWhiskerEnd = minus([x1,y1],halfWhiskerVector);

    let endWhiskerStart = add([x2,y2],halfWhiskerVector);
    let endWhiskerEnd = minus([x2,y2],halfWhiskerVector);

    whiskers = [
      <line strokeWidth={1} stroke={stroke} x1={startWhiskerStart[0]} y1={startWhiskerStart[1]} x2={startWhiskerEnd[0]} y2={startWhiskerEnd[1]}/>,

      <line stroke={stroke} strokeWidth={1} x1={endWhiskerStart[0]} y1={endWhiskerStart[1]} x2={endWhiskerEnd[0]} y2={endWhiskerEnd[1]}/>

    ]
  }

  let box;
  let strokeWidth = 1.5;


 
  let defaultDemoHeight = 20;

  let angle = (vectorAngleDegrees(minus(...scaledLs)));
  let rotation = "rotate("+[angle,...scaledLs[0]].join(',')+")";
  let translate = "translate(0,"+-(defaultCropHeight||defaultDemoHeight)/2+")";
  let transformation = rotation+" "+translate;


  if( true || annotation.choice === true ){
    strokeWidth = 3;  
    outlineBox = (
      <rect
        x={x1} y={y1} width={distance(...scaledLs)}
        height={defaultCropHeight}
        stroke={highlight?.includes(annotation.label)?"lime":"orange"}
        fill="none"
        strokeWidth="3"
        transform={transformation}
      />
    )


    //defaultCropHeight


  }


  box = highlight && (
    <rect
      x={x1} y={y1} 
      width={distance(...scaledLs)}
      height={defaultDemoHeight}
      stroke={highlight?.includes(annotation.label)?"lime":"orange"}
      fill="none"
      strokeWidth="3"
      transform={transformation}
    />
  )

  
  let fw = guidelineSpec?.w || 1;
  let fh = guidelineSpec?.h || 1;

  let ratio = fh/fw;

  let maxFDist = distance(...scaledLs);

  let fixedBox = (
      <rect
        x={x1-(maxFDist/2)} y={y1-(ratio*maxFDist)/2} 
        width={maxFDist}
        height={(maxFDist * ratio)}
        stroke={highlight?.includes(annotation.label)?"lime":"red"}
        fill="none"
        strokeWidth="3"
        transform={rotation}
      />
  )




  return (
    <>

      {lsStyle==="box" && box}
      {outlineBox}
      {guidelineType==="fixedRatio" && fixedBox}
      {whiskers}

      <line {...{x1,y1,x2,y2,stroke, strokeWidth}}/>



      {deleteButton}
    </>
  )
}
