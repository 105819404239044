import { useRef, useEffect, useState } from 'react';
import QuantificationLaneBoxContainer from './QuantificationLaneBoxContainer';
import { useDispatch } from 'react-redux';
import G from './Getters';
import ImageCroppingContainer from './ImageCroppingContainer';
import CropWrapper from './CropWrapper';
import { ANNOTATION_IMAGE_ID } from './UIConstants';
import usePresentSelector from './usePresentSelector';
import { scale, distance } from './Geometry';
import A from './ActionCreators';
import LaneSqueezerHandle from './LaneSqueezerHandle';


export function bump({laneBoundaryPositions,leftBump,rightBump,dist,currentLeftMostPosition,currentLength}){


  let newLength = currentLength - leftBump - rightBump;
  let newLeft = currentLeftMostPosition + leftBump;

  //let resolvedLeft = left || 0;
  //let resolvedRight = right || 0;
  //const newLen = 1 - right - left;
  return laneBoundaryPositions.map( x => {

    let proportionOfCurrentRange = ( 
      x - currentLeftMostPosition
    ) / currentLength;

    return ( proportionOfCurrentRange * newLength + newLeft );

  })


  //return (left + (x/lsDistance)*newLen) * lsDistance;
}


export default function LaneAdjuster({annotationId,tempProperties,
  activeUpdatePreviewProperty,
  imageVersion,

}){

  const useNonQuantificationCrop = activeUpdatePreviewProperty === "window";
  const useNonQuantificationImage = activeUpdatePreviewProperty === "imageId";

  const useGrayscaleImage = activeUpdatePreviewProperty === "grayscaleImage";

  //tempProperties/updatePreview should have info about
  //what to use: quantificationAnnotation or the "preview" version.

  let dispatch = useDispatch();

  let [imageContainerBounds,setImageContainerBounds] = useState();

  const [ leftSqueezeXStart, setLeftSqueezeXStart ] = useState(null);

  const [ rightSqueezeXStart, setRightSqueezeXStart ] = useState(null);

  const [ leftBump, setLeftBump ] = useState(0);
  const [ rightBump, setRightBump] = useState(0);


  //const state = useSelector(state => state);


  let previewContainerRef = useRef();
  let previewRef = useRef();

  let fullImageWidth = imageContainerBounds?.width;


  let { atn,ls, inlineCrop,evaluatedLaneOffsets,annotationHeight, quantifications, 
    imageId, imagePresent } = usePresentSelector(state => {

      let atnPropsForLs = G.getAnnotationProperties(state,{_id:annotationId,forQuantification:true,properties:["ls","imageId"]})
      let { ls } = atnPropsForLs;


      const forQuantification = !useNonQuantificationImage;

      let atnPropsForImageId = G.getAnnotationProperties(state,{_id:annotationId,forQuantification,properties:["ls","imageId"]})
      let { imageId } = atnPropsForImageId;
      



      //let imageId = G.getImageSetFigureImageIdByAnnotationId(state,{annotationId});
      let imagePresent = G.isImagePresent(state,{imageId,version:(imageVersion||"raw-png")});
      if( process.env.NODE_ENV === "development" ){
        if( !imagePresent && (!imageVersion || imageVersion === "raw-png")){
          imagePresent = G.isImagePresent(state,{imageId,version:"raw"});

        }
      }


      let atn = G.getData(state,{itemType:'annotations',_id:annotationId});
      

      let { quantifications } = atn;
      let _id = annotationId;

      let cropFromAnnotationOverride = 
        useNonQuantificationCrop ? {} : (atn.quantificationAnnotation||{});

      /*
      if( useNonQuantificationCrop ){
        //debugger;
      }
      */

      let inlineCrop= G.getCropFromAnnotation(state,atn._id,{
        ...(cropFromAnnotationOverride)
      })

      /*
    if( quantAtn ){
      inlineCrop.ls = quantAtn.ls;
      inlineCrop.height = quantAtn.height;

    }*/
      let evaluatedLaneOffsets = G.getLaneLineOffsetEvaluations(state,{_id, temporarySpacingFactorOverride:tempProperties.temporaryLaneSpacing});

      let annotationHeight = inlineCrop.height;//G.getAnnotationHeight(state,_id);

      return {
        atn, 
        inlineCrop, 
        evaluatedLaneOffsets,
        annotationHeight,
        quantifications, 
        ls,
        imagePresent,
        imageId
      }
    },[activeUpdatePreviewProperty])







  let dist = distance(...ls);
  let scaleFactor = fullImageWidth / dist;


  let currentLeftMostPosition = evaluatedLaneOffsets[0][0];
  let currentRightMostPosition = evaluatedLaneOffsets.slice(-1)[0][1];
  let currentLength = (currentRightMostPosition - currentLeftMostPosition);

  //let widthBetweenSqueezeLimits = (rightSqueezeLimitPrortionOfWidth - leftSqueezeLimitProportionOfWidth) * fullImageWidth;


  function onMouseUp(e){

    if( leftSqueezeXStart !== null || rightSqueezeXStart !== null ){
      let mouseUpX = e.clientX;

      let curRightBump = rightBump;
      let curLeftBump = leftBump;
      let newLaneBoundaryPositions = evaluatedLaneOffsets.map(laneOffsets => bump({laneBoundaryPositions:laneOffsets,leftBump,rightBump,currentLength,currentLeftMostPosition}));

      setLeftBump(null);
      setLeftSqueezeXStart(null);
      setRightBump(null);
      setRightSqueezeXStart(null);

      dispatch(A.setAnnotationProperties({
        _id:atn._id,
        laneBoundaryPositions:newLaneBoundaryPositions
      }))



    }
  }

  function onMouseMove(e){
    //let mouseX = e.clientX;


    if( !imageContainerBounds ){
      return;
    }

    let imgContainerLeft = imageContainerBounds.left

    let imgContainerRight = imageContainerBounds.left + imageContainerBounds.width;

    let clientX = e.clientX;
    if( leftSqueezeXStart !== null ){




      let mouseX = Math.max(clientX, imgContainerLeft); 

      let changeFromSqueezeStartToCurrent = ( mouseX - leftSqueezeXStart );
      let proposedBump = changeFromSqueezeStartToCurrent / fullImageWidth * dist;

      if( (currentLeftMostPosition + proposedBump) < currentRightMostPosition ){

        console.log({proposedBump, imgContainerLeft, imgContainerRight, clientX, changeFromSqueezeStartToCurrent, currentLeftMostPosition});
        setLeftBump(proposedBump);
      }


      //let bumpAccountingForRightLimit = Math.min( proposedBump, 1-rightSqueezeLimit )



    }else if( rightSqueezeXStart !== null ){

      //let icbRight = imageContainerBounds.right

      let mouseX = Math.min(e.clientX,imgContainerRight);
      let changeFromSqueezeStartToCurrent = ( rightSqueezeXStart - mouseX );
      let proposedBump = changeFromSqueezeStartToCurrent / fullImageWidth * dist;
      //let rightBumpLimit = 1 - leftSqueezeLimit;

      //let bumpAccountingForLeftLimit = Math.max( proposedBump, rightBumpLimit );
      if( (currentRightMostPosition-proposedBump) > currentLeftMostPosition ){

        console.log({proposedBump, imgContainerLeft, imgContainerRight, icbWidth:imageContainerBounds.width, clientX, changeFromSqueezeStartToCurrent, currentLeftMostPosition});

        setRightBump(proposedBump);
      }
    }
  }

  //let scaledLanes = evaluatedLaneOffsets.map(lane => lane.map(x => x*scaleFactor));
  let scaledAnnotationHeight = annotationHeight * scaleFactor;

  useEffect(() => {
    const onResize = () => {

      let bounds = previewContainerRef.current && previewContainerRef.current.getBoundingClientRect();
      //console.log("Setting image container bounds...");
      //console.log(bounds);
      setImageContainerBounds(bounds);

    }
    window.addEventListener('resize',onResize);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('resize',onResize);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);


    }
  })





  useEffect(() => {

    let bounds = previewContainerRef.current && previewContainerRef.current.getBoundingClientRect();
    //console.log("Setting image container bounds...");
    //console.log(bounds);
    setImageContainerBounds(bounds);
  },[]);



  //const leftBump = 0.02;
  //const rightBump = 0.0275;


  





  let bumps = (evaluatedLaneOffsets.map(offset => bump({laneBoundaryPositions:offset, leftBump, rightBump, currentLeftMostPosition, currentLength })));




  return (




    <div style={{position:'relative', 
      //border:'3px solid red',
      width:'100%',
    }} ref={el => {

      previewContainerRef.current = el;

      //console.log("Re-rendered quant crop box ("+annotationId+")");
      if( el ){
        //previewContainerRef.current = el;
        let bounds = el.getBoundingClientRect();
        let differentWidth = bounds.width !== (imageContainerBounds?.width);
        let differentHeight = bounds.height !== (imageContainerBounds?.height);
        let differentLeft = bounds.left !== imageContainerBounds?.left;
        let differentRight = bounds.right !== imageContainerBounds?.right;
        let somethingDifferent = differentWidth || differentHeight || differentLeft || differentRight;

        if( somethingDifferent ){
          //console.log("Setting new bounds!");
          setImageContainerBounds(bounds);
        }else{
          //console.log(`(cur,new) = (${imageContainerBounds?.width},${bounds.width})`);

        }

      }else{
        //console.log("--> el = " + el);
      }
    }}>



      {imagePresent && <LaneSqueezerHandle {...{
        onMouseDown:e => { setLeftSqueezeXStart(e.clientX); },
        side:'left',
        sideInset:bumps[0][0] * fullImageWidth / dist,
      }}/>}

      {imagePresent && <LaneSqueezerHandle {...{
        onMouseDown:e => { setRightSqueezeXStart(e.clientX); },
        side:'right',
        sideInset:(1-(bumps.slice(-1)[0][1] / dist) )*fullImageWidth,
      }}/>}


      <CropWrapper 
        version={imageVersion}
        annotation={atn}
        draggable={false}
        htmlImageId={ANNOTATION_IMAGE_ID}
        imageSetId={atn.imageSetId}
        imgRef={previewRef}
        imageId={imageId}
        inlineCrop={inlineCrop}/>
      <div style={{height:'100%',position:'absolute',top:0,width:'100%'}}/>

      <QuantificationLaneBoxContainer {...{
        imageContainerBounds,
        evaluatedLaneOffsets,
        imagePresent,
        leftBump,
        rightBump,
        fullImageWidth,
        scaledAnnotationHeight,

        currentLeftMostPosition,
        currentLength,
        dist,
        quantifications,
        atn,



      }}/>


    </div>

  )
}
