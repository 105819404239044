// memory-usage-middleware.js
const chalk = require('chalk');
const os = require('os');

// Function to get the current memory usage in MB

/*
function getCurrentMemoryUsage() {
  const memoryUsage = process.memoryUsage();
  return {
    rss: memoryUsage.rss / (1024 * 1024), // Resident Set Size
    heapTotal: memoryUsage.heapTotal / (1024 * 1024), // Total Heap Size
    heapUsed: memoryUsage.heapUsed / (1024 * 1024), // Heap Used
  };
}
*/

// Middleware function


let indent = 0;

const memoryUsageMiddleware = () => (next) => async (action) => {
  // Get memory usage before action processing
  //const memoryBefore = getCurrentMemoryUsage();

  // Process the action
  let actionName = action.type || action.__registeredName;
  if( !action ){
    console.log(chalk.bgRed("UNDEFINED ACTION TYPE: " + JSON.stringify(action)));
    debugger;
  }

  if( !action.type || action.type === "undefined" ){

      }

  let indentString = Array(indent).fill(" ").join("");

  const startTime = Date.now();
  console.log(chalk.magenta(indentString+" > "+actionName))//+" "+JSON.stringify(action)));
  indent++;
  const result = await next(action);
  const endTime = Date.now();

  indent--;
  console.log(chalk.magenta(indentString+" < "+actionName))//+" "+JSON.stringify(action)));


  // Get memory usage after action processing
  //const memoryAfter = getCurrentMemoryUsage();

  // Log memory difference

  /*
  console.log(chalk.magenta.bold(`Action: ${actionName}`))
  console.log(chalk.green(JSON.stringify(action)));
  console.log(`Memory Before ${actionName}: RSS ${memoryBefore.rss.toFixed(2)} MB, Heap Used ${memoryBefore.heapUsed.toFixed(2)} MB`);
  console.log(`Memory After: ${actionName}: RSS ${memoryAfter.rss.toFixed(2)} MB, Heap Used ${memoryAfter.heapUsed.toFixed(2)} MB`);
  const memoryDelta = {
      rss: memoryAfter.rss - memoryBefore.rss,
      heapUsed: memoryAfter.heapUsed - memoryBefore.heapUsed,
    };

  console.red(`Memory Delta: RSS ${memoryDelta.rss.toFixed(2)} MB, Heap Used ${memoryDelta.heapUsed.toFixed(2)} MB`);
  */

  return result;
};

export default memoryUsageMiddleware;

