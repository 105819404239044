export const HEIGHT_GROUP_LABEL_INPUT = "height-group-label-input"


export const ANNOTATION_IMAGE_ID = "annotation-image";
export const MAIN_FIGURE_ID = "main-editing-figure";
export const INCLUDE_IN_SCREENSHOT = "include-in-screenshot"
export const SCREENSHOT_IGNORE = "screenshot-ignore";
export const MINIMUM_ROW_HEIGHT = 15;

export const HORIZONTAL_CROP_RESIZER_Z_INDEX = 3;
export const GRID_COLUMN_RESIZE_Z_INDEX = HORIZONTAL_CROP_RESIZER_Z_INDEX + 1;



//MODES
export const QUANTIFICATION="QUANTIFICATION";
export const ANNOTATION="ANNOTATION";
export const DEMO = "DEMO";
export const DROPBOX = "DROPBOX";
export const ANTIBODY_VALIDATION = "ANTIBODY_VALIDATION";
export const FIGURES_AND_PANELS = "FIGURES_AND_PANELS";
export const WELCOME = "WELCOME";
export const IMAGE_STORAGE = "IMAGE_STORAGE";
export const FIGURE_BUILDER = "FIGURE_BUILDER";
export const FIGURE = "FIGURE";
export const WESTERN_BLOTS = "WESTERN_BLOTS";
export const PROTOCOLS = "PROTOCOLS";
export const INVENTORY = "INVENTORY";

