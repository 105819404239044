import ImageCroppingContainer from './ImageCroppingContainer';
//import Modal from 'react-bootstrap/Modal';

import { useSelector, useDispatch } from 'react-redux';
import G from './Getters';
import { useState } from 'react';
import { AnnotationStyleContext } from './Contexts';

function CroppingSidebarWrapper({children}){
  
  return (
    <div>
      {children}

      <div>
        <button>Select Subregion</button>
      </div>

    </div>
  )
}

export default function ImageAnnotationModal({selectedImageId, closeModal, referenceAnnotationId, mode, onEvent, annotationStyle, targetAnnotationId }){

  let boundedLsAtnList = useSelector(state => {
    return G.getRelativeLsAnnotationsBoundedByAnnotationId(state,{annotationId:referenceAnnotationId})
  })


  let [boundedAtnsOnModalOpen,_] = useState(boundedLsAtnList)

  /*let stylingByAnnotationId = Object.fromEntries(boundedAtnsOnModalOpen.map(({_id}) => {
    return [ _id, { interactive:false, color:'red', fill:"url(#stripePattern)", cropBoxes:{rotationHandles:false} } ]
  }).filter(x => x[0] !== targetAnnotationId));
  */






  let body =(
    <AnnotationStyleContext.Provider value={{/*stylingByAnnotationId*/}}>
      <ImageCroppingContainer {...{
        referenceAnnotationId,
        showSidebar:true,
        mode:"regionChoice",
        onEvent,
        selectedImageId,
        imageManagementWidth:'100%',
      }}/>
    </AnnotationStyleContext.Provider>
  )

  return body;

}

