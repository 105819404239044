import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import DeleteButton from './DeleteButton';

export default function DeleteAnnotationButton({annotationId, cx, cy, r, fontSize, onDelete}){

  let dispatch = useDispatch();

  let deleteButton = (
    <g 
      onMouseDown={e => e.stopPropagation()}
      onMouseUp={e => e.stopPropagation()}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        //dispatch(A.deleteAnnotation({_id:annotationId}))
        //the onDelete call must come
        //after the dispatch
        //I'm not 100% sure why 
        //but it works like that:w
        //
        onDelete && onDelete(annotationId)
      }} cursor="pointer">
      <DeleteButton {...{cx,cy,r,fontSize}}/>
    </g>
  )

  return deleteButton;


}
