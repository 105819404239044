import G from './Getters';
import { post } from './io';
import A from './ActionCreators';
import getBlobFromUrl from './getBlobFromUrl';
import SciugoFormData from './SciugoFormData';
import C from './Constants';

function getRemoteStorageResourceIdIfAllowed({
  remoteStorageSpecification,
  version,
  imageId,
  storageLocation
}){



  if( !remoteStorageSpecification ){
    return;
  }
  if( storageLocation !== 'test-server' ){
    return;
  }


  let spec = remoteStorageSpecification.find(s => s.imageId === imageId && s.version === version);

  if( spec ){
    return spec.remoteStorageResourceId;
  }
}

let clearPostData = (imageSpec,dispatch) => {
  dispatch(A.clearPostData({imageSpec}))
}

let onSuccess = ({remoteStorageResourceId,imageSpec,storageLocation},dispatch) => res => {



  let { _id, version, imageId } = imageSpec;
  _id = _id || imageId;

  let postData = res.json;
  clearPostData(imageSpec,dispatch);

  if( res.status !== 204 && imageSpec.version === 'raw' ){
    dispatch(

      A.pushNotification({
        notificationType:C.imageSyncFailure,
        args:{ imageId:_id }
      })
    )
    return;
  }

  //let remoteStorageResourceId = postData.fields.key;
  

  dispatch(A.setRemoteStorageResourceId({
    _id, 
    version,
    pendingRecords:true,
    storageLocation,
    remoteStorageResourceId
  }))
  return true;

}

let onServerFailure = (imageSpec,dispatch) => res => {



  //let { _id, imageId } = imageSpec;
  //_id = _id || imageId;

  if( imageSpec.version === 'raw' ){
    clearPostData(imageSpec,dispatch);

    dispatch(A.notifyMediaPersistenceError({
      imageSpec,
      errorType:C.postResourceError
    }))
  }

  return false;
}


export default function persistMediaWithPresignedUrls(args){
  return (dispatch,getState) => {


    let {imagesToSync, storageLocation, state, remoteStorageSpecification} = args;
    state = state || getState();




    imagesToSync = imagesToSync || G.getMediaItemsWithPresignedUrls(state);

    

    let persistPromises = imagesToSync.map(imageSpec => {

      let { _id, version } = imageSpec;
      let imageVersionReferenceContainer = state.media[_id];
      let imageInfoContainer = imageVersionReferenceContainer[version];

      let localResourceUrl = imageInfoContainer.localBlobUrl;


      if( ! localResourceUrl ){
        debugger;
      }

      return getBlobFromUrl(localResourceUrl).then(res => {


        let postData = G.getPostInfo(state,imageSpec);
        if( !postData ){
          return;
        }

        let formData = new SciugoFormData();


        if( process.env.NODE_ENV !== 'production' ){


          let testSuppliedRemoteStorageResourceId = getRemoteStorageResourceIdIfAllowed({imageId:_id, version, storageLocation, remoteStorageSpecification});
          if( testSuppliedRemoteStorageResourceId ){
            postData = JSON.parse(JSON.stringify(postData));
            postData.fields['key'] = testSuppliedRemoteStorageResourceId;

          }
        }


        for(let key in postData.fields){
          formData.append(
            key, 
            postData.fields[key]
          );
        }

        formData.append('file',res);


        if( ! postData.fields ){
          return;
          debugger;
        }
        //console.log({postData:postData});

        return post({
          routeOrigin:postData.url,
          route:'',
          body:formData,
          stringify:false,
          state,
          onSuccess:onSuccess({remoteStorageResourceId:postData.fields.key,imageSpec,storageLocation},dispatch),
          onServerFailure:onServerFailure(imageSpec,dispatch),
          onFetchError:(err) => {


            //For ease of navigation:
            //'onFetchError' is called when the `fetch` call in SciugoFetch leads to a rejected promise --> catch(err => {...});

            dispatch(A.alertServer({
              cliMessage:"Fetch was caught when posting presigned urls with images."
            }))

            onServerFailure(imageSpec,dispatch)();

          },
          shouldRetry:(res,attemptsMade) => {
            if( res.status !== 204 && attemptsMade < 3){
              return true;
            }
          }
        },dispatch)
      })
    })

    return Promise.all(persistPromises);

  }
}
