import DropCell from './DropCell';
import { useDispatch } from 'react-redux';
import usePresentSelector from './usePresentSelector';
import { useState } from 'react';
import G from './Getters';
import A from './ActionCreators';

const sum = row => row.reduce((a,b) => a+b);

export default function CropDropRow({
  figurePanelId,
  rowIndex,
  setCropIdsBeingDragged,
  cropIdsBeingDragged, 
  cellDragEntered, setCellDragEntered}){

  let dispatch = useDispatch();

  if( !figurePanelId ){
    throw Error("CropDropRow requries figurePanelId");
  }

  let figurePanel = usePresentSelector(state => G.getFigurePanel(state,{figurePanelId}))

  let gridIsEmpty = figurePanel.grid.length === 0;

  let structures = usePresentSelector(state => {
    if( gridIsEmpty ){
      return [[1,15,1]];
    }
    return G.getAdjacentRowStructuresForAdding(state,{figurePanelId:figurePanel._id,rowIndex})
  })

  let cellSpansMultipleRows = usePresentSelector(state => {
    if( rowIndex < 0 ){
      return Array(sum(structures[0])).fill(false);
    }else{
      return G.doCellsSpanMultipleRows(state,{rowIndex,figurePanelId:figurePanel._id})
    }
  })

  let numColumns = sum(structures[0]);

  let numRows = usePresentSelector(state => G.getFigurePanel(state,{figurePanelId}).grid.length);

  return (
    <>
      {gridIsEmpty && <tr>{Array(numColumns).fill(<td style={{width:30}}></td>)}</tr>}
      { structures.map( (structure,structureIndex) =>{ 

        if(!gridIsEmpty){
          structure = G.amendStructureFromMultiRowSpanCells(null, structure, cellSpansMultipleRows);
        }

        return <tr><td>

        </td>
          {structure.map((length,index) => {

            let colIndex = structure.slice(0,index).reduce((a,b) => a+Math.abs(b),0);

            if( cellSpansMultipleRows[colIndex] ){
              return null;
            }

            /*const onDrop = () => {

              if( cropIdsBeingDragged.length === 0 ){
                return;
              }


              let targetRowIndex = Math.ceil(rowIndex);
              let rowValues = Object.fromEntries(cropIdsBeingDragged.map((xx,ii) => ([ii+targetRowIndex,{columnIndices:[colIndex],groupValues:[{value:xx}]}])));


              let insertRowsArgs = {
                  figurePanelId,
                  addExtraRowAbove:(numRows===0),
                  rowIndex:Math.ceil(rowIndex),
                  rowValues,
                  structure:structure,
                  //columnIndices:[colIndex],
                  //groupValues:[{value:cropIdsBeingDragged[0]}]
                }

              console.log({insertRowsArgs});

              dispatch(
                A.insertRows(insertRowsArgs)
              )
              setCropIdsBeingDragged([]);

            }*/

          

            return <DropCell 
              {...{
                //onDrop,
                setCropIdsBeingDragged,
                structure,
                figurePanelId,
                colIndex,
                length,
                cropIdsBeingDragged,
                cellDragEntered,
                setCellDragEntered
              }}
              rowIndex={rowIndex + ((structureIndex+1)/(structures.length + 1))}
            />
          })}
          <td></td></tr>
      })}
    </>
  )

}
