import CollapsibleSection from './CollapsibleSection';
const order = ["red","green","blue"];

export default function ChannelSelector({data, onEvent}){

  let { channels, grayscale } = data.value;

  let channelArray = Array(3).fill(false);//
  Array.from(channels).forEach(val => {
    channelArray[Number(val)] = true;
  })


  console.log({channelArray});
  
  return (
    <>

    <CollapsibleSection sectionTitle={"Channels, color, greyscale"} titleStyle={{ fontSize:12, 
      fontWeight:'bold', }}>

      <div style={{border:'1px solid #ccc'}} class="full-width fair-padding">


    <div class="small-margin sg-row">
      {channelArray.map((cVal,ii) => {
        return (
          <div style={{borderBottom:'3px solid '+order[ii]}} class="tiny-margin tiny-padding">
            <input type="checkbox" checked={cVal === true} onChange={(e) => {
              let newVal = e.target.checked;
              debugger;

              let newChannels = channelArray.map((v,jj) => {
                if( ii === jj ){
                  return (newVal?""+jj:"");
                }else{
                  return v ? ""+jj : "";
                }
              }).join("")
              onEvent({
                event:e,
                type:"change",
                properties:{
                  "value.channels":newChannels
                }});
            }}
            style={{
            }} />
          </div>
        )
      })}

    </div>
      <div>
        Grayscale: <input onChange={e => {
          onEvent({
            type:"change",
            event:e,
            properties:{
              "value.grayscale":(e.target.checked ? 100:0)
            }
        })
          }} type="checkbox" checked={grayscale}/>
      </div>
      </div>
    </CollapsibleSection>
    </>

  )

}
