import computeSpecificScript from "./computeImageJLaneBoxes";
import displayMacroDialog from './displayMacroDialog';

/*
Need to quantifiy each crop.
So we take each image and run the scripts we need for each,
but we need to name the thing at the end. 
That's really the only thing that matters.

So we pass in:
  (cropName, imageName, ls, boxLocations, integrationRanges)

  That will give us the info we need

*/

function createQuantificationMacro(itemsToProcess){


  let appearancesOfCropLabelWindowName = {};
  let imageIdToRawWindowName = {};
  let numWindowsBasedOnFilename = {};

  /*
  There are 3 windows that are used in this process:
  1) Image windows
  2) Straightened crop windows
  3) Plot windows

  The first image of a name gets no dash.
  Then, the straightened crops and succeeding windows all get a dash.

*/

  let fileKeys = [];
  let fileLabels = [];

  let rowLabels = [];
  let rowstarts = [];
  let numLanes = [];


  Object.values(itemsToProcess).forEach(item => {

    //## What happens if they use the same image for 2 different crops?
    //## Ans: they shouldn't be using the same image twice...

    //1. if the image is not yet open, open it
    //1a. Rename the window to the given label. The pathPromptLabel should be unique
    //   else: use the label for that image
    //
    //2. select the rotated rectangle; straighten
    //  2a. rename to the proper crop name (which will be the number of label-crop pairs already seen
    //
    //3. "Plots of X" will be the final name (plot name)



    let { boundaries, label, imageFilename, imageId, pathPromptLabel, lanesStartIndex } = item;

    rowLabels.push(label);
    rowstarts.push(lanesStartIndex||0);
    numLanes.push(boundaries.length);

    if( !(imageId in imageIdToRawWindowName) ){
      item.shouldOpen = true;
      imageIdToRawWindowName[imageId] = item.imageFilename + (item.additionalFilenameInfo ? ' ('+item.additionalFilenameInfo+')' :'');
    } 

    item.rawImageWindowName = imageIdToRawWindowName[imageId];

    let cropWindowSuffix = ' -- ' + item.rawImageWindowName;
    let baseCropWindowName = item.label + cropWindowSuffix;

    let numCropWindowNames = appearancesOfCropLabelWindowName[baseCropWindowName];
    let cropWindowCountModifier = (numCropWindowNames > 0 ? '('+numCropWindowNames+')' : '');

    let modifiedSuffix = cropWindowCountModifier + cropWindowSuffix;
    let cropWindowName = item.label +  modifiedSuffix;
    item.cropWindowName = cropWindowName;
    item.plotWindowName = "Plots of " + cropWindowName;

    fileKeys.push(imageId);
    fileLabels.push( item.rawImageWindowName );

  });

  let labelsAndKeysAssignment = `
fileLabels = newArray("${fileLabels.join('","')}");
fileKeys = newArray("${fileKeys.join('","')}");
`

  let closeAllWindows = "close('*');";
  let versionInfo = 'requires("1.53d");';
  let imageLocationMapPath = 'imageLocationMapPath = getDirectory("home") + ".sciugo-imagej-map";';

  let individualScripts = itemsToProcess.map(item => {
    return computeSpecificScript(item);
  })


  //then, we need to process every item to process
  //but we need to account for crops from the same image
  //and image files with the same name, but different images.
  //if it's the same image, we need to reuse the images image-window names
  //that we already have.
  //we we need to pre-compute what the image-window names will be



  //ALSO, we need to pad the results the crops with zeros on either side, so that info needs to be taken from sciugo as well.


  let createTable = `
  rowNames = newArray(${'"'+rowLabels.join('","')+'"'});
  rowStarts = newArray(${rowstarts.join(',')});
  numLanes = newArray(${numLanes.join(',')});

  createTable(rowNames,rowStarts,numLanes);
  selectWindow("Quantification");

  function createTable(rownames, rowstarts, boxLengths){

    Table.create("Quantification");
    Table.setColumn("Target", rownames,"Quantification");

    lengthMax = 0;

    for(ii = 0; ii < boxLengths.length; ii++){
        if( boxLengths[ii] > lengthMax ){
            for(jj = lengthMax+1; jj <= boxLengths[ii]; jj++){
                Table.setColumn(jj, newArray(rownames.length), "Quantification");
            }
            lengthMax = boxLengths[ii];
        }
    }

    //get the values associated 
    curMeasurementRow = 0;
    for(iiRow = 0; iiRow < rownames.length; iiRow++){
        for(columnIndex = 1; columnIndex <= lengthMax; columnIndex++){
            valueToSet = 0;
            if( (columnIndex - 1 >= rowstarts[iiRow]) && (columnIndex - 1 < boxLengths[iiRow] + rowstarts[iiRow]) ){
                valueToSet = Table.get("Area", curMeasurementRow,"Results");
                curMeasurementRow++;
            }
            Table.set(columnIndex+"", iiRow, valueToSet,"Quantification");
        }
    }

}`




  let findNearestVerticalPointFunction = `function findNearestVerticalPoint(x,argY,windowName){
    proposedY = round(argY);
    selectWindow(windowName);
    for(i = 0; i < 100; i++){
        print("(x,y) = ("+x+","+proposedY+i+")");
        print("(x,y) = ("+x+","+proposedY-i+")");
        valueAbove = getPixel(x,proposedY-i);
        print("valueAbove: " + valueAbove);
        valueBelow = getPixel(x,proposedY+i);
        print("valueBelow: " + valueBelow);
        if( valueAbove != 255 ){
            return proposedY-i;
        }else if( valueBelow != 255 ){
            return proposedY+i;
        }
    }
    print("NOTHING TO RETURN!");
}`;

  const processEndpointsFunction = `function processEndpoints(x0,y0,x1,y1,windowName){

    chosenY0 = findNearestVerticalPoint(x0,y0,windowName);
    chosenY1 = findNearestVerticalPoint(x1,y1,windowName);

    drawLine(x0, chosenY0, x1, chosenY1);
    makeLine(x0, chosenY0, x1, chosenY1);
    run("Interpolate", "interval=1");
    getSelectionCoordinates(xpoints, ypoints);

    // it could get broken here on cases 
    // where the pixel we got isn't the one we should choose or something

    halfXLength = abs(x1 - x0) / 2;
    roundedXIndex = round(halfXLength);

    yOnLine = ypoints[roundedXIndex];
    yAboveLine = yOnLine - 1;

    xMid = 0.5 * (x0 + x1);
    //yMid = 0.5 * (chosenY0 + chosenY1);

    selectWindow(windowName);
    setTool("wand");
    doWand(xMid, yAboveLine);
    setColor(1,0,0);
    fill();
    run("Measure");

}`

  let finalScript = [
    versionInfo,
    imageLocationMapPath,
    closeAllWindows,
    labelsAndKeysAssignment,
    displayMacroDialog,
    ...individualScripts,
    createTable,
    findNearestVerticalPointFunction,
    processEndpointsFunction,

  ]

  return finalScript.join('\n')

}

export default createQuantificationMacro;
