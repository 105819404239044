export default function TutorialMessage({
  errorPopup, 
  debugLocation,
  message
}){

  let { position } = errorPopup;

  let { height=75 } = message || {};


  let resolvedPosition = {};
  if( Array.isArray(position) ){
    resolvedPosition.left = position[0];
    resolvedPosition.top = position[1];
  }else{
    resolvedPosition = position;
  }


  return (
    <div id={"tutorial-popup"} style={{position:'absolute',
      ...resolvedPosition,
      //top:errorPopup.position[1],
      //left:errorPopup.position[0],
      padding:5,
      width:300,
      height,
      background:(errorPopup.background || '#eee'),
      border:'1px solid #ccc',
      color:'black',
      borderRadius:5,
      zIndex:50000,
      boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      fontSize:12,
    }}>
      {errorPopup.text}

      {false && debugLocation && <div>
        <code>{JSON.stringify(debugLocation)}</code>
      </div>}

      {false && <div>
        <code>{errorPopup.tutorialState}</code>
      </div>}

    </div>
  )
}
