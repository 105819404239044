//import gif from './images/testgif.gif';
import WelcomeVideo from './WelcomeVideo';
import A from './ActionCreators';
import SigninModal from './SigninModal';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import AuthContainer from './AuthContainer';

function Lb({children}){
  return <span style={{
    fontWeight:400,
     textShadow: '0px 0px 1px rgba(0, 0, 0, 0.5)', 
    /* Add a subtle text shadow for emphasis */
  }}>{children}</span>
}

export default function WelcomeScene({setMode, setAuthModal, mobile}){
  
  let dispatch = useDispatch();

  
  let gotoAppBypass;
  if( process.env.NODE_ENV !== 'production' ){
    if( typeof(navigator) !== typeof(undefined) ){
      if( !!navigator.webdriver ){
        gotoAppBypass = (

          <button 
            button="go-to-app"
            onClick={() => {

              dispatch(A.enterApp());
              setMode("ANNOTATION");

            }}
            class="fair-margin btn btn-warning" style={{
              fontSize:'1.5vw',
              marginTop:40,
              fontWeight:'bold',width:'80%'}}>Continue as guest</button>

        )
      }
    }

  }


  let titleContainer = mobile => (
    <div class="fair-margin-bottom" style={{
      textAlign:(mobile?'center':'unset'),
      //border:'3px solid lime',
    }}>
      <div style={{
        fontSize:(mobile ? '10vw' : '48px'),
        borderRadius:5, 
        color:'rgba(0,129,255)',
        fontWeight:500,

      }}>sciugo</div>

      <div style={{
        fontSize: mobile ? '3.5vw' : '2vw',
      }}>
        <Lb>Quantify</Lb> blots & gels. Create <Lb>figures</Lb>. In <Lb>seconds</Lb>.
      </div>
    </div>
  )


  let authHalf = (

    <div class="flex-expand" style={{
      marginTop:(mobile?'unset':100),
      marginBottom:(mobile?30:'unset'),

      }}>
      <div style={{
        position:'relative', 
      }}>
        <AuthContainer/>
        {gotoAppBypass}
      </div>
      </div>

  )

  let videoHalf = mobile => (
      <div class="welcome-video-container" style={{
        paddingTop:(!mobile?30:'unset'),
        //border:'3px solid green',
        paddingBottom:0,
        marginLeft:(!mobile?30:'unset'),
        //width:(mobile?'unset':'60%'),
      }}
        class={`welcome-video-container full-height ${mobile ? "full-width" :""}`}>

        {!mobile && titleContainer(mobile)}

        
        <div class="white-shaddow-container" style={{
          //width:(mobile?'80vw':'55vw'), 
        }}>
          <WelcomeVideo/>
        </div>
        
      </div>
  )

  let componentOrder;
  if( !mobile ){
    componentOrder = [videoHalf(mobile),authHalf];
  }else{
    componentOrder = [titleContainer(mobile), authHalf,videoHalf(mobile)];
  }


  let flexDirection = mobile ? "sg-col" : "sg-row";


  return (
    <div style={{
      background:'#eee',
      //border:'3px solid magenta',
      }} class={`full-width flex-expand`}>
      <div style={{
        //border:'3px solid orange',
        alignItems:(!mobile?'flex-end':'unset'),
        paddingLeft:20,
        paddingRight:20
      }} class={flexDirection}>
      {componentOrder}
      </div>
    </div>
  )

}
