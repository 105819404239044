import { getPeakBoundary } from './Quantify';
import Quantify from './Quantify';


function integrateBetweenBoundaries({plotValues,integrationRanges}){

  try{

    let integrationRange = integrationRanges[0];
    let [ rangeStart, rangeEnd ] = integrationRange;

    let startIndex = Math.round( rangeStart * plotValues.length);
    let endIndex = Math.round( rangeEnd * plotValues.length );
    let toIntegrate = plotValues.slice(startIndex, endIndex);

    let sum = toIntegrate.reduce((a,b) => a+b,0);

    if( toIntegrate.includes(NaN) ){
      debugger;
    }

    return Number(Number(sum).toFixed(2));

  }catch(e){
    return -1;
  }

  

}

export default function quantifyAnnotation({grey,invert, raw,laneLsList,height, previousLanePeakBoundaries }){


  grey = grey || raw.grey();

  if( invert ){
    grey.invert({inPlace:true});
  }

  //let pixelValues = getPixelValues(


  let lanePixelValues = laneLsList.map(
    laneLs => Quantify(grey, laneLs, height)
  )

  let bitDepth = grey.bitDepth;

  let lanePeakBoundaries;
  if( !!previousLanePeakBoundaries ){
    lanePeakBoundaries = previousLanePeakBoundaries;
  }else{
    lanePeakBoundaries = lanePixelValues.map(values => getPeakBoundary(values,bitDepth));
  }

  let quantifications = lanePixelValues.map((plotValues,ii) => {
    let integrationRanges = lanePeakBoundaries[ii];
    return {plotValues, integrationRanges, value:integrateBetweenBoundaries({plotValues,integrationRanges})};
  })

  return quantifications;

}
