import FigureImageExpansionLayout from './FigureImageExpansionLayout';

import G from './Getters';
import { useSelector, useDispatch } from 'react-redux';
import { ExpansionWindowFocusContext, ExpansionTemplateInfo, FigureInteractionContext } from './Contexts';

import ImageAnnotationModal from './ImageAnnotationModal';

import { useState, useContext } from 'react';
import { setData, overrideNodes } from './utils';



export default function FigureImageExpansionContainer({
  figurePanelId,
  templateId,
  value,
  onEvent,

  width,

  cellLocation

}){


  let { annotationId, regions } = value;
  let { nodes, computedDimensions} = useSelector(state => {
    return {
      nodes:G.getMicroscopyExpansionNodes(state,{figurePanelId,cellLocation}),
      computedDimensions:G.getEvaluatedExpansionNodeDimensions(state,{figurePanelId, cellLocation, regions }),
    }}); 






  let { selectedFigureItems={} } = useContext(FigureInteractionContext);

  let selectedNodes = selectedFigureItems.expansionNodes;

  let pixelDimensions = {
    w:width, 
    h:computedDimensions.root.h * width
  };


  return (
    <ExpansionWindowFocusContext.Provider value={{ onItemClick:onEvent, onEvent, selectedNodes }}>
    <ExpansionTemplateInfo.Provider value={{computedDimensions}}>
        

        <FigureImageExpansionLayout {...{nodeId:"root",
          nodes, 
          computedDimensions, 
          parentPixelDimensions:{w:width},
          regions:{
            main:annotationId,
            ...(regions||{})
          }
        }}/>

      {false && <div style={{position:'absolute', top:0, right:0, color:'magenta', overflow:'visible', zIndex:100, fontWeight:'bold'}}>
          {""+width+", " + JSON.stringify(pixelDimensions)}
        </div>}
    </ExpansionTemplateInfo.Provider>
    </ExpansionWindowFocusContext.Provider>
  )

  

}
