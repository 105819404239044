import Navbar from './Navbar';
import A from './ActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionPlanOption from './SubscriptionPlanOption';
import { useState, useEffect } from 'react';
import G from './Getters';

import { PricingConfigContext } from './Contexts';
import Countdown from './Countdown';

const navOptions = [
  { _id:"academic", label:"Academic" },
  { _id:"enterprise", label:"Industry" }
]

export default function SubscribeScene({background}){

  const dispatch = useDispatch();

  const [institutionType,setInstitutionType] = useState("academic");

  const [ checkoutLinkOpened, setCheckoutLinkOpened ] = useState(false);
  const [ checkoutLinkRequested, setCheckoutLinkRequested ] = useState(false);
  const [ responseUrl, setResponseUrl ] = useState();
  const [ showLinkAdvisory, setShowLinkAdvisory ] = useState(false);

  const [paymentCycle,setPaymentCycle] = useState("annual");
  const [plurality, setPlurality] = useState("individual");

  const [ labAlreadyRequested, setLabAlreadyRequested ] = useState(false);

  


  let { 
    checkoutLinkResponse,
    discountResponse,
    pricingResponse,
  } = useSelector(state => {
    return {
      checkoutLinkResponse:G.getRequestResponse(state,{route:"/getCheckoutLink"}),
      discountResponse:G.getRequestResponse(state,{route:'/getDiscounts'}),
      pricingResponse:G.getRequestResponse(state,{route:'/getPricing'}),

    }
  })


  useEffect(() => {

    dispatch(A.makeRequest({
      route:'/getDiscounts',
      body:{}
    }))

    dispatch(A.makeRequest({
      route:'/getPricing',
      body:{}
    }))


  },[])

  if( !pricingResponse || pricingResponse.pending === true ){
    return <div class="fair-margin">Prices loading...</div>
  }

  if( pricingResponse.status === "failed" ){
    return <div class="small-margin alert alert-danger">Failed to load prices. Reload this tab to try again.</div>
  }

  let pricing = pricingResponse.pricing;


  


  let pricingConfigSettersAndGetters = {
    institutionType,
    setInstitutionType,
    paymentCycle,
    setPaymentCycle,
    plurality,
    setPlurality,
    discountData:(discountResponse?.data || [])
  }



  if( checkoutLinkRequested ){
    if( checkoutLinkResponse?.url ){
      setResponseUrl(checkoutLinkResponse.url);
    }
  }





  if( responseUrl ){
    setResponseUrl(null);
    setCheckoutLinkRequested(false);
    setShowLinkAdvisory(true);

    let a = document.createElement("A");
    a.href = checkoutLinkResponse.url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    //console.log("Clicked!");
    document.body.removeChild(a);

    dispatch(A.setWarnings({
      subscriptionRefereshNeeded:true
    }))


  }


  

  


  let prices = (
    <div 
      style={{
        //border:'1px solid black',
        justifyContent:'center',
      }}
      class="sg-row">
      {pricing[institutionType].map(data => <SubscriptionPlanOption {...{
        discountData:discountResponse?.data,
        institutionType,
        ...data,

        optionPlurality:data.plurality,

        selectedPaymentCycle:paymentCycle,
        selectedPlurality:plurality,

        setPlurality,
        setPaymentCycle,


        setLabAlreadyRequested,
        setShowLinkAdvisory,
        setCheckoutLinkRequested,
        setCheckoutLinkOpened,



      }}/>
      )}
    </div>
  );

  let targetEnd = Number(discountResponse?.data?.[0]?.end) * 1000;

  //let endDateMessage = (new Date(*1000)).toString()



  return (

    <PricingConfigContext.Provider value={pricingConfigSettersAndGetters}>
      <div style={{
        textAlign:'center',
        alignText:'center',
        width:'100%',


        overflowY:'auto',
      }}>

        <div style={{
          //border:'1px solid black'
        }} class="flex-expand sg-row tiny-margin">
        </div>


        <div style={{
          justifyContent:'center'
        }} class="sg-row">
          <Navbar options={navOptions}
            name={"institutionType"}
            spread={false}
            selected={institutionType}
            onSelect={setInstitutionType}
          />
        </div>

        {discountResponse?.data?.[0] && <div 
          class="sg-row discount-color" style={{
            fontWeight:'bold',
            marginBottom:10,
            justifyContent:'center',
            fontSize:20
          }}>
          <div style={{
            }} class="sg-row">
            {Date.now() > targetEnd &&(
              <div>Discount ended!</div>
            )}
            <div>
              Discount ends in&nbsp;
            </div>
            <Countdown targetDate={targetEnd}/>
          </div>
        </div>}


        <div style={{
          //background:background||'rgba(0,129,255)',
          //border:'3px solid red',
          borderRadius:10,
          position:'relative',
          display:'flex',
        }}>




          <div style={{
            background:background||'rgba(0,129,255)',
            borderRadius:5,
            padding:10,
            width:'100%',
          }}> {prices} </div>
          <div class="flex-expand"/>
        </div>





        <div style={{
          background:'white',
          margin:10,
          alignItems:'center',
          justifyContent:'center',
        }}>


          <div>

            {plurality === "lab" && showLinkAdvisory === "lab" && (
              <div style={{color:'red', fontSize:12,margin:10}}>
                <div><u>Lab plans</u> are currently unavailable via this form.</div>
                <div>However, your request was received and we will contact you to set this up.</div>
              </div>
            )}

            {showLinkAdvisory && showLinkAdvisory!=="lab" && (
              <div style={{fontSize:12,margin:10}}>
                <div>
                  A popup will direct you to your checkout session.
                </div>
                <div>
                  If you were not directed, <b>check blocked pop-ups</b>.
                </div>

                <div>
                  Only trust URLs starting with <b><span style={{color:'blue'}}>https://checkout.stripe.com/</span></b>.
                </div>
              </div>
            )}
          </div>

        </div>






      </div>
    </PricingConfigContext.Provider>
  )

}


