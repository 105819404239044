export function ignoreActionsOnCliServerError(store){
  return next => action => {
    let state = store.getState();
    if( state.UNEXPECTED_SERVER_ERROR ){
      //debugger;
    }else{
      return next(action);
    }
  }
}
