import React from 'react';
import A from './ActionCreators';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    if(!props.onCatch){
      throw Error("dispatch must be passed as a prop to ErrorBoundary");
    }
    this.onCatch = props.onCatch;

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.onCatch(error,errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{textAlign:'center'}} class="full-height full-width fair-padding">
          <h1>Something went wrong.</h1>
        </div>
      );
    }

    return this.props.children; 
  }
}
