import FigurePanelFactory from './FigurePanelFactory';
import ExperimentFactory from './ExperimentFactory';
import AssayFactory from './AssayFactory';
import TemplateFactory from './TemplateFactory';
import Id from './IdFactory';
//import ExperimentFactory from './ExperimentFactory';
import { TEMPLATES, ASSAYS, EXPERIMENTS, WESTERN_BLOTS, SAMPLE_LAYOUTS, FIGURE_PANELS, DIRECTORIES, getResolvedItemTypeName, FIGURES } from './RecordTypes';
const factories = {
  [FIGURE_PANELS]:FigurePanelFactory,
  [WESTERN_BLOTS]:(() => ({title:'Untitled western blot'})),
  antibodyValidation:(() => ({ target:"", templates:[] })),
  [SAMPLE_LAYOUTS]:(({figurePanelId}) => ({figurePanelId})),
  [DIRECTORIES]:()=>({children:[]}),
  [FIGURES]:() => ({ title:'', description:'', panelOrder:[], panels:{} }),
  [TEMPLATES]:TemplateFactory,


  [EXPERIMENTS]:ExperimentFactory,
  [ASSAYS]:AssayFactory,
}

export default function Factory(state,item){

 
  if( !item ){
    debugger;
  }

  let _id = item._id || Id();
  let { type } = item;

  if( !(type in factories) ){
    throw Error("'"+type + "' is not a registered type.");
  }

  let data = {
    _id,
    ...factories[getResolvedItemTypeName(type)](state,item),
    links:{}
  }
  return data;

}
