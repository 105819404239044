import SciugoForm from './SciugoForm';
import ReferralPromotionInfo from './ReferralPromotionInfo';
import { useSelector, useDispatch } from 'react-redux';
import A from './ActionCreators';
import G from './Getters';
import Message from './MessageText.txt';
import TrialEndMessage from './TrialEndMessage';
const schema = [
  {
    type:"message",
    hasValue:false,
    message:"You can use Sciugo for free/discounted by inviting other researchers to the app."
  }
]

export default function ReferralProgramMessage(){
  let dispatch = useDispatch();

  let expiryDateString = useSelector(state => G.getSubscriptionExpiryDateString(state)); 


  let withAt = Array.from(Array.from(expiryDateString).reverse().join('').replace(' ',' ta ')).reverse().join('');

  let noFirstWord = withAt.replace(/[^ ]* /,'');

  let message = `Your trial ends/ended on ${noFirstWord}.`;


  

  return (
    <div style={{
      //border:'3px solid red',
      position:'relative',
    }} 
      class="text-selectable trial-end sg-col full-height small-padding"
      survey={"referralProgramMessage"}>
      <TrialEndMessage dateString={message}/>
    </div>
  )

}
