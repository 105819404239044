export default function DrawRotatedRectIconLarge(){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="92" viewBox="0 0 108 92" fill="none">
      <path d="M1 0H106.263V91.7431H1V0Z" fill="white"/>
      <g filter="url(#filter0_f)">
        <rect x="9.95105" y="48.2791" width="15.625" height="4" rx="2" transform="rotate(-10 9.95105 48.2791)" fill="black"/>
      </g>
      <g filter="url(#filter1_f)">
        <rect x="7.25" y="33.9632" width="15.625" height="4" rx="2" transform="rotate(-10 7.25 33.9632)" fill="black"/>
      </g>
      <g filter="url(#filter2_f)">
        <rect x="15.0625" y="74.5882" width="15.625" height="4" rx="2" transform="rotate(-10 15.0625 74.5882)" fill="black"/>
      </g>
      <g filter="url(#filter3_f)">
        <rect x="36.9375" y="70.3125" width="15.625" height="4" rx="2" transform="rotate(-10 36.9375 70.3125)" fill="black"/>
      </g>
      <g filter="url(#filter4_f)">
        <rect x="57.25" y="66.364" width="15.625" height="4" rx="2" transform="rotate(-10 57.25 66.364)" fill="black"/>
      </g>
      <g filter="url(#filter5_f)">
        <rect x="79.125" y="62.0882" width="15.625" height="4" rx="2" transform="rotate(-10 79.125 62.0882)" fill="black"/>
      </g>
      <g filter="url(#filter6_f)">
        <rect x="31.826" y="48.2791" width="15.625" height="4" rx="2" transform="rotate(-10 31.826 48.2791)" fill="black"/>
      </g>
      <g filter="url(#filter7_f)">
        <path d="M54.0483 50.2487C53.8565 49.1609 54.5829 48.1236 55.6707 47.9318L67.1191 45.9131C68.2068 45.7213 69.2442 46.4477 69.436 47.5355V47.5355C69.6278 48.6232 68.9014 49.6606 67.8136 49.8524L56.3653 51.871C55.2775 52.0628 54.2401 51.3365 54.0483 50.2487V50.2487Z" fill="black"/>
      </g>
      <g filter="url(#filter8_f)">
        <rect x="75.576" y="48.2791" width="15.625" height="4" rx="2" transform="rotate(-10 75.576 48.2791)" fill="black"/>
      </g>
      <path d="M91.2931 14.9202C91.7668 14.2405 91.5998 13.3056 90.9202 12.8319L79.8448 5.1127C79.1652 4.63901 78.2302 4.80597 77.7565 5.48561C77.2828 6.16525 77.4498 7.10021 78.1294 7.5739L87.9742 14.4354L81.1127 24.2802C80.639 24.9598 80.806 25.8948 81.4856 26.3685C82.1653 26.8422 83.1002 26.6752 83.5739 25.9956L91.2931 14.9202ZM2.82619 31.1641L90.3262 15.5391L89.7988 12.5859L2.29881 28.2109L2.82619 31.1641Z" fill="#1400FF"/>
      <path d="M95.4587 57.4833C96.1398 57.9549 97.0743 57.785 97.5458 57.1038L105.23 46.0043C105.702 45.3231 105.532 44.3887 104.851 43.9171C104.17 43.4456 103.235 43.6155 102.764 44.2966L95.933 54.1629L86.0667 47.3324C85.3856 46.8609 84.4512 47.0308 83.9796 47.7119C83.5081 48.393 83.678 49.3274 84.3591 49.799L95.4587 57.4833ZM88.5867 22.1433L94.8367 56.5184L97.7883 55.9817L91.5383 21.6067L88.5867 22.1433Z" fill="#1400FF"/>
      <rect x="3.61003" y="30.305" width="86.5" height="55.25" transform="rotate(-10 3.61003 30.305)" stroke="#001AFF"/>
      <path d="M6.265 13.125C7.65167 12.805 8.73833 12.125 9.525 11.085H10.565V21.625H8.805V13.625C8.21833 14.0783 7.37167 14.3717 6.265 14.505V13.125Z" fill="black"/>
      <path d="M96.3675 24.91C96.5275 24.0433 96.9475 23.3633 97.6275 22.87C98.3208 22.3633 99.1608 22.11 100.148 22.11C101.108 22.11 101.894 22.3967 102.508 22.97C103.134 23.53 103.448 24.2967 103.448 25.27C103.448 26.4433 102.708 27.5567 101.228 28.61C99.6275 29.7433 98.7408 30.6233 98.5675 31.25H103.708V32.75H96.2275C96.3608 31.6433 96.7408 30.7167 97.3675 29.97C97.9942 29.21 98.9408 28.3633 100.208 27.43C101.168 26.7367 101.648 26.03 101.648 25.31C101.648 24.8567 101.521 24.4633 101.268 24.13C101.028 23.7967 100.614 23.63 100.028 23.63C98.9608 23.63 98.3408 24.1567 98.1675 25.21L96.3675 24.91Z" fill="black"/>
      <defs>
        <filter id="filter0_f" x="7.95105" y="43.5658" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter1_f" x="5.25" y="29.25" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter2_f" x="13.0625" y="69.875" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter3_f" x="34.9375" y="65.5992" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter4_f" x="55.25" y="61.6508" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter5_f" x="77.125" y="57.375" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter6_f" x="29.826" y="43.5658" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter7_f" x="51.701" y="43.5658" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
        <filter id="filter8_f" x="73.576" y="43.5658" width="20.0822" height="10.6525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </svg>
  )
}
