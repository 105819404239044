export default function getEntityRelationshipMap(EntityRelationshipList){

  let EntityRelationshipMap = {};
  EntityRelationshipList.forEach(relationship => {

    let { structure, defaultProperties } = relationship;
    let [type0,type1] = structure;
    let [type0Type,type0RelationshipType] = type0;
    let [type1Type,type1RelationshipType] = type1;

    let relMapFromType0 = EntityRelationshipMap[type0Type];
    let curMappingFrom0To1 = relMapFromType0 && relMapFromType0[type1Type];

    let relMapFromType1 = EntityRelationshipMap[type1Type];
    let curMappingFrom1To0 = relMapFromType1 && relMapFromType1[type0Type];

    if( curMappingFrom0To1 || curMappingFrom1To0 ){
      throw Error("The relationship ["+type0Type+","+type1Type+"] is defined twice in the entity relationship list.\n\n"+JSON.stringify(EntityRelationshipList,null,1))
    }


    let zeroToOneInfo = {
      [type1Type]:{ 
        // [1 or Many]    to    [1 or Many]
        relationshipStructure:[type0RelationshipType,type1RelationshipType],
        defaultProperties
      }
    }

    let oneToZeroInfo = {
      [type0Type]:{
        // [1 or Many]    to    [1 or Many]
        relationshipStructure:[type1RelationshipType,type0RelationshipType],
        defaultProperties
      }
    }

    EntityRelationshipMap[type0Type] = {
      ...(EntityRelationshipMap[type0Type]||{}),
      ...zeroToOneInfo
    }

    EntityRelationshipMap[type1Type] = {
      ...(EntityRelationshipMap[type1Type]||{}),
      ...oneToZeroInfo
    }
  })

  return EntityRelationshipMap;

}
