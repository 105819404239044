import WelcomeScene from './WelcomeScene';
import MobileWelcomeScene from './MobileWelcomeScene';
import SigninModal from './SigninModal';
import Navbar from './Navbar';
import WelcomeSceneNavbar from './WelcomeSceneNavbar';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WelcomeOverview from './WelcomeOverview';
import WelcomeFeatures from './WelcomeFeatures';
import WelcomeAbout from './WelcomeAbout';

export default function WelcomeSceneWrapper({setMode}){

  let [ authModal, setAuthModal ] = useState(false);
  const [toggle,setToggle] = useState(false);
  const width = window.innerWidth;
  const height = window.innerHeight;

  const [ subscene, setSubscene ] = useState("home");

  let { username } = useSelector(state => state.loginInfo)
  if( username ){
    setMode("ANNOTATION");
  }


  const welcomeSceneMap = {
    features:<WelcomeFeatures/>,
    overview:<WelcomeOverview/>,
    about:<WelcomeAbout {...{setAuthModal}}/>
  }


  useEffect(() => {

    const onResize = (e) => { 
      setToggle(Math.random());
    }
    window.addEventListener('resize',onResize);
    return () => window.removeEventListener('resize',onResize);
  })

  let welcomeScene;

  if( subscene === "home" ){
    if( height > width ){
      welcomeScene = <WelcomeScene {...{setMode, setAuthModal, mobile:true}}/>
    }else{
      welcomeScene = <WelcomeScene {...{setMode, setAuthModal}}/>;
    }
  }else{
    welcomeScene = welcomeSceneMap[subscene];
  }

  return (
    <div class="welcome-scene sg-col full-height" style={{
    }}>

      <WelcomeSceneNavbar {...{
        onNavbarClick:(_id) => {
          if( _id === "signin" ){
            setAuthModal(true);
          }else if( welcomeSceneMap[_id] ){
            setSubscene(_id)
          }
        },
        subscene, 
        setSubscene,
      }}/>
      
      {authModal && <SigninModal {...{closeModal:() => setAuthModal(false)}}/>}
      {welcomeScene}
    </div>
  )

}
