import { useState } from 'react';
import G from './Getters';
import imageMap from './imageMap';
import { ANNOTATIONS } from './RecordTypes';
import SvgImageFilter, { computeFilterArgs } from './SvgImageFilter';
import computeFilters from './computeFilters';

function ErrorText({x,y,width,height,text}){


  return <text x={x+width/2}y={y+height/2} width={width} 
    height={height} 
    dominantBaseline={"middle"}
    textAnchor={"middle"}
    fill="red">
    {text}</text>

}


export default function SvgImage({state,value,bounds,div, imageUrlMap, exportId}){

  const [dataUrl, setDataUrl] = useState(null);

  let {width, height, x, y} = bounds;


  let annotationId = value.value.annotationId;
  let atnRecord = G.getRecord(state,{type:ANNOTATIONS,_id:annotationId});
  let { meta } = atnRecord
  if( meta.archived ){
    return <ErrorText {...{
      x,y,width,height,
      text:"Annotation Deleted"
    }}/>
  }

  let crop = G.getCropFromAnnotation(state,annotationId);



  let imageId = crop.imageId || state.data.imageSets[crop.imageSetId].figureImageId;




  let imageSet = state.data.imageSets[ crop.imageSetId ];
  let angle = !isNaN(crop.rotation) ? crop.rotation : imageSet.rotation;
  let imageRecord = state.data.imageUploads[ imageId ];

  //debugger;


  //let imageHeight = imageRecord.height;
  let url = imageRecord.url;
  let adjustments = imageRecord.adjustments;
  let imgStyle = {height:crop.height * width, width, }
  let clipId = 'clip-'+div.id;
  let filterId = 'sciugo-adjustments-'+div.id+'_'+exportId;


  let href = (imageUrlMap && imageUrlMap[value._id]) || (
    imageMap[url] || url
  )

  if( !href ){
    return <ErrorText {...{
      x,y,width,height,
      text:"Image not found."
    }}/>
  }

  let zoom = (value.imageAdjustments && value.imageAdjustments.zoom) || 1;
  let [deltaX,deltaY] = (
    value.imageAdjustments && value.imageAdjustments.positionDelta
  ) || [0,0];

  let rads = Math.abs(Math.PI * 2 * angle / 360);
  //let rotatedWidth = defaultCropWidth * Math.cos(rads) + defaultCropHeight * Math.sin(rads);
  //let rotatedHeight = defaultCropWidth * Math.sin(rads) + defaultCropHeight * Math.cos(rads);


  let imageX = x - crop.left * width / crop.width * zoom + deltaX;

  let translateY = - crop.top * width / crop.width * zoom + deltaY;

  let imageY = y + translateY;

  let imageWidth = width / crop.width * zoom;
  let imageHeight = width / crop.width * zoom * imageRecord.height;


  let defaultHeight = width * imageRecord.height;

  let rotationOriginX = zoom * width / crop.width /2;
  let rotationOriginY = zoom * defaultHeight / crop.width /2;

  let rotate = "rotate("+-angle+","+rotationOriginX+","+rotationOriginY+") ";



  let trX = -crop.left * zoom * width / crop.width + deltaX /// crop.width;
  let trY = -crop.top * zoom * width / crop.width + deltaY;
  let translate = "translate("+trX+","+trY+")";
  let scale = ""//"scale("+1/crop.width+")"

  let transform = scale + " " + translate + " " + rotate;


  //let filters = computeFilters(adjustments).filter;

  const dim = {
    x:0,
    y:0,
    width:zoom * width / crop.width ,
    height:zoom * defaultHeight / crop.width
  }

  let filterArgs = computeFilterArgs(adjustments);
  filterArgs.filterId = filterId;

  let filter;
  if( filterArgs.length > 0 ){
    filter = <SvgImageFilter {...{filterArgs}}/>
  }

    return(
      <>

        



        <svg 

          colorInterpolationFilters={"sRGB"}
          x={x} y={y} width={width} height={height}

          style={{}}>

        { true && <defs>
          <clipPath clipPathUnits="userSpaceOnUse" id={clipId}>
            <rect x={-trX} y={-trY}
              transform={"rotate("+angle+","+rotationOriginX+","+rotationOriginY+")"}
              width={width} height={height}/>
          </clipPath>

          {filter}
        </defs>}
          

          <image
            filter={filterArgs.length > 0 ?`url(#${filterId})`:undefined}
            style={{
              clipPath:"url('#"+clipId+"')",
              //filter:`url(#image-filter)`, // 
              //...computeFilters(adjustments)
            }}

            transform={transform}
            preserveAspectRatio="xMinYMin slice"

            xlinkHref={href}
            {...{...dim}}

          />
        </svg>

        {false && <text x={x} y={y} stroke="yellow" height="15">{JSON.stringify([trX,trY])}</text>}


        {false && <circle cx={x} cy={y} r={3} fill="magenta"/>}

        {false && <rect stroke="lime" strokeWidth={3} 
          x={imageX} y={imageY} 
          width={imageWidth} height={imageHeight} fill={"none"}/>}

        {false && <rect stroke="red" strokeWidth={1} x={x} y={y} width={width} height={height} fill={"none"}/>}



      </>
    )
}
