export default function debounce(func, delay) {
  let timerId;

  return function(...args) {
    const currentDelay = timerId ? delay : 0; // Adjust the delay based on the existence of timerId

    clearTimeout(timerId); // Clear the previous timer

    timerId = setTimeout(() => {
      func.apply(this, args); // Execute after the adjusted delay
    }, currentDelay);
  };
}
