
import SubsciptionPlanOptionButton from './SubscriptionPlanOptionButton';
import CheckoutButton from './CheckoutButton';

const SELECTION_BORDER = 'rgba(0,129,255)'

export default function SubscriptionPlanOption({

  discountData,

  title,monthly,annual,
  monthlyPaidAnnually,
  header,
  onClick,
  //plurality,
  optionPaymentCycle,
  optionPlurality,
  selectedPlurality,
  setPlurality,
  selectedPaymentCycle,
  //paymentCycle,
  setPaymentCycle,
  features,

  institutionType,

  setLabAlreadyRequested,
  setShowLinkAdvisory,
  setCheckoutLinkRequested,
  setCheckoutLinkOpened,


}){

  let priceFontStyle ={
    fontSize:20,
    fontWeight:'bold',
  }
  let commentStyle = {
    fontSize:12,
    color:'grey'
  }

  let planPlurality = optionPlurality;

  //title.toLowerCase()

  return (
    <div onClick={onClick} class="flex-expand" style={{
      background:'white',
      paddingTop:10,
      borderRadius:10,
      margin:10,
      alignText:'center',
      textAlign:'center',
      boxShadow:'2px 2px 2px #222',


    }}>
      <h3>{title}</h3>
      {false && <div  style={{
        ...commentStyle,
        borderBottom:'1px solid #ccc',
        //paddingBottom:30,
        height:25,
        //background:'green',

      }}>{header}</div>}

      <div style={{
        borderTop:'1px solid #ccc',
        marginTop:20,
        //background:'red',
        position:'relative',
      }} class="sg-row v-center">

        <SubsciptionPlanOptionButton {...{
          discountData,

          selectedPlurality,
          selectedPaymentCycle,

          optionPlurality:planPlurality,

          optionPaymentCycle:"annual",
          price:monthlyPaidAnnually,

          setPaymentCycle,
          setPlurality,


        }}/>


        <div class="tiny-margin">Or</div>

        <SubsciptionPlanOptionButton {...{
          discountData,
          selectedPlurality,
          selectedPaymentCycle,

          optionPlurality:planPlurality,

          optionPaymentCycle:"30day",
          price:monthly,

          setPaymentCycle,
          setPlurality,

        }}/>
      </div>
      



      <CheckoutButton {...{

        disabled:(selectedPlurality !== planPlurality),
        
        plurality:selectedPlurality,
        institutionType,
        selectedPaymentCycle,

        setLabAlreadyRequested,
        setShowLinkAdvisory,
        setCheckoutLinkRequested,
        setCheckoutLinkOpened,

      }}/>


      <div class="small-margin" style={{ 
        marginTop:10,
        textAlign:'left' ,
        //fontSize:'1.5vw',
        }}>
        <ul class="pricing-list" style={{listStyleColor:'navy'}}>
          {(features||[]).map(feature => <li>{feature}</li>)}
        </ul>
      </div>

    </div>
  )
}
