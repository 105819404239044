
import FigureImageExpansionLayoutValue from './FigureImageExpansionLayoutValue';
import { ExpansionTemplateInfo } from './Contexts';

import { useContext } from 'react';

export default function FigureImageExpansionLayout({nodeId, nodes,
  style, 
  regions,
  parentPixelDimensions
}){

  let { computedDimensions } = useContext(ExpansionTemplateInfo);

  
  let node = nodes[nodeId];

  if( !node ){
    debugger;
  }

  

  let { d, c, value } = node || {};
  let parentStyle = node.style || {};

 
  let nodeDims = computedDimensions[nodeId] || { w:1, h: 1};

  if( !parentPixelDimensions ){
  }

  let nodeWidth = parentPixelDimensions.w * (nodeDims.w);// * 100 + '%';
  let nodeHeight = (parentPixelDimensions.h??parentPixelDimensions.w) * (nodeDims.h);// * 100 + '%';


  //console.log(JSON.stringify({ nodeId, nodeWidth, nodeHeight }));


  let thisPixelDimensions = {
    w:nodeWidth,
    h:nodeHeight
  }
  

  return (
    <div 
      onClick={() => {
      }}
      valueType={"crop-expansion"}

      class={d === "h" ? "sg-row" : "sg-col" } style={{

      width:nodeWidth,
      height:nodeHeight,

      //background:'pink',
      //border:(c ? '2px solid red' : '2px solid blue'),
      position:'relative',
    }}>

      {value && <FigureImageExpansionLayoutValue {...{
          value,
        regions,
        node,
        nodeId,
        nodes,
        parentPixelDimensions:thisPixelDimensions
      }}/>
      }

      {!value && (c||[]).map(listItem => {
        return(
          <FigureImageExpansionLayout {...{nodeId:listItem, nodes, computedDimensions, regions, parentPixelDimensions:thisPixelDimensions}}/>
        )
      })}


      {false && <div style={{position:'absolute', top:0, right:0, color:'magenta', overflow:'visible', zIndex:100, fontWeight:'bold'}}>
          {JSON.stringify(thisPixelDimensions)}
        </div>}

      {false && !c && (
        <div style={{
          
          fontSize:8,
        }}><pre>{JSON.stringify({nodeDims},null,1)}</pre></div>
      )}
    </div>
  )



}
