import Id, {Ids} from './IdFactory';

export default function ExperimentFactory(state,args){
  let { design } = args;
  return {
    _id:Id(),
    design,
    datePerformed:null,
    assays:[],

  }
}
