import TableActionToolbar from './TableActionToolbar';
import StyleToolbar from './StyleToolbar';

export default function Toolbar({
  setRetainInputFocus,
  figurePanelId,
  quantificationToolbarVisible,
  setQuantificationToolbarVisible,
  selectedFigureItems,
  setSelectedCells,
  unifiedGroup,

}){


  return (
    <div id={"top-editor-toolbar"} style={{
      padding:5,
      //border:'2px solid red',
      //background:'blue',
      display:'flex',
      width:'100%'

     }} class="light-border-bottom">
      <div style={{alignItems:'center'}} class="sg-row">
        <StyleToolbar { ...{
          figurePanelId,
          setRetainInputFocus,
          selectedFigureItems,
          unifiedGroup,
        }}/>

        <TableActionToolbar {...{
          unifiedGroup,
          figurePanelId,
          setSelectedCells,
          selectedFigureItems,
        }}/>
        {false && <div class="flex-expand"/>}
        {false && <div>
          <button onClick={() => setQuantificationToolbarVisible(!quantificationToolbarVisible)}>
            {(quantificationToolbarVisible?"Hide":"Show") + " Quantification"}
          </button>
        </div>}
      </div>
    </div>
  )

}
