import ResetCropLanesButton from './ResetCropLanesButton';
import RemoveCropFromQuantificationListButton from './RemoveCropFromQuantificationListButton';

import UpdateIcon from '@material-ui/icons/Update';

export default function QuantificationCropButtons({_id,onRemove}){
  return (
    <div style={{
      //border:'1px solid red'
      }}>
      <RemoveCropFromQuantificationListButton {...{_id,onRemove}} />

      <div style={{
        //border:'1px solid red',
      }} class="sg-row">
        <ResetCropLanesButton {...{_id}}/>
        {false && <UpdateIcon style={{marginTop:5, 
        fontSize:18}}/>}
      </div>


    </div>
  )
}
