import { SIGNIN, PAYMENT, NEW_FEATURES, REPORT_BUG } from './AppModal';
import G from './Getters';
import { useState } from 'react';
import HelpPopover from './HelpPopover';
import UserSettingsButton from './UserSettingsButton';

import ExitTutorialButton from './ExitTutorialButton';

import SaveButton from './SaveButton';
//import SignInWithDevAccountButton from './SignInWithDevAccountButton';
//import SignInWithGoogleButton from './SignInWithGoogleButton';

import { useDispatch } from 'react-redux';


import CloudSyncToolbarItem from './CloudSyncToolbarItem';

import CloudBackupButton from './CloudBackupButton';


import MenuIcon from '@material-ui/icons/Menu';
import MainDrawer from './MainDrawer';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PeopleIcon from '@material-ui/icons/People';
import pingServer from './pingServer';


import { MAIN_FIGURE_ID, SCREENSHOT_IGNORE } from './UIConstants';



const QUANTIFICATION_FEATURE = true;


const SETTINGS = "SETTINGS";
const FIGURE="FIGURE";
const IMAGES="IMAGES";
const ANNOTATION="ANNOTATION";
const DEMO = "DEMO";
const QUANTIFICATION = "QUANTIFICATION";
const ANTIBODY_VALIDATION = "ANTIBODY_VALIDATION";
const FIGURES_AND_PANELS = "FIGURES_AND_PANELS";
const IMAGE_STORAGE = "IMAGE_STORAGE";
const WESTERN_BLOTS = false && "WESTERN_BLOTS";/// && false;
const PLOT_TEST = "PLOT_TEST";
const PROTOCOLS = "PROTOCOLS";
const INVENTORY = "INVENTORY";

export default function AppNavbar({mode,setMode,
  state, 
  setSvg, 
  setModal,
  setCommentModalOpen, name, 
  setCollaboratorFormOpen, 
  isLoggedIn,
  clearUserSelections,
}){

  let doingTutorial = G.isInTutorial(state);

  

  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ shouldThrowError, setShouldThrowError ] = useState(false);

  if( shouldThrowError ){
    throw Error("ERROR");
  }

  


  let menuButtonStyle = { fontSize:12, display:'flex', alignItems:'center' };

  return (
    <div style={{alignItems:'center'}}
      selected={mode}
      objecttype="navbar" className="sg-row divider-bottom">
   
    {false && <div 
      onClick={() => setDrawerOpen(true)}
  className="hoverable" style={{display:'flex', alignItems:'center',paddingLeft:5,paddingRight:5, 
      //height:'100%',
      borderRadius:20,
      marginLeft:10,
      marginRight:10,
      //border:'1px solid black'
      }}>
    <MenuIcon/>
    </div>}

    <div objectType="navbarTab" navbar="main" tab="imageScene" id="my-annotations" style={menuButtonStyle} onClick={() => setMode(ANNOTATION)} className={(mode===ANNOTATION && "selected ") + " small-padding hoverable round-corners tiny-margin app-navbar"}>Upload and Label</div>

    {false && QUANTIFICATION_FEATURE && <div id="quantification-scene" style={menuButtonStyle} onClick={()=>setMode(QUANTIFICATION)} className={(mode===QUANTIFICATION&&" selected ")+" tiny-padding hoverable round-corners tiny-margin app-navbar"}>Quantification</div>}

      {WESTERN_BLOTS && <div navbar="main" objecttype="navbarTab" tab="quantification" style={menuButtonStyle} onClick={()=>setMode(WESTERN_BLOTS)} className={(mode===WESTERN_BLOTS&&" selected ")+" small-padding hoverable round-corners tiny-margin app-navbar"}>Gels</div>}


      {QUANTIFICATION_FEATURE && <div navbar="main" objecttype="navbarTab" tab="quantification" style={menuButtonStyle} onClick={()=>setMode(QUANTIFICATION)} className={(mode===QUANTIFICATION&&" selected ")+" small-padding hoverable round-corners tiny-margin app-navbar"}>Quantification</div>}


    <div navbar="main" objecttype="navbarTab" tab="figurePanels" style={menuButtonStyle} onClick={()=>setMode(FIGURES_AND_PANELS)} className={(mode===FIGURES_AND_PANELS&&" selected ")+" small-padding hoverable round-corners tiny-margin app-navbar"}>My Figures</div>


    <div navbar="main" objecttype="navbarTab" tab="protocols" style={menuButtonStyle} onClick={()=>setMode(PROTOCOLS)} className={(mode===PROTOCOLS&&" selected ")+" small-padding hoverable round-corners tiny-margin app-navbar"}>Protocols</div>


      <div navbar="main" objecttype="navbarTab" tab="protocols" style={menuButtonStyle} onClick={()=>setMode(INVENTORY)} className={(mode===INVENTORY&&" selected ")+" small-padding hoverable round-corners tiny-margin app-navbar"}>Inventory</div>


      {false && <div navbar="main" objecttype="navbarTab" tab="figurePanels" style={menuButtonStyle} onClick={()=>setMode(PLOT_TEST)} className={(mode===PLOT_TEST&&" selected ")+" small-padding hoverable round-corners tiny-margin app-navbar"}>Plot Test</div>}




    <div id="new-features" style={{...menuButtonStyle,alignItems:'center',display:'flex', 
      //width:25,
      justifyContent:'center',
      //height:25
      }} 
      onClick={() => setModal(NEW_FEATURES)} 
      
      className={"hoverable round-corners tiny-margin small-padding"}>
      <HelpOutlineIcon style={{
      display:'flex',
        alignItems:'center',
      justifyContent:'center',
      fontSize:20,padding:-1,height:'100%'}}/>
    </div>
        {false && <>

      <div button="report-bug-modal" navbar="main" objecttype="navbarTab" tab="report-bug" style={{...menuButtonStyle, fontWeight:'bold',
       // color:'blue'
        }} 
      onClick={()=>setModal(REPORT_BUG)} 
      className={"btn btn-link small-padding tiny-margin app-navbar"}>Report Bug</div>
      </>}




    {mode === ANNOTATION && (
      <>

        {false && <HelpPopover {...{
          anchorElement:<h6 id="annotation-help-button" style={menuButtonStyle} className={" small-padding hoverable round-corners tiny-margin"}>Annotation Help</h6>
        }} />}

       

      </>
    )}

    <div className="flex-expand"/>



      

    {false && <MainDrawer setMode={setMode} open={drawerOpen} setOpen={setDrawerOpen}/>}


    


    <div className="flex-expand"/>
    {/*take app snapshot with <AppSnapshotTaker/>*/}

    {window.__TESTING_ERROR === true && <h6 id="throw-error" style={menuButtonStyle} 
      onClick={() => {setShouldThrowError(true)}}
      className={"small-padding hoverable round-corners tiny-margin"}>Throw Error</h6>}
 


      {doingTutorial && <ExitTutorialButton/>}

      {!doingTutorial && <>

    
    {isLoggedIn && <SaveButton/>}
 
    {!isLoggedIn && null && (<div style={{...menuButtonStyle,
     // background:'red',
      marginRight:10,fontWeight:'bold'}}>
      <button button="open-auth-modal" onClick={() => setModal(SIGNIN)} style={{fontWeight:'bold'}} className="btn btn-primary btn-sm sg-row">Sign in</button>
    </div>)}


    {true && (
      <>
        <div info="user-email">{state.loginInfo.username}</div>
        <UserSettingsButton {...{setMode,clearUserSelections,
          openSettingsModal:() => setModal(SETTINGS),
          openBugModal:()=>setModal(REPORT_BUG)}}/>
      </>
    )}
      </>}
      

    </div>
  )
}
