import G from './Getters';
import getLabelProperty from './getLabelProperty';

import { getTextWidth } from './PositionalLayout';

const anchorSideMap = {
  'right':'left',
  'left':'right'
}

export default function SvgBandAnnotation({
  figurePanel,
  state,annotation,bounds,cell}){

  let panelGlobalStyle = figurePanel.globalStyle || {};
  let style = {}

  let { annotationId, sideRelativeToImage, labelType } = annotation;

  /*
  let cellValueList = G.getCellsValue(state,{cells:[cell],figurePanelId:figurePanel._id});

  let cellValue = cellValueList[0];
  let { sideRelativeToImage } = cellValue;
  */

  let labels = G.getMarkLabels(state,{annotationId,
    side:sideRelativeToImage,
    labelType:labelType||"mw"
  })

  let height = bounds.height;
  
  let markerWidth = getLabelProperty(style,panelGlobalStyle,'pointerWidth');

  let fontSize = getLabelProperty(style,panelGlobalStyle,'labelFontSize');


  let spacing = getLabelProperty(style,panelGlobalStyle,'textPointerSpacing');

  let labelConnector = getLabelProperty(style,panelGlobalStyle,'labelConnector');


  debugger;

  let labelSvgs = labels.map(({label,positions}) => {
    let position = positions[0]

    let y = bounds.y + position * bounds.height;
    let lineX1;
    let lineX2; 
    let textX;

    if( sideRelativeToImage === "right" ){
      lineX1 = bounds.x;
      lineX2 = bounds.x + markerWidth;
      textX = lineX2 + spacing;

    }else if( sideRelativeToImage === "left" ){
      lineX1 = bounds.x + bounds.width - markerWidth;
      lineX2 = lineX1 + markerWidth;
      textX = lineX1 - spacing - getTextWidth({text:label,fontSize})
    }
    return (
      <>
        <line x1={lineX1} y1={y} x2={lineX2} y2={y} stroke={"black"} strokeWidth={1}/>
        <text class="svg-annotation-label" {...{...bounds, y, x:textX, fill:'black', zIndex:100, 
          fontSize,//:fontSize+"px",
          fontFamily:'Helvetica',
          //dominantBaseline:"middle",
          dy:"0.25em"
          //alignmentBaseline:"middle",
          }}>
          {label}
      </text>
      </>
    );
  })

  return labelSvgs;

}
