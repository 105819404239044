const handler = {
  get(target,prop,receiver){
    return function(...children){ 
      return {
        tag:prop,
        children:children.flat()
      }
    }
  }
}

const html = new Proxy({},handler);
export default html;
