import { ANNOTATIONS, DIRECTORIES, IMAGE_SETS, IMAGE_UPLOADS, FIGURE_PANELS } from './RecordTypes';

import { CREATION_DATE, LAST_EDITED_DATE } from './FieldConstants';

export const FILESYSTEM_NAME = "filesystemName";
export const FILESYSTEM_PARENT_DIRECTORY = "filesystemParentDirectory";
export const IS_FILESYSTEM_ITEM_EXPANDABLE = 'isExpandable';
export const NOTIFICATIONS = "notifications";
export const IMAGE_SET_WITHOUT_ANNOTATIONS = "imageSetWithoutAnnotations";
export const CHILDREN = "children";
export const FILESYSTEM_NAME_PREFIX = "filesystemNamePrefix";


export const ChildTypes = {
  [DIRECTORIES]:[DIRECTORIES,IMAGE_SETS,IMAGE_UPLOADS,FIGURE_PANELS],
  [FIGURE_PANELS]:[],
  [IMAGE_SETS]:[IMAGE_UPLOADS,IMAGE_SETS],
  [IMAGE_UPLOADS]:[IMAGE_SETS,IMAGE_UPLOADS],
  [ANNOTATIONS]:[]

}

export const FilesystemColumnNames = {
  [FILESYSTEM_NAME]:"Name",
  [LAST_EDITED_DATE]:"Last Edited",
  [CREATION_DATE]:"Date Created"

}


