import G from './Getters';
import jimp from "jimp/es";

import imageMap from './imageMap';

export default async function getImageUrlMap(state,args={}){
  let figurePanelId = args.figurePanelId;
  if( !figurePanelId ){
      figurePanelId = state.selectedFigurePanelId;
      if( !figurePanelId ){
        throw Error("You must pass figurePanelId.");
      }
  }
  let figurePanel = state.data.figurePanels[figurePanelId];

  let idToCellMap = G.getIdToCellMap(state,{figurePanelId});

  let isImageType = val => ['crop','image'].includes(val.value.valueType);

  let cellGroupMapEntries = Object.values(figurePanel.cellGroups)
    .filter(isImageType)
    .filter(val => val.value.annotationId in state.data.annotations)
    .map(value => {

      let _id = value._id;

      let cellOfId = idToCellMap[ _id ];


      let crop = G.getCropFromAnnotation(state,value.value.annotationId);
      //let imageData = G.getImageDataFromCropId(state,cropId);
      let imageId = crop.imageId;
      let url = G.getImageRecordUrl(state,{imageId});

      //console.log("url: " + url);

      let mappedUrl = imageMap[url] || url;

      
      return jimp.read(mappedUrl).then( image => {
        return image.getBase64Async("image/png").then( res => 
          [_id, res]
        )

      }).catch(err => {
        
        console.log("Jimp had a problem reading the image.");
        console.error(err)
        return [_id, undefined]
      });
    })


  return Promise.all(cellGroupMapEntries).then(map => {
    console.log("Jimp map:");
    console.log(map);
    return Object.fromEntries(map);
  });

}


