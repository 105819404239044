import SciugoFolderIcon from './SciugoFolderIcon';
import G from './Getters';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import A from './ActionCreators';
import ImageThumbnail from './ImageThumbnail';
import { IMAGE_SETS, IMAGE_UPLOADS, DIRECTORIES } from './RecordTypes';
import EditableContent from './EditableContent';
import Tooltip from '@material-ui/core/Tooltip';


export default function TitledThumbnail({ treePath, filesystemContainer, item, isDragPreview, renaming, setRenaming }){


  let dispatch = useDispatch();
  //let fsName = useSelector(state => G.getFilesystemName(state,item));
  let { type } = item;

  let {
    fsName,
    renameArgs,
    imageIdUsedForThumbnail
  } = useSelector(state => {


    let fsName = G.getFilesystemName(state,item) || item.filesystemName;

    let childrenIdsNeededForThumbnail;
    let imageIdUsedForThumbnail;

    let renameArgs = {
      itemType:(item.type||item.itemType),
      _id:item._id
    }

    if( type === IMAGE_SETS ){
      childrenIdsNeededForThumbnail = G.listDirectory(state,item);
      imageIdUsedForThumbnail = childrenIdsNeededForThumbnail[0]?._id;

      let isSingletonImageSet = (type===IMAGE_SETS && childrenIdsNeededForThumbnail.length === 1);
      if( isSingletonImageSet ){
    
        renameArgs = {
          itemType:IMAGE_UPLOADS,
          _id:imageIdUsedForThumbnail,
        }
      }

    }else if( type === IMAGE_UPLOADS ){
      imageIdUsedForThumbnail = item._id
    }
    

    return {
      fsName,
      imageIdUsedForThumbnail,
      renameArgs
    }

  });

  /*if( !imageIdUsedForThumbnail ){
    debugger;
  }*/


  
  let thumbnail;

  let thumbnailHasImagePreview = [IMAGE_SETS,IMAGE_UPLOADS].includes(type)
  
  if( thumbnailHasImagePreview ){

    thumbnail = <ImageThumbnail {...{ dim:16, imageId:imageIdUsedForThumbnail, draggable:false, isDragPreview, treePath }}/>
  }else if( type === DIRECTORIES ){
    thumbnail = <SciugoFolderIcon/>
  }

  let dragPreviewStyle = {};
  if( isDragPreview ){
    dragPreviewStyle = {
      paddingLeft:5,
      paddingRight:10,
      paddingTop:2,
      paddingBottom:2,
      borderRadius:12
      //color:'white'
    }
  }


  let treePathString = treePath?.join('-');
  let tooltipPlacement = "right";
  return (

    

    <div 


      filesystemName={fsName} filesystemContainer={filesystemContainer} 
      treePath={treePath?.join('-')} 
      style={{alignItems:'middle',...dragPreviewStyle,
      }} class={"sg-row " + (isDragPreview?"selected":"")}>
      {thumbnail}
      <div style={{width:5}}/>
      {!renaming && (

        <Tooltip placement={"right"} title={fsName}>
                <div treePath={treePathString} filesystemItemProperty={"filesystemName"} filesystemName={fsName}
          style={{
            //border:'1px solid black',
            display:'flex',

            textOverflow:'ellipsis',
            overflow:'hidden'

          }}
        >
          {fsName}
        </div>
        </Tooltip>
      )}
      {renaming && <EditableContent 
        focusOnRender={true}
        editing={renaming}
        onChange={value => {
          //if( e.key === 'Enter' ){

            setRenaming(false);
            dispatch(A.setFilesystemName({
              ...renameArgs,
              filesystemName:value
            }))


         // }
        }}
        
        style={{ 
        //boxSizing:'border-box',
        padding:0,
        lineHeight:1,
        //border:'2px solid cyan' 
      }} content={fsName}/>}
    </div>
  )
}
