import A from './ActionCreators';
import { useState ,useEffect } from 'react';
import FigureFilesystemCreationContainer from './FigureFilesystemCreationContainer';
import { FILESYSTEM_NAME } from './Filesystem';
import { CREATION_DATE, LAST_EDITED_DATE } from './FieldConstants';
import ResizableSidebar from './ResizableSidebar';

import FilesystemListContainer from './FilesystemListContainer';
import usePresentSelector from './usePresentSelector';
import G from './Getters';
import MinimizedFilesystemContainer from './MinimizedFilesystemContainer';
import FigureFilesystemAccessibilityButton from './FigureFilesystemAccessibilityButton';




import Navbar from './Navbar';




import FilesystemItemContextMenu from './FilesystemItemContextMenu';

export default function FigureFilesystemContainer({
  setTargetDirectoryOfNewFigurePanel,
  newPanelFilesystemRootTarget,
  setFocusedFsItemId,
  focusedFsItemId,
  setSelectedFigurePanelContext,
}){

  

  const [activelyRenamingFilesystemItem,setActivelyRenamingFilesystemItem] = useState(false);
  const [ contextMenuInfo, setContextMenuInfo ] = useState(null);




  const [ dropTargetId,setDropTargetId] = useState(null);


  let inTutorial = usePresentSelector(G.isInTutorial);


  const [ showFilesystem, setShowFilesystem ] = useState(true);
  const [ activeFilesystemRoot, setActiveFilesystemRoot ] = useState("figurePanels");

  /*
  useEffect(() => {
    if( focusedFsItemId && inTutorial && showFilesystem ){
      setShowFilesystem(false);
    }
  },[focusedFsItemId])
  */


  let state = usePresentSelector(state => state);




  const topLevelDirectoryId = usePresentSelector(state => G.getTopLevelDirectoryId(state,{itemType:activeFilesystemRoot}));

  let filesystemHidden = (
    !showFilesystem
    ||
    (focusedFsItemId && inTutorial)
  )

  if( filesystemHidden ){
    return <MinimizedFilesystemContainer {...{setShowFilesystem}}/> 
  }

  return (

    <ResizableSidebar {...{minWidth:100, defaultWidth:200, maxWidth:700}}>
      <div class="sg-col full-height">
        <div 
          style={{

            
            width:'100%',
            background:'#fafafa',
          }}>

          <FigureFilesystemAccessibilityButton {...{
            activeFilesystemRoot,
            setActiveFilesystemRoot,
            setShowFilesystem,
          }}/>


        </div>


        <div 
          onContextMenu={e => e.preventDefault()}
          style={{
            //border:'3px solid red',
            minHeight:0,
          }}
          class="full-width full-height sg-col">


          {contextMenuInfo && <FilesystemItemContextMenu {...{
            ...contextMenuInfo,
            filesystemRootName:activeFilesystemRoot,
            functions:{
              setActivelyRenamingFilesystemItem,
              setSelectedFigurePanelContext,
              setActiveFilesystemRoot,
            },
            onClose:() => {
              setContextMenuInfo(null);
            }
          }}/>}



          <FigureFilesystemCreationContainer {...{
            activeFilesystemRoot,
            setNewPanelFilesystemRootTarget:setTargetDirectoryOfNewFigurePanel,
          }}/>




          <FilesystemListContainer 

            key={"figuresAndPanels"}
            {...{
              contextMenuInfo, 
              setContextMenuInfo, 

              topLevelDirectoryId,
              columns:[FILESYSTEM_NAME, LAST_EDITED_DATE, CREATION_DATE],
              activelyRenamingFilesystemItem,
              setActivelyRenamingFilesystemItem,

              dropTargetId,
              setDropTargetId

            }}/>
        </div>
      </div>
    </ResizableSidebar>
  )
}
