export default function computeFilters(adjustments,args){

 
  let filterOrder = getFilterOrder(adjustments);

  let filter = '';

  filterOrder.forEach(key => {
    if( adjustments[key] ){
      if( key === "inverted" ){
        filter += ' invert(1) ';
      }else{
        let value = adjustments[key];
        filter += ` ${key}(${value}) `
      }
    }
  })

  /*

  if( adjustments.inverted ){
    filter += ' invert(1) '
  }

  if( adjustments.brightness ){
    filter += ' brightness('+adjustments.brightness+') '
  }
  if( adjustments.contrast ){
    filter += ' contrast('+adjustments.contrast+') '
  }
  */
  

  return {
    WebkitFilter:filter,
    filter
  }

}

export function getFilterOrder(adjustments){

  return ['inverted','brightness','contrast'];

  /*

  let filterOrder = [];
  if( adjustments.version === 1 ){
    filterOrder = ['brightness','contrast','inverted'];
  }else{
    filterOrder = ['inverted','brightness','contrast'];
  }
  return filterOrder;
  */

}
