import { useState } from 'react';
import AntibodyValidationTemplate from './AntibodyValidationTemplate';
import { useSelector } from 'react-redux';
import usePresentSelector from './usePresentSelector';

export default function(){
  const [target,setTarget] = useState("");
  const templates = usePresentSelector(state => state.data.antibodyValidations['validation0'].templates); 


  return (
    <div style={{width:'100%'}} class="fair-padding">
      <div>{JSON.stringify(templates)}</div>
      <h5>Target name: <input/></h5>

      <div style={{marginTop:20}}>
        <h5>Templates</h5>
        {templates.map(template => {
          return <AntibodyValidationTemplate {...{template}}/>
        })}
      </div>
    </div>
  )
}
