//the filesystem item options
//depend on what time of item it is.
//hence, we need to choose based on that

//[ images, imageUploads, figurePanels, figurePanelTemplate ]
// onclicks take control of higher order things
//
import { useEffect } from 'react';
import G from './Getters';
import A from './ActionCreators';
import usePresentSelector from './usePresentSelector';
import { useDispatch } from 'react-redux';
import Id from './IdFactory';
import { DIRECTORIES, IMAGE_UPLOADS, IMAGE_SETS, FIGURE_PANELS } from './RecordTypes';
import Dialog from './DialogConstants';




function getMenuLayout({state,filesystemItem,functions,filesystemRootName,
  setSelectedFigurePanelContext,
  dispatch}){

  let isImageFilesystemRoot = filesystemRootName === "images";

  //let fsPath = G.getAbsoluteFsPath(state,filesystemItem);

  let options = [];

  if( filesystemItem.type === "figurePanels" ){
    options.push(
      ...getFigurePanelContextMenuItems({
        filesystemItem,
        filesystemRootName,
        dispatch,
        functions
      })
    );
  }

  options.push(...[
    {
      text:"Rename",// - " + fsPath,
      onClick:() => {
        let _id = filesystemItem._id;
        functions.setActivelyRenamingFilesystemItem(_id);
      }
    }
  ])

  if( G.canDeleteFilesystemItem(state, filesystemItem) ){
    options.push(deleteContextMenuItem({filesystemItem,dispatch,state}))
  }

  return options;


}

export default function FilesystemItemContextMenu({x,y,filesystemItem, onClose,
  functions,
  filesystemRootName
}){

  let dispatch = useDispatch();



  let options = usePresentSelector(state => getMenuLayout({state,filesystemItem,filesystemRootName,functions,dispatch}));


  const onClick = (e) => {
      let path = e.composedPath();

      if( !path.some(item => {
        return item.item === "contextMenu";
      }) ){
        onClose();
      }


    }


  useEffect(() => {

    window.addEventListener('click',onClick);

    return () => {
      window.removeEventListener('click',onClick);
    }
    

  })






  return (
    <div 
      onClick={e => e.stopPropagation()}
      style={{
        position:'fixed',
        minWidth:100,
        background:'white',
        top:y,
        left:x,
        zIndex:100,
        border:'1px solid #ccc',

        boxShadow:'0 2px 4px rgba(0 0 0 / 10%), 0 8px 16px rgba(0 0 0 / 10%)',
        boxSizing:'border-box',


      }}
      class="sg-context-menu">
      {options.map(({text,style,onClick}) => {
        return (

          <div
            style={style}
            item={"contextMenuItem"}
            contextMenuItem={text}
            onClick={() => {
              if( onClick ){
                onClick();
                onClose();
              }
            }} class="tiny-margin hoverable">{text}</div>

        )

      })}
    </div>
  )
}




function deleteContextMenuItem({filesystemItem,dispatch,state}){

  return {
      text:"Delete",// + JSON.stringify(filesystemItem),
      style:{color:'red',
        borderTop:'1px solid #ccc',
      },
      onClick:() => {

        if( filesystemItem.type === IMAGE_SETS || filesystemItem.type === IMAGE_UPLOADS ){

          if( filesystemItem.type === IMAGE_SETS ){
            let imageIds = G.getImageSetImageIds(state,{imageSetId:filesystemItem._id})

            if( imageIds.length === 1 ){
              dispatch(A.tryArchiveItems({
                items:[{itemType:IMAGE_UPLOADS,_id:imageIds[0]}]
              }))
              return;
            }
          }

          dispatch(A.tryArchiveItems({
            items:[{itemType:filesystemItem.type,_id:filesystemItem._id}]
          }))

        }else{

          dispatch(A.createDialog({
            source:"contextMenu",
            dialogName:Dialog.CONFIRM_DELETE_ITEM,
            args:{
              _id:filesystemItem._id,
              itemType:filesystemItem.type
            }
          }))
        }

       

        //functions.setSelectedFigurePanelContext(null);
      }
    }
}

function getFigurePanelContextMenuItems({filesystemItem,filesystemRootName,functions,dispatch}){

  let options = [];

  if( filesystemRootName === "figurePanelTemplates"  ){

    options.push(... [

      {
        text:"Edit template",onClick:() => { 
          let _id = filesystemItem._id;
          functions.setSelectedFigurePanelContext({_id,editing:true});
        },
      },

      {text:"New panel",onClick:() => {
        let _id = Id();
        dispatch(A.createItem({
          type:FIGURE_PANELS,
          figurePanelType:'westernBlot',
          _id,
          args:{
            basedOn:filesystemItem._id
          }
        }))
        functions.setActiveFilesystemRoot("figurePanels");
        functions.setSelectedFigurePanelContext({_id});

      }},
      {text:"Duplicate",onClick:() => {

        let _id = Id();
        dispatch(A.createItem({
          type:FIGURE_PANELS,
          figurePanelType:'westernBlot',
          _id,
          args:{
            template:true,
            basedOn:filesystemItem._id
          },
        }))
        functions.setSelectedFigurePanelContext({_id});

      }},
      
    ])

  }else if( filesystemRootName === "figurePanels"  ){

    options.push(... [

      {text:"New template from panel",onClick:() => {

        let _id = Id();
        dispatch(A.createItem({
          type:FIGURE_PANELS,
          figurePanelType:'westernBlot',
          _id,
          args:{
            //fromFigure:filesystemItem._id,
            basedOn:filesystemItem._id,
            template:true,
          },
        }))
        functions.setActiveFilesystemRoot("figurePanelTemplates");
        functions.setSelectedFigurePanelContext({_id});


      }},
      {text:"Duplicate (without images)",onClick:() => {

        let _id = Id();
        dispatch(A.createItem({
          type:FIGURE_PANELS,
          figurePanelType:'westernBlot',
          _id,
          args:{
            fromFigure:filesystemItem._id
          }
        }))
        functions.setActiveFilesystemRoot("figurePanels");
        functions.setSelectedFigurePanelContext({_id});


      }},
      
      //{text:"Edit template",onClick:() => {}},
    ])

  }


  

  return options;

}
