import G from './Getters';
import FigureImageExpansionTemplateLayoutContainer from './FigureImageExpansionTemplateLayoutContainer';

export default function MicroscopyTemplateThumbnail({template,templateId}){

  return (
    <div style={{ pointerEvents:'none',
      minHeight:0,
      background:'#f0f0f0'

    }} class="full-width full-height">
      <FigureImageExpansionTemplateLayoutContainer {...{
        template,
        thumbnail:true,
      }}/>
    </div>
  )

}
