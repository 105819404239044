import { INCLUDE_IN_SCREENSHOT } from './UIConstants';
export default function SpacerRow({height,position,rowIndex}){

  if( position === 'bottom' && rowIndex === 0 && height > 0 ){
    debugger;
  }
  return (
    <tr class={"" + INCLUDE_IN_SCREENSHOT} style={{
      //background:'blue'
    }} class="spacer-row">
      <td style={{padding:0,height}}>
    </td>
    </tr>
  )
}
