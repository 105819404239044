import { Image } from 'image-js';
import SciugoFetch from './SciugoFetch';
import { isCLI } from './versionConfig';
import getBlobFromUrl from './getBlobFromUrl';

let imageMap = {};
if( !isCLI() ){
  imageMap = require('./imageMap').default;
}



export default function LoadImage(src){

  if( !src ){
    console.log("ABOUT TO THROW!");
    throw Error("Image src cannot be undefined.");
  }

  let resolvedSource = src;
  //console.log({src});
  if( process.env.NODE_ENV !== 'production' ){
    if( isCLI() ){

      //console.log("\n\nIN CLI TRYING TO LOAD WITH SCIUGO FETCH\n\n")
      return getBlobFromUrl(src).then(blob => {
        //console.log({blob});
        return Image.load(blob);
      }).catch(e => {
        console.error(e);
      })

    }else {
      resolvedSource = imageMap[src] || src;
    }
  }

  //console.log({src});
  return Image.load(resolvedSource);
}
