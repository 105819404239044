export function round(number,decimalPlaces){
  return Number(Number(number).toFixed(decimalPlaces))
}


export function flattenObject(obj, parentKey = '', separator = '.') {
    let flattened = {};
    for (const [key, value] of Object.entries(obj)) {
        const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
        if (typeof value === 'object' && value !== null) {
            Object.assign(flattened, flattenObject(value, newKey, separator));
        } else {
            flattened[newKey] = value;
        }
    }
    return flattened;
}

export function unflattenObject(obj,sep='.') {
    const result = {};

    for (const [key, value] of Object.entries(obj)) {
        const keys = key.split(sep);
        let currentObj = result;

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (i === keys.length - 1) {
                //console.log(`SETTING ${key},${value}`);
                if( currentObj[k] !== undefined ){
                    throw Error(`Unflatten dict conflict (${key},${value}). '${k}' on that path is already set to ${typeof(currentObj[k]) === typeof({}) ? JSON.stringify(currentObj[k]) : currentObj[k]}.`);
                }
                currentObj[k] = value;
            } else {
                currentObj[k] = currentObj[k] || {};
                currentObj = currentObj[k];
            }
        }
    }

    return result;
}


export function overrideNodes(oldNodes,overrides){
  if( !overrides ){
    return oldNodes;
  }

  let resolvedOverrides = Object.fromEntries(
    Object.entries(overrides).map(([key,value]) => {
      return [key.replaceAll('~','.'),value]
    })
  )
 
  let nodes;
  try{
    nodes = JSON.parse(JSON.stringify(oldNodes))
  }catch(error){
    debugger;
  }


  let freshProperties = JSON.parse(JSON.stringify(resolvedOverrides));
  //need fresh properties
  //because if we pass in an object
  //(adding a node to the thing,
  //then that object args will get overwritten
  //when we modify properties of the added object)
  //hence, we need to keep freshProperties

  let newNodes;
  try{
  
    newNodes = setData(
      nodes,
      freshProperties
    );
  }catch(error){
    debugger;
    throw(error);
  }

  return newNodes;

}

export function setData(mutable, properties){




  for(let pKey in properties){
    let fullPath = pKey.split('.');
    //console.log({fullPath});
    let value = properties[pKey];
    let curObject = mutable;

    let toTraverse = fullPath.slice(0,-1)
    let toSet = fullPath.slice(-1)[0];

    toTraverse.forEach((pathItem,ii) => {
      if( !(pathItem in curObject) ){
          curObject[pathItem ] = {}
      }
      curObject = curObject[pathItem]
    })

    
    let arrayOperation = toSet.slice(-2);

    if( arrayOperation === ':+' ){

      let objKey = toSet.slice(0,-2);
      let target = curObject[objKey];
      if( !target ){
        debugger;
      }

      target.push(value);

    }else if( arrayOperation === ':-' ){

      let objKey = toSet.slice(0,-2);

      let toRemove = value;
      let indexOfValue = curObject[objKey].indexOf(toRemove);
      if( indexOfValue !== -1 ){
        curObject[objKey].splice(indexOfValue,1);
      }
    }else{
      curObject[toSet] = value;

    }
    
    
  }

  return mutable;

}


export function argmin(list){
  if( list.length === 0 ){
    throw Error("Cannot compute argmin of empty list.");
  }
  let min = list[0];
  let minIndex = 0;
  list.forEach((value,ii) => {
    if( value < min ){
      minIndex = ii;
      min = value;
    }
  })
  return minIndex;
}

export function table(list, allowedKeys){
  if( allowedKeys !== undefined && allowedKeys.length === 0 ){
    return {};
  }
  let counts = {};

  (allowedKeys||[]).forEach(k => {
    counts[k] = 0;
  })

  list.forEach(item => counts[item]=(counts[item]||0)+1);


  return counts;
}

export function mode(list){
  
  let counts = table(list);
  let vals = Object.values(counts)
  let maxIndex = argmax(vals);
  let maxValue = Object.keys(counts)[maxIndex];
  return maxValue;
}

export function argmax(list){

  if(list.length === 0){
    throw Error("Cannot compute argmax of empty list.");
  }

  let max = list[0];
  let maxIndex = 0;
  list.forEach((value,ii) => {
    if( value > max ){
      maxIndex = ii;
      max = value;
    }
  })
  return maxIndex;
}

export function diff(list){

  if(list.length === 0){
    throw Error("Cannot compute diff on empty list.");
  }

  let result = list.map((val,ii) => {
    if(ii === 0){
      return 0;
    }else{
      return val - list[ii-1];
    }
  })

  return result;

}

export function smooth(values,kernel){
  if( kernel.length % 2 === 0 ){
    throw Error("Smoothing kernel must have odd length.");
  }

  let centerIndex = (kernel.length-1)/2;

  let result = values.map((val,ii) => {
    let value = 0;
    for(let jj = 0; jj < kernel.length; jj++){
      let originalValue = (values[ ii + (jj - centerIndex)]||0)
      value += (originalValue||0) * kernel[jj];
    }
    return value;
  })

  return result;



}
