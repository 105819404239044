import { useContext } from 'react';
import { QuantificationListContext } from './Contexts';

import { useSelector } from 'react-redux';

export default function QuantificationListToggler({_id, item, children, treePath}){

  let quantificationListContext = useContext(QuantificationListContext);

  if( !quantificationListContext ){
    return null;
  }

  let { quantificationList, quantificationListDispatch } = quantificationListContext;


  let action = (_id in quantificationList) ? "Remove" : "Quantify";


  if( item.type !== 'annotations' ){
    return null;
  }

  let quantificationListToggle = action.toLowerCase();

  return (
    <div>
      <button 
        treePath={treePath.join('-')}
        quantificationListToggle={quantificationListToggle}
        onClick={() => {
          if( action === "Quantify" ){

            quantificationListDispatch({
              type:"add",
              ids:[_id]
            })
          }else if( action === "Remove" ){
            quantificationListDispatch({
              type:"remove",
              ids:[_id]
            })
          }
        }}>{action}</button>
    </div>
  )

}
