import { IDLE, SYNC_UPDATE } from './SyncConstants';



export default function queueAllObjectRecordsForSync(draft){

  let data = draft.data;
  let syncStatusForRecords = draft.syncStatus.records;

  let recordTypes = Object.keys(data);
  recordTypes.forEach(type => {
    let typeContainer = data[type];
    let typeIds = Object.keys(typeContainer)
    let datatypeSpecificSyncEntries = typeIds.map(_id => {
      return ([_id,{
        _id, 
        syncKey:SYNC_UPDATE, 
        status:IDLE,
        meta:true,
        data:true
      }])
    })
    let datatypeSpecificSyncObject = Object.fromEntries(
      datatypeSpecificSyncEntries
    );

    let currentTypeSyncObject = (syncStatusForRecords[type]||{})

    syncStatusForRecords[type] = {
      ...currentTypeSyncObject,
      ...datatypeSpecificSyncObject
    }

  })

}
