export default function InvisibleRowWhichMaintainsProperColumnWidths({widths,editable}){

  let firstColWhichIsEmpty = editable !== false && <td style={{width:0,padding:0,height:0}}/>
  return (
    <tr style={{height:0,padding:0}}>
      {firstColWhichIsEmpty}
      {widths.map(width => <td style={{
        minWidth:width,
        maxWidth:width,
        width,
        //border:'1px solid black',
        height:0,
        padding:0,
      }}/>)}
      {firstColWhichIsEmpty}
    </tr>
  )

}
