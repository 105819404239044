import { getFilterOrder } from './computeFilters';
import React from 'react';

export function computeFilterArgs(adjustments){

  let filterOrder = getFilterOrder(adjustments);
  let filterArgs = [];
  
  filterOrder.forEach(filterKey => {
    let filterValue = adjustments[filterKey]
    if( filterValue ){
      let thisFilterArgs = getFilterArgs({filterKey,filterValue})
      filterArgs.push(thisFilterArgs);
    }
  })

  filterArgs.forEach((args,ii) => {
    args.inKey = ii === 0 ? "SourceGraphic" : `${ii-1}-out`;
    args.result = ii === filterArgs.length - 1 ? 'source' : `${ii}-out`;
  })

  return filterArgs;

}


function getFilterArgs({filterKey,filterValue}){

  if( filterKey === "contrast" ){
    let intercept = -0.5 * filterValue + 0.5;
    return { slope:filterValue, intercept, filterKey };
  }else if( filterKey === "brightness" ){
    return { slope:filterValue, intercept:0, filterKey }
  }else if( filterKey === "inverted" ){
    return { slope:-1, intercept:1, filterKey }
  }

}

function FeComponentTransfer({ inKey, result, slope, intercept }){


  let innerFunctions = ["R","G","B"].map( letter => {
    let comp = "feFunc"+letter;
    return React.createElement(comp,{
      type:"linear",
      slope,
      intercept
    })
  })

  return (
    <feComponentTransfer {...{in:inKey, result} }>
      {innerFunctions}
    </feComponentTransfer>
  )


}

export default function SvgImageFilter({filterArgs}){



  
  if( filterArgs.length === 0 ){
    return null;
  }


  return (

    <filter colorInterpolationFilters={"sRGB"} 
      //filterUnits="objectBoundingBox" height="100%" width="100%" x="0%" y="0%"
      id={filterArgs.filterId}>
      {filterArgs.map(args => <FeComponentTransfer {...{...args}}/>)}
      
    </filter>
  )
}
