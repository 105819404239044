import Reducer from './Reducer';
import A from './ActionCreators';
import stateClearedOfUserData from './stateClearedOfUserData';

import getDefaultState from './DefaultState';
import C from './Constants';
import G from './Getters';
import D from './DialogConstants';

import ActionTypesInvokedByServerResponse from './ActionTypesInvokedByServerResponse';

import { diff } from 'jsondiffpatch';
import ReduceStateBasedOnChanges from './ReduceStateBasedOnChanges';
import ReduceBasedOnDiff from './ReduceBasedOnDiff';
import changeIsPermittedForUser from './changeIsPermittedForUser';

//import SyncReducer from './SyncReducer';


/*
 If it isn't a fetch from server, it's new.
 Thus, if the action is: "INJECT_FETCH_DATA",
 we get all the _ids that came from the fetch.

 We also need to to figure out how
 these metadata are added, or do we?

 We can slightly adjust the schema on
 the sever...

 Every id just has its data and meta

*/
const IGNORABLE_ACTION_TYPES = [
  ...ActionTypesInvokedByServerResponse,
  C.launchTutorial,
  C.exitTutorial,
  C.completeTutorial,
  "CLEAR_STATE",
]

function shouldIgnoreAction(action){
   return IGNORABLE_ACTION_TYPES.includes(action.type);
}

export default function ChangeReducer(state,action){
 
  if( state === undefined ){
    return getDefaultState();
  }else if(action.type === C.LOGOUT_USER  ){
    return stateClearedOfUserData(state);
    
  }else if( action.type === C.unexpectedCliServerError ){
    return { 
      ...state,
      UNEXPECTED_SERVER_ERROR:action.errorMessage 
    }
  }

  let newState;
  try{
    newState = Reducer(state,action);

    if( newState.dialogs && newState.dialogs.length > 0 && G.isInTutorial(state) ){
      newState = {
        ...newState,
        dialogs:[]
      }
    }



  }catch(err){
    if( (typeof(process) !== typeof(undefined)) && process.isDebugging ){
      console.log('\n\n')
      //console.red && console.red("\n\nAction dispatched:\n\n"+JSON.stringify(action,null,1)+"\n\n");
      console.error(err);
    }

    if( (typeof(process) !== typeof(undefined)) && process.isDebugging ){ 
      console.red && console.red("\n\nAction dispatched:\n\n"+JSON.stringify(action,null,1)+"\n\n");
    }


    throw err;
  }

  if( !state || !newState ){
    debugger;
  }


  

  if( shouldIgnoreAction(action) ){
    return newState;
  }


  let changeReduced = ReduceStateBasedOnChanges(state,newState);

  let stateDiff;
  try{
    stateDiff = diff(state,changeReduced);
  }catch(e){
    debugger;
  }

  let reducedStateBasedOnDiff = ReduceBasedOnDiff(state,changeReduced,stateDiff);  

  if( !stateDiff ){
    return reducedStateBasedOnDiff;
  }

  if( changeIsPermittedForUser({state,stateDiff,action}) ){

    
    return reducedStateBasedOnDiff;
  }else{

    let createDialogAction = A.createDialog({
      dialogName:D.SUBSCRIBE,
      source:"paywallMiddleware",
      args:{ }
    })

    let reducedWithPaywallDialog = Reducer(state,createDialogAction);
    return reducedWithPaywallDialog;

    //throw Error("The change,\n " + JSON.stringify(stateDiff,null,1) + "\n was not permitted based on this user's priveleges.");
  }


  



  

  
}


