import getTypeChanges from './getTypeChanges';
import RecordTypes from './RecordTypes';
import { IMAGE_SETS } from './RecordTypes';
import { IDLE } from './SyncConstants';

export default function getObjectsToSyncWithServer(state,newState){


  let newObjectsToSync = {};

  let timestamp = Date.now();

  RecordTypes.forEach(type => {
  
    let syncForType = {};

    ['data','meta'].forEach(recordInfoKey => {
      let newInfo = newState[recordInfoKey][type];
      let oldInfo = state[recordInfoKey][type];

      if( type === IMAGE_SETS && recordInfoKey === "meta" ){
       // debugger;
      }

      if( newInfo !== oldInfo ){


        

        let {newItems,missingItems,changed} = getTypeChanges(
          newInfo, oldInfo
        )

      let syncKeyForEachChange = [
        ['SYNC_ADD',newItems],
        ['SYNC_DELETE',missingItems],
        ['SYNC_UPDATE',changed]
      ]

        syncKeyForEachChange.forEach( change => {
          let [syncKey,changedIds] = change;
          changedIds.forEach(_id => {
            let curSyncObject = syncForType[_id] || {
              _id, syncKey,
              status:IDLE
            };

            syncForType[_id] = {
              ...curSyncObject,
              [recordInfoKey]:true,
            }


          })
        })
      }

    })

    if( Object.keys(syncForType).length > 0 ){
      newObjectsToSync[type] = syncForType;
    }

  })

  return newObjectsToSync;

}
