import Tooltip from '@material-ui/core/Tooltip';

import { useDispatch } from 'react-redux';
import A from './ActionCreators';
import { useContext, useState } from 'react';
import { ExpansionWindowFocusContext, ExpansionTemplateInfo } from './Contexts';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Adders from './FigureImageExpansionTemplateInsertionLayer';
import ExpansionTemplatePlacementCover from './ExpansionTemplatePlacementCover';
import AspectRatioEditor from './AspectRatioEditor';


import FigureImageExpansionTemplateNodeValue from './FigureImageExpansionTemplateNodeValue';

const DEFAULT_CHANNEL_COLORS = ["red","lime","blue"]


function NodeHeader({regionId,expansionItemChannels,padding}){


  return (
    <>

    {true && <div class="sg-row" style={{
        position:'absolute',
        height:6, fontSize:8, lineHeight:0.5,
        top:0,
        left:padding,
        paddingRight:3,
        overflow:'hidden',
      }}>
        <div style={{marginRight:5}}>{regionId}</div>
        <div class="flex-expand"/>

        {Array.from(expansionItemChannels||"012").map(cIndex => (
          <div style={{
            marginTop:1,
            background:DEFAULT_CHANNEL_COLORS[cIndex],
            borderRadius:10,
            width:4,
            height:4
          }}>
          </div>
        ))}
      </div>}
    </>
  )

}


function ExpansionSpecLabel({regionId,channels}){

  return (

    <div class="deletable-container expansion-item-id">
      {regionId}
      <div class="sg-row h-center">
        {Array.from(channels||"012").map(cIndex => (
          <div style={{
            background:DEFAULT_CHANNEL_COLORS[cIndex],
            borderRadius:10,
            width:6,
            height:6
          }}>
          </div>
        ))}
      </div>
    </div>
  )

}



export default function FigureImageExpansionTemplateNode({node, nodeId, nodes, templateId,
  setContextMenuConfig,
}){



  let { computedDimensions } = useContext(ExpansionTemplateInfo);


  const [ entered, setEntered ] = useState(false);

  let aspect = nodes[nodeId].dimension || { w:1, h:1 };

  let nodeDims = computedDimensions[nodeId] || { w:1, h: 1};


  let nodeWidth = (nodeDims.w) * 100 + '%';
  let nodeHeight = (nodeDims.h) * 100 + '%';


  let dispatch = useDispatch();


  let expansionWindowFocusContext = useContext(ExpansionWindowFocusContext);

  let { onItemClick, focusedExpansionInfo={} } = expansionWindowFocusContext || {};

  

  let focusedExpansionChannels = focusedExpansionInfo.channels;
  let focusedExpansionRegion = focusedExpansionInfo.region;
  let readyToPlaceWindow = focusedExpansionRegion || focusedExpansionChannels;

  let valuesToSet = {};
  if( focusedExpansionChannels ){
    valuesToSet.channels = focusedExpansionChannels;
  }
  if( focusedExpansionRegion ){
    valuesToSet.regionId = focusedExpansionRegion;
  }


  let { value, style } = node;
  let { regionId } = value || {};
  let expansionItemChannels = value?.channels;
  
  const padding = 6;

  //style.padding || 0;

  return (
    <div 
      onMouseEnter={() => { setEntered(true); }}
      onMouseLeave={() => { setEntered(false); }}
      onClick={onItemClick && ((e)=>{
        e.stopPropagation();
        //alert(nodeId);
        onItemClick(nodeId)
      })}
      class={
        ("magic-parent sg-col full-height full-width") 
          + 
          (entered && readyToPlaceWindow ? " selected" : "")
      } 
      style={{
        position:'absolute',
        border:'1px solid #ccc',
        background:'white',

      }}>

      {!readyToPlaceWindow && <RemoveButton {...{dispatch, templateId, nodeId,

        onClick:() => {
          dispatch(A.removeExpansionTemplateNode({ 
            templateId,
            nodeId,
          }))
        }

      }}/>}

      <NodeHeader {...{regionId,expansionItemChannels,padding}}/>


      {!readyToPlaceWindow && <Adders {...{dispatch, templateId, nodeId}}/>}

      
      <div 

        class="sg-row full-width magic-parent deletable-container" 
        style={{

          top:padding,
          left:padding,
          width:`calc(100% - ${padding*2}px)`,
          height:`calc(100% - ${padding*2}px)`,

          alignItems:'center',
          justifyContent:'center',
          border:'1px solid #ccc',
          position:'relative',

          //pointerEvents:(readyToPlaceWindow?'none':'unset'),
          //border:'3px solid red',
        }}>
        {!value &&(
          <AspectRatioEditor {...{
            dispatch,
            nodeId,
            templateId,
            aspect,
          }}/> 
        )}

        {value && <FigureImageExpansionTemplateNodeValue {...{ value, dispatch, templateId, nodeId, regionId, readyToPlaceWindow, entered, node,
          nodes,
          computedDimensions,
        }}/>}


        {false && value && (


          <div style={{position:'relative'}} class="expansion-item-container">

            {!readyToPlaceWindow && <RemoveButton {...{dispatch, templateId, nodeId, onClick:() => {
              dispatch(A.setRegionExpansionNodeProperties({ 
                templateId,
                nodeId,
                properties:{
                  value:undefined
                }
              }))
            }}}/>}


            {false && (!readyToPlaceWindow || !entered) && <ExpansionSpecLabel {...{regionId:regionId, channels:expansionItemChannels}}/>}

            {false && (readyToPlaceWindow && entered) && <ExpansionSpecLabel {...{
              ...{regionId, channels:expansionItemChannels}, 
              ...valuesToSet,
            }}/>}



          </div>)}


              </div>

      {readyToPlaceWindow && <ExpansionTemplatePlacementCover readyToPlaceWindow={readyToPlaceWindow} onClick={() => {
        dispatch(A.setRegionExpansionNodeProperties({
          templateId,
          nodeId,
          properties:{
            value:{ 
              ...node.value,
              ...valuesToSet,
            }
          }
        }))
      }}/>}


    </div>
  )

}

function RemoveButton({onClick, dispatch, templateId, nodeId}){
  return (

    <div
      onClick={onClick}
      style={{
        position:'absolute', 
        right:0, 
        top:0,
        borderRadius:1000, 
        padding:0, 
        zIndex:20,
        cursor:'pointer',
        background:'white', 
        transform:'translate(50%,-50%)'}} class="hidden-child container-deleter"><HighlightOffIcon style={{
          fontSize:18, 
          color:'#333'
        }}/>
    </div>
  )
}


