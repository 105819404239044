
function GridContent({content}){
  let { image, caption, gif, width } = content;
  return (
    <div style={{marginBottom:20}}>
      <div style={{fontSize:12}}>{caption}</div>
      <img alt={caption} style={{
          //border:'1px solid black',

          boxShadow:'0 2px 4px rgba(0 0 0 / 10%), 0 8px 16px rgba(0 0 0 / 10%)',
          boxSizing:'border-box',
          borderRadius:5,

          //height:'50%',
          width:(width||80)+'%',
          objectFit:'contain'}
        }
      src={image||gif}/>
    </div>
  )
}

function Content({content,_id}){
  let text = content.text?.split('\n').map(str => <p>{str}</p>);
  let contentRows;

  let { grid, html } = content;

  if( grid ){
    contentRows = grid.map(gridRow => {
      return <div class="sg-row">{gridRow.map(gridCell => <GridContent {...{content:gridCell}}/>)}</div>
    })
  }


  return (html||text||grid) ? (
    <div style={{height:'100%'}}>
      <h4>{_id}</h4>
      {html || <div style={{fontSize:14,marginLeft:10,marginTop:10}}>{text}</div>}
      <div style={{
        //width:'100%',
        //border:'1px solid black',
        display:'flex',
      }}>
        {content.gif && <img style={{
          //border:'1px solid black',

          boxShadow:'0 2px 4px rgba(0 0 0 / 10%), 0 8px 16px rgba(0 0 0 / 10%)',
          boxSizing:'border-box',
          borderRadius:5,

          //height:'50%',
          width:'80%',
          objectFit:'contain'}} src={content.gif}/>}
      </div>
      <div>
        {contentRows}
      </div>

    </div>
  ) : <div>NO TEXT GIVEN!</div>;


}

export default function DocumentationContent({selectionContent,path}){
  //alert(JSON.stringify({path}));
  let _id = path.split('__').slice(-1)[0];
  let data = selectionContent;
  if( !data ){
    return (
      <div>
        {"No data at: " + data._id}
      </div>
    )
  }
  return (
    <div style={{
      padding:20,
      height:'100%',
      minHeight:0,
      width:'100%',
      //border:'3px solid blue',
      overflow:'scroll',
    }}>
      <Content content={data} _id={_id}/>
      {false && <pre>
        {JSON.stringify(data,null,1)}
      </pre>}
    </div>
  )
}



