import { createContext } from 'react';

export const ExpansionTemplateInfo = createContext();
export const ExpansionWindowFocusContext = createContext({});

export const GridBoundaryAdjustmentContext = createContext();
export const ColumnResizeContext = createContext();
export const CropAdjustmentContext = createContext();
export const FilesystemDragContext = createContext();
export const FilesystemColumnWidthContext = createContext();
export const FilesystemItemHoverContext = createContext();

export const FilesystemHierarchyContext = createContext();

export const QuantificationListContext = createContext();

export const QuantificationUpdateMenuContext = createContext();

export const RowDragContext = createContext({});

export const FilesystemStore = createContext();

export const AnnotationStyleContext = createContext();

export const DiscountContext = createContext();

export const PricingConfigContext = createContext();

export const DrawingContext = createContext();

export const FigureInteractionContext = createContext({});

export const GridCellContext = createContext({});

export const ContextMenuContext = createContext();
