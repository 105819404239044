import G from './Getters';
import A from './ActionCreators';
import { useContext, useRef, useState, useReducer, useEffect } from 'react';
import ColumnAdder from './ColumnAdder';
import { useDispatch } from 'react-redux';
import usePresentSelector from './usePresentSelector';
import activeElementConsumesArrowKeys from './activeElementConsumesArrowKeys';
import TextRowAdder from './TextRowAdder';
import ManualAnnotationGridTable from './ManualAnnotationGridTable';
import { produce } from 'immer'


import { getPointsFromLs, unit, minus, scale, add } from './Geometry';

import { 
  GridBoundaryAdjustmentContext, 
  ColumnResizeContext,
  CropAdjustmentContext,
  RowDragContext,
  ContextMenuContext,
} from './Contexts';


import { MAIN_FIGURE_ID, SCREENSHOT_IGNORE, MINIMUM_ROW_HEIGHT } from './UIConstants';

import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';

import Tooltip from '@material-ui/core/Tooltip';


const doRectsIntersect = function(r1, r2){
  let res = !(r2.left > r1.right || 
    r2.right < r1.left || 
    r2.top > r1.bottom ||
    r2.bottom < r1.top);

  return res;
}



const getRectIntersectionList= function(selectionRect){

  if( Object.values(selectionRect).includes(NaN) ){
    return [];
  }

  let gridItems = Array.from(document.querySelectorAll('td[id*=grid-]'));


  let comparableSelectionRect = {
    ...selectionRect,
    right:selectionRect.left + selectionRect.width,
    bottom:selectionRect.top + selectionRect.height
  }



  let intersectingIds = [];

  gridItems.forEach( item => {
    let br = item.getBoundingClientRect();
    if( doRectsIntersect(comparableSelectionRect, br) ){
      intersectingIds.push( item.id );
    }
  })

  return intersectingIds;

}

const DEFAULT_DRAG_RECT = { rectStart:[],intersections:[], rect:null }

const DEFAULT_STATE = (widths) => ({
    cellDragEntered:[-10,-10],
    multiselect:false,
    mouseIsDown:false,
    lastClickDragArea:0,
    cellResizeInfo:undefined,
    dontUnselectCells:false,
    dragRectInfo:DEFAULT_DRAG_RECT,
    currentWidths:widths,
    contextMenuLocation:undefined,
    verticalImageResizeTranslation:{untilRowIndex:-1,y:0},
})

function reducer(state,setAction){
  //console.log({setAction});
  //return state;

  return produce(state,draft => {
    for(let prop in setAction ){
      let val = setAction[prop];
      draft[prop] = val;
    }
  })

}

function RowDragReducer(state,action){

  //return state;

  let type = Object.keys(action)[0];
  let rowIndex = action[type];

  if( state && state.dragging !== undefined && !['spaceAt','release'].includes(type) ){
    return state;
  }

  switch(type){
    case 'release':{
      return {};
    }
    case 'hover':{
      if( state.dragging !== undefined ){
        return state;
      }
      if( rowIndex === null || rowIndex === undefined ){
        throw Error("Row index must be defined here...");
      }
      return { hovering: rowIndex }
    }
    case 'dragging':{
      if( rowIndex === null || rowIndex === undefined ){
        throw Error("Row index must be defined here...");
      }
      return { 
        ...state,
        dragging: rowIndex 
      }
    }
    case 'spaceAt':{
      return {
        ...state,
        spaceAt:rowIndex
      }
    }
    case 'preDrag':{
      return { 
        ...state, preDrag:true
      }
    }

    case 'releasePreDrag':{
      return {
        ...state,
        preDrag:false,
      };
      if( rowIndex === state.hover ){
        return { }

        /*...state, preDrag:false ,
          hover:false,
        }*/
      }else{
        return state;
      }
    }
    default:
      throw Error("Did not handle type " + type);
  }
}


export default function ManualAnnotationGrid({
  selectedFigureItems,
  rows,
  cols,
  handleArrowKey,

  editable,

  onCropDrop,
  setTemporaryCellValue,

  makeCellSelection,

  cropIdsBeingDragged,
  setCropIdsBeingDragged,

  setSelectedCells,
  selectedCells,

  selectionJustMade,
  setSelectionJustMade,
  cellValueInputRef,
  temporaryCellValue,

  angle,
  isImageManagementOpen,
  gridId,
  hideAddRow,
  figurePanelId,
  demoSelected,
  setDemoSelected,

  rowArrangedGridData,
  tableOutlineShowing,

  isDraggingCrop,


}){



  const [adjustmentSide,setAdjustmentSideContext] = useState();
  const { contextMenuInfo, setContextMenuInfo } = useContext(ContextMenuContext);
  const columnResizeContext = useContext(ColumnResizeContext);
  let { columnResizeInfo, setColumnResizeInfo } = columnResizeContext;

  const [ rowDragState, rowDragDispatch ] = useReducer(RowDragReducer,{});

  let [ cropResizeInfo, setCropResizeInfo ] = useState();


  columnResizeInfo = columnResizeInfo || {};



  if( !figurePanelId ){
    throw Error("ManualAnnotationGrid MUST be passed a 'figurePanelId'");
  }

  let widths = usePresentSelector(state => G.getColumnsWidths(state,{figurePanelId}));
  let doingWalkthrough = usePresentSelector(state=>G.isInTutorial(state));


  const [ localState, localDispatch ] = useReducer(reducer, DEFAULT_STATE(widths));

  /*
  let localState = DEFAULT_STATE(widths);
  function localDispatch(){}
  */

  let setDragRectInfo = value => localDispatch({dragRectInfo:value});
  let setCellDragEntered = value => localDispatch({cellDragEntered:value});
  let setCurrentWidths = value => localDispatch({currentWidths:value});
  let setDontUnselectCells = value => localDispatch({dontUnselectCells:value})
  let setMouseIsDown = value => localDispatch({mouseIsDown:value})
  let setCellResizeInfo = value => localDispatch({cellResizeInfo:value})
  let setContextMenuLocation = value => localDispatch({contextMenuLocation:value});
  let setLastClickDragArea = value => localDispatch({lastClickDragArea:value});
  let setTableOutlineShowing = value => localDispatch({tableOutlineShowing:value})

  let { cellDragEntered,
    multiselect,
    mouseIsDown,
    lastClickDragArea,
    cellResizeInfo,
    dontUnselectCells,
    dragRectInfo,
    currentWidths,
    contextMenuLocation,
    verticalImageResizeTranslation,
    //tableOutlineShowing
  } = localState;


  useEffect(()=>localDispatch({currentWidths:widths}),[widths]);

  let movingRow = rowDragState?.dragging !== undefined;

  useEffect(() => {
    if( !isDraggingCrop && !movingRow ){
      if( localState.cellDragEntered ){
        localDispatch({cellDragEntered:[-10,-10]});
      }
    }
  },[isDraggingCrop,rowDragState])

  //isDraggingCrop = true;

  let horizontalTranslationFromShrinkingACellFromItsLeft = 0;
  if( cellResizeInfo && cellResizeInfo.leftResize ){
    let { currentX, startX } = cellResizeInfo;
    let delta = currentX - startX;
    if( delta > 0 ){
      horizontalTranslationFromShrinkingACellFromItsLeft = delta;
    }
  }

  const dispatch = useDispatch();


  const isCellSelected = (cell,selectedCells) => {
    let id = "grid-"+cell[0]+"-"+cell[1]; 
    let actuallySelected = selectedCells.map(JSON.stringify).includes(JSON.stringify(cell));

    if( actuallySelected ){ return true; }


    let isAlmostSelectedByDrag = dragRectInfo.intersections.includes(id);

    return isAlmostSelectedByDrag;

  }





  const processArrowKeys = e => {


    if( activeElementConsumesArrowKeys() ){
      return;
    }

    if( doingWalkthrough ){
      console.log("No arrow keys while doing tutorial.");
      return;
    }


    handleArrowKey(e.key,e);


  }

  const onKeyDown = e => {

    processArrowKeys(e);
    if( e.key === 'Shift' || e.key === 'Control' ){
      localDispatch({multiselect:true})//setMultiselect(true);
    }


  }

  const onKeyUp = e => {


    if( e.key === 'Shift' || e.key === 'Control' ){
      localDispatch({multiselect:false});
      //setMultiselect(false);
    }

  }



  const pointInsideTable = ([X,Y]) => {
    let tableElement = document.getElementById('figure');
    if( !tableElement ){ return false; }

    let rect = tableElement.getBoundingClientRect();

    let inXRange = rect.left <= X && X <= rect.left + rect.width;
    let inYRange = rect.top <= Y && Y <= rect.top + rect.height;

    return inXRange && inYRange;

  }

  const eventOnCropResizer = e => {

    let path = e.composedPath();

    if( path.includes && path.some(comp => comp.className && comp.className.includes && comp.className.includes('crop-resizer')) ){
      return true;
    }
    return false

  }

  const onMouseDown = e => {



    if( e.which === 3 || eventOnTopToolbar(e) || eventOnContextMenu(e) || eventOnCropResizer(e) || eventOnRowDragButton(e) ){

      return;
    }


    if( contextMenuLocation ){

      localDispatch({contextMenuLocation:null});
    }


    if( contextMenuInfo ){


      setContextMenuInfo();
    }

    if( isImageManagementOpen ){
      return;
    }

    e.stopPropagation();

    let X = e.clientX
    let Y = e.clientY

    //setMouseIsDown(pointInsideTable([X,Y]));
    if( !isDraggingCrop ){
      localDispatch({
        mouseIsDown:(pointInsideTable([X,Y])),
        dragRectInfo:{ ...dragRectInfo, rectStart:[X,Y] }
      });
    }


  }

  const getDragRectArea = () => {
    let rect = dragRectInfo.rect;
    if( !rect ){ 
      return 0; 
    }

    let area = (rect.width||1) * (rect.height||1);
    return area;
  }


  const onMouseUp = e => {




    if( e.which === 3 || eventOnContextMenu(e)  || eventOnTopToolbar(e) ){
      return;
    }


    if( cropResizeInfo ){




      const handleCropResizeEnd = (cropResizeInfo) => {

        let { tempInlineCrop, inlineCrop, side, annotation, annotationChanges } = cropResizeInfo;


        if( side.includes('rotation') ){
          setCropResizeInfo(null);

          dispatch(A.setAnnotationProperties({
            _id:annotation._id,
            ls:annotationChanges.ls,
          })) 

          return;


        }

        let newHeight = tempInlineCrop.height;
        let oldHeight = inlineCrop.height;
        let halfHeightDelta = (newHeight - oldHeight) / 2; 
        halfHeightDelta *= (side === 'top' ? -1 : 1);

        let [p0,p1,p2,p3] = getPointsFromLs(annotation.ls, oldHeight);


        let unitLs = unit(minus(annotation.ls[1],annotation.ls[0]))

        let unitLsPerp = unit(minus(p3,p0));

        if( ['left','right'].includes(side) ){
          halfHeightDelta = 0;
        }




        let shifter = scale(halfHeightDelta,unitLsPerp)

        let newLs = annotation.ls.map(point => add(point, shifter));

        let leftDelta = (tempInlineCrop.left - inlineCrop.left);
        let rightDelta = (tempInlineCrop.width - inlineCrop.width);

        if( side === 'left' ){
          rightDelta = 0;
        }


        let newLs0 = add( newLs[0], scale(leftDelta, unitLs) )
        let newLs1 = add( newLs[1], scale(rightDelta, unitLs) )

        let lsUpdatedWithHorizontalChanges = [
          newLs0,
          newLs1
        ]




        //setTempInlineCrop(null);
        setCropResizeInfo(null)
        localDispatch({verticalImageResizeTranslation:{
          untilRowIndex:0,
          y:0
        }})

        dispatch(A.setAnnotationProperties({
          _id:annotation._id,
          ls:lsUpdatedWithHorizontalChanges,
          height:newHeight
        })) 
      }

      handleCropResizeEnd(cropResizeInfo);
      e.stopPropagation();



      return;
    }


    localDispatch({
      mouseIsDown:false,
      dragRectInfo:{
        rectStart:[],
        rect:null,
        intersections:[]
      }
    })


    //setMouseIsDown(false);
    //setDragRectInfo()

    if( cellResizeInfo ){



      let cell = cellResizeInfo.cell;
      let cellX = cellResizeInfo.cellX;

      let cellsToSet = currentWidths.map((_,ii) => ([0,ii]))
      /*
      let widths = [currentWidths[ cellX ]]
      if( !cellResizeInfo.leftResize ){
        if( currentWidths[ cellX + 1 ] ){
          cellsToSet.push([cell[0], cellX + 1])
          widths.push( currentWidths[ cellX + 1 ] ) 
        }
      }*/

      dispatch(
        A.setCellWidths({
          figurePanelId,
          cellsToSet,
          widths:currentWidths
        })
      )

      setCellResizeInfo();
      setAdjustmentSideContext();



      const findIfHoveringColumnResize = () => {

        let hoveredElementsByMouse = document.querySelectorAll(":hover");
        let widthResizerClassName = ('cell-width-resizer-element')
        let isHoveringResize = Array.from(hoveredElementsByMouse).find(ele => ele.className?.includes(widthResizerClassName));

        return isHoveringResize;
      };

      let isHoveringColumnResize = findIfHoveringColumnResize();

      if( !isHoveringColumnResize ){
        setColumnResizeInfo();
      }

      setCurrentWidths(null);

      setCropIdsBeingDragged([]);



      return;

    }


    let curDragRectArea = getDragRectArea();
    setLastClickDragArea(curDragRectArea);

    if( !mouseIsDown ){


      let path = e.composedPath();


      let classNames = Array.from(path).map(ele => ele.className);
      let tagNames = Array.from(path).map(ele => ele.tagName);
      if( classNames.some((cl,ii) => {
        if( typeof(cl) !== 'string' ){
          return false;
        }
        let hasBadTag = ['INPUT','TEXTAREA'].includes(tagNames[ii]);


        //without these classes, we'd clear the current 
        //figureItemSelection
        let hasBadClass = ['MuiListItem-root','value-textarea','style-toolbar','grid','toolbar-button','crop-resizer','figure-info-sidebar'].some(x => cl.includes(x));


        let badPropertyMappings = {
          filesystemcontainer:'crops',
          action:'rowDragButton',
          //valuetype:"crop-expansion",
        }

        let hasBadPropertyMapping = Object.entries(badPropertyMappings).some(([key,value]) => path[ii].getAttribute(key) === value);

        let matchesSkips = hasBadTag || hasBadClass || hasBadPropertyMapping;

        let elementIdsToCheckIntersection = ['figure-info-sidebar'];

        if( !matchesSkips ){

          if(elementIdsToCheckIntersection.findIndex(id => {
            let ele = document.querySelector('#'+id);
            if( ele ){
              let bounds = ele.getBoundingClientRect();

              let leftX = bounds.left;
              let rightX = bounds.left + bounds.width;
              let top = bounds.top;
              let bottom = bounds.top + bounds.height;

              let inX = leftX <= e.clientX && e.clientX <= rightX;
              let inY = top <= e.clientY && e.clientY <= bottom;

              let inBounds = inX && inY;
              if( inBounds ){
                return true;
              }else{
                //alert("Not in bounds! " + JSON.stringify({leftX, rightX, top, bottom}) + " " + JSON.stringify([e.clientX,e.clientY]));
              }

            }
          }) !== -1){
            return true;
          }


        }else{
          return matchesSkips;
        }


      }) ){

        return;
      }else{
      }



      if(cropIdsBeingDragged.length > 0 ){
        setCropIdsBeingDragged([]);
      }

      makeCellSelection([]);

      rowDragDispatch({release:true});

      return; 
    }

    let intersectionIds = dragRectInfo.intersections;
    let cells = intersectionIds.map( id => id.split('-').filter((x,ii)=>ii>0).map(
      item => Number(item)
    ).filter(xx => !isNaN(xx)));


    setDragRectInfo({
      rectStart:[],
      rect:null,
      intersections:[]
    })


    if( intersectionIds.length === 0 ){
      return; 
    }

    makeCellSelection(cells);


  }



  const onMouseMove = e => {


    /*if( !leftButtonDown && dragRectInfo.rectStart.length > 0 ){
      setDragRectInfo(DEFAULT_DRAG_RECT);
    }*/

    let X = e.clientX;
    let Y = e.clientY



    if( cropResizeInfo ){





      function handleAdjustmentEnd(){}

      let { 
        side,
        startX, 
        startY, 
        inlineCrop, 
        tempInlineCrop,
        cellLocation,
        cellWidthOfCropBeingAdjusted,
        annotation,
        globalState
      } = cropResizeInfo;


      let deltaX = X - startX;
      let deltaY = Y - startY;

      let leftButtonIsDown = e.buttons === 1;
      if( !leftButtonIsDown ){
        handleAdjustmentEnd(side);
        return;
      }


      let adjustingHorizontally = ['left','right'].includes(side);

      //let deltaY = e.clientY - adjustingCrop.yStart;

      if( side === 'bottom' ){



        let potentialNewHeight = inlineCrop.height + (deltaY * inlineCrop.width / cellWidthOfCropBeingAdjusted );



        let potentialNewCellHeight = cellWidthOfCropBeingAdjusted * (potentialNewHeight / inlineCrop.width);

        if( potentialNewCellHeight < MINIMUM_ROW_HEIGHT ){
          return;
        }

        let newTempInlineCrop = {
          ...inlineCrop,
          height:potentialNewHeight
        }
        setCropResizeInfo({
          ...cropResizeInfo,
          tempInlineCrop:newTempInlineCrop,
          cellWidthOfCropBeingAdjusted
        });

        //setTempInlineCrop(newTempInlineCrop)


        /*
         *
         * The localDispatch
         * here is ONLY here because 
         * I saw that adjustments from the top
         * were firing the local onMouseUp event
         * and so I tried it here and this makes it
         * also fire that event.
         */
        localDispatch({

          verticalImageResizeTranslation:{
            untilRowIndex:0,
            y:0
          }
        })

      }else if( side === 'top' ){

        let change = -(deltaY * inlineCrop.width / cellWidthOfCropBeingAdjusted )

        let potentialNewHeight = inlineCrop.height + change;

        let potentialNewCellHeight = cellWidthOfCropBeingAdjusted * (potentialNewHeight / inlineCrop.width);


        if( potentialNewCellHeight < MINIMUM_ROW_HEIGHT ){
          return;
        }

        let newTempInlineCrop = ({
          ...inlineCrop,
          top:inlineCrop.top - change,
          height:potentialNewHeight,
          translate:deltaY
        })

        setCropResizeInfo({
          ...cropResizeInfo,
          tempInlineCrop:newTempInlineCrop,
          cellWidthOfCropBeingAdjusted
        });


        localDispatch({
          verticalImageResizeTranslation:{
            untilRowIndex:cellLocation[0],
            y:deltaY
          }
        })
      }else if( adjustingHorizontally ){
        //let deltaX = (e.clientX - xStart);


        let change = (deltaX * inlineCrop.width / cellWidthOfCropBeingAdjusted);
        if( side === 'left' ){
          change *= -1;
        }

        let hOverWConstant =  inlineCrop.height / inlineCrop.width ;
        let newWidth = inlineCrop.width - change;
        let newHeight = newWidth * hOverWConstant;

        //let heightChange = (totalArea / (inlineCrop.width - change)) - inlineCrop.height; 

        let heightChange = newHeight - inlineCrop.height;
        let newTop = inlineCrop.top - (heightChange/2);

        let newInlineCrop = {
          ...inlineCrop,
          width:newWidth,
          top:newTop,
          height:newHeight //inlineCrop.height + heightChange
        }
        if( side === 'left' ){
          newInlineCrop.left = inlineCrop.left + change;
        }



        setCropResizeInfo({
          ...cropResizeInfo,
          tempInlineCrop:newInlineCrop
        });


        /*
         *
         * The localDispatch
         * here is ONLY here because 
         * I saw that adjustments from the top
         * were firing the local onMouseUp event
         * and so I tried it here and this makes it
         * also fire that event.
         */
        localDispatch({
          verticalImageResizeTranslation:{
            untilRowIndex:0,
            y:0
          }
        })


      }else if( side.includes("rotation") ){

        let change = -(deltaY * inlineCrop.width / cellWidthOfCropBeingAdjusted )
        let fromLeft = side.includes("left") ? 1 : 0;
        let fromRight = fromLeft ? 0 : 1;

        let [p0,p1] = annotation.ls;
        let [ x0, y0 ] = p0;
        let [ x1, y1 ] = p1;

        let newY0 = y0 + change * fromLeft;
        let newY1 = y1 + change * fromRight;

        let newLs = [
          [x0,newY0],
          [x1,newY1]
        ]

        let newInlineCrop = G.getCropFromAnnotation(globalState,annotation._id,{ls:newLs});

        setCropResizeInfo({
          ...cropResizeInfo,
          tempInlineCrop:newInlineCrop,
          annotationChanges:{
            ls:newLs
          }

        })

        /*
        localDispatch({ })
        */





      }
      return;
    }


    if( isImageManagementOpen ){
      return;
    }








    if( adjustmentSide && !cellResizeInfo ){
      //means that we are currently adjusting some crop
      //from the side
      return;
    }

    /*
    if( columnResizeInfo && !cellResizeInfo ){
      setColumnResizeInfo();
    }
    */


    if( cellResizeInfo !== undefined ){
      setCellResizeInfo({
        ...cellResizeInfo,
        currentX:X
      })

      // if started on left:
      //  expand if move to left (delta will be -ve)
      //  shrink if move to right (delta will be +ve)
      //
      // if started on right:
      //  expand if move to right (delta will be +ve)
      //  shrink if move to left (delta will be -ve)

      let delta = (X - cellResizeInfo.startX);

      if( cellResizeInfo.leftResize === true && columnResizeInfo.impactedRanges.length === 1 ){
        //everything is backwards if done from the left
        delta *= -1
      }

      //let currentWidthToAdjust = currentWidths[cellResizeInfo.cellX];
      let newWidth = widths[cellResizeInfo.cellX] + delta;

      let {cellXList} = cellResizeInfo;




      setCurrentWidths(currentWidths.map((w,ii) => {

        let { impactedRanges } = columnResizeInfo;
        let impactedRangeCount = impactedRanges.length;




        if( impactedRangeCount === 1 ){ 
          let range = impactedRanges[0];
          let cellsImpacted = range[1] - range[0];
          let newCellWidth = Math.max(5, (widths[ii] + (delta/cellsImpacted)));
          return (
            range[0] <= ii && ii < range[1]
          ) ? newCellWidth : w
        }else if( impactedRangeCount > 1 ){

          let positionInImpactedRanges = columnResizeInfo.impactedRanges.findIndex(range => {
            return range[0] <= ii && ii < range[1]
          });

          let columnToResizeInLeftRange = positionInImpactedRanges === 0;
          let columnToResizeInRightRange = positionInImpactedRanges === 1;
          let columnToResizeOutsideImpactedRanges = positionInImpactedRanges === -1;


          if( delta > 5 || delta < -5 ){
            //debugger;
          }





          if( delta === 0 || columnToResizeOutsideImpactedRanges ){
            return w;
          }
          else if( columnToResizeInLeftRange ){
            let rangeLen = impactedRanges[0][1] - impactedRanges[0][0];
            let increment = (delta / rangeLen)
            if( delta > 0 ){

              return Math.max(5,widths[ii] + increment);
            }else{
              return Math.max(5,widths[ii] + increment);
            }
          }else if( columnToResizeInRightRange ){

            let rangeLen = impactedRanges[1][1] - impactedRanges[1][0];
            let increment = (delta / rangeLen)


            if( delta > 0 ){
              return Math.max(5,widths[ii] - increment);
            }else{
              return Math.max(5,widths[ii] - increment);
            }

          }


        }

      }))//.splice(cellResizeInfo.cellX,1, newWidth))

      return;
    }


    //drag rect
    if( e.buttons === 1 ){

      let { rectStart } = dragRectInfo;



      let left = Math.min(rectStart[0],X);
      let top = Math.min(rectStart[1],Y);

      let right = Math.max(rectStart[0],X)
      let bottom = Math.max(rectStart[1],Y)

      let width = right - left;
      let height = bottom - top;

      let newSelectionRect = {left,top,width,height};
      let rectIntersectionList = getRectIntersectionList(newSelectionRect);
      let numInts = rectIntersectionList.length;


      let newDragRectInfo = {
        rectStart,
        intersections:rectIntersectionList,
        rect:newSelectionRect
      }

      setDragRectInfo(newDragRectInfo);
    }

  }

  const eventOnRowDragButton = e => {

    let path = e.composedPath();
    let onRowDragButton = path.find(comp => {
      let getAttribute = comp?.getAttribute;

      //document object doesn't have getAttribute function
      if( !getAttribute ){
        return false;
      }
      return getAttribute && comp?.getAttribute('action') === 'rowDragButton'
    })
    /* && comp.className.includes && comp.className.includes('sg-context-menu'));*/

    return onRowDragButton;


  }







  const onClick = () => {
    //needed to put this in because for some
    //reason the mouseup isn't triggered...
    //it may be because the event is dispatch
    //before the mouse can come up
    //which causes react to remove components
    //and then reattach them, but it
    //misses the onmouse up event? 
    //not sure what's going on there,
    //but added this and I think it works...


    localDispatch({dragRectInfo:DEFAULT_DRAG_RECT})
  }


  


  useEffect(() => {


    if( editable !== false ){


      window.addEventListener('click',onClick);
      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('keyup', onKeyUp);
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mousedown',onMouseDown);
      window.addEventListener('mouseup', onMouseUp);

      return () => {


        window.removeEventListener('click',onClick);

        window.removeEventListener('keydown', onKeyDown);
        window.removeEventListener('mousedown',onMouseDown);
        window.removeEventListener('mouseup', onMouseUp);
        window.removeEventListener('keyup', onKeyUp);
        window.removeEventListener('mousemove', onMouseMove);

      }
    }
  });





  const clearCellSelection = (e) => {

    let nothingSelected = Object.keys(selectedFigureItems).length === 0;

    if( nothingSelected ){
      return;
    }

    if( selectionJustMade ){
      setSelectionJustMade(false);
    }else if( dontUnselectCells ){
      localDispatch({dontUnselectCells:false})
    }else{
    }
  }


  //let [currentWidths, setCurrentWidths] = useState(widths);
  let marginLeft = 50;
  //let conservedWidth = !(columnResizeInfo?.impactedRanges.length === 1);
  if( cellResizeInfo?.leftResize === true /*&& !conservedWidth*/ ){

    let DELTA = Math.max(0,
      cellResizeInfo.startX - cellResizeInfo.currentX
    )
    marginLeft -= DELTA;
  }

  const editableTableRef = useRef();

  let gridTableProps = {
    editableTableRef,
    dispatch,
    editable, hideAddRow, verticalImageResizeTranslation, tableOutlineShowing,
    figurePanelId,
    isDraggingCrop,
    setCropIdsBeingDragged,
    cropIdsBeingDragged,
    cellDragEntered,
    setCellDragEntered,
    rowArranged:rowArrangedGridData,
    localDispatch,
    setDemoSelected,
    demoSelected,
    angle,
    onCropDrop,
    makeCellSelection,
    isCellSelected,
    selectedCells,
    multiselect,
    setCurrentWidths,
    setCellResizeInfo,
    setDontUnselectCells,
    temporaryCellValue,
    widths,
    currentWidths,
    setMouseIsDown,
    cellResizeInfo,

  }

  let [topRowAdder,bottomRowAdder] = [0,rows].map(rowIndex => (
    <TextRowAdder {...{figurePanelId,rowIndex}}/>
  ))



  return (
    <RowDragContext.Provider value={{rowDragState,rowDragDispatch}}>



      <div class="sg-row full-width" onClick={e => {
        if( lastClickDragArea === 0 ){
          clearCellSelection(e)
          setCropIdsBeingDragged([]);

        }
      }}
        style={{
          position:'relative',
          minHeight:0,
          minWidth:0,
          
        }}>


        <div 
          class="full-width"
          style={{
            //when I had position:relative here before, it made there be some hidden extended div with scroll on the page's right side that was super annoying
            //marginLeft,
            //left:marginLeft,
            top:25,
            transform:'translate('+horizontalTranslationFromShrinkingACellFromItsLeft+'px,0)',
            display:'flex',
            //gridTemplateColumns:'100px auto 100px',
          }}
          onClick={e => {
            if( lastClickDragArea === 0 ){
              clearCellSelection(e) 
              setCropIdsBeingDragged();
            }
          }}>


          <div class="sg-col" style={{
            color:(tableOutlineShowing?undefined:'white'),
            marginLeft:30,
            marginRight:10,
            justifyContent:'center',
            width:45,


          }}>

            {true && <ColumnAdder {...{rows, columnIndex:0, figurePanelId}}/>}
          </div>


          <div class="sg-col" style={{
            //flex:'0 0 auto',
            minWidth:0,
            minHeight:0,
            position:'relative',
          }}>

            {topRowAdder}
          

          <div 
            ref={editableTableRef}
            class="scroll-shadows-vertical "
            style={{
              overflow:'auto',
              minHeight:0,
              paddingLeft:50,
              paddingRight:50,
              //boxShadow:'inset 0 0 10px black',
              minWidth:0,
            }}>



            <CropAdjustmentContext.Provider value={{cropResizeInfo,setCropResizeInfo}}>
              <GridBoundaryAdjustmentContext.Provider value={{adjustmentSide,setAdjustmentSideContext}}>
                <ManualAnnotationGridTable {...gridTableProps}/>

              </GridBoundaryAdjustmentContext.Provider>
            </CropAdjustmentContext.Provider>
            



          </div>

            {bottomRowAdder}

          </div>

          <div class="sg-col" style={{
            //paddingLeft:20,
            alignItems:'start',
            justifyContent:'center',
            color:(tableOutlineShowing?undefined:'white')}}>

            {tableOutlineShowing && <ColumnAdder {...{
              rows,
              columnIndex:cols,
              figurePanelId
            }}/> }
          </div>

          


        </div>

    {mouseIsDown && <div style={{
        background:'black',
        opacity:0.5,
        position:'fixed',
        overflow:'visible',
        ...dragRectInfo.rect,
        color:'magenta'
      }}>
      </div>}






        
      </div>
    </RowDragContext.Provider>
  )

}



const eventOnContextMenu = e => {

  let path = e.composedPath();
  let containsContextMenu = path.find(comp => comp.className && comp.className.includes && comp.className.includes('sg-context-menu'));

  return containsContextMenu;


}

const eventOnTopToolbar = e => {

  let path = e.composedPath();
  let containsToolbar = path.find(comp => comp.id === 'top-editor-toolbar');

  return containsToolbar;

}
