import { useState, useContext } from 'react';
import { GridCellContext } from './Contexts';
import RotationContainerSvg from './RotationContainerSvg';
import G from './Getters';

import { AnnotationStyleContext } from './Contexts';
export default function SubregionOutlineLayer({
  node,
  nodeId,
  nodes,
  state,
  inlineCrop,
  imageSetId,
  regions,
  subregionAtns,
  selectedNodes,
  annotationId,

  onEvent,

}){


  /*
   nodeId selected => regionId
  */


  let gridCellContext = useContext(GridCellContext);

  let { cellLocation } = gridCellContext;


  //let annotationId = regions[ node.value.regionId ];
  let nodeChildren = node.c;
  if( !nodeChildren ){
    debugger;
  }
  let regionOutlineNodeIds = nodeChildren.filter(x => nodes[x].type === "regionOutline")



  const [ containerBounds, setContainerBounds ] = useState({});

  


  let atnIdToRegionId = {};
  let atnIdToNodeId = {};
  let stylingByAnnotationId = {};
  let { regionOutline } = node.value;

  regionOutlineNodeIds.forEach(regionOutlineNodeId => {
    let regionOutlineNode = nodes[regionOutlineNodeId];
    let { regionId } = regionOutlineNode;
    let atnId = regions[regionId];
    atnIdToRegionId[atnId] = regionId;
    atnIdToNodeId[atnId] = regionOutlineNodeId;

    if( atnId ){
      stylingByAnnotationId[ atnId ] = {...(regionOutlineNode.style || {}),
        pointerEvents:'auto',
      };


      let selected = selectedNodes?.find((item) => {
        let thatNodeId = item.nodeId;
        let thatNode = item;

        let eqCL = thatNode.cellLocation.every(
          (x,ii) => x === cellLocation[ii]
        );


        return thatNodeId === regionOutlineNodeId &&  eqCL;
      })

      if( selected ){
        stylingByAnnotationId[atnId].fill = "#33aaff";
        stylingByAnnotationId[atnId].fillOpacity = "0.5";

      }



    }
  })


  let atns = G.getRelativeLsAnnotationsBoundedByAnnotationId(state,{annotationId}).filter(x => atnIdToRegionId[x._id]);

  return (
    <div style={{ top:0, left:0, height:'100%', width:'100%', position:'absolute',
      pointerEvents:'none',
    }}
      ref={el => {
        if( el ){
          let cr = el.getBoundingClientRect();
          let crDim = { width:cr.width, height:cr.height, left:cr.left, top:cr.top };

          if( JSON.stringify(crDim) !== JSON.stringify(containerBounds) ){
            setContainerBounds(crDim);
          }

        }
      }}
    >
      {containerBounds && (

        <AnnotationStyleContext.Provider value={{
          interactive:false,
          mwMarkers:{
            point:{ 
              r:2
            },
            label:{
              extrasSurroundingLabelForMovability:false
            }
          },
          cropBoxes:{
            rotationHandles:false
          },
          stylingByAnnotationId,
          defaultStyling:{
            interactive:false,
            stroke:"#ffffff",
            strokeWidth:1,
          }

        }}>
          <RotationContainerSvg {...{
            interactive:false,
            atns,
            imageViewTransformation:inlineCrop,
            imageSetId,
            imgContainerBounds:containerBounds,
            crop:{
              top:0,
              left:0,
              width:1,
              height:0.667
            },
            setFocusedAnnotationId:(annotationId,e) => {

              e.stopPropagation();

              let targetedNodeId = atnIdToNodeId[annotationId];

              onEvent({
                type:e.type,
                itemType:"expansionNode",
                node:nodes[targetedNodeId],
                nodeId:targetedNodeId
              })

            }
          }}/>

        </AnnotationStyleContext.Provider>
      )}
    </div>
  )

}
