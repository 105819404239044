import './wdyr';

import React from 'react';


import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';


import ItemDragDev from './ItemDragDev';

import { HTML5Backend, getEmptyImage } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import initErrorHandler from './initErrorHandler';
import JournalDemo from './JournalDemo';
import SurveyContainer from './SurveyContainer';

import { Provider } from 'react-redux'

import ErrorBoundary from 'react-error-boundary';
//import { loadState, saveState } from './localStorage';

import AppWrapper from './AppWrapper';
import C from './Constants';
import initSession from './initSession';
import initSocket from './initSocket';

import LetterMetrics from './LetterMetrics.js';

import A from './ActionCreators';

import fetch from 'cross-fetch';


import isBrowserSupported from './browserSupportCheck';
import BrowserSupportMessage from './BrowserSupportMessage';

import { getSessionInfo, getBuildVersion, isUsingWebdriver } from './versionConfig';
import initRrweb from './initRrweb';


import CellCultureDesignForm from './CellCultureDesignForm';

import MicroscopyLayoutDev from './MicroscopyLayoutDev';





//import INITIAL_STATE from './savedStates/walkthroughSetup.json';


//import INITIAL_STATE from './savedStates/quantifyImagesNeedingInvert.json';

//import INITIAL_STATE from './savedStates/createVideoFigure.json';

//import INITIAL_STATE from './savedStates/16bitImageQuant.json';
//import INITIAL_STATE from './savedStates/setFigureImagePrepState.json';

//import INITIAL_STATE from './savedStates/demoGrouped.json';

//import INITIAL_STATE from './savedStates/createFigureImageRepresentationTemplates.json'



//import INITIAL_STATE from './savedStates/getAbsoluteLsGeomtryFromTransformed.json'

import INITIAL_STATE from './savedStates/createFigureFromHardCodedTemplate.json'
//let INITIAL_STATE;



if( !Object.entries ){

  Object.prototype.entries = (obj) => {
    let vals = Object.values(obj);
    let keys = Object.keys(obj);
    return keys.map((k,ii) => ([k,vals[ii]]));
  }
}
if( !Object.fromEntries ){
  Object.fromEntries = function(entries){
    let newObj = {};
    entries.forEach(([key,val]) => {
      newObj[key] = val;
    }) 
    return newObj; 
  }
}



if( process.env.NODE_ENV === 'production' ){
  fetch('/onSiteLoad')
}

if( process.env.NODE_ENV === 'production' ){
  initRrweb()
}

//let middlewares = [ analyticsMiddleware, thunk ];
function customFilter(action,currentState,previousHistory){
  //debugger;
}

//console.log("initialState");
//console.log(INITIAL_STATE)

let remoteDevTools = false;
if( process.env.NODE_ENV !== 'production' ){
  remoteDevTools = true;
  window.__TESTING_ERROR = true;
}

function getInjectedInitialState(){
  let envContainer = (window||process);
  return envContainer.__injectedState;
}


function getInitialState(){
  let importedInitialState;
  let injectedInitialState;


  if( typeof(INITIAL_STATE) !== typeof(undefined) ){
    if(false){
      //let INITIAL_STATE;
    }

    function getCookie(name) {
      //copied from stack overflow (https://stackoverflow.com/questions/10730362/get-cookie-by-name)
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    if( isUsingWebdriver() ){

      //window.__injectedState;


      if( !window.__testMeta ){
        window.__testMeta = {};
      }

        let agentId = getCookie("agentId");
        let sessionId = getCookie("sessionId");
        window.__testMeta.agentId = agentId;
        window.__testMeta.sessionId = sessionId;
        window.__testMeta.windowSessionId = sessionId;
      return;
    }


    importedInitialState = INITIAL_STATE;
  }

  injectedInitialState = getInjectedInitialState();

  return injectedInitialState || importedInitialState;



}

let initialState;
if( process.env.NODE_ENV !== 'production' ){
  initialState = getInitialState();
}



let websocketTarget;
if( window.location.href.includes('sciugo') ){
  websocketTarget = "https://sciugo.com"
}else{
  websocketTarget = "http://10.0.0.222:80"
}

function getCookieMap(){
  let map = Object.fromEntries(
    document.cookie.split('; ').map(x => x.split('='))
  )
  return map;
}


//let cookieMap = getCookieMap();
let personId = (getCookieMap().personId);

if( process.env.NODE_ENV === 'development' ){
  personId = "dev";
}

console.log({personId});




let session = initSession({
  initialState,//:undefined,
  remoteDevTools,
  websocketTarget,
  personId,
});

let wsArgs = { remoteDevTools, websocketTarget, personId };


let shouldCreateSocket = true;

if( process.env.NODE_ENV !== 'production' ){
  //have this here to maybe speed up UI tests?
  //most of them don't need to establish a websocket connection
  if( window.__testMeta ){
    shouldCreateSocket = false;
  }
}

if( shouldCreateSocket ){
  session.socket = initSocket(session.store,wsArgs);
}

let { store, socket } = session;


if( process.env.NODE_ENV !== 'production' ){
  window.__reduxStore = store;
  window.__actionCreators = A; 
}


let route = document.URL.split('/')[3];
let path = route !== '' ? route : '';

store.dispatch( A.initSession(path) );
store.dispatch( A.fetchThreads() );

if( process.env.NODE_ENV !== 'production' ){

  //store.dispatch(A.setUiMode({mode:"FIGURES_AND_PANELS", modeArgs:{ _id:"fp0", figurePanelId:"fp0" }}));

  if( !isUsingWebdriver() ){

      /*
  window.__invoke = () => {}
      */
  }
  
}



window.__sendEvents = function(){
  store.dispatch( A.sendDomEvents() );
}

/*store.dispatch(
  A.initSession()
)*/

window.__hookTrace = { useState:null };

window.__setState = state => {
  store.dispatch(A.setState({state}));
}

window.onbeforeunload = function(){
  return !document.location.href.includes('localhost')
}

window.addEventListener('beforeunload',() => {
  socket.then(s => s.disconnect())

  if( window.__streamEvents ){
    window.__streamEvents();
  }

  store.dispatch(A.endSession());
})




if( !process.env.REACT_APP_BUILD_VERSION ){
  throw Error("No build version provided.");
}

if( process.env.NODE_ENV !== 'production' ){
  window.__sciugoMocks = {
    ".*s3.*aws":{body:'<xml></xml>',options:{status:500}}
  };

}

const handleError = initErrorHandler(store);

window.addEventListener('error',handleError)
window.addEventListener('unhandledrejection',handleError)

if( process.env.NODE_ENV !== 'production' ){
  if( isUsingWebdriver() ){
    window.addEventListener('mousemove',function(e){
      let nativeEvent = e.nativeEvent;
      //console.log([e.clientX,e.clientY]);
      let div = document.createElement("DIV");
      div.style['pointer-events'] = 'none';
      div.style.width = '5px';
      div.style.height = '5px';
      div.style.background = 'lime';
      div.style.position = 'fixed';
      div.style.top = ''+e.clientY+'px';
      div.style.left = ''+e.clientX+'px';
      div.id = 'mouse-tracker';
      div.style['z-index'] = '1000000';


      let currentTracker = document.querySelector('#mouse-tracker');
      if( currentTracker ){
        currentTracker.parentNode.removeChild(currentTracker);
      }

      document.body.appendChild(div);



    })
  }
}




window.__errorHandler = handleError;


window.blobToDataString = function(blobPath){
  fetch(blobPath).then(r => r.text()).then(console.log);
}

let browserSupport;
try{
  browserSupport = isBrowserSupported();
  browserSupport = { supported:true };
  if( !Object.fromEntries ){
    browserSupport = { supported:false, agent:'Unknown browser, you may need to update your browser.' };
  }
}catch{
  browserSupport = { supported:false, agent:"Unknown browser, you may need to update your browser." }
}

let toRender = browserSupport.supported ? <AppWrapper key={Math.random()}/> : <BrowserSupportMessage browserSupport={browserSupport}/> 

  //toRender = <MicroscopyLayoutDev/>

  //toRender = <CellCultureDesignForm/>

  //toRender = <JournalDemo/>

  //toRender = <LetterMetrics/>

  //toRender = <SurveyContainer/>
  //toRender = <ItemDragDev/>

  ReactDOM.render(
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
      <React.StrictMode>
        {toRender}
      </React.StrictMode>
      </DndProvider>
    </Provider>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
