import { useState, useRef } from 'react';
import ColorPicker from './ColorPicker';
import Popover from '@material-ui/core/Popover';
import ScalebarTextPlacementStructure from './ScalebarTextPlacementStructure';

export default function ScalebarEditor({
  data,
  onEvent,
}){


  const [ colorPopoverOpen, setColorPopoverOpen ] = useState(false);

  const colorPreviewRef = useRef();

  let { textPosition, style={} } = data;
  let { color="red" } = style;

  if( data.computed.label.includes('?') ){
    return (
      <div class="fair-margin">
        Set image resolution
      </div>
    )
  }


  const unitsOrder = [
    "nm",
    "μm",
    "mm",
    "cm",
    "dm",
    "m",
    "%"
  ]

  let isProportionWidth = data.width.units === "%";
  let maxValue = isProportionWidth ? 100 : null;


  return (

    <div class="fair-padding">

      <div style={{marginBottom:20}} class="sg-row">
        <div>
          <div>Length</div>
          <input 
            input={"scalebar-target-length"}
            onChange={e => {
              onEvent({
                type:"change",
                event:e,
                properties:{
                  "width.value":Number(e.target.value)
                }
              })
            }}
            min={0}
            max={ maxValue }
            type="number"
            value={data.width.value}
            style={{width:50}}/>
        </div>
        <div style={{marginLeft:10}}>
          <div>Units</div>
          <select defaultValue={"μm"} value={data.width.units} onChange={e => {
            onEvent({
              type:"change",
              event:e,
              properties:{
                "width.units":e.target.value
              }
            })
          }}>
            {unitsOrder.map(option => {
              return (
                <option value={option}>
                  {option}
                </option>
              )
            })}
           
          </select>
        </div>

          <div style={{marginLeft:10}}>
          <div>Color</div>

          <div ref={colorPreviewRef} onClick={() => {
            setColorPopoverOpen(!colorPopoverOpen);
          }} style={{
          position:'relative',
          width:20,
          height:20,
          borderRadius:2,
          background:(data.style.color),
          border:'1px solid #aaa',
        }}>


      </div>

            {colorPopoverOpen && <Popover
              style={{ 
                zIndex:0,
                //width:240,
              }}
              className={"style-toolbar"}
              open={true}
              anchorEl={colorPreviewRef.current}
              onClose={() => setColorPopoverOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{position:'relative',
                width:240
              }}>
             <ColorPicker {...{
              onChange:color => {
                onEvent({
                  type:"change",
                  properties:{
                    "style.color":color
                  }
                })
                setColorPopoverOpen(false);
              },
              color:data.style.color,
            }}/> 
              </div>
            </Popover>}
          
          </div>

      </div>


      {false && <div>
        <div>Whiskers length</div>
        <div><input value={data.whiskers} onChange={e => {
          onEvent({
            type:"change",
            event:e,
            properties:{
              whiskers:Number(e.target.value)
            }
          })
        }} style={{width:40, fontSize:10,}} type="number"/></div>
      </div>}


      <ScalebarTextPlacementStructure {...{onEvent,textPosition,data}}/>






    </div>


  )


}


