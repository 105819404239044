import { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import onUploadFilesSelected from './onUploadFilesSelected';

import DropTargetBox from './DropTargetBox';

export const getFilesFromEvent = e => {

    let files;
    if( e.dataTransfer && e.dataTransfer.files ){

      files = Array.from(e.dataTransfer.files);
    }else{
      files = Array.from(e.target.files);
    }
    return files;
  }


export default function AppImageUploader({onDrop}){

  const dispatch = useDispatch();

  const [isDraggingFile, setIsDraggingFile] = useState(false);

  const onDragEnter = (e) => {
    if( e.dataTransfer.types.length === 1 ){
      let isFiles = e.dataTransfer.types[0] == 'Files';
      //console.log(e.dataTransfer);
      if( isFiles ){
        setIsDraggingFile(true);
      }
    }
  };

  const onMouseLeave = (e) => {
    e = e ? e : window.event;
    var from = e.relatedTarget || e.toElement;
    if (!from || from.nodeName == "HTML") {
      // stop your drag event here
      // for now we can just use an alert
      setIsDraggingFile(false);
    }
  };



  useEffect(() => {
    window.addEventListener("dragenter", onDragEnter);
    //window.addEventListener("drop", onDrop);
    document.addEventListener("mouseout", onMouseLeave);
    //window.addEventListener("dragend", onDragEnd);
    //window.addEventListener("mousemove", onMouseMove);
    return () => {
      window.removeEventListener("dragenter", onDragEnter);
      document.removeEventListener("mouseout", onMouseLeave);
      //window.removeEventListener("mousemove", onMouseMove);
    };
  });


  const containerRef = useRef(null);

  const fileUploader = useRef(null);

  

  let onChange = function(e){

    let files = getFilesFromEvent(e);

    onUploadFilesSelected(files,dispatch); 

    setIsDraggingFile(false);

  }


  useEffect(() => {

    //setContainerWidth(containerRef.current.getBoundingClientRect().width);

  },[containerRef])


  return isDraggingFile && (
    <>
    <div style={{
      position:'absolute',top:0,left:0,
      width:'100%',height:'100%',background:'#111',
      opacity:0.9,zIndex:100
    }}>

      <DropTargetBox 
        onDrop={e => {onChange(e); onDrop()}}
        mouseEnteredColor={"#111"}
        style={{
          position:'absolute',
          top:'2.5%',
          left:'2.5%',
          width:'95%',
          height:'95%',

        }}>
        <div style={{
          marginTop:'25%',
          transform:'translate(0,-50%)',
          fontSize:64,color:'white'}}>
          Drop Images (PNG,JPEG,TIFF) To Upload
        </div>
      </DropTargetBox>


    </div>

      <input custom-tag={"image-uploader"} onChange={onChange} ref={fileUploader} style={{display:'none'}} type="file" class="hidden"/>

    </>

  )



}
